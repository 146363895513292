import React, {Component} from 'react';
import {Button, Card, Nav, Tab} from 'react-bootstrap';
import DataSelection from './DataSelection';
import FineTuning from './FineTuning';

export default class SidebarTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 2,
            open: true,
            scapeRef: {},
            stateRef: {},
            layerRef: {},
            viewRef: {},
            scapeList: []
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleNewScapeMenu = this.handleNewScapeMenu.bind(this);
    }
    componentDidMount() {
        document.addEventListener('socioscapes.newScapeMenu', this.handleNewScapeMenu);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        document.removeEventListener('socioscapes.newScapeMenu', this.handleNewScapeMenu);
        document.addEventListener('socioscapes.newScapeMenu', this.handleNewScapeMenu);
    }

    handleNewScapeMenu(e) {
        let event = {
                name: e.detail.meta.name,
                menu: e.detail,
                object: e.detail.meta.thisObject,
                type: e.detail.meta.schema.class,
            },
            current = {
                scapeList: this.state.scapeList,
                scape: this.state.scapeRef,
                state: this.state.stateRef,
                layer: this.state.layerRef,
                view: this.state.viewRef,
                object: this.state[`${e.detail.meta.schema.class}Ref`].object,
            },
            myUpdate;
        if (event.object !== current.object) {
            if (event.type === 'scape') {
                for (let scape in current.scapeList) {
                    if (current.scapeList.hasOwnProperty(scape)) {
                        this.state.scapeList[scape] = current.scapeList[scape];
                    }
                }
                myUpdate = {
                    scapeRef: {
                        menu: event.menu,
                        name: event.name,
                        object: event.object,
                    },
                    stateRef: {
                        name: event.object.state[0].meta.name,
                        object: event.object.state[0],
                    },
                    layerRef: {
                        name: event.object.state[0].layer[0].meta.name,
                        object: event.object.state[0].layer[0],
                    },
                    viewRef: {
                        name: event.object.state[0].view[0].meta.name,
                        object: event.object.state[0].view[0],
                    },
                };
            } else if (event.type === 'state') {
                myUpdate = {
                    stateRef: {
                        menu: event.menu,
                        name: event.name,
                        object: event.object,
                    },
                    layerRef: {
                        name: event.object.layer[0].meta.name,
                        object: event.object.layer[0],
                    },
                    viewRef: {
                        name: event.object.view[0].meta.name,
                        object: event.object.view[0],
                    },
                }
            } else if (event.type === 'layer') {
                myUpdate = {
                    layerRef: {
                        menu: event.menu,
                        name: event.name,
                        object: event.object,
                    },
                };
            } else if (event.type === 'view') {
                myUpdate = {
                    viewRef: {
                        menu: event.menu,
                        name: event.name,
                        object: event.object,
                    },
                };
            }
            this.setState(myUpdate);
        }
    }

    handleSelect(key) {
        this.setState({key: key});
    }

    getTitle() {
        if (+this.state.key === 1) {
            return 'File Management';
        } else if (+this.state.key === 2) {
            return 'Census Explorer';
        } else if (+this.state.key === 3) {
            return 'Fine Tuning';
        }
    }

    handleClick() {
        this.setState({open: !this.state.open});
    }

    render() {
        return (
            <div className="col-12">
                <Card className="shadow-lg">
                    <Card.Header>
                        <Button className="bg-transparent border-0 my-2"
                                onClick={this.handleClick}
                                title="Toggle Tabs"
                        >
                            {this.state.open ?
                                <i className="bi bi-chevron-compact-down"/> :
                                <i className="bi bi-chevron-compact-up"/>}
                            &nbsp;&nbsp;{this.state.characteristic}&nbsp;&nbsp;
                            {this.getTitle()}
                        </Button>
                    </Card.Header>
                    <Card.Body
                        className={this.state.open ? 'vh-50' : 'd-none'}
                    >
                        <Tab.Container defaultActiveKey={this.state.key}>
                            <Nav
                                onSelect={this.handleSelect}>
                                <Nav.Item>
                                    <Nav.Link eventKey={1}
                                              className={'h-100' + (' disabled')}><i
                                        className="bi bi-save-fill"> </i></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={2}
                                              className={'h-100' + (+this.state.key === 2 ? ' disabled' : '')}><i
                                        className="bi bi-globe"> </i></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={3}
                                              className={'h-100' + (+this.state.key === 3 ? ' disabled' : '')}
                                    ><i className="bi bi-wrench"> </i></Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey={2}>
                                    <DataSelection disabled tab={this}
                                                   ref="DataSelection"
                                                   viewRef={this.state.viewRef}
                                                   layerRef={this.state.layerRef}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey={3}>
                                    <FineTuning tab={this} ref="FineTuning"
                                                viewRef={this.state.viewRef}
                                                layerRef={this.state.layerRef}
                                                stateRef={this.state.stateRef}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}