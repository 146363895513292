const censusQuestionnaire2021 = [false,
    {
        "question": 1,
        "id": 1,
        "characteristic": "Population, 2021 (1)",
        "normalization": [0, 7],
        "parent": 0,
        "gender": false
    }, {
        "question": 2,
        "id": 2,
        "characteristic": "Population, 2016 (1)",
        "normalization": [0, 7],
        "parent": 0,
        "gender": false
    }, {
        "question": 3,
        "id": 3,
        "characteristic": "Population percentage change, 2016 to 2021",
        "normalization": [0],
        "parent": 0,
        "gender": false
    }, {
        "question": 4,
        "id": 4,
        "characteristic": "Total private dwellings (2)",
        "normalization": [1, 7],
        "parent": 0,
        "gender": false
    }, {
        "question": 5,
        "id": 5,
        "characteristic": "Private dwellings occupied by usual residents (3)",
        "normalization": [1, 7],
        "parent": 0,
        "gender": false
    }, {
        "question": 6,
        "id": 6,
        "characteristic": "Population density per square kilometre",
        "normalization": [0],
        "parent": 0,
        "gender": false
    }, {
        "question": 7,
        "id": 7,
        "characteristic": "Land area in square kilometres",
        "normalization": [0],
        "parent": 0,
        "gender": false
    }, {
        "question": 8,
        "id": 8,
        "characteristic": "Total - Age groups of the population - 100% data",
        "normalization": [0, 7],
        "parent": 0,
        "gender": true
    }, {
        "question": 9,
        "id": 9,
        "characteristic": "  0 to 14 years",
        "normalization": [8, 7],
        "parent": 8,
        "gender": true
    }, {
        "question": 10,
        "id": 10,
        "characteristic": "    0 to 4 years",
        "normalization": [8, 7],
        "parent": 9,
        "gender": true
    }, {
        "question": 11,
        "id": 11,
        "characteristic": "    5 to 9 years",
        "normalization": [8, 7],
        "parent": 9,
        "gender": true
    }, {
        "question": 12,
        "id": 12,
        "characteristic": "    10 to 14 years",
        "normalization": [8, 7],
        "parent": 9,
        "gender": true
    }, {
        "question": 13,
        "id": 13,
        "characteristic": "  15 to 64 years",
        "normalization": [8, 7],
        "parent": 8,
        "gender": true
    }, {
        "question": 14,
        "id": 14,
        "characteristic": "    15 to 19 years",
        "normalization": [8, 7],
        "parent": 13,
        "gender": true
    }, {
        "question": 15,
        "id": 15,
        "characteristic": "    20 to 24 years",
        "normalization": [8, 7],
        "parent": 13,
        "gender": true
    }, {
        "question": 16,
        "id": 16,
        "characteristic": "    25 to 29 years",
        "normalization": [8, 7],
        "parent": 13,
        "gender": true
    }, {
        "question": 17,
        "id": 17,
        "characteristic": "    30 to 34 years",
        "normalization": [8, 7],
        "parent": 13,
        "gender": true
    }, {
        "question": 18,
        "id": 18,
        "characteristic": "    35 to 39 years",
        "normalization": [8, 7],
        "parent": 13,
        "gender": true
    }, {
        "question": 19,
        "id": 19,
        "characteristic": "    40 to 44 years",
        "normalization": [8, 7],
        "parent": 13,
        "gender": true
    }, {
        "question": 20,
        "id": 20,
        "characteristic": "    45 to 49 years",
        "normalization": [8, 7],
        "parent": 13,
        "gender": true
    }, {
        "question": 21,
        "id": 21,
        "characteristic": "    50 to 54 years",
        "normalization": [8, 7],
        "parent": 13,
        "gender": true
    }, {
        "question": 22,
        "id": 22,
        "characteristic": "    55 to 59 years",
        "normalization": [8, 7],
        "parent": 13,
        "gender": true
    }, {
        "question": 23,
        "id": 23,
        "characteristic": "    60 to 64 years",
        "normalization": [8, 7],
        "parent": 13,
        "gender": true
    }, {
        "question": 24,
        "id": 24,
        "characteristic": "  65 years and over",
        "normalization": [8, 7],
        "parent": 8,
        "gender": true
    }, {
        "question": 25,
        "id": 25,
        "characteristic": "    65 to 69 years",
        "normalization": [8, 7],
        "parent": 24,
        "gender": true
    }, {
        "question": 26,
        "id": 26,
        "characteristic": "    70 to 74 years",
        "normalization": [8, 7],
        "parent": 24,
        "gender": true
    }, {
        "question": 27,
        "id": 27,
        "characteristic": "    75 to 79 years",
        "normalization": [8, 7],
        "parent": 24,
        "gender": true
    }, {
        "question": 28,
        "id": 28,
        "characteristic": "    80 to 84 years",
        "normalization": [8, 7],
        "parent": 24,
        "gender": true
    }, {
        "question": 29,
        "id": 29,
        "characteristic": "    85 years and over",
        "normalization": [8, 7],
        "parent": 24,
        "gender": true
    }, {
        "question": 30,
        "id": 30,
        "characteristic": "      85 to 89 years",
        "normalization": [8, 7],
        "parent": 29,
        "gender": true
    }, {
        "question": 31,
        "id": 31,
        "characteristic": "      90 to 94 years",
        "normalization": [8, 7],
        "parent": 29,
        "gender": true
    }, {
        "question": 32,
        "id": 32,
        "characteristic": "      95 to 99 years",
        "normalization": [8, 7],
        "parent": 29,
        "gender": true
    }, {
        "question": 33,
        "id": 33,
        "characteristic": "      100 years and over",
        "normalization": [8, 7],
        "parent": 29,
        "gender": true
    }, {
        "question": 34,
        "id": 34,
        "characteristic": "Total - Distribution (%) of the population by broad age groups - 100% data",
        "normalization": [0, 7],
        "parent": 0,
        "gender": true
    }, {
        "question": 35,
        "id": 35,
        "characteristic": "  0 to 14 years",
        "normalization": [34, 7],
        "parent": 34,
        "gender": true
    }, {
        "question": 36,
        "id": 36,
        "characteristic": "  15 to 64 years",
        "normalization": [34, 7],
        "parent": 34,
        "gender": true
    }, {
        "question": 37,
        "id": 37,
        "characteristic": "  65 years and over",
        "normalization": [34, 7],
        "parent": 34,
        "gender": true
    }, {
        "question": 38,
        "id": 38,
        "characteristic": "    85 years and over",
        "normalization": [34, 7],
        "parent": 37,
        "gender": true
    }, {
        "question": 39,
        "id": 39,
        "characteristic": "Average age of the population",
        "normalization": [0],
        "parent": 0,
        "gender": true
    }, {
        "question": 40,
        "id": 40,
        "characteristic": "Median age of the population",
        "normalization": [0],
        "parent": 0,
        "gender": true
    }, {
        "question": 41,
        "id": 41,
        "characteristic": "Total - Occupied private dwellings by structural type of dwelling - 100% data",
        "normalization": [0, 7],
        "parent": 0,
        "gender": false
    }, {
        "question": 42,
        "id": 42,
        "characteristic": "  Single-detached house",
        "normalization": [41, 7],
        "parent": 41,
        "gender": false
    }, {
        "question": 43,
        "id": 43,
        "characteristic": "  Semi-detached house",
        "normalization": [41, 7],
        "parent": 41,
        "gender": false
    }, {
        "question": 44,
        "id": 44,
        "characteristic": "  Row house",
        "normalization": [41, 7],
        "parent": 41,
        "gender": false
    }, {
        "question": 45,
        "id": 45,
        "characteristic": "  Apartment or flat in a duplex",
        "normalization": [41, 7],
        "parent": 41,
        "gender": false
    }, {
        "question": 46,
        "id": 46,
        "characteristic": "  Apartment in a building that has fewer than five storeys",
        "normalization": [41, 7],
        "parent": 41,
        "gender": false
    }, {
        "question": 47,
        "id": 47,
        "characteristic": "  Apartment in a building that has five or more storeys",
        "normalization": [41, 7],
        "parent": 41,
        "gender": false
    }, {
        "question": 48,
        "id": 48,
        "characteristic": "  Other single-attached house",
        "normalization": [41, 7],
        "parent": 41,
        "gender": false
    }, {
        "question": 49,
        "id": 49,
        "characteristic": "  Movable dwelling (4)",
        "normalization": [41, 7],
        "parent": 41,
        "gender": false
    }, {
        "question": 50,
        "id": 50,
        "characteristic": "Total - Private households by household size - 100% data",
        "normalization": [0, 7],
        "parent": 0,
        "gender": false
    }, {
        "question": 51,
        "id": 51,
        "characteristic": "  1 person",
        "normalization": [50, 7],
        "parent": 50,
        "gender": false
    }, {
        "question": 52,
        "id": 52,
        "characteristic": "  2 persons",
        "normalization": [50, 7],
        "parent": 50,
        "gender": false
    }, {
        "question": 53,
        "id": 53,
        "characteristic": "  3 persons",
        "normalization": [50, 7],
        "parent": 50,
        "gender": false
    }, {
        "question": 54,
        "id": 54,
        "characteristic": "  4 persons",
        "normalization": [50, 7],
        "parent": 50,
        "gender": false
    }, {
        "question": 55,
        "id": 55,
        "characteristic": "  5 or more persons",
        "normalization": [50, 7],
        "parent": 50,
        "gender": false
    }, {
        "question": 56,
        "id": 56,
        "characteristic": "Number of persons in private households",
        "normalization": [0, 7],
        "parent": 0,
        "gender": false
    }, {
        "question": 57,
        "id": 57,
        "characteristic": "Average household size",
        "normalization": [],
        "parent": 0,
        "gender": false
    }, {
        "question": 58,
        "id": 58,
        "characteristic": "Total - Marital status for the total population aged 15 years and over - 100% data",
        "normalization": [0, 7],
        "parent": 0,
        "gender": false
    }, {
        "question": 59,
        "id": 59,
        "characteristic": "  Married or living common-law",
        "normalization": [58, 7],
        "parent": 58,
        "gender": false
    }, {
        "question": 60,
        "id": 60,
        "characteristic": "    Married",
        "normalization": [58, 7],
        "parent": 59,
        "gender": false
    }, {
        "question": 61,
        "id": 61,
        "characteristic": "    Living common-law",
        "normalization": [58, 7],
        "parent": 59,
        "gender": false
    }, {
        "question": 62,
        "id": 62,
        "characteristic": "      Living common law - Never married",
        "normalization": [58, 7],
        "parent": 61,
        "gender": false
    }, {
        "question": 63,
        "id": 63,
        "characteristic": "      Living common law - Separated",
        "normalization": [58, 7],
        "parent": 61,
        "gender": false
    }, {
        "question": 64,
        "id": 64,
        "characteristic": "      Living common law - Divorced",
        "normalization": [58, 7],
        "parent": 61,
        "gender": false
    }, {
        "question": 65,
        "id": 65,
        "characteristic": "      Living common law - Widowed",
        "normalization": [58, 7],
        "parent": 61,
        "gender": false
    }, {
        "question": 66,
        "id": 66,
        "characteristic": "  Not married and not living common-law",
        "normalization": [58, 7],
        "parent": 58,
        "gender": false
    }, {
        "question": 67,
        "id": 67,
        "characteristic": "    Not married and not living common law - Never married",
        "normalization": [58, 7],
        "parent": 66,
        "gender": false
    }, {
        "question": 68,
        "id": 68,
        "characteristic": "    Not married and not living common law - Separated",
        "normalization": [58, 7],
        "parent": 66,
        "gender": false
    }, {
        "question": 69,
        "id": 69,
        "characteristic": "    Not married and not living common law - Divorced",
        "normalization": [58, 7],
        "parent": 66,
        "gender": false
    }, {
        "question": 70,
        "id": 70,
        "characteristic": "    Not married and not living common law - Widowed",
        "normalization": [58, 7],
        "parent": 66,
        "gender": false
    }, {
        "question": 71,
        "id": 71,
        "characteristic": "Total - Census families in private households by family size - 100% data (5)",
        "normalization": [0, 7],
        "parent": 0,
        "gender": false
    }, {
        "question": 72,
        "id": 72,
        "characteristic": "  2 persons",
        "normalization": [71, 7],
        "parent": 71,
        "gender": false
    }, {
        "question": 73,
        "id": 73,
        "characteristic": "  3 persons",
        "normalization": [71, 7],
        "parent": 71,
        "gender": false
    }, {
        "question": 74,
        "id": 74,
        "characteristic": "  4 persons",
        "normalization": [71, 7],
        "parent": 71,
        "gender": false
    }, {
        "question": 75,
        "id": 75,
        "characteristic": "  5 or more persons",
        "normalization": [71, 7],
        "parent": 71,
        "gender": false
    }, {
        "question": 76,
        "id": 76,
        "characteristic": "Average size of census families",
        "normalization": [],
        "parent": 0,
        "gender": false
    }, {
        "question": 77,
        "id": 77,
        "characteristic": "Average number of children in census families with children (6)",
        "normalization": [],
        "parent": 0,
        "gender": false
    }, {
        "question": 78,
        "id": 78,
        "characteristic": "Total number of census families in private households - 100% data",
        "normalization": [0, 7],
        "parent": 0,
        "gender": false
    }, {
        "question": 79,
        "id": 79,
        "characteristic": "  Total couple families",
        "normalization": [78, 7],
        "parent": 78,
        "gender": false
    }, {
        "question": 80,
        "id": 80,
        "characteristic": "    Married couples",
        "normalization": [78, 7],
        "parent": 79,
        "gender": false
    }, {
        "question": 81,
        "id": 81,
        "characteristic": "      With children (6)",
        "normalization": [78, 7],
        "parent": 80,
        "gender": false
    }, {
        "question": 82,
        "id": 82,
        "characteristic": "      Without children",
        "normalization": [78, 7],
        "parent": 80,
        "gender": false
    }, {
        "question": 83,
        "id": 83,
        "characteristic": "    Common-law couples",
        "normalization": [78, 7],
        "parent": 79,
        "gender": false
    }, {
        "question": 84,
        "id": 84,
        "characteristic": "      With children (6)",
        "normalization": [78, 7],
        "parent": 84,
        "gender": false
    }, {
        "question": 85,
        "id": 85,
        "characteristic": "      Without children",
        "normalization": [78, 7],
        "parent": 84,
        "gender": false
    }, {
        "question": 86,
        "id": 86,
        "characteristic": "  Total one-parent families",
        "normalization": [78, 7],
        "parent": 78,
        "gender": false
    }, {
        "question": 87,
        "id": 87,
        "characteristic": "    in which the parent is a woman+",
        "normalization": [78, 7],
        "parent": 86,
        "gender": false
    }, {
        "question": 88,
        "id": 88,
        "characteristic": "    in which the parent is a man+",
        "normalization": [78, 7],
        "parent": 86,
        "gender": false
    }, {
        "question": 89,
        "id": 89,
        "characteristic": "Total - Persons in private households - 100% data",
        "normalization": [0, 7],
        "parent": 0,
        "gender": false
    }, {
        "question": 90,
        "id": 90,
        "characteristic": "  Total - Persons in census families",
        "normalization": [89, 7],
        "parent": 89,
        "gender": false
    }, {
        "question": 91,
        "id": 91,
        "characteristic": "    Married spouses or common-law partners",
        "normalization": [89, 7],
        "parent": 90,
        "gender": false
    }, {
        "question": 92,
        "id": 92,
        "characteristic": "    Parents in one-parent families",
        "normalization": [89, 7],
        "parent": 90,
        "gender": false
    }, {
        "question": 93,
        "id": 93,
        "characteristic": "    Children (6)",
        "normalization": [89, 7],
        "parent": 90,
        "gender": false
    }, {
        "question": 94,
        "id": 94,
        "characteristic": "      In a two-parent family",
        "normalization": [89, 7],
        "parent": 93,
        "gender": false
    }, {
        "question": 95,
        "id": 95,
        "characteristic": "      In a one-parent family",
        "normalization": [89, 7],
        "parent": 93,
        "gender": false
    }, {
        "question": 96,
        "id": 96,
        "characteristic": "  Total - Persons not in census families in private households - 100% data",
        "normalization": [89, 7],
        "parent": 0,
        "gender": true
    }, {
        "question": 97,
        "id": 97,
        "characteristic": "    Living alone",
        "normalization": [89, 7],
        "parent": 96,
        "gender": true
    }, {
        "question": 98,
        "id": 98,
        "characteristic": "    Living with other relatives (7)",
        "normalization": [89, 7],
        "parent": 96,
        "gender": true
    }, {
        "question": 99,
        "id": 99,
        "characteristic": "    Living with non-relatives only",
        "normalization": [89, 7],
        "parent": 96,
        "gender": true
    }, {
        "question": 100,
        "id": 100,
        "characteristic": "Total - Household type - 100% data",
        "normalization": [0, 7],
        "parent": 0,
        "gender": false
    }, {
        "question": 101,
        "id": 101,
        "characteristic": "  One-census-family households without additional persons",
        "normalization": [100, 7],
        "parent": 100,
        "gender": false
    }, {
        "question": 102,
        "id": 102,
        "characteristic": "    Couple-family households",
        "normalization": [100, 7],
        "parent": 101,
        "gender": false
    }, {
        "question": 103,
        "id": 103,
        "characteristic": "      With children (6)",
        "normalization": [100, 7],
        "parent": 102,
        "gender": false
    }, {
        "question": 104,
        "id": 104,
        "characteristic": "      Without children",
        "normalization": [100, 7],
        "parent": 102,
        "gender": false
    }, {
        "question": 105,
        "id": 105,
        "characteristic": "    One-parent-family households",
        "normalization": [100, 7],
        "parent": 101,
        "gender": false
    }, {
        "question": 106,
        "id": 106,
        "characteristic": "  Multigenerational households (8)",
        "normalization": [100, 7],
        "parent": 100,
        "gender": false
    }, {
        "question": 107,
        "id": 107,
        "characteristic": "  Multiple-census-family households (9)",
        "normalization": [100, 7],
        "parent": 100,
        "gender": false
    }, {
        "question": 108,
        "id": 108,
        "characteristic": "  One-census-family households with additional persons (9)",
        "normalization": [100, 7],
        "parent": 100,
        "gender": false
    }, {
        "question": 109,
        "id": 109,
        "characteristic": "  Two-or-more-person non-census-family households",
        "normalization": [100, 7],
        "parent": 100,
        "gender": false
    }, {
        "question": 110,
        "id": 110,
        "characteristic": "  One-person households",
        "normalization": [100, 7],
        "parent": 100,
        "gender": false
    }, {
        "question": 111,
        "id": 111,
        "characteristic": "Total - Income statistics in 2020 for the population aged 15 years and over in private households - 100% data (10)",
        "normalization": [0, 7],
        "parent": 0,
        "gender": true
    }, {
        "question": 112,
        "id": 112,
        "characteristic": "  Number of total income recipients aged 15 years and over in private households in 2020 - 100% data",
        "normalization": [111, 7],
        "parent": 111,
        "gender": true
    }, {
        "question": 113,
        "id": 113,
        "characteristic": "    Median total income in 2020 among recipients ($)",
        "normalization": [],
        "parent": 112,
        "gender": true
    }, {
        "question": 114,
        "id": 114,
        "characteristic": "  Number of after-tax income recipients aged 15 years and over in private households in 2020 - 100% data",
        "normalization": [111, 7],
        "parent": 111,
        "gender": true
    }, {
        "question": 115,
        "id": 115,
        "characteristic": "    Median after-tax income in 2020 among recipients ($)",
        "normalization": [],
        "parent": 114,
        "gender": true
    }, {
        "question": 116,
        "id": 116,
        "characteristic": "  Number of market income recipients aged 15 years and over in private households in 2020 - 100% data",
        "normalization": [111, 7],
        "parent": 111,
        "gender": true
    }, {
        "question": 117,
        "id": 117,
        "characteristic": "    Median market income in 2020 among recipients ($)",
        "normalization": [],
        "parent": 116,
        "gender": true
    }, {
        "question": 118,
        "id": 118,
        "characteristic": "  Number of employment income recipients aged 15 years and over in private households in 2020 - 100% data",
        "normalization": [111, 7],
        "parent": 111,
        "gender": true
    }, {
        "question": 119,
        "id": 119,
        "characteristic": "    Median employment income in 2020 among recipients ($)",
        "normalization": [],
        "parent": 118,
        "gender": true
    }, {
        "question": 120,
        "id": 120,
        "characteristic": "  Number of government transfers recipients aged 15 years and over in private households in 2020 - 100% data",
        "normalization": [111, 7],
        "parent": 111,
        "gender": true
    }, {
        "question": 121,
        "id": 121,
        "characteristic": "    Median government transfers in 2020 among recipients ($)",
        "normalization": [],
        "parent": 120,
        "gender": true
    }, {
        "question": 122,
        "id": 122,
        "characteristic": "  Number of employment insurance benefits recipients aged 15 years and over in private households in 2020 - 100% data",
        "normalization": [111, 7],
        "parent": 111,
        "gender": true
    }, {
        "question": 123,
        "id": 123,
        "characteristic": "    Median employment insurance benefits in 2020 among recipients ($)",
        "normalization": [],
        "parent": 122,
        "gender": true
    }, {
        "question": 124,
        "id": 124,
        "characteristic": "  Number of COVID-19 emergency and recovery benefits recipients aged 15 years and over in private households in 2020 - 100% data",
        "normalization": [111, 7],
        "parent": 111,
        "gender": true
    }, {
        "question": 125,
        "id": 125,
        "characteristic": "    Median COVID-19 emergency and recovery benefits in 2020 among recipients ($)",
        "normalization": [],
        "parent": 124,
        "gender": true
    }, {
        "question": 126,
        "id": 126,
        "characteristic": "Total - Income statistics in 2020 for the population aged 15 years and over in private households - 25% sample data (11)",
        "normalization": [0, 7],
        "parent": 0,
        "gender": true
    }, {
        "question": 127,
        "id": 127,
        "characteristic": "  Number of total income recipients aged 15 years and over in private households in 2020 - 25% sample data",
        "normalization": [126, 7],
        "parent": 126,
        "gender": true
    }, {
        "question": 128,
        "id": 128,
        "characteristic": "    Average total income in 2020 among recipients ($)",
        "normalization": [],
        "parent": 127,
        "gender": true
    }, {
        "question": 129,
        "id": 129,
        "characteristic": "  Number of after-tax income recipients aged 15 years and over in private households in 2020 - 25% sample data",
        "normalization": [126, 7],
        "parent": 126,
        "gender": true
    }, {
        "question": 130,
        "id": 130,
        "characteristic": "    Average after-tax income in 2020 among recipients ($)",
        "normalization": [],
        "parent": 129,
        "gender": true
    }, {
        "question": 131,
        "id": 131,
        "characteristic": "  Number of market income recipients aged 15 years and over in private households in 2020 - 25% sample data",
        "normalization": [126, 7],
        "parent": 126,
        "gender": true
    }, {
        "question": 132,
        "id": 132,
        "characteristic": "    Average market income in 2020 among recipients ($)",
        "normalization": [],
        "parent": 131,
        "gender": true
    }, {
        "question": 133,
        "id": 133,
        "characteristic": "  Number of employment income recipients aged 15 years and over in private households in 2020 - 25% sample data",
        "normalization": [126, 7],
        "parent": 126,
        "gender": true
    }, {
        "question": 134,
        "id": 134,
        "characteristic": "    Average employment income in 2020 among recipients ($)",
        "normalization": [],
        "parent": 133,
        "gender": true
    }, {
        "question": 135,
        "id": 135,
        "characteristic": "  Number of government transfers recipients aged 15 years and over in private households in 2020 - 25% sample data",
        "normalization": [126, 7],
        "parent": 126,
        "gender": true
    }, {
        "question": 136,
        "id": 136,
        "characteristic": "    Average government transfers in 2020 among recipients ($)",
        "normalization": [],
        "parent": 135,
        "gender": true
    }, {
        "question": 137,
        "id": 137,
        "characteristic": "  Number of employment insurance benefits recipients aged 15 years and over in private households in 2020 - 25% sample data",
        "normalization": [126, 7],
        "parent": 126,
        "gender": true
    }, {
        "question": 138,
        "id": 138,
        "characteristic": "    Average employment insurance benefits in 2020 among recipients ($)",
        "normalization": [],
        "parent": 137,
        "gender": true
    }, {
        "question": 139,
        "id": 139,
        "characteristic": "  Number of COVID-19 emergency and recovery benefits recipients aged 15 years and over in private households in 2020 - 25% sample data",
        "normalization": [126, 7],
        "parent": 126,
        "gender": true
    }, {
        "question": 140,
        "id": 140,
        "characteristic": "    Average COVID-19 emergency and recovery benefits in 2020 among recipients ($)",
        "normalization": [],
        "parent": 139,
        "gender": true
    }, {
        "question": 141,
        "id": 141,
        "characteristic": "Composition of total income in 2020 of the population aged 15 years and over in private households (%) - 25% sample data (12)",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 142,
        "id": 142,
        "characteristic": "  Market income (%) (13)",
        "normalization": [],
        "parent": 141,
        "gender": true
    }, {
        "question": 143,
        "id": 143,
        "characteristic": "    Employment income (%) (14)",
        "normalization": [],
        "parent": 142,
        "gender": true
    }, {
        "question": 144,
        "id": 144,
        "characteristic": "  Government transfers (%) (15)",
        "normalization": [],
        "parent": 141,
        "gender": true
    }, {
        "question": 145,
        "id": 145,
        "characteristic": "    Employment insurance benefits (%)",
        "normalization": [],
        "parent": 144,
        "gender": true
    }, {
        "question": 146,
        "id": 146,
        "characteristic": "    COVID-19 - Government income support and benefits (%)",
        "normalization": [],
        "parent": 144,
        "gender": true
    }, {
        "question": 147,
        "id": 147,
        "characteristic": "      COVID-19 - Emergency and recovery benefits (%)",
        "normalization": [],
        "parent": 146,
        "gender": true
    }, {
        "question": 148,
        "id": 148,
        "characteristic": "Total - Total income groups in 2020 for the population aged 15 years and over in private households - 100% data (16)",
        "normalization": [0, 7],
        "parent": 0,
        "gender": true
    }, {
        "question": 149,
        "id": 149,
        "characteristic": "  Without total income",
        "normalization": [148, 7],
        "parent": 148,
        "gender": true
    }, {
        "question": 150,
        "id": 150,
        "characteristic": "  With total income",
        "normalization": [148, 7],
        "parent": 148,
        "gender": true
    }, {
        "question": 151,
        "id": 151,
        "characteristic": "    Under $10,000 (including loss)",
        "normalization": [148, 7],
        "parent": 150,
        "gender": true
    }, {
        "question": 152,
        "id": 152,
        "characteristic": "    $10,000 to $19,999",
        "normalization": [148, 7],
        "parent": 150,
        "gender": true
    }, {
        "question": 153,
        "id": 153,
        "characteristic": "    $20,000 to $29,999",
        "normalization": [148, 7],
        "parent": 150,
        "gender": true
    }, {
        "question": 154,
        "id": 154,
        "characteristic": "    $30,000 to $39,999",
        "normalization": [148, 7],
        "parent": 150,
        "gender": true
    }, {
        "question": 155,
        "id": 155,
        "characteristic": "    $40,000 to $49,999",
        "normalization": [148, 7],
        "parent": 150,
        "gender": true
    }, {
        "question": 156,
        "id": 156,
        "characteristic": "    $50,000 to $59,999",
        "normalization": [148, 7],
        "parent": 150,
        "gender": true
    }, {
        "question": 157,
        "id": 157,
        "characteristic": "    $60,000 to $69,999",
        "normalization": [148, 7],
        "parent": 150,
        "gender": true
    }, {
        "question": 158,
        "id": 158,
        "characteristic": "    $70,000 to $79,999",
        "normalization": [148, 7],
        "parent": 150,
        "gender": true
    }, {
        "question": 159,
        "id": 159,
        "characteristic": "    $80,000 to $89,999",
        "normalization": [148, 7],
        "parent": 150,
        "gender": true
    }, {
        "question": 160,
        "id": 160,
        "characteristic": "    $90,000 to $99,999",
        "normalization": [148, 7],
        "parent": 150,
        "gender": true
    }, {
        "question": 161,
        "id": 161,
        "characteristic": "    $100,000 and over",
        "normalization": [148, 7],
        "parent": 150,
        "gender": true
    }, {
        "question": 162,
        "id": 162,
        "characteristic": "      $100,000 to $149,999",
        "normalization": [148, 7],
        "parent": 161,
        "gender": true
    }, {
        "question": 163,
        "id": 163,
        "characteristic": "      $150,000 and over",
        "normalization": [148, 7],
        "parent": 161,
        "gender": true
    }, {
        "question": 164,
        "id": 164,
        "characteristic": "Total - After-tax income groups in 2020 for the population aged 15 years and over in private households - 100% data (17)",
        "normalization": [0, 7],
        "parent": 0,
        "gender": true
    }, {
        "question": 165,
        "id": 165,
        "characteristic": "  Without after-tax income",
        "normalization": [164, 7],
        "parent": 164,
        "gender": true
    }, {
        "question": 166,
        "id": 166,
        "characteristic": "  With after-tax income",
        "normalization": [164, 7],
        "parent": 164,
        "gender": true
    }, {
        "question": 167,
        "id": 167,
        "characteristic": "    Under $10,000 (including loss)",
        "normalization": [164, 7],
        "parent": 166,
        "gender": true
    }, {
        "question": 168,
        "id": 168,
        "characteristic": "    $10,000 to $19,999",
        "normalization": [164, 7],
        "parent": 166,
        "gender": true
    }, {
        "question": 169,
        "id": 169,
        "characteristic": "    $20,000 to $29,999",
        "normalization": [164, 7],
        "parent": 166,
        "gender": true
    }, {
        "question": 170,
        "id": 170,
        "characteristic": "    $30,000 to $39,999",
        "normalization": [164, 7],
        "parent": 166,
        "gender": true
    }, {
        "question": 171,
        "id": 171,
        "characteristic": "    $40,000 to $49,999",
        "normalization": [164, 7],
        "parent": 166,
        "gender": true
    }, {
        "question": 172,
        "id": 172,
        "characteristic": "    $50,000 to $59,999",
        "normalization": [164, 7],
        "parent": 166,
        "gender": true
    }, {
        "question": 173,
        "id": 173,
        "characteristic": "    $60,000 to $69,999",
        "normalization": [164, 7],
        "parent": 166,
        "gender": true
    }, {
        "question": 174,
        "id": 174,
        "characteristic": "    $70,000 to $79,999",
        "normalization": [164, 7],
        "parent": 166,
        "gender": true
    }, {
        "question": 175,
        "id": 175,
        "characteristic": "    $80,000 to $89,999",
        "normalization": [164, 7],
        "parent": 166,
        "gender": true
    }, {
        "question": 176,
        "id": 176,
        "characteristic": "    $90,000 to $99,999",
        "normalization": [164, 7],
        "parent": 166,
        "gender": true
    }, {
        "question": 177,
        "id": 177,
        "characteristic": "    $100,000 and over",
        "normalization": [164, 7],
        "parent": 166,
        "gender": true
    }, {
        "question": 178,
        "id": 178,
        "characteristic": "      $100,000 to $124,999",
        "normalization": [164, 7],
        "parent": 177,
        "gender": true
    }, {
        "question": 179,
        "id": 179,
        "characteristic": "      $125,000 and over",
        "normalization": [164, 7],
        "parent": 177,
        "gender": true
    }, {
        "question": 180,
        "id": 180,
        "characteristic": "Total - Employment income groups in 2020 for the population aged 15 years and over in private households - 100% data (14)",
        "normalization": [0, 7],
        "parent": 0,
        "gender": true
    }, {
        "question": 181,
        "id": 181,
        "characteristic": "  Without employment income",
        "normalization": [180, 7],
        "parent": 180,
        "gender": true
    }, {
        "question": 182,
        "id": 182,
        "characteristic": "  With employment income",
        "normalization": [180, 7],
        "parent": 180,
        "gender": true
    }, {
        "question": 183,
        "id": 183,
        "characteristic": "    Under $5,000 (including loss)",
        "normalization": [180, 7],
        "parent": 182,
        "gender": true
    }, {
        "question": 184,
        "id": 184,
        "characteristic": "    $5,000 to $9,999",
        "normalization": [180, 7],
        "parent": 182,
        "gender": true
    }, {
        "question": 185,
        "id": 185,
        "characteristic": "    $10,000 to $19,999",
        "normalization": [180, 7],
        "parent": 182,
        "gender": true
    }, {
        "question": 186,
        "id": 186,
        "characteristic": "    $20,000 to $29,999",
        "normalization": [180, 7],
        "parent": 182,
        "gender": true
    }, {
        "question": 187,
        "id": 187,
        "characteristic": "    $30,000 to $39,999",
        "normalization": [180, 7],
        "parent": 182,
        "gender": true
    }, {
        "question": 188,
        "id": 188,
        "characteristic": "    $40,000 to $49,999",
        "normalization": [180, 7],
        "parent": 182,
        "gender": true
    }, {
        "question": 189,
        "id": 189,
        "characteristic": "    $50,000 to $59,999",
        "normalization": [180, 7],
        "parent": 182,
        "gender": true
    }, {
        "question": 190,
        "id": 190,
        "characteristic": "    $60,000 to $69,999",
        "normalization": [180, 7],
        "parent": 182,
        "gender": true
    }, {
        "question": 191,
        "id": 191,
        "characteristic": "    $70,000 to $79,999",
        "normalization": [180, 7],
        "parent": 182,
        "gender": true
    }, {
        "question": 192,
        "id": 192,
        "characteristic": "    $80,000 to $89,999",
        "normalization": [180, 7],
        "parent": 182,
        "gender": true
    }, {
        "question": 193,
        "id": 193,
        "characteristic": "    $90,000 to $99,999",
        "normalization": [180, 7],
        "parent": 182,
        "gender": true
    }, {
        "question": 194,
        "id": 194,
        "characteristic": "    $100,000 and over",
        "normalization": [180, 7],
        "parent": 182,
        "gender": true
    }, {
        "question": 195,
        "id": 195,
        "characteristic": "      $100,000 to $124,999",
        "normalization": [180, 7],
        "parent": 194,
        "gender": true
    }, {
        "question": 196,
        "id": 196,
        "characteristic": "      $125,000 and over",
        "normalization": [180, 7],
        "parent": 194,
        "gender": true
    }, {
        "question": 197,
        "id": 197,
        "characteristic": "Total - Income statistics in 2019 for the population aged 15 years and over in private households - 100% data (18)",
        "normalization": [0, 7],
        "parent": 0,
        "gender": true
    }, {
        "question": 198,
        "id": 198,
        "characteristic": "  Number of total income recipients aged 15 years and over in private households in 2019 - 100% data",
        "normalization": [197, 7],
        "parent": 197,
        "gender": true
    }, {
        "question": 199,
        "id": 199,
        "characteristic": "    Median total income in 2019 among recipients ($)",
        "normalization": [],
        "parent": 198,
        "gender": true
    }, {
        "question": 200,
        "id": 200,
        "characteristic": "  Number of after-tax income recipients aged 15 years and over in private households in 2019 - 100% data",
        "normalization": [197, 7],
        "parent": 197,
        "gender": true
    }, {
        "question": 201,
        "id": 201,
        "characteristic": "    Median after-tax income in 2019 among recipients ($)",
        "normalization": [],
        "parent": 200,
        "gender": true
    }, {
        "question": 202,
        "id": 202,
        "characteristic": "  Number of market income recipients aged 15 years and over in private households in 2019 - 100% data",
        "normalization": [197, 7],
        "parent": 197,
        "gender": true
    }, {
        "question": 203,
        "id": 203,
        "characteristic": "    Median market income in 2019 among recipients ($)",
        "normalization": [],
        "parent": 202,
        "gender": true
    }, {
        "question": 204,
        "id": 204,
        "characteristic": "  Number of employment income recipients aged 15 years and over in private households in 2019 - 100% data",
        "normalization": [197, 7],
        "parent": 197,
        "gender": true
    }, {
        "question": 205,
        "id": 205,
        "characteristic": "    Median employment income in 2019 among recipients ($)",
        "normalization": [],
        "parent": 204,
        "gender": true
    }, {
        "question": 206,
        "id": 206,
        "characteristic": "  Number of government transfers recipients aged 15 years and over in private households in 2019 - 100% data",
        "normalization": [197, 7],
        "parent": 197,
        "gender": true
    }, {
        "question": 207,
        "id": 207,
        "characteristic": "    Median government transfers in 2019 among recipients ($)",
        "normalization": [],
        "parent": 206,
        "gender": true
    }, {
        "question": 208,
        "id": 208,
        "characteristic": "  Number of employment insurance benefits recipients aged 15 years and over in private households in 2019 -100% data",
        "normalization": [197, 7],
        "parent": 197,
        "gender": true
    }, {
        "question": 209,
        "id": 209,
        "characteristic": "    Median employment insurance benefits in 2019 among recipients ($)",
        "normalization": [],
        "parent": 208,
        "gender": true
    }, {
        "question": 210,
        "id": 210,
        "characteristic": "Total - Income statistics in 2019 for the population aged 15 years and over in private households - 25% sample data (19)",
        "normalization": [0, 7],
        "parent": 0,
        "gender": true
    }, {
        "question": 211,
        "id": 211,
        "characteristic": "  Number of total income recipients aged 15 years and over in private households in 2019 - 25% sample data",
        "normalization": [210, 7],
        "parent": 210,
        "gender": true
    }, {
        "question": 212,
        "id": 212,
        "characteristic": "    Average total income in 2019 among recipients ($)",
        "normalization": [],
        "parent": 211,
        "gender": true
    }, {
        "question": 213,
        "id": 213,
        "characteristic": "  Number of after-tax income recipients aged 15 years and over in private households in 2019 - 25% sample data",
        "normalization": [210, 7],
        "parent": 210,
        "gender": true
    }, {
        "question": 214,
        "id": 214,
        "characteristic": "    Average after-tax income in 2019 among recipients ($)",
        "normalization": [],
        "parent": 213,
        "gender": true
    }, {
        "question": 215,
        "id": 215,
        "characteristic": "  Number of market income recipients aged 15 years and over in private households in 2019 - 25% sample data",
        "normalization": [210, 7],
        "parent": 210,
        "gender": true
    }, {
        "question": 216,
        "id": 216,
        "characteristic": "    Average market income in 2019 among recipients ($)",
        "normalization": [],
        "parent": 215,
        "gender": true
    }, {
        "question": 217,
        "id": 217,
        "characteristic": "  Number of employment income recipients aged 15 years and over in private households in 2019 - 25% sample data",
        "normalization": [210, 7],
        "parent": 210,
        "gender": true
    }, {
        "question": 218,
        "id": 218,
        "characteristic": "    Average employment income in 2019 among recipients ($)",
        "normalization": [],
        "parent": 217,
        "gender": true
    }, {
        "question": 219,
        "id": 219,
        "characteristic": "  Number of government transfers recipients aged 15 years and over in private households in 2019 - 25% sample data",
        "normalization": [210, 7],
        "parent": 210,
        "gender": true
    }, {
        "question": 220,
        "id": 220,
        "characteristic": "    Average government transfers in 2019 among recipients ($)",
        "normalization": [],
        "parent": 219,
        "gender": true
    }, {
        "question": 221,
        "id": 221,
        "characteristic": "  Number of employment insurance benefits recipients aged 15 years and over in private households in 2019 - 25% sample data",
        "normalization": [210, 7],
        "parent": 210,
        "gender": true
    }, {
        "question": 222,
        "id": 222,
        "characteristic": "    Average employment insurance benefits in 2019 among recipients ($)",
        "normalization": [],
        "parent": 221,
        "gender": true
    }, {
        "question": 223,
        "id": 223,
        "characteristic": "Composition of total income in 2019 of the population aged 15 years and over in private households (%) - 25% sample data",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 224,
        "id": 224,
        "characteristic": "  Market income (%)",
        "normalization": [],
        "parent": 223,
        "gender": true
    }, {
        "question": 225,
        "id": 225,
        "characteristic": "    Employment income (%)",
        "normalization": [],
        "parent": 224,
        "gender": true
    }, {
        "question": 226,
        "id": 226,
        "characteristic": "  Government transfers (%)",
        "normalization": [],
        "parent": 223,
        "gender": true
    }, {
        "question": 227,
        "id": 227,
        "characteristic": "    Employment insurance benefits (%)",
        "normalization": [],
        "parent": 226,
        "gender": true
    }, {
        "question": 228,
        "id": 228,
        "characteristic": "Total - Income statistics for private households - 100% data (20)",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 229,
        "id": 229,
        "characteristic": "  Median total income of household in 2020 ($)",
        "normalization": [],
        "parent": 226,
        "gender": true
    }, {
        "question": 230,
        "id": 230,
        "characteristic": "  Median after-tax income of household in 2020 ($)",
        "normalization": [],
        "parent": 226,
        "gender": true
    }, {
        "question": 231,
        "id": 231,
        "characteristic": "    Total - Income statistics for one-person private households - 100% data",
        "normalization": [],
        "parent": 230,
        "gender": true
    }, {
        "question": 232,
        "id": 232,
        "characteristic": "      Median total income of one-person households in 2020 ($)",
        "normalization": [],
        "parent": 231,
        "gender": true
    }, {
        "question": 233,
        "id": 233,
        "characteristic": "      Median after-tax income of one-person households in 2020 ($)",
        "normalization": [],
        "parent": 231,
        "gender": true
    }, {
        "question": 234,
        "id": 234,
        "characteristic": "    Total - Income statistics for two-or-more-persons private households - 100% data",
        "normalization": [],
        "parent": 230,
        "gender": true
    }, {
        "question": 235,
        "id": 235,
        "characteristic": "      Median total income of two-or-more-person households in 2020 ($)",
        "normalization": [],
        "parent": 234,
        "gender": true
    }, {
        "question": 236,
        "id": 236,
        "characteristic": "      Median after-tax income of two-or-more-person households in 2020 ($)",
        "normalization": [],
        "parent": 234,
        "gender": true
    }, {
        "question": 237,
        "id": 237,
        "characteristic": "Total - Income statistics for private households - 25% sample data (21)",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 238,
        "id": 238,
        "characteristic": "  Average total income of household in 2020 ($)",
        "normalization": [],
        "parent": 237,
        "gender": true
    }, {
        "question": 239,
        "id": 239,
        "characteristic": "  Average after-tax income of household in 2020 ($)",
        "normalization": [],
        "parent": 237,
        "gender": true
    }, {
        "question": 240,
        "id": 240,
        "characteristic": "    Total - Income statistics for one-person private households - 25% sample data",
        "normalization": [],
        "parent": 239,
        "gender": true
    }, {
        "question": 241,
        "id": 241,
        "characteristic": "      Average total income of one-person households in 2020 ($)",
        "normalization": [],
        "parent": 240,
        "gender": true
    }, {
        "question": 242,
        "id": 242,
        "characteristic": "      Average after-tax income of one-person households in 2020 ($)",
        "normalization": [],
        "parent": 240,
        "gender": true
    }, {
        "question": 243,
        "id": 243,
        "characteristic": "    Total - Income statistics for two-or-more-persons private households - 25% sample data",
        "normalization": [],
        "parent": 239,
        "gender": true
    }, {
        "question": 244,
        "id": 244,
        "characteristic": "      Average total income of two-or-more-person households in 2020 ($)",
        "normalization": [],
        "parent": 243,
        "gender": true
    }, {
        "question": 245,
        "id": 245,
        "characteristic": "      Average after-tax income of two-or-more-person households in 2020 ($)",
        "normalization": [],
        "parent": 243,
        "gender": true
    }, {
        "question": 246,
        "id": 246,
        "characteristic": "Total - Household total income groups in 2020 for private households - 100% data (16)",
        "normalization": [0, 7],
        "parent": 0,
        "gender": true
    }, {
        "question": 247,
        "id": 247,
        "characteristic": "  Under $5,000",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 248,
        "id": 248,
        "characteristic": "  $5,000 to $9,999",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 249,
        "id": 249,
        "characteristic": "  $10,000 to $14,999",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 250,
        "id": 250,
        "characteristic": "  $15,000 to $19,999",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 251,
        "id": 251,
        "characteristic": "  $20,000 to $24,999",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 252,
        "id": 252,
        "characteristic": "  $25,000 to $29,999",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 253,
        "id": 253,
        "characteristic": "  $30,000 to $34,999",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 254,
        "id": 254,
        "characteristic": "  $35,000 to $39,999",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 255,
        "id": 255,
        "characteristic": "  $40,000 to $44,999",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 256,
        "id": 256,
        "characteristic": "  $45,000 to $49,999",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 257,
        "id": 257,
        "characteristic": "  $50,000 to $59,999",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 258,
        "id": 258,
        "characteristic": "  $60,000 to $69,999",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 259,
        "id": 259,
        "characteristic": "  $70,000 to $79,999",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 260,
        "id": 260,
        "characteristic": "  $80,000 to $89,999",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 261,
        "id": 261,
        "characteristic": "  $90,000 to $99,999",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 262,
        "id": 262,
        "characteristic": "  $100,000 and over",
        "normalization": [246, 7],
        "parent": 246,
        "gender": true
    }, {
        "question": 263,
        "id": 263,
        "characteristic": "    $100,000 to $124,999",
        "normalization": [246, 7],
        "parent": 262,
        "gender": true
    }, {
        "question": 264,
        "id": 264,
        "characteristic": "    $125,000 to $149,999",
        "normalization": [246, 7],
        "parent": 262,
        "gender": true
    }, {
        "question": 265,
        "id": 265,
        "characteristic": "    $150,000 to $199,999",
        "normalization": [246, 7],
        "parent": 262,
        "gender": true
    }, {
        "question": 266,
        "id": 266,
        "characteristic": "    $200,000 and over",
        "normalization": [246, 7],
        "parent": 262,
        "gender": true
    }, {
        "question": 267,
        "id": 267,
        "characteristic": "Total - Household after-tax income groups in 2020 for private households - 100% data (17)",
        "normalization": [0, 7],
        "parent": 0,
        "gender": true
    }, {
        "question": 268,
        "id": 268,
        "characteristic": "  Under $5,000",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 269,
        "id": 269,
        "characteristic": "  $5,000 to $9,999",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 270,
        "id": 270,
        "characteristic": "  $10,000 to $14,999",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 271,
        "id": 271,
        "characteristic": "  $15,000 to $19,999",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 272,
        "id": 272,
        "characteristic": "  $20,000 to $24,999",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 273,
        "id": 273,
        "characteristic": "  $25,000 to $29,999",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 274,
        "id": 274,
        "characteristic": "  $30,000 to $34,999",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 275,
        "id": 275,
        "characteristic": "  $35,000 to $39,999",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 276,
        "id": 276,
        "characteristic": "  $40,000 to $44,999",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 277,
        "id": 277,
        "characteristic": "  $45,000 to $49,999",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 278,
        "id": 278,
        "characteristic": "  $50,000 to $59,999",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 279,
        "id": 279,
        "characteristic": "  $60,000 to $69,999",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 280,
        "id": 280,
        "characteristic": "  $70,000 to $79,999",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 281,
        "id": 281,
        "characteristic": "  $80,000 to $89,999",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 282,
        "id": 282,
        "characteristic": "  $90,000 to $99,999",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 283,
        "id": 283,
        "characteristic": "  $100,000 and over",
        "normalization": [267, 7],
        "parent": 267,
        "gender": true
    }, {
        "question": 284,
        "id": 284,
        "characteristic": "    $100,000 to $124,999",
        "normalization": [267, 7],
        "parent": 283,
        "gender": true
    }, {
        "question": 285,
        "id": 285,
        "characteristic": "    $125,000 to $149,999",
        "normalization": [267, 7],
        "parent": 283,
        "gender": true
    }, {
        "question": 286,
        "id": 286,
        "characteristic": "    $150,000 and over",
        "normalization": [267, 7],
        "parent": 283,
        "gender": true
    }, {
        "question": 287,
        "id": 287,
        "characteristic": "Total - Income statistics for economic families in private households - 100% data (22)",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 288,
        "id": 288,
        "characteristic": "  Median total income of economic family in 2020 ($)",
        "normalization": [],
        "parent": 287,
        "gender": true
    }, {
        "question": 289,
        "id": 289,
        "characteristic": "  Median after-tax income of economic family in 2020 ($)",
        "normalization": [],
        "parent": 287,
        "gender": true
    }, {
        "question": 290,
        "id": 290,
        "characteristic": "  Average family size of economic families",
        "normalization": [],
        "parent": 287,
        "gender": true
    }, {
        "question": 291,
        "id": 291,
        "characteristic": "Total - Income statistics for couple-only economic families in private households - 100% data",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 292,
        "id": 292,
        "characteristic": "  Median total income of couple-only economic families in 2020 ($)",
        "normalization": [],
        "parent": 291,
        "gender": true
    }, {
        "question": 293,
        "id": 293,
        "characteristic": "  Median after-tax income of couple-only economic families in 2020 ($)",
        "normalization": [],
        "parent": 291,
        "gender": true
    }, {
        "question": 294,
        "id": 294,
        "characteristic": "  Average family size of couple-only economic families",
        "normalization": [],
        "parent": 291,
        "gender": true
    }, {
        "question": 295,
        "id": 295,
        "characteristic": "Total - Income statistics for couple-with-children economic families in private households - 100% data",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 296,
        "id": 296,
        "characteristic": "  Median total income of couple-with-children economic families  in 2020 ($)",
        "normalization": [],
        "parent": 295,
        "gender": true
    }, {
        "question": 297,
        "id": 297,
        "characteristic": "  Median after-tax income of couple-with-children economic families in 2020 ($)",
        "normalization": [],
        "parent": 295,
        "gender": true
    }, {
        "question": 298,
        "id": 298,
        "characteristic": "  Average family size of couple-with-children economic families",
        "normalization": [],
        "parent": 295,
        "gender": true
    }, {
        "question": 299,
        "id": 299,
        "characteristic": "Total - Income statistics for one-parent economic families in private households - 100% data",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 300,
        "id": 300,
        "characteristic": "  Median total income of one-parent economic families in 2020 ($)",
        "normalization": [],
        "parent": 299,
        "gender": true
    }, {
        "question": 301,
        "id": 301,
        "characteristic": "  Median after-tax income of one-parent economic families in 2020 ($)",
        "normalization": [],
        "parent": 299,
        "gender": true
    }, {
        "question": 302,
        "id": 302,
        "characteristic": "  Average family size of one-parent economic families",
        "normalization": [],
        "parent": 299,
        "gender": true
    }, {
        "question": 303,
        "id": 303,
        "characteristic": "Total - Income statistics for persons aged 15 years and over not in economic families in private households - 100% data (23)",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 304,
        "id": 304,
        "characteristic": "  Median total income in 2020 ($) (24)",
        "normalization": [],
        "parent": 303,
        "gender": true
    }, {
        "question": 305,
        "id": 305,
        "characteristic": "  Median after-tax income in 2020 ($) (24)",
        "normalization": [],
        "parent": 303,
        "gender": true
    }, {
        "question": 306,
        "id": 306,
        "characteristic": "Total - Income statistics for economic families in private households - 25% sample data (25)",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 307,
        "id": 307,
        "characteristic": "  Average total income of economic family in 2020 ($)",
        "normalization": [],
        "parent": 306,
        "gender": true
    }, {
        "question": 308,
        "id": 308,
        "characteristic": "  Average after-tax income of economic family in 2020 ($)",
        "normalization": [],
        "parent": 306,
        "gender": true
    }, {
        "question": 309,
        "id": 309,
        "characteristic": "Total - Income statistics for couple-only economic families in private households - 25% sample data",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 310,
        "id": 310,
        "characteristic": "  Average total income of couple-only economic families in 2020 ($)",
        "normalization": [],
        "parent": 309,
        "gender": true
    }, {
        "question": 311,
        "id": 311,
        "characteristic": "  Average after-tax income of couple-only economic families in 2020 ($)",
        "normalization": [],
        "parent": 309,
        "gender": true
    }, {
        "question": 312,
        "id": 312,
        "characteristic": "Total - Income statistics for couple-with-children economic families in private households - 25% sample data",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 313,
        "id": 313,
        "characteristic": "  Average total income of couple-with-children economic families in 2020 ($)",
        "normalization": [],
        "parent": 312,
        "gender": true
    }, {
        "question": 314,
        "id": 314,
        "characteristic": "  Average after-tax income of couple-with-children economic families in 2020 ($)",
        "normalization": [],
        "parent": 312,
        "gender": true
    }, {
        "question": 315,
        "id": 315,
        "characteristic": "Total - Income statistics for one-parent economic families in private households - 25% sample data",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 316,
        "id": 316,
        "characteristic": "  Average total income of one-parent economic families in 2020 ($)",
        "normalization": [],
        "parent": 315,
        "gender": true
    }, {
        "question": 317,
        "id": 317,
        "characteristic": "  Average after-tax income of one-parent economic families in 2020 ($)",
        "normalization": [],
        "parent": 315,
        "gender": true
    }, {
        "question": 318,
        "id": 318,
        "characteristic": "Total - Income statistics for persons aged 15 years and over not in economic families in private households - 25% sample data (26)",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 319,
        "id": 319,
        "characteristic": "  Average total income in 2020 ($) (24)",
        "normalization": [],
        "parent": 318,
        "gender": true
    }, {
        "question": 320,
        "id": 320,
        "characteristic": "  Average after-tax income in 2020 ($) (24)",
        "normalization": [],
        "parent": 318,
        "gender": true
    }, {
        "question": 321,
        "id": 321,
        "characteristic": "Total - LIM low-income status in 2020 for the population in private households - 100% data (27)",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 322,
        "id": 322,
        "characteristic": "  0 to 17 years",
        "normalization": [],
        "parent": 321,
        "gender": true
    }, {
        "question": 323,
        "id": 323,
        "characteristic": "    0 to 5 years",
        "normalization": [],
        "parent": 322,
        "gender": true
    }, {
        "question": 324,
        "id": 324,
        "characteristic": "  18 to 64 years",
        "normalization": [],
        "parent": 321,
        "gender": true
    }, {
        "question": 325,
        "id": 325,
        "characteristic": "  65 years and over",
        "normalization": [],
        "parent": 321,
        "gender": true
    }, {
        "question": 326,
        "id": 326,
        "characteristic": "In low income based on the Low-income measure, after tax (LIM-AT)",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 327,
        "id": 327,
        "characteristic": "  0 to 17 years",
        "normalization": [],
        "parent": 326,
        "gender": true
    }, {
        "question": 328,
        "id": 328,
        "characteristic": "    0 to 5 years",
        "normalization": [],
        "parent": 327,
        "gender": true
    }, {
        "question": 329,
        "id": 329,
        "characteristic": "  18 to 64 years",
        "normalization": [],
        "parent": 326,
        "gender": true
    }, {
        "question": 330,
        "id": 330,
        "characteristic": "  65 years and over",
        "normalization": [],
        "parent": 326,
        "gender": true
    }, {
        "question": 331,
        "id": 331,
        "characteristic": "Prevalence of low income based on the Low-income measure, after tax (LIM-AT) (%)",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 332,
        "id": 332,
        "characteristic": "  0 to 17 years (%)",
        "normalization": [],
        "parent": 331,
        "gender": true
    }, {
        "question": 333,
        "id": 333,
        "characteristic": "    0 to 5 years (%)",
        "normalization": [],
        "parent": 332,
        "gender": true
    }, {
        "question": 334,
        "id": 334,
        "characteristic": "  18 to 64 years (%)",
        "normalization": [],
        "parent": 331,
        "gender": true
    }, {
        "question": 335,
        "id": 335,
        "characteristic": "  65 years and over (%)",
        "normalization": [],
        "parent": 331,
        "gender": true
    }, {
        "question": 336,
        "id": 336,
        "characteristic": "Total - LICO low-income status in 2020 for the population in private households to whom the low-income concept is applicable - 100% data (27)",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 337,
        "id": 337,
        "characteristic": "  0 to 17 years",
        "normalization": [],
        "parent": 336,
        "gender": true
    }, {
        "question": 338,
        "id": 338,
        "characteristic": "    0 to 5 years",
        "normalization": [],
        "parent": 337,
        "gender": true
    }, {
        "question": 339,
        "id": 339,
        "characteristic": "  18 to 64 years",
        "normalization": [],
        "parent": 336,
        "gender": true
    }, {
        "question": 340,
        "id": 340,
        "characteristic": "  65 years and over",
        "normalization": [],
        "parent": 336,
        "gender": true
    }, {
        "question": 341,
        "id": 341,
        "characteristic": "In low income based on the Low-income cut-offs, after tax (LICO-AT)",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 342,
        "id": 342,
        "characteristic": "  0 to 17 years",
        "normalization": [],
        "parent": 341,
        "gender": true
    }, {
        "question": 343,
        "id": 343,
        "characteristic": "    0 to 5 years",
        "normalization": [],
        "parent": 342,
        "gender": true
    }, {
        "question": 344,
        "id": 344,
        "characteristic": "  18 to 64 years",
        "normalization": [],
        "parent": 341,
        "gender": true
    }, {
        "question": 345,
        "id": 345,
        "characteristic": "  65 years and over",
        "normalization": [],
        "parent": 341,
        "gender": true
    }, {
        "question": 346,
        "id": 346,
        "characteristic": "Prevalence of low income based on the Low-income cut-offs, after tax (LICO-AT) (%)",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 347,
        "id": 347,
        "characteristic": "  0 to 17 years (%)",
        "normalization": [],
        "parent": 346,
        "gender": true
    }, {
        "question": 348,
        "id": 348,
        "characteristic": "    0 to 5 years (%)",
        "normalization": [],
        "parent": 347,
        "gender": true
    }, {
        "question": 349,
        "id": 349,
        "characteristic": "  18 to 64 years (%)",
        "normalization": [],
        "parent": 346,
        "gender": true
    }, {
        "question": 350,
        "id": 350,
        "characteristic": "  65 years and over (%)",
        "normalization": [],
        "parent": 346,
        "gender": true
    }, {
        "question": 351,
        "id": 351,
        "characteristic": "Total - Adjusted after-tax family income decile group for the population in private households - 100% data (28)",
        "normalization": [0, 7],
        "parent": 0,
        "gender": true
    }, {
        "question": 352,
        "id": 352,
        "characteristic": "  In bottom half of the distribution",
        "normalization": [351, 7],
        "parent": 351,
        "gender": true
    }, {
        "question": 353,
        "id": 353,
        "characteristic": "    In bottom decile",
        "normalization": [351, 7],
        "parent": 352,
        "gender": true
    }, {
        "question": 354,
        "id": 354,
        "characteristic": "    In second decile",
        "normalization": [351, 7],
        "parent": 352,
        "gender": true
    }, {
        "question": 355,
        "id": 355,
        "characteristic": "    In third decile",
        "normalization": [351, 7],
        "parent": 352,
        "gender": true
    }, {
        "question": 356,
        "id": 356,
        "characteristic": "    In fourth decile",
        "normalization": [351, 7],
        "parent": 352,
        "gender": true
    }, {
        "question": 357,
        "id": 357,
        "characteristic": "    In fifth decile",
        "normalization": [351, 7],
        "parent": 352,
        "gender": true
    }, {
        "question": 358,
        "id": 358,
        "characteristic": "  In top half of the distribution",
        "normalization": [351, 7],
        "parent": 351,
        "gender": true
    }, {
        "question": 359,
        "id": 359,
        "characteristic": "    In sixth decile",
        "normalization": [351, 7],
        "parent": 358,
        "gender": true
    }, {
        "question": 360,
        "id": 360,
        "characteristic": "    In seventh decile",
        "normalization": [351, 7],
        "parent": 358,
        "gender": true
    }, {
        "question": 361,
        "id": 361,
        "characteristic": "    In eighth decile",
        "normalization": [351, 7],
        "parent": 358,
        "gender": true
    }, {
        "question": 362,
        "id": 362,
        "characteristic": "    In ninth decile",
        "normalization": [351, 7],
        "parent": 358,
        "gender": true
    }, {
        "question": 363,
        "id": 363,
        "characteristic": "    In top decile",
        "normalization": [351, 7],
        "parent": 358,
        "gender": true
    }, {
        "question": 364,
        "id": 364,
        "characteristic": "Total - Inequality measures for the population in private households - 100% data (29)",
        "normalization": [],
        "parent": 0,
        "gender": true
    }, {
        "question": 365,
        "id": 365,
        "characteristic": "  Gini index on adjusted household total income",
        "normalization": [],
        "parent": 364,
        "gender": true
    }, {
        "question": 366,
        "id": 366,
        "characteristic": "  Gini index on adjusted household market income",
        "normalization": [],
        "parent": 364,
        "gender": true
    }, {
        "question": 367,
        "id": 367,
        "characteristic": "  Gini index on adjusted household after-tax income",
        "normalization": [],
        "parent": 364,
        "gender": true
    }, {
        "question": 368,
        "id": 368,
        "characteristic": "  P90/P10 ratio on adjusted household after-tax income",
        "normalization": [],
        "parent": 364,
        "gender": true
    }
];
export default censusQuestionnaire2021;