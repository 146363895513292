import React, {Component} from 'react';
import {Button, Card, Table} from 'react-bootstrap';

export default class LegendPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            characteristic: '',
            das: '',
            feature: {id: '', value: ''},
            gender: false,
            isLegend: false,
            normalizationType: '',
            open: true,
            place: '',
            topic: '',
            view: {},
        };
        this.myColumns = this.myColumns.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.getTable = this.getTable.bind(this);
    }

    componentDidMount() {
        let myScape,
            myState,
            myView,
            myLayer,
            that = this;
        document.addEventListener('socioscapes.dataSelectionUpdated',
            function(e) {
                myView = App.viewMenu ? App.viewMenu.meta.thisObject : e.detail;
                myState = myView.meta.schema.parent;
                myScape = myState.meta.schema.parent;
                myLayer = socioscapes.fn.fetchFromScape(myView.config.layer,
                    'name', myState.layer);
                that.setState({
                    characteristic: myView.config.dataSelectionStates.characteristic,
                    das: +myLayer.data.meta.totalRows,
                    gender: myView.config.dataSelectionStates.gender ===
                    'everyone' ?
                        false :
                        myView.config.dataSelectionStates.gender,
                    isLegend: true,
                    normalizationType: myView.config.dataSelectionStates.normalizationType,
                    place: myView.config.dataSelectionStates.cmaTitle,
                    topic: myView.config.dataSelectionStates.topic,
                    view: myView,
                });
            });
        document.addEventListener('socioscapes.updateFeatureHover',
            function(e) {
                that.setState({feature: e.detail});
            });
    }

    myColumns() {
        let myColours = [],
            myColumns = [],
            myChroma;
        if (this.state.view && this.state.view.meta) {
            myChroma = socioscapes.fn.chroma.scale(
                this.state.view.config.colourScale).
                mode('lab').
                classes(this.state.view.config.classes).
                out('hex');
            for (let i = 0; i < this.state.view.config.classes.length -
            1; i++) {
                myColours.push(myChroma(this.state.view.config.classes[i]));
                myColumns.push({
                    name: +this.state.view.config.classes[i],
                    colour: myColours[i],
                });
            }
        }
        return myColumns;
    }

    handleClick() {
        this.setState({open: !this.state.open});
    }

    getTable() {
        let that = this;
        return (
            <>
                <p className="fs-5">{this.state.place}</p>
                <div className="d-flex col-12 overflow-scroll">
                    {that.myColumns().map(function(column, index) {
                        if (that.state.normalizationType === 'ratio') {
                            column.name *= 100;
                        }
                        return <div
                            key={`${column.name}-${index}`}
                            className="p-1 col d-flex justify-content-center p-1"
                            style={{backgroundColor: column.colour}}>
                            <div
                                className="fw-bold small font-monospace text-light align-self-center p-1"
                                style={{backgroundColor: 'rgba(255, 255, 255, 0.5)'}}>{'>'}{column.name.toFixed(
                                2)}</div>
                        </div>;
                    })}
                </div>
            </>
        );
    }

    render() {
        let that = this,
            myTable = this.getTable(),
            mySymbol = '('+that.state.normalizationType+')',
            myLegend;
        if (this.state.isLegend) {
            myLegend = (
                <div className="col-12">
                    <Card className="shadow-lg">
                        <Card.Header>
                            <Button className="bg-transparent border-0 my-2"
                                    onClick={this.handleClick}
                                    title="Toggle Legend"
                            >
                                {this.state.open ?
                                    <i className="bi bi-chevron-compact-down"/> :
                                    <i className="bi bi-chevron-compact-up"/>}
                                &nbsp;&nbsp;{this.state.characteristic}&nbsp;&nbsp;
                                {this.state.feature.id ?
                                    this.state.normalizationType === 'ratio' ?
                                        (isNaN(
                                            parseFloat(
                                                this.state.feature.value) *
                                            100)) ?
                                            '0' + ' ' + mySymbol :
                                            parseFloat(
                                                (this.state.feature.value) *
                                                100).
                                                toFixed(2) + ' ' + mySymbol :
                                        parseFloat(this.state.feature.value).
                                            toFixed(0) + ' ' + mySymbol :
                                    ''}
                            </Button>
                        </Card.Header>
                        <Card.Body variant="primary"
                                   className={this.state.open ? '' : 'd-none'}
                                   collapsible="1">
                        >
                            {myTable}
                            <span>{this.state.das} dissemination areas {this.state.gender !==
                            'total' ?
                                ' (only ' + this.state.gender +
                                '-identified respondants)' :
                                ''}</span><br/>
                        </Card.Body>
                    </Card>
                </div>
            );
        } else {
            myLegend = <></>;
        }
        return myLegend;
    }
}