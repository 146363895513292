import React, {Component} from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';
import {point, booleanPointInPolygon} from '@turf/turf'

import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card,
    Form,
    OverlayTrigger,
    ProgressBar,
    Tooltip,
} from 'react-bootstrap';
import censusQuestionnaire2011 from './data/censusQuestionnaire2011';
import censusGeography2011 from './data/censusGeography2011';
import censusQuestionnaire2016 from './data/censusQuestionnaire2016';
import censusGeography2016 from './data/censusGeography2016';
import censusQuestionnaire2021 from './data/censusQuestionnaire2021';
import censusGeography2021 from './data/censusGeography2021';

const count = (props) => (
    <Tooltip {...props}>
        <h6>Population count: </h6>
        <p className="text-justify">Select this option if you're interested in
            seeing areas with the most or least <em>number of people</em> that
            match your criteria. The unit of your results will
            be <strong>people</strong>.</p>
    </Tooltip>
);

const density = (props) => (
    <Tooltip {...props}>
        <h6>Population density: </h6>
        <p className="text-justify">Select this option if you're interested in
            seeing areas with the highest or lowest <em>concentration of
                people</em> that match your criteria. The unit of your results
            will be <strong>people per km<sup>2</sup></strong>.</p>
    </Tooltip>
);

const ratio = (props) => (
    <Tooltip {...props}>
        <h6>Population ratio: </h6>
        <p className="text-justify">Select this option if you're interested in
            seeing areas with the highest or lowest <em>ratio of
                people</em> that match your criteria. The unit of your results
            will be <strong>people as a percentage of the population</strong>.
        </p>
    </Tooltip>
);

export default class DataSelection extends Component {
    constructor(props) {
        super(props);
        this.maleRef = React.createRef();
        this.femaleRef = React.createRef();
        this.totalRef = React.createRef();
        this.topicRef = React.createRef();
        this.characteristicRef = React.createRef();
        this.refMap = new WeakMap();
        this.refMap.set(this.maleRef, 'male');
        this.refMap.set(this.femaleRef, 'female');
        this.refMap.set(this.totalRef, 'total');
        this.refMap.set(this.topicRef, 'topic');
        this.refMap.set(this.characteristicRef, 'characteristic');
        this.state = {
            censusTitle: 'Pick a census year and question...',
            cmaTitle: 'Vancouver (Vancouver area)',
            topic: 'Population and dwelling counts',
            currentGeom: {},
            characteristic: 'Population in 2021',
            censusQuestionnaire: censusQuestionnaire2021,
            censusGeography: censusGeography2021,
            gender: 'total',
            normalizationType: 'density',
            normalizationQuestion: 7,
            question: 1,
            topicQuestion: 1,
            cmaname: 'Vancouver',
            cmauid: '933',
            csdname: 'Vancouver',
            csduid: '5915022',
            isLoading: false,
            scape: '',
            state: '',
            year: 2021,
            layer: '',
            view: '',
            selectBoxes: {
                2011: [
                    {ref: this.topicRef, value: 'topicQuestion'},
                    {ref: this.characteristicRef, value: 'question'},
                ],
                2016: [
                    {ref: this.characteristicRef, value: 'question'},
                ],
                2021: [
                    {ref: this.characteristicRef, value: 'question'},
                ],
            },
        };
        this.selectOptions = this.selectOptions.bind(this);
        this.setYear = this.setYear.bind(this);
        this.handlePlace = this.handlePlace.bind(this);
        this.handleNormalization = this.handleNormalization.bind(this);
        this.handleGender = this.handleGender.bind(this);
        this.handleCensusQuestionsSelectBox = this.handleCensusQuestionsSelectBox.bind(
            this);
        this.handleLoadingButton = this.handleLoadingButton.bind(this);
        this.updateSelectedQuestion = this.updateSelectedQuestion.bind(this);
    }

    componentDidMount() {
        let that = this;
        document.addEventListener('socioscapes.scapeUpdated', function (e) {
            that.setState(function () {
                return {
                    scape: e.detail,
                    state: that.state.state || e.detail.state[0],
                    layer: that.state.layer || e.detail.state[0].layer[0],
                    view: that.state.view || e.detail.state[0].view[0],
                };
            });
        });
        document.addEventListener('socioscapes.stateUpdated', function (e) {
            that.setState(function () {
                return {
                    state: e.detail,
                    layer: that.state.layer || e.detail.layer[0],
                    view: that.state.view || e.detail.view[0],
                };
            });
        });
        document.addEventListener('socioscapes.layerUpdated', function (e) {
            that.setState(function () {
                return {
                    layer: e.detail,
                };
            });
        });
        document.addEventListener('socioscapes.viewUpdated', function (e) {
            that.setState(function () {
                return {
                    view: e.detail,
                };
            });
        });
        document.addEventListener('socioscapes.googleMapMoved', function (e) {
            if (!that.state.isLoading) {
                if (Object.keys(that.state.currentGeom).length) {
                    let pt = point([e.detail.lng, e.detail.lat], {
                        name: 'EPSG:4326'
                    });
                    if (booleanPointInPolygon(pt, that.state.currentGeom)){
                        return;
                    }
                }
                that.setState({isLoading: true});
                fetch(
                    'https://' + window.location.host + '/api/census/geoLookupFromLatLng',
                    {
                        method: 'POST',
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                        },
                        body: new URLSearchParams({
                            geoLevel: 'csduid',
                            lat: String(e.detail.lat),
                            lng: String(e.detail.lng),
                        }),
                    }).then(response => {
                    response.json().then(jsonResponse => {
                        if (jsonResponse.geojson && jsonResponse.csduid) {
                            that.setState({currentGeom: jsonResponse.geojson});
                            let updateCsd = that.state.censusGeography.find(function (geography) {
                                return geography.csduid === jsonResponse.csduid
                            })
                            if (that.state.csduid !== updateCsd.csduid) {
                                that.handlePlace([updateCsd], true);
                            }
                        }
                    });
                }).finally(function () {
                    that.setState({isLoading: false});
                });
            }
        });
    }

    selectOptions(ref) {
        let myTopics = [],
            myOptions = [],
            that = this;
        if (ref === this.topicRef) {
            this.state.censusQuestionnaire.forEach(function (question) {
                if (question) {
                    if (myTopics.indexOf(question.topic) === -1) {
                        myOptions.push({
                            value: question.question,
                            label: question.topic,
                        });
                        myTopics.push(question.topic);
                    }
                }
            });
        } else {
            this.state.censusQuestionnaire.forEach(function (question) {
                if (question) {
                    if (2011 === that.state.year) {
                        if (question.topic === that.state.topic) {
                            myOptions.push({
                                value: parseInt(question.question),
                                label: question.characteristic.replace(/ /g,
                                    '\xa0'),
                            });
                        }
                    }
                    if ((2016 === that.state.year) || (2021 === that.state.year)) {
                        myOptions.push({
                            value: parseInt(question.question),
                            label: question.characteristic.replace(/ /g,
                                '\xa0'),
                        });
                    }
                }
            });
        }
        return myOptions;
    }

    handlePlace(option, noFetch = false) {
        option = option[0];
        let myView = this.props.viewRef.object;
        this.setState({
            csdname: option.csdname,
            csduid: option.csduid,
            cmaname: option.cmaname,
            cmauid: option.cmauid,
            prename: option.prename,
            cmaTitle: option.csdname + ' (' + option.cmaname.split('(')[0] +
                ' area)',
        });
        if (noFetch) {
            return;
        }
        fetch(
            'https://' + window.location.host + '/api/census/geoLocation',
            {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: new URLSearchParams({
                    geographyLevel: 'csduid',
                    geographyId: option.csduid,
                }),
            }).then(response => {
            response.json().then(jsonResponse => {
                myView.gmap.mapBase.setCenter({
                    lat: jsonResponse['coordinates'][1],
                    lng: jsonResponse['coordinates'][0],
                });
            });
        });
    }

    handleNormalization(normalization) {
        let updateValue;
        if (normalization === 'count') {
            updateValue = 0;
        } else {
            if (normalization === 'ratio') {
                updateValue = this.state.censusQuestionnaire[this.state.question].normalization[0];
            }
            if (normalization === 'density') {
                updateValue = this.state.censusQuestionnaire[this.state.question].normalization[1];
            }
        }
        this.setState({
            normalizationQuestion: parseInt(updateValue),
            normalizationType: normalization,
        });
    }

    handleGender(gender) {
        this.setState({gender: gender});
    }

    handleCensusQuestionsSelectBox(event) {
        let selectBoxRef = event.target.dataset.ref;
        let selectBoxValue = event.target.selectedOptions[0].value;
        this.updateSelectedQuestion(selectBoxRef, selectBoxValue);
    }

    updateSelectedQuestion(selectBoxRef, selectBoxValue) {
        let selectBoxQuestion = this.state.censusQuestionnaire[selectBoxValue],
            that = this,
            myUpdate = {},
            topicQuestion;
        if (this.state.year === 2011) {
            Array.from(this.topicRef.current.options).forEach(function (item) {
                if (item.title === selectBoxQuestion.topic) {
                    topicQuestion = item;
                }
            });
            myUpdate = {'topicQuestion': topicQuestion.value};
        }
        this.setState(function () {
            myUpdate[selectBoxRef] = selectBoxQuestion[selectBoxRef];
            myUpdate.question = parseInt(selectBoxQuestion.question);
            if (that.state.gender !== 'total' && !selectBoxQuestion.gender) {
                myUpdate.gender = 'total';
            }
            if (that.state.normalizationType === 'ratio' &&
                (selectBoxQuestion.normalization[0] === 0)) {
                myUpdate.normalizationType = 'count';
            }
            if (that.state.normalizationType === 'density' &&
                (typeof selectBoxQuestion.normalization[1] === 'undefined')) {
                myUpdate.normalizationType = 'count';
            }
            return myUpdate;
        });
    }

    async handleLoadingButton() {
        this.setState({isLoading: true});
        let wfsLocation = 'https://' + window.location.host +
                '/api/census/geoJson',
            that = this,
            myView = this.props.viewRef.object,
            myViewMenu = socioscapes.fn.newScapeMenu(
                this.props.viewRef.object, socioscapes.fn),
            myLayerMenu = socioscapes.fn.newScapeMenu(
                this.props.layerRef.object, socioscapes.fn),
            myGmap = myViewMenu ? myViewMenu.meta.thisObject.gmap : null,
            totalColumn = this.state.gender,
            normalizationQuestionObject = this.state.censusQuestionnaire.find(
                element => +element.question ===
                    this.state.normalizationQuestion),
            questionObject = this.state.censusQuestionnaire.find(
                element => +element.question === this.state.question);
        fetch(
            wfsLocation,
            {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: new URLSearchParams({
                    year: this.state.year,
                    type: 'both',
                    parentLevel: 'csduid',
                    parentId: this.state.csduid,
                    childLevel: 'dauid',
                    question: questionObject.question,
                    normalization: normalizationQuestionObject?.question,
                    totalColumn: totalColumn,
                }),
            }).then(response => {
            response.json().then(jsonResponse => {
                if (!jsonResponse.error) {
                    myView.config.classification = this.props.tab.refs.FineTuning.state.classification;
                    myView.config.colourScale = this.props.tab.refs.FineTuning.state.colourScale;
                    myView.config.breaks = this.props.tab.refs.FineTuning.state.breaks;
                    if (myGmap && myGmap.mapSymbology) {
                        myGmap.mapSymbology.dataLayer.forEach(
                            function (feature) {
                                myGmap.mapSymbology.dataLayer.remove(feature);
                            });
                    }
                    myViewMenu.meta.thisObject.config.address = this.state.csdname +
                        ', ' + this.state.prename;
                    myViewMenu.meta.thisObject.config.dataSelectionStates = that.state;
                    if (myLayerMenu?.data) {
                        myLayerMenu?.data('rawData', jsonResponse.data).geom('rawGeom', jsonResponse.geom, function () {
                            myViewMenu.config('gview', function () {
                                socioscapes.fn.newEvent(
                                    'socioscapes.dataSelectionUpdated',
                                    myViewMenu.meta.thisObject);
                                that.setState({isLoading: false});
                            });
                        });
                    } else {
                        that.setState({isLoading: false});
                    }
                }
            });
        });
    }

    setYear(event) {
        let year = +event.target.dataset.year;
        this.updateSelectedQuestion('characteristic', '1');
        let updates = {year: year};
        if (2011 === year) {
            updates.censusQuestionnaire = censusQuestionnaire2011;
            updates.censusGeography = censusGeography2011;
        }
        if (2016 === year) {
            updates.censusQuestionnaire = censusQuestionnaire2016;
            updates.censusGeography = censusGeography2016;
        }
        if (2021 === year) {
            updates.censusQuestionnaire = censusQuestionnaire2021;
            updates.censusGeography = censusGeography2021;
        }
        this.setState(updates);
    }

    render() {
        let that = this,
            selectBoxes = this.state.selectBoxes[this.state.year].map(
                function (mySelect, myIndex) {
                    const SelectNode = function () {
                        return {
                            render() {
                                return (
                                    <option value={this.props.value}
                                            title={this.props.label}>{this.props.label}</option>
                                );
                            },
                        };
                    };
                    SelectNode.prototype = React.Component.prototype;
                    let myArr = that.selectOptions(mySelect.ref);
                    return (
                        <Form.Select
                            onChange={this.handleCensusQuestionsSelectBox}
                            className="my-2"
                            key={myIndex}
                            data-ref={this.refMap.get(mySelect.ref)}
                            ref={mySelect.ref}
                            value={this.state[mySelect.value]}
                            type="select"
                            title={this.refMap.get(mySelect.ref)}
                        >
                            {myArr.map(function (option, index) {
                                return <SelectNode key={index}
                                                   value={option.value}
                                                   label={option.label}/>;
                            })}
                        </Form.Select>
                    );
                }.bind(this));
        return (
            <Card className="col-12 vh-50 d-flex">
                <Card.Body>
                    <h6>{this.state.cmaTitle}</h6>
                    <Typeahead id={`${this.state.cmaTitle}-typeahead`}
                               title="Search"
                               maxResults={3}
                               options={this.state.censusGeography}
                               labelKey={option => option.csdname}
                               paginate={false}
                               placeholder=""
                               className="mb-2"
                               onChange={this.handlePlace}
                    />
                    <h6>{this.state.censusTitle}</h6>
                    <div className="mb-3">
                        <Form
                            onChange={this.setYear}
                        >
                            {Object.keys(that.state.selectBoxes).reverse().map((radio) => (
                                <Form.Check
                                    inline
                                    label={radio}
                                    name="census-year"
                                    type="radio"
                                    data-year={radio}
                                    key={`year-${radio}`}
                                    defaultChecked={that.state.year === +radio}
                                />
                            ))}
                        </Form>
                    </div>
                    {selectBoxes}
                    <ButtonToolbar
                        className="d-flex justify-content-center"
                    >
                        <ButtonGroup vertical
                                     size="sm"
                                     className="text-left">
                            <Button ref={this.maleRef} title="Only men"
                                    onClick={this.handleGender.bind(null,
                                        'male')}
                                    active={(this.state.gender === 'male')}
                                    variant={(this.state.gender === 'male') ?
                                        'primary' :
                                        'link'}
                                    disabled={!this.state.censusQuestionnaire[this.state.question].gender}>men</Button>
                            <Button ref={this.totalRef} title="Everyone"
                                    onClick={this.handleGender.bind(null,
                                        'total')}
                                    active={(this.state.gender === 'total')}
                                    variant={(this.state.gender === 'total') ?
                                        'primary' :
                                        'link'}>everyone</Button>
                            <Button ref={this.femaleRef} title="Only women"
                                    onClick={this.handleGender.bind(null,
                                        'female')}
                                    active={(this.state.gender === 'female')}
                                    variant={(this.state.gender === 'female') ?
                                        'primary' :
                                        'link'}
                                    disabled={!this.state.censusQuestionnaire[this.state.question].gender}>women</Button>
                        </ButtonGroup>
                        <ButtonGroup vertical
                                     className="text-left col-xs-6 col-md-offset-4 col-md-4">
                            <OverlayTrigger overlay={ratio}
                                            placement="top"
                                            title="Population proportion"
                            >
                                <Button title="Population proportion"
                                        onClick={this.handleNormalization.bind(
                                            null,
                                            'ratio')}
                                        active={(this.state.normalizationType ===
                                            'ratio')}
                                        variant={(this.state.normalizationType ===
                                            'ratio') ? 'primary' : 'link'}
                                        disabled={!this.state.censusQuestionnaire[this.state.question].normalization[0]}>ratio</Button>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={density}
                                            placement="top"
                                            title="Population density"
                            >
                                <Button title="Population density"
                                        onClick={this.handleNormalization.bind(
                                            null,
                                            'density')}
                                        active={(this.state.normalizationType ===
                                            'density')}
                                        variant={(this.state.normalizationType ===
                                            'density') ? 'primary' : 'link'}
                                        disabled={!this.state.censusQuestionnaire[this.state.question].normalization[1]}>density</Button>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={count}
                                            placement="top"
                                            title="Population count"
                            >
                                <Button title="Population count"
                                        onClick={this.handleNormalization.bind(
                                            null,
                                            'count')}
                                        active={(this.state.normalizationType ===
                                            'count')}
                                        variant={(this.state.normalizationType ===
                                            'count') ?
                                            'primary' :
                                            'link'}>count</Button>
                            </OverlayTrigger>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Card.Body>
                <Card.Footer>
                    <Button title="Show map"
                            className="btn-lg col-12 mb-2"
                            variant={this.state.isLoading ? 'link' :
                                (this.state.cmaTitle === 'Find a place...' ?
                                    'default' :
                                    'primary')}
                            disabled={this.state.isLoading ||
                                (this.state.cmaTitle === 'Find a place...')}
                            onClick={this.state.isLoading ?
                                null :
                                this.handleLoadingButton}>
                        {this.state.isLoading ?
                            <ProgressBar
                                striped
                                active={this.state.isLoading.toString()}
                                className="primary"
                                now={100}
                            /> :
                            'Show Me!'}
                    </Button>
                </Card.Footer>
            </Card>
        );
    }
}
