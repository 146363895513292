const censusQuestionnaire2016=[false,
    {"question":1,"id":1,"characteristic":"Population, 2016 (1)","normalization":[0,7],"parent":0,"gender":false},
    {"question":2,"id":2,"characteristic":"Population, 2011 (2)","normalization":[0],"parent":0,"gender":false},
    {"question":3,"id":3,"characteristic":"Population percentage change, 2011 to 2016","normalization":[0],"parent":0,"gender":false},
    {"question":4,"id":4,"characteristic":"Total private dwellings (3)","normalization":[1,7],"parent":0,"gender":false},
    {"question":5,"id":5,"characteristic":"Private dwellings occupied by usual residents (4)","normalization":[1,7],"parent":0,"gender":false},
    {"question":6,"id":6,"characteristic":"Population density per square kilometre","normalization":[0],"parent":0,"gender":false},
    {"question":7,"id":7,"characteristic":"Land area in square kilometres","normalization":[0],"parent":0,"gender":false},
    {"question":8,"id":8,"characteristic":"Total - Age groups and average age of the population - 100% data (5)","normalization":[0,7],"parent":0,"gender":true},
    {"question":9,"id":9,"characteristic":"  0 to 14 years","normalization":[8,7],"parent":8,"gender":true},
    {"question":10,"id":10,"characteristic":"    0 to 4 years","normalization":[8,7],"parent":9,"gender":true},
    {"question":11,"id":11,"characteristic":"    5 to 9 years","normalization":[8,7],"parent":9,"gender":true},
    {"question":12,"id":12,"characteristic":"    10 to 14 years","normalization":[8,7],"parent":9,"gender":true},
    {"question":13,"id":13,"characteristic":"  15 to 64 years","normalization":[8,7],"parent":8,"gender":true},
    {"question":14,"id":14,"characteristic":"    15 to 19 years","normalization":[8,7],"parent":13,"gender":true},
    {"question":15,"id":15,"characteristic":"    20 to 24 years","normalization":[8,7],"parent":13,"gender":true},
    {"question":16,"id":16,"characteristic":"    25 to 29 years","normalization":[8,7],"parent":13,"gender":true},
    {"question":17,"id":17,"characteristic":"    30 to 34 years","normalization":[8,7],"parent":13,"gender":true},
    {"question":18,"id":18,"characteristic":"    35 to 39 years","normalization":[8,7],"parent":13,"gender":true},
    {"question":19,"id":19,"characteristic":"    40 to 44 years","normalization":[8,7],"parent":13,"gender":true},
    {"question":20,"id":20,"characteristic":"    45 to 49 years","normalization":[8,7],"parent":13,"gender":true},
    {"question":21,"id":21,"characteristic":"    50 to 54 years","normalization":[8,7],"parent":13,"gender":true},
    {"question":22,"id":22,"characteristic":"    55 to 59 years","normalization":[8,7],"parent":13,"gender":true},
    {"question":23,"id":23,"characteristic":"    60 to 64 years","normalization":[8,7],"parent":13,"gender":true},
    {"question":24,"id":24,"characteristic":"  65 years and over","normalization":[8,7],"parent":8,"gender":true},
    {"question":25,"id":25,"characteristic":"    65 to 69 years","normalization":[8,7],"parent":24,"gender":true},
    {"question":26,"id":26,"characteristic":"    70 to 74 years","normalization":[8,7],"parent":24,"gender":true},
    {"question":27,"id":27,"characteristic":"    75 to 79 years","normalization":[8,7],"parent":24,"gender":true},
    {"question":28,"id":28,"characteristic":"    80 to 84 years","normalization":[8,7],"parent":24,"gender":true},
    {"question":29,"id":29,"characteristic":"    85 years and over","normalization":[8,7],"parent":24,"gender":true},
    {"question":30,"id":30,"characteristic":"      85 to 89 years","normalization":[8,7],"parent":29,"gender":true},
    {"question":31,"id":31,"characteristic":"      90 to 94 years","normalization":[8,7],"parent":29,"gender":true},
    {"question":32,"id":32,"characteristic":"      95 to 99 years","normalization":[8,7],"parent":29,"gender":true},
    {"question":33,"id":33,"characteristic":"      100 years and over","normalization":[8,7],"parent":29,"gender":true},
    {"question":34,"id":34,"characteristic":"Total - Distribution (%) of the population by broad age groups - 100% data","normalization":[],"parent":0,"gender":true},
    {"question":35,"id":35,"characteristic":"  0 to 14 years","normalization":[],"parent":34,"gender":true},
    {"question":36,"id":36,"characteristic":"  15 to 64 years","normalization":[],"parent":34,"gender":true},
    {"question":37,"id":37,"characteristic":"  65 years and over","normalization":[],"parent":34,"gender":true},
    {"question":38,"id":38,"characteristic":"    85 years and over","normalization":[],"parent":37,"gender":true},
    {"question":39,"id":39,"characteristic":"Average age of the population","normalization":[],"parent":0,"gender":true},
    {"question":40,"id":40,"characteristic":"Median age of the population (6)","normalization":[],"parent":0,"gender":true},
    {"question":41,"id":41,"characteristic":"Total - Occupied private dwellings by structural type of dwelling - 100% data (7)","normalization":[],"parent":0,"gender":true},
    {"question":42,"id":42,"characteristic":"  Single-detached house","normalization":[41,7],"parent":41,"gender":false},
    {"question":43,"id":43,"characteristic":"  Apartment in a building that has five or more storeys","normalization":[41,7],"parent":41,"gender":false},
    {"question":44,"id":44,"characteristic":"  Other attached dwelling (8)","normalization":[41,7],"parent":41,"gender":false},
    {"question":45,"id":45,"characteristic":"    Semi-detached house","normalization":[41,7],"parent":44,"gender":false},
    {"question":46,"id":46,"characteristic":"    Row house","normalization":[41,7],"parent":44,"gender":false},
    {"question":47,"id":47,"characteristic":"    Apartment or flat in a duplex","normalization":[41,7],"parent":44,"gender":false},
    {"question":48,"id":48,"characteristic":"    Apartment in a building that has fewer than five storeys","normalization":[41,7],"parent":44,"gender":false},
    {"question":49,"id":49,"characteristic":"    Other single-attached house","normalization":[41,7],"parent":44,"gender":false},
    {"question":50,"id":50,"characteristic":"  Movable dwelling (9)","normalization":[41,7],"parent":41,"gender":false},
    {"question":51,"id":51,"characteristic":"Total - Private households by household size - 100% data (10)","normalization":[1,7],"parent":0,"gender":false},
    {"question":52,"id":52,"characteristic":"  1 person","normalization":[51,7],"parent":51,"gender":false},
    {"question":53,"id":53,"characteristic":"  2 persons","normalization":[51,7],"parent":51,"gender":false},
    {"question":54,"id":54,"characteristic":"  3 persons","normalization":[51,7],"parent":51,"gender":false},
    {"question":55,"id":55,"characteristic":"  4 persons","normalization":[51,7],"parent":51,"gender":false},
    {"question":56,"id":56,"characteristic":"  5 or more persons","normalization":[51,7],"parent":51,"gender":false},
    {"question":57,"id":57,"characteristic":"Number of persons in private households","normalization":[1,7],"parent":0,"gender":false},
    {"question":58,"id":58,"characteristic":"Average household size","normalization":[],"parent":0,"gender":false},
    {"question":59,"id":59,"characteristic":"Total - Marital status for the population aged 15 years and over - 100% data (11)","normalization":[],"parent":0,"gender":false},
    {"question":60,"id":60,"characteristic":"  Married or living common law","normalization":[59,7],"parent":59,"gender":false},
    {"question":61,"id":61,"characteristic":"    Married","normalization":[59,7],"parent":60,"gender":false},
    {"question":62,"id":62,"characteristic":"    Living common law","normalization":[59,7],"parent":60,"gender":false},
    {"question":63,"id":63,"characteristic":"  Not married and not living common law","normalization":[59,7],"parent":59,"gender":false},
    {"question":64,"id":64,"characteristic":"    Never married","normalization":[59,7],"parent":63,"gender":false},
    {"question":65,"id":65,"characteristic":"    Separated","normalization":[59,7],"parent":63,"gender":false},
    {"question":66,"id":66,"characteristic":"    Divorced","normalization":[59,7],"parent":63,"gender":false},
    {"question":67,"id":67,"characteristic":"    Widowed","normalization":[59,7],"parent":63,"gender":false},
    {"question":68,"id":68,"characteristic":"Total - Census families in private households by family size - 100% data (12)","normalization":[1,7],"parent":0,"gender":false},
    {"question":69,"id":69,"characteristic":"  2 persons","normalization":[68,7],"parent":68,"gender":false},
    {"question":70,"id":70,"characteristic":"  3 persons","normalization":[68,7],"parent":68,"gender":false},
    {"question":71,"id":71,"characteristic":"  4 persons","normalization":[68,7],"parent":68,"gender":false},
    {"question":72,"id":72,"characteristic":"  5 or more persons","normalization":[68,7],"parent":68,"gender":false},
    {"question":73,"id":73,"characteristic":"Average size of census families","normalization":[],"parent":0,"gender":false},
    {"question":74,"id":74,"characteristic":"Total number of census families in private households - 100% data","normalization":[1,7],"parent":0,"gender":false},
    {"question":75,"id":75,"characteristic":"  Total couple families","normalization":[74,7],"parent":74,"gender":false},
    {"question":76,"id":76,"characteristic":"    Married couples","normalization":[74,7],"parent":75,"gender":false},
    {"question":77,"id":77,"characteristic":"    Common-law couples","normalization":[74,7],"parent":75,"gender":false},
    {"question":78,"id":78,"characteristic":"  Total lone-parent families by sex of parent","normalization":[74,7],"parent":74,"gender":false},
    {"question":79,"id":79,"characteristic":"    Female parent","normalization":[74,7],"parent":78,"gender":false},
    {"question":80,"id":80,"characteristic":"    Male parent","normalization":[74,7],"parent":78,"gender":false},
    {"question":81,"id":81,"characteristic":"Total - Couple census families in private households - 100% data","normalization":[1,7],"parent":0,"gender":false},
    {"question":82,"id":82,"characteristic":"  Couples without children","normalization":[81,7],"parent":81,"gender":false},
    {"question":83,"id":83,"characteristic":"  Couples with children","normalization":[81,7],"parent":81,"gender":false},
    {"question":84,"id":84,"characteristic":"    1 child","normalization":[81,7],"parent":83,"gender":false},
    {"question":85,"id":85,"characteristic":"    2 children","normalization":[81,7],"parent":83,"gender":false},
    {"question":86,"id":86,"characteristic":"    3 or more children","normalization":[81,7],"parent":83,"gender":false},
    {"question":87,"id":87,"characteristic":"Total - Lone-parent census families in private households - 100% data","normalization":[1,7],"parent":0,"gender":false},
    {"question":88,"id":88,"characteristic":"  1 child","normalization":[87,7],"parent":87,"gender":false},
    {"question":89,"id":89,"characteristic":"  2 children","normalization":[87,7],"parent":87,"gender":false},
    {"question":90,"id":90,"characteristic":"  3 or more children","normalization":[87,7],"parent":87,"gender":false},
    {"question":91,"id":91,"characteristic":"Total - Persons not in census families in private households - 100% data","normalization":[1,7],"parent":0,"gender":true},
    {"question":92,"id":92,"characteristic":"Total - Private households by household type - 100% data (13)","normalization":[1,7],"parent":0,"gender":false},
    {"question":93,"id":93,"characteristic":"  One-census-family households","normalization":[92,7],"parent":92,"gender":false},
    {"question":94,"id":94,"characteristic":"    Without children in a census family (14)","normalization":[92,7],"parent":93,"gender":false},
    {"question":95,"id":95,"characteristic":"    With children in a census family (15)","normalization":[92,7],"parent":93,"gender":false},
    {"question":96,"id":96,"characteristic":"  Multiple-census-family households","normalization":[92,7],"parent":92,"gender":false},
    {"question":97,"id":97,"characteristic":"  Non-census-family households","normalization":[92,7],"parent":92,"gender":false},
    {"question":98,"id":98,"characteristic":"    One-person households","normalization":[92,7],"parent":97,"gender":false},
    {"question":99,"id":99,"characteristic":"    Two-or-more person non-census-family households","normalization":[92,7],"parent":97,"gender":false},
    {"question":100,"id":100,"characteristic":"Total - Knowledge of official languages for the total population excluding institutional residents - 100% data (16)","normalization":[],"parent":0,"gender":true},
    {"question":101,"id":101,"characteristic":"  English only","normalization":[100,7],"parent":100,"gender":true},
    {"question":102,"id":102,"characteristic":"  French only","normalization":[100,7],"parent":100,"gender":true},
    {"question":103,"id":103,"characteristic":"  English and French","normalization":[100,7],"parent":100,"gender":true},
    {"question":104,"id":104,"characteristic":"  Neither English nor French","normalization":[100,7],"parent":100,"gender":true},
    {"question":105,"id":105,"characteristic":"Total - First official language spoken for the total population excluding institutional residents - 100% data (18)","normalization":[],"parent":0,"gender":true},
    {"question":106,"id":106,"characteristic":"  English","normalization":[105,7],"parent":105,"gender":true},
    {"question":107,"id":107,"characteristic":"  French","normalization":[105,7],"parent":105,"gender":true},
    {"question":108,"id":108,"characteristic":"  English and French","normalization":[105,7],"parent":105,"gender":true},
    {"question":109,"id":109,"characteristic":"  Neither English nor French","normalization":[105,7],"parent":105,"gender":true},
    {"question":110,"id":110,"characteristic":"Official language minority (number) (20)","normalization":[1,7],"parent":0,"gender":true},
    {"question":111,"id":111,"characteristic":"Official language minority (percentage) (21)","normalization":[],"parent":0,"gender":true},
    {"question":112,"id":112,"characteristic":"Total - Mother tongue for the total population excluding institutional residents - 100% data (22)","normalization":[],"parent":0,"gender":true},
    {"question":113,"id":113,"characteristic":"  Single responses","normalization":[112,7],"parent":112,"gender":true},
    {"question":114,"id":114,"characteristic":"    Official languages","normalization":[112,7],"parent":113,"gender":true},
    {"question":115,"id":115,"characteristic":"      English","normalization":[112,7],"parent":114,"gender":true},
    {"question":116,"id":116,"characteristic":"      French","normalization":[112,7],"parent":114,"gender":true},
    {"question":117,"id":117,"characteristic":"    Non-official languages","normalization":[112,7],"parent":113,"gender":true},
    {"question":118,"id":118,"characteristic":"      Aboriginal languages","normalization":[112,7],"parent":117,"gender":true},
    {"question":119,"id":119,"characteristic":"        Algonquian languages","normalization":[112,7],"parent":118,"gender":true},
    {"question":120,"id":120,"characteristic":"          Blackfoot","normalization":[112,7],"parent":119,"gender":true},
    {"question":121,"id":121,"characteristic":"          Cree-Montagnais languages","normalization":[112,7],"parent":119,"gender":true},
    {"question":122,"id":122,"characteristic":"            Atikamekw","normalization":[112,7],"parent":121,"gender":true},
    {"question":123,"id":123,"characteristic":"            Montagnais (Innu)","normalization":[112,7],"parent":121,"gender":true},
    {"question":124,"id":124,"characteristic":"            Moose Cree","normalization":[112,7],"parent":121,"gender":true},
    {"question":125,"id":125,"characteristic":"            Naskapi","normalization":[112,7],"parent":121,"gender":true},
    {"question":126,"id":126,"characteristic":"            Northern East Cree","normalization":[112,7],"parent":121,"gender":true},
    {"question":127,"id":127,"characteristic":"            Plains Cree","normalization":[112,7],"parent":121,"gender":true},
    {"question":128,"id":128,"characteristic":"            Southern East Cree","normalization":[112,7],"parent":121,"gender":true},
    {"question":129,"id":129,"characteristic":"            Swampy Cree","normalization":[112,7],"parent":121,"gender":true},
    {"question":130,"id":130,"characteristic":"            Woods Cree","normalization":[112,7],"parent":121,"gender":true},
    {"question":131,"id":131,"characteristic":"            Cree, n.o.s.","normalization":[112,7],"parent":121,"gender":true},
    {"question":132,"id":132,"characteristic":"          Eastern Algonquian languages","normalization":[112,7],"parent":119,"gender":true},
    {"question":133,"id":133,"characteristic":"            Malecite","normalization":[112,7],"parent":132,"gender":true},
    {"question":134,"id":134,"characteristic":"            Mi'kmaq","normalization":[112,7],"parent":132,"gender":true},
    {"question":135,"id":135,"characteristic":"          Ojibway-Potawatomi languages","normalization":[112,7],"parent":119,"gender":true},
    {"question":136,"id":136,"characteristic":"            Algonquin","normalization":[112,7],"parent":135,"gender":true},
    {"question":137,"id":137,"characteristic":"            Ojibway","normalization":[112,7],"parent":135,"gender":true},
    {"question":138,"id":138,"characteristic":"            Oji-Cree","normalization":[112,7],"parent":135,"gender":true},
    {"question":139,"id":139,"characteristic":"            Ottawa (Odawa)","normalization":[112,7],"parent":135,"gender":true},
    {"question":140,"id":140,"characteristic":"          Algonquian languages, n.i.e.","normalization":[112,7],"parent":119,"gender":true},
    {"question":141,"id":141,"characteristic":"        Athabaskan languages","normalization":[112,7],"parent":118,"gender":true},
    {"question":142,"id":142,"characteristic":"          Northern Athabaskan languages","normalization":[112,7],"parent":141,"gender":true},
    {"question":143,"id":143,"characteristic":"            Babine (Wetsuwet'en)","normalization":[112,7],"parent":142,"gender":true},
    {"question":144,"id":144,"characteristic":"            Beaver","normalization":[112,7],"parent":142,"gender":true},
    {"question":145,"id":145,"characteristic":"            Carrier","normalization":[112,7],"parent":142,"gender":true},
    {"question":146,"id":146,"characteristic":"            Chilcotin","normalization":[112,7],"parent":142,"gender":true},
    {"question":147,"id":147,"characteristic":"            Dene","normalization":[112,7],"parent":142,"gender":true},
    {"question":148,"id":148,"characteristic":"            Dogrib (Tlicho)","normalization":[112,7],"parent":142,"gender":true},
    {"question":149,"id":149,"characteristic":"            Gwich'in","normalization":[112,7],"parent":142,"gender":true},
    {"question":150,"id":150,"characteristic":"            Sarsi (Sarcee)","normalization":[112,7],"parent":142,"gender":true},
    {"question":151,"id":151,"characteristic":"            Sekani","normalization":[112,7],"parent":142,"gender":true},
    {"question":152,"id":152,"characteristic":"            Slavey-Hare languages","normalization":[112,7],"parent":142,"gender":true},
    {"question":153,"id":153,"characteristic":"              North Slavey (Hare)","normalization":[112,7],"parent":152,"gender":true},
    {"question":154,"id":154,"characteristic":"              South Slavey","normalization":[112,7],"parent":152,"gender":true},
    {"question":155,"id":155,"characteristic":"              Slavey, n.o.s.","normalization":[112,7],"parent":152,"gender":true},
    {"question":156,"id":156,"characteristic":"            Tahltan languages","normalization":[112,7],"parent":142,"gender":true},
    {"question":157,"id":157,"characteristic":"              Kaska (Nahani)","normalization":[112,7],"parent":156,"gender":true},
    {"question":158,"id":158,"characteristic":"              Tahltan","normalization":[112,7],"parent":156,"gender":true},
    {"question":159,"id":159,"characteristic":"            Tutchone languages","normalization":[112,7],"parent":142,"gender":true},
    {"question":160,"id":160,"characteristic":"              Northern Tutchone","normalization":[112,7],"parent":159,"gender":true},
    {"question":161,"id":161,"characteristic":"              Southern Tutchone","normalization":[112,7],"parent":159,"gender":true},
    {"question":162,"id":162,"characteristic":"          Athabaskan languages, n.i.e.","normalization":[112,7],"parent":141,"gender":true},
    {"question":163,"id":163,"characteristic":"        Haida","normalization":[112,7],"parent":118,"gender":true},
    {"question":164,"id":164,"characteristic":"        Inuit languages","normalization":[112,7],"parent":118,"gender":true},
    {"question":165,"id":165,"characteristic":"          Inuinnaqtun (Inuvialuktun)","normalization":[112,7],"parent":164,"gender":true},
    {"question":166,"id":166,"characteristic":"          Inuktitut","normalization":[112,7],"parent":164,"gender":true},
    {"question":167,"id":167,"characteristic":"          Inuit languages, n.i.e.","normalization":[112,7],"parent":164,"gender":true},
    {"question":168,"id":168,"characteristic":"        Iroquoian languages","normalization":[112,7],"parent":118,"gender":true},
    {"question":169,"id":169,"characteristic":"          Cayuga","normalization":[112,7],"parent":168,"gender":true},
    {"question":170,"id":170,"characteristic":"          Mohawk","normalization":[112,7],"parent":168,"gender":true},
    {"question":171,"id":171,"characteristic":"          Oneida","normalization":[112,7],"parent":168,"gender":true},
    {"question":172,"id":172,"characteristic":"          Iroquoian languages, n.i.e.","normalization":[112,7],"parent":168,"gender":true},
    {"question":173,"id":173,"characteristic":"        Kutenai","normalization":[112,7],"parent":118,"gender":true},
    {"question":174,"id":174,"characteristic":"        Michif","normalization":[112,7],"parent":118,"gender":true},
    {"question":175,"id":175,"characteristic":"        Salish languages","normalization":[112,7],"parent":118,"gender":true},
    {"question":176,"id":176,"characteristic":"          Comox","normalization":[112,7],"parent":175,"gender":true},
    {"question":177,"id":177,"characteristic":"          Halkomelem","normalization":[112,7],"parent":175,"gender":true},
    {"question":178,"id":178,"characteristic":"          Lillooet","normalization":[112,7],"parent":175,"gender":true},
    {"question":179,"id":179,"characteristic":"          Okanagan","normalization":[112,7],"parent":175,"gender":true},
    {"question":180,"id":180,"characteristic":"          Shuswap (Secwepemctsin)","normalization":[112,7],"parent":175,"gender":true},
    {"question":181,"id":181,"characteristic":"          Squamish","normalization":[112,7],"parent":175,"gender":true},
    {"question":182,"id":182,"characteristic":"          Straits","normalization":[112,7],"parent":175,"gender":true},
    {"question":183,"id":183,"characteristic":"          Thompson (Ntlakapamux)","normalization":[112,7],"parent":175,"gender":true},
    {"question":184,"id":184,"characteristic":"          Salish languages, n.i.e.","normalization":[112,7],"parent":175,"gender":true},
    {"question":185,"id":185,"characteristic":"        Siouan languages","normalization":[112,7],"parent":118,"gender":true},
    {"question":186,"id":186,"characteristic":"          Dakota","normalization":[112,7],"parent":185,"gender":true},
    {"question":187,"id":187,"characteristic":"          Stoney","normalization":[112,7],"parent":185,"gender":true},
    {"question":188,"id":188,"characteristic":"          Siouan languages, n.i.e.","normalization":[112,7],"parent":185,"gender":true},
    {"question":189,"id":189,"characteristic":"        Tlingit","normalization":[112,7],"parent":118,"gender":true},
    {"question":190,"id":190,"characteristic":"        Tsimshian languages","normalization":[112,7],"parent":118,"gender":true},
    {"question":191,"id":191,"characteristic":"          Gitxsan (Gitksan)","normalization":[112,7],"parent":190,"gender":true},
    {"question":192,"id":192,"characteristic":"          Nisga'a","normalization":[112,7],"parent":190,"gender":true},
    {"question":193,"id":193,"characteristic":"          Tsimshian","normalization":[112,7],"parent":190,"gender":true},
    {"question":194,"id":194,"characteristic":"        Wakashan languages","normalization":[112,7],"parent":118,"gender":true},
    {"question":195,"id":195,"characteristic":"          Haisla","normalization":[112,7],"parent":194,"gender":true},
    {"question":196,"id":196,"characteristic":"          Heiltsuk","normalization":[112,7],"parent":194,"gender":true},
    {"question":197,"id":197,"characteristic":"          Kwakiutl (Kwak'wala)","normalization":[112,7],"parent":194,"gender":true},
    {"question":198,"id":198,"characteristic":"          Nuu-chah-nulth (Nootka)","normalization":[112,7],"parent":194,"gender":true},
    {"question":199,"id":199,"characteristic":"          Wakashan languages, n.i.e.","normalization":[112,7],"parent":194,"gender":true},
    {"question":200,"id":200,"characteristic":"        Aboriginal languages, n.o.s.","normalization":[112,7],"parent":118,"gender":true},
    {"question":201,"id":201,"characteristic":"      Non-Aboriginal languages","normalization":[112,7],"parent":117,"gender":true},
    {"question":202,"id":202,"characteristic":"        Afro-Asiatic languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":203,"id":203,"characteristic":"          Berber languages","normalization":[112,7],"parent":202,"gender":true},
    {"question":204,"id":204,"characteristic":"            Kabyle","normalization":[112,7],"parent":203,"gender":true},
    {"question":205,"id":205,"characteristic":"            Berber languages, n.i.e.","normalization":[112,7],"parent":203,"gender":true},
    {"question":206,"id":206,"characteristic":"          Cushitic languages","normalization":[112,7],"parent":202,"gender":true},
    {"question":207,"id":207,"characteristic":"            Bilen","normalization":[112,7],"parent":206,"gender":true},
    {"question":208,"id":208,"characteristic":"            Oromo","normalization":[112,7],"parent":206,"gender":true},
    {"question":209,"id":209,"characteristic":"            Somali","normalization":[112,7],"parent":206,"gender":true},
    {"question":210,"id":210,"characteristic":"            Cushitic languages, n.i.e.","normalization":[112,7],"parent":206,"gender":true},
    {"question":211,"id":211,"characteristic":"          Semitic languages","normalization":[112,7],"parent":202,"gender":true},
    {"question":212,"id":212,"characteristic":"            Amharic","normalization":[112,7],"parent":211,"gender":true},
    {"question":213,"id":213,"characteristic":"            Arabic","normalization":[112,7],"parent":211,"gender":true},
    {"question":214,"id":214,"characteristic":"            Assyrian Neo-Aramaic","normalization":[112,7],"parent":211,"gender":true},
    {"question":215,"id":215,"characteristic":"            Chaldean Neo-Aramaic","normalization":[112,7],"parent":211,"gender":true},
    {"question":216,"id":216,"characteristic":"            Harari","normalization":[112,7],"parent":211,"gender":true},
    {"question":217,"id":217,"characteristic":"            Hebrew","normalization":[112,7],"parent":211,"gender":true},
    {"question":218,"id":218,"characteristic":"            Maltese","normalization":[112,7],"parent":211,"gender":true},
    {"question":219,"id":219,"characteristic":"            Tigrigna","normalization":[112,7],"parent":211,"gender":true},
    {"question":220,"id":220,"characteristic":"            Semitic languages, n.i.e.","normalization":[112,7],"parent":211,"gender":true},
    {"question":221,"id":221,"characteristic":"          Afro-Asiatic languages, n.i.e.","normalization":[112,7],"parent":202,"gender":true},
    {"question":222,"id":222,"characteristic":"        Austro-Asiatic languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":223,"id":223,"characteristic":"          Khmer (Cambodian)","normalization":[112,7],"parent":222,"gender":true},
    {"question":224,"id":224,"characteristic":"          Vietnamese","normalization":[112,7],"parent":222,"gender":true},
    {"question":225,"id":225,"characteristic":"          Austro-Asiatic languages, n.i.e","normalization":[112,7],"parent":222,"gender":true},
    {"question":226,"id":226,"characteristic":"        Austronesian languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":227,"id":227,"characteristic":"          Bikol","normalization":[112,7],"parent":226,"gender":true},
    {"question":228,"id":228,"characteristic":"          Cebuano","normalization":[112,7],"parent":226,"gender":true},
    {"question":229,"id":229,"characteristic":"          Fijian","normalization":[112,7],"parent":226,"gender":true},
    {"question":230,"id":230,"characteristic":"          Hiligaynon","normalization":[112,7],"parent":226,"gender":true},
    {"question":231,"id":231,"characteristic":"          Ilocano","normalization":[112,7],"parent":226,"gender":true},
    {"question":232,"id":232,"characteristic":"          Malagasy","normalization":[112,7],"parent":226,"gender":true},
    {"question":233,"id":233,"characteristic":"          Malay","normalization":[112,7],"parent":226,"gender":true},
    {"question":234,"id":234,"characteristic":"          Pampangan (Kapampangan, Pampango)","normalization":[112,7],"parent":226,"gender":true},
    {"question":235,"id":235,"characteristic":"          Pangasinan","normalization":[112,7],"parent":226,"gender":true},
    {"question":236,"id":236,"characteristic":"          Tagalog (Pilipino, Filipino)","normalization":[112,7],"parent":226,"gender":true},
    {"question":237,"id":237,"characteristic":"          Waray-Waray","normalization":[112,7],"parent":226,"gender":true},
    {"question":238,"id":238,"characteristic":"          Austronesian languages, n.i.e.","normalization":[112,7],"parent":226,"gender":true},
    {"question":239,"id":239,"characteristic":"        Creole languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":240,"id":240,"characteristic":"          Haitian Creole","normalization":[112,7],"parent":239,"gender":true},
    {"question":241,"id":241,"characteristic":"          Creole, n.o.s.","normalization":[112,7],"parent":239,"gender":true},
    {"question":242,"id":242,"characteristic":"          Creole languages, n.i.e.","normalization":[112,7],"parent":239,"gender":true},
    {"question":243,"id":243,"characteristic":"        Dravidian languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":244,"id":244,"characteristic":"          Kannada","normalization":[112,7],"parent":243,"gender":true},
    {"question":245,"id":245,"characteristic":"          Malayalam","normalization":[112,7],"parent":243,"gender":true},
    {"question":246,"id":246,"characteristic":"          Tamil","normalization":[112,7],"parent":243,"gender":true},
    {"question":247,"id":247,"characteristic":"          Telugu","normalization":[112,7],"parent":243,"gender":true},
    {"question":248,"id":248,"characteristic":"          Dravidian languages, n.i.e.","normalization":[112,7],"parent":243,"gender":true},
    {"question":249,"id":249,"characteristic":"        Hmong-Mien languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":250,"id":250,"characteristic":"        Indo-European languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":251,"id":251,"characteristic":"          Albanian","normalization":[112,7],"parent":250,"gender":true},
    {"question":252,"id":252,"characteristic":"          Armenian","normalization":[112,7],"parent":250,"gender":true},
    {"question":253,"id":253,"characteristic":"          Balto-Slavic languages","normalization":[112,7],"parent":250,"gender":true},
    {"question":254,"id":254,"characteristic":"            Baltic languages","normalization":[112,7],"parent":253,"gender":true},
    {"question":255,"id":255,"characteristic":"              Latvian","normalization":[112,7],"parent":254,"gender":true},
    {"question":256,"id":256,"characteristic":"              Lithuanian","normalization":[112,7],"parent":254,"gender":true},
    {"question":257,"id":257,"characteristic":"            Slavic languages","normalization":[112,7],"parent":253,"gender":true},
    {"question":258,"id":258,"characteristic":"              Belarusan","normalization":[112,7],"parent":257,"gender":true},
    {"question":259,"id":259,"characteristic":"              Bosnian","normalization":[112,7],"parent":257,"gender":true},
    {"question":260,"id":260,"characteristic":"              Bulgarian","normalization":[112,7],"parent":257,"gender":true},
    {"question":261,"id":261,"characteristic":"              Croatian","normalization":[112,7],"parent":257,"gender":true},
    {"question":262,"id":262,"characteristic":"              Czech","normalization":[112,7],"parent":257,"gender":true},
    {"question":263,"id":263,"characteristic":"              Macedonian","normalization":[112,7],"parent":257,"gender":true},
    {"question":264,"id":264,"characteristic":"              Polish","normalization":[112,7],"parent":257,"gender":true},
    {"question":265,"id":265,"characteristic":"              Russian","normalization":[112,7],"parent":257,"gender":true},
    {"question":266,"id":266,"characteristic":"              Serbian","normalization":[112,7],"parent":257,"gender":true},
    {"question":267,"id":267,"characteristic":"              Serbo-Croatian","normalization":[112,7],"parent":257,"gender":true},
    {"question":268,"id":268,"characteristic":"              Slovak","normalization":[112,7],"parent":257,"gender":true},
    {"question":269,"id":269,"characteristic":"              Slovene (Slovenian)","normalization":[112,7],"parent":257,"gender":true},
    {"question":270,"id":270,"characteristic":"              Ukrainian","normalization":[112,7],"parent":257,"gender":true},
    {"question":271,"id":271,"characteristic":"              Slavic languages, n.i.e.","normalization":[112,7],"parent":257,"gender":true},
    {"question":272,"id":272,"characteristic":"          Celtic languages","normalization":[112,7],"parent":253,"gender":true},
    {"question":273,"id":273,"characteristic":"            Scottish Gaelic","normalization":[112,7],"parent":272,"gender":true},
    {"question":274,"id":274,"characteristic":"            Welsh","normalization":[112,7],"parent":272,"gender":true},
    {"question":275,"id":275,"characteristic":"            Celtic languages, n.i.e.","normalization":[112,7],"parent":272,"gender":true},
    {"question":276,"id":276,"characteristic":"          Germanic languages","normalization":[112,7],"parent":253,"gender":true},
    {"question":277,"id":277,"characteristic":"            Afrikaans","normalization":[112,7],"parent":276,"gender":true},
    {"question":278,"id":278,"characteristic":"            Danish","normalization":[112,7],"parent":276,"gender":true},
    {"question":279,"id":279,"characteristic":"            Dutch","normalization":[112,7],"parent":276,"gender":true},
    {"question":280,"id":280,"characteristic":"            Frisian","normalization":[112,7],"parent":276,"gender":true},
    {"question":281,"id":281,"characteristic":"            German","normalization":[112,7],"parent":276,"gender":true},
    {"question":282,"id":282,"characteristic":"            Icelandic","normalization":[112,7],"parent":276,"gender":true},
    {"question":283,"id":283,"characteristic":"            Norwegian","normalization":[112,7],"parent":276,"gender":true},
    {"question":284,"id":284,"characteristic":"            Swedish","normalization":[112,7],"parent":276,"gender":true},
    {"question":285,"id":285,"characteristic":"            Vlaams (Flemish)","normalization":[112,7],"parent":276,"gender":true},
    {"question":286,"id":286,"characteristic":"            Yiddish","normalization":[112,7],"parent":276,"gender":true},
    {"question":287,"id":287,"characteristic":"            Germanic languages, n.i.e.","normalization":[112,7],"parent":276,"gender":true},
    {"question":288,"id":288,"characteristic":"          Greek","normalization":[112,7],"parent":253,"gender":true},
    {"question":289,"id":289,"characteristic":"          Indo-Iranian languages","normalization":[112,7],"parent":253,"gender":true},
    {"question":290,"id":290,"characteristic":"            Indo-Aryan languages","normalization":[112,7],"parent":289,"gender":true},
    {"question":291,"id":291,"characteristic":"              Bengali","normalization":[112,7],"parent":290,"gender":true},
    {"question":292,"id":292,"characteristic":"              Gujarati","normalization":[112,7],"parent":290,"gender":true},
    {"question":293,"id":293,"characteristic":"              Hindi","normalization":[112,7],"parent":290,"gender":true},
    {"question":294,"id":294,"characteristic":"              Kashmiri","normalization":[112,7],"parent":290,"gender":true},
    {"question":295,"id":295,"characteristic":"              Konkani","normalization":[112,7],"parent":290,"gender":true},
    {"question":296,"id":296,"characteristic":"              Marathi","normalization":[112,7],"parent":290,"gender":true},
    {"question":297,"id":297,"characteristic":"              Nepali","normalization":[112,7],"parent":290,"gender":true},
    {"question":298,"id":298,"characteristic":"              Oriya (Odia)","normalization":[112,7],"parent":290,"gender":true},
    {"question":299,"id":299,"characteristic":"              Punjabi (Panjabi)","normalization":[112,7],"parent":290,"gender":true},
    {"question":300,"id":300,"characteristic":"              Sindhi","normalization":[112,7],"parent":290,"gender":true},
    {"question":301,"id":301,"characteristic":"              Sinhala (Sinhalese)","normalization":[112,7],"parent":290,"gender":true},
    {"question":302,"id":302,"characteristic":"              Urdu","normalization":[112,7],"parent":290,"gender":true},
    {"question":303,"id":303,"characteristic":"            Iranian languages","normalization":[112,7],"parent":289,"gender":true},
    {"question":304,"id":304,"characteristic":"              Kurdish","normalization":[112,7],"parent":303,"gender":true},
    {"question":305,"id":305,"characteristic":"              Pashto","normalization":[112,7],"parent":303,"gender":true},
    {"question":306,"id":306,"characteristic":"              Persian (Farsi)","normalization":[112,7],"parent":303,"gender":true},
    {"question":307,"id":307,"characteristic":"            Indo-Iranian languages, n.i.e.","normalization":[112,7],"parent":289,"gender":true},
    {"question":308,"id":308,"characteristic":"          Italic (Romance) languages","normalization":[112,7],"parent":253,"gender":true},
    {"question":309,"id":309,"characteristic":"            Catalan","normalization":[112,7],"parent":308,"gender":true},
    {"question":310,"id":310,"characteristic":"            Italian","normalization":[112,7],"parent":308,"gender":true},
    {"question":311,"id":311,"characteristic":"            Portuguese","normalization":[112,7],"parent":308,"gender":true},
    {"question":312,"id":312,"characteristic":"            Romanian","normalization":[112,7],"parent":308,"gender":true},
    {"question":313,"id":313,"characteristic":"            Spanish","normalization":[112,7],"parent":308,"gender":true},
    {"question":314,"id":314,"characteristic":"            Italic (Romance) languages, n.i.e.","normalization":[112,7],"parent":308,"gender":true},
    {"question":315,"id":315,"characteristic":"        Japanese","normalization":[112,7],"parent":201,"gender":true},
    {"question":316,"id":316,"characteristic":"        Kartvelian languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":317,"id":317,"characteristic":"          Georgian","normalization":[112,7],"parent":316,"gender":true},
    {"question":318,"id":318,"characteristic":"        Korean","normalization":[112,7],"parent":201,"gender":true},
    {"question":319,"id":319,"characteristic":"        Mongolic languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":320,"id":320,"characteristic":"          Mongolian","normalization":[112,7],"parent":319,"gender":true},
    {"question":321,"id":321,"characteristic":"        Niger-Congo languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":322,"id":322,"characteristic":"          Akan (Twi)","normalization":[112,7],"parent":321,"gender":true},
    {"question":323,"id":323,"characteristic":"          Bamanankan","normalization":[112,7],"parent":321,"gender":true},
    {"question":324,"id":324,"characteristic":"          Edo","normalization":[112,7],"parent":321,"gender":true},
    {"question":325,"id":325,"characteristic":"          Ewe","normalization":[112,7],"parent":321,"gender":true},
    {"question":326,"id":326,"characteristic":"          Fulah (Pular, Pulaar, Fulfulde)","normalization":[112,7],"parent":321,"gender":true},
    {"question":327,"id":327,"characteristic":"          Ga","normalization":[112,7],"parent":321,"gender":true},
    {"question":328,"id":328,"characteristic":"          Ganda","normalization":[112,7],"parent":321,"gender":true},
    {"question":329,"id":329,"characteristic":"          Igbo","normalization":[112,7],"parent":321,"gender":true},
    {"question":330,"id":330,"characteristic":"          Lingala","normalization":[112,7],"parent":321,"gender":true},
    {"question":331,"id":331,"characteristic":"          Rundi (Kirundi)","normalization":[112,7],"parent":321,"gender":true},
    {"question":332,"id":332,"characteristic":"          Kinyarwanda (Rwanda)","normalization":[112,7],"parent":321,"gender":true},
    {"question":333,"id":333,"characteristic":"          Shona","normalization":[112,7],"parent":321,"gender":true},
    {"question":334,"id":334,"characteristic":"          Swahili","normalization":[112,7],"parent":321,"gender":true},
    {"question":335,"id":335,"characteristic":"          Wolof","normalization":[112,7],"parent":321,"gender":true},
    {"question":336,"id":336,"characteristic":"          Yoruba","normalization":[112,7],"parent":321,"gender":true},
    {"question":337,"id":337,"characteristic":"          Niger-Congo languages, n.i.e.","normalization":[112,7],"parent":321,"gender":true},
    {"question":338,"id":338,"characteristic":"        Nilo-Saharan languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":339,"id":339,"characteristic":"          Dinka","normalization":[112,7],"parent":338,"gender":true},
    {"question":340,"id":340,"characteristic":"          Nilo-Saharan languages, n.i.e.","normalization":[112,7],"parent":338,"gender":true},
    {"question":341,"id":341,"characteristic":"        Sign languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":342,"id":342,"characteristic":"          American Sign Language","normalization":[112,7],"parent":341,"gender":true},
    {"question":343,"id":343,"characteristic":"          Quebec Sign Language","normalization":[112,7],"parent":341,"gender":true},
    {"question":344,"id":344,"characteristic":"          Sign languages, n.i.e","normalization":[112,7],"parent":341,"gender":true},
    {"question":345,"id":345,"characteristic":"        Sino-Tibetan languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":346,"id":346,"characteristic":"          Chinese languages","normalization":[112,7],"parent":345,"gender":true},
    {"question":347,"id":347,"characteristic":"            Cantonese","normalization":[112,7],"parent":346,"gender":true},
    {"question":348,"id":348,"characteristic":"            Hakka","normalization":[112,7],"parent":346,"gender":true},
    {"question":349,"id":349,"characteristic":"            Mandarin","normalization":[112,7],"parent":346,"gender":true},
    {"question":350,"id":350,"characteristic":"            Min Dong","normalization":[112,7],"parent":346,"gender":true},
    {"question":351,"id":351,"characteristic":"            Min Nan (Chaochow, Teochow, Fukien, Taiwanese)","normalization":[112,7],"parent":346,"gender":true},
    {"question":352,"id":352,"characteristic":"            Wu (Shanghainese)","normalization":[112,7],"parent":346,"gender":true},
    {"question":353,"id":353,"characteristic":"            Chinese, n.o.s.","normalization":[112,7],"parent":346,"gender":true},
    {"question":354,"id":354,"characteristic":"            Chinese languages, n.i.e.","normalization":[112,7],"parent":346,"gender":true},
    {"question":355,"id":355,"characteristic":"          Tibeto-Burman languages","normalization":[112,7],"parent":345,"gender":true},
    {"question":356,"id":356,"characteristic":"            Burmese","normalization":[112,7],"parent":355,"gender":true},
    {"question":357,"id":357,"characteristic":"            Karenic languages","normalization":[112,7],"parent":355,"gender":true},
    {"question":358,"id":358,"characteristic":"            Tibetan","normalization":[112,7],"parent":355,"gender":true},
    {"question":359,"id":359,"characteristic":"            Tibeto-Burman languages, n.i.e.","normalization":[112,7],"parent":355,"gender":true},
    {"question":360,"id":360,"characteristic":"        Tai-Kadai languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":361,"id":361,"characteristic":"          Lao","normalization":[112,7],"parent":360,"gender":true},
    {"question":362,"id":362,"characteristic":"          Thai","normalization":[112,7],"parent":360,"gender":true},
    {"question":363,"id":363,"characteristic":"          Tai-Kadai languages, n.i.e","normalization":[112,7],"parent":360,"gender":true},
    {"question":364,"id":364,"characteristic":"        Turkic languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":365,"id":365,"characteristic":"          Azerbaijani","normalization":[112,7],"parent":364,"gender":true},
    {"question":366,"id":366,"characteristic":"          Turkish","normalization":[112,7],"parent":364,"gender":true},
    {"question":367,"id":367,"characteristic":"          Uyghur","normalization":[112,7],"parent":364,"gender":true},
    {"question":368,"id":368,"characteristic":"          Uzbek","normalization":[112,7],"parent":364,"gender":true},
    {"question":369,"id":369,"characteristic":"          Turkic languages, n.i.e.","normalization":[112,7],"parent":364,"gender":true},
    {"question":370,"id":370,"characteristic":"        Uralic languages","normalization":[112,7],"parent":201,"gender":true},
    {"question":371,"id":371,"characteristic":"          Estonian","normalization":[112,7],"parent":370,"gender":true},
    {"question":372,"id":372,"characteristic":"          Finnish","normalization":[112,7],"parent":370,"gender":true},
    {"question":373,"id":373,"characteristic":"          Hungarian","normalization":[112,7],"parent":370,"gender":true},
    {"question":374,"id":374,"characteristic":"          Uralic languages, n.i.e.","normalization":[112,7],"parent":370,"gender":true},
    {"question":375,"id":375,"characteristic":"        Other languages, n.i.e.","normalization":[112,7],"parent":201,"gender":true},
    {"question":376,"id":376,"characteristic":"  Multiple responses","normalization":[112,7],"parent":112,"gender":true},
    {"question":377,"id":377,"characteristic":"    English and French","normalization":[112,7],"parent":376,"gender":true},
    {"question":378,"id":378,"characteristic":"    English and non-official language","normalization":[112,7],"parent":376,"gender":true},
    {"question":379,"id":379,"characteristic":"    French and non-official language","normalization":[112,7],"parent":376,"gender":true},
    {"question":380,"id":380,"characteristic":"    English, French and non-official language","normalization":[112,7],"parent":376,"gender":true},
    {"question":381,"id":381,"characteristic":"Total - Language spoken most often at home for the total population excluding institutional residents - 100% data (25)","normalization":[],"parent":0,"gender":true},
    {"question":382,"id":382,"characteristic":"  Single responses","normalization":[381,7],"parent":381,"gender":true},
    {"question":383,"id":383,"characteristic":"    Official languages","normalization":[381,7],"parent":382,"gender":true},
    {"question":384,"id":384,"characteristic":"      English","normalization":[381,7],"parent":383,"gender":true},
    {"question":385,"id":385,"characteristic":"      French","normalization":[381,7],"parent":383,"gender":true},
    {"question":386,"id":386,"characteristic":"    Non-official languages","normalization":[381,7],"parent":382,"gender":true},
    {"question":387,"id":387,"characteristic":"      Aboriginal languages","normalization":[381,7],"parent":386,"gender":true},
    {"question":388,"id":388,"characteristic":"        Algonquian languages","normalization":[381,7],"parent":387,"gender":true},
    {"question":389,"id":389,"characteristic":"          Blackfoot","normalization":[381,7],"parent":388,"gender":true},
    {"question":390,"id":390,"characteristic":"          Cree-Montagnais languages","normalization":[381,7],"parent":388,"gender":true},
    {"question":391,"id":391,"characteristic":"            Atikamekw","normalization":[381,7],"parent":390,"gender":true},
    {"question":392,"id":392,"characteristic":"            Montagnais (Innu)","normalization":[381,7],"parent":390,"gender":true},
    {"question":393,"id":393,"characteristic":"            Moose Cree","normalization":[381,7],"parent":390,"gender":true},
    {"question":394,"id":394,"characteristic":"            Naskapi","normalization":[381,7],"parent":390,"gender":true},
    {"question":395,"id":395,"characteristic":"            Northern East Cree","normalization":[381,7],"parent":390,"gender":true},
    {"question":396,"id":396,"characteristic":"            Plains Cree","normalization":[381,7],"parent":390,"gender":true},
    {"question":397,"id":397,"characteristic":"            Southern East Cree","normalization":[381,7],"parent":390,"gender":true},
    {"question":398,"id":398,"characteristic":"            Swampy Cree","normalization":[381,7],"parent":390,"gender":true},
    {"question":399,"id":399,"characteristic":"            Woods Cree","normalization":[381,7],"parent":390,"gender":true},
    {"question":400,"id":400,"characteristic":"            Cree, n.o.s.","normalization":[381,7],"parent":390,"gender":true},
    {"question":401,"id":401,"characteristic":"          Eastern Algonquian languages","normalization":[381,7],"parent":388,"gender":true},
    {"question":402,"id":402,"characteristic":"            Malecite","normalization":[381,7],"parent":401,"gender":true},
    {"question":403,"id":403,"characteristic":"            Mi'kmaq","normalization":[381,7],"parent":401,"gender":true},
    {"question":404,"id":404,"characteristic":"          Ojibway-Potawatomi languages","normalization":[381,7],"parent":388,"gender":true},
    {"question":405,"id":405,"characteristic":"            Algonquin","normalization":[381,7],"parent":404,"gender":true},
    {"question":406,"id":406,"characteristic":"            Ojibway","normalization":[381,7],"parent":404,"gender":true},
    {"question":407,"id":407,"characteristic":"            Oji-Cree","normalization":[381,7],"parent":404,"gender":true},
    {"question":408,"id":408,"characteristic":"            Ottawa (Odawa)","normalization":[381,7],"parent":404,"gender":true},
    {"question":409,"id":409,"characteristic":"          Algonquian languages, n.i.e.","normalization":[381,7],"parent":388,"gender":true},
    {"question":410,"id":410,"characteristic":"        Athabaskan languages","normalization":[381,7],"parent":387,"gender":true},
    {"question":411,"id":411,"characteristic":"          Northern Athabaskan languages","normalization":[381,7],"parent":410,"gender":true},
    {"question":412,"id":412,"characteristic":"            Babine (Wetsuwet'en)","normalization":[381,7],"parent":411,"gender":true},
    {"question":413,"id":413,"characteristic":"            Beaver","normalization":[381,7],"parent":411,"gender":true},
    {"question":414,"id":414,"characteristic":"            Carrier","normalization":[381,7],"parent":411,"gender":true},
    {"question":415,"id":415,"characteristic":"            Chilcotin","normalization":[381,7],"parent":411,"gender":true},
    {"question":416,"id":416,"characteristic":"            Dene","normalization":[381,7],"parent":411,"gender":true},
    {"question":417,"id":417,"characteristic":"            Dogrib (Tlicho)","normalization":[381,7],"parent":411,"gender":true},
    {"question":418,"id":418,"characteristic":"            Gwich'in","normalization":[381,7],"parent":411,"gender":true},
    {"question":419,"id":419,"characteristic":"            Sarsi (Sarcee)","normalization":[381,7],"parent":411,"gender":true},
    {"question":420,"id":420,"characteristic":"            Sekani","normalization":[381,7],"parent":411,"gender":true},
    {"question":421,"id":421,"characteristic":"            Slavey-Hare languages","normalization":[381,7],"parent":411,"gender":true},
    {"question":422,"id":422,"characteristic":"              North Slavey (Hare)","normalization":[381,7],"parent":421,"gender":true},
    {"question":423,"id":423,"characteristic":"              South Slavey","normalization":[381,7],"parent":421,"gender":true},
    {"question":424,"id":424,"characteristic":"              Slavey, n.o.s.","normalization":[381,7],"parent":421,"gender":true},
    {"question":425,"id":425,"characteristic":"            Tahltan languages","normalization":[381,7],"parent":411,"gender":true},
    {"question":426,"id":426,"characteristic":"              Kaska (Nahani)","normalization":[381,7],"parent":425,"gender":true},
    {"question":427,"id":427,"characteristic":"              Tahltan","normalization":[381,7],"parent":425,"gender":true},
    {"question":428,"id":428,"characteristic":"            Tutchone languages","normalization":[381,7],"parent":411,"gender":true},
    {"question":429,"id":429,"characteristic":"              Northern Tutchone","normalization":[381,7],"parent":428,"gender":true},
    {"question":430,"id":430,"characteristic":"              Southern Tutchone","normalization":[381,7],"parent":428,"gender":true},
    {"question":431,"id":431,"characteristic":"          Athabaskan languages, n.i.e.","normalization":[381,7],"parent":410,"gender":true},
    {"question":432,"id":432,"characteristic":"        Haida","normalization":[381,7],"parent":387,"gender":true},
    {"question":433,"id":433,"characteristic":"        Inuit languages","normalization":[381,7],"parent":387,"gender":true},
    {"question":434,"id":434,"characteristic":"          Inuinnaqtun (Inuvialuktun)","normalization":[381,7],"parent":433,"gender":true},
    {"question":435,"id":435,"characteristic":"          Inuktitut","normalization":[381,7],"parent":433,"gender":true},
    {"question":436,"id":436,"characteristic":"          Inuit languages, n.i.e.","normalization":[381,7],"parent":433,"gender":true},
    {"question":437,"id":437,"characteristic":"        Iroquoian languages","normalization":[381,7],"parent":387,"gender":true},
    {"question":438,"id":438,"characteristic":"          Cayuga","normalization":[381,7],"parent":437,"gender":true},
    {"question":439,"id":439,"characteristic":"          Mohawk","normalization":[381,7],"parent":437,"gender":true},
    {"question":440,"id":440,"characteristic":"          Oneida","normalization":[381,7],"parent":437,"gender":true},
    {"question":441,"id":441,"characteristic":"          Iroquoian languages, n.i.e.","normalization":[381,7],"parent":437,"gender":true},
    {"question":442,"id":442,"characteristic":"        Kutenai","normalization":[381,7],"parent":387,"gender":true},
    {"question":443,"id":443,"characteristic":"        Michif","normalization":[381,7],"parent":387,"gender":true},
    {"question":444,"id":444,"characteristic":"        Salish languages","normalization":[381,7],"parent":387,"gender":true},
    {"question":445,"id":445,"characteristic":"          Comox","normalization":[381,7],"parent":444,"gender":true},
    {"question":446,"id":446,"characteristic":"          Halkomelem","normalization":[381,7],"parent":444,"gender":true},
    {"question":447,"id":447,"characteristic":"          Lillooet","normalization":[381,7],"parent":444,"gender":true},
    {"question":448,"id":448,"characteristic":"          Okanagan","normalization":[381,7],"parent":444,"gender":true},
    {"question":449,"id":449,"characteristic":"          Shuswap (Secwepemctsin)","normalization":[381,7],"parent":444,"gender":true},
    {"question":450,"id":450,"characteristic":"          Squamish","normalization":[381,7],"parent":444,"gender":true},
    {"question":451,"id":451,"characteristic":"          Straits","normalization":[381,7],"parent":444,"gender":true},
    {"question":452,"id":452,"characteristic":"          Thompson (Ntlakapamux)","normalization":[381,7],"parent":444,"gender":true},
    {"question":453,"id":453,"characteristic":"          Salish languages, n.i.e.","normalization":[381,7],"parent":444,"gender":true},
    {"question":454,"id":454,"characteristic":"        Siouan languages","normalization":[381,7],"parent":387,"gender":true},
    {"question":455,"id":455,"characteristic":"          Dakota","normalization":[381,7],"parent":454,"gender":true},
    {"question":456,"id":456,"characteristic":"          Stoney","normalization":[381,7],"parent":454,"gender":true},
    {"question":457,"id":457,"characteristic":"          Siouan languages, n.i.e.","normalization":[381,7],"parent":454,"gender":true},
    {"question":458,"id":458,"characteristic":"        Tlingit","normalization":[381,7],"parent":387,"gender":true},
    {"question":459,"id":459,"characteristic":"        Tsimshian languages","normalization":[381,7],"parent":387,"gender":true},
    {"question":460,"id":460,"characteristic":"          Gitxsan (Gitksan)","normalization":[381,7],"parent":459,"gender":true},
    {"question":461,"id":461,"characteristic":"          Nisga'a","normalization":[381,7],"parent":459,"gender":true},
    {"question":462,"id":462,"characteristic":"          Tsimshian","normalization":[381,7],"parent":459,"gender":true},
    {"question":463,"id":463,"characteristic":"        Wakashan languages","normalization":[381,7],"parent":387,"gender":true},
    {"question":464,"id":464,"characteristic":"          Haisla","normalization":[381,7],"parent":463,"gender":true},
    {"question":465,"id":465,"characteristic":"          Heiltsuk","normalization":[381,7],"parent":463,"gender":true},
    {"question":466,"id":466,"characteristic":"          Kwakiutl (Kwak'wala)","normalization":[381,7],"parent":463,"gender":true},
    {"question":467,"id":467,"characteristic":"          Nuu-chah-nulth (Nootka)","normalization":[381,7],"parent":463,"gender":true},
    {"question":468,"id":468,"characteristic":"          Wakashan languages, n.i.e.","normalization":[381,7],"parent":463,"gender":true},
    {"question":469,"id":469,"characteristic":"        Aboriginal languages, n.o.s.","normalization":[381,7],"parent":387,"gender":true},
    {"question":470,"id":470,"characteristic":"      Non-Aboriginal languages","normalization":[381,7],"parent":386,"gender":true},
    {"question":471,"id":471,"characteristic":"        Afro-Asiatic languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":472,"id":472,"characteristic":"          Berber languages","normalization":[381,7],"parent":471,"gender":true},
    {"question":473,"id":473,"characteristic":"            Kabyle","normalization":[381,7],"parent":472,"gender":true},
    {"question":474,"id":474,"characteristic":"            Berber languages, n.i.e.","normalization":[381,7],"parent":472,"gender":true},
    {"question":475,"id":475,"characteristic":"          Cushitic languages","normalization":[381,7],"parent":471,"gender":true},
    {"question":476,"id":476,"characteristic":"            Bilen","normalization":[381,7],"parent":475,"gender":true},
    {"question":477,"id":477,"characteristic":"            Oromo","normalization":[381,7],"parent":475,"gender":true},
    {"question":478,"id":478,"characteristic":"            Somali","normalization":[381,7],"parent":475,"gender":true},
    {"question":479,"id":479,"characteristic":"            Cushitic languages, n.i.e.","normalization":[381,7],"parent":475,"gender":true},
    {"question":480,"id":480,"characteristic":"          Semitic languages","normalization":[381,7],"parent":471,"gender":true},
    {"question":481,"id":481,"characteristic":"            Amharic","normalization":[381,7],"parent":480,"gender":true},
    {"question":482,"id":482,"characteristic":"            Arabic","normalization":[381,7],"parent":480,"gender":true},
    {"question":483,"id":483,"characteristic":"            Assyrian Neo-Aramaic","normalization":[381,7],"parent":480,"gender":true},
    {"question":484,"id":484,"characteristic":"            Chaldean Neo-Aramaic","normalization":[381,7],"parent":480,"gender":true},
    {"question":485,"id":485,"characteristic":"            Harari","normalization":[381,7],"parent":480,"gender":true},
    {"question":486,"id":486,"characteristic":"            Hebrew","normalization":[381,7],"parent":480,"gender":true},
    {"question":487,"id":487,"characteristic":"            Maltese","normalization":[381,7],"parent":480,"gender":true},
    {"question":488,"id":488,"characteristic":"            Tigrigna","normalization":[381,7],"parent":480,"gender":true},
    {"question":489,"id":489,"characteristic":"            Semitic languages, n.i.e.","normalization":[381,7],"parent":480,"gender":true},
    {"question":490,"id":490,"characteristic":"          Afro-Asiatic languages, n.i.e.","normalization":[381,7],"parent":471,"gender":true},
    {"question":491,"id":491,"characteristic":"        Austro-Asiatic languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":492,"id":492,"characteristic":"          Khmer (Cambodian)","normalization":[381,7],"parent":491,"gender":true},
    {"question":493,"id":493,"characteristic":"          Vietnamese","normalization":[381,7],"parent":491,"gender":true},
    {"question":494,"id":494,"characteristic":"          Austro-Asiatic languages, n.i.e","normalization":[381,7],"parent":491,"gender":true},
    {"question":495,"id":495,"characteristic":"        Austronesian languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":496,"id":496,"characteristic":"          Bikol","normalization":[381,7],"parent":495,"gender":true},
    {"question":497,"id":497,"characteristic":"          Cebuano","normalization":[381,7],"parent":495,"gender":true},
    {"question":498,"id":498,"characteristic":"          Fijian","normalization":[381,7],"parent":495,"gender":true},
    {"question":499,"id":499,"characteristic":"          Hiligaynon","normalization":[381,7],"parent":495,"gender":true},
    {"question":500,"id":500,"characteristic":"          Ilocano","normalization":[381,7],"parent":495,"gender":true},
    {"question":501,"id":501,"characteristic":"          Malagasy","normalization":[381,7],"parent":495,"gender":true},
    {"question":502,"id":502,"characteristic":"          Malay","normalization":[381,7],"parent":495,"gender":true},
    {"question":503,"id":503,"characteristic":"          Pampangan (Kapampangan, Pampango)","normalization":[381,7],"parent":495,"gender":true},
    {"question":504,"id":504,"characteristic":"          Pangasinan","normalization":[381,7],"parent":495,"gender":true},
    {"question":505,"id":505,"characteristic":"          Tagalog (Pilipino, Filipino)","normalization":[381,7],"parent":495,"gender":true},
    {"question":506,"id":506,"characteristic":"          Waray-Waray","normalization":[381,7],"parent":495,"gender":true},
    {"question":507,"id":507,"characteristic":"          Austronesian languages, n.i.e.","normalization":[381,7],"parent":495,"gender":true},
    {"question":508,"id":508,"characteristic":"        Creole languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":509,"id":509,"characteristic":"          Haitian Creole","normalization":[381,7],"parent":508,"gender":true},
    {"question":510,"id":510,"characteristic":"          Creole, n.o.s.","normalization":[381,7],"parent":508,"gender":true},
    {"question":511,"id":511,"characteristic":"          Creole languages, n.i.e.","normalization":[381,7],"parent":508,"gender":true},
    {"question":512,"id":512,"characteristic":"        Dravidian languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":513,"id":513,"characteristic":"          Kannada","normalization":[381,7],"parent":512,"gender":true},
    {"question":514,"id":514,"characteristic":"          Malayalam","normalization":[381,7],"parent":512,"gender":true},
    {"question":515,"id":515,"characteristic":"          Tamil","normalization":[381,7],"parent":512,"gender":true},
    {"question":516,"id":516,"characteristic":"          Telugu","normalization":[381,7],"parent":512,"gender":true},
    {"question":517,"id":517,"characteristic":"          Dravidian languages, n.i.e.","normalization":[381,7],"parent":512,"gender":true},
    {"question":518,"id":518,"characteristic":"        Hmong-Mien languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":519,"id":519,"characteristic":"        Indo-European languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":520,"id":520,"characteristic":"          Albanian","normalization":[381,7],"parent":519,"gender":true},
    {"question":521,"id":521,"characteristic":"          Armenian","normalization":[381,7],"parent":519,"gender":true},
    {"question":522,"id":522,"characteristic":"          Balto-Slavic languages","normalization":[381,7],"parent":519,"gender":true},
    {"question":523,"id":523,"characteristic":"            Baltic languages","normalization":[381,7],"parent":522,"gender":true},
    {"question":524,"id":524,"characteristic":"              Latvian","normalization":[381,7],"parent":523,"gender":true},
    {"question":525,"id":525,"characteristic":"              Lithuanian","normalization":[381,7],"parent":523,"gender":true},
    {"question":526,"id":526,"characteristic":"            Slavic languages","normalization":[381,7],"parent":522,"gender":true},
    {"question":527,"id":527,"characteristic":"              Belarusan","normalization":[381,7],"parent":526,"gender":true},
    {"question":528,"id":528,"characteristic":"              Bosnian","normalization":[381,7],"parent":526,"gender":true},
    {"question":529,"id":529,"characteristic":"              Bulgarian","normalization":[381,7],"parent":526,"gender":true},
    {"question":530,"id":530,"characteristic":"              Croatian","normalization":[381,7],"parent":526,"gender":true},
    {"question":531,"id":531,"characteristic":"              Czech","normalization":[381,7],"parent":526,"gender":true},
    {"question":532,"id":532,"characteristic":"              Macedonian","normalization":[381,7],"parent":526,"gender":true},
    {"question":533,"id":533,"characteristic":"              Polish","normalization":[381,7],"parent":526,"gender":true},
    {"question":534,"id":534,"characteristic":"              Russian","normalization":[381,7],"parent":526,"gender":true},
    {"question":535,"id":535,"characteristic":"              Serbian","normalization":[381,7],"parent":526,"gender":true},
    {"question":536,"id":536,"characteristic":"              Serbo-Croatian","normalization":[381,7],"parent":526,"gender":true},
    {"question":537,"id":537,"characteristic":"              Slovak","normalization":[381,7],"parent":526,"gender":true},
    {"question":538,"id":538,"characteristic":"              Slovene (Slovenian)","normalization":[381,7],"parent":526,"gender":true},
    {"question":539,"id":539,"characteristic":"              Ukrainian","normalization":[381,7],"parent":526,"gender":true},
    {"question":540,"id":540,"characteristic":"              Slavic languages, n.i.e.","normalization":[381,7],"parent":526,"gender":true},
    {"question":541,"id":541,"characteristic":"          Celtic languages","normalization":[381,7],"parent":519,"gender":true},
    {"question":542,"id":542,"characteristic":"            Scottish Gaelic","normalization":[381,7],"parent":541,"gender":true},
    {"question":543,"id":543,"characteristic":"            Welsh","normalization":[381,7],"parent":541,"gender":true},
    {"question":544,"id":544,"characteristic":"            Celtic languages, n.i.e.","normalization":[381,7],"parent":541,"gender":true},
    {"question":545,"id":545,"characteristic":"          Germanic languages","normalization":[381,7],"parent":519,"gender":true},
    {"question":546,"id":546,"characteristic":"            Afrikaans","normalization":[381,7],"parent":545,"gender":true},
    {"question":547,"id":547,"characteristic":"            Danish","normalization":[381,7],"parent":545,"gender":true},
    {"question":548,"id":548,"characteristic":"            Dutch","normalization":[381,7],"parent":545,"gender":true},
    {"question":549,"id":549,"characteristic":"            Frisian","normalization":[381,7],"parent":545,"gender":true},
    {"question":550,"id":550,"characteristic":"            German","normalization":[381,7],"parent":545,"gender":true},
    {"question":551,"id":551,"characteristic":"            Icelandic","normalization":[381,7],"parent":545,"gender":true},
    {"question":552,"id":552,"characteristic":"            Norwegian","normalization":[381,7],"parent":545,"gender":true},
    {"question":553,"id":553,"characteristic":"            Swedish","normalization":[381,7],"parent":545,"gender":true},
    {"question":554,"id":554,"characteristic":"            Vlaams (Flemish)","normalization":[381,7],"parent":545,"gender":true},
    {"question":555,"id":555,"characteristic":"            Yiddish","normalization":[381,7],"parent":545,"gender":true},
    {"question":556,"id":556,"characteristic":"            Germanic languages, n.i.e.","normalization":[381,7],"parent":545,"gender":true},
    {"question":557,"id":557,"characteristic":"          Greek","normalization":[381,7],"parent":519,"gender":true},
    {"question":558,"id":558,"characteristic":"          Indo-Iranian languages","normalization":[381,7],"parent":519,"gender":true},
    {"question":559,"id":559,"characteristic":"            Indo-Aryan languages","normalization":[381,7],"parent":558,"gender":true},
    {"question":560,"id":560,"characteristic":"              Bengali","normalization":[381,7],"parent":559,"gender":true},
    {"question":561,"id":561,"characteristic":"              Gujarati","normalization":[381,7],"parent":559,"gender":true},
    {"question":562,"id":562,"characteristic":"              Hindi","normalization":[381,7],"parent":559,"gender":true},
    {"question":563,"id":563,"characteristic":"              Kashmiri","normalization":[381,7],"parent":559,"gender":true},
    {"question":564,"id":564,"characteristic":"              Konkani","normalization":[381,7],"parent":559,"gender":true},
    {"question":565,"id":565,"characteristic":"              Marathi","normalization":[381,7],"parent":559,"gender":true},
    {"question":566,"id":566,"characteristic":"              Nepali","normalization":[381,7],"parent":559,"gender":true},
    {"question":567,"id":567,"characteristic":"              Oriya (Odia)","normalization":[381,7],"parent":559,"gender":true},
    {"question":568,"id":568,"characteristic":"              Punjabi (Panjabi)","normalization":[381,7],"parent":559,"gender":true},
    {"question":569,"id":569,"characteristic":"              Sindhi","normalization":[381,7],"parent":559,"gender":true},
    {"question":570,"id":570,"characteristic":"              Sinhala (Sinhalese)","normalization":[381,7],"parent":559,"gender":true},
    {"question":571,"id":571,"characteristic":"              Urdu","normalization":[381,7],"parent":559,"gender":true},
    {"question":572,"id":572,"characteristic":"            Iranian languages","normalization":[381,7],"parent":558,"gender":true},
    {"question":573,"id":573,"characteristic":"              Kurdish","normalization":[381,7],"parent":572,"gender":true},
    {"question":574,"id":574,"characteristic":"              Pashto","normalization":[381,7],"parent":572,"gender":true},
    {"question":575,"id":575,"characteristic":"              Persian (Farsi)","normalization":[381,7],"parent":572,"gender":true},
    {"question":576,"id":576,"characteristic":"            Indo-Iranian languages, n.i.e.","normalization":[381,7],"parent":558,"gender":true},
    {"question":577,"id":577,"characteristic":"          Italic (Romance) languages","normalization":[381,7],"parent":519,"gender":true},
    {"question":578,"id":578,"characteristic":"            Catalan","normalization":[381,7],"parent":577,"gender":true},
    {"question":579,"id":579,"characteristic":"            Italian","normalization":[381,7],"parent":577,"gender":true},
    {"question":580,"id":580,"characteristic":"            Portuguese","normalization":[381,7],"parent":577,"gender":true},
    {"question":581,"id":581,"characteristic":"            Romanian","normalization":[381,7],"parent":577,"gender":true},
    {"question":582,"id":582,"characteristic":"            Spanish","normalization":[381,7],"parent":577,"gender":true},
    {"question":583,"id":583,"characteristic":"            Italic (Romance) languages, n.i.e.","normalization":[381,7],"parent":577,"gender":true},
    {"question":584,"id":584,"characteristic":"        Japanese","normalization":[381,7],"parent":470,"gender":true},
    {"question":585,"id":585,"characteristic":"        Kartvelian languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":586,"id":586,"characteristic":"          Georgian","normalization":[381,7],"parent":585,"gender":true},
    {"question":587,"id":587,"characteristic":"        Korean","normalization":[381,7],"parent":470,"gender":true},
    {"question":588,"id":588,"characteristic":"        Mongolic languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":589,"id":589,"characteristic":"          Mongolian","normalization":[381,7],"parent":588,"gender":true},
    {"question":590,"id":590,"characteristic":"        Niger-Congo languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":591,"id":591,"characteristic":"          Akan (Twi)","normalization":[381,7],"parent":590,"gender":true},
    {"question":592,"id":592,"characteristic":"          Bamanankan","normalization":[381,7],"parent":590,"gender":true},
    {"question":593,"id":593,"characteristic":"          Edo","normalization":[381,7],"parent":590,"gender":true},
    {"question":594,"id":594,"characteristic":"          Ewe","normalization":[381,7],"parent":590,"gender":true},
    {"question":595,"id":595,"characteristic":"          Fulah (Pular, Pulaar, Fulfulde)","normalization":[381,7],"parent":590,"gender":true},
    {"question":596,"id":596,"characteristic":"          Ga","normalization":[381,7],"parent":590,"gender":true},
    {"question":597,"id":597,"characteristic":"          Ganda","normalization":[381,7],"parent":590,"gender":true},
    {"question":598,"id":598,"characteristic":"          Igbo","normalization":[381,7],"parent":590,"gender":true},
    {"question":599,"id":599,"characteristic":"          Lingala","normalization":[381,7],"parent":590,"gender":true},
    {"question":600,"id":600,"characteristic":"          Rundi (Kirundi)","normalization":[381,7],"parent":590,"gender":true},
    {"question":601,"id":601,"characteristic":"          Kinyarwanda (Rwanda)","normalization":[381,7],"parent":590,"gender":true},
    {"question":602,"id":602,"characteristic":"          Shona","normalization":[381,7],"parent":590,"gender":true},
    {"question":603,"id":603,"characteristic":"          Swahili","normalization":[381,7],"parent":590,"gender":true},
    {"question":604,"id":604,"characteristic":"          Wolof","normalization":[381,7],"parent":590,"gender":true},
    {"question":605,"id":605,"characteristic":"          Yoruba","normalization":[381,7],"parent":590,"gender":true},
    {"question":606,"id":606,"characteristic":"          Niger-Congo languages, n.i.e.","normalization":[381,7],"parent":590,"gender":true},
    {"question":607,"id":607,"characteristic":"        Nilo-Saharan languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":608,"id":608,"characteristic":"          Dinka","normalization":[381,7],"parent":607,"gender":true},
    {"question":609,"id":609,"characteristic":"          Nilo-Saharan languages, n.i.e.","normalization":[381,7],"parent":607,"gender":true},
    {"question":610,"id":610,"characteristic":"        Sign languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":611,"id":611,"characteristic":"          American Sign Language","normalization":[381,7],"parent":610,"gender":true},
    {"question":612,"id":612,"characteristic":"          Quebec Sign Language","normalization":[381,7],"parent":610,"gender":true},
    {"question":613,"id":613,"characteristic":"          Sign languages, n.i.e","normalization":[381,7],"parent":610,"gender":true},
    {"question":614,"id":614,"characteristic":"        Sino-Tibetan languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":615,"id":615,"characteristic":"          Chinese languages","normalization":[381,7],"parent":614,"gender":true},
    {"question":616,"id":616,"characteristic":"            Cantonese","normalization":[381,7],"parent":615,"gender":true},
    {"question":617,"id":617,"characteristic":"            Hakka","normalization":[381,7],"parent":615,"gender":true},
    {"question":618,"id":618,"characteristic":"            Mandarin","normalization":[381,7],"parent":615,"gender":true},
    {"question":619,"id":619,"characteristic":"            Min Dong","normalization":[381,7],"parent":615,"gender":true},
    {"question":620,"id":620,"characteristic":"            Min Nan (Chaochow, Teochow, Fukien, Taiwanese)","normalization":[381,7],"parent":615,"gender":true},
    {"question":621,"id":621,"characteristic":"            Wu (Shanghainese)","normalization":[381,7],"parent":615,"gender":true},
    {"question":622,"id":622,"characteristic":"            Chinese, n.o.s.","normalization":[381,7],"parent":615,"gender":true},
    {"question":623,"id":623,"characteristic":"            Chinese languages, n.i.e.","normalization":[381,7],"parent":615,"gender":true},
    {"question":624,"id":624,"characteristic":"          Tibeto-Burman languages","normalization":[381,7],"parent":614,"gender":true},
    {"question":625,"id":625,"characteristic":"            Burmese","normalization":[381,7],"parent":624,"gender":true},
    {"question":626,"id":626,"characteristic":"            Karenic languages","normalization":[381,7],"parent":624,"gender":true},
    {"question":627,"id":627,"characteristic":"            Tibetan","normalization":[381,7],"parent":624,"gender":true},
    {"question":628,"id":628,"characteristic":"            Tibeto-Burman languages, n.i.e.","normalization":[381,7],"parent":624,"gender":true},
    {"question":629,"id":629,"characteristic":"        Tai-Kadai languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":630,"id":630,"characteristic":"          Lao","normalization":[381,7],"parent":629,"gender":true},
    {"question":631,"id":631,"characteristic":"          Thai","normalization":[381,7],"parent":629,"gender":true},
    {"question":632,"id":632,"characteristic":"          Tai-Kadai languages, n.i.e","normalization":[381,7],"parent":629,"gender":true},
    {"question":633,"id":633,"characteristic":"        Turkic languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":634,"id":634,"characteristic":"          Azerbaijani","normalization":[381,7],"parent":633,"gender":true},
    {"question":635,"id":635,"characteristic":"          Turkish","normalization":[381,7],"parent":633,"gender":true},
    {"question":636,"id":636,"characteristic":"          Uyghur","normalization":[381,7],"parent":633,"gender":true},
    {"question":637,"id":637,"characteristic":"          Uzbek","normalization":[381,7],"parent":633,"gender":true},
    {"question":638,"id":638,"characteristic":"          Turkic languages, n.i.e.","normalization":[381,7],"parent":633,"gender":true},
    {"question":639,"id":639,"characteristic":"        Uralic languages","normalization":[381,7],"parent":470,"gender":true},
    {"question":640,"id":640,"characteristic":"          Estonian","normalization":[381,7],"parent":639,"gender":true},
    {"question":641,"id":641,"characteristic":"          Finnish","normalization":[381,7],"parent":639,"gender":true},
    {"question":642,"id":642,"characteristic":"          Hungarian","normalization":[381,7],"parent":639,"gender":true},
    {"question":643,"id":643,"characteristic":"          Uralic languages, n.i.e.","normalization":[381,7],"parent":639,"gender":true},
    {"question":644,"id":644,"characteristic":"        Other languages, n.i.e.","normalization":[381,7],"parent":470,"gender":true},
    {"question":645,"id":645,"characteristic":"  Multiple responses","normalization":[381,7],"parent":381,"gender":true},
    {"question":646,"id":646,"characteristic":"    English and French","normalization":[381,7],"parent":645,"gender":true},
    {"question":647,"id":647,"characteristic":"    English and non-official language","normalization":[381,7],"parent":645,"gender":true},
    {"question":648,"id":648,"characteristic":"    French and non-official language","normalization":[381,7],"parent":645,"gender":true},
    {"question":649,"id":649,"characteristic":"    English, French and non-official language","normalization":[381,7],"parent":645,"gender":true},
    {"question":650,"id":650,"characteristic":"Total - Other language(s) spoken regularly at home for the total population excluding institutional residents - 100% data (28)","normalization":[],"parent":0,"gender":true},
    {"question":651,"id":651,"characteristic":"  None","normalization":[650,7],"parent":650,"gender":true},
    {"question":652,"id":652,"characteristic":"  English","normalization":[650,7],"parent":650,"gender":true},
    {"question":653,"id":653,"characteristic":"  French","normalization":[650,7],"parent":650,"gender":true},
    {"question":654,"id":654,"characteristic":"  Non-official language","normalization":[650,7],"parent":650,"gender":true},
    {"question":655,"id":655,"characteristic":"    Aboriginal","normalization":[650,7],"parent":654,"gender":true},
    {"question":656,"id":656,"characteristic":"    Non-Aboriginal","normalization":[650,7],"parent":654,"gender":true},
    {"question":657,"id":657,"characteristic":"  English and French","normalization":[650,7],"parent":650,"gender":true},
    {"question":658,"id":658,"characteristic":"  English and non-official language","normalization":[650,7],"parent":650,"gender":true},
    {"question":659,"id":659,"characteristic":"  French and non-official language","normalization":[650,7],"parent":650,"gender":true},
    {"question":660,"id":660,"characteristic":"  English, French and non-official language","normalization":[650,7],"parent":650,"gender":true},
    {"question":661,"id":661,"characteristic":"Total - Income statistics in 2015 for the population aged 15 years and over in private households - 100% data (30)","normalization":[],"parent":0,"gender":true},
    {"question":662,"id":662,"characteristic":"  Number of total income recipients aged 15 years and over in private households - 100% data","normalization":[661,7],"parent":661,"gender":true},
    {"question":663,"id":663,"characteristic":"    Median total income in 2015 among recipients ($)","normalization":[],"parent":662,"gender":true},
    {"question":664,"id":664,"characteristic":"  Number of after-tax income recipients aged 15 years and over in private households - 100% data","normalization":[661,7],"parent":661,"gender":true},
    {"question":665,"id":665,"characteristic":"    Median after-tax income in 2015 among recipients ($)","normalization":[],"parent":664,"gender":true},
    {"question":666,"id":666,"characteristic":"  Number of market income recipients aged 15 years and over in private households - 100% data","normalization":[661,7],"parent":661,"gender":true},
    {"question":667,"id":667,"characteristic":"    Median market income in 2015 among recipients ($)","normalization":[],"parent":666,"gender":true},
    {"question":668,"id":668,"characteristic":"  Number of government transfers recipients aged 15 years and over in private households - 100% data","normalization":[661,7],"parent":661,"gender":true},
    {"question":669,"id":669,"characteristic":"    Median government transfers in 2015 among recipients ($)","normalization":[],"parent":668,"gender":true},
    {"question":670,"id":670,"characteristic":"  Number of employment income recipients aged 15 years and over in private households - 100% data","normalization":[661,7],"parent":661,"gender":true},
    {"question":671,"id":671,"characteristic":"    Median employment income in 2015 among recipients ($)","normalization":[],"parent":670,"gender":true},
    {"question":672,"id":672,"characteristic":"Total - Income statistics in 2015 for the population aged 15 years and over in private households - 25% sample data (31)","normalization":[],"parent":0,"gender":true},
    {"question":673,"id":673,"characteristic":"  Number of total income recipients aged 15 years and over in private households - 25% sample data","normalization":[672,7],"parent":672,"gender":true},
    {"question":674,"id":674,"characteristic":"    Average total income in 2015 among recipients ($)","normalization":[],"parent":673,"gender":true},
    {"question":675,"id":675,"characteristic":"  Number of after-tax income recipients aged 15 years and over in private households - 25% sample data","normalization":[672,7],"parent":672,"gender":true},
    {"question":676,"id":676,"characteristic":"    Average after-tax income in 2015 among recipients ($)","normalization":[],"parent":675,"gender":true},
    {"question":677,"id":677,"characteristic":"  Number of market income recipients aged 15 years and over in private households - 25% sample data","normalization":[672,7],"parent":672,"gender":true},
    {"question":678,"id":678,"characteristic":"    Average market income in 2015 among recipients ($)","normalization":[],"parent":677,"gender":true},
    {"question":679,"id":679,"characteristic":"  Number of government transfers recipients aged 15 years and over in private households - 25% sample data","normalization":[672,7],"parent":672,"gender":true},
    {"question":680,"id":680,"characteristic":"    Average government transfers in 2015 among recipients ($)","normalization":[],"parent":679,"gender":true},
    {"question":681,"id":681,"characteristic":"  Number of employment income recipients aged 15 years and over in private households - 25% sample data","normalization":[672,7],"parent":672,"gender":true},
    {"question":682,"id":682,"characteristic":"    Average employment income in 2015 among recipients ($)","normalization":[],"parent":681,"gender":true},
    {"question":683,"id":683,"characteristic":"Total - Employment income statistics for the population aged 15 years and over in private households - 25% sample data (32)","normalization":[],"parent":0,"gender":true},
    {"question":684,"id":684,"characteristic":"  Number of employment income recipients aged 15 years and over in private households who worked full year full time in 2015 - 25% sample data (33)","normalization":[683,7],"parent":683,"gender":true},
    {"question":685,"id":685,"characteristic":"    Median employment income in 2015 for full-year full-time workers ($) (34)","normalization":[],"parent":684,"gender":true},
    {"question":686,"id":686,"characteristic":"    Average employment income in 2015 for full-year full-time workers ($) (35)","normalization":[],"parent":684,"gender":true},
    {"question":687,"id":687,"characteristic":"Composition of total income in 2015 of the population aged 15 years and over in private households (%) - 100% data (36)","normalization":[],"parent":0,"gender":true},
    {"question":688,"id":688,"characteristic":"  Market income (%) (37)","normalization":[687,7],"parent":687,"gender":true},
    {"question":689,"id":689,"characteristic":"    Employment income (%) (38)","normalization":[687,7],"parent":688,"gender":true},
    {"question":690,"id":690,"characteristic":"  Government transfers (%) (39)","normalization":[687,7],"parent":687,"gender":true},
    {"question":691,"id":691,"characteristic":"Total - Total income groups in 2015 for the population aged 15 years and over in private households - 100% data (40)","normalization":[],"parent":0,"gender":true},
    {"question":692,"id":692,"characteristic":"  Without total income","normalization":[691,7],"parent":691,"gender":true},
    {"question":693,"id":693,"characteristic":"  With total income","normalization":[691,7],"parent":691,"gender":true},
    {"question":694,"id":694,"characteristic":"  Percentage with total income","normalization":[691,7],"parent":691,"gender":true},
    {"question":695,"id":695,"characteristic":"    Under $10,000 (including loss)","normalization":[691,7],"parent":694,"gender":true},
    {"question":696,"id":696,"characteristic":"    $10,000 to $19,999","normalization":[691,7],"parent":694,"gender":true},
    {"question":697,"id":697,"characteristic":"    $20,000 to $29,999","normalization":[691,7],"parent":694,"gender":true},
    {"question":698,"id":698,"characteristic":"    $30,000 to $39,999","normalization":[691,7],"parent":694,"gender":true},
    {"question":699,"id":699,"characteristic":"    $40,000 to $49,999","normalization":[691,7],"parent":694,"gender":true},
    {"question":700,"id":700,"characteristic":"    $50,000 to $59,999","normalization":[691,7],"parent":694,"gender":true},
    {"question":701,"id":701,"characteristic":"    $60,000 to $69,999","normalization":[691,7],"parent":694,"gender":true},
    {"question":702,"id":702,"characteristic":"    $70,000 to $79,999","normalization":[691,7],"parent":694,"gender":true},
    {"question":703,"id":703,"characteristic":"    $80,000 to $89,999","normalization":[691,7],"parent":694,"gender":true},
    {"question":704,"id":704,"characteristic":"    $90,000 to $99,999","normalization":[691,7],"parent":694,"gender":true},
    {"question":705,"id":705,"characteristic":"    $100,000 and over","normalization":[691,7],"parent":694,"gender":true},
    {"question":706,"id":706,"characteristic":"      $100,000 to $149,999","normalization":[691,7],"parent":705,"gender":true},
    {"question":707,"id":707,"characteristic":"      $150,000 and over","normalization":[691,7],"parent":705,"gender":true},
    {"question":708,"id":708,"characteristic":"Total - After-tax income groups in 2015 for the population aged 15 years and over in private households - 100% data (41)","normalization":[],"parent":0,"gender":true},
    {"question":709,"id":709,"characteristic":"  Without after-tax income","normalization":[708,7],"parent":708,"gender":true},
    {"question":710,"id":710,"characteristic":"  With after-tax income","normalization":[708,7],"parent":708,"gender":true},
    {"question":711,"id":711,"characteristic":"  Percentage with after-tax income","normalization":[708,7],"parent":708,"gender":true},
    {"question":712,"id":712,"characteristic":"    Under $10,000 (including loss)","normalization":[708,7],"parent":711,"gender":true},
    {"question":713,"id":713,"characteristic":"    $10,000 to $19,999","normalization":[708,7],"parent":711,"gender":true},
    {"question":714,"id":714,"characteristic":"    $20,000 to $29,999","normalization":[708,7],"parent":711,"gender":true},
    {"question":715,"id":715,"characteristic":"    $30,000 to $39,999","normalization":[708,7],"parent":711,"gender":true},
    {"question":716,"id":716,"characteristic":"    $40,000 to $49,999","normalization":[708,7],"parent":711,"gender":true},
    {"question":717,"id":717,"characteristic":"    $50,000 to $59,999","normalization":[708,7],"parent":711,"gender":true},
    {"question":718,"id":718,"characteristic":"    $60,000 to $69,999","normalization":[708,7],"parent":711,"gender":true},
    {"question":719,"id":719,"characteristic":"    $70,000 to $79,999","normalization":[708,7],"parent":711,"gender":true},
    {"question":720,"id":720,"characteristic":"    $80,000 and over","normalization":[708,7],"parent":711,"gender":true},
    {"question":721,"id":721,"characteristic":"      $80,000 to $89,999","normalization":[708,7],"parent":720,"gender":true},
    {"question":722,"id":722,"characteristic":"      $90,000 to $99,999","normalization":[708,7],"parent":720,"gender":true},
    {"question":723,"id":723,"characteristic":"      $100,000 and over","normalization":[708,7],"parent":720,"gender":true},
    {"question":724,"id":724,"characteristic":"Total - Employment income groups in 2015 for the population aged 15 years and over in private households - 100% data (42)","normalization":[],"parent":0,"gender":true},
    {"question":725,"id":725,"characteristic":"  Without employment income","normalization":[724,7],"parent":724,"gender":true},
    {"question":726,"id":726,"characteristic":"  With employment income","normalization":[724,7],"parent":724,"gender":true},
    {"question":727,"id":727,"characteristic":"  Percentage with employment income","normalization":[724,7],"parent":724,"gender":true},
    {"question":728,"id":728,"characteristic":"    Under $5,000 (including loss)","normalization":[724,7],"parent":727,"gender":true},
    {"question":729,"id":729,"characteristic":"    $5,000 to $9,999","normalization":[724,7],"parent":727,"gender":true},
    {"question":730,"id":730,"characteristic":"    $10,000 to $19,999","normalization":[724,7],"parent":727,"gender":true},
    {"question":731,"id":731,"characteristic":"    $20,000 to $29,999","normalization":[724,7],"parent":727,"gender":true},
    {"question":732,"id":732,"characteristic":"    $30,000 to $39,999","normalization":[724,7],"parent":727,"gender":true},
    {"question":733,"id":733,"characteristic":"    $40,000 to $49,999","normalization":[724,7],"parent":727,"gender":true},
    {"question":734,"id":734,"characteristic":"    $50,000 to $59,999","normalization":[724,7],"parent":727,"gender":true},
    {"question":735,"id":735,"characteristic":"    $60,000 to $69,999","normalization":[724,7],"parent":727,"gender":true},
    {"question":736,"id":736,"characteristic":"    $70,000 to $79,999","normalization":[724,7],"parent":727,"gender":true},
    {"question":737,"id":737,"characteristic":"    $80,000 and over","normalization":[724,7],"parent":727,"gender":true},
    {"question":738,"id":738,"characteristic":"      $80,000 to $89,999","normalization":[724,7],"parent":737,"gender":true},
    {"question":739,"id":739,"characteristic":"      $90,000 to $99,999","normalization":[724,7],"parent":737,"gender":true},
    {"question":740,"id":740,"characteristic":"      $100,000 and over","normalization":[724,7],"parent":737,"gender":true},
    {"question":741,"id":741,"characteristic":"Total - Income statistics in 2015 for private households by household size - 100% data (43)","normalization":[],"parent":0,"gender":false},
    {"question":742,"id":742,"characteristic":"  Median total income of households in 2015 ($)","normalization":[],"parent":741,"gender":false},
    {"question":743,"id":743,"characteristic":"  Median after-tax income of households in 2015 ($)","normalization":[],"parent":741,"gender":false},
    {"question":744,"id":744,"characteristic":"    Total - Income statistics in 2015 for one-person private households - 100% data","normalization":[],"parent":743,"gender":false},
    {"question":745,"id":745,"characteristic":"      Median total income of one-person households in 2015 ($)","normalization":[],"parent":744,"gender":false},
    {"question":746,"id":746,"characteristic":"      Median after-tax income of one-person households in 2015 ($)","normalization":[],"parent":744,"gender":false},
    {"question":747,"id":747,"characteristic":"    Total - Income statistics in 2015 for two-or-more-person private households - 100% data","normalization":[],"parent":743,"gender":false},
    {"question":748,"id":748,"characteristic":"      Median total income of two-or-more-person households in 2015 ($)","normalization":[],"parent":747,"gender":false},
    {"question":749,"id":749,"characteristic":"      Median after-tax income of two-or-more-person households in 2015 ($)","normalization":[],"parent":747,"gender":false},
    {"question":750,"id":750,"characteristic":"Total - Income statistics in 2015 for private households by household size - 25% sample data (44)","normalization":[],"parent":0,"gender":false},
    {"question":751,"id":751,"characteristic":"  Average total income of households in 2015 ($)","normalization":[],"parent":750,"gender":false},
    {"question":752,"id":752,"characteristic":"  Average after-tax income of households in 2015 ($)","normalization":[],"parent":750,"gender":false},
    {"question":753,"id":753,"characteristic":"    Total - Income statistics in 2015 for one-person private households - 25% sample data","normalization":[],"parent":752,"gender":false},
    {"question":754,"id":754,"characteristic":"      Average total income of one-person households in 2015 ($)","normalization":[],"parent":753,"gender":false},
    {"question":755,"id":755,"characteristic":"      Average after-tax income of one-person households in 2015 ($)","normalization":[],"parent":753,"gender":false},
    {"question":756,"id":756,"characteristic":"    Total - Income statistics in 2015 for two-or-more-person private households - 25% sample data","normalization":[],"parent":752,"gender":false},
    {"question":757,"id":757,"characteristic":"      Average total income of two-or-more-person households in 2015 ($)","normalization":[],"parent":756,"gender":false},
    {"question":758,"id":758,"characteristic":"      Average after-tax income of two-or-more-person households in 2015 ($)","normalization":[],"parent":756,"gender":false},
    {"question":759,"id":759,"characteristic":"Total - Household total income groups in 2015 for private households - 100% data (45)","normalization":[],"parent":0,"gender":false},
    {"question":760,"id":760,"characteristic":"  Under $5,000","normalization":[759,7],"parent":759,"gender":false},
    {"question":761,"id":761,"characteristic":"  $5,000 to $9,999","normalization":[759,7],"parent":759,"gender":false},
    {"question":762,"id":762,"characteristic":"  $10,000 to $14,999","normalization":[759,7],"parent":759,"gender":false},
    {"question":763,"id":763,"characteristic":"  $15,000 to $19,999","normalization":[759,7],"parent":759,"gender":false},
    {"question":764,"id":764,"characteristic":"  $20,000 to $24,999","normalization":[759,7],"parent":759,"gender":false},
    {"question":765,"id":765,"characteristic":"  $25,000 to $29,999","normalization":[759,7],"parent":759,"gender":false},
    {"question":766,"id":766,"characteristic":"  $30,000 to $34,999","normalization":[759,7],"parent":759,"gender":false},
    {"question":767,"id":767,"characteristic":"  $35,000 to $39,999","normalization":[759,7],"parent":759,"gender":false},
    {"question":768,"id":768,"characteristic":"  $40,000 to $44,999","normalization":[759,7],"parent":759,"gender":false},
    {"question":769,"id":769,"characteristic":"  $45,000 to $49,999","normalization":[759,7],"parent":759,"gender":false},
    {"question":770,"id":770,"characteristic":"  $50,000 to $59,999","normalization":[759,7],"parent":759,"gender":false},
    {"question":771,"id":771,"characteristic":"  $60,000 to $69,999","normalization":[759,7],"parent":759,"gender":false},
    {"question":772,"id":772,"characteristic":"  $70,000 to $79,999","normalization":[759,7],"parent":759,"gender":false},
    {"question":773,"id":773,"characteristic":"  $80,000 to $89,999","normalization":[759,7],"parent":759,"gender":false},
    {"question":774,"id":774,"characteristic":"  $90,000 to $99,999","normalization":[759,7],"parent":759,"gender":false},
    {"question":775,"id":775,"characteristic":"  $100,000 and over","normalization":[759,7],"parent":759,"gender":false},
    {"question":776,"id":776,"characteristic":"    $100,000 to $124,999","normalization":[759,7],"parent":775,"gender":false},
    {"question":777,"id":777,"characteristic":"    $125,000 to $149,999","normalization":[759,7],"parent":775,"gender":false},
    {"question":778,"id":778,"characteristic":"    $150,000 to $199,999","normalization":[759,7],"parent":775,"gender":false},
    {"question":779,"id":779,"characteristic":"    $200,000 and over","normalization":[759,7],"parent":775,"gender":false},
    {"question":780,"id":780,"characteristic":"Total - Household after-tax income groups in 2015 for private households - 100% data (46)","normalization":[],"parent":0,"gender":false},
    {"question":781,"id":781,"characteristic":"  Under $5,000","normalization":[780,7],"parent":780,"gender":false},
    {"question":782,"id":782,"characteristic":"  $5,000 to $9,999","normalization":[780,7],"parent":780,"gender":false},
    {"question":783,"id":783,"characteristic":"  $10,000 to $14,999","normalization":[780,7],"parent":780,"gender":false},
    {"question":784,"id":784,"characteristic":"  $15,000 to $19,999","normalization":[780,7],"parent":780,"gender":false},
    {"question":785,"id":785,"characteristic":"  $20,000 to $24,999","normalization":[780,7],"parent":780,"gender":false},
    {"question":786,"id":786,"characteristic":"  $25,000 to $29,999","normalization":[780,7],"parent":780,"gender":false},
    {"question":787,"id":787,"characteristic":"  $30,000 to $34,999","normalization":[780,7],"parent":780,"gender":false},
    {"question":788,"id":788,"characteristic":"  $35,000 to $39,999","normalization":[780,7],"parent":780,"gender":false},
    {"question":789,"id":789,"characteristic":"  $40,000 to $44,999","normalization":[780,7],"parent":780,"gender":false},
    {"question":790,"id":790,"characteristic":"  $45,000 to $49,999","normalization":[780,7],"parent":780,"gender":false},
    {"question":791,"id":791,"characteristic":"  $50,000 to $59,999","normalization":[780,7],"parent":780,"gender":false},
    {"question":792,"id":792,"characteristic":"  $60,000 to $69,999","normalization":[780,7],"parent":780,"gender":false},
    {"question":793,"id":793,"characteristic":"  $70,000 to $79,999","normalization":[780,7],"parent":780,"gender":false},
    {"question":794,"id":794,"characteristic":"  $80,000 to $89,999","normalization":[780,7],"parent":780,"gender":false},
    {"question":795,"id":795,"characteristic":"  $90,000 to $99,999","normalization":[780,7],"parent":780,"gender":false},
    {"question":796,"id":796,"characteristic":"  $100,000 and over","normalization":[780,7],"parent":780,"gender":false},
    {"question":797,"id":797,"characteristic":"    $100,000 to $124,999","normalization":[780,7],"parent":796,"gender":false},
    {"question":798,"id":798,"characteristic":"    $125,000 to $149,999","normalization":[780,7],"parent":796,"gender":false},
    {"question":799,"id":799,"characteristic":"    $150,000 and over","normalization":[780,7],"parent":796,"gender":false},
    {"question":800,"id":800,"characteristic":"Total - Income statistics in 2015 for economic families in private households - 100% data (47)","normalization":[],"parent":0,"gender":false},
    {"question":801,"id":801,"characteristic":"  Median total income of economic families in 2015 ($)","normalization":[],"parent":800,"gender":false},
    {"question":802,"id":802,"characteristic":"  Median after-tax income of economic families in 2015 ($)","normalization":[],"parent":800,"gender":false},
    {"question":803,"id":803,"characteristic":"  Average family size of economic families","normalization":[],"parent":800,"gender":false},
    {"question":804,"id":804,"characteristic":"Total - Income statistics in 2015 for couple economic families without children or other relatives in private households - 100% data","normalization":[],"parent":0,"gender":false},
    {"question":805,"id":805,"characteristic":"  Median total income of couple economic families without children or other relatives in 2015 ($)","normalization":[],"parent":804,"gender":false},
    {"question":806,"id":806,"characteristic":"  Median after-tax income of couple economic families without children or other relatives in 2015 ($)","normalization":[],"parent":804,"gender":false},
    {"question":807,"id":807,"characteristic":"  Average family size of couple economic families without children or other relatives","normalization":[],"parent":804,"gender":false},
    {"question":808,"id":808,"characteristic":"Total - Income statistics in 2015 for couple economic families with children in private households - 100% data","normalization":[],"parent":0,"gender":false},
    {"question":809,"id":809,"characteristic":"  Median total income of couple economic families with children in 2015 ($)","normalization":[],"parent":808,"gender":false},
    {"question":810,"id":810,"characteristic":"  Median after-tax income of couple economic families with children in 2015 ($)","normalization":[],"parent":808,"gender":false},
    {"question":811,"id":811,"characteristic":"  Average family size of couple economic families with children","normalization":[],"parent":808,"gender":false},
    {"question":812,"id":812,"characteristic":"Total - Income statistics in 2015 for lone-parent economic families in private households - 100% data","normalization":[],"parent":0,"gender":false},
    {"question":813,"id":813,"characteristic":"  Median total income of lone-parent economic families in 2015 ($)","normalization":[],"parent":812,"gender":false},
    {"question":814,"id":814,"characteristic":"  Median after-tax income of lone-parent economic families in 2015 ($)","normalization":[],"parent":812,"gender":false},
    {"question":815,"id":815,"characteristic":"  Average family size of lone-parent economic families","normalization":[],"parent":812,"gender":false},
    {"question":816,"id":816,"characteristic":"Total - Income statistics in 2015 for economic families in private households - 25% sample data (48)","normalization":[],"parent":0,"gender":false},
    {"question":817,"id":817,"characteristic":"  Average total income of economic families in 2015 ($)","normalization":[],"parent":816,"gender":false},
    {"question":818,"id":818,"characteristic":"  Average after-tax income of economic families in 2015 ($)","normalization":[],"parent":816,"gender":false},
    {"question":819,"id":819,"characteristic":"Total - Income statistics in 2015 for couple economic families without children or other relatives in private households - 25% sample data","normalization":[],"parent":0,"gender":false},
    {"question":820,"id":820,"characteristic":"  Average total income of couple economic families without children or other relatives in 2015 ($)","normalization":[],"parent":819,"gender":false},
    {"question":821,"id":821,"characteristic":"  Average after-tax income of couple economic families without children or other relatives in 2015 ($)","normalization":[],"parent":819,"gender":false},
    {"question":822,"id":822,"characteristic":"Total - Income statistics in 2015 for couple economic families with children in private households - 25% sample data","normalization":[],"parent":0,"gender":false},
    {"question":823,"id":823,"characteristic":"  Average total income of couple economic families with children in 2015 ($)","normalization":[],"parent":822,"gender":false},
    {"question":824,"id":824,"characteristic":"  Average after-tax income of couple economic families with children in 2015 ($)","normalization":[],"parent":822,"gender":false},
    {"question":825,"id":825,"characteristic":"Total - Income statistics in 2015 for lone-parent economic families in private households - 25% sample data","normalization":[],"parent":0,"gender":false},
    {"question":826,"id":826,"characteristic":"  Average total income of lone-parent economic families in 2015 ($)","normalization":[],"parent":825,"gender":false},
    {"question":827,"id":827,"characteristic":"  Average after-tax income of lone-parent economic families in 2015 ($)","normalization":[],"parent":825,"gender":false},
    {"question":828,"id":828,"characteristic":"Total - Income statistics in 2015 for persons aged 15 years and over not in economic families in private households - 100% data (49)","normalization":[],"parent":0,"gender":true},
    {"question":829,"id":829,"characteristic":"  Median total income in 2015 for persons aged 15 years and over not in economic families ($) (50)","normalization":[],"parent":828,"gender":true},
    {"question":830,"id":830,"characteristic":"  Median after-tax income in 2015 for persons aged 15 years and over not in economic families ($) (51)","normalization":[],"parent":828,"gender":true},
    {"question":831,"id":831,"characteristic":"Total - Income statistics in 2015 for persons aged 15 years and over not in economic families in private households - 25% sample data (52)","normalization":[],"parent":0,"gender":true},
    {"question":832,"id":832,"characteristic":"  Average total income in 2015 for persons aged 15 years and over not in economic families ($) (53)","normalization":[],"parent":831,"gender":true},
    {"question":833,"id":833,"characteristic":"  Average after-tax income in 2015 for persons aged 15 years and over not in economic families ($) (54)","normalization":[],"parent":831,"gender":true},
    {"question":834,"id":834,"characteristic":"Total - Economic family income decile group for the population in private households - 100% data (55)","normalization":[],"parent":0,"gender":true},
    {"question":835,"id":835,"characteristic":"  In the bottom half of the distribution","normalization":[834,7],"parent":834,"gender":true},
    {"question":836,"id":836,"characteristic":"    In the bottom decile","normalization":[834,7],"parent":835,"gender":true},
    {"question":837,"id":837,"characteristic":"    In the second decile","normalization":[834,7],"parent":835,"gender":true},
    {"question":838,"id":838,"characteristic":"    In the third decile","normalization":[834,7],"parent":835,"gender":true},
    {"question":839,"id":839,"characteristic":"    In the fourth decile","normalization":[834,7],"parent":835,"gender":true},
    {"question":840,"id":840,"characteristic":"    In the fifth decile","normalization":[834,7],"parent":835,"gender":true},
    {"question":841,"id":841,"characteristic":"  In the top half of the distribution","normalization":[834,7],"parent":834,"gender":true},
    {"question":842,"id":842,"characteristic":"    In the sixth decile","normalization":[834,7],"parent":841,"gender":true},
    {"question":843,"id":843,"characteristic":"    In the seventh decile","normalization":[834,7],"parent":841,"gender":true},
    {"question":844,"id":844,"characteristic":"    In the eighth decile","normalization":[834,7],"parent":841,"gender":true},
    {"question":845,"id":845,"characteristic":"    In the ninth decile","normalization":[834,7],"parent":841,"gender":true},
    {"question":846,"id":846,"characteristic":"    In the top decile","normalization":[834,7],"parent":841,"gender":true},
    {"question":847,"id":847,"characteristic":"Total - Low-income status in 2015 for the population in private households to whom low-income concepts are applicable - 100% data (56)","normalization":[],"parent":0,"gender":true},
    {"question":848,"id":848,"characteristic":"  0 to 17 years","normalization":[847,7],"parent":847,"gender":true},
    {"question":849,"id":849,"characteristic":"    0 to 5 years","normalization":[847,7],"parent":848,"gender":true},
    {"question":850,"id":850,"characteristic":"  18 to 64 years","normalization":[847,7],"parent":847,"gender":true},
    {"question":851,"id":851,"characteristic":"  65 years and over","normalization":[847,7],"parent":847,"gender":true},
    {"question":852,"id":852,"characteristic":"In low income based on the Low-income measure, after tax (LIM-AT)","normalization":[1,7],"parent":0,"gender":true},
    {"question":853,"id":853,"characteristic":"  0 to 17 years","normalization":[852,7],"parent":852,"gender":true},
    {"question":854,"id":854,"characteristic":"    0 to 5 years","normalization":[852,7],"parent":853,"gender":true},
    {"question":855,"id":855,"characteristic":"  18 to 64 years","normalization":[852,7],"parent":852,"gender":true},
    {"question":856,"id":856,"characteristic":"  65 years and over","normalization":[852,7],"parent":852,"gender":true},
    {"question":857,"id":857,"characteristic":"Prevalence of low income based on the Low-income measure, after tax (LIM-AT) (%)","normalization":[],"parent":0,"gender":true},
    {"question":858,"id":858,"characteristic":"  0 to 17 years (%)","normalization":[854,7],"parent":857,"gender":true},
    {"question":859,"id":859,"characteristic":"    0 to 5 years (%)","normalization":[854,7],"parent":858,"gender":true},
    {"question":860,"id":860,"characteristic":"  18 to 64 years (%)","normalization":[854,7],"parent":857,"gender":true},
    {"question":861,"id":861,"characteristic":"  65 years and over (%)","normalization":[854,7],"parent":857,"gender":true},
    {"question":862,"id":862,"characteristic":"In low income based on the Low-income cut-offs, after tax (LICO-AT)","normalization":[1,7],"parent":0,"gender":true},
    {"question":863,"id":863,"characteristic":"  0 to 17 years","normalization":[862,7],"parent":862,"gender":true},
    {"question":864,"id":864,"characteristic":"    0 to 5 years","normalization":[862,7],"parent":863,"gender":true},
    {"question":865,"id":865,"characteristic":"  18 to 64 years","normalization":[862,7],"parent":862,"gender":true},
    {"question":866,"id":866,"characteristic":"  65 years and over","normalization":[862,7],"parent":862,"gender":true},
    {"question":867,"id":867,"characteristic":"Prevalence of low income based on the Low-income cut-offs, after tax (LICO-AT) (%)","normalization":[],"parent":0,"gender":true},
    {"question":868,"id":868,"characteristic":"  0 to 17 years (%)","normalization":[],"parent":867,"gender":true},
    {"question":869,"id":869,"characteristic":"    0 to 5 years (%)","normalization":[],"parent":868,"gender":true},
    {"question":870,"id":870,"characteristic":"  18 to 64 years (%)","normalization":[],"parent":867,"gender":true},
    {"question":871,"id":871,"characteristic":"  65 years and over (%)","normalization":[],"parent":867,"gender":true},
    {"question":872,"id":872,"characteristic":"Total - Knowledge of languages for the population in private households - 25% sample data (57)","normalization":[],"parent":0,"gender":true},
    {"question":873,"id":873,"characteristic":"  Official languages","normalization":[872,7],"parent":872,"gender":true},
    {"question":874,"id":874,"characteristic":"    English","normalization":[872,7],"parent":873,"gender":true},
    {"question":875,"id":875,"characteristic":"    French","normalization":[872,7],"parent":873,"gender":true},
    {"question":876,"id":876,"characteristic":"  Non-official languages","normalization":[872,7],"parent":872,"gender":true},
    {"question":877,"id":877,"characteristic":"    Aboriginal languages","normalization":[872,7],"parent":876,"gender":true},
    {"question":878,"id":878,"characteristic":"      Algonquian languages","normalization":[872,7],"parent":877,"gender":true},
    {"question":879,"id":879,"characteristic":"        Blackfoot","normalization":[872,7],"parent":878,"gender":true},
    {"question":880,"id":880,"characteristic":"        Cree-Montagnais languages","normalization":[872,7],"parent":878,"gender":true},
    {"question":881,"id":881,"characteristic":"          Atikamekw","normalization":[872,7],"parent":880,"gender":true},
    {"question":882,"id":882,"characteristic":"          Montagnais (Innu)","normalization":[872,7],"parent":880,"gender":true},
    {"question":883,"id":883,"characteristic":"          Moose Cree","normalization":[872,7],"parent":880,"gender":true},
    {"question":884,"id":884,"characteristic":"          Naskapi","normalization":[872,7],"parent":880,"gender":true},
    {"question":885,"id":885,"characteristic":"          Northern East Cree","normalization":[872,7],"parent":880,"gender":true},
    {"question":886,"id":886,"characteristic":"          Plains Cree","normalization":[872,7],"parent":880,"gender":true},
    {"question":887,"id":887,"characteristic":"          Southern East Cree","normalization":[872,7],"parent":880,"gender":true},
    {"question":888,"id":888,"characteristic":"          Swampy Cree","normalization":[872,7],"parent":880,"gender":true},
    {"question":889,"id":889,"characteristic":"          Woods Cree","normalization":[872,7],"parent":880,"gender":true},
    {"question":890,"id":890,"characteristic":"          Cree, n.o.s.","normalization":[872,7],"parent":880,"gender":true},
    {"question":891,"id":891,"characteristic":"        Eastern Algonquian languages","normalization":[872,7],"parent":878,"gender":true},
    {"question":892,"id":892,"characteristic":"          Malecite","normalization":[872,7],"parent":891,"gender":true},
    {"question":893,"id":893,"characteristic":"          Mi'kmaq","normalization":[872,7],"parent":891,"gender":true},
    {"question":894,"id":894,"characteristic":"        Ojibway-Potawatomi languages","normalization":[872,7],"parent":878,"gender":true},
    {"question":895,"id":895,"characteristic":"          Algonquin","normalization":[872,7],"parent":894,"gender":true},
    {"question":896,"id":896,"characteristic":"          Ojibway","normalization":[872,7],"parent":894,"gender":true},
    {"question":897,"id":897,"characteristic":"          Oji-Cree","normalization":[872,7],"parent":894,"gender":true},
    {"question":898,"id":898,"characteristic":"          Ottawa (Odawa)","normalization":[872,7],"parent":894,"gender":true},
    {"question":899,"id":899,"characteristic":"        Algonquian languages, n.i.e.","normalization":[872,7],"parent":878,"gender":true},
    {"question":900,"id":900,"characteristic":"      Athabaskan languages","normalization":[872,7],"parent":877,"gender":true},
    {"question":901,"id":901,"characteristic":"        Northern Athabaskan languages","normalization":[872,7],"parent":900,"gender":true},
    {"question":902,"id":902,"characteristic":"          Babine (Wetsuwet'en)","normalization":[872,7],"parent":901,"gender":true},
    {"question":903,"id":903,"characteristic":"          Beaver","normalization":[872,7],"parent":901,"gender":true},
    {"question":904,"id":904,"characteristic":"          Carrier","normalization":[872,7],"parent":901,"gender":true},
    {"question":905,"id":905,"characteristic":"          Chilcotin","normalization":[872,7],"parent":901,"gender":true},
    {"question":906,"id":906,"characteristic":"          Dene","normalization":[872,7],"parent":901,"gender":true},
    {"question":907,"id":907,"characteristic":"          Dogrib (Tlicho)","normalization":[872,7],"parent":901,"gender":true},
    {"question":908,"id":908,"characteristic":"          Gwich'in","normalization":[872,7],"parent":901,"gender":true},
    {"question":909,"id":909,"characteristic":"          Sarsi (Sarcee)","normalization":[872,7],"parent":901,"gender":true},
    {"question":910,"id":910,"characteristic":"          Sekani","normalization":[872,7],"parent":901,"gender":true},
    {"question":911,"id":911,"characteristic":"          Slavey-Hare languages","normalization":[872,7],"parent":901,"gender":true},
    {"question":912,"id":912,"characteristic":"            North Slavey (Hare)","normalization":[872,7],"parent":911,"gender":true},
    {"question":913,"id":913,"characteristic":"            South Slavey","normalization":[872,7],"parent":911,"gender":true},
    {"question":914,"id":914,"characteristic":"            Slavey, n.o.s.","normalization":[872,7],"parent":911,"gender":true},
    {"question":915,"id":915,"characteristic":"          Tahltan languages","normalization":[872,7],"parent":901,"gender":true},
    {"question":916,"id":916,"characteristic":"            Kaska (Nahani)","normalization":[872,7],"parent":915,"gender":true},
    {"question":917,"id":917,"characteristic":"            Tahltan","normalization":[872,7],"parent":915,"gender":true},
    {"question":918,"id":918,"characteristic":"          Tutchone languages","normalization":[872,7],"parent":901,"gender":true},
    {"question":919,"id":919,"characteristic":"            Northern Tutchone","normalization":[872,7],"parent":918,"gender":true},
    {"question":920,"id":920,"characteristic":"            Southern Tutchone","normalization":[872,7],"parent":918,"gender":true},
    {"question":921,"id":921,"characteristic":"        Athabaskan languages, n.i.e.","normalization":[872,7],"parent":900,"gender":true},
    {"question":922,"id":922,"characteristic":"      Haida","normalization":[872,7],"parent":877,"gender":true},
    {"question":923,"id":923,"characteristic":"      Inuit languages","normalization":[872,7],"parent":877,"gender":true},
    {"question":924,"id":924,"characteristic":"        Inuinnaqtun (Inuvialuktun)","normalization":[872,7],"parent":923,"gender":true},
    {"question":925,"id":925,"characteristic":"        Inuktitut","normalization":[872,7],"parent":923,"gender":true},
    {"question":926,"id":926,"characteristic":"        Inuit languages, n.i.e.","normalization":[872,7],"parent":923,"gender":true},
    {"question":927,"id":927,"characteristic":"      Iroquoian languages","normalization":[872,7],"parent":877,"gender":true},
    {"question":928,"id":928,"characteristic":"        Cayuga","normalization":[872,7],"parent":927,"gender":true},
    {"question":929,"id":929,"characteristic":"        Mohawk","normalization":[872,7],"parent":927,"gender":true},
    {"question":930,"id":930,"characteristic":"        Oneida","normalization":[872,7],"parent":927,"gender":true},
    {"question":931,"id":931,"characteristic":"        Iroquoian languages, n.i.e.","normalization":[872,7],"parent":927,"gender":true},
    {"question":932,"id":932,"characteristic":"      Kutenai","normalization":[872,7],"parent":877,"gender":true},
    {"question":933,"id":933,"characteristic":"      Michif","normalization":[872,7],"parent":877,"gender":true},
    {"question":934,"id":934,"characteristic":"      Salish languages","normalization":[872,7],"parent":877,"gender":true},
    {"question":935,"id":935,"characteristic":"        Comox","normalization":[872,7],"parent":934,"gender":true},
    {"question":936,"id":936,"characteristic":"        Halkomelem","normalization":[872,7],"parent":934,"gender":true},
    {"question":937,"id":937,"characteristic":"        Lillooet","normalization":[872,7],"parent":934,"gender":true},
    {"question":938,"id":938,"characteristic":"        Okanagan","normalization":[872,7],"parent":934,"gender":true},
    {"question":939,"id":939,"characteristic":"        Shuswap (Secwepemctsin)","normalization":[872,7],"parent":934,"gender":true},
    {"question":940,"id":940,"characteristic":"        Squamish","normalization":[872,7],"parent":934,"gender":true},
    {"question":941,"id":941,"characteristic":"        Straits","normalization":[872,7],"parent":934,"gender":true},
    {"question":942,"id":942,"characteristic":"        Thompson (Ntlakapamux)","normalization":[872,7],"parent":934,"gender":true},
    {"question":943,"id":943,"characteristic":"        Salish languages, n.i.e.","normalization":[872,7],"parent":934,"gender":true},
    {"question":944,"id":944,"characteristic":"      Siouan languages","normalization":[872,7],"parent":877,"gender":true},
    {"question":945,"id":945,"characteristic":"        Dakota","normalization":[872,7],"parent":944,"gender":true},
    {"question":946,"id":946,"characteristic":"        Stoney","normalization":[872,7],"parent":944,"gender":true},
    {"question":947,"id":947,"characteristic":"        Siouan languages, n.i.e.","normalization":[872,7],"parent":944,"gender":true},
    {"question":948,"id":948,"characteristic":"      Tlingit","normalization":[872,7],"parent":877,"gender":true},
    {"question":949,"id":949,"characteristic":"      Tsimshian languages","normalization":[872,7],"parent":877,"gender":true},
    {"question":950,"id":950,"characteristic":"        Gitxsan (Gitksan)","normalization":[872,7],"parent":949,"gender":true},
    {"question":951,"id":951,"characteristic":"        Nisga'a","normalization":[872,7],"parent":949,"gender":true},
    {"question":952,"id":952,"characteristic":"        Tsimshian","normalization":[872,7],"parent":949,"gender":true},
    {"question":953,"id":953,"characteristic":"      Wakashan languages","normalization":[872,7],"parent":877,"gender":true},
    {"question":954,"id":954,"characteristic":"        Haisla","normalization":[872,7],"parent":953,"gender":true},
    {"question":955,"id":955,"characteristic":"        Heiltsuk","normalization":[872,7],"parent":953,"gender":true},
    {"question":956,"id":956,"characteristic":"        Kwakiutl (Kwak'wala)","normalization":[872,7],"parent":953,"gender":true},
    {"question":957,"id":957,"characteristic":"        Nuu-chah-nulth (Nootka)","normalization":[872,7],"parent":953,"gender":true},
    {"question":958,"id":958,"characteristic":"        Wakashan languages, n.i.e.","normalization":[872,7],"parent":953,"gender":true},
    {"question":959,"id":959,"characteristic":"      Aboriginal languages, n.o.s.","normalization":[872,7],"parent":877,"gender":true},
    {"question":960,"id":960,"characteristic":"    Non-Aboriginal languages","normalization":[872,7],"parent":876,"gender":true},
    {"question":961,"id":961,"characteristic":"      Afro-Asiatic languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":962,"id":962,"characteristic":"        Berber languages","normalization":[872,7],"parent":961,"gender":true},
    {"question":963,"id":963,"characteristic":"          Kabyle","normalization":[872,7],"parent":962,"gender":true},
    {"question":964,"id":964,"characteristic":"          Berber languages, n.i.e.","normalization":[872,7],"parent":962,"gender":true},
    {"question":965,"id":965,"characteristic":"        Cushitic languages","normalization":[872,7],"parent":961,"gender":true},
    {"question":966,"id":966,"characteristic":"          Bilen","normalization":[872,7],"parent":965,"gender":true},
    {"question":967,"id":967,"characteristic":"          Oromo","normalization":[872,7],"parent":965,"gender":true},
    {"question":968,"id":968,"characteristic":"          Somali","normalization":[872,7],"parent":965,"gender":true},
    {"question":969,"id":969,"characteristic":"          Cushitic languages, n.i.e.","normalization":[872,7],"parent":965,"gender":true},
    {"question":970,"id":970,"characteristic":"        Semitic languages","normalization":[872,7],"parent":961,"gender":true},
    {"question":971,"id":971,"characteristic":"          Amharic","normalization":[872,7],"parent":970,"gender":true},
    {"question":972,"id":972,"characteristic":"          Arabic","normalization":[872,7],"parent":970,"gender":true},
    {"question":973,"id":973,"characteristic":"          Assyrian Neo-Aramaic","normalization":[872,7],"parent":970,"gender":true},
    {"question":974,"id":974,"characteristic":"          Chaldean Neo-Aramaic","normalization":[872,7],"parent":970,"gender":true},
    {"question":975,"id":975,"characteristic":"          Harari","normalization":[872,7],"parent":970,"gender":true},
    {"question":976,"id":976,"characteristic":"          Hebrew","normalization":[872,7],"parent":970,"gender":true},
    {"question":977,"id":977,"characteristic":"          Maltese","normalization":[872,7],"parent":970,"gender":true},
    {"question":978,"id":978,"characteristic":"          Tigrigna","normalization":[872,7],"parent":970,"gender":true},
    {"question":979,"id":979,"characteristic":"          Semitic languages, n.i.e.","normalization":[872,7],"parent":970,"gender":true},
    {"question":980,"id":980,"characteristic":"        Afro-Asiatic languages, n.i.e.","normalization":[872,7],"parent":961,"gender":true},
    {"question":981,"id":981,"characteristic":"      Austro-Asiatic languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":982,"id":982,"characteristic":"        Khmer (Cambodian)","normalization":[872,7],"parent":981,"gender":true},
    {"question":983,"id":983,"characteristic":"        Vietnamese","normalization":[872,7],"parent":981,"gender":true},
    {"question":984,"id":984,"characteristic":"        Austro-Asiatic languages, n.i.e","normalization":[872,7],"parent":981,"gender":true},
    {"question":985,"id":985,"characteristic":"      Austronesian languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":986,"id":986,"characteristic":"        Bikol","normalization":[872,7],"parent":985,"gender":true},
    {"question":987,"id":987,"characteristic":"        Cebuano","normalization":[872,7],"parent":985,"gender":true},
    {"question":988,"id":988,"characteristic":"        Fijian","normalization":[872,7],"parent":985,"gender":true},
    {"question":989,"id":989,"characteristic":"        Hiligaynon","normalization":[872,7],"parent":985,"gender":true},
    {"question":990,"id":990,"characteristic":"        Ilocano","normalization":[872,7],"parent":985,"gender":true},
    {"question":991,"id":991,"characteristic":"        Malagasy","normalization":[872,7],"parent":985,"gender":true},
    {"question":992,"id":992,"characteristic":"        Malay","normalization":[872,7],"parent":985,"gender":true},
    {"question":993,"id":993,"characteristic":"        Pampangan (Kapampangan, Pampango)","normalization":[872,7],"parent":985,"gender":true},
    {"question":994,"id":994,"characteristic":"        Pangasinan","normalization":[872,7],"parent":985,"gender":true},
    {"question":995,"id":995,"characteristic":"        Tagalog (Pilipino, Filipino)","normalization":[872,7],"parent":985,"gender":true},
    {"question":996,"id":996,"characteristic":"        Waray-Waray","normalization":[872,7],"parent":985,"gender":true},
    {"question":997,"id":997,"characteristic":"        Austronesian languages, n.i.e.","normalization":[872,7],"parent":985,"gender":true},
    {"question":998,"id":998,"characteristic":"      Creole languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":999,"id":999,"characteristic":"        Haitian Creole","normalization":[872,7],"parent":998,"gender":true},
    {"question":1000,"id":1000,"characteristic":"        Creole, n.o.s.","normalization":[872,7],"parent":998,"gender":true},
    {"question":1001,"id":1001,"characteristic":"        Creole languages, n.i.e.","normalization":[872,7],"parent":998,"gender":true},
    {"question":1002,"id":1002,"characteristic":"      Dravidian languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":1003,"id":1003,"characteristic":"        Kannada","normalization":[872,7],"parent":1002,"gender":true},
    {"question":1004,"id":1004,"characteristic":"        Malayalam","normalization":[872,7],"parent":1002,"gender":true},
    {"question":1005,"id":1005,"characteristic":"        Tamil","normalization":[872,7],"parent":1002,"gender":true},
    {"question":1006,"id":1006,"characteristic":"        Telugu","normalization":[872,7],"parent":1002,"gender":true},
    {"question":1007,"id":1007,"characteristic":"        Dravidian languages, n.i.e.","normalization":[872,7],"parent":1002,"gender":true},
    {"question":1008,"id":1008,"characteristic":"      Hmong-Mien languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":1009,"id":1009,"characteristic":"      Indo-European languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":1010,"id":1010,"characteristic":"        Albanian","normalization":[872,7],"parent":1009,"gender":true},
    {"question":1011,"id":1011,"characteristic":"        Armenian","normalization":[872,7],"parent":1009,"gender":true},
    {"question":1012,"id":1012,"characteristic":"        Balto-Slavic languages","normalization":[872,7],"parent":1009,"gender":true},
    {"question":1013,"id":1013,"characteristic":"          Baltic languages","normalization":[872,7],"parent":1012,"gender":true},
    {"question":1014,"id":1014,"characteristic":"            Latvian","normalization":[872,7],"parent":1013,"gender":true},
    {"question":1015,"id":1015,"characteristic":"            Lithuanian","normalization":[872,7],"parent":1013,"gender":true},
    {"question":1016,"id":1016,"characteristic":"          Slavic languages","normalization":[872,7],"parent":1012,"gender":true},
    {"question":1017,"id":1017,"characteristic":"            Belarusan","normalization":[872,7],"parent":1016,"gender":true},
    {"question":1018,"id":1018,"characteristic":"            Bosnian","normalization":[872,7],"parent":1016,"gender":true},
    {"question":1019,"id":1019,"characteristic":"            Bulgarian","normalization":[872,7],"parent":1016,"gender":true},
    {"question":1020,"id":1020,"characteristic":"            Croatian","normalization":[872,7],"parent":1016,"gender":true},
    {"question":1021,"id":1021,"characteristic":"            Czech","normalization":[872,7],"parent":1016,"gender":true},
    {"question":1022,"id":1022,"characteristic":"            Macedonian","normalization":[872,7],"parent":1016,"gender":true},
    {"question":1023,"id":1023,"characteristic":"            Polish","normalization":[872,7],"parent":1016,"gender":true},
    {"question":1024,"id":1024,"characteristic":"            Russian","normalization":[872,7],"parent":1016,"gender":true},
    {"question":1025,"id":1025,"characteristic":"            Serbian","normalization":[872,7],"parent":1016,"gender":true},
    {"question":1026,"id":1026,"characteristic":"            Serbo-Croatian","normalization":[872,7],"parent":1016,"gender":true},
    {"question":1027,"id":1027,"characteristic":"            Slovak","normalization":[872,7],"parent":1016,"gender":true},
    {"question":1028,"id":1028,"characteristic":"            Slovene (Slovenian)","normalization":[872,7],"parent":1016,"gender":true},
    {"question":1029,"id":1029,"characteristic":"            Ukrainian","normalization":[872,7],"parent":1016,"gender":true},
    {"question":1030,"id":1030,"characteristic":"            Slavic languages, n.i.e.","normalization":[872,7],"parent":1016,"gender":true},
    {"question":1031,"id":1031,"characteristic":"        Celtic languages","normalization":[872,7],"parent":1009,"gender":true},
    {"question":1032,"id":1032,"characteristic":"          Scottish Gaelic","normalization":[872,7],"parent":1031,"gender":true},
    {"question":1033,"id":1033,"characteristic":"          Welsh","normalization":[872,7],"parent":1031,"gender":true},
    {"question":1034,"id":1034,"characteristic":"          Celtic languages, n.i.e.","normalization":[872,7],"parent":1031,"gender":true},
    {"question":1035,"id":1035,"characteristic":"        Germanic languages","normalization":[872,7],"parent":1009,"gender":true},
    {"question":1036,"id":1036,"characteristic":"          Afrikaans","normalization":[872,7],"parent":1035,"gender":true},
    {"question":1037,"id":1037,"characteristic":"          Danish","normalization":[872,7],"parent":1035,"gender":true},
    {"question":1038,"id":1038,"characteristic":"          Dutch","normalization":[872,7],"parent":1035,"gender":true},
    {"question":1039,"id":1039,"characteristic":"          Frisian","normalization":[872,7],"parent":1035,"gender":true},
    {"question":1040,"id":1040,"characteristic":"          German","normalization":[872,7],"parent":1035,"gender":true},
    {"question":1041,"id":1041,"characteristic":"          Icelandic","normalization":[872,7],"parent":1035,"gender":true},
    {"question":1042,"id":1042,"characteristic":"          Norwegian","normalization":[872,7],"parent":1035,"gender":true},
    {"question":1043,"id":1043,"characteristic":"          Swedish","normalization":[872,7],"parent":1035,"gender":true},
    {"question":1044,"id":1044,"characteristic":"          Vlaams (Flemish)","normalization":[872,7],"parent":1035,"gender":true},
    {"question":1045,"id":1045,"characteristic":"          Yiddish","normalization":[872,7],"parent":1035,"gender":true},
    {"question":1046,"id":1046,"characteristic":"          Germanic languages, n.i.e.","normalization":[872,7],"parent":1035,"gender":true},
    {"question":1047,"id":1047,"characteristic":"        Greek","normalization":[872,7],"parent":1009,"gender":true},
    {"question":1048,"id":1048,"characteristic":"        Indo-Iranian languages","normalization":[872,7],"parent":1009,"gender":true},
    {"question":1049,"id":1049,"characteristic":"          Indo-Aryan languages","normalization":[872,7],"parent":1048,"gender":true},
    {"question":1050,"id":1050,"characteristic":"            Bengali","normalization":[872,7],"parent":1049,"gender":true},
    {"question":1051,"id":1051,"characteristic":"            Gujarati","normalization":[872,7],"parent":1049,"gender":true},
    {"question":1052,"id":1052,"characteristic":"            Hindi","normalization":[872,7],"parent":1049,"gender":true},
    {"question":1053,"id":1053,"characteristic":"            Kashmiri","normalization":[872,7],"parent":1049,"gender":true},
    {"question":1054,"id":1054,"characteristic":"            Konkani","normalization":[872,7],"parent":1049,"gender":true},
    {"question":1055,"id":1055,"characteristic":"            Marathi","normalization":[872,7],"parent":1049,"gender":true},
    {"question":1056,"id":1056,"characteristic":"            Nepali","normalization":[872,7],"parent":1049,"gender":true},
    {"question":1057,"id":1057,"characteristic":"            Oriya (Odia)","normalization":[872,7],"parent":1049,"gender":true},
    {"question":1058,"id":1058,"characteristic":"            Punjabi (Panjabi)","normalization":[872,7],"parent":1049,"gender":true},
    {"question":1059,"id":1059,"characteristic":"            Sindhi","normalization":[872,7],"parent":1049,"gender":true},
    {"question":1060,"id":1060,"characteristic":"            Sinhala (Sinhalese)","normalization":[872,7],"parent":1049,"gender":true},
    {"question":1061,"id":1061,"characteristic":"            Urdu","normalization":[872,7],"parent":1049,"gender":true},
    {"question":1062,"id":1062,"characteristic":"          Iranian languages","normalization":[872,7],"parent":1048,"gender":true},
    {"question":1063,"id":1063,"characteristic":"            Kurdish","normalization":[872,7],"parent":1062,"gender":true},
    {"question":1064,"id":1064,"characteristic":"            Pashto","normalization":[872,7],"parent":1062,"gender":true},
    {"question":1065,"id":1065,"characteristic":"            Persian (Farsi)","normalization":[872,7],"parent":1062,"gender":true},
    {"question":1066,"id":1066,"characteristic":"          Indo-Iranian languages, n.i.e.","normalization":[872,7],"parent":1048,"gender":true},
    {"question":1067,"id":1067,"characteristic":"        Italic (Romance) languages","normalization":[872,7],"parent":1009,"gender":true},
    {"question":1068,"id":1068,"characteristic":"          Catalan","normalization":[872,7],"parent":1067,"gender":true},
    {"question":1069,"id":1069,"characteristic":"          Italian","normalization":[872,7],"parent":1067,"gender":true},
    {"question":1070,"id":1070,"characteristic":"          Portuguese","normalization":[872,7],"parent":1067,"gender":true},
    {"question":1071,"id":1071,"characteristic":"          Romanian","normalization":[872,7],"parent":1067,"gender":true},
    {"question":1072,"id":1072,"characteristic":"          Spanish","normalization":[872,7],"parent":1067,"gender":true},
    {"question":1073,"id":1073,"characteristic":"          Italic (Romance) languages, n.i.e.","normalization":[872,7],"parent":1067,"gender":true},
    {"question":1074,"id":1074,"characteristic":"      Japanese","normalization":[872,7],"parent":960,"gender":true},
    {"question":1075,"id":1075,"characteristic":"      Kartvelian languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":1076,"id":1076,"characteristic":"        Georgian","normalization":[872,7],"parent":1075,"gender":true},
    {"question":1077,"id":1077,"characteristic":"      Korean","normalization":[872,7],"parent":960,"gender":true},
    {"question":1078,"id":1078,"characteristic":"      Mongolic languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":1079,"id":1079,"characteristic":"        Mongolian","normalization":[872,7],"parent":1078,"gender":true},
    {"question":1080,"id":1080,"characteristic":"      Niger-Congo languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":1081,"id":1081,"characteristic":"        Akan (Twi)","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1082,"id":1082,"characteristic":"        Bamanankan","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1083,"id":1083,"characteristic":"        Edo","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1084,"id":1084,"characteristic":"        Ewe","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1085,"id":1085,"characteristic":"        Fulah (Pular, Pulaar, Fulfulde)","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1086,"id":1086,"characteristic":"        Ga","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1087,"id":1087,"characteristic":"        Ganda","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1088,"id":1088,"characteristic":"        Igbo","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1089,"id":1089,"characteristic":"        Lingala","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1090,"id":1090,"characteristic":"        Rundi (Kirundi)","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1091,"id":1091,"characteristic":"        Kinyarwanda (Rwanda)","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1092,"id":1092,"characteristic":"        Shona","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1093,"id":1093,"characteristic":"        Swahili","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1094,"id":1094,"characteristic":"        Wolof","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1095,"id":1095,"characteristic":"        Yoruba","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1096,"id":1096,"characteristic":"        Niger-Congo languages, n.i.e.","normalization":[872,7],"parent":1080,"gender":true},
    {"question":1097,"id":1097,"characteristic":"      Nilo-Saharan languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":1098,"id":1098,"characteristic":"        Dinka","normalization":[872,7],"parent":1097,"gender":true},
    {"question":1099,"id":1099,"characteristic":"        Nilo-Saharan languages, n.i.e.","normalization":[872,7],"parent":1097,"gender":true},
    {"question":1100,"id":1100,"characteristic":"      Sign languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":1101,"id":1101,"characteristic":"        American Sign Language","normalization":[872,7],"parent":1100,"gender":true},
    {"question":1102,"id":1102,"characteristic":"        Quebec Sign Language","normalization":[872,7],"parent":1100,"gender":true},
    {"question":1103,"id":1103,"characteristic":"        Sign languages, n.i.e","normalization":[872,7],"parent":1100,"gender":true},
    {"question":1104,"id":1104,"characteristic":"      Sino-Tibetan languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":1105,"id":1105,"characteristic":"        Chinese languages","normalization":[872,7],"parent":1104,"gender":true},
    {"question":1106,"id":1106,"characteristic":"          Cantonese","normalization":[872,7],"parent":1105,"gender":true},
    {"question":1107,"id":1107,"characteristic":"          Hakka","normalization":[872,7],"parent":1105,"gender":true},
    {"question":1108,"id":1108,"characteristic":"          Mandarin","normalization":[872,7],"parent":1105,"gender":true},
    {"question":1109,"id":1109,"characteristic":"          Min Dong","normalization":[872,7],"parent":1105,"gender":true},
    {"question":1110,"id":1110,"characteristic":"          Min Nan (Chaochow, Teochow, Fukien, Taiwanese)","normalization":[872,7],"parent":1105,"gender":true},
    {"question":1111,"id":1111,"characteristic":"          Wu (Shanghainese)","normalization":[872,7],"parent":1105,"gender":true},
    {"question":1112,"id":1112,"characteristic":"          Chinese, n.o.s.","normalization":[872,7],"parent":1105,"gender":true},
    {"question":1113,"id":1113,"characteristic":"          Chinese languages, n.i.e.","normalization":[872,7],"parent":1105,"gender":true},
    {"question":1114,"id":1114,"characteristic":"        Tibeto-Burman languages","normalization":[872,7],"parent":1104,"gender":true},
    {"question":1115,"id":1115,"characteristic":"          Burmese","normalization":[872,7],"parent":1114,"gender":true},
    {"question":1116,"id":1116,"characteristic":"          Karenic languages","normalization":[872,7],"parent":1114,"gender":true},
    {"question":1117,"id":1117,"characteristic":"          Tibetan","normalization":[872,7],"parent":1114,"gender":true},
    {"question":1118,"id":1118,"characteristic":"          Tibeto-Burman languages, n.i.e.","normalization":[872,7],"parent":1114,"gender":true},
    {"question":1119,"id":1119,"characteristic":"      Tai-Kadai languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":1120,"id":1120,"characteristic":"        Lao","normalization":[872,7],"parent":1119,"gender":true},
    {"question":1121,"id":1121,"characteristic":"        Thai","normalization":[872,7],"parent":1119,"gender":true},
    {"question":1122,"id":1122,"characteristic":"        Tai-Kadai languages, n.i.e","normalization":[872,7],"parent":1119,"gender":true},
    {"question":1123,"id":1123,"characteristic":"      Turkic languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":1124,"id":1124,"characteristic":"        Azerbaijani","normalization":[872,7],"parent":1123,"gender":true},
    {"question":1125,"id":1125,"characteristic":"        Turkish","normalization":[872,7],"parent":1123,"gender":true},
    {"question":1126,"id":1126,"characteristic":"        Uyghur","normalization":[872,7],"parent":1123,"gender":true},
    {"question":1127,"id":1127,"characteristic":"        Uzbek","normalization":[872,7],"parent":1123,"gender":true},
    {"question":1128,"id":1128,"characteristic":"        Turkic languages, n.i.e.","normalization":[872,7],"parent":1123,"gender":true},
    {"question":1129,"id":1129,"characteristic":"      Uralic languages","normalization":[872,7],"parent":960,"gender":true},
    {"question":1130,"id":1130,"characteristic":"        Estonian","normalization":[872,7],"parent":1129,"gender":true},
    {"question":1131,"id":1131,"characteristic":"        Finnish","normalization":[872,7],"parent":1129,"gender":true},
    {"question":1132,"id":1132,"characteristic":"        Hungarian","normalization":[872,7],"parent":1129,"gender":true},
    {"question":1133,"id":1133,"characteristic":"        Uralic languages, n.i.e.","normalization":[872,7],"parent":1129,"gender":true},
    {"question":1134,"id":1134,"characteristic":"      Other languages, n.i.e.","normalization":[872,7],"parent":960,"gender":true},
    {"question":1135,"id":1135,"characteristic":"Total - Citizenship for the population in private households - 25% sample data (60)","normalization":[],"parent":0,"gender":true},
    {"question":1136,"id":1136,"characteristic":"  Canadian citizens (61)","normalization":[1135,7],"parent":1135,"gender":true},
    {"question":1137,"id":1137,"characteristic":"    Canadian citizens aged under 18","normalization":[1135,7],"parent":1136,"gender":true},
    {"question":1138,"id":1138,"characteristic":"    Canadian citizens aged 18 and over","normalization":[1135,7],"parent":1136,"gender":true},
    {"question":1139,"id":1139,"characteristic":"  Not Canadian citizens (62)","normalization":[1135,7],"parent":1135,"gender":true},
    {"question":1140,"id":1140,"characteristic":"Total - Immigrant status and period of immigration for the population in private households - 25% sample data (63)","normalization":[],"parent":0,"gender":true},
    {"question":1141,"id":1141,"characteristic":"  Non-immigrants (64)","normalization":[1140,7],"parent":1140,"gender":true},
    {"question":1142,"id":1142,"characteristic":"  Immigrants (65)","normalization":[1140,7],"parent":1140,"gender":true},
    {"question":1143,"id":1143,"characteristic":"    Before 1981","normalization":[1140,7],"parent":1142,"gender":true},
    {"question":1144,"id":1144,"characteristic":"    1981 to 1990","normalization":[1140,7],"parent":1142,"gender":true},
    {"question":1145,"id":1145,"characteristic":"    1991 to 2000","normalization":[1140,7],"parent":1142,"gender":true},
    {"question":1146,"id":1146,"characteristic":"    2001 to 2010","normalization":[1140,7],"parent":1142,"gender":true},
    {"question":1147,"id":1147,"characteristic":"      2001 to 2005","normalization":[1140,7],"parent":1146,"gender":true},
    {"question":1148,"id":1148,"characteristic":"      2006 to 2010","normalization":[1140,7],"parent":1146,"gender":true},
    {"question":1149,"id":1149,"characteristic":"    2011 to 2016 (66)","normalization":[1140,7],"parent":1142,"gender":true},
    {"question":1150,"id":1150,"characteristic":"  Non-permanent residents (67)","normalization":[1140,7],"parent":1140,"gender":true},
    {"question":1151,"id":1151,"characteristic":"Total - Age at immigration for the immigrant population in private households - 25% sample data (68)","normalization":[],"parent":0,"gender":true},
    {"question":1152,"id":1152,"characteristic":"  Under 5 years","normalization":[1151,7],"parent":1151,"gender":true},
    {"question":1153,"id":1153,"characteristic":"  5 to 14 years","normalization":[1151,7],"parent":1151,"gender":true},
    {"question":1154,"id":1154,"characteristic":"  15 to 24 years","normalization":[1151,7],"parent":1151,"gender":true},
    {"question":1155,"id":1155,"characteristic":"  25 to 44 years","normalization":[1151,7],"parent":1151,"gender":true},
    {"question":1156,"id":1156,"characteristic":"  45 years and over","normalization":[1151,7],"parent":1151,"gender":true},
    {"question":1157,"id":1157,"characteristic":"Total - Selected places of birth for the immigrant population in private households - 25% sample data (69)","normalization":[],"parent":0,"gender":true},
    {"question":1158,"id":1158,"characteristic":"  Americas","normalization":[1157,7],"parent":1157,"gender":true},
    {"question":1159,"id":1159,"characteristic":"    Brazil","normalization":[1157,7],"parent":1158,"gender":true},
    {"question":1160,"id":1160,"characteristic":"    Colombia","normalization":[1157,7],"parent":1158,"gender":true},
    {"question":1161,"id":1161,"characteristic":"    El Salvador","normalization":[1157,7],"parent":1158,"gender":true},
    {"question":1162,"id":1162,"characteristic":"    Guyana","normalization":[1157,7],"parent":1158,"gender":true},
    {"question":1163,"id":1163,"characteristic":"    Haiti","normalization":[1157,7],"parent":1158,"gender":true},
    {"question":1164,"id":1164,"characteristic":"    Jamaica","normalization":[1157,7],"parent":1158,"gender":true},
    {"question":1165,"id":1165,"characteristic":"    Mexico","normalization":[1157,7],"parent":1158,"gender":true},
    {"question":1166,"id":1166,"characteristic":"    Peru","normalization":[1157,7],"parent":1158,"gender":true},
    {"question":1167,"id":1167,"characteristic":"    Trinidad and Tobago","normalization":[1157,7],"parent":1158,"gender":true},
    {"question":1168,"id":1168,"characteristic":"    United States (70)","normalization":[1157,7],"parent":1158,"gender":true},
    {"question":1169,"id":1169,"characteristic":"    Other places of birth in Americas","normalization":[1157,7],"parent":1158,"gender":true},
    {"question":1170,"id":1170,"characteristic":"  Europe","normalization":[1157,7],"parent":1157,"gender":true},
    {"question":1171,"id":1171,"characteristic":"    Bosnia and Herzegovina","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1172,"id":1172,"characteristic":"    Croatia","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1173,"id":1173,"characteristic":"    France","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1174,"id":1174,"characteristic":"    Germany","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1175,"id":1175,"characteristic":"    Greece","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1176,"id":1176,"characteristic":"    Hungary","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1177,"id":1177,"characteristic":"    Ireland (71)","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1178,"id":1178,"characteristic":"    Italy","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1179,"id":1179,"characteristic":"    Netherlands","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1180,"id":1180,"characteristic":"    Poland","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1181,"id":1181,"characteristic":"    Portugal","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1182,"id":1182,"characteristic":"    Romania","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1183,"id":1183,"characteristic":"    Russian Federation","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1184,"id":1184,"characteristic":"    Serbia (72)","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1185,"id":1185,"characteristic":"    Ukraine","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1186,"id":1186,"characteristic":"    United Kingdom (73)","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1187,"id":1187,"characteristic":"    Other places of birth in Europe","normalization":[1157,7],"parent":1170,"gender":true},
    {"question":1188,"id":1188,"characteristic":"  Africa","normalization":[1157,7],"parent":1157,"gender":true},
    {"question":1189,"id":1189,"characteristic":"    Algeria","normalization":[1157,7],"parent":1188,"gender":true},
    {"question":1190,"id":1190,"characteristic":"    Egypt","normalization":[1157,7],"parent":1188,"gender":true},
    {"question":1191,"id":1191,"characteristic":"    Ethiopia","normalization":[1157,7],"parent":1188,"gender":true},
    {"question":1192,"id":1192,"characteristic":"    Kenya","normalization":[1157,7],"parent":1188,"gender":true},
    {"question":1193,"id":1193,"characteristic":"    Morocco","normalization":[1157,7],"parent":1188,"gender":true},
    {"question":1194,"id":1194,"characteristic":"    Nigeria","normalization":[1157,7],"parent":1188,"gender":true},
    {"question":1195,"id":1195,"characteristic":"    Somalia","normalization":[1157,7],"parent":1188,"gender":true},
    {"question":1196,"id":1196,"characteristic":"    South Africa, Republic of","normalization":[1157,7],"parent":1188,"gender":true},
    {"question":1197,"id":1197,"characteristic":"    Other places of birth in Africa","normalization":[1157,7],"parent":1188,"gender":true},
    {"question":1198,"id":1198,"characteristic":"  Asia","normalization":[1157,7],"parent":1157,"gender":true},
    {"question":1199,"id":1199,"characteristic":"    Afghanistan","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1200,"id":1200,"characteristic":"    Bangladesh","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1201,"id":1201,"characteristic":"    China (74)","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1202,"id":1202,"characteristic":"    Hong Kong (75)","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1203,"id":1203,"characteristic":"    India","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1204,"id":1204,"characteristic":"    Iran (76)","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1205,"id":1205,"characteristic":"    Iraq","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1206,"id":1206,"characteristic":"    Japan","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1207,"id":1207,"characteristic":"    Korea, South (77)","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1208,"id":1208,"characteristic":"    Lebanon","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1209,"id":1209,"characteristic":"    Pakistan","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1210,"id":1210,"characteristic":"    Philippines","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1211,"id":1211,"characteristic":"    Sri Lanka","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1212,"id":1212,"characteristic":"    Syria (78)","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1213,"id":1213,"characteristic":"    Taiwan","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1214,"id":1214,"characteristic":"    Viet Nam","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1215,"id":1215,"characteristic":"    Other places of birth in Asia","normalization":[1157,7],"parent":1198,"gender":true},
    {"question":1216,"id":1216,"characteristic":"  Oceania and other places of birth (79)","normalization":[1157,7],"parent":1157,"gender":true},
    {"question":1217,"id":1217,"characteristic":"Total - Selected places of birth for the recent immigrant population in private households - 25% sample data (80)","normalization":[],"parent":0,"gender":true},
    {"question":1218,"id":1218,"characteristic":"  Americas","normalization":[1217,7],"parent":1217,"gender":true},
    {"question":1219,"id":1219,"characteristic":"    Brazil","normalization":[1217,7],"parent":1218,"gender":true},
    {"question":1220,"id":1220,"characteristic":"    Colombia","normalization":[1217,7],"parent":1218,"gender":true},
    {"question":1221,"id":1221,"characteristic":"    Cuba","normalization":[1217,7],"parent":1218,"gender":true},
    {"question":1222,"id":1222,"characteristic":"    Haiti","normalization":[1217,7],"parent":1218,"gender":true},
    {"question":1223,"id":1223,"characteristic":"    Jamaica","normalization":[1217,7],"parent":1218,"gender":true},
    {"question":1224,"id":1224,"characteristic":"    Mexico","normalization":[1217,7],"parent":1218,"gender":true},
    {"question":1225,"id":1225,"characteristic":"    United States (81)","normalization":[1217,7],"parent":1218,"gender":true},
    {"question":1226,"id":1226,"characteristic":"    Venezuela (82)","normalization":[1217,7],"parent":1218,"gender":true},
    {"question":1227,"id":1227,"characteristic":"    Other places of birth in Americas","normalization":[1217,7],"parent":1218,"gender":true},
    {"question":1228,"id":1228,"characteristic":"  Europe","normalization":[1217,7],"parent":1217,"gender":true},
    {"question":1229,"id":1229,"characteristic":"    France","normalization":[1217,7],"parent":1228,"gender":true},
    {"question":1230,"id":1230,"characteristic":"    Germany","normalization":[1217,7],"parent":1228,"gender":true},
    {"question":1231,"id":1231,"characteristic":"    Ireland (83)","normalization":[1217,7],"parent":1228,"gender":true},
    {"question":1232,"id":1232,"characteristic":"    Moldova (84)","normalization":[1217,7],"parent":1228,"gender":true},
    {"question":1233,"id":1233,"characteristic":"    Romania","normalization":[1217,7],"parent":1228,"gender":true},
    {"question":1234,"id":1234,"characteristic":"    Russian Federation","normalization":[1217,7],"parent":1228,"gender":true},
    {"question":1235,"id":1235,"characteristic":"    Ukraine","normalization":[1217,7],"parent":1228,"gender":true},
    {"question":1236,"id":1236,"characteristic":"    United Kingdom (85)","normalization":[1217,7],"parent":1228,"gender":true},
    {"question":1237,"id":1237,"characteristic":"    Other places of birth in Europe","normalization":[1217,7],"parent":1228,"gender":true},
    {"question":1238,"id":1238,"characteristic":"  Africa","normalization":[1217,7],"parent":1217,"gender":true},
    {"question":1239,"id":1239,"characteristic":"    Algeria","normalization":[1217,7],"parent":1238,"gender":true},
    {"question":1240,"id":1240,"characteristic":"    Cameroon","normalization":[1217,7],"parent":1238,"gender":true},
    {"question":1241,"id":1241,"characteristic":"    Congo, Democratic Republic of the","normalization":[1217,7],"parent":1238,"gender":true},
    {"question":1242,"id":1242,"characteristic":"    Côte d'Ivoire","normalization":[1217,7],"parent":1238,"gender":true},
    {"question":1243,"id":1243,"characteristic":"    Egypt","normalization":[1217,7],"parent":1238,"gender":true},
    {"question":1244,"id":1244,"characteristic":"    Eritrea","normalization":[1217,7],"parent":1238,"gender":true},
    {"question":1245,"id":1245,"characteristic":"    Ethiopia","normalization":[1217,7],"parent":1238,"gender":true},
    {"question":1246,"id":1246,"characteristic":"    Morocco","normalization":[1217,7],"parent":1238,"gender":true},
    {"question":1247,"id":1247,"characteristic":"    Nigeria","normalization":[1217,7],"parent":1238,"gender":true},
    {"question":1248,"id":1248,"characteristic":"    Somalia","normalization":[1217,7],"parent":1238,"gender":true},
    {"question":1249,"id":1249,"characteristic":"    South Africa, Republic of","normalization":[1217,7],"parent":1238,"gender":true},
    {"question":1250,"id":1250,"characteristic":"    Tunisia","normalization":[1217,7],"parent":1238,"gender":true},
    {"question":1251,"id":1251,"characteristic":"    Other places of birth in Africa","normalization":[1217,7],"parent":1238,"gender":true},
    {"question":1252,"id":1252,"characteristic":"  Asia","normalization":[1217,7],"parent":1217,"gender":true},
    {"question":1253,"id":1253,"characteristic":"    Afghanistan","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1254,"id":1254,"characteristic":"    Bangladesh","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1255,"id":1255,"characteristic":"    China (86)","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1256,"id":1256,"characteristic":"    Hong Kong (87)","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1257,"id":1257,"characteristic":"    India","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1258,"id":1258,"characteristic":"    Iran (88)","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1259,"id":1259,"characteristic":"    Iraq","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1260,"id":1260,"characteristic":"    Israel","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1261,"id":1261,"characteristic":"    Japan","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1262,"id":1262,"characteristic":"    Korea, South (89)","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1263,"id":1263,"characteristic":"    Lebanon","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1264,"id":1264,"characteristic":"    Nepal","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1265,"id":1265,"characteristic":"    Pakistan","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1266,"id":1266,"characteristic":"    Philippines","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1267,"id":1267,"characteristic":"    Saudi Arabia","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1268,"id":1268,"characteristic":"    Sri Lanka","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1269,"id":1269,"characteristic":"    Syria (90)","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1270,"id":1270,"characteristic":"    Taiwan","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1271,"id":1271,"characteristic":"    Turkey","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1272,"id":1272,"characteristic":"    United Arab Emirates","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1273,"id":1273,"characteristic":"    Viet Nam","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1274,"id":1274,"characteristic":"    Other places of birth in Asia","normalization":[1217,7],"parent":1252,"gender":true},
    {"question":1275,"id":1275,"characteristic":"  Oceania and other (91)","normalization":[1217,7],"parent":1217,"gender":true},
    {"question":1276,"id":1276,"characteristic":"    Australia","normalization":[1217,7],"parent":1275,"gender":true},
    {"question":1277,"id":1277,"characteristic":"    Other places of birth (92)","normalization":[1217,7],"parent":1275,"gender":true},
    {"question":1278,"id":1278,"characteristic":"Total - Generation status for the population in private households - 25% sample data (93)","normalization":[],"parent":0,"gender":true},
    {"question":1279,"id":1279,"characteristic":"  First generation (94)","normalization":[1278,7],"parent":1278,"gender":true},
    {"question":1280,"id":1280,"characteristic":"  Second generation (95)","normalization":[1278,7],"parent":1278,"gender":true},
    {"question":1281,"id":1281,"characteristic":"  Third generation or more (96)","normalization":[1278,7],"parent":1278,"gender":true},
    {"question":1282,"id":1282,"characteristic":"Total - Admission category and applicant type for the immigrant population in private households who landed between 1980 and 2016 - 25% sample data (97)","normalization":[],"parent":0,"gender":true},
    {"question":1283,"id":1283,"characteristic":"  Economic immigrants (98)","normalization":[1282,7],"parent":1282,"gender":true},
    {"question":1284,"id":1284,"characteristic":"    Principal applicants (99)","normalization":[1282,7],"parent":1283,"gender":true},
    {"question":1285,"id":1285,"characteristic":"    Secondary applicants (100)","normalization":[1282,7],"parent":1283,"gender":true},
    {"question":1286,"id":1286,"characteristic":"  Immigrants sponsored by family (101)","normalization":[1282,7],"parent":1282,"gender":true},
    {"question":1287,"id":1287,"characteristic":"  Refugees (102)","normalization":[1282,7],"parent":1282,"gender":true},
    {"question":1288,"id":1288,"characteristic":"  Other immigrants (103)","normalization":[1282,7],"parent":1282,"gender":true},
    {"question":1289,"id":1289,"characteristic":"Total - Aboriginal identity for the population in private households - 25% sample data (104)","normalization":[],"parent":0,"gender":true},
    {"question":1290,"id":1290,"characteristic":"  Aboriginal identity (105)","normalization":[1289,7],"parent":1289,"gender":true},
    {"question":1291,"id":1291,"characteristic":"    Single Aboriginal responses (106)","normalization":[1289,7],"parent":1290,"gender":true},
    {"question":1292,"id":1292,"characteristic":"      First Nations (North American Indian) (107)","normalization":[1289,7],"parent":1291,"gender":true},
    {"question":1293,"id":1293,"characteristic":"      Métis","normalization":[1289,7],"parent":1291,"gender":true},
    {"question":1294,"id":1294,"characteristic":"      Inuk (Inuit)","normalization":[1289,7],"parent":1291,"gender":true},
    {"question":1295,"id":1295,"characteristic":"    Multiple Aboriginal responses (108)","normalization":[1289,7],"parent":1290,"gender":true},
    {"question":1296,"id":1296,"characteristic":"    Aboriginal responses not included elsewhere (109)","normalization":[1289,7],"parent":1290,"gender":true},
    {"question":1297,"id":1297,"characteristic":"  Non-Aboriginal identity","normalization":[1289,7],"parent":1289,"gender":true},
    {"question":1298,"id":1298,"characteristic":"Total - Population by Registered or Treaty Indian status for the population in private households - 25% sample data (110)","normalization":[],"parent":0,"gender":true},
    {"question":1299,"id":1299,"characteristic":"  Registered or Treaty Indian (111)","normalization":[1298,7],"parent":1298,"gender":true},
    {"question":1300,"id":1300,"characteristic":"  Not a Registered or Treaty Indian","normalization":[1298,7],"parent":1298,"gender":true},
    {"question":1301,"id":1301,"characteristic":"Total - Aboriginal ancestry for the population in private households - 25% sample data (112)","normalization":[],"parent":0,"gender":true},
    {"question":1302,"id":1302,"characteristic":"  Aboriginal ancestry (only) (113)","normalization":[1301,7],"parent":1301,"gender":true},
    {"question":1303,"id":1303,"characteristic":"    Single Aboriginal ancestry (only) (114)","normalization":[1301,7],"parent":1302,"gender":true},
    {"question":1304,"id":1304,"characteristic":"      First Nations (North American Indian) single ancestry (115)","normalization":[1301,7],"parent":1303,"gender":true},
    {"question":1305,"id":1305,"characteristic":"      Métis single ancestry","normalization":[1301,7],"parent":1303,"gender":true},
    {"question":1306,"id":1306,"characteristic":"      Inuit single ancestry","normalization":[1301,7],"parent":1303,"gender":true},
    {"question":1307,"id":1307,"characteristic":"    Multiple Aboriginal ancestries (only) (116)","normalization":[1301,7],"parent":1302,"gender":true},
    {"question":1308,"id":1308,"characteristic":"      First Nations (North American Indian) and Métis ancestries","normalization":[1301,7],"parent":1307,"gender":true},
    {"question":1309,"id":1309,"characteristic":"      First Nations (North American Indian) and Inuit ancestries","normalization":[1301,7],"parent":1307,"gender":true},
    {"question":1310,"id":1310,"characteristic":"      Métis and Inuit ancestries","normalization":[1301,7],"parent":1307,"gender":true},
    {"question":1311,"id":1311,"characteristic":"      First Nations (North American Indian), Métis and Inuit ancestries","normalization":[1301,7],"parent":1307,"gender":true},
    {"question":1312,"id":1312,"characteristic":"  Aboriginal and non-Aboriginal ancestries (117)","normalization":[1301,7],"parent":1301,"gender":true},
    {"question":1313,"id":1313,"characteristic":"    Single Aboriginal and non-Aboriginal ancestries (118)","normalization":[1301,7],"parent":1312,"gender":true},
    {"question":1314,"id":1314,"characteristic":"      First Nations (North American Indian) and non-Aboriginal ancestries","normalization":[1301,7],"parent":1313,"gender":true},
    {"question":1315,"id":1315,"characteristic":"      Métis and non-Aboriginal ancestries","normalization":[1301,7],"parent":1313,"gender":true},
    {"question":1316,"id":1316,"characteristic":"      Inuit and non-Aboriginal ancestries","normalization":[1301,7],"parent":1313,"gender":true},
    {"question":1317,"id":1317,"characteristic":"    Multiple Aboriginal and non-Aboriginal ancestries (119)","normalization":[1301,7],"parent":1312,"gender":true},
    {"question":1318,"id":1318,"characteristic":"      First Nations (North American Indian), Métis and non-Aboriginal ancestries","normalization":[1301,7],"parent":1317,"gender":true},
    {"question":1319,"id":1319,"characteristic":"      First Nations (North American Indian), Inuit and non-Aboriginal ancestries","normalization":[1301,7],"parent":1317,"gender":true},
    {"question":1320,"id":1320,"characteristic":"      Métis, Inuit and non-Aboriginal ancestries","normalization":[1301,7],"parent":1317,"gender":true},
    {"question":1321,"id":1321,"characteristic":"      First Nations (North American Indian), Métis, Inuit and non-Aboriginal ancestries","normalization":[1301,7],"parent":1317,"gender":true},
    {"question":1322,"id":1322,"characteristic":"  Non-Aboriginal ancestry (only) (120)","normalization":[1301,7],"parent":1301,"gender":true},
    {"question":1323,"id":1323,"characteristic":"Total - Visible minority for the population in private households - 25% sample data (121)","normalization":[],"parent":0,"gender":true},
    {"question":1324,"id":1324,"characteristic":"  Total visible minority population (122)","normalization":[1323,7],"parent":1323,"gender":true},
    {"question":1325,"id":1325,"characteristic":"    South Asian (123)","normalization":[1323,7],"parent":1324,"gender":true},
    {"question":1326,"id":1326,"characteristic":"    Chinese","normalization":[1323,7],"parent":1324,"gender":true},
    {"question":1327,"id":1327,"characteristic":"    Black","normalization":[1323,7],"parent":1324,"gender":true},
    {"question":1328,"id":1328,"characteristic":"    Filipino","normalization":[1323,7],"parent":1324,"gender":true},
    {"question":1329,"id":1329,"characteristic":"    Latin American","normalization":[1323,7],"parent":1324,"gender":true},
    {"question":1330,"id":1330,"characteristic":"    Arab","normalization":[1323,7],"parent":1324,"gender":true},
    {"question":1331,"id":1331,"characteristic":"    Southeast Asian (124)","normalization":[1323,7],"parent":1324,"gender":true},
    {"question":1332,"id":1332,"characteristic":"    West Asian (125)","normalization":[1323,7],"parent":1324,"gender":true},
    {"question":1333,"id":1333,"characteristic":"    Korean","normalization":[1323,7],"parent":1324,"gender":true},
    {"question":1334,"id":1334,"characteristic":"    Japanese","normalization":[1323,7],"parent":1324,"gender":true},
    {"question":1335,"id":1335,"characteristic":"    Visible minority, n.i.e. (126)","normalization":[1323,7],"parent":1324,"gender":true},
    {"question":1336,"id":1336,"characteristic":"    Multiple visible minorities (127)","normalization":[1323,7],"parent":1324,"gender":true},
    {"question":1337,"id":1337,"characteristic":"  Not a visible minority (128)","normalization":[1323,7],"parent":1323,"gender":true},
    {"question":1338,"id":1338,"characteristic":"Total - Ethnic origin for the population in private households - 25% sample data (129)","normalization":[],"parent":0,"gender":true},
    {"question":1339,"id":1339,"characteristic":"  North American Aboriginal origins","normalization":[1338,7],"parent":1338,"gender":true},
    {"question":1340,"id":1340,"characteristic":"    First Nations (North American Indian)","normalization":[1338,7],"parent":1339,"gender":true},
    {"question":1341,"id":1341,"characteristic":"    Inuit","normalization":[1338,7],"parent":1339,"gender":true},
    {"question":1342,"id":1342,"characteristic":"    Métis","normalization":[1338,7],"parent":1339,"gender":true},
    {"question":1343,"id":1343,"characteristic":"  Other North American origins","normalization":[1338,7],"parent":1338,"gender":true},
    {"question":1344,"id":1344,"characteristic":"    Acadian","normalization":[1338,7],"parent":1343,"gender":true},
    {"question":1345,"id":1345,"characteristic":"    American","normalization":[1338,7],"parent":1343,"gender":true},
    {"question":1346,"id":1346,"characteristic":"    Canadian","normalization":[1338,7],"parent":1343,"gender":true},
    {"question":1347,"id":1347,"characteristic":"    New Brunswicker","normalization":[1338,7],"parent":1343,"gender":true},
    {"question":1348,"id":1348,"characteristic":"    Newfoundlander","normalization":[1338,7],"parent":1343,"gender":true},
    {"question":1349,"id":1349,"characteristic":"    Nova Scotian","normalization":[1338,7],"parent":1343,"gender":true},
    {"question":1350,"id":1350,"characteristic":"    Ontarian","normalization":[1338,7],"parent":1343,"gender":true},
    {"question":1351,"id":1351,"characteristic":"    Québécois","normalization":[1338,7],"parent":1343,"gender":true},
    {"question":1352,"id":1352,"characteristic":"    Other North American origins, n.i.e. (130)","normalization":[1338,7],"parent":1343,"gender":true},
    {"question":1353,"id":1353,"characteristic":"  European origins","normalization":[1338,7],"parent":1338,"gender":true},
    {"question":1354,"id":1354,"characteristic":"    British Isles origins","normalization":[1338,7],"parent":1353,"gender":true},
    {"question":1355,"id":1355,"characteristic":"      Channel Islander","normalization":[1338,7],"parent":1354,"gender":true},
    {"question":1356,"id":1356,"characteristic":"      Cornish","normalization":[1338,7],"parent":1354,"gender":true},
    {"question":1357,"id":1357,"characteristic":"      English","normalization":[1338,7],"parent":1354,"gender":true},
    {"question":1358,"id":1358,"characteristic":"      Irish","normalization":[1338,7],"parent":1354,"gender":true},
    {"question":1359,"id":1359,"characteristic":"      Manx","normalization":[1338,7],"parent":1354,"gender":true},
    {"question":1360,"id":1360,"characteristic":"      Scottish","normalization":[1338,7],"parent":1354,"gender":true},
    {"question":1361,"id":1361,"characteristic":"      Welsh","normalization":[1338,7],"parent":1354,"gender":true},
    {"question":1362,"id":1362,"characteristic":"      British Isles origins, n.i.e. (131)","normalization":[1338,7],"parent":1354,"gender":true},
    {"question":1363,"id":1363,"characteristic":"    French origins","normalization":[1338,7],"parent":1353,"gender":true},
    {"question":1364,"id":1364,"characteristic":"      Alsatian","normalization":[1338,7],"parent":1363,"gender":true},
    {"question":1365,"id":1365,"characteristic":"      Breton","normalization":[1338,7],"parent":1363,"gender":true},
    {"question":1366,"id":1366,"characteristic":"      Corsican","normalization":[1338,7],"parent":1363,"gender":true},
    {"question":1367,"id":1367,"characteristic":"      French","normalization":[1338,7],"parent":1363,"gender":true},
    {"question":1368,"id":1368,"characteristic":"    Western European origins (except French origins)","normalization":[1338,7],"parent":1353,"gender":true},
    {"question":1369,"id":1369,"characteristic":"      Austrian","normalization":[1338,7],"parent":1368,"gender":true},
    {"question":1370,"id":1370,"characteristic":"      Bavarian","normalization":[1338,7],"parent":1368,"gender":true},
    {"question":1371,"id":1371,"characteristic":"      Belgian","normalization":[1338,7],"parent":1368,"gender":true},
    {"question":1372,"id":1372,"characteristic":"      Dutch","normalization":[1338,7],"parent":1368,"gender":true},
    {"question":1373,"id":1373,"characteristic":"      Flemish","normalization":[1338,7],"parent":1368,"gender":true},
    {"question":1374,"id":1374,"characteristic":"      Frisian","normalization":[1338,7],"parent":1368,"gender":true},
    {"question":1375,"id":1375,"characteristic":"      German","normalization":[1338,7],"parent":1368,"gender":true},
    {"question":1376,"id":1376,"characteristic":"      Luxembourger","normalization":[1338,7],"parent":1368,"gender":true},
    {"question":1377,"id":1377,"characteristic":"      Swiss","normalization":[1338,7],"parent":1368,"gender":true},
    {"question":1378,"id":1378,"characteristic":"      Western European origins, n.i.e. (132)","normalization":[1338,7],"parent":1368,"gender":true},
    {"question":1379,"id":1379,"characteristic":"    Northern European origins (except British Isles origins)","normalization":[1338,7],"parent":1353,"gender":true},
    {"question":1380,"id":1380,"characteristic":"      Danish","normalization":[1338,7],"parent":1379,"gender":true},
    {"question":1381,"id":1381,"characteristic":"      Finnish","normalization":[1338,7],"parent":1379,"gender":true},
    {"question":1382,"id":1382,"characteristic":"      Icelandic","normalization":[1338,7],"parent":1379,"gender":true},
    {"question":1383,"id":1383,"characteristic":"      Norwegian","normalization":[1338,7],"parent":1379,"gender":true},
    {"question":1384,"id":1384,"characteristic":"      Swedish","normalization":[1338,7],"parent":1379,"gender":true},
    {"question":1385,"id":1385,"characteristic":"      Northern European origins, n.i.e. (133)","normalization":[1338,7],"parent":1379,"gender":true},
    {"question":1386,"id":1386,"characteristic":"    Eastern European origins","normalization":[1338,7],"parent":1353,"gender":true},
    {"question":1387,"id":1387,"characteristic":"      Bulgarian","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1388,"id":1388,"characteristic":"      Byelorussian","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1389,"id":1389,"characteristic":"      Czech","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1390,"id":1390,"characteristic":"      Czechoslovakian, n.o.s. (134)","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1391,"id":1391,"characteristic":"      Estonian","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1392,"id":1392,"characteristic":"      Hungarian","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1393,"id":1393,"characteristic":"      Latvian","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1394,"id":1394,"characteristic":"      Lithuanian","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1395,"id":1395,"characteristic":"      Moldovan","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1396,"id":1396,"characteristic":"      Polish","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1397,"id":1397,"characteristic":"      Romanian","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1398,"id":1398,"characteristic":"      Russian","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1399,"id":1399,"characteristic":"      Slovak","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1400,"id":1400,"characteristic":"      Ukrainian","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1401,"id":1401,"characteristic":"      Eastern European origins, n.i.e. (135)","normalization":[1338,7],"parent":1386,"gender":true},
    {"question":1402,"id":1402,"characteristic":"    Southern European origins","normalization":[1338,7],"parent":1353,"gender":true},
    {"question":1403,"id":1403,"characteristic":"      Albanian","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1404,"id":1404,"characteristic":"      Bosnian","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1405,"id":1405,"characteristic":"      Catalan","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1406,"id":1406,"characteristic":"      Croatian","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1407,"id":1407,"characteristic":"      Cypriot","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1408,"id":1408,"characteristic":"      Greek","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1409,"id":1409,"characteristic":"      Italian","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1410,"id":1410,"characteristic":"      Kosovar","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1411,"id":1411,"characteristic":"      Macedonian","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1412,"id":1412,"characteristic":"      Maltese","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1413,"id":1413,"characteristic":"      Montenegrin","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1414,"id":1414,"characteristic":"      Portuguese","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1415,"id":1415,"characteristic":"      Serbian","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1416,"id":1416,"characteristic":"      Sicilian","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1417,"id":1417,"characteristic":"      Slovenian","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1418,"id":1418,"characteristic":"      Spanish","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1419,"id":1419,"characteristic":"      Yugoslavian, n.o.s. (136)","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1420,"id":1420,"characteristic":"      Southern European origins, n.i.e. (137)","normalization":[1338,7],"parent":1402,"gender":true},
    {"question":1421,"id":1421,"characteristic":"    Other European origins","normalization":[1338,7],"parent":1353,"gender":true},
    {"question":1422,"id":1422,"characteristic":"      Basque","normalization":[1338,7],"parent":1421,"gender":true},
    {"question":1423,"id":1423,"characteristic":"      Jewish","normalization":[1338,7],"parent":1421,"gender":true},
    {"question":1424,"id":1424,"characteristic":"      Roma (Gypsy)","normalization":[1338,7],"parent":1421,"gender":true},
    {"question":1425,"id":1425,"characteristic":"      Slavic, n.o.s. (138)","normalization":[1338,7],"parent":1421,"gender":true},
    {"question":1426,"id":1426,"characteristic":"      Other European origins, n.i.e. (139)","normalization":[1338,7],"parent":1421,"gender":true},
    {"question":1427,"id":1427,"characteristic":"  Caribbean origins","normalization":[1338,7],"parent":1338,"gender":true},
    {"question":1428,"id":1428,"characteristic":"    Antiguan","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1429,"id":1429,"characteristic":"    Bahamian","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1430,"id":1430,"characteristic":"    Barbadian","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1431,"id":1431,"characteristic":"    Bermudan","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1432,"id":1432,"characteristic":"    Carib","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1433,"id":1433,"characteristic":"    Cuban","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1434,"id":1434,"characteristic":"    Dominican","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1435,"id":1435,"characteristic":"    Grenadian","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1436,"id":1436,"characteristic":"    Guadeloupean","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1437,"id":1437,"characteristic":"    Haitian","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1438,"id":1438,"characteristic":"    Jamaican","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1439,"id":1439,"characteristic":"    Kittitian/Nevisian","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1440,"id":1440,"characteristic":"    Martinican","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1441,"id":1441,"characteristic":"    Montserratan","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1442,"id":1442,"characteristic":"    Puerto Rican","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1443,"id":1443,"characteristic":"    St. Lucian","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1444,"id":1444,"characteristic":"    Trinidadian/Tobagonian","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1445,"id":1445,"characteristic":"    Vincentian/Grenadinian","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1446,"id":1446,"characteristic":"    West Indian, n.o.s. (140)","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1447,"id":1447,"characteristic":"    Caribbean origins, n.i.e. (141)","normalization":[1338,7],"parent":1427,"gender":true},
    {"question":1448,"id":1448,"characteristic":"  Latin, Central and South American origins","normalization":[1338,7],"parent":1338,"gender":true},
    {"question":1449,"id":1449,"characteristic":"    Aboriginal from Central/South America (except Arawak and Maya)","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1450,"id":1450,"characteristic":"    Arawak","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1451,"id":1451,"characteristic":"    Argentinian","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1452,"id":1452,"characteristic":"    Belizean","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1453,"id":1453,"characteristic":"    Bolivian","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1454,"id":1454,"characteristic":"    Brazilian","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1455,"id":1455,"characteristic":"    Chilean","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1456,"id":1456,"characteristic":"    Colombian","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1457,"id":1457,"characteristic":"    Costa Rican","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1458,"id":1458,"characteristic":"    Ecuadorian","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1459,"id":1459,"characteristic":"    Guatemalan","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1460,"id":1460,"characteristic":"    Guyanese","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1461,"id":1461,"characteristic":"    Hispanic","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1462,"id":1462,"characteristic":"    Honduran","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1463,"id":1463,"characteristic":"    Maya","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1464,"id":1464,"characteristic":"    Mexican","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1465,"id":1465,"characteristic":"    Nicaraguan","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1466,"id":1466,"characteristic":"    Panamanian","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1467,"id":1467,"characteristic":"    Paraguayan","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1468,"id":1468,"characteristic":"    Peruvian","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1469,"id":1469,"characteristic":"    Salvadorean","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1470,"id":1470,"characteristic":"    Uruguayan","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1471,"id":1471,"characteristic":"    Venezuelan","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1472,"id":1472,"characteristic":"    Latin, Central and South American origins, n.i.e. (142)","normalization":[1338,7],"parent":1448,"gender":true},
    {"question":1473,"id":1473,"characteristic":"  African origins","normalization":[1338,7],"parent":1338,"gender":true},
    {"question":1474,"id":1474,"characteristic":"    Central and West African origins","normalization":[1338,7],"parent":1473,"gender":true},
    {"question":1475,"id":1475,"characteristic":"      Akan","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1476,"id":1476,"characteristic":"      Angolan","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1477,"id":1477,"characteristic":"      Ashanti","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1478,"id":1478,"characteristic":"      Beninese","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1479,"id":1479,"characteristic":"      Burkinabe","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1480,"id":1480,"characteristic":"      Cameroonian","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1481,"id":1481,"characteristic":"      Chadian","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1482,"id":1482,"characteristic":"      Congolese","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1483,"id":1483,"characteristic":"      Edo","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1484,"id":1484,"characteristic":"      Ewe","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1485,"id":1485,"characteristic":"      Gabonese","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1486,"id":1486,"characteristic":"      Gambian","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1487,"id":1487,"characteristic":"      Ghanaian","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1488,"id":1488,"characteristic":"      Guinean","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1489,"id":1489,"characteristic":"      Ibo","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1490,"id":1490,"characteristic":"      Ivorian","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1491,"id":1491,"characteristic":"      Liberian","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1492,"id":1492,"characteristic":"      Malian","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1493,"id":1493,"characteristic":"      Malinké","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1494,"id":1494,"characteristic":"      Nigerian","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1495,"id":1495,"characteristic":"      Peulh","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1496,"id":1496,"characteristic":"      Senegalese","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1497,"id":1497,"characteristic":"      Sierra Leonean","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1498,"id":1498,"characteristic":"      Togolese","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1499,"id":1499,"characteristic":"      Wolof","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1500,"id":1500,"characteristic":"      Yoruba","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1501,"id":1501,"characteristic":"      Central and West African origins, n.i.e. (143)","normalization":[1338,7],"parent":1474,"gender":true},
    {"question":1502,"id":1502,"characteristic":"    North African origins","normalization":[1338,7],"parent":1473,"gender":true},
    {"question":1503,"id":1503,"characteristic":"      Algerian","normalization":[1338,7],"parent":1502,"gender":true},
    {"question":1504,"id":1504,"characteristic":"      Berber","normalization":[1338,7],"parent":1502,"gender":true},
    {"question":1505,"id":1505,"characteristic":"      Coptic","normalization":[1338,7],"parent":1502,"gender":true},
    {"question":1506,"id":1506,"characteristic":"      Dinka","normalization":[1338,7],"parent":1502,"gender":true},
    {"question":1507,"id":1507,"characteristic":"      Egyptian","normalization":[1338,7],"parent":1502,"gender":true},
    {"question":1508,"id":1508,"characteristic":"      Libyan","normalization":[1338,7],"parent":1502,"gender":true},
    {"question":1509,"id":1509,"characteristic":"      Maure","normalization":[1338,7],"parent":1502,"gender":true},
    {"question":1510,"id":1510,"characteristic":"      Moroccan","normalization":[1338,7],"parent":1502,"gender":true},
    {"question":1511,"id":1511,"characteristic":"      Sudanese","normalization":[1338,7],"parent":1502,"gender":true},
    {"question":1512,"id":1512,"characteristic":"      Tunisian","normalization":[1338,7],"parent":1502,"gender":true},
    {"question":1513,"id":1513,"characteristic":"      North African origins, n.i.e. (144)","normalization":[1338,7],"parent":1502,"gender":true},
    {"question":1514,"id":1514,"characteristic":"    Southern and East African origins","normalization":[1338,7],"parent":1473,"gender":true},
    {"question":1515,"id":1515,"characteristic":"      Afrikaner","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1516,"id":1516,"characteristic":"      Amhara","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1517,"id":1517,"characteristic":"      Bantu, n.o.s. (145)","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1518,"id":1518,"characteristic":"      Burundian","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1519,"id":1519,"characteristic":"      Djiboutian","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1520,"id":1520,"characteristic":"      Eritrean","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1521,"id":1521,"characteristic":"      Ethiopian","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1522,"id":1522,"characteristic":"      Harari","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1523,"id":1523,"characteristic":"      Kenyan","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1524,"id":1524,"characteristic":"      Malagasy","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1525,"id":1525,"characteristic":"      Mauritian","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1526,"id":1526,"characteristic":"      Oromo","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1527,"id":1527,"characteristic":"      Rwandan","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1528,"id":1528,"characteristic":"      Seychellois","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1529,"id":1529,"characteristic":"      Somali","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1530,"id":1530,"characteristic":"      South African","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1531,"id":1531,"characteristic":"      Tanzanian","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1532,"id":1532,"characteristic":"      Tigrian","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1533,"id":1533,"characteristic":"      Ugandan","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1534,"id":1534,"characteristic":"      Zambian","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1535,"id":1535,"characteristic":"      Zimbabwean","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1536,"id":1536,"characteristic":"      Zulu","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1537,"id":1537,"characteristic":"      Southern and East African origins, n.i.e. (146)","normalization":[1338,7],"parent":1514,"gender":true},
    {"question":1538,"id":1538,"characteristic":"    Other African origins","normalization":[1338,7],"parent":1473,"gender":true},
    {"question":1539,"id":1539,"characteristic":"      Black, n.o.s. (147)","normalization":[1338,7],"parent":1538,"gender":true},
    {"question":1540,"id":1540,"characteristic":"      Other African origins, n.i.e. (148)","normalization":[1338,7],"parent":1538,"gender":true},
    {"question":1541,"id":1541,"characteristic":"  Asian origins","normalization":[1338,7],"parent":1338,"gender":true},
    {"question":1542,"id":1542,"characteristic":"    West Central Asian and Middle Eastern origins","normalization":[1338,7],"parent":1541,"gender":true},
    {"question":1543,"id":1543,"characteristic":"      Afghan","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1544,"id":1544,"characteristic":"      Arab, n.o.s. (149)","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1545,"id":1545,"characteristic":"      Armenian","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1546,"id":1546,"characteristic":"      Assyrian","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1547,"id":1547,"characteristic":"      Azerbaijani","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1548,"id":1548,"characteristic":"      Georgian","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1549,"id":1549,"characteristic":"      Hazara","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1550,"id":1550,"characteristic":"      Iranian","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1551,"id":1551,"characteristic":"      Iraqi","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1552,"id":1552,"characteristic":"      Israeli","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1553,"id":1553,"characteristic":"      Jordanian","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1554,"id":1554,"characteristic":"      Kazakh","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1555,"id":1555,"characteristic":"      Kurd","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1556,"id":1556,"characteristic":"      Kuwaiti","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1557,"id":1557,"characteristic":"      Kyrgyz","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1558,"id":1558,"characteristic":"      Lebanese","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1559,"id":1559,"characteristic":"      Palestinian","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1560,"id":1560,"characteristic":"      Pashtun","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1561,"id":1561,"characteristic":"      Saudi Arabian","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1562,"id":1562,"characteristic":"      Syrian","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1563,"id":1563,"characteristic":"      Tajik","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1564,"id":1564,"characteristic":"      Tatar","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1565,"id":1565,"characteristic":"      Turk","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1566,"id":1566,"characteristic":"      Turkmen","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1567,"id":1567,"characteristic":"      Uighur","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1568,"id":1568,"characteristic":"      Uzbek","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1569,"id":1569,"characteristic":"      Yemeni","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1570,"id":1570,"characteristic":"      West Central Asian and Middle Eastern origins, n.i.e. (150)","normalization":[1338,7],"parent":1542,"gender":true},
    {"question":1571,"id":1571,"characteristic":"    South Asian origins","normalization":[1338,7],"parent":1541,"gender":true},
    {"question":1572,"id":1572,"characteristic":"      Bangladeshi","normalization":[1338,7],"parent":1571,"gender":true},
    {"question":1573,"id":1573,"characteristic":"      Bengali","normalization":[1338,7],"parent":1571,"gender":true},
    {"question":1574,"id":1574,"characteristic":"      Bhutanese","normalization":[1338,7],"parent":1571,"gender":true},
    {"question":1575,"id":1575,"characteristic":"      East Indian","normalization":[1338,7],"parent":1571,"gender":true},
    {"question":1576,"id":1576,"characteristic":"      Goan","normalization":[1338,7],"parent":1571,"gender":true},
    {"question":1577,"id":1577,"characteristic":"      Gujarati","normalization":[1338,7],"parent":1571,"gender":true},
    {"question":1578,"id":1578,"characteristic":"      Kashmiri","normalization":[1338,7],"parent":1571,"gender":true},
    {"question":1579,"id":1579,"characteristic":"      Nepali","normalization":[1338,7],"parent":1571,"gender":true},
    {"question":1580,"id":1580,"characteristic":"      Pakistani","normalization":[1338,7],"parent":1571,"gender":true},
    {"question":1581,"id":1581,"characteristic":"      Punjabi","normalization":[1338,7],"parent":1571,"gender":true},
    {"question":1582,"id":1582,"characteristic":"      Sinhalese","normalization":[1338,7],"parent":1571,"gender":true},
    {"question":1583,"id":1583,"characteristic":"      Sri Lankan","normalization":[1338,7],"parent":1571,"gender":true},
    {"question":1584,"id":1584,"characteristic":"      Tamil","normalization":[1338,7],"parent":1571,"gender":true},
    {"question":1585,"id":1585,"characteristic":"      South Asian origins, n.i.e. (151)","normalization":[1338,7],"parent":1571,"gender":true},
    {"question":1586,"id":1586,"characteristic":"    East and Southeast Asian origins","normalization":[1338,7],"parent":1541,"gender":true},
    {"question":1587,"id":1587,"characteristic":"      Burmese","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1588,"id":1588,"characteristic":"      Cambodian (Khmer)","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1589,"id":1589,"characteristic":"      Chinese","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1590,"id":1590,"characteristic":"      Filipino","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1591,"id":1591,"characteristic":"      Hmong","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1592,"id":1592,"characteristic":"      Indonesian","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1593,"id":1593,"characteristic":"      Japanese","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1594,"id":1594,"characteristic":"      Karen","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1595,"id":1595,"characteristic":"      Korean","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1596,"id":1596,"characteristic":"      Laotian","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1597,"id":1597,"characteristic":"      Malaysian","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1598,"id":1598,"characteristic":"      Mongolian","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1599,"id":1599,"characteristic":"      Singaporean","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1600,"id":1600,"characteristic":"      Taiwanese","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1601,"id":1601,"characteristic":"      Thai","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1602,"id":1602,"characteristic":"      Tibetan","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1603,"id":1603,"characteristic":"      Vietnamese","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1604,"id":1604,"characteristic":"      East and Southeast Asian origins, n.i.e. (152)","normalization":[1338,7],"parent":1586,"gender":true},
    {"question":1605,"id":1605,"characteristic":"    Other Asian origins","normalization":[1338,7],"parent":1541,"gender":true},
    {"question":1606,"id":1606,"characteristic":"      Other Asian origins, n.i.e. (153)","normalization":[1338,7],"parent":1605,"gender":true},
    {"question":1607,"id":1607,"characteristic":"  Oceania origins","normalization":[1338,7],"parent":1338,"gender":true},
    {"question":1608,"id":1608,"characteristic":"    Australian","normalization":[1338,7],"parent":1607,"gender":true},
    {"question":1609,"id":1609,"characteristic":"    New Zealander","normalization":[1338,7],"parent":1607,"gender":true},
    {"question":1610,"id":1610,"characteristic":"    Pacific Islands origins","normalization":[1338,7],"parent":1607,"gender":true},
    {"question":1611,"id":1611,"characteristic":"      Fijian","normalization":[1338,7],"parent":1610,"gender":true},
    {"question":1612,"id":1612,"characteristic":"      Hawaiian","normalization":[1338,7],"parent":1610,"gender":true},
    {"question":1613,"id":1613,"characteristic":"      Maori","normalization":[1338,7],"parent":1610,"gender":true},
    {"question":1614,"id":1614,"characteristic":"      Samoan","normalization":[1338,7],"parent":1610,"gender":true},
    {"question":1615,"id":1615,"characteristic":"      Polynesian, n.o.s. (154)","normalization":[1338,7],"parent":1610,"gender":true},
    {"question":1616,"id":1616,"characteristic":"      Pacific Islands origins, n.i.e. (155)","normalization":[1338,7],"parent":1610,"gender":true},
    {"question":1617,"id":1617,"characteristic":"Total - Private households by tenure - 25% sample data (156)","normalization":[],"parent":0,"gender":false},
    {"question":1618,"id":1618,"characteristic":"  Owner","normalization":[1617,7],"parent":1617,"gender":false},
    {"question":1619,"id":1619,"characteristic":"  Renter","normalization":[1617,7],"parent":1617,"gender":false},
    {"question":1620,"id":1620,"characteristic":"  Band housing","normalization":[1617,7],"parent":1617,"gender":false},
    {"question":1621,"id":1621,"characteristic":"Total - Occupied private dwellings by condominium status - 25% sample data (157)","normalization":[],"parent":0,"gender":false},
    {"question":1622,"id":1622,"characteristic":"  Condominium","normalization":[1621,7],"parent":1621,"gender":false},
    {"question":1623,"id":1623,"characteristic":"  Not condominium","normalization":[1621,7],"parent":1621,"gender":false},
    {"question":1624,"id":1624,"characteristic":"Total - Occupied private dwellings by number of bedrooms - 25% sample data (158)","normalization":[],"parent":0,"gender":false},
    {"question":1625,"id":1625,"characteristic":"  No bedrooms","normalization":[1624,7],"parent":1624,"gender":false},
    {"question":1626,"id":1626,"characteristic":"  1 bedroom","normalization":[1624,7],"parent":1624,"gender":false},
    {"question":1627,"id":1627,"characteristic":"  2 bedrooms","normalization":[1624,7],"parent":1624,"gender":false},
    {"question":1628,"id":1628,"characteristic":"  3 bedrooms","normalization":[1624,7],"parent":1624,"gender":false},
    {"question":1629,"id":1629,"characteristic":"  4 or more bedrooms","normalization":[1624,7],"parent":1624,"gender":false},
    {"question":1630,"id":1630,"characteristic":"Total - Occupied private dwellings by number of rooms - 25% sample data (159)","normalization":[],"parent":0,"gender":false},
    {"question":1631,"id":1631,"characteristic":"  1 to 4 rooms","normalization":[1630,7],"parent":1630,"gender":false},
    {"question":1632,"id":1632,"characteristic":"  5 rooms","normalization":[1630,7],"parent":1630,"gender":false},
    {"question":1633,"id":1633,"characteristic":"  6 rooms","normalization":[1630,7],"parent":1630,"gender":false},
    {"question":1634,"id":1634,"characteristic":"  7 rooms","normalization":[1630,7],"parent":1630,"gender":false},
    {"question":1635,"id":1635,"characteristic":"  8 or more rooms","normalization":[1630,7],"parent":1630,"gender":false},
    {"question":1636,"id":1636,"characteristic":"Average number of rooms per dwelling","normalization":[],"parent":0,"gender":false},
    {"question":1637,"id":1637,"characteristic":"Total - Private households by number of persons per room - 25% sample data (160)","normalization":[],"parent":0,"gender":false},
    {"question":1638,"id":1638,"characteristic":"  One person or fewer per room","normalization":[1637,7],"parent":1637,"gender":false},
    {"question":1639,"id":1639,"characteristic":"  More than 1 person per room","normalization":[1637,7],"parent":1637,"gender":false},
    {"question":1640,"id":1640,"characteristic":"Total - Private households by housing suitability - 25% sample data (161)","normalization":[],"parent":0,"gender":false},
    {"question":1641,"id":1641,"characteristic":"  Suitable","normalization":[1640,7],"parent":1640,"gender":false},
    {"question":1642,"id":1642,"characteristic":"  Not suitable","normalization":[1640,7],"parent":1640,"gender":false},
    {"question":1643,"id":1643,"characteristic":"Total - Occupied private dwellings by period of construction - 25% sample data (162)","normalization":[],"parent":0,"gender":false},
    {"question":1644,"id":1644,"characteristic":"  1960 or before","normalization":[1643,7],"parent":1643,"gender":false},
    {"question":1645,"id":1645,"characteristic":"  1961 to 1980","normalization":[1643,7],"parent":1643,"gender":false},
    {"question":1646,"id":1646,"characteristic":"  1981 to 1990","normalization":[1643,7],"parent":1643,"gender":false},
    {"question":1647,"id":1647,"characteristic":"  1991 to 2000","normalization":[1643,7],"parent":1643,"gender":false},
    {"question":1648,"id":1648,"characteristic":"  2001 to 2005","normalization":[1643,7],"parent":1643,"gender":false},
    {"question":1649,"id":1649,"characteristic":"  2006 to 2010","normalization":[1643,7],"parent":1643,"gender":false},
    {"question":1650,"id":1650,"characteristic":"  2011 to 2016 (163)","normalization":[1643,7],"parent":1643,"gender":false},
    {"question":1651,"id":1651,"characteristic":"Total - Occupied private dwellings by dwelling condition - 25% sample data (164)","normalization":[],"parent":0,"gender":false},
    {"question":1652,"id":1652,"characteristic":"  Only regular maintenance or minor repairs needed","normalization":[1651,7],"parent":1651,"gender":false},
    {"question":1653,"id":1653,"characteristic":"  Major repairs needed","normalization":[1651,7],"parent":1651,"gender":false},
    {"question":1654,"id":1654,"characteristic":"Total - Private households by number of household maintainers - 25% sample data (165)","normalization":[],"parent":0,"gender":false},
    {"question":1655,"id":1655,"characteristic":"  1 household maintainer","normalization":[1654,7],"parent":1654,"gender":false},
    {"question":1656,"id":1656,"characteristic":"  2 household maintainers","normalization":[1654,7],"parent":1654,"gender":false},
    {"question":1657,"id":1657,"characteristic":"  3 or more household maintainers","normalization":[1654,7],"parent":1654,"gender":false},
    {"question":1658,"id":1658,"characteristic":"Total - Private households by age of primary household maintainers - 25% sample data (166)","normalization":[],"parent":0,"gender":false},
    {"question":1659,"id":1659,"characteristic":"  15 to 24 years","normalization":[1658,7],"parent":1658,"gender":false},
    {"question":1660,"id":1660,"characteristic":"  25 to 34 years","normalization":[1658,7],"parent":1658,"gender":false},
    {"question":1661,"id":1661,"characteristic":"  35 to 44 years","normalization":[1658,7],"parent":1658,"gender":false},
    {"question":1662,"id":1662,"characteristic":"  45 to 54 years","normalization":[1658,7],"parent":1658,"gender":false},
    {"question":1663,"id":1663,"characteristic":"  55 to 64 years","normalization":[1658,7],"parent":1658,"gender":false},
    {"question":1664,"id":1664,"characteristic":"  65 to 74 years","normalization":[1658,7],"parent":1658,"gender":false},
    {"question":1665,"id":1665,"characteristic":"  75 to 84 years","normalization":[1658,7],"parent":1658,"gender":false},
    {"question":1666,"id":1666,"characteristic":"  85 years and over","normalization":[1658,7],"parent":1658,"gender":false},
    {"question":1667,"id":1667,"characteristic":"Total -  Owner and tenant households with household total income greater than zero, in non-farm, non-reserve private dwellings by shelter-cost-to-income ratio - 25% sample data (167)","normalization":[],"parent":0,"gender":false},
    {"question":1668,"id":1668,"characteristic":"  Spending less than 30% of income on shelter costs","normalization":[1667,7],"parent":1667,"gender":false},
    {"question":1669,"id":1669,"characteristic":"  Spending 30% or more of income on shelter costs","normalization":[1667,7],"parent":1667,"gender":false},
    {"question":1670,"id":1670,"characteristic":"    30% to less than 100%","normalization":[1667,7],"parent":1669,"gender":false},
    {"question":1671,"id":1671,"characteristic":"Total - Owner households in non-farm, non-reserve private dwellings - 25% sample data","normalization":[],"parent":0,"gender":false},
    {"question":1672,"id":1672,"characteristic":"  % of owner households with a mortgage (168)","normalization":[1671,7],"parent":1671,"gender":false},
    {"question":1673,"id":1673,"characteristic":"  % of owner households spending 30% or more of its income on shelter costs (169)","normalization":[1671,7],"parent":1671,"gender":false},
    {"question":1674,"id":1674,"characteristic":"  Median monthly shelter costs for owned dwellings ($) (170)","normalization":[1671,7],"parent":1671,"gender":false},
    {"question":1675,"id":1675,"characteristic":"  Average monthly shelter costs for owned dwellings ($) (171)","normalization":[1671,7],"parent":1671,"gender":false},
    {"question":1676,"id":1676,"characteristic":"  Median value of dwellings ($) (172)","normalization":[1671,7],"parent":1671,"gender":false},
    {"question":1677,"id":1677,"characteristic":"  Average value of dwellings ($) (173)","normalization":[1671,7],"parent":1671,"gender":false},
    {"question":1678,"id":1678,"characteristic":"Total - Tenant households in non-farm, non-reserve private dwellings - 25% sample data","normalization":[],"parent":0,"gender":false},
    {"question":1679,"id":1679,"characteristic":"  % of tenant households in subsidized housing (174)","normalization":[],"parent":1678,"gender":false},
    {"question":1680,"id":1680,"characteristic":"  % of tenant households spending 30% or more of its income on shelter costs (175)","normalization":[],"parent":1678,"gender":false},
    {"question":1681,"id":1681,"characteristic":"  Median monthly shelter costs for rented dwellings ($) (176)","normalization":[],"parent":1678,"gender":false},
    {"question":1682,"id":1682,"characteristic":"  Average monthly shelter costs for rented dwellings ($) (177)","normalization":[],"parent":1678,"gender":false},
    {"question":1683,"id":1683,"characteristic":"Total - Highest certificate, diploma or degree for the population aged 15 years and over in private households - 25% sample data (178)","normalization":[],"parent":0,"gender":true},
    {"question":1684,"id":1684,"characteristic":"  No certificate, diploma or degree","normalization":[1683,7],"parent":1683,"gender":true},
    {"question":1685,"id":1685,"characteristic":"  Secondary (high) school diploma or equivalency certificate (179)","normalization":[1683,7],"parent":1683,"gender":true},
    {"question":1686,"id":1686,"characteristic":"  Postsecondary certificate, diploma or degree","normalization":[1683,7],"parent":1683,"gender":true},
    {"question":1687,"id":1687,"characteristic":"    Apprenticeship or trades certificate or diploma","normalization":[1683,7],"parent":1686,"gender":true},
    {"question":1688,"id":1688,"characteristic":"      Trades certificate or diploma other than Certificate of Apprenticeship or Certificate of Qualification (180)","normalization":[1683,7],"parent":1687,"gender":true},
    {"question":1689,"id":1689,"characteristic":"      Certificate of Apprenticeship or Certificate of Qualification (181)","normalization":[1683,7],"parent":1687,"gender":true},
    {"question":1690,"id":1690,"characteristic":"    College, CEGEP or other non-university certificate or diploma","normalization":[1683,7],"parent":1686,"gender":true},
    {"question":1691,"id":1691,"characteristic":"    University certificate or diploma below bachelor level","normalization":[1683,7],"parent":1686,"gender":true},
    {"question":1692,"id":1692,"characteristic":"    University certificate, diploma or degree at bachelor level or above","normalization":[1683,7],"parent":1686,"gender":true},
    {"question":1693,"id":1693,"characteristic":"      Bachelor's degree","normalization":[1683,7],"parent":1692,"gender":true},
    {"question":1694,"id":1694,"characteristic":"      University certificate or diploma above bachelor level","normalization":[1683,7],"parent":1692,"gender":true},
    {"question":1695,"id":1695,"characteristic":"      Degree in medicine, dentistry, veterinary medicine or optometry","normalization":[1683,7],"parent":1692,"gender":true},
    {"question":1696,"id":1696,"characteristic":"      Master's degree","normalization":[1683,7],"parent":1692,"gender":true},
    {"question":1697,"id":1697,"characteristic":"      Earned doctorate (182)","normalization":[1683,7],"parent":1692,"gender":true},
    {"question":1698,"id":1698,"characteristic":"Total - Highest certificate, diploma or degree for the population aged 25 to 64 years in private households - 25% sample data (183)","normalization":[],"parent":0,"gender":true},
    {"question":1699,"id":1699,"characteristic":"  No certificate, diploma or degree","normalization":[1698,7],"parent":1698,"gender":true},
    {"question":1700,"id":1700,"characteristic":"  Secondary (high) school diploma or equivalency certificate (184)","normalization":[1698,7],"parent":1698,"gender":true},
    {"question":1701,"id":1701,"characteristic":"  Postsecondary certificate, diploma or degree","normalization":[1698,7],"parent":1698,"gender":true},
    {"question":1702,"id":1702,"characteristic":"    Apprenticeship or trades certificate or diploma","normalization":[1698,7],"parent":1701,"gender":true},
    {"question":1703,"id":1703,"characteristic":"      Trades certificate or diploma other than Certificate of Apprenticeship or Certificate of Qualification (185)","normalization":[1698,7],"parent":1702,"gender":true},
    {"question":1704,"id":1704,"characteristic":"      Certificate of Apprenticeship or Certificate of Qualification (186)","normalization":[1698,7],"parent":1702,"gender":true},
    {"question":1705,"id":1705,"characteristic":"    College, CEGEP or other non-university certificate or diploma","normalization":[1698,7],"parent":1701,"gender":true},
    {"question":1706,"id":1706,"characteristic":"    University certificate or diploma below bachelor level","normalization":[1698,7],"parent":1701,"gender":true},
    {"question":1707,"id":1707,"characteristic":"    University certificate, diploma or degree at bachelor level or above","normalization":[1698,7],"parent":1701,"gender":true},
    {"question":1708,"id":1708,"characteristic":"      Bachelor's degree","normalization":[1698,7],"parent":1707,"gender":true},
    {"question":1709,"id":1709,"characteristic":"      University certificate or diploma above bachelor level","normalization":[1698,7],"parent":1707,"gender":true},
    {"question":1710,"id":1710,"characteristic":"      Degree in medicine, dentistry, veterinary medicine or optometry","normalization":[1698,7],"parent":1707,"gender":true},
    {"question":1711,"id":1711,"characteristic":"      Master's degree","normalization":[1698,7],"parent":1707,"gender":true},
    {"question":1712,"id":1712,"characteristic":"      Earned doctorate (187)","normalization":[1698,7],"parent":1707,"gender":true},
    {"question":1713,"id":1713,"characteristic":"Total - Major field of study - Classification of Instructional Programs (CIP) 2016 for the population aged 15 years and over in private households - 25% sample data (188)","normalization":[],"parent":0,"gender":true},
    {"question":1714,"id":1714,"characteristic":"  No postsecondary certificate, diploma or degree (190)","normalization":[1713,7],"parent":1713,"gender":true},
    {"question":1715,"id":1715,"characteristic":"  Education","normalization":[1713,7],"parent":1713,"gender":true},
    {"question":1716,"id":1716,"characteristic":"    13. Education","normalization":[1713,7],"parent":1715,"gender":true},
    {"question":1717,"id":1717,"characteristic":"  Visual and performing arts, and communications technologies","normalization":[1713,7],"parent":1713,"gender":true},
    {"question":1718,"id":1718,"characteristic":"    10. Communications technologies/technicians and support services","normalization":[1713,7],"parent":1717,"gender":true},
    {"question":1719,"id":1719,"characteristic":"    50. Visual and performing arts","normalization":[1713,7],"parent":1717,"gender":true},
    {"question":1720,"id":1720,"characteristic":"  Humanities","normalization":[1713,7],"parent":1713,"gender":true},
    {"question":1721,"id":1721,"characteristic":"    16. Aboriginal and foreign languages, literatures and linguistics","normalization":[1713,7],"parent":1720,"gender":true},
    {"question":1722,"id":1722,"characteristic":"    23. English language and literature/letters","normalization":[1713,7],"parent":1720,"gender":true},
    {"question":1723,"id":1723,"characteristic":"    24. Liberal arts and sciences, general studies and humanities","normalization":[1713,7],"parent":1720,"gender":true},
    {"question":1724,"id":1724,"characteristic":"    30A Interdisciplinary humanities (191)","normalization":[1713,7],"parent":1720,"gender":true},
    {"question":1725,"id":1725,"characteristic":"    38. Philosophy and religious studies","normalization":[1713,7],"parent":1720,"gender":true},
    {"question":1726,"id":1726,"characteristic":"    39. Theology and religious vocations","normalization":[1713,7],"parent":1720,"gender":true},
    {"question":1727,"id":1727,"characteristic":"    54. History","normalization":[1713,7],"parent":1720,"gender":true},
    {"question":1728,"id":1728,"characteristic":"    55. French language and literature/letters","normalization":[1713,7],"parent":1720,"gender":true},
    {"question":1729,"id":1729,"characteristic":"  Social and behavioural sciences and law","normalization":[1713,7],"parent":1713,"gender":true},
    {"question":1730,"id":1730,"characteristic":"    05. Area, ethnic, cultural, gender, and group studies","normalization":[1713,7],"parent":1729,"gender":true},
    {"question":1731,"id":1731,"characteristic":"    09. Communication, journalism and related programs","normalization":[1713,7],"parent":1729,"gender":true},
    {"question":1732,"id":1732,"characteristic":"    19. Family and consumer sciences/human sciences","normalization":[1713,7],"parent":1729,"gender":true},
    {"question":1733,"id":1733,"characteristic":"    22. Legal professions and studies","normalization":[1713,7],"parent":1729,"gender":true},
    {"question":1734,"id":1734,"characteristic":"    30B Interdisciplinary social and behavioural sciences (192)","normalization":[1713,7],"parent":1729,"gender":true},
    {"question":1735,"id":1735,"characteristic":"    42. Psychology","normalization":[1713,7],"parent":1729,"gender":true},
    {"question":1736,"id":1736,"characteristic":"    45. Social sciences","normalization":[1713,7],"parent":1729,"gender":true},
    {"question":1737,"id":1737,"characteristic":"  Business, management and public administration","normalization":[1713,7],"parent":1713,"gender":true},
    {"question":1738,"id":1738,"characteristic":"    30.16 Accounting and computer science","normalization":[1713,7],"parent":1737,"gender":true},
    {"question":1739,"id":1739,"characteristic":"    44. Public administration and social service professions","normalization":[1713,7],"parent":1737,"gender":true},
    {"question":1740,"id":1740,"characteristic":"    52. Business, management, marketing and related support services","normalization":[1713,7],"parent":1737,"gender":true},
    {"question":1741,"id":1741,"characteristic":"  Physical and life sciences and technologies","normalization":[1713,7],"parent":1713,"gender":true},
    {"question":1742,"id":1742,"characteristic":"    26. Biological and biomedical sciences","normalization":[1713,7],"parent":1742,"gender":true},
    {"question":1743,"id":1743,"characteristic":"    30.01 Biological and physical sciences","normalization":[1713,7],"parent":1742,"gender":true},
    {"question":1744,"id":1744,"characteristic":"    30C Other interdisciplinary physical and life sciences (193)","normalization":[1713,7],"parent":1742,"gender":true},
    {"question":1745,"id":1745,"characteristic":"    40. Physical sciences","normalization":[1713,7],"parent":1742,"gender":true},
    {"question":1746,"id":1746,"characteristic":"    41. Science technologies/technicians","normalization":[1713,7],"parent":1742,"gender":true},
    {"question":1747,"id":1747,"characteristic":"  Mathematics, computer and information sciences","normalization":[1713,7],"parent":1713,"gender":true},
    {"question":1748,"id":1748,"characteristic":"    11. Computer and information sciences and support services","normalization":[1713,7],"parent":1747,"gender":true},
    {"question":1749,"id":1749,"characteristic":"    25. Library science","normalization":[1713,7],"parent":1747,"gender":true},
    {"question":1750,"id":1750,"characteristic":"    27. Mathematics and statistics","normalization":[1713,7],"parent":1747,"gender":true},
    {"question":1751,"id":1751,"characteristic":"    30D Interdisciplinary mathematics, computer and information sciences (194)","normalization":[1713,7],"parent":1747,"gender":true},
    {"question":1752,"id":1752,"characteristic":"  Architecture, engineering, and related technologies","normalization":[1713,7],"parent":1713,"gender":true},
    {"question":1753,"id":1753,"characteristic":"    04. Architecture and related services","normalization":[1713,7],"parent":1752,"gender":true},
    {"question":1754,"id":1754,"characteristic":"    14. Engineering","normalization":[1713,7],"parent":1752,"gender":true},
    {"question":1755,"id":1755,"characteristic":"    15. Engineering technologies and engineering-related fields","normalization":[1713,7],"parent":1752,"gender":true},
    {"question":1756,"id":1756,"characteristic":"    30.12 Historic preservation and conservation","normalization":[1713,7],"parent":1752,"gender":true},
    {"question":1757,"id":1757,"characteristic":"    46. Construction trades","normalization":[1713,7],"parent":1752,"gender":true},
    {"question":1758,"id":1758,"characteristic":"    47. Mechanic and repair technologies/technicians","normalization":[1713,7],"parent":1752,"gender":true},
    {"question":1759,"id":1759,"characteristic":"    48. Precision production","normalization":[1713,7],"parent":1752,"gender":true},
    {"question":1760,"id":1760,"characteristic":"  Agriculture, natural resources and conservation","normalization":[1713,7],"parent":1713,"gender":true},
    {"question":1761,"id":1761,"characteristic":"    01. Agriculture, agriculture operations and related sciences","normalization":[1713,7],"parent":1760,"gender":true},
    {"question":1762,"id":1762,"characteristic":"    03. Natural resources and conservation","normalization":[1713,7],"parent":1760,"gender":true},
    {"question":1763,"id":1763,"characteristic":"  Health and related fields","normalization":[1713,7],"parent":1713,"gender":true},
    {"question":1764,"id":1764,"characteristic":"    31. Parks, recreation, leisure and fitness studies","normalization":[1713,7],"parent":1763,"gender":true},
    {"question":1765,"id":1765,"characteristic":"    51. Health professions and related programs","normalization":[1713,7],"parent":1763,"gender":true},
    {"question":1766,"id":1766,"characteristic":"    60. Dental, medical and veterinary residency programs","normalization":[1713,7],"parent":1763,"gender":true},
    {"question":1767,"id":1767,"characteristic":"  Personal, protective and transportation services","normalization":[1713,7],"parent":1713,"gender":true},
    {"question":1768,"id":1768,"characteristic":"    12. Personal and culinary services","normalization":[1713,7],"parent":1767,"gender":true},
    {"question":1769,"id":1769,"characteristic":"    28. Military science, leadership and operational art","normalization":[1713,7],"parent":1767,"gender":true},
    {"question":1770,"id":1770,"characteristic":"    29. Military technologies and applied sciences","normalization":[1713,7],"parent":1767,"gender":true},
    {"question":1771,"id":1771,"characteristic":"    43. Security and protective services","normalization":[1713,7],"parent":1767,"gender":true},
    {"question":1772,"id":1772,"characteristic":"    49. Transportation and materials moving","normalization":[1713,7],"parent":1767,"gender":true},
    {"question":1773,"id":1773,"characteristic":"  Other","normalization":[1713,7],"parent":1713,"gender":true},
    {"question":1774,"id":1774,"characteristic":"    30.99 Multidisciplinary/interdisciplinary studies, other","normalization":[1713,7],"parent":1773,"gender":true},
    {"question":1775,"id":1775,"characteristic":"Total - Major field of study - Classification of Instructional Programs (CIP) 2016 for the population aged 25 to 64 years in private households - 25% sample data (195)","normalization":[],"parent":0,"gender":true},
    {"question":1776,"id":1776,"characteristic":"  No postsecondary certificate, diploma or degree (197)","normalization":[1775,7],"parent":1775,"gender":true},
    {"question":1777,"id":1777,"characteristic":"  Education","normalization":[1775,7],"parent":1775,"gender":true},
    {"question":1778,"id":1778,"characteristic":"    13. Education","normalization":[1775,7],"parent":1777,"gender":true},
    {"question":1779,"id":1779,"characteristic":"  Visual and performing arts, and communications technologies","normalization":[1775,7],"parent":1775,"gender":true},
    {"question":1780,"id":1780,"characteristic":"    10. Communications technologies/technicians and support services","normalization":[1775,7],"parent":1779,"gender":true},
    {"question":1781,"id":1781,"characteristic":"    50. Visual and performing arts","normalization":[1775,7],"parent":1779,"gender":true},
    {"question":1782,"id":1782,"characteristic":"  Humanities","normalization":[1775,7],"parent":1775,"gender":true},
    {"question":1783,"id":1783,"characteristic":"    16. Aboriginal and foreign languages, literatures and linguistics","normalization":[1775,7],"parent":1782,"gender":true},
    {"question":1784,"id":1784,"characteristic":"    23. English language and literature/letters","normalization":[1775,7],"parent":1782,"gender":true},
    {"question":1785,"id":1785,"characteristic":"    24. Liberal arts and sciences, general studies and humanities","normalization":[1775,7],"parent":1782,"gender":true},
    {"question":1786,"id":1786,"characteristic":"    30A Interdisciplinary humanities (198)","normalization":[1775,7],"parent":1782,"gender":true},
    {"question":1787,"id":1787,"characteristic":"    38. Philosophy and religious studies","normalization":[1775,7],"parent":1782,"gender":true},
    {"question":1788,"id":1788,"characteristic":"    39. Theology and religious vocations","normalization":[1775,7],"parent":1782,"gender":true},
    {"question":1789,"id":1789,"characteristic":"    54. History","normalization":[1775,7],"parent":1782,"gender":true},
    {"question":1790,"id":1790,"characteristic":"    55. French language and literature/letters","normalization":[1775,7],"parent":1782,"gender":true},
    {"question":1791,"id":1791,"characteristic":"  Social and behavioural sciences and law","normalization":[1775,7],"parent":1775,"gender":true},
    {"question":1792,"id":1792,"characteristic":"    05. Area, ethnic, cultural, gender, and group studies","normalization":[1775,7],"parent":1791,"gender":true},
    {"question":1793,"id":1793,"characteristic":"    09. Communication, journalism and related programs","normalization":[1775,7],"parent":1791,"gender":true},
    {"question":1794,"id":1794,"characteristic":"    19. Family and consumer sciences/human sciences","normalization":[1775,7],"parent":1791,"gender":true},
    {"question":1795,"id":1795,"characteristic":"    22. Legal professions and studies","normalization":[1775,7],"parent":1791,"gender":true},
    {"question":1796,"id":1796,"characteristic":"    30B Interdisciplinary social and behavioural sciences (199)","normalization":[1775,7],"parent":1791,"gender":true},
    {"question":1797,"id":1797,"characteristic":"    42. Psychology","normalization":[1775,7],"parent":1791,"gender":true},
    {"question":1798,"id":1798,"characteristic":"    45. Social sciences","normalization":[1775,7],"parent":1791,"gender":true},
    {"question":1799,"id":1799,"characteristic":"  Business, management and public administration","normalization":[1775,7],"parent":1775,"gender":true},
    {"question":1800,"id":1800,"characteristic":"    30.16 Accounting and computer science","normalization":[1775,7],"parent":1799,"gender":true},
    {"question":1801,"id":1801,"characteristic":"    44. Public administration and social service professions","normalization":[1775,7],"parent":1799,"gender":true},
    {"question":1802,"id":1802,"characteristic":"    52. Business, management, marketing and related support services","normalization":[1775,7],"parent":1799,"gender":true},
    {"question":1803,"id":1803,"characteristic":"  Physical and life sciences and technologies","normalization":[1775,7],"parent":1775,"gender":true},
    {"question":1804,"id":1804,"characteristic":"    26. Biological and biomedical sciences","normalization":[1775,7],"parent":1803,"gender":true},
    {"question":1805,"id":1805,"characteristic":"    30.01 Biological and physical sciences","normalization":[1775,7],"parent":1803,"gender":true},
    {"question":1806,"id":1806,"characteristic":"    30C Other interdisciplinary physical and life sciences (200)","normalization":[1775,7],"parent":1803,"gender":true},
    {"question":1807,"id":1807,"characteristic":"    40. Physical sciences","normalization":[1775,7],"parent":1803,"gender":true},
    {"question":1808,"id":1808,"characteristic":"    41. Science technologies/technicians","normalization":[1775,7],"parent":1803,"gender":true},
    {"question":1809,"id":1809,"characteristic":"  Mathematics, computer and information sciences","normalization":[1775,7],"parent":1775,"gender":true},
    {"question":1810,"id":1810,"characteristic":"    11. Computer and information sciences and support services","normalization":[1775,7],"parent":1809,"gender":true},
    {"question":1811,"id":1811,"characteristic":"    25. Library science","normalization":[1775,7],"parent":1809,"gender":true},
    {"question":1812,"id":1812,"characteristic":"    27. Mathematics and statistics","normalization":[1775,7],"parent":1809,"gender":true},
    {"question":1813,"id":1813,"characteristic":"    30D Interdisciplinary mathematics, computer and information sciences (201)","normalization":[1775,7],"parent":1809,"gender":true},
    {"question":1814,"id":1814,"characteristic":"  Architecture, engineering, and related technologies","normalization":[1775,7],"parent":1775,"gender":true},
    {"question":1815,"id":1815,"characteristic":"    04. Architecture and related services","normalization":[1775,7],"parent":1814,"gender":true},
    {"question":1816,"id":1816,"characteristic":"    14. Engineering","normalization":[1775,7],"parent":1814,"gender":true},
    {"question":1817,"id":1817,"characteristic":"    15. Engineering technologies and engineering-related fields","normalization":[1775,7],"parent":1814,"gender":true},
    {"question":1818,"id":1818,"characteristic":"    30.12 Historic preservation and conservation","normalization":[1775,7],"parent":1814,"gender":true},
    {"question":1819,"id":1819,"characteristic":"    46. Construction trades","normalization":[1775,7],"parent":1814,"gender":true},
    {"question":1820,"id":1820,"characteristic":"    47. Mechanic and repair technologies/technicians","normalization":[1775,7],"parent":1814,"gender":true},
    {"question":1821,"id":1821,"characteristic":"    48. Precision production","normalization":[1775,7],"parent":1814,"gender":true},
    {"question":1822,"id":1822,"characteristic":"  Agriculture, natural resources and conservation","normalization":[1775,7],"parent":1775,"gender":true},
    {"question":1823,"id":1823,"characteristic":"    01. Agriculture, agriculture operations and related sciences","normalization":[1775,7],"parent":1822,"gender":true},
    {"question":1824,"id":1824,"characteristic":"    03. Natural resources and conservation","normalization":[1775,7],"parent":1822,"gender":true},
    {"question":1825,"id":1825,"characteristic":"  Health and related fields","normalization":[1775,7],"parent":1775,"gender":true},
    {"question":1826,"id":1826,"characteristic":"    31. Parks, recreation, leisure and fitness studies","normalization":[1775,7],"parent":1825,"gender":true},
    {"question":1827,"id":1827,"characteristic":"    51. Health professions and related programs","normalization":[1775,7],"parent":1825,"gender":true},
    {"question":1828,"id":1828,"characteristic":"    60. Dental, medical and veterinary residency programs","normalization":[1775,7],"parent":1825,"gender":true},
    {"question":1829,"id":1829,"characteristic":"  Personal, protective and transportation services","normalization":[1775,7],"parent":1775,"gender":true},
    {"question":1830,"id":1830,"characteristic":"    12. Personal and culinary services","normalization":[1775,7],"parent":1829,"gender":true},
    {"question":1831,"id":1831,"characteristic":"    28. Military science, leadership and operational art","normalization":[1775,7],"parent":1829,"gender":true},
    {"question":1832,"id":1832,"characteristic":"    29. Military technologies and applied sciences","normalization":[1775,7],"parent":1829,"gender":true},
    {"question":1833,"id":1833,"characteristic":"    43. Security and protective services","normalization":[1775,7],"parent":1829,"gender":true},
    {"question":1834,"id":1834,"characteristic":"    49. Transportation and materials moving","normalization":[1775,7],"parent":1829,"gender":true},
    {"question":1835,"id":1835,"characteristic":"  Other","normalization":[1775,7],"parent":1775,"gender":true},
    {"question":1836,"id":1836,"characteristic":"    30.99 Multidisciplinary/interdisciplinary studies, other","normalization":[1775,7],"parent":1835,"gender":true},
    {"question":1837,"id":1837,"characteristic":"Total - Location of study compared with province or territory of residence with countries outside Canada for the population aged 15 years and over in private households - 25% sample data (202)","normalization":[],"parent":0,"gender":true},
    {"question":1838,"id":1838,"characteristic":"  No postsecondary certificate, diploma or degree","normalization":[1837,7],"parent":1837,"gender":true},
    {"question":1839,"id":1839,"characteristic":"  Postsecondary certificate, diploma or degree (203)","normalization":[1837,7],"parent":1837,"gender":true},
    {"question":1840,"id":1840,"characteristic":"    Location of study inside Canada","normalization":[1837,7],"parent":1839,"gender":true},
    {"question":1841,"id":1841,"characteristic":"      Same as province or territory of residence","normalization":[1837,7],"parent":1840,"gender":true},
    {"question":1842,"id":1842,"characteristic":"      Different than province or territory of residence","normalization":[1837,7],"parent":1840,"gender":true},
    {"question":1843,"id":1843,"characteristic":"    Location of study outside Canada (204)","normalization":[1837,7],"parent":1839,"gender":true},
    {"question":1844,"id":1844,"characteristic":"      United States (205)","normalization":[1837,7],"parent":1843,"gender":true},
    {"question":1845,"id":1845,"characteristic":"      Philippines","normalization":[1837,7],"parent":1843,"gender":true},
    {"question":1846,"id":1846,"characteristic":"      India","normalization":[1837,7],"parent":1843,"gender":true},
    {"question":1847,"id":1847,"characteristic":"      United Kingdom (206)","normalization":[1837,7],"parent":1843,"gender":true},
    {"question":1848,"id":1848,"characteristic":"      China (207)","normalization":[1837,7],"parent":1843,"gender":true},
    {"question":1849,"id":1849,"characteristic":"      France","normalization":[1837,7],"parent":1843,"gender":true},
    {"question":1850,"id":1850,"characteristic":"      Other","normalization":[1837,7],"parent":1843,"gender":true},
    {"question":1851,"id":1851,"characteristic":"Total - Location of study compared with province or territory of residence with countries outside Canada for the population aged 25 to 64 years in private households - 25% sample data (208)","normalization":[],"parent":0,"gender":true},
    {"question":1852,"id":1852,"characteristic":"  No postsecondary certificate, diploma or degree","normalization":[1851,7],"parent":1851,"gender":true},
    {"question":1853,"id":1853,"characteristic":"  Postsecondary certificate, diploma or degree (209)","normalization":[1851,7],"parent":1851,"gender":true},
    {"question":1854,"id":1854,"characteristic":"    Location of study inside Canada","normalization":[1851,7],"parent":1853,"gender":true},
    {"question":1855,"id":1855,"characteristic":"      Same as province or territory of residence","normalization":[1851,7],"parent":1854,"gender":true},
    {"question":1856,"id":1856,"characteristic":"      Different than province or territory of residence","normalization":[1851,7],"parent":1854,"gender":true},
    {"question":1857,"id":1857,"characteristic":"    Location of study outside Canada (210)","normalization":[1851,7],"parent":1853,"gender":true},
    {"question":1858,"id":1858,"characteristic":"      United States (211)","normalization":[1851,7],"parent":1857,"gender":true},
    {"question":1859,"id":1859,"characteristic":"      Philippines","normalization":[1851,7],"parent":1857,"gender":true},
    {"question":1860,"id":1860,"characteristic":"      India","normalization":[1851,7],"parent":1857,"gender":true},
    {"question":1861,"id":1861,"characteristic":"      United Kingdom (212)","normalization":[1851,7],"parent":1857,"gender":true},
    {"question":1862,"id":1862,"characteristic":"      China (213)","normalization":[1851,7],"parent":1857,"gender":true},
    {"question":1863,"id":1863,"characteristic":"      France","normalization":[1851,7],"parent":1857,"gender":true},
    {"question":1864,"id":1864,"characteristic":"      Other","normalization":[1851,7],"parent":1857,"gender":true},
    {"question":1865,"id":1865,"characteristic":"Total - Population aged 15 years and over by Labour force status - 25% sample data (214)","normalization":[],"parent":0,"gender":true},
    {"question":1866,"id":1866,"characteristic":"  In the labour force","normalization":[1865,7],"parent":1865,"gender":true},
    {"question":1867,"id":1867,"characteristic":"    Employed","normalization":[1865,7],"parent":1866,"gender":true},
    {"question":1868,"id":1868,"characteristic":"    Unemployed","normalization":[1865,7],"parent":1866,"gender":true},
    {"question":1869,"id":1869,"characteristic":"  Not in the labour force","normalization":[1865,7],"parent":1865,"gender":true},
    {"question":1870,"id":1870,"characteristic":"Participation rate","normalization":[],"parent":0,"gender":true},
    {"question":1871,"id":1871,"characteristic":"Employment rate","normalization":[],"parent":0,"gender":true},
    {"question":1872,"id":1872,"characteristic":"Unemployment rate","normalization":[],"parent":0,"gender":true},
    {"question":1873,"id":1873,"characteristic":"Total population aged 15 years and over by work activity during the reference year - 25% sample data (215)","normalization":[],"parent":0,"gender":true},
    {"question":1874,"id":1874,"characteristic":"  Did not work (216)","normalization":[1873,7],"parent":1873,"gender":true},
    {"question":1875,"id":1875,"characteristic":"  Worked","normalization":[1873,7],"parent":1873,"gender":true},
    {"question":1876,"id":1876,"characteristic":"    Worked full year, full time (217)","normalization":[1873,7],"parent":1875,"gender":true},
    {"question":1877,"id":1877,"characteristic":"    Worked part year and/or part time (218)","normalization":[1873,7],"parent":1875,"gender":true},
    {"question":1878,"id":1878,"characteristic":"Average weeks worked in reference year","normalization":[],"parent":0,"gender":true},
    {"question":1879,"id":1879,"characteristic":"Total labour force aged 15 years and over by class of worker - 25% sample data (219)","normalization":[],"parent":0,"gender":true},
    {"question":1880,"id":1880,"characteristic":"  Class of worker - not applicable (220)","normalization":[1879,7],"parent":1879,"gender":true},
    {"question":1881,"id":1881,"characteristic":"  All classes of workers (221)","normalization":[1879,7],"parent":1879,"gender":true},
    {"question":1882,"id":1882,"characteristic":"    Employee","normalization":[1879,7],"parent":1881,"gender":true},
    {"question":1883,"id":1883,"characteristic":"    Self-employed (222)","normalization":[1879,7],"parent":1881,"gender":true},
    {"question":1884,"id":1884,"characteristic":"Total labour force population aged 15 years and over by occupation - National Occupational Classification (NOC) 2016 - 25% sample data (223)","normalization":[],"parent":0,"gender":true},
    {"question":1885,"id":1885,"characteristic":"  Occupation - not applicable (224)","normalization":[1884,7],"parent":1884,"gender":true},
    {"question":1886,"id":1886,"characteristic":"  All occupations (225)","normalization":[1884,7],"parent":1884,"gender":true},
    {"question":1887,"id":1887,"characteristic":"    0 Management occupations","normalization":[1884,7],"parent":1886,"gender":true},
    {"question":1888,"id":1888,"characteristic":"    1 Business, finance and administration occupations","normalization":[1884,7],"parent":1886,"gender":true},
    {"question":1889,"id":1889,"characteristic":"    2 Natural and applied sciences and related occupations","normalization":[1884,7],"parent":1886,"gender":true},
    {"question":1890,"id":1890,"characteristic":"    3 Health occupations","normalization":[1884,7],"parent":1886,"gender":true},
    {"question":1891,"id":1891,"characteristic":"    4 Occupations in education, law and social, community and government services","normalization":[1884,7],"parent":1886,"gender":true},
    {"question":1892,"id":1892,"characteristic":"    5 Occupations in art, culture, recreation and sport","normalization":[1884,7],"parent":1886,"gender":true},
    {"question":1893,"id":1893,"characteristic":"    6 Sales and service occupations","normalization":[1884,7],"parent":1886,"gender":true},
    {"question":1894,"id":1894,"characteristic":"    7 Trades, transport and equipment operators and related occupations","normalization":[1884,7],"parent":1886,"gender":true},
    {"question":1895,"id":1895,"characteristic":"    8 Natural resources, agriculture and related production occupations","normalization":[1884,7],"parent":1886,"gender":true},
    {"question":1896,"id":1896,"characteristic":"    9 Occupations in manufacturing and utilities","normalization":[1884,7],"parent":1886,"gender":true},
    {"question":1897,"id":1897,"characteristic":"Total Labour Force population aged 15 years and over by Industry - North American Industry Classification System (NAICS) 2012 - 25% sample data (226)","normalization":[],"parent":0,"gender":true},
    {"question":1898,"id":1898,"characteristic":"  Industry - NAICS2012 - not applicable (227)","normalization":[1897,7],"parent":1897,"gender":true},
    {"question":1899,"id":1899,"characteristic":"  All industry categories (228)","normalization":[1897,7],"parent":1897,"gender":true},
    {"question":1900,"id":1900,"characteristic":"    11 Agriculture, forestry, fishing and hunting","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1901,"id":1901,"characteristic":"    21 Mining, quarrying, and oil and gas extraction","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1902,"id":1902,"characteristic":"    22 Utilities","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1903,"id":1903,"characteristic":"    23 Construction","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1904,"id":1904,"characteristic":"    31-33 Manufacturing","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1905,"id":1905,"characteristic":"    41 Wholesale trade","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1906,"id":1906,"characteristic":"    44-45 Retail trade","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1907,"id":1907,"characteristic":"    48-49 Transportation and warehousing","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1908,"id":1908,"characteristic":"    51 Information and cultural industries","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1909,"id":1909,"characteristic":"    52 Finance and insurance","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1910,"id":1910,"characteristic":"    53 Real estate and rental and leasing","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1911,"id":1911,"characteristic":"    54 Professional, scientific and technical services","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1912,"id":1912,"characteristic":"    55 Management of companies and enterprises","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1913,"id":1913,"characteristic":"    56 Administrative and support, waste management and remediation services","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1914,"id":1914,"characteristic":"    61 Educational services","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1915,"id":1915,"characteristic":"    62 Health care and social assistance","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1916,"id":1916,"characteristic":"    71 Arts, entertainment and recreation","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1917,"id":1917,"characteristic":"    72 Accommodation and food services","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1918,"id":1918,"characteristic":"    81 Other services (except public administration)","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1919,"id":1919,"characteristic":"    91 Public administration","normalization":[1897,7],"parent":1899,"gender":true},
    {"question":1920,"id":1920,"characteristic":"Total - Place of work status for the employed labour force aged 15 years and over in private households - 25% sample data (229)","normalization":[],"parent":0,"gender":true},
    {"question":1921,"id":1921,"characteristic":"  Worked at home","normalization":[1920,7],"parent":1920,"gender":true},
    {"question":1922,"id":1922,"characteristic":"  Worked outside Canada","normalization":[1920,7],"parent":1920,"gender":true},
    {"question":1923,"id":1923,"characteristic":"  No fixed workplace address","normalization":[1920,7],"parent":1920,"gender":true},
    {"question":1924,"id":1924,"characteristic":"  Worked at usual place","normalization":[1920,7],"parent":1920,"gender":true},
    {"question":1925,"id":1925,"characteristic":"Total - Commuting destination for the employed labour force aged 15 years and over in private households with a usual place of work - 25% sample data","normalization":[],"parent":0,"gender":true},
    {"question":1926,"id":1926,"characteristic":"  Commute within census subdivision (CSD) of residence","normalization":[1925,7],"parent":1925,"gender":true},
    {"question":1927,"id":1927,"characteristic":"  Commute to a different census subdivision (CSD) within census division (CD) of residence","normalization":[1925,7],"parent":1925,"gender":true},
    {"question":1928,"id":1928,"characteristic":"  Commute to a different census subdivision (CSD) and census division (CD) within province or territory of residence","normalization":[1925,7],"parent":1925,"gender":true},
    {"question":1929,"id":1929,"characteristic":"  Commute to a different province or territory","normalization":[1925,7],"parent":1925,"gender":true},
    {"question":1930,"id":1930,"characteristic":"Total - Main mode of commuting for the employed labour force aged 15 years and over in private households with a usual place of work or no fixed workplace address - 25% sample data (230)","normalization":[],"parent":0,"gender":true},
    {"question":1931,"id":1931,"characteristic":"  Car, truck, van - as a driver","normalization":[1930,7],"parent":1930,"gender":true},
    {"question":1932,"id":1932,"characteristic":"  Car, truck, van - as a passenger","normalization":[1930,7],"parent":1930,"gender":true},
    {"question":1933,"id":1933,"characteristic":"  Public transit","normalization":[1930,7],"parent":1930,"gender":true},
    {"question":1934,"id":1934,"characteristic":"  Walked","normalization":[1930,7],"parent":1930,"gender":true},
    {"question":1935,"id":1935,"characteristic":"  Bicycle","normalization":[1930,7],"parent":1930,"gender":true},
    {"question":1936,"id":1936,"characteristic":"  Other method","normalization":[1930,7],"parent":1930,"gender":true},
    {"question":1937,"id":1937,"characteristic":"Total - Commuting duration for the employed labour force aged 15 years and over in private households with a usual place of work or no fixed workplace address - 25% sample data (231)","normalization":[],"parent":0,"gender":true},
    {"question":1938,"id":1938,"characteristic":"  Less than 15 minutes","normalization":[1937,7],"parent":1937,"gender":true},
    {"question":1939,"id":1939,"characteristic":"  15 to 29 minutes","normalization":[1937,7],"parent":1937,"gender":true},
    {"question":1940,"id":1940,"characteristic":"  30 to 44 minutes","normalization":[1937,7],"parent":1937,"gender":true},
    {"question":1941,"id":1941,"characteristic":"  45 to 59 minutes","normalization":[1937,7],"parent":1937,"gender":true},
    {"question":1942,"id":1942,"characteristic":"  60 minutes and over","normalization":[1937,7],"parent":1937,"gender":true},
    {"question":1943,"id":1943,"characteristic":"Total - Time leaving for work for the employed labour force aged 15 years and over in private households with a usual place of work or no fixed workplace address - 25% sample data (232)","normalization":[],"parent":0,"gender":true},
    {"question":1944,"id":1944,"characteristic":"  Between 5 a.m. and 5:59 a.m.","normalization":[1943,7],"parent":1943,"gender":true},
    {"question":1945,"id":1945,"characteristic":"  Between 6 a.m. and 6:59 a.m.","normalization":[1943,7],"parent":1943,"gender":true},
    {"question":1946,"id":1946,"characteristic":"  Between 7 a.m. and 7:59 a.m.","normalization":[1943,7],"parent":1943,"gender":true},
    {"question":1947,"id":1947,"characteristic":"  Between 8 a.m. and 8:59 a.m.","normalization":[1943,7],"parent":1943,"gender":true},
    {"question":1948,"id":1948,"characteristic":"  Between 9 a.m. and 11:59 a.m.","normalization":[1943,7],"parent":1943,"gender":true},
    {"question":1949,"id":1949,"characteristic":"  Between 12 p.m. and 4:59 a.m.","normalization":[1943,7],"parent":1943,"gender":true},
    {"question":1950,"id":1950,"characteristic":"Total - Language used most often at work for the population in private households aged 15 years and over who worked since January 1, 2015 - 25% sample data (233)","normalization":[],"parent":0,"gender":true},
    {"question":1951,"id":1951,"characteristic":"  Single responses","normalization":[1950,7],"parent":1950,"gender":true},
    {"question":1952,"id":1952,"characteristic":"    Official languages","normalization":[1950,7],"parent":1951,"gender":true},
    {"question":1953,"id":1953,"characteristic":"      English","normalization":[1950,7],"parent":1952,"gender":true},
    {"question":1954,"id":1954,"characteristic":"      French","normalization":[1950,7],"parent":1952,"gender":true},
    {"question":1955,"id":1955,"characteristic":"    Non-official languages","normalization":[1950,7],"parent":1951,"gender":true},
    {"question":1956,"id":1956,"characteristic":"      Aboriginal languages","normalization":[1950,7],"parent":1955,"gender":true},
    {"question":1957,"id":1957,"characteristic":"        Algonquian languages","normalization":[1950,7],"parent":1956,"gender":true},
    {"question":1958,"id":1958,"characteristic":"          Blackfoot","normalization":[1950,7],"parent":1957,"gender":true},
    {"question":1959,"id":1959,"characteristic":"          Cree-Montagnais languages","normalization":[1950,7],"parent":1957,"gender":true},
    {"question":1960,"id":1960,"characteristic":"            Atikamekw","normalization":[1950,7],"parent":1959,"gender":true},
    {"question":1961,"id":1961,"characteristic":"            Montagnais (Innu)","normalization":[1950,7],"parent":1959,"gender":true},
    {"question":1962,"id":1962,"characteristic":"            Moose Cree","normalization":[1950,7],"parent":1959,"gender":true},
    {"question":1963,"id":1963,"characteristic":"            Naskapi","normalization":[1950,7],"parent":1959,"gender":true},
    {"question":1964,"id":1964,"characteristic":"            Northern East Cree","normalization":[1950,7],"parent":1959,"gender":true},
    {"question":1965,"id":1965,"characteristic":"            Plains Cree","normalization":[1950,7],"parent":1959,"gender":true},
    {"question":1966,"id":1966,"characteristic":"            Southern East Cree","normalization":[1950,7],"parent":1959,"gender":true},
    {"question":1967,"id":1967,"characteristic":"            Swampy Cree","normalization":[1950,7],"parent":1959,"gender":true},
    {"question":1968,"id":1968,"characteristic":"            Woods Cree","normalization":[1950,7],"parent":1959,"gender":true},
    {"question":1969,"id":1969,"characteristic":"            Cree, n.o.s.","normalization":[1950,7],"parent":1959,"gender":true},
    {"question":1970,"id":1970,"characteristic":"          Eastern Algonquian languages","normalization":[1950,7],"parent":1957,"gender":true},
    {"question":1971,"id":1971,"characteristic":"            Malecite","normalization":[1950,7],"parent":1970,"gender":true},
    {"question":1972,"id":1972,"characteristic":"            Mi'kmaq","normalization":[1950,7],"parent":1970,"gender":true},
    {"question":1973,"id":1973,"characteristic":"          Ojibway-Potawatomi languages","normalization":[1950,7],"parent":1957,"gender":true},
    {"question":1974,"id":1974,"characteristic":"            Algonquin","normalization":[1950,7],"parent":1973,"gender":true},
    {"question":1975,"id":1975,"characteristic":"            Ojibway","normalization":[1950,7],"parent":1973,"gender":true},
    {"question":1976,"id":1976,"characteristic":"            Oji-Cree","normalization":[1950,7],"parent":1973,"gender":true},
    {"question":1977,"id":1977,"characteristic":"            Ottawa (Odawa)","normalization":[1950,7],"parent":1973,"gender":true},
    {"question":1978,"id":1978,"characteristic":"          Algonquian languages, n.i.e.","normalization":[1950,7],"parent":1957,"gender":true},
    {"question":1979,"id":1979,"characteristic":"        Athabaskan languages","normalization":[1950,7],"parent":1956,"gender":true},
    {"question":1980,"id":1980,"characteristic":"          Northern Athabaskan languages","normalization":[1950,7],"parent":1979,"gender":true},
    {"question":1981,"id":1981,"characteristic":"            Babine (Wetsuwet'en)","normalization":[1950,7],"parent":1980,"gender":true},
    {"question":1982,"id":1982,"characteristic":"            Beaver","normalization":[1950,7],"parent":1980,"gender":true},
    {"question":1983,"id":1983,"characteristic":"            Carrier","normalization":[1950,7],"parent":1980,"gender":true},
    {"question":1984,"id":1984,"characteristic":"            Chilcotin","normalization":[1950,7],"parent":1980,"gender":true},
    {"question":1985,"id":1985,"characteristic":"            Dene","normalization":[1950,7],"parent":1980,"gender":true},
    {"question":1986,"id":1986,"characteristic":"            Dogrib (Tlicho)","normalization":[1950,7],"parent":1980,"gender":true},
    {"question":1987,"id":1987,"characteristic":"            Gwich'in","normalization":[1950,7],"parent":1980,"gender":true},
    {"question":1988,"id":1988,"characteristic":"            Sarsi (Sarcee)","normalization":[1950,7],"parent":1980,"gender":true},
    {"question":1989,"id":1989,"characteristic":"            Sekani","normalization":[1950,7],"parent":1980,"gender":true},
    {"question":1990,"id":1990,"characteristic":"            Slavey-Hare languages","normalization":[1950,7],"parent":1980,"gender":true},
    {"question":1991,"id":1991,"characteristic":"              North Slavey (Hare)","normalization":[1950,7],"parent":1990,"gender":true},
    {"question":1992,"id":1992,"characteristic":"              South Slavey","normalization":[1950,7],"parent":1990,"gender":true},
    {"question":1993,"id":1993,"characteristic":"              Slavey, n.o.s.","normalization":[1950,7],"parent":1990,"gender":true},
    {"question":1994,"id":1994,"characteristic":"            Tahltan languages","normalization":[1950,7],"parent":1980,"gender":true},
    {"question":1995,"id":1995,"characteristic":"              Kaska (Nahani)","normalization":[1950,7],"parent":1994,"gender":true},
    {"question":1996,"id":1996,"characteristic":"              Tahltan","normalization":[1950,7],"parent":1994,"gender":true},
    {"question":1997,"id":1997,"characteristic":"            Tutchone languages","normalization":[1950,7],"parent":1980,"gender":true},
    {"question":1998,"id":1998,"characteristic":"              Northern Tutchone","normalization":[1950,7],"parent":1997,"gender":true},
    {"question":1999,"id":1999,"characteristic":"              Southern Tutchone","normalization":[1950,7],"parent":1997,"gender":true},
    {"question":2000,"id":2000,"characteristic":"          Athabaskan languages, n.i.e.","normalization":[1950,7],"parent":1979,"gender":true},
    {"question":2001,"id":2001,"characteristic":"        Haida","normalization":[1950,7],"parent":1956,"gender":true},
    {"question":2002,"id":2002,"characteristic":"        Inuit languages","normalization":[1950,7],"parent":1956,"gender":true},
    {"question":2003,"id":2003,"characteristic":"          Inuinnaqtun (Inuvialuktun)","normalization":[1950,7],"parent":2002,"gender":true},
    {"question":2004,"id":2004,"characteristic":"          Inuktitut","normalization":[1950,7],"parent":2002,"gender":true},
    {"question":2005,"id":2005,"characteristic":"          Inuit languages, n.i.e.","normalization":[1950,7],"parent":2002,"gender":true},
    {"question":2006,"id":2006,"characteristic":"        Iroquoian languages","normalization":[1950,7],"parent":1956,"gender":true},
    {"question":2007,"id":2007,"characteristic":"          Cayuga","normalization":[1950,7],"parent":2006,"gender":true},
    {"question":2008,"id":2008,"characteristic":"          Mohawk","normalization":[1950,7],"parent":2006,"gender":true},
    {"question":2009,"id":2009,"characteristic":"          Oneida","normalization":[1950,7],"parent":2006,"gender":true},
    {"question":2010,"id":2010,"characteristic":"          Iroquoian languages, n.i.e.","normalization":[1950,7],"parent":2006,"gender":true},
    {"question":2011,"id":2011,"characteristic":"        Kutenai","normalization":[1950,7],"parent":1956,"gender":true},
    {"question":2012,"id":2012,"characteristic":"        Michif","normalization":[1950,7],"parent":1956,"gender":true},
    {"question":2013,"id":2013,"characteristic":"        Salish languages","normalization":[1950,7],"parent":1956,"gender":true},
    {"question":2014,"id":2014,"characteristic":"          Comox","normalization":[1950,7],"parent":2013,"gender":true},
    {"question":2015,"id":2015,"characteristic":"          Halkomelem","normalization":[1950,7],"parent":2013,"gender":true},
    {"question":2016,"id":2016,"characteristic":"          Lillooet","normalization":[1950,7],"parent":2013,"gender":true},
    {"question":2017,"id":2017,"characteristic":"          Okanagan","normalization":[1950,7],"parent":2013,"gender":true},
    {"question":2018,"id":2018,"characteristic":"          Shuswap (Secwepemctsin)","normalization":[1950,7],"parent":2013,"gender":true},
    {"question":2019,"id":2019,"characteristic":"          Squamish","normalization":[1950,7],"parent":2013,"gender":true},
    {"question":2020,"id":2020,"characteristic":"          Straits","normalization":[1950,7],"parent":2013,"gender":true},
    {"question":2021,"id":2021,"characteristic":"          Thompson (Ntlakapamux)","normalization":[1950,7],"parent":2013,"gender":true},
    {"question":2022,"id":2022,"characteristic":"          Salish languages, n.i.e.","normalization":[1950,7],"parent":2013,"gender":true},
    {"question":2023,"id":2023,"characteristic":"        Siouan languages","normalization":[1950,7],"parent":1956,"gender":true},
    {"question":2024,"id":2024,"characteristic":"          Dakota","normalization":[1950,7],"parent":2023,"gender":true},
    {"question":2025,"id":2025,"characteristic":"          Stoney","normalization":[1950,7],"parent":2023,"gender":true},
    {"question":2026,"id":2026,"characteristic":"          Siouan languages, n.i.e.","normalization":[1950,7],"parent":2023,"gender":true},
    {"question":2027,"id":2027,"characteristic":"        Tlingit","normalization":[1950,7],"parent":1956,"gender":true},
    {"question":2028,"id":2028,"characteristic":"        Tsimshian languages","normalization":[1950,7],"parent":1956,"gender":true},
    {"question":2029,"id":2029,"characteristic":"          Gitxsan (Gitksan)","normalization":[1950,7],"parent":2028,"gender":true},
    {"question":2030,"id":2030,"characteristic":"          Nisga'a","normalization":[1950,7],"parent":2028,"gender":true},
    {"question":2031,"id":2031,"characteristic":"          Tsimshian","normalization":[1950,7],"parent":2028,"gender":true},
    {"question":2032,"id":2032,"characteristic":"        Wakashan languages","normalization":[1950,7],"parent":1956,"gender":true},
    {"question":2033,"id":2033,"characteristic":"          Haisla","normalization":[1950,7],"parent":2032,"gender":true},
    {"question":2034,"id":2034,"characteristic":"          Heiltsuk","normalization":[1950,7],"parent":2032,"gender":true},
    {"question":2035,"id":2035,"characteristic":"          Kwakiutl (Kwak'wala)","normalization":[1950,7],"parent":2032,"gender":true},
    {"question":2036,"id":2036,"characteristic":"          Nuu-chah-nulth (Nootka)","normalization":[1950,7],"parent":2032,"gender":true},
    {"question":2037,"id":2037,"characteristic":"          Wakashan languages, n.i.e.","normalization":[1950,7],"parent":2032,"gender":true},
    {"question":2038,"id":2038,"characteristic":"        Aboriginal languages, n.o.s.","normalization":[1950,7],"parent":1956,"gender":true},
    {"question":2039,"id":2039,"characteristic":"      Non-Aboriginal languages","normalization":[1950,7],"parent":1955,"gender":true},
    {"question":2040,"id":2040,"characteristic":"        Afro-Asiatic languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2041,"id":2041,"characteristic":"          Berber languages","normalization":[1950,7],"parent":2040,"gender":true},
    {"question":2042,"id":2042,"characteristic":"            Kabyle","normalization":[1950,7],"parent":2041,"gender":true},
    {"question":2043,"id":2043,"characteristic":"            Berber languages, n.i.e.","normalization":[1950,7],"parent":2041,"gender":true},
    {"question":2044,"id":2044,"characteristic":"          Cushitic languages","normalization":[1950,7],"parent":2040,"gender":true},
    {"question":2045,"id":2045,"characteristic":"            Bilen","normalization":[1950,7],"parent":2044,"gender":true},
    {"question":2046,"id":2046,"characteristic":"            Oromo","normalization":[1950,7],"parent":2044,"gender":true},
    {"question":2047,"id":2047,"characteristic":"            Somali","normalization":[1950,7],"parent":2044,"gender":true},
    {"question":2048,"id":2048,"characteristic":"            Cushitic languages, n.i.e.","normalization":[1950,7],"parent":2044,"gender":true},
    {"question":2049,"id":2049,"characteristic":"          Semitic languages","normalization":[1950,7],"parent":2040,"gender":true},
    {"question":2050,"id":2050,"characteristic":"            Amharic","normalization":[1950,7],"parent":2049,"gender":true},
    {"question":2051,"id":2051,"characteristic":"            Arabic","normalization":[1950,7],"parent":2049,"gender":true},
    {"question":2052,"id":2052,"characteristic":"            Assyrian Neo-Aramaic","normalization":[1950,7],"parent":2049,"gender":true},
    {"question":2053,"id":2053,"characteristic":"            Chaldean Neo-Aramaic","normalization":[1950,7],"parent":2049,"gender":true},
    {"question":2054,"id":2054,"characteristic":"            Harari","normalization":[1950,7],"parent":2049,"gender":true},
    {"question":2055,"id":2055,"characteristic":"            Hebrew","normalization":[1950,7],"parent":2049,"gender":true},
    {"question":2056,"id":2056,"characteristic":"            Maltese","normalization":[1950,7],"parent":2049,"gender":true},
    {"question":2057,"id":2057,"characteristic":"            Tigrigna","normalization":[1950,7],"parent":2049,"gender":true},
    {"question":2058,"id":2058,"characteristic":"            Semitic languages, n.i.e.","normalization":[1950,7],"parent":2049,"gender":true},
    {"question":2059,"id":2059,"characteristic":"          Afro-Asiatic languages, n.i.e.","normalization":[1950,7],"parent":2040,"gender":true},
    {"question":2060,"id":2060,"characteristic":"        Austro-Asiatic languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2061,"id":2061,"characteristic":"          Khmer (Cambodian)","normalization":[1950,7],"parent":2060,"gender":true},
    {"question":2062,"id":2062,"characteristic":"          Vietnamese","normalization":[1950,7],"parent":2060,"gender":true},
    {"question":2063,"id":2063,"characteristic":"          Austro-Asiatic languages, n.i.e","normalization":[1950,7],"parent":2060,"gender":true},
    {"question":2064,"id":2064,"characteristic":"        Austronesian languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2065,"id":2065,"characteristic":"          Bikol","normalization":[1950,7],"parent":2064,"gender":true},
    {"question":2066,"id":2066,"characteristic":"          Cebuano","normalization":[1950,7],"parent":2064,"gender":true},
    {"question":2067,"id":2067,"characteristic":"          Fijian","normalization":[1950,7],"parent":2064,"gender":true},
    {"question":2068,"id":2068,"characteristic":"          Hiligaynon","normalization":[1950,7],"parent":2064,"gender":true},
    {"question":2069,"id":2069,"characteristic":"          Ilocano","normalization":[1950,7],"parent":2064,"gender":true},
    {"question":2070,"id":2070,"characteristic":"          Malagasy","normalization":[1950,7],"parent":2064,"gender":true},
    {"question":2071,"id":2071,"characteristic":"          Malay","normalization":[1950,7],"parent":2064,"gender":true},
    {"question":2072,"id":2072,"characteristic":"          Pampangan (Kapampangan, Pampango)","normalization":[1950,7],"parent":2064,"gender":true},
    {"question":2073,"id":2073,"characteristic":"          Pangasinan","normalization":[1950,7],"parent":2064,"gender":true},
    {"question":2074,"id":2074,"characteristic":"          Tagalog (Pilipino, Filipino)","normalization":[1950,7],"parent":2064,"gender":true},
    {"question":2075,"id":2075,"characteristic":"          Waray-Waray","normalization":[1950,7],"parent":2064,"gender":true},
    {"question":2076,"id":2076,"characteristic":"          Austronesian languages, n.i.e.","normalization":[1950,7],"parent":2064,"gender":true},
    {"question":2077,"id":2077,"characteristic":"        Creole languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2078,"id":2078,"characteristic":"          Haitian Creole","normalization":[1950,7],"parent":2077,"gender":true},
    {"question":2079,"id":2079,"characteristic":"          Creole, n.o.s.","normalization":[1950,7],"parent":2077,"gender":true},
    {"question":2080,"id":2080,"characteristic":"          Creole languages, n.i.e.","normalization":[1950,7],"parent":2077,"gender":true},
    {"question":2081,"id":2081,"characteristic":"        Dravidian languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2082,"id":2082,"characteristic":"          Kannada","normalization":[1950,7],"parent":2081,"gender":true},
    {"question":2083,"id":2083,"characteristic":"          Malayalam","normalization":[1950,7],"parent":2081,"gender":true},
    {"question":2084,"id":2084,"characteristic":"          Tamil","normalization":[1950,7],"parent":2081,"gender":true},
    {"question":2085,"id":2085,"characteristic":"          Telugu","normalization":[1950,7],"parent":2081,"gender":true},
    {"question":2086,"id":2086,"characteristic":"          Dravidian languages, n.i.e.","normalization":[1950,7],"parent":2081,"gender":true},
    {"question":2087,"id":2087,"characteristic":"        Hmong-Mien languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2088,"id":2088,"characteristic":"        Indo-European languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2089,"id":2089,"characteristic":"          Albanian","normalization":[1950,7],"parent":2088,"gender":true},
    {"question":2090,"id":2090,"characteristic":"          Armenian","normalization":[1950,7],"parent":2088,"gender":true},
    {"question":2091,"id":2091,"characteristic":"          Balto-Slavic languages","normalization":[1950,7],"parent":2088,"gender":true},
    {"question":2092,"id":2092,"characteristic":"            Baltic languages","normalization":[1950,7],"parent":2091,"gender":true},
    {"question":2093,"id":2093,"characteristic":"              Latvian","normalization":[1950,7],"parent":2092,"gender":true},
    {"question":2094,"id":2094,"characteristic":"              Lithuanian","normalization":[1950,7],"parent":2092,"gender":true},
    {"question":2095,"id":2095,"characteristic":"            Slavic languages","normalization":[1950,7],"parent":2091,"gender":true},
    {"question":2096,"id":2096,"characteristic":"              Belarusan","normalization":[1950,7],"parent":2095,"gender":true},
    {"question":2097,"id":2097,"characteristic":"              Bosnian","normalization":[1950,7],"parent":2095,"gender":true},
    {"question":2098,"id":2098,"characteristic":"              Bulgarian","normalization":[1950,7],"parent":2095,"gender":true},
    {"question":2099,"id":2099,"characteristic":"              Croatian","normalization":[1950,7],"parent":2095,"gender":true},
    {"question":2100,"id":2100,"characteristic":"              Czech","normalization":[1950,7],"parent":2095,"gender":true},
    {"question":2101,"id":2101,"characteristic":"              Macedonian","normalization":[1950,7],"parent":2095,"gender":true},
    {"question":2102,"id":2102,"characteristic":"              Polish","normalization":[1950,7],"parent":2095,"gender":true},
    {"question":2103,"id":2103,"characteristic":"              Russian","normalization":[1950,7],"parent":2095,"gender":true},
    {"question":2104,"id":2104,"characteristic":"              Serbian","normalization":[1950,7],"parent":2095,"gender":true},
    {"question":2105,"id":2105,"characteristic":"              Serbo-Croatian","normalization":[1950,7],"parent":2095,"gender":true},
    {"question":2106,"id":2106,"characteristic":"              Slovak","normalization":[1950,7],"parent":2095,"gender":true},
    {"question":2107,"id":2107,"characteristic":"              Slovene (Slovenian)","normalization":[1950,7],"parent":2095,"gender":true},
    {"question":2108,"id":2108,"characteristic":"              Ukrainian","normalization":[1950,7],"parent":2095,"gender":true},
    {"question":2109,"id":2109,"characteristic":"              Slavic languages, n.i.e.","normalization":[1950,7],"parent":2095,"gender":true},
    {"question":2110,"id":2110,"characteristic":"          Celtic languages","normalization":[1950,7],"parent":2088,"gender":true},
    {"question":2111,"id":2111,"characteristic":"            Scottish Gaelic","normalization":[1950,7],"parent":2110,"gender":true},
    {"question":2112,"id":2112,"characteristic":"            Welsh","normalization":[1950,7],"parent":2110,"gender":true},
    {"question":2113,"id":2113,"characteristic":"            Celtic languages, n.i.e.","normalization":[1950,7],"parent":2110,"gender":true},
    {"question":2114,"id":2114,"characteristic":"          Germanic languages","normalization":[1950,7],"parent":2088,"gender":true},
    {"question":2115,"id":2115,"characteristic":"            Afrikaans","normalization":[1950,7],"parent":2114,"gender":true},
    {"question":2116,"id":2116,"characteristic":"            Danish","normalization":[1950,7],"parent":2114,"gender":true},
    {"question":2117,"id":2117,"characteristic":"            Dutch","normalization":[1950,7],"parent":2114,"gender":true},
    {"question":2118,"id":2118,"characteristic":"            Frisian","normalization":[1950,7],"parent":2114,"gender":true},
    {"question":2119,"id":2119,"characteristic":"            German","normalization":[1950,7],"parent":2114,"gender":true},
    {"question":2120,"id":2120,"characteristic":"            Icelandic","normalization":[1950,7],"parent":2114,"gender":true},
    {"question":2121,"id":2121,"characteristic":"            Norwegian","normalization":[1950,7],"parent":2114,"gender":true},
    {"question":2122,"id":2122,"characteristic":"            Swedish","normalization":[1950,7],"parent":2114,"gender":true},
    {"question":2123,"id":2123,"characteristic":"            Vlaams (Flemish)","normalization":[1950,7],"parent":2114,"gender":true},
    {"question":2124,"id":2124,"characteristic":"            Yiddish","normalization":[1950,7],"parent":2114,"gender":true},
    {"question":2125,"id":2125,"characteristic":"            Germanic languages, n.i.e.","normalization":[1950,7],"parent":2114,"gender":true},
    {"question":2126,"id":2126,"characteristic":"          Greek","normalization":[1950,7],"parent":2088,"gender":true},
    {"question":2127,"id":2127,"characteristic":"          Indo-Iranian languages","normalization":[1950,7],"parent":2088,"gender":true},
    {"question":2128,"id":2128,"characteristic":"            Indo-Aryan languages","normalization":[1950,7],"parent":2127,"gender":true},
    {"question":2129,"id":2129,"characteristic":"              Bengali","normalization":[1950,7],"parent":2128,"gender":true},
    {"question":2130,"id":2130,"characteristic":"              Gujarati","normalization":[1950,7],"parent":2128,"gender":true},
    {"question":2131,"id":2131,"characteristic":"              Hindi","normalization":[1950,7],"parent":2128,"gender":true},
    {"question":2132,"id":2132,"characteristic":"              Kashmiri","normalization":[1950,7],"parent":2128,"gender":true},
    {"question":2133,"id":2133,"characteristic":"              Konkani","normalization":[1950,7],"parent":2128,"gender":true},
    {"question":2134,"id":2134,"characteristic":"              Marathi","normalization":[1950,7],"parent":2128,"gender":true},
    {"question":2135,"id":2135,"characteristic":"              Nepali","normalization":[1950,7],"parent":2128,"gender":true},
    {"question":2136,"id":2136,"characteristic":"              Oriya (Odia)","normalization":[1950,7],"parent":2128,"gender":true},
    {"question":2137,"id":2137,"characteristic":"              Punjabi (Panjabi)","normalization":[1950,7],"parent":2128,"gender":true},
    {"question":2138,"id":2138,"characteristic":"              Sindhi","normalization":[1950,7],"parent":2128,"gender":true},
    {"question":2139,"id":2139,"characteristic":"              Sinhala (Sinhalese)","normalization":[1950,7],"parent":2128,"gender":true},
    {"question":2140,"id":2140,"characteristic":"              Urdu","normalization":[1950,7],"parent":2128,"gender":true},
    {"question":2141,"id":2141,"characteristic":"            Iranian languages","normalization":[1950,7],"parent":2127,"gender":true},
    {"question":2142,"id":2142,"characteristic":"              Kurdish","normalization":[1950,7],"parent":2141,"gender":true},
    {"question":2143,"id":2143,"characteristic":"              Pashto","normalization":[1950,7],"parent":2141,"gender":true},
    {"question":2144,"id":2144,"characteristic":"              Persian (Farsi)","normalization":[1950,7],"parent":2141,"gender":true},
    {"question":2145,"id":2145,"characteristic":"            Indo-Iranian languages, n.i.e.","normalization":[1950,7],"parent":2127,"gender":true},
    {"question":2146,"id":2146,"characteristic":"          Italic (Romance) languages","normalization":[1950,7],"parent":2088,"gender":true},
    {"question":2147,"id":2147,"characteristic":"            Catalan","normalization":[1950,7],"parent":2146,"gender":true},
    {"question":2148,"id":2148,"characteristic":"            Italian","normalization":[1950,7],"parent":2146,"gender":true},
    {"question":2149,"id":2149,"characteristic":"            Portuguese","normalization":[1950,7],"parent":2146,"gender":true},
    {"question":2150,"id":2150,"characteristic":"            Romanian","normalization":[1950,7],"parent":2146,"gender":true},
    {"question":2151,"id":2151,"characteristic":"            Spanish","normalization":[1950,7],"parent":2146,"gender":true},
    {"question":2152,"id":2152,"characteristic":"            Italic (Romance) languages, n.i.e.","normalization":[1950,7],"parent":2146,"gender":true},
    {"question":2153,"id":2153,"characteristic":"        Japanese","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2154,"id":2154,"characteristic":"        Kartvelian languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2155,"id":2155,"characteristic":"          Georgian","normalization":[1950,7],"parent":2154,"gender":true},
    {"question":2156,"id":2156,"characteristic":"        Korean","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2157,"id":2157,"characteristic":"        Mongolic languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2158,"id":2158,"characteristic":"          Mongolian","normalization":[1950,7],"parent":2157,"gender":true},
    {"question":2159,"id":2159,"characteristic":"        Niger-Congo languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2160,"id":2160,"characteristic":"          Akan (Twi)","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2161,"id":2161,"characteristic":"          Bamanankan","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2162,"id":2162,"characteristic":"          Edo","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2163,"id":2163,"characteristic":"          Ewe","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2164,"id":2164,"characteristic":"          Fulah (Pular, Pulaar, Fulfulde)","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2165,"id":2165,"characteristic":"          Ga","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2166,"id":2166,"characteristic":"          Ganda","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2167,"id":2167,"characteristic":"          Igbo","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2168,"id":2168,"characteristic":"          Lingala","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2169,"id":2169,"characteristic":"          Rundi (Kirundi)","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2170,"id":2170,"characteristic":"          Kinyarwanda (Rwanda)","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2171,"id":2171,"characteristic":"          Shona","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2172,"id":2172,"characteristic":"          Swahili","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2173,"id":2173,"characteristic":"          Wolof","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2174,"id":2174,"characteristic":"          Yoruba","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2175,"id":2175,"characteristic":"          Niger-Congo languages, n.i.e.","normalization":[1950,7],"parent":2159,"gender":true},
    {"question":2176,"id":2176,"characteristic":"        Nilo-Saharan languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2177,"id":2177,"characteristic":"          Dinka","normalization":[1950,7],"parent":2176,"gender":true},
    {"question":2178,"id":2178,"characteristic":"          Nilo-Saharan languages, n.i.e.","normalization":[1950,7],"parent":2176,"gender":true},
    {"question":2179,"id":2179,"characteristic":"        Sign languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2180,"id":2180,"characteristic":"          American Sign Language","normalization":[1950,7],"parent":2179,"gender":true},
    {"question":2181,"id":2181,"characteristic":"          Quebec Sign Language","normalization":[1950,7],"parent":2179,"gender":true},
    {"question":2182,"id":2182,"characteristic":"          Sign languages, n.i.e","normalization":[1950,7],"parent":2179,"gender":true},
    {"question":2183,"id":2183,"characteristic":"        Sino-Tibetan languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2184,"id":2184,"characteristic":"          Chinese languages","normalization":[1950,7],"parent":2183,"gender":true},
    {"question":2185,"id":2185,"characteristic":"            Cantonese","normalization":[1950,7],"parent":2184,"gender":true},
    {"question":2186,"id":2186,"characteristic":"            Hakka","normalization":[1950,7],"parent":2184,"gender":true},
    {"question":2187,"id":2187,"characteristic":"            Mandarin","normalization":[1950,7],"parent":2184,"gender":true},
    {"question":2188,"id":2188,"characteristic":"            Min Dong","normalization":[1950,7],"parent":2184,"gender":true},
    {"question":2189,"id":2189,"characteristic":"            Min Nan (Chaochow, Teochow, Fukien, Taiwanese)","normalization":[1950,7],"parent":2184,"gender":true},
    {"question":2190,"id":2190,"characteristic":"            Wu (Shanghainese)","normalization":[1950,7],"parent":2184,"gender":true},
    {"question":2191,"id":2191,"characteristic":"            Chinese, n.o.s.","normalization":[1950,7],"parent":2184,"gender":true},
    {"question":2192,"id":2192,"characteristic":"            Chinese languages, n.i.e.","normalization":[1950,7],"parent":2184,"gender":true},
    {"question":2193,"id":2193,"characteristic":"          Tibeto-Burman languages","normalization":[1950,7],"parent":2183,"gender":true},
    {"question":2194,"id":2194,"characteristic":"            Burmese","normalization":[1950,7],"parent":2193,"gender":true},
    {"question":2195,"id":2195,"characteristic":"            Karenic languages","normalization":[1950,7],"parent":2193,"gender":true},
    {"question":2196,"id":2196,"characteristic":"            Tibetan","normalization":[1950,7],"parent":2193,"gender":true},
    {"question":2197,"id":2197,"characteristic":"            Tibeto-Burman languages, n.i.e.","normalization":[1950,7],"parent":2193,"gender":true},
    {"question":2198,"id":2198,"characteristic":"        Tai-Kadai languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2199,"id":2199,"characteristic":"          Lao","normalization":[1950,7],"parent":2198,"gender":true},
    {"question":2200,"id":2200,"characteristic":"          Thai","normalization":[1950,7],"parent":2198,"gender":true},
    {"question":2201,"id":2201,"characteristic":"          Tai-Kadai languages, n.i.e","normalization":[1950,7],"parent":2198,"gender":true},
    {"question":2202,"id":2202,"characteristic":"        Turkic languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2203,"id":2203,"characteristic":"          Azerbaijani","normalization":[1950,7],"parent":2202,"gender":true},
    {"question":2204,"id":2204,"characteristic":"          Turkish","normalization":[1950,7],"parent":2202,"gender":true},
    {"question":2205,"id":2205,"characteristic":"          Uyghur","normalization":[1950,7],"parent":2202,"gender":true},
    {"question":2206,"id":2206,"characteristic":"          Uzbek","normalization":[1950,7],"parent":2202,"gender":true},
    {"question":2207,"id":2207,"characteristic":"          Turkic languages, n.i.e.","normalization":[1950,7],"parent":2202,"gender":true},
    {"question":2208,"id":2208,"characteristic":"        Uralic languages","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2209,"id":2209,"characteristic":"          Estonian","normalization":[1950,7],"parent":2208,"gender":true},
    {"question":2210,"id":2210,"characteristic":"          Finnish","normalization":[1950,7],"parent":2208,"gender":true},
    {"question":2211,"id":2211,"characteristic":"          Hungarian","normalization":[1950,7],"parent":2208,"gender":true},
    {"question":2212,"id":2212,"characteristic":"          Uralic languages, n.i.e.","normalization":[1950,7],"parent":2208,"gender":true},
    {"question":2213,"id":2213,"characteristic":"        Other languages, n.i.e.","normalization":[1950,7],"parent":2039,"gender":true},
    {"question":2214,"id":2214,"characteristic":"  Multiple responses","normalization":[1950,7],"parent":1950,"gender":true},
    {"question":2215,"id":2215,"characteristic":"    English and French","normalization":[1950,7],"parent":2214,"gender":true},
    {"question":2216,"id":2216,"characteristic":"    English and non-official language","normalization":[1950,7],"parent":2214,"gender":true},
    {"question":2217,"id":2217,"characteristic":"    French and non-official language","normalization":[1950,7],"parent":2214,"gender":true},
    {"question":2218,"id":2218,"characteristic":"    English, French and non-official language","normalization":[1950,7],"parent":2214,"gender":true},
    {"question":2219,"id":2219,"characteristic":"Total - Other language(s) used regularly at work for the population in private households aged 15 years and over who worked since January 1, 2015 - 25% Sample Data (236)","normalization":[],"parent":0,"gender":true},
    {"question":2220,"id":2220,"characteristic":"  None","normalization":[2219,7],"parent":2219,"gender":true},
    {"question":2221,"id":2221,"characteristic":"  English","normalization":[2219,7],"parent":2219,"gender":true},
    {"question":2222,"id":2222,"characteristic":"  French","normalization":[2219,7],"parent":2219,"gender":true},
    {"question":2223,"id":2223,"characteristic":"  Non-official language","normalization":[2219,7],"parent":2219,"gender":true},
    {"question":2224,"id":2224,"characteristic":"    Aboriginal","normalization":[2219,7],"parent":2223,"gender":true},
    {"question":2225,"id":2225,"characteristic":"    Non-Aboriginal","normalization":[2219,7],"parent":2223,"gender":true},
    {"question":2226,"id":2226,"characteristic":"  English and French","normalization":[2219,7],"parent":2219,"gender":true},
    {"question":2227,"id":2227,"characteristic":"  English and non-official language","normalization":[2219,7],"parent":2219,"gender":true},
    {"question":2228,"id":2228,"characteristic":"  French and non-official language","normalization":[2219,7],"parent":2219,"gender":true},
    {"question":2229,"id":2229,"characteristic":"  English, French and non-official language","normalization":[2219,7],"parent":2219,"gender":true},
    {"question":2230,"id":2230,"characteristic":"Total - Mobility status 1 year ago - 25% sample data (237)","normalization":[],"parent":0,"gender":true},
    {"question":2231,"id":2231,"characteristic":"  Non-movers","normalization":[2230,7],"parent":2230,"gender":true},
    {"question":2232,"id":2232,"characteristic":"  Movers","normalization":[2230,7],"parent":2230,"gender":true},
    {"question":2233,"id":2233,"characteristic":"    Non-migrants","normalization":[2230,7],"parent":2232,"gender":true},
    {"question":2234,"id":2234,"characteristic":"    Migrants","normalization":[2230,7],"parent":2232,"gender":true},
    {"question":2235,"id":2235,"characteristic":"      Internal migrants","normalization":[2230,7],"parent":2234,"gender":true},
    {"question":2236,"id":2236,"characteristic":"        Intraprovincial migrants","normalization":[2230,7],"parent":2235,"gender":true},
    {"question":2237,"id":2237,"characteristic":"        Interprovincial migrants","normalization":[2230,7],"parent":2235,"gender":true},
    {"question":2238,"id":2238,"characteristic":"      External migrants","normalization":[2230,7],"parent":2234,"gender":true},
    {"question":2239,"id":2239,"characteristic":"Total - Mobility status 5 years ago - 25% sample data (238)","normalization":[],"parent":0,"gender":true},
    {"question":2240,"id":2240,"characteristic":"  Non-movers","normalization":[2239,7],"parent":2239,"gender":true},
    {"question":2241,"id":2241,"characteristic":"  Movers","normalization":[2239,7],"parent":2239,"gender":true},
    {"question":2242,"id":2242,"characteristic":"    Non-migrants","normalization":[2239,7],"parent":2241,"gender":true},
    {"question":2243,"id":2243,"characteristic":"    Migrants","normalization":[2239,7],"parent":2241,"gender":true},
    {"question":2244,"id":2244,"characteristic":"      Internal migrants","normalization":[2239,7],"parent":2243,"gender":true},
    {"question":2245,"id":2245,"characteristic":"        Intraprovincial migrants","normalization":[2239,7],"parent":2244,"gender":true},
    {"question":2246,"id":2246,"characteristic":"        Interprovincial migrants","normalization":[2239,7],"parent":2244,"gender":true},
    {"question":2247,"id":2247,"characteristic":"      External migrants","normalization":[2239,7],"parent":2243,"gender":true}];
export default censusQuestionnaire2016;