import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/cyborg/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'SsJs/react/SocioscapesDemo/styles.scss';
import React from 'react';
import ReactDOM from "react-dom/client";
import SidebarTabs from 'SsJs/react/SocioscapesDemo/SidebarTabs';
import LegendPanel from 'SsJs/react/SocioscapesDemo/LegendPanel';
import socioscapes from 'socioscapes';

window.socioscapes = socioscapes;
socioscapes.fn.schema.alias.rawGeom = (scapeObject, geom, callback) => callback(
    {
        meta: {
            crs: geom.crs,
            totalFeatures: geom.totalFeatures,
            type: 'geoJson',
        },
        geoJson: geom,
    });

socioscapes.fn.schema.alias.rawData = function rawData(
    scapeObject, data, callback) {
    let queryResult = {
        meta: {
            columns: [
                'dauid',
                'total',
            ],
            featureIdProperty: 'dauid',
            normalizationIdProperty: 'none',
            valueIdProperty: 'total',
            totalRows: data.features.length,
        },
        geoJson: data,
        byColumn: {
            total: [],
            dauid: [],
        },
        byId: {},
    };
    data.features.forEach(function(feature) {
        queryResult.byId[+feature.properties.dauid] = feature.properties;
        queryResult.byColumn.total.push(
            parseFloat(feature.properties.total) || 0);
        queryResult.byColumn.dauid.push(+feature.properties.dauid);
    });
    queryResult.geostats = new socioscapes.fn.geostats(
        queryResult.byColumn.total);
    console.log(queryResult);
    callback(queryResult);
};

socioscapes.fn.fetchGoogleGeocode = function(address, callback) {
    callback({lat: 49.2486050497781, lng: -123.107850477883});
};

ReactDOM.createRoot(document.getElementById("socioscapes-tabs-react")).render(
    <SidebarTabs/>
);

ReactDOM.createRoot(document.getElementById("socioscapes-legend-react")).render(
    <LegendPanel/>
);
