import React, {Component} from 'react';
import {Button, ButtonGroup, Card, Form} from 'react-bootstrap';
import colorBrewer from 'colorbrewer';

export default class FineTuning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breaks: 5,
            classification: 'Jenks',
            colourScale: 'YlOrRd',
            colourStyle: {
                padding: '1em',
                background: '-webkit-linear-gradient(right, #ffffb2, #fecc5c, #fd8d3c, #f03b20, #bd0026)',
                background: '-o-linear-gradient(right, #ffffb2, #fecc5c, #fd8d3c, #f03b20, #bd0026)',
                background: '-moz-linear-gradient(right, #ffffb2, #fecc5c, #fd8d3c, #f03b20, #bd0026)',
                background: 'linear-gradient(to right, #ffffb2, #fecc5c, #fd8d3c, #f03b20, #bd0026)',
                backgroundImage: '-o-linear-gradient(right, #ffffb2, #fecc5c, #fd8d3c, #f03b20, #bd0026)',
                backgroundImage: '-moz-linear-gradient(right, #ffffb2, #fecc5c, #fd8d3c, #f03b20, #bd0026)',
                backgroundImage: '-webkit-linear-gradient(right, #ffffb2, #fecc5c, #fd8d3c, #f03b20, #bd0026)',
                backgroundImage: '-ms-linear-gradient(right, #ffffb2, #fecc5c, #fd8d3c, #f03b20, #bd0026)',
                backgroundImage: 'linear-gradient(to right, #ffffb2, #fecc5c, #fd8d3c, #f03b20, #bd0026)',
            },
        };
        this.handleClassification = this.handleClassification.bind(this);
        this.handleBreaks = this.handleBreaks.bind(this);
        this.handleColourScale = this.handleColourScale.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.getColourScales = this.getColourScales.bind(this);
    }

    handleClassification(event) {
        this.setState({classification: event.target.selectedOptions[0].value});
    }

    handleBreaks(event) {
        let breaks = +event.target.dataset.breaks;
        this.setState({breaks: breaks});
    }

    handleColourScale(event) {

        let myColourScale = event.target.selectedOptions[0].value,
            myColourStyle = {
                padding: '1em',
                background: '-webkit-linear-gradient(right,' +
                    String(colorBrewer[myColourScale][5]) + ')',
                background: '-o-linear-gradient(right,' +
                    String(colorBrewer[myColourScale][5]) + ')',
                background: '-moz-linear-gradient(right,' +
                    String(colorBrewer[myColourScale][5]) + ')',
                background: 'linear-gradient(to right,' +
                    String(colorBrewer[myColourScale][5]) + ')',
                backgroundImage: '-o-linear-gradient(right,' +
                    String(colorBrewer[myColourScale][5]) + ')',
                backgroundImage: '-moz-linear-gradient(right,' +
                    String(colorBrewer[myColourScale][5]) + ')',
                backgroundImage: '-webkit-linear-gradient(right,' +
                    String(colorBrewer[myColourScale][5]) + ')',
                backgroundImage: '-ms-linear-gradient(right,' +
                    String(colorBrewer[myColourScale][5]) + ')',
                backgroundImage: 'linear-gradient(to right,' +
                    String(colorBrewer[myColourScale][5]) + ')',
            };
        this.setState({colourScale: myColourScale, colourStyle: myColourStyle});
    }

    handleClick() {
        let myLayer = this.props.layerRef.object ||
            this.props.stateRef.object.layer[0],
            myView = this.props.viewRef.object ||
                this.props.stateRef.object.view[0];
        myView.config.breaks = this.state.breaks;
        myView.config.classification = this.state.classification;
        myView.config.colourScale = this.state.colourScale;
        if (myLayer.data.geostats) {
            myView.config.classes = myLayer.data.geostats['getClass' +
            this.state.classification](this.state.breaks);
        }
        if (myView.gmap.mapSymbology) {
            myView.gmap.mapSymbology.init(function() {
                socioscapes.fn.newEvent('socioscapes.dataSelectionUpdated',
                    myView);
            });
        }
    }

    render() {
        const SelectNode = function() {
            return {
                render() {
                    return (
                        <option value={this.props.value}
                                title={this.props.value}>{this.props.value}</option>
                    );
                },
            };
        };
        SelectNode.prototype = React.Component.prototype;
        return (
            <Card className="col-12 vh-50 d-flex">
                <Card.Body>
                    <Form.Select onChange={this.handleClassification}
                                 className="my-2"
                                 ref="classification"
                                 label="Grouping Strategy (classification algorithm)"
                                 title="Classification"
                                 value={this.state.classification}
                                 type="select">
                        <option value="EqInterval">Equal Intervals</option>
                        <option value="StdDeviation">Standard Deviations
                        </option>
                        <option value="ArithmeticProgression">Arithmetic
                            Progression
                        </option>
                        <option value="GeometricProgression">Geometric
                            Progression
                        </option>
                        <option value="Jenks">Jenks</option>
                        <option value="Quantile">Quantiles</option>
                    </Form.Select>
                    <Form.Select onChange={this.handleColourScale}
                                 className="my-2"
                                 ref="colourScale"
                                 label="Group Styles (colorbrewer colour scale)"
                                 title="Colour Scale"
                                 value={this.state.colourScale}
                                 type="select">
                        {this.getColourScales().
                            map(function(colourScale, index) {
                                return <SelectNode key={index}
                                                   value={colourScale.label}
                                                   gradient={colourScale.gradient}/>;
                            })}
                    </Form.Select>
                    <Card
                        title={colorBrewer[this.state.colourScale].name + ' (' +
                        this.state.colourScale + ')'}>
                        <Card.Body style={this.state.colourStyle}/>
                    </Card>
                    <h6 className="ms-3 mt-3">Number of Groups (data domain &
                        breaks)</h6>
                    <ButtonGroup title="Breaks"
                                 className="col-xs-12">
                        <Button ref="three" title="Three"
                                className="col-xs-3"
                                data-breaks="3"
                                onClick={this.handleBreaks}
                                active={(this.state.breaks === 3)}
                                variant={(this.state.breaks === 3) ?
                                    'primary' :
                                    'link'}
                                disabled={this.state.breaks ===
                                3}>Three</Button>
                        <Button ref="five" title="Five"
                                className="col-xs-3"
                                data-breaks="5"
                                onClick={this.handleBreaks}
                                active={(this.state.breaks === 5)}
                                variant={(this.state.breaks === 5) ?
                                    'primary' :
                                    'link'}
                                disabled={this.state.breaks === 5}>Five</Button>
                        <Button ref="seven" title="Seven"
                                className="col-xs-3"
                                data-breaks="7"
                                onClick={this.handleBreaks}
                                active={(this.state.breaks === 7)}
                                variant={(this.state.breaks === 7) ?
                                    'primary' :
                                    'link'}
                                disabled={this.state.breaks ===
                                7}>Seven</Button>
                        <Button ref="nine" title="Nine"
                                className="col-xs-3"
                                data-breaks="9"
                                onClick={this.handleBreaks}
                                active={(this.state.breaks === 9)}
                                variant={(this.state.breaks === 9) ?
                                    'primary' :
                                    'link'}
                                disabled={this.state.breaks === 9}>Nine</Button>
                    </ButtonGroup>
                </Card.Body>
                <Card.Footer>
                    <Button onClick={this.handleClick}
                            title="Change It Up!"
                            className="btn-lg col-12 align-bottom mb-2"
                            variant="primary">
                        Change It Up!
                    </Button>
                </Card.Footer>
            </Card>
        );
    }

    getColourScales() {
        let myColourScales = [];
        for (let colourScale in colorBrewer) {
            if ('schemeGroups' === colourScale) {
                continue;
            }
            if (colorBrewer.hasOwnProperty(colourScale)) {
                let gradientArray = [
                    colorBrewer[colourScale][5][0],
                    colorBrewer[colourScale][5][1],
                    colorBrewer[colourScale][5][2],
                    colorBrewer[colourScale][5][3],
                    colorBrewer[colourScale][5][4]];
                myColourScales.push({
                        gradient: String(gradientArray),
                        label: colourScale,
                    },
                );
            }
        }
        return myColourScales;
    }
}
