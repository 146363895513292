const censusGeography2016 = [{"csdname":"Division No.  1, Subd. V","csduid":"1001101","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Portugal Cove South","csduid":"1001105","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Trepassey","csduid":"1001113","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. Shott's","csduid":"1001120","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. U","csduid":"1001124","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Cape Broyle","csduid":"1001126","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Renews-Cappahayden","csduid":"1001131","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Fermeuse","csduid":"1001136","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Port Kirwan","csduid":"1001140","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Aquaforte","csduid":"1001144","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Ferryland","csduid":"1001149","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. W","csduid":"1001155","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. Vincent's-St. Stephen's-Peter's River","csduid":"1001169","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Gaskiers-Point La Haye","csduid":"1001174","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. Mary's","csduid":"1001178","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Riverhead","csduid":"1001181","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Admirals Beach","csduid":"1001186","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. Joseph's","csduid":"1001192","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Mount Carmel-Mitchells Brook-St. Catherine's","csduid":"1001197","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. X","csduid":"1001203","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Colinet","csduid":"1001207","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. C","csduid":"1001214","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Branch","csduid":"1001219","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Point Lance","csduid":"1001225","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. Bride's","csduid":"1001228","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. B","csduid":"1001234","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Placentia","csduid":"1001240","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Fox Harbour","csduid":"1001254","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. A","csduid":"1001259","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Long Harbour-Mount Arlington Heights","csduid":"1001263","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Southern Harbour","csduid":"1001267","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Arnold's Cove","csduid":"1001270","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Come By Chance","csduid":"1001274","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Sunnyside","csduid":"1001277","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Chance Cove","csduid":"1001281","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Norman's Cove-Long Cove","csduid":"1001285","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Chapel Arm","csduid":"1001289","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. Y","csduid":"1001293","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Whitbourne","csduid":"1001298","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. E","csduid":"1001304","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Whiteway","csduid":"1001308","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Heart's Delight-Islington","csduid":"1001312","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Heart's Desire","csduid":"1001316","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. F","csduid":"1001321","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Heart's Content","csduid":"1001325","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"New Perlican","csduid":"1001328","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Winterton","csduid":"1001332","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Hant's Harbour","csduid":"1001335","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. G","csduid":"1001339","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Old Perlican","csduid":"1001343","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Bay de Verde","csduid":"1001347","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Small Point-Adam's Cove-Blackhead-Broad Cove","csduid":"1001352","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. H","csduid":"1001357","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Salmon Cove","csduid":"1001361","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Victoria","csduid":"1001365","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Carbonear","csduid":"1001370","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. I","csduid":"1001374","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Bryant's Cove","csduid":"1001375","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Harbour Grace","csduid":"1001379","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Upper Island Cove","csduid":"1001385","cmaname":"Bay Roberts","cmauid":"005","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. J","csduid":"1001391","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Bishop's Cove","csduid":"1001394","cmaname":"Bay Roberts","cmauid":"005","prname":"Newfoundland and Labrador"},
    {"csdname":"Spaniard's Bay","csduid":"1001396","cmaname":"Bay Roberts","cmauid":"005","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. K","csduid":"1001405","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Bay Roberts","csduid":"1001409","cmaname":"Bay Roberts","cmauid":"005","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. L","csduid":"1001415","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. M","csduid":"1001421","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"North River","csduid":"1001426","cmaname":"Bay Roberts","cmauid":"005","prname":"Newfoundland and Labrador"},
    {"csdname":"Clarke's Beach","csduid":"1001429","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"South River","csduid":"1001434","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Cupids","csduid":"1001437","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. N","csduid":"1001441","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Brigus","csduid":"1001446","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. O","csduid":"1001452","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Colliers","csduid":"1001456","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Conception Harbour","csduid":"1001461","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Avondale","csduid":"1001464","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Harbour Main-Chapel's Cove-Lakeview","csduid":"1001469","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Holyrood","csduid":"1001472","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Conception Bay South","csduid":"1001485","cmaname":"St. John's","cmauid":"001","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. R","csduid":"1001490","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Wabana","csduid":"1001494","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Portugal Cove-St. Philip's","csduid":"1001504","cmaname":"St. John's","cmauid":"001","prname":"Newfoundland and Labrador"},
    {"csdname":"Pouch Cove","csduid":"1001505","cmaname":"St. John's","cmauid":"001","prname":"Newfoundland and Labrador"},
    {"csdname":"Flatrock","csduid":"1001507","cmaname":"St. John's","cmauid":"001","prname":"Newfoundland and Labrador"},
    {"csdname":"Torbay","csduid":"1001509","cmaname":"St. John's","cmauid":"001","prname":"Newfoundland and Labrador"},
    {"csdname":"Logy Bay-Middle Cove-Outer Cove","csduid":"1001511","cmaname":"St. John's","cmauid":"001","prname":"Newfoundland and Labrador"},
    {"csdname":"Bauline","csduid":"1001512","cmaname":"St. John's","cmauid":"001","prname":"Newfoundland and Labrador"},
    {"csdname":"Paradise","csduid":"1001517","cmaname":"St. John's","cmauid":"001","prname":"Newfoundland and Labrador"},
    {"csdname":"St. John's","csduid":"1001519","cmaname":"St. John's","cmauid":"001","prname":"Newfoundland and Labrador"},
    {"csdname":"Mount Pearl","csduid":"1001542","cmaname":"St. John's","cmauid":"001","prname":"Newfoundland and Labrador"},
    {"csdname":"Petty Harbour-Maddox Cove","csduid":"1001551","cmaname":"St. John's","cmauid":"001","prname":"Newfoundland and Labrador"},
    {"csdname":"Bay Bulls","csduid":"1001557","cmaname":"St. John's","cmauid":"001","prname":"Newfoundland and Labrador"},
    {"csdname":"Witless Bay","csduid":"1001559","cmaname":"St. John's","cmauid":"001","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  1, Subd. D","csduid":"1001565","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  2, Subd. E","csduid":"1002001","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Lewin's Cove","csduid":"1002002","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Burin","csduid":"1002005","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  2, Subd. F","csduid":"1002006","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. Lawrence","csduid":"1002008","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  2, Subd. G","csduid":"1002009","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Lawn","csduid":"1002011","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Lamaline","csduid":"1002012","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Lord's Cove","csduid":"1002013","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Point May","csduid":"1002014","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Point au Gaul","csduid":"1002015","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  2, Subd. H","csduid":"1002016","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Frenchman's Cove","csduid":"1002017","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Fortune","csduid":"1002018","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Grand Bank","csduid":"1002019","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Garnish","csduid":"1002021","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  2, Subd. D","csduid":"1002022","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Winterland","csduid":"1002023","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Marystown","csduid":"1002024","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Fox Cove-Mortier","csduid":"1002025","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  2, Subd. I","csduid":"1002026","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Bay L'Argent","csduid":"1002027","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  2, Subd. J","csduid":"1002029","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Grand le Pierre","csduid":"1002030","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  2, Subd. C","csduid":"1002031","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Rushoon","csduid":"1002033","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Parkers Cove","csduid":"1002034","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Terrenceville","csduid":"1002036","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Red Harbour","csduid":"1002037","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"English Harbour East","csduid":"1002039","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Baine Harbour","csduid":"1002040","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. Bernard's-Jacques Fontaine","csduid":"1002041","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  2, Subd. L","csduid":"1002044","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  2, Subd. K","csduid":"1002048","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Little Bay East","csduid":"1002055","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  3, Subd. A","csduid":"1003001","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Rencontre East","csduid":"1003002","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. Jacques-Coomb's Cove","csduid":"1003003","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Belleoram","csduid":"1003004","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Pool's Cove","csduid":"1003005","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  3, Subd. B","csduid":"1003006","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Harbour Breton","csduid":"1003008","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  3, Subd. C","csduid":"1003009","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Seal Cove (Fortune Bay)","csduid":"1003010","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Hermitage-Sandyville","csduid":"1003011","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Gaultois","csduid":"1003012","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  3, Subd. D","csduid":"1003014","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Milltown-Head of Bay d'Espoir","csduid":"1003018","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. Alban's","csduid":"1003019","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Morrisville","csduid":"1003020","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  3, Subd. E","csduid":"1003021","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  3, Subd. F","csduid":"1003024","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Ramea","csduid":"1003026","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Burgeo","csduid":"1003028","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  3, Subd. H","csduid":"1003031","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Isle aux Morts","csduid":"1003032","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Burnt Islands","csduid":"1003033","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Channel-Port aux Basques","csduid":"1003034","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  3, Subd. I","csduid":"1003038","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  3, Subd. J","csduid":"1003042","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Rose Blanche-Harbour le Cou","csduid":"1003045","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Samiajij Miawpukek","csduid":"1003801","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  4, Subd. A","csduid":"1004001","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  4, Subd. B","csduid":"1004006","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  4, Subd. C","csduid":"1004011","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. George's","csduid":"1004013","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  4, Subd. D","csduid":"1004016","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Gallants","csduid":"1004017","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Stephenville Crossing","csduid":"1004018","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Stephenville","csduid":"1004019","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Kippens","csduid":"1004020","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Port au Port East","csduid":"1004021","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  4, Subd. E","csduid":"1004022","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Cape St. George","csduid":"1004033","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Lourdes","csduid":"1004037","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Port au Port West-Aguathuna-Felix Cove","csduid":"1004042","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  5, Subd. A","csduid":"1005001","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  5, Subd. G","csduid":"1005003","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Deer Lake","csduid":"1005004","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Jackson's Arm","csduid":"1005005","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Howley","csduid":"1005006","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  5, Subd. E","csduid":"1005007","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Hampden","csduid":"1005008","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Reidville","csduid":"1005009","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  5, Subd. F","csduid":"1005010","cmaname":"Corner Brook","cmauid":"015","prname":"Newfoundland and Labrador"},
    {"csdname":"Steady Brook","csduid":"1005011","cmaname":"Corner Brook","cmauid":"015","prname":"Newfoundland and Labrador"},
    {"csdname":"Pasadena","csduid":"1005012","cmaname":"Corner Brook","cmauid":"015","prname":"Newfoundland and Labrador"},
    {"csdname":"Cormack","csduid":"1005014","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Gillams","csduid":"1005015","cmaname":"Corner Brook","cmauid":"015","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  5, Subd. C","csduid":"1005016","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Massey Drive","csduid":"1005017","cmaname":"Corner Brook","cmauid":"015","prname":"Newfoundland and Labrador"},
    {"csdname":"Corner Brook","csduid":"1005018","cmaname":"Corner Brook","cmauid":"015","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  5, Subd. D","csduid":"1005019","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Humber Arm South","csduid":"1005020","cmaname":"Corner Brook","cmauid":"015","prname":"Newfoundland and Labrador"},
    {"csdname":"McIvers","csduid":"1005022","cmaname":"Corner Brook","cmauid":"015","prname":"Newfoundland and Labrador"},
    {"csdname":"Cox's Cove","csduid":"1005023","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Lark Harbour","csduid":"1005024","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Meadows","csduid":"1005025","cmaname":"Corner Brook","cmauid":"015","prname":"Newfoundland and Labrador"},
    {"csdname":"Hughes Brook","csduid":"1005028","cmaname":"Corner Brook","cmauid":"015","prname":"Newfoundland and Labrador"},
    {"csdname":"Irishtown-Summerside","csduid":"1005030","cmaname":"Corner Brook","cmauid":"015","prname":"Newfoundland and Labrador"},
    {"csdname":"Mount Moriah","csduid":"1005033","cmaname":"Corner Brook","cmauid":"015","prname":"Newfoundland and Labrador"},
    {"csdname":"York Harbour","csduid":"1005035","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  6, Subd. D","csduid":"1006001","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Norris Arm","csduid":"1006003","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  6, Subd. E","csduid":"1006008","cmaname":"Gander","cmauid":"011","prname":"Newfoundland and Labrador"},
    {"csdname":"Gander","csduid":"1006009","cmaname":"Gander","cmauid":"011","prname":"Newfoundland and Labrador"},
    {"csdname":"Appleton","csduid":"1006011","cmaname":"Gander","cmauid":"011","prname":"Newfoundland and Labrador"},
    {"csdname":"Glenwood","csduid":"1006012","cmaname":"Gander","cmauid":"011","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  6, Subd. C","csduid":"1006014","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Northern Arm","csduid":"1006015","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Grand Falls-Windsor","csduid":"1006017","cmaname":"Grand Falls-Windsor","cmauid":"010","prname":"Newfoundland and Labrador"},
    {"csdname":"Bishop's Falls","csduid":"1006019","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Peterview","csduid":"1006021","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Botwood","csduid":"1006022","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Badger","csduid":"1006026","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Millertown","csduid":"1006028","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  6, Subd. A","csduid":"1006029","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Buchans","csduid":"1006031","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  7, Subd. M","csduid":"1007001","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  7, Subd. L","csduid":"1007006","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  7, Subd. K","csduid":"1007009","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Clarenville","csduid":"1007013","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  7, Subd. J","csduid":"1007014","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Trinity (Trinity Bay)","csduid":"1007015","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Port Rexton","csduid":"1007016","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  7, Subd. I","csduid":"1007018","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Elliston","csduid":"1007020","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Trinity Bay North","csduid":"1007022","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Bonavista","csduid":"1007023","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  7, Subd. G","csduid":"1007024","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Keels","csduid":"1007025","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Duntara","csduid":"1007026","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"King's Cove","csduid":"1007027","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  7, Subd. F","csduid":"1007028","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  7, Subd. E","csduid":"1007031","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Musgravetown","csduid":"1007032","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Port Blandford","csduid":"1007036","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Traytown","csduid":"1007037","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  7, Subd. D","csduid":"1007038","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Sandy Cove","csduid":"1007039","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Terra Nova","csduid":"1007040","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Happy Adventure","csduid":"1007041","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Eastport","csduid":"1007042","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Sandringham","csduid":"1007043","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Glovertown","csduid":"1007044","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  7, Subd. N","csduid":"1007045","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Salvage","csduid":"1007046","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Gambo","csduid":"1007047","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. Brendan's","csduid":"1007048","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Hare Bay","csduid":"1007049","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Centreville-Wareham-Trinity","csduid":"1007050","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  7, Subd. B","csduid":"1007051","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Greenspond","csduid":"1007054","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  7, Subd. A","csduid":"1007056","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Dover","csduid":"1007057","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"New-Wes-Valley","csduid":"1007060","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Indian Bay","csduid":"1007067","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  8, Subd. M","csduid":"1008001","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Lumsden","csduid":"1008004","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Musgrave Harbour","csduid":"1008006","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  8, Subd. L","csduid":"1008008","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Carmanville","csduid":"1008011","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Change Islands","csduid":"1008024","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  8, Subd. I","csduid":"1008026","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Crow Head","csduid":"1008029","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  8, Subd. H","csduid":"1008031","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Cottlesville","csduid":"1008032","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Summerford","csduid":"1008033","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Twillingate","csduid":"1008035","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  8, Subd. G","csduid":"1008036","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Campbellton","csduid":"1008037","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Birchy Bay","csduid":"1008038","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Comfort Cove-Newstead","csduid":"1008039","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Baytona","csduid":"1008040","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  8, Subd. F","csduid":"1008042","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Embree","csduid":"1008043","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Lewisporte","csduid":"1008044","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Little Burnt Bay","csduid":"1008045","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  8, Subd. E","csduid":"1008046","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Point of Bay","csduid":"1008047","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Leading Tickles","csduid":"1008048","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Point Leamington","csduid":"1008049","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  8, Subd. D","csduid":"1008052","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Pilley's Island","csduid":"1008053","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Triton","csduid":"1008054","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Brighton","csduid":"1008055","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Lushes Bight-Beaumont-Beaumont North","csduid":"1008056","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  8, Subd. C","csduid":"1008058","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"South Brook","csduid":"1008059","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Robert's Arm","csduid":"1008060","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Springdale","csduid":"1008061","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Port Anson","csduid":"1008062","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Little Bay","csduid":"1008063","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Little Bay Islands","csduid":"1008064","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  8, Subd. P","csduid":"1008065","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Beachside","csduid":"1008066","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  8, Subd. O","csduid":"1008067","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"King's Point","csduid":"1008069","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Nippers Harbour","csduid":"1008070","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Burlington","csduid":"1008071","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Middle Arm","csduid":"1008072","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Tilt Cove","csduid":"1008073","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  8, Subd. A","csduid":"1008074","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Fleur de Lys","csduid":"1008075","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"LaScie","csduid":"1008076","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Brent's Cove","csduid":"1008077","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Pacquet","csduid":"1008078","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Baie Verte","csduid":"1008079","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Seal Cove (White Bay)","csduid":"1008091","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Coachman's Cove","csduid":"1008092","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Westport","csduid":"1008093","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Ming's Bight","csduid":"1008095","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Woodstock","csduid":"1008096","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Miles Cove","csduid":"1008098","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Fogo Island","csduid":"1008099","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  9, Subd. F","csduid":"1009001","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Englee","csduid":"1009004","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Roddickton-Bide Arm","csduid":"1009007","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Conche","csduid":"1009008","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  9, Subd. A","csduid":"1009009","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Trout River","csduid":"1009010","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Woody Point, Bonne Bay","csduid":"1009011","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Norris Point","csduid":"1009012","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Rocky Harbour","csduid":"1009013","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Daniel's Harbour","csduid":"1009015","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Cow Head","csduid":"1009016","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Parson's Pond","csduid":"1009017","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Hawke's Bay","csduid":"1009018","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Port Saunders","csduid":"1009019","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. Pauls","csduid":"1009020","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  9, Subd. C","csduid":"1009021","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Port au Choix","csduid":"1009022","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Anchor Point","csduid":"1009023","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Flower's Cove","csduid":"1009024","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Bird Cove","csduid":"1009026","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Main Brook","csduid":"1009028","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. Anthony","csduid":"1009029","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Glenburnie-Birchy Head-Shoal Brook","csduid":"1009030","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  9, Subd. D","csduid":"1009031","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. Lunaire-Griquet","csduid":"1009032","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Cook's Harbour","csduid":"1009034","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Raleigh","csduid":"1009035","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Sally's Cove","csduid":"1009037","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Goose Cove East","csduid":"1009039","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  9, Subd. H","csduid":"1009041","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Bellburns","csduid":"1009042","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No.  9, Subd. G","csduid":"1009047","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"River of Ponds","csduid":"1009048","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No. 10, Subd. A","csduid":"1010001","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"L'Anse au Loup","csduid":"1010002","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Red Bay","csduid":"1010003","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"L'Anse-au-Clair","csduid":"1010004","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Forteau","csduid":"1010005","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"West St. Modeste","csduid":"1010006","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Pinware","csduid":"1010007","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No. 10, Subd. B","csduid":"1010008","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Port Hope Simpson","csduid":"1010009","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"St. Lewis","csduid":"1010010","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Mary's Harbour","csduid":"1010011","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Cartwright, Labrador","csduid":"1010012","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Charlottetown (Labrador)","csduid":"1010013","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No. 10, Subd. C","csduid":"1010020","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"North West River","csduid":"1010022","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Happy Valley-Goose Bay","csduid":"1010025","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No. 10, Subd. D","csduid":"1010029","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Labrador City","csduid":"1010032","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Wabush","csduid":"1010034","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No. 10, Subd. E","csduid":"1010042","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Natuashish  2","csduid":"1010801","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Sheshatshiu 3","csduid":"1010802","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No. 11, Subd. C","csduid":"1011005","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Rigolet","csduid":"1011010","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Postville","csduid":"1011015","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Makkovik","csduid":"1011020","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Hopedale","csduid":"1011030","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Nain","csduid":"1011035","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Division No. 11, Subd. E","csduid":"1011040","cmaname":"","cmauid":"","prname":"Newfoundland and Labrador"},
    {"csdname":"Lot 64","csduid":"1101001","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Murray Harbour","csduid":"1101002","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Murray River","csduid":"1101004","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 63","csduid":"1101006","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 61","csduid":"1101008","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 59","csduid":"1101009","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 66","csduid":"1101011","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 51","csduid":"1101014","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 52","csduid":"1101016","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Montague","csduid":"1101018","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 53","csduid":"1101019","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Cardigan","csduid":"1101021","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Kings, Royalty","csduid":"1101022","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Georgetown","csduid":"1101024","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 54","csduid":"1101026","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 55","csduid":"1101028","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 56","csduid":"1101029","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 43","csduid":"1101031","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 44","csduid":"1101032","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 45","csduid":"1101034","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Souris","csduid":"1101036","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 46","csduid":"1101038","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 47","csduid":"1101039","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 42","csduid":"1101041","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 41","csduid":"1101042","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"St. Peters Bay","csduid":"1101044","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 40","csduid":"1101046","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Morell","csduid":"1101048","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 39","csduid":"1101049","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Morell 2","csduid":"1101050","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 38","csduid":"1101051","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 62","csduid":"1102001","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 60","csduid":"1102004","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 58","csduid":"1102006","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 57","csduid":"1102008","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 50","csduid":"1102009","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 49","csduid":"1102012","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Lot 48","csduid":"1102014","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Lot 31","csduid":"1102026","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Lot 65","csduid":"1102028","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Lot 30","csduid":"1102029","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Rocky Point 3","csduid":"1102030","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Lot 29","csduid":"1102031","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Victoria","csduid":"1102032","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Meadowbank","csduid":"1102033","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Crapaud","csduid":"1102034","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 67","csduid":"1102036","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Clyde River","csduid":"1102037","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Lot 20","csduid":"1102038","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 21","csduid":"1102039","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Miltonvale Park","csduid":"1102040","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Lot 22","csduid":"1102041","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 23","csduid":"1102042","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Hunter River","csduid":"1102043","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 24","csduid":"1102044","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Resort Mun. Stan.B.-Hope R.-Bayv.-Cavend.-N.Rust.","csduid":"1102045","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"North Rustico","csduid":"1102046","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Brackley","csduid":"1102048","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Lot 33","csduid":"1102049","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Union Road","csduid":"1102050","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Breadalbane","csduid":"1102051","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 34","csduid":"1102052","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Lot 35","csduid":"1102054","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Lot 36","csduid":"1102056","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Scotchfort 4","csduid":"1102057","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Lot 37","csduid":"1102058","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Mount Stewart","csduid":"1102059","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Winsloe South","csduid":"1102065","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Warren Grove","csduid":"1102070","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Charlottetown","csduid":"1102075","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Stratford","csduid":"1102080","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Cornwall","csduid":"1102085","cmaname":"Charlottetown","cmauid":"105","prname":"Prince Edward Island"},
    {"csdname":"Lot 28","csduid":"1103001","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Borden-Carleton","csduid":"1103005","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 27","csduid":"1103006","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Kinkora","csduid":"1103008","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 26","csduid":"1103009","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 25","csduid":"1103011","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 19","csduid":"1103012","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Kensington","csduid":"1103014","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Bedeque and Area","csduid":"1103015","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 18","csduid":"1103016","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Sherbrooke","csduid":"1103018","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 17","csduid":"1103019","cmaname":"Summerside","cmauid":"110","prname":"Prince Edward Island"},
    {"csdname":"Linkletter","csduid":"1103023","cmaname":"Summerside","cmauid":"110","prname":"Prince Edward Island"},
    {"csdname":"Miscouche","csduid":"1103024","cmaname":"Summerside","cmauid":"110","prname":"Prince Edward Island"},
    {"csdname":"Summerside","csduid":"1103025","cmaname":"Summerside","cmauid":"110","prname":"Prince Edward Island"},
    {"csdname":"Lot 15","csduid":"1103026","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Abrams Village","csduid":"1103027","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 16","csduid":"1103028","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Wellington","csduid":"1103029","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 14","csduid":"1103031","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 13","csduid":"1103032","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Tyne Valley","csduid":"1103033","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 12","csduid":"1103034","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lennox Island 1","csduid":"1103035","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 11","csduid":"1103036","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot 10","csduid":"1103038","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot  6","csduid":"1103041","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"O'Leary","csduid":"1103042","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot  9","csduid":"1103044","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot  8","csduid":"1103046","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot  7","csduid":"1103048","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot  5","csduid":"1103049","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot  4","csduid":"1103051","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Alberton","csduid":"1103052","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot  3","csduid":"1103054","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot  2","csduid":"1103056","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"St. Louis","csduid":"1103057","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Lot  1","csduid":"1103058","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Tignish","csduid":"1103059","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Miminegash","csduid":"1103062","cmaname":"","cmauid":"","prname":"Prince Edward Island"},
    {"csdname":"Barrington","csduid":"1201001","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Clark's Harbour","csduid":"1201004","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Shelburne","csduid":"1201006","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Shelburne","csduid":"1201008","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Lockeport","csduid":"1201009","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Argyle","csduid":"1202001","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Yarmouth","csduid":"1202004","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Yarmouth","csduid":"1202006","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Yarmouth 33","csduid":"1202040","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Clare","csduid":"1203001","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Digby","csduid":"1203004","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Digby","csduid":"1203006","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Bear River (Part) 6","csduid":"1203009","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Ponhook Lake 10","csduid":"1204009","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Queens","csduid":"1204010","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Wildcat 12","csduid":"1204015","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Annapolis, Subd. D","csduid":"1205001","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Bear River (Part) 6","csduid":"1205002","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Annapolis, Subd. A","csduid":"1205004","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Bear River 6B","csduid":"1205006","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Annapolis Royal","csduid":"1205008","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Annapolis, Subd. B","csduid":"1205009","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Annapolis, Subd. C","csduid":"1205014","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Middleton","csduid":"1205016","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Lunenburg","csduid":"1206001","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Bridgewater","csduid":"1206004","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Lunenburg","csduid":"1206006","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Mahone Bay","csduid":"1206008","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Chester","csduid":"1206009","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Gold River 21","csduid":"1206011","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Pennal 19","csduid":"1206014","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"New Ross 20","csduid":"1206016","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Kings, Subd. A","csduid":"1207001","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Cambridge 32","csduid":"1207002","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Berwick","csduid":"1207004","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Kings, Subd. C","csduid":"1207011","cmaname":"Kentville","cmauid":"210","prname":"Nova Scotia"},
    {"csdname":"Kentville","csduid":"1207012","cmaname":"Kentville","cmauid":"210","prname":"Nova Scotia"},
    {"csdname":"Kings, Subd. B","csduid":"1207016","cmaname":"Kentville","cmauid":"210","prname":"Nova Scotia"},
    {"csdname":"Kings, Subd. D","csduid":"1207021","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Wolfville","csduid":"1207024","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Glooscap 35","csduid":"1207027","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"West Hants","csduid":"1208001","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Windsor","csduid":"1208002","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"East Hants","csduid":"1208008","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Indian Brook 14","csduid":"1208014","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Cole Harbour 30","csduid":"1209019","cmaname":"Halifax","cmauid":"205","prname":"Nova Scotia"},
    {"csdname":"Shubenacadie 13","csduid":"1209029","cmaname":"Halifax","cmauid":"205","prname":"Nova Scotia"},
    {"csdname":"Halifax","csduid":"1209034","cmaname":"Halifax","cmauid":"205","prname":"Nova Scotia"},
    {"csdname":"Beaver Lake 17","csduid":"1209037","cmaname":"Halifax","cmauid":"205","prname":"Nova Scotia"},
    {"csdname":"Sheet Harbour 36","csduid":"1209038","cmaname":"Halifax","cmauid":"205","prname":"Nova Scotia"},
    {"csdname":"Wallace Hills 14A","csduid":"1209800","cmaname":"Halifax","cmauid":"205","prname":"Nova Scotia"},
    {"csdname":"Colchester, Subd. C","csduid":"1210001","cmaname":"Truro","cmauid":"215","prname":"Nova Scotia"},
    {"csdname":"Stewiacke","csduid":"1210002","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Millbrook 27","csduid":"1210003","cmaname":"Truro","cmauid":"215","prname":"Nova Scotia"},
    {"csdname":"Truro","csduid":"1210006","cmaname":"Truro","cmauid":"215","prname":"Nova Scotia"},
    {"csdname":"Colchester, Subd. B","csduid":"1210008","cmaname":"Truro","cmauid":"215","prname":"Nova Scotia"},
    {"csdname":"Colchester, Subd. A","csduid":"1210012","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Cumberland, Subd. A","csduid":"1211001","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Parrsboro","csduid":"1211002","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Cumberland, Subd. B","csduid":"1211006","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Cumberland, Subd. C","csduid":"1211009","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Amherst","csduid":"1211011","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Oxford","csduid":"1211012","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Cumberland, Subd. D","csduid":"1211014","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Pictou, Subd. A","csduid":"1212001","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Pictou","csduid":"1212004","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Pictou, Subd. B","csduid":"1212006","cmaname":"New Glasgow","cmauid":"220","prname":"Nova Scotia"},
    {"csdname":"Westville","csduid":"1212008","cmaname":"New Glasgow","cmauid":"220","prname":"Nova Scotia"},
    {"csdname":"Stellarton","csduid":"1212009","cmaname":"New Glasgow","cmauid":"220","prname":"Nova Scotia"},
    {"csdname":"Pictou, Subd. C","csduid":"1212011","cmaname":"New Glasgow","cmauid":"220","prname":"Nova Scotia"},
    {"csdname":"New Glasgow","csduid":"1212014","cmaname":"New Glasgow","cmauid":"220","prname":"Nova Scotia"},
    {"csdname":"Trenton","csduid":"1212016","cmaname":"New Glasgow","cmauid":"220","prname":"Nova Scotia"},
    {"csdname":"Fisher's Grant 24","csduid":"1212019","cmaname":"New Glasgow","cmauid":"220","prname":"Nova Scotia"},
    {"csdname":"Merigomish Harbour 31","csduid":"1212023","cmaname":"New Glasgow","cmauid":"220","prname":"Nova Scotia"},
    {"csdname":"St. Mary's","csduid":"1213001","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Guysborough","csduid":"1213004","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Mulgrave","csduid":"1213008","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Antigonish, Subd. A","csduid":"1214001","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Antigonish","csduid":"1214002","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Antigonish, Subd. B","csduid":"1214006","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Pomquet And Afton 23","csduid":"1214010","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Inverness, Subd. C","csduid":"1215001","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Port Hawkesbury","csduid":"1215002","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Inverness, Subd. B","csduid":"1215006","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Whycocomagh 2","csduid":"1215008","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Inverness, Subd. A","csduid":"1215011","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Richmond, Subd. C","csduid":"1216001","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Richmond, Subd. A","csduid":"1216006","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Richmond, Subd. B","csduid":"1216011","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Chapel Island 5","csduid":"1216014","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Membertou 28B","csduid":"1217008","cmaname":"Cape Breton","cmauid":"225","prname":"Nova Scotia"},
    {"csdname":"Eskasoni 3","csduid":"1217020","cmaname":"Cape Breton","cmauid":"225","prname":"Nova Scotia"},
    {"csdname":"Cape Breton","csduid":"1217030","cmaname":"Cape Breton","cmauid":"225","prname":"Nova Scotia"},
    {"csdname":"Victoria, Subd. B","csduid":"1218001","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Wagmatcook 1","csduid":"1218003","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Victoria, Subd. A","csduid":"1218006","cmaname":"","cmauid":"","prname":"Nova Scotia"},
    {"csdname":"Saint Martins","csduid":"1301001","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"St. Martins","csduid":"1301002","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Simonds","csduid":"1301004","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Saint John","csduid":"1301006","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Musquash","csduid":"1301016","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Grand Manan","csduid":"1302001","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Campobello Island","csduid":"1302004","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"West Isles","csduid":"1302006","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Lepreau","csduid":"1302008","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Pennfield","csduid":"1302011","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Blacks Harbour","csduid":"1302012","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Clarendon","csduid":"1302014","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint George","csduid":"1302016","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"St. George","csduid":"1302018","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint Patrick","csduid":"1302021","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint Andrews","csduid":"1302024","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint Andrews","csduid":"1302026","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint Croix","csduid":"1302028","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Dufferin","csduid":"1302031","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint Stephen","csduid":"1302034","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"St. Stephen","csduid":"1302037","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint James","csduid":"1302039","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint David","csduid":"1302042","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Dumbarton","csduid":"1302044","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Grand Manan","csduid":"1302052","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Blissville","csduid":"1303001","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Gladstone","csduid":"1303004","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Tracy","csduid":"1303005","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Fredericton Junction","csduid":"1303006","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Lincoln","csduid":"1303008","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Burton","csduid":"1303011","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Oromocto","csduid":"1303012","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Oromocto 26","csduid":"1303013","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Sheffield","csduid":"1303014","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Maugerville","csduid":"1303016","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Northfield","csduid":"1303018","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Petersville","csduid":"1304001","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Gagetown","csduid":"1304004","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Gagetown","csduid":"1304005","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Hampstead","csduid":"1304006","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Wickham","csduid":"1304008","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Cambridge","csduid":"1304011","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Cambridge-Narrows","csduid":"1304013","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Johnston","csduid":"1304014","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Brunswick","csduid":"1304016","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Waterborough","csduid":"1304018","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Canning","csduid":"1304021","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Minto","csduid":"1304022","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Chipman","csduid":"1304024","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Chipman","csduid":"1304025","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Hammond","csduid":"1305001","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Upham","csduid":"1305004","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Hampton","csduid":"1305006","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Hampton","csduid":"1305007","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Rothesay","csduid":"1305008","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Westfield","csduid":"1305011","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Kingston","csduid":"1305014","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Grand Bay-Westfield","csduid":"1305015","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Norton","csduid":"1305016","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Norton","csduid":"1305018","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Sussex","csduid":"1305021","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Sussex","csduid":"1305022","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Sussex Corner","csduid":"1305023","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Waterford","csduid":"1305024","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Cardwell","csduid":"1305026","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Havelock","csduid":"1305028","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Studholm","csduid":"1305031","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Springfield","csduid":"1305034","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Kars","csduid":"1305036","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Greenwich","csduid":"1305038","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Rothesay","csduid":"1305045","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Quispamsis","csduid":"1305057","cmaname":"Saint John","cmauid":"310","prname":"New Brunswick"},
    {"csdname":"Hopewell","csduid":"1306001","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Riverside-Albert","csduid":"1306003","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Harvey","csduid":"1306004","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Alma","csduid":"1306006","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Alma","csduid":"1306007","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Elgin","csduid":"1306008","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Hillsborough","csduid":"1306011","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Coverdale","csduid":"1306014","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Riverview","csduid":"1306020","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Hillsborough","csduid":"1306025","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Westmorland","csduid":"1307001","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Port Elgin","csduid":"1307002","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Botsford","csduid":"1307004","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Beaubassin East / Beaubassin-est","csduid":"1307005","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Cap-Pelé","csduid":"1307007","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Sackville","csduid":"1307008","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Sackville","csduid":"1307009","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Dorchester","csduid":"1307011","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Dorchester","csduid":"1307012","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Memramcook","csduid":"1307013","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Fort Folly 1","csduid":"1307014","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Shediac","csduid":"1307016","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Moncton","csduid":"1307019","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Moncton","csduid":"1307022","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Salisbury","csduid":"1307024","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Salisbury","csduid":"1307028","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Petitcodiac","csduid":"1307029","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Dieppe","csduid":"1307045","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Shediac","csduid":"1307052","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Dundas","csduid":"1308001","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Cocagne","csduid":"1308002","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Buctouche 16","csduid":"1308003","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Wellington","csduid":"1308004","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Bouctouche","csduid":"1308005","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint Mary","csduid":"1308006","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-Antoine","csduid":"1308007","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-Paul","csduid":"1308008","cmaname":"Moncton","cmauid":"305","prname":"New Brunswick"},
    {"csdname":"Harcourt","csduid":"1308010","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Huskisson","csduid":"1308012","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Weldford","csduid":"1308014","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Richibucto 15","csduid":"1308015","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Richibucto","csduid":"1308016","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Rexton","csduid":"1308017","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Richibucto","csduid":"1308018","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-Charles","csduid":"1308019","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Indian Island 28","csduid":"1308020","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-Louis","csduid":"1308021","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-Louis de Kent","csduid":"1308022","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Acadieville","csduid":"1308024","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Carleton","csduid":"1308026","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Hardwicke","csduid":"1309001","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Glenelg","csduid":"1309004","cmaname":"Miramichi","cmauid":"329","prname":"New Brunswick"},
    {"csdname":"Chatham","csduid":"1309006","cmaname":"Miramichi","cmauid":"329","prname":"New Brunswick"},
    {"csdname":"Derby","csduid":"1309011","cmaname":"Miramichi","cmauid":"329","prname":"New Brunswick"},
    {"csdname":"Nelson","csduid":"1309014","cmaname":"Miramichi","cmauid":"329","prname":"New Brunswick"},
    {"csdname":"Rogersville","csduid":"1309016","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Rogersville","csduid":"1309017","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Blackville","csduid":"1309018","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Blackville","csduid":"1309019","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Blissfield","csduid":"1309021","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Doaktown","csduid":"1309022","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Red Bank 4","csduid":"1309023","cmaname":"Miramichi","cmauid":"329","prname":"New Brunswick"},
    {"csdname":"Southesk","csduid":"1309026","cmaname":"Miramichi","cmauid":"329","prname":"New Brunswick"},
    {"csdname":"Upper Miramichi","csduid":"1309027","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Northesk","csduid":"1309028","cmaname":"Miramichi","cmauid":"329","prname":"New Brunswick"},
    {"csdname":"Newcastle","csduid":"1309031","cmaname":"Miramichi","cmauid":"329","prname":"New Brunswick"},
    {"csdname":"Big Hole Tract 8 (South Half)","csduid":"1309034","cmaname":"Miramichi","cmauid":"329","prname":"New Brunswick"},
    {"csdname":"Eel Ground 2","csduid":"1309035","cmaname":"Miramichi","cmauid":"329","prname":"New Brunswick"},
    {"csdname":"Alnwick","csduid":"1309036","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Neguac","csduid":"1309038","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Esgenoôpetitj 14","csduid":"1309044","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Tabusintac 9","csduid":"1309047","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Miramichi","csduid":"1309050","cmaname":"Miramichi","cmauid":"329","prname":"New Brunswick"},
    {"csdname":"New Maryland","csduid":"1310001","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"New Maryland","csduid":"1310002","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Manners Sutton","csduid":"1310004","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Harvey","csduid":"1310005","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"McAdam","csduid":"1310006","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"McAdam","csduid":"1310007","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"North Lake","csduid":"1310008","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Canterbury","csduid":"1310011","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Canterbury","csduid":"1310012","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Meductic","csduid":"1310013","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Dumfries","csduid":"1310014","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Prince William","csduid":"1310016","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Hanwell","csduid":"1310017","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Kingsclear","csduid":"1310018","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Kingsclear 6","csduid":"1310019","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Queensbury","csduid":"1310021","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Southampton","csduid":"1310024","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Millville","csduid":"1310025","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Bright","csduid":"1310026","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Douglas","csduid":"1310028","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Saint Marys","csduid":"1310031","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Fredericton","csduid":"1310032","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Devon 30","csduid":"1310034","cmaname":"Fredericton","cmauid":"320","prname":"New Brunswick"},
    {"csdname":"Stanley","csduid":"1310036","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Stanley","csduid":"1310037","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Nackawic","csduid":"1310054","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Richmond","csduid":"1311001","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Woodstock","csduid":"1311004","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Woodstock 23","csduid":"1311005","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Woodstock","csduid":"1311006","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Northampton","csduid":"1311008","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Brighton","csduid":"1311011","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Hartland","csduid":"1311012","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Wakefield","csduid":"1311014","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Wilmot","csduid":"1311018","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Centreville","csduid":"1311019","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Simonds","csduid":"1311021","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Peel","csduid":"1311024","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Aberdeen","csduid":"1311026","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Florenceville-Bristol","csduid":"1311027","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Kent","csduid":"1311028","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Bath","csduid":"1311030","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Wicklow","csduid":"1311031","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Andover","csduid":"1312001","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Aroostook","csduid":"1312002","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Perth","csduid":"1312004","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Perth-Andover","csduid":"1312006","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Tobique 20","csduid":"1312007","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Gordon","csduid":"1312008","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Plaster Rock","csduid":"1312009","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Lorne","csduid":"1312011","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Denmark","csduid":"1312014","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Grand Falls","csduid":"1312016","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Grand Falls / Grand-Sault","csduid":"1312019","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Drummond","csduid":"1312021","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Drummond","csduid":"1312023","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-André","csduid":"1313001","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-André","csduid":"1313002","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-Léonard","csduid":"1313004","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-Léonard","csduid":"1313006","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Notre-Dame-de-Lourdes","csduid":"1313008","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Sainte-Anne","csduid":"1313011","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Sainte-Anne-de-Madawaska","csduid":"1313012","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Rivière-Verte","csduid":"1313014","cmaname":"Edmundston","cmauid":"335","prname":"New Brunswick"},
    {"csdname":"Rivière-Verte","csduid":"1313015","cmaname":"Edmundston","cmauid":"335","prname":"New Brunswick"},
    {"csdname":"Saint-Basile","csduid":"1313018","cmaname":"Edmundston","cmauid":"335","prname":"New Brunswick"},
    {"csdname":"St. Basile 10","csduid":"1313020","cmaname":"Edmundston","cmauid":"335","prname":"New Brunswick"},
    {"csdname":"Saint-Joseph","csduid":"1313022","cmaname":"Edmundston","cmauid":"335","prname":"New Brunswick"},
    {"csdname":"Saint-Jacques","csduid":"1313024","cmaname":"Edmundston","cmauid":"335","prname":"New Brunswick"},
    {"csdname":"Edmundston","csduid":"1313027","cmaname":"Edmundston","cmauid":"335","prname":"New Brunswick"},
    {"csdname":"Madawaska","csduid":"1313028","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-Hilaire","csduid":"1313032","cmaname":"Edmundston","cmauid":"335","prname":"New Brunswick"},
    {"csdname":"St. Hilaire","csduid":"1313033","cmaname":"Edmundston","cmauid":"335","prname":"New Brunswick"},
    {"csdname":"Baker Brook","csduid":"1313034","cmaname":"Edmundston","cmauid":"335","prname":"New Brunswick"},
    {"csdname":"Baker-Brook","csduid":"1313035","cmaname":"Edmundston","cmauid":"335","prname":"New Brunswick"},
    {"csdname":"Lac-Baker","csduid":"1313036","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Lac Baker","csduid":"1313037","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Clair","csduid":"1313038","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Clair","csduid":"1313039","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-François","csduid":"1313041","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-François de Madawaska","csduid":"1313042","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Durham","csduid":"1314001","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Colborne","csduid":"1314003","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Charlo","csduid":"1314004","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Balmoral","csduid":"1314005","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Balmoral","csduid":"1314006","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Dalhousie","csduid":"1314008","cmaname":"Campbellton (New Brunswick part / partie du Nouveau-Brunswick)","cmauid":"330","prname":"New Brunswick"},
    {"csdname":"Eel River 3","csduid":"1314010","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Eel River Crossing","csduid":"1314011","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Addington","csduid":"1314012","cmaname":"Campbellton (New Brunswick part / partie du Nouveau-Brunswick)","cmauid":"330","prname":"New Brunswick"},
    {"csdname":"Atholville","csduid":"1314013","cmaname":"Campbellton (New Brunswick part / partie du Nouveau-Brunswick)","cmauid":"330","prname":"New Brunswick"},
    {"csdname":"Campbellton","csduid":"1314014","cmaname":"Campbellton (New Brunswick part / partie du Nouveau-Brunswick)","cmauid":"330","prname":"New Brunswick"},
    {"csdname":"Tide Head","csduid":"1314015","cmaname":"Campbellton (New Brunswick part / partie du Nouveau-Brunswick)","cmauid":"330","prname":"New Brunswick"},
    {"csdname":"Eldon","csduid":"1314016","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Dalhousie","csduid":"1314017","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Kedgwick","csduid":"1314020","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-Quentin","csduid":"1314021","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-Quentin","csduid":"1314022","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Belledune","csduid":"1314025","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Indian Ranch","csduid":"1314026","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Tracadie","csduid":"1315002","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Allardville","csduid":"1315006","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Bathurst","csduid":"1315008","cmaname":"Bathurst","cmauid":"328","prname":"New Brunswick"},
    {"csdname":"Pabineau 11","csduid":"1315010","cmaname":"Bathurst","cmauid":"328","prname":"New Brunswick"},
    {"csdname":"Bathurst","csduid":"1315011","cmaname":"Bathurst","cmauid":"328","prname":"New Brunswick"},
    {"csdname":"Beresford","csduid":"1315012","cmaname":"Bathurst","cmauid":"328","prname":"New Brunswick"},
    {"csdname":"Pointe-Verte","csduid":"1315013","cmaname":"Bathurst","cmauid":"328","prname":"New Brunswick"},
    {"csdname":"Petit-Rocher","csduid":"1315014","cmaname":"Bathurst","cmauid":"328","prname":"New Brunswick"},
    {"csdname":"Beresford","csduid":"1315015","cmaname":"Bathurst","cmauid":"328","prname":"New Brunswick"},
    {"csdname":"New Bandon","csduid":"1315016","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-Léolin","csduid":"1315017","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Paquetville","csduid":"1315019","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Paquetville","csduid":"1315020","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-Isidore","csduid":"1315021","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Saint-Isidore","csduid":"1315022","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Inkerman","csduid":"1315024","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Caraquet","csduid":"1315026","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Bas-Caraquet","csduid":"1315027","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Caraquet","csduid":"1315028","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Shippagan","csduid":"1315029","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Le Goulet","csduid":"1315030","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Shippagan","csduid":"1315031","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Lamèque","csduid":"1315032","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Sainte-Marie-Saint-Raphaël","csduid":"1315033","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Bertrand","csduid":"1315036","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Nigadoo","csduid":"1315037","cmaname":"Bathurst","cmauid":"328","prname":"New Brunswick"},
    {"csdname":"Grande-Anse","csduid":"1315038","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Maisonnette","csduid":"1315040","cmaname":"","cmauid":"","prname":"New Brunswick"},
    {"csdname":"Les Îles-de-la-Madeleine","csduid":"2401023","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Grosse-Île","csduid":"2401042","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Percé","csduid":"2402005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Thérèse-de-Gaspé","csduid":"2402010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Grande-Rivière","csduid":"2402015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Chandler","csduid":"2402028","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Port-Daniel--Gascons","csduid":"2402047","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mont-Alexandre","csduid":"2402902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Gaspé","csduid":"2403005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Cloridorme","csduid":"2403010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Petite-Vallée","csduid":"2403015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Grande-Vallée","csduid":"2403020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Murdochville","csduid":"2403025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Saint-Jean","csduid":"2403902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Collines-du-Basque","csduid":"2403904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Madeleine-de-la-Rivière-Madeleine","csduid":"2404005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Maxime-du-Mont-Louis","csduid":"2404010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mont-Saint-Pierre","csduid":"2404015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-à-Claude","csduid":"2404020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Marsoui","csduid":"2404025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Martre","csduid":"2404030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Anne-des-Monts","csduid":"2404037","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Cap-Chat","csduid":"2404047","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mont-Albert","csduid":"2404902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Coulée-des-Adolphe","csduid":"2404904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Shigawake","csduid":"2405010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Godefroi","csduid":"2405015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Hope Town","csduid":"2405020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Hope","csduid":"2405025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Paspébiac","csduid":"2405032","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"New Carlisle","csduid":"2405040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Bonaventure","csduid":"2405045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Elzéar","csduid":"2405050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Siméon","csduid":"2405055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Caplan","csduid":"2405060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Alphonse","csduid":"2405065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"New Richmond","csduid":"2405070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Cascapédia--Saint-Jules","csduid":"2405077","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Bonaventure","csduid":"2405902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Maria","csduid":"2406005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Carleton-sur-Mer","csduid":"2406013","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Nouvelle","csduid":"2406020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Escuminac","csduid":"2406025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Pointe-à-la-Croix","csduid":"2406030","cmaname":"Campbellton (partie du Québec / Quebec part)","cmauid":"330","prname":"Quebec"},
    {"csdname":"Ristigouche-Partie-Sud-Est","csduid":"2406035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-André-de-Restigouche","csduid":"2406040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Matapédia","csduid":"2406045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Alexis-de-Matapédia","csduid":"2406050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-François-d'Assise","csduid":"2406055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"L'Ascension-de-Patapédia","csduid":"2406060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Gesgapegiag","csduid":"2406802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Listuguj","csduid":"2406804","cmaname":"Campbellton (partie du Québec / Quebec part)","cmauid":"330","prname":"Quebec"},
    {"csdname":"Rivière-Nouvelle","csduid":"2406902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ruisseau-Ferguson","csduid":"2406904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Marguerite-Marie","csduid":"2407005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Florence","csduid":"2407010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Causapscal","csduid":"2407018","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Albertville","csduid":"2407025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Léon-le-Grand","csduid":"2407030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Zénon-du-Lac-Humqui","csduid":"2407035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Irène","csduid":"2407040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Amqui","csduid":"2407047","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-au-Saumon","csduid":"2407057","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Alexandre-des-Lacs","csduid":"2407065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Tharcisius","csduid":"2407070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Vianney","csduid":"2407075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Val-Brillant","csduid":"2407080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sayabec","csduid":"2407085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Cléophas","csduid":"2407090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Moïse","csduid":"2407095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Noël","csduid":"2407100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Damase","csduid":"2407105","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Routhierville","csduid":"2407902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Vaseuse","csduid":"2407904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Patapédia-Est","csduid":"2407906","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Casault","csduid":"2407908","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ruisseau-des-Mineurs","csduid":"2407910","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Alfred","csduid":"2407912","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Matapédia","csduid":"2407914","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Les Méchins","csduid":"2408005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Jean-de-Cherbourg","csduid":"2408010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Grosses-Roches","csduid":"2408015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Félicité","csduid":"2408023","cmaname":"Matane","cmauid":"403","prname":"Quebec"},
    {"csdname":"Saint-Adelme","csduid":"2408030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-René-de-Matane","csduid":"2408035","cmaname":"Matane","cmauid":"403","prname":"Quebec"},
    {"csdname":"Sainte-Paule","csduid":"2408040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Matane","csduid":"2408053","cmaname":"Matane","cmauid":"403","prname":"Quebec"},
    {"csdname":"Saint-Léandre","csduid":"2408065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Ulric","csduid":"2408073","cmaname":"Matane","cmauid":"403","prname":"Quebec"},
    {"csdname":"Baie-des-Sables","csduid":"2408080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Bonjour","csduid":"2408902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Rédemption","csduid":"2409005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Charles-Garnier","csduid":"2409010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Les Hauteurs","csduid":"2409015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Jeanne-d'Arc","csduid":"2409020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Gabriel-de-Rimouski","csduid":"2409025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Donat","csduid":"2409030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Angèle-de-Mérici","csduid":"2409035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Padoue","csduid":"2409040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Métis-sur-Mer","csduid":"2409048","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Octave-de-Métis","csduid":"2409055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Grand-Métis","csduid":"2409060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Price","csduid":"2409065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Joseph-de-Lepage","csduid":"2409070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mont-Joli","csduid":"2409077","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Flavie","csduid":"2409085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Luce","csduid":"2409092","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-des-Eaux-Mortes","csduid":"2409902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-à-la-Croix","csduid":"2409904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Esprit-Saint","csduid":"2410005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Trinité-des-Monts","csduid":"2410010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Narcisse-de-Rimouski","csduid":"2410015","cmaname":"Rimouski","cmauid":"404","prname":"Quebec"},
    {"csdname":"Saint-Marcellin","csduid":"2410025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Anaclet-de-Lessard","csduid":"2410030","cmaname":"Rimouski","cmauid":"404","prname":"Quebec"},
    {"csdname":"Rimouski","csduid":"2410043","cmaname":"Rimouski","cmauid":"404","prname":"Quebec"},
    {"csdname":"Saint-Valérien","csduid":"2410060","cmaname":"Rimouski","cmauid":"404","prname":"Quebec"},
    {"csdname":"Saint-Fabien","csduid":"2410070","cmaname":"Rimouski","cmauid":"404","prname":"Quebec"},
    {"csdname":"Saint-Eugène-de-Ladrière","csduid":"2410075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Huron","csduid":"2410902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Clément","csduid":"2411005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Jean-de-Dieu","csduid":"2411010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Rita","csduid":"2411015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Guy","csduid":"2411020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Médard","csduid":"2411025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Françoise","csduid":"2411030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Éloi","csduid":"2411035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Trois-Pistoles","csduid":"2411040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-des-Neiges","csduid":"2411045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Mathieu-de-Rioux","csduid":"2411050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Simon","csduid":"2411055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Boisbouscache","csduid":"2411902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Cyprien","csduid":"2412005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Hubert-de-Rivière-du-Loup","csduid":"2412010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Antonin","csduid":"2412015","cmaname":"Rivière-du-Loup","cmauid":"405","prname":"Quebec"},
    {"csdname":"Saint-Modeste","csduid":"2412020","cmaname":"Rivière-du-Loup","cmauid":"405","prname":"Quebec"},
    {"csdname":"Saint-François-Xavier-de-Viger","csduid":"2412025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Épiphane","csduid":"2412030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Paul-de-la-Croix","csduid":"2412035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"L'Isle-Verte","csduid":"2412043","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-des-Sept-Douleurs","csduid":"2412045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Cacouna","csduid":"2412057","cmaname":"Rivière-du-Loup","cmauid":"405","prname":"Quebec"},
    {"csdname":"Saint-Arsène","csduid":"2412065","cmaname":"Rivière-du-Loup","cmauid":"405","prname":"Quebec"},
    {"csdname":"Rivière-du-Loup","csduid":"2412072","cmaname":"Rivière-du-Loup","cmauid":"405","prname":"Quebec"},
    {"csdname":"Notre-Dame-du-Portage","csduid":"2412080","cmaname":"Rivière-du-Loup","cmauid":"405","prname":"Quebec"},
    {"csdname":"Dégelis","csduid":"2413005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Jean-de-la-Lande","csduid":"2413010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Packington","csduid":"2413015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Marc-du-Lac-Long","csduid":"2413020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Bleue","csduid":"2413025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Eusèbe","csduid":"2413030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Juste-du-Lac","csduid":"2413040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Auclair","csduid":"2413045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lejeune","csduid":"2413050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Biencourt","csduid":"2413055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-des-Aigles","csduid":"2413060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Michel-du-Squatec","csduid":"2413065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Témiscouata-sur-le-Lac","csduid":"2413073","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Pierre-de-Lamy","csduid":"2413075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Louis-du-Ha! Ha!","csduid":"2413080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Elzéar-de-Témiscouata","csduid":"2413085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Honoré-de-Témiscouata","csduid":"2413090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Pohénégamook","csduid":"2413095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Athanase","csduid":"2413100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mont-Carmel","csduid":"2414005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Bruno-de-Kamouraska","csduid":"2414010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Pascal","csduid":"2414018","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Hélène-de-Kamouraska","csduid":"2414025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Joseph-de-Kamouraska","csduid":"2414030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Alexandre-de-Kamouraska","csduid":"2414035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-André","csduid":"2414040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Germain","csduid":"2414045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kamouraska","csduid":"2414050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Denis-De La Bouteillerie","csduid":"2414055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Philippe-de-Néri","csduid":"2414060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Ouelle","csduid":"2414065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Pacôme","csduid":"2414070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Gabriel-Lalemant","csduid":"2414075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Onésime-d'Ixworth","csduid":"2414080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Pocatière","csduid":"2414085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Anne-de-la-Pocatière","csduid":"2414090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Picard","csduid":"2414902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Petit-Lac-Sainte-Anne","csduid":"2414904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Irénée","csduid":"2415005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Malbaie","csduid":"2415013","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-des-Monts","csduid":"2415025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Aimé-des-Lacs","csduid":"2415030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Clermont","csduid":"2415035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Siméon","csduid":"2415058","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Baie-Sainte-Catherine","csduid":"2415065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mont-Élie","csduid":"2415902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sagard","csduid":"2415904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Petite-Rivière-Saint-François","csduid":"2416005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Baie-Saint-Paul","csduid":"2416013","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"L'Isle-aux-Coudres","csduid":"2416023","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Les Éboulements","csduid":"2416048","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Hilarion","csduid":"2416050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Urbain","csduid":"2416055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Pikauba","csduid":"2416902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Omer","csduid":"2417005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Pamphile","csduid":"2417010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Adalbert","csduid":"2417015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Marcel","csduid":"2417020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Félicité","csduid":"2417025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Perpétue","csduid":"2417030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Tourville","csduid":"2417035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Damase-de-L'Islet","csduid":"2417040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Cyrille-de-Lessard","csduid":"2417045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Aubert","csduid":"2417055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Louise","csduid":"2417060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Roch-des-Aulnaies","csduid":"2417065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Jean-Port-Joli","csduid":"2417070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"L'Islet","csduid":"2417078","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Just-de-Bretenières","csduid":"2418005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Frontière","csduid":"2418010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Fabien-de-Panet","csduid":"2418015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Lucie-de-Beauregard","csduid":"2418020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Apolline-de-Patton","csduid":"2418025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Paul-de-Montminy","csduid":"2418030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Euphémie-sur-Rivière-du-Sud","csduid":"2418035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-du-Rosaire","csduid":"2418040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Cap-Saint-Ignace","csduid":"2418045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Montmagny","csduid":"2418050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Pierre-de-la-Rivière-du-Sud","csduid":"2418055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-François-de-la-Rivière-du-Sud","csduid":"2418060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Berthier-sur-Mer","csduid":"2418065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Antoine-de-l'Isle-aux-Grues","csduid":"2418070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Philémon","csduid":"2419005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-Auxiliatrice-de-Buckland","csduid":"2419010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Nazaire-de-Dorchester","csduid":"2419015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Léon-de-Standon","csduid":"2419020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Malachie","csduid":"2419025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Damien-de-Buckland","csduid":"2419030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Armagh","csduid":"2419037","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Nérée-de-Bellechasse","csduid":"2419045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Lazare-de-Bellechasse","csduid":"2419050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Claire","csduid":"2419055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Anselme","csduid":"2419062","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Henri","csduid":"2419068","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Honfleur","csduid":"2419070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Gervais","csduid":"2419075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Raphaël","csduid":"2419082","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Durantaye","csduid":"2419090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Charles-de-Bellechasse","csduid":"2419097","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Beaumont","csduid":"2419105","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Saint-Michel-de-Bellechasse","csduid":"2419110","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Vallier","csduid":"2419117","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-François-de-l'Île-d'Orléans","csduid":"2420005","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Sainte-Famille","csduid":"2420010","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Saint-Jean-de-l'Île-d'Orléans","csduid":"2420015","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Saint-Laurent-de-l'Île-d'Orléans","csduid":"2420020","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Saint-Pierre-de-l'Île-d'Orléans","csduid":"2420025","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Sainte-Pétronille","csduid":"2420030","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Saint-Tite-des-Caps","csduid":"2421005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Ferréol-les-Neiges","csduid":"2421010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Louis-de-Gonzague-du-Cap-Tourmente","csduid":"2421015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Joachim","csduid":"2421020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Beaupré","csduid":"2421025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Anne-de-Beaupré","csduid":"2421030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Château-Richer","csduid":"2421035","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"L'Ange-Gardien","csduid":"2421040","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Boischatel","csduid":"2421045","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Sault-au-Cochon","csduid":"2421902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Jacques-Cartier","csduid":"2421904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Catherine-de-la-Jacques-Cartier","csduid":"2422005","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Fossambault-sur-le-Lac","csduid":"2422010","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Lac-Saint-Joseph","csduid":"2422015","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Shannon","csduid":"2422020","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Saint-Gabriel-de-Valcartier","csduid":"2422025","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Lac-Delage","csduid":"2422030","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Stoneham-et-Tewkesbury","csduid":"2422035","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Lac-Beauport","csduid":"2422040","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Sainte-Brigitte-de-Laval","csduid":"2422045","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Lac-Croche","csduid":"2422902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-des-Anges","csduid":"2423015","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Québec","csduid":"2423027","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"L'Ancienne-Lorette","csduid":"2423057","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Saint-Augustin-de-Desmaures","csduid":"2423072","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Wendake","csduid":"2423802","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Lévis","csduid":"2425213","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Frampton","csduid":"2426005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saints-Anges","csduid":"2426010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Vallée-Jonction","csduid":"2426015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Elzéar","csduid":"2426022","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Marie","csduid":"2426030","cmaname":"Sainte-Marie","cmauid":"423","prname":"Quebec"},
    {"csdname":"Sainte-Marguerite","csduid":"2426035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Hénédine","csduid":"2426040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Scott","csduid":"2426048","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Bernard","csduid":"2426055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Isidore","csduid":"2426063","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Lambert-de-Lauzon","csduid":"2426070","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Saint-Victor","csduid":"2427008","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Alfred","csduid":"2427015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Beauceville","csduid":"2427028","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Odilon-de-Cranbourne","csduid":"2427035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Joseph-de-Beauce","csduid":"2427043","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Joseph-des-Érables","csduid":"2427050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Jules","csduid":"2427055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Tring-Jonction","csduid":"2427060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Frédéric","csduid":"2427065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Séverin","csduid":"2427070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Zacharie","csduid":"2428005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Aurélie","csduid":"2428015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Prosper","csduid":"2428020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Benjamin","csduid":"2428025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Rose-de-Watford","csduid":"2428030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Louis-de-Gonzague","csduid":"2428035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Cyprien","csduid":"2428040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Justine","csduid":"2428045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Etchemin","csduid":"2428053","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Luc-de-Bellechasse","csduid":"2428060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Sabine","csduid":"2428065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Camille-de-Lellis","csduid":"2428070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Magloire","csduid":"2428075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Théophile","csduid":"2429005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Gédéon-de-Beauce","csduid":"2429013","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Hilaire-de-Dorset","csduid":"2429020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Évariste-de-Forsyth","csduid":"2429025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Guadeloupe","csduid":"2429030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Honoré-de-Shenley","csduid":"2429038","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Martin","csduid":"2429045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-René","csduid":"2429050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Côme--Linière","csduid":"2429057","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Philibert","csduid":"2429065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Georges","csduid":"2429073","cmaname":"Saint-Georges","cmauid":"428","prname":"Quebec"},
    {"csdname":"Lac-Poulin","csduid":"2429095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Benoît-Labre","csduid":"2429100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Éphrem-de-Beauce","csduid":"2429112","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-des-Pins","csduid":"2429120","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Simon-les-Mines","csduid":"2429125","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Augustin-de-Woburn","csduid":"2430005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-des-Bois","csduid":"2430010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Val-Racine","csduid":"2430015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Piopolis","csduid":"2430020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Frontenac","csduid":"2430025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Mégantic","csduid":"2430030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Marston","csduid":"2430035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Milan","csduid":"2430040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Nantes","csduid":"2430045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Cécile-de-Whitton","csduid":"2430050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Audet","csduid":"2430055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Robert-Bellarmin","csduid":"2430070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Ludger","csduid":"2430072","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Drolet","csduid":"2430080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Sébastien","csduid":"2430085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Courcelles","csduid":"2430090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lambton","csduid":"2430095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Romain","csduid":"2430100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Stornoway","csduid":"2430105","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Stratford","csduid":"2430110","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Beaulac-Garthby","csduid":"2431008","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Disraeli","csduid":"2431015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Disraeli","csduid":"2431020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Jacques-le-Majeur-de-Wolfestown","csduid":"2431025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Fortunat","csduid":"2431030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Julien","csduid":"2431035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Irlande","csduid":"2431040","cmaname":"Thetford Mines","cmauid":"430","prname":"Quebec"},
    {"csdname":"Saint-Joseph-de-Coleraine","csduid":"2431045","cmaname":"Thetford Mines","cmauid":"430","prname":"Quebec"},
    {"csdname":"Sainte-Praxède","csduid":"2431050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Adstock","csduid":"2431056","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Clotilde-de-Beauce","csduid":"2431060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Thetford Mines","csduid":"2431084","cmaname":"Thetford Mines","cmauid":"430","prname":"Quebec"},
    {"csdname":"Saint-Adrien-d'Irlande","csduid":"2431095","cmaname":"Thetford Mines","cmauid":"430","prname":"Quebec"},
    {"csdname":"Saint-Jean-de-Brébeuf","csduid":"2431100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kinnear's Mills","csduid":"2431105","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"East Broughton","csduid":"2431122","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sacré-Coeur-de-Jésus","csduid":"2431130","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Pierre-de-Broughton","csduid":"2431135","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Jacques-de-Leeds","csduid":"2431140","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Ferdinand","csduid":"2432013","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Sophie-d'Halifax","csduid":"2432023","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Princeville","csduid":"2432033","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Plessisville","csduid":"2432040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Plessisville","csduid":"2432045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Pierre-Baptiste","csduid":"2432050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Inverness","csduid":"2432058","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lyster","csduid":"2432065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Laurierville","csduid":"2432072","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-de-Lourdes","csduid":"2432080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Villeroy","csduid":"2432085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Sylvestre","csduid":"2433007","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Agathe-de-Lotbinière","csduid":"2433017","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Patrice-de-Beaurivage","csduid":"2433025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Narcisse-de-Beaurivage","csduid":"2433030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Gilles","csduid":"2433035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Dosquet","csduid":"2433040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Agapit","csduid":"2433045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Flavien","csduid":"2433052","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Laurier-Station","csduid":"2433060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Janvier-de-Joly","csduid":"2433065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Val-Alain","csduid":"2433070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Édouard-de-Lotbinière","csduid":"2433080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-du-Sacré-Coeur-d'Issoudun","csduid":"2433085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Apollinaire","csduid":"2433090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Antoine-de-Tilly","csduid":"2433095","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Sainte-Croix","csduid":"2433102","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lotbinière","csduid":"2433115","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Leclercville","csduid":"2433123","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Neuville","csduid":"2434007","cmaname":"Québec","cmauid":"421","prname":"Quebec"},
    {"csdname":"Pont-Rouge","csduid":"2434017","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Donnacona","csduid":"2434025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Cap-Santé","csduid":"2434030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Basile","csduid":"2434038","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Portneuf","csduid":"2434048","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Deschambault-Grondines","csduid":"2434058","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Gilbert","csduid":"2434060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Marc-des-Carrières","csduid":"2434065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Casimir","csduid":"2434078","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Thuribe","csduid":"2434085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Ubalde","csduid":"2434090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Alban","csduid":"2434097","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Christine-d'Auvergne","csduid":"2434105","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Léonard-de-Portneuf","csduid":"2434115","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Sergent","csduid":"2434120","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Raymond","csduid":"2434128","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-à-Pierre","csduid":"2434135","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Blanc","csduid":"2434902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Linton","csduid":"2434904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Lapeyrère","csduid":"2434906","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-de-Montauban","csduid":"2435005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-aux-Sables","csduid":"2435010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Adelphe","csduid":"2435015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Séverin","csduid":"2435020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Tite","csduid":"2435027","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Hérouxville","csduid":"2435035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Grandes-Piles","csduid":"2435040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Roch-de-Mékinac","csduid":"2435045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Thècle","csduid":"2435050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Trois-Rives","csduid":"2435055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Masketsi","csduid":"2435902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Normand","csduid":"2435904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-de-la-Savane","csduid":"2435906","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Boulé","csduid":"2435908","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Shawinigan","csduid":"2436033","cmaname":"Shawinigan","cmauid":"444","prname":"Quebec"},
    {"csdname":"Trois-Rivières","csduid":"2437067","cmaname":"Trois-Rivières","cmauid":"442","prname":"Quebec"},
    {"csdname":"Sainte-Anne-de-la-Pérade","csduid":"2437205","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Batiscan","csduid":"2437210","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Geneviève-de-Batiscan","csduid":"2437215","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Champlain","csduid":"2437220","cmaname":"Trois-Rivières","cmauid":"442","prname":"Quebec"},
    {"csdname":"Saint-Luc-de-Vincennes","csduid":"2437225","cmaname":"Trois-Rivières","cmauid":"442","prname":"Quebec"},
    {"csdname":"Saint-Maurice","csduid":"2437230","cmaname":"Trois-Rivières","cmauid":"442","prname":"Quebec"},
    {"csdname":"Notre-Dame-du-Mont-Carmel","csduid":"2437235","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Narcisse","csduid":"2437240","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Stanislas","csduid":"2437245","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Prosper-de-Champlain","csduid":"2437250","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Sylvère","csduid":"2438005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Bécancour","csduid":"2438010","cmaname":"Trois-Rivières","cmauid":"442","prname":"Quebec"},
    {"csdname":"Sainte-Marie-de-Blandford","csduid":"2438015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lemieux","csduid":"2438020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Manseau","csduid":"2438028","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Françoise","csduid":"2438035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Sophie-de-Lévrard","csduid":"2438040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Fortierville","csduid":"2438047","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Parisville","csduid":"2438055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Cécile-de-Lévrard","csduid":"2438060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Pierre-les-Becquets","csduid":"2438065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Deschaillons-sur-Saint-Laurent","csduid":"2438070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Wôlinak","csduid":"2438802","cmaname":"Trois-Rivières","cmauid":"442","prname":"Quebec"},
    {"csdname":"Saints-Martyrs-Canadiens","csduid":"2439005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ham-Nord","csduid":"2439010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-de-Ham","csduid":"2439015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Rémi-de-Tingwick","csduid":"2439020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Tingwick","csduid":"2439025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Chesterville","csduid":"2439030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Hélène-de-Chester","csduid":"2439035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Norbert-d'Arthabaska","csduid":"2439043","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Christophe-d'Arthabaska","csduid":"2439060","cmaname":"Victoriaville","cmauid":"440","prname":"Quebec"},
    {"csdname":"Victoriaville","csduid":"2439062","cmaname":"Victoriaville","cmauid":"440","prname":"Quebec"},
    {"csdname":"Warwick","csduid":"2439077","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Albert","csduid":"2439085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Élizabeth-de-Warwick","csduid":"2439090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kingsey Falls","csduid":"2439097","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Séraphine","csduid":"2439105","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Clotilde-de-Horton","csduid":"2439117","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Samuel","csduid":"2439130","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Valère","csduid":"2439135","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Rosaire","csduid":"2439145","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Anne-du-Sault","csduid":"2439150","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Daveluyville","csduid":"2439155","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Maddington Falls","csduid":"2439165","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Louis-de-Blandford","csduid":"2439170","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ham-Sud","csduid":"2440005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Adrien","csduid":"2440010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Wotton","csduid":"2440017","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Camille","csduid":"2440025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Georges-de-Windsor","csduid":"2440032","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Asbestos","csduid":"2440043","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Danville","csduid":"2440047","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Isidore-de-Clifton","csduid":"2441012","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Chartierville","csduid":"2441020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Patrie","csduid":"2441027","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Newport","csduid":"2441037","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Cookshire-Eaton","csduid":"2441038","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ascot Corner","csduid":"2441055","cmaname":"Sherbrooke","cmauid":"433","prname":"Quebec"},
    {"csdname":"East Angus","csduid":"2441060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Westbury","csduid":"2441065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Bury","csduid":"2441070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Hampden","csduid":"2441075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Scotstown","csduid":"2441080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lingwick","csduid":"2441085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Weedon","csduid":"2441098","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Dudswell","csduid":"2441117","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Stoke","csduid":"2442005","cmaname":"Sherbrooke","cmauid":"433","prname":"Quebec"},
    {"csdname":"Saint-François-Xavier-de-Brompton","csduid":"2442020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Denis-de-Brompton","csduid":"2442025","cmaname":"Sherbrooke","cmauid":"433","prname":"Quebec"},
    {"csdname":"Racine","csduid":"2442032","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Bonsecours","csduid":"2442040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lawrenceville","csduid":"2442045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Anne-de-la-Rochelle","csduid":"2442050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Valcourt","csduid":"2442055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Valcourt","csduid":"2442060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Maricourt","csduid":"2442065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kingsbury","csduid":"2442070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Melbourne","csduid":"2442075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ulverton","csduid":"2442078","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Windsor","csduid":"2442088","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Val-Joli","csduid":"2442095","cmaname":"Sherbrooke","cmauid":"433","prname":"Quebec"},
    {"csdname":"Richmond","csduid":"2442098","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Claude","csduid":"2442100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Cleveland","csduid":"2442110","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sherbrooke","csduid":"2443027","cmaname":"Sherbrooke","cmauid":"433","prname":"Quebec"},
    {"csdname":"Saint-Malo","csduid":"2444003","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Venant-de-Paquette","csduid":"2444005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"East Hereford","csduid":"2444010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Herménégilde","csduid":"2444015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Dixville","csduid":"2444023","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Coaticook","csduid":"2444037","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Barnston-Ouest","csduid":"2444045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Stanstead-Est","csduid":"2444050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Edwidge-de-Clifton","csduid":"2444055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Martinville","csduid":"2444060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Compton","csduid":"2444071","cmaname":"Sherbrooke","cmauid":"433","prname":"Quebec"},
    {"csdname":"Waterville","csduid":"2444080","cmaname":"Sherbrooke","cmauid":"433","prname":"Quebec"},
    {"csdname":"Stanstead","csduid":"2445008","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ogden","csduid":"2445020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Stanstead","csduid":"2445025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Potton","csduid":"2445030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ayer's Cliff","csduid":"2445035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Hatley","csduid":"2445043","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"North Hatley","csduid":"2445050","cmaname":"Sherbrooke","cmauid":"433","prname":"Quebec"},
    {"csdname":"Hatley","csduid":"2445055","cmaname":"Sherbrooke","cmauid":"433","prname":"Quebec"},
    {"csdname":"Sainte-Catherine-de-Hatley","csduid":"2445060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Magog","csduid":"2445072","cmaname":"Sherbrooke","cmauid":"433","prname":"Quebec"},
    {"csdname":"Saint-Benoît-du-Lac","csduid":"2445080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Austin","csduid":"2445085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Eastman","csduid":"2445093","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Bolton-Est","csduid":"2445095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Étienne-de-Bolton","csduid":"2445100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Stukely-Sud","csduid":"2445105","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Orford","csduid":"2445115","cmaname":"Sherbrooke","cmauid":"433","prname":"Quebec"},
    {"csdname":"Abercorn","csduid":"2446005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Frelighsburg","csduid":"2446010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Armand","csduid":"2446017","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Pike River","csduid":"2446025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Stanbridge Station","csduid":"2446030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Bedford","csduid":"2446035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Bedford","csduid":"2446040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Stanbridge East","csduid":"2446045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Dunham","csduid":"2446050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sutton","csduid":"2446058","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Bolton-Ouest","csduid":"2446065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Brome","csduid":"2446070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Brome","csduid":"2446075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Bromont","csduid":"2446078","cmaname":"Granby","cmauid":"450","prname":"Quebec"},
    {"csdname":"Cowansville","csduid":"2446080","cmaname":"Cowansville","cmauid":"437","prname":"Quebec"},
    {"csdname":"East Farnham","csduid":"2446085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Brigham","csduid":"2446090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Ignace-de-Stanbridge","csduid":"2446095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-de-Stanbridge","csduid":"2446100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Sabine","csduid":"2446105","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Farnham","csduid":"2446112","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Alphonse-de-Granby","csduid":"2447010","cmaname":"Granby","cmauid":"450","prname":"Quebec"},
    {"csdname":"Granby","csduid":"2447017","cmaname":"Granby","cmauid":"450","prname":"Quebec"},
    {"csdname":"Waterloo","csduid":"2447025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Warden","csduid":"2447030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Shefford","csduid":"2447035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Joachim-de-Shefford","csduid":"2447040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Roxton Pond","csduid":"2447047","cmaname":"Granby","cmauid":"450","prname":"Quebec"},
    {"csdname":"Sainte-Cécile-de-Milton","csduid":"2447055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Béthanie","csduid":"2448005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Roxton Falls","csduid":"2448010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Roxton","csduid":"2448015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Christine","csduid":"2448020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Acton Vale","csduid":"2448028","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Upton","csduid":"2448038","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Théodore-d'Acton","csduid":"2448045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Nazaire-d'Acton","csduid":"2448050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Félix-de-Kingsey","csduid":"2449005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Durham-Sud","csduid":"2449015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lefebvre","csduid":"2449020","cmaname":"Drummondville","cmauid":"447","prname":"Quebec"},
    {"csdname":"L'Avenir","csduid":"2449025","cmaname":"Drummondville","cmauid":"447","prname":"Quebec"},
    {"csdname":"Saint-Lucien","csduid":"2449030","cmaname":"Drummondville","cmauid":"447","prname":"Quebec"},
    {"csdname":"Wickham","csduid":"2449040","cmaname":"Drummondville","cmauid":"447","prname":"Quebec"},
    {"csdname":"Saint-Germain-de-Grantham","csduid":"2449048","cmaname":"Drummondville","cmauid":"447","prname":"Quebec"},
    {"csdname":"Drummondville","csduid":"2449058","cmaname":"Drummondville","cmauid":"447","prname":"Quebec"},
    {"csdname":"Saint-Cyrille-de-Wendover","csduid":"2449070","cmaname":"Drummondville","cmauid":"447","prname":"Quebec"},
    {"csdname":"Notre-Dame-du-Bon-Conseil","csduid":"2449075","cmaname":"Drummondville","cmauid":"447","prname":"Quebec"},
    {"csdname":"Notre-Dame-du-Bon-Conseil","csduid":"2449080","cmaname":"Drummondville","cmauid":"447","prname":"Quebec"},
    {"csdname":"Sainte-Brigitte-des-Saults","csduid":"2449085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Majorique-de-Grantham","csduid":"2449095","cmaname":"Drummondville","cmauid":"447","prname":"Quebec"},
    {"csdname":"Saint-Edmond-de-Grantham","csduid":"2449100","cmaname":"Drummondville","cmauid":"447","prname":"Quebec"},
    {"csdname":"Saint-Eugène","csduid":"2449105","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Guillaume","csduid":"2449113","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Bonaventure","csduid":"2449125","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Pie-de-Guire","csduid":"2449130","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Eulalie","csduid":"2450005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Aston-Jonction","csduid":"2450013","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Wenceslas","csduid":"2450023","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Célestin","csduid":"2450030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Célestin","csduid":"2450035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Léonard-d'Aston","csduid":"2450042","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Perpétue","csduid":"2450050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Monique","csduid":"2450057","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Grand-Saint-Esprit","csduid":"2450065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Nicolet","csduid":"2450072","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Visitation-de-Yamaska","csduid":"2450085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Zéphirin-de-Courval","csduid":"2450090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Elphège","csduid":"2450095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Baie-du-Febvre","csduid":"2450100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Pierreville","csduid":"2450113","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-François-du-Lac","csduid":"2450128","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Odanak","csduid":"2450802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Maskinongé","csduid":"2451008","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Louiseville","csduid":"2451015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Yamachiche","csduid":"2451020","cmaname":"Trois-Rivières","cmauid":"442","prname":"Quebec"},
    {"csdname":"Saint-Barnabé","csduid":"2451025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Sévère","csduid":"2451030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Léon-le-Grand","csduid":"2451035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Ursule","csduid":"2451040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Justin","csduid":"2451045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Édouard-de-Maskinongé","csduid":"2451050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Angèle-de-Prémont","csduid":"2451055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Paulin","csduid":"2451060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Alexis-des-Monts","csduid":"2451065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Mathieu-du-Parc","csduid":"2451070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Élie-de-Caxton","csduid":"2451075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Charette","csduid":"2451080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Boniface","csduid":"2451085","cmaname":"Shawinigan","cmauid":"444","prname":"Quebec"},
    {"csdname":"Saint-Étienne-des-Grès","csduid":"2451090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lavaltrie","csduid":"2452007","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Lanoraie","csduid":"2452017","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Élisabeth","csduid":"2452030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Berthierville","csduid":"2452035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Geneviève-de-Berthier","csduid":"2452040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Ignace-de-Loyola","csduid":"2452045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Visitation-de-l'Île-Dupas","csduid":"2452050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Barthélemy","csduid":"2452055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Cuthbert","csduid":"2452062","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Norbert","csduid":"2452070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Cléophas-de-Brandon","csduid":"2452075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Gabriel","csduid":"2452080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Gabriel-de-Brandon","csduid":"2452085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Didace","csduid":"2452090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mandeville","csduid":"2452095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-David","csduid":"2453005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Massueville","csduid":"2453010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Aimé","csduid":"2453015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Robert","csduid":"2453020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Victoire-de-Sorel","csduid":"2453025","cmaname":"Sorel-Tracy","cmauid":"454","prname":"Quebec"},
    {"csdname":"Saint-Ours","csduid":"2453032","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Roch-de-Richelieu","csduid":"2453040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Joseph-de-Sorel","csduid":"2453050","cmaname":"Sorel-Tracy","cmauid":"454","prname":"Quebec"},
    {"csdname":"Sorel-Tracy","csduid":"2453052","cmaname":"Sorel-Tracy","cmauid":"454","prname":"Quebec"},
    {"csdname":"Sainte-Anne-de-Sorel","csduid":"2453065","cmaname":"Sorel-Tracy","cmauid":"454","prname":"Quebec"},
    {"csdname":"Yamaska","csduid":"2453072","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Gérard-Majella","csduid":"2453085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Pie","csduid":"2454008","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Damase","csduid":"2454017","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Madeleine","csduid":"2454025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Marie-Madeleine","csduid":"2454030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Présentation","csduid":"2454035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Hyacinthe","csduid":"2454048","cmaname":"Saint-Hyacinthe","cmauid":"452","prname":"Quebec"},
    {"csdname":"Saint-Dominique","csduid":"2454060","cmaname":"Saint-Hyacinthe","cmauid":"452","prname":"Quebec"},
    {"csdname":"Saint-Valérien-de-Milton","csduid":"2454065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Liboire","csduid":"2454072","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Simon","csduid":"2454090","cmaname":"Saint-Hyacinthe","cmauid":"452","prname":"Quebec"},
    {"csdname":"Sainte-Hélène-de-Bagot","csduid":"2454095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Hugues","csduid":"2454100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Barnabé-Sud","csduid":"2454105","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Jude","csduid":"2454110","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Bernard-de-Michaudville","csduid":"2454115","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Louis","csduid":"2454120","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Marcel-de-Richelieu","csduid":"2454125","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ange-Gardien","csduid":"2455008","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Paul-d'Abbotsford","csduid":"2455015","cmaname":"Granby","cmauid":"450","prname":"Quebec"},
    {"csdname":"Saint-Césaire","csduid":"2455023","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Angèle-de-Monnoir","csduid":"2455030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rougemont","csduid":"2455037","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Marieville","csduid":"2455048","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Richelieu","csduid":"2455057","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Mathias-sur-Richelieu","csduid":"2455065","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Venise-en-Québec","csduid":"2456005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Georges-de-Clarenceville","csduid":"2456010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Noyan","csduid":"2456015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lacolle","csduid":"2456023","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Valentin","csduid":"2456030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Paul-de-l'Île-aux-Noix","csduid":"2456035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Henryville","csduid":"2456042","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Sébastien","csduid":"2456050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Alexandre","csduid":"2456055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Anne-de-Sabrevois","csduid":"2456060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Blaise-sur-Richelieu","csduid":"2456065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Jean-sur-Richelieu","csduid":"2456083","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Mont-Saint-Grégoire","csduid":"2456097","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Brigide-d'Iberville","csduid":"2456105","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Chambly","csduid":"2457005","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Carignan","csduid":"2457010","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Basile-le-Grand","csduid":"2457020","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"McMasterville","csduid":"2457025","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Otterburn Park","csduid":"2457030","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Jean-Baptiste","csduid":"2457033","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mont-Saint-Hilaire","csduid":"2457035","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Beloeil","csduid":"2457040","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Mathieu-de-Beloeil","csduid":"2457045","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Marc-sur-Richelieu","csduid":"2457050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Charles-sur-Richelieu","csduid":"2457057","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Denis-sur-Richelieu","csduid":"2457068","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Antoine-sur-Richelieu","csduid":"2457075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Brossard","csduid":"2458007","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Lambert","csduid":"2458012","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Boucherville","csduid":"2458033","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Bruno-de-Montarville","csduid":"2458037","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Longueuil","csduid":"2458227","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Sainte-Julie","csduid":"2459010","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Amable","csduid":"2459015","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Varennes","csduid":"2459020","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Verchères","csduid":"2459025","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Calixa-Lavallée","csduid":"2459030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Contrecoeur","csduid":"2459035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Charlemagne","csduid":"2460005","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Repentigny","csduid":"2460013","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Sulpice","csduid":"2460020","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"L'Assomption","csduid":"2460028","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"L'Épiphanie","csduid":"2460035","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"L'Épiphanie","csduid":"2460040","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Paul","csduid":"2461005","cmaname":"Joliette","cmauid":"456","prname":"Quebec"},
    {"csdname":"Crabtree","csduid":"2461013","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Pierre","csduid":"2461020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Joliette","csduid":"2461025","cmaname":"Joliette","cmauid":"456","prname":"Quebec"},
    {"csdname":"Saint-Thomas","csduid":"2461027","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-des-Prairies","csduid":"2461030","cmaname":"Joliette","cmauid":"456","prname":"Quebec"},
    {"csdname":"Saint-Charles-Borromée","csduid":"2461035","cmaname":"Joliette","cmauid":"456","prname":"Quebec"},
    {"csdname":"Saint-Ambroise-de-Kildare","csduid":"2461040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-de-Lourdes","csduid":"2461045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Mélanie","csduid":"2461050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Félix-de-Valois","csduid":"2462007","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Jean-de-Matha","csduid":"2462015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Béatrix","csduid":"2462020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Alphonse-Rodriguez","csduid":"2462025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Marcelline-de-Kildare","csduid":"2462030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rawdon","csduid":"2462037","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Chertsey","csduid":"2462047","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Entrelacs","csduid":"2462053","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-de-la-Merci","csduid":"2462055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Donat","csduid":"2462060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Côme","csduid":"2462065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Émélie-de-l'Énergie","csduid":"2462070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Damien","csduid":"2462075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Zénon","csduid":"2462080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Michel-des-Saints","csduid":"2462085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Manawan","csduid":"2462802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Minaki","csduid":"2462902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Devenyns","csduid":"2462904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Baie-de-la-Bouteille","csduid":"2462906","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Matawin","csduid":"2462908","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Legendre","csduid":"2462910","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Guillaume-Nord","csduid":"2462912","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-des-Dix-Milles","csduid":"2462914","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Santé","csduid":"2462916","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Baie-Obaoca","csduid":"2462918","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Cabasta","csduid":"2462919","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Baie-Atibenne","csduid":"2462920","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-du-Taureau","csduid":"2462922","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Marie-Salomé","csduid":"2463005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Jacques","csduid":"2463013","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Alexis","csduid":"2463023","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Esprit","csduid":"2463030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Roch-de-l'Achigan","csduid":"2463035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Roch-Ouest","csduid":"2463040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Lin--Laurentides","csduid":"2463048","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Calixte","csduid":"2463055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Julienne","csduid":"2463060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Liguori","csduid":"2463065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Terrebonne","csduid":"2464008","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Mascouche","csduid":"2464015","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Laval","csduid":"2465005","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Montréal-Est","csduid":"2466007","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Montréal","csduid":"2466023","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Westmount","csduid":"2466032","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Montréal-Ouest","csduid":"2466047","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Côte-Saint-Luc","csduid":"2466058","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Hampstead","csduid":"2466062","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Mont-Royal","csduid":"2466072","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Dorval","csduid":"2466087","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"L'Île-Dorval","csduid":"2466092","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Pointe-Claire","csduid":"2466097","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Kirkland","csduid":"2466102","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Beaconsfield","csduid":"2466107","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Baie-D'Urfé","csduid":"2466112","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Sainte-Anne-de-Bellevue","csduid":"2466117","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Senneville","csduid":"2466127","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Dollard-Des Ormeaux","csduid":"2466142","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Mathieu","csduid":"2467005","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Philippe","csduid":"2467010","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"La Prairie","csduid":"2467015","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Candiac","csduid":"2467020","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Delson","csduid":"2467025","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Sainte-Catherine","csduid":"2467030","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Constant","csduid":"2467035","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Isidore","csduid":"2467040","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Mercier","csduid":"2467045","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Châteauguay","csduid":"2467050","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Léry","csduid":"2467055","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Kahnawake","csduid":"2467802","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Bernard-de-Lacolle","csduid":"2468005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Hemmingford","csduid":"2468010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Hemmingford","csduid":"2468015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Clotilde","csduid":"2468020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Patrice-de-Sherrington","csduid":"2468025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Napierville","csduid":"2468030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Cyprien-de-Napierville","csduid":"2468035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Jacques-le-Mineur","csduid":"2468040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Édouard","csduid":"2468045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Michel","csduid":"2468050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Rémi","csduid":"2468055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Havelock","csduid":"2469005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Franklin","csduid":"2469010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Chrysostome","csduid":"2469017","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Howick","csduid":"2469025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Très-Saint-Sacrement","csduid":"2469030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ormstown","csduid":"2469037","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Hinchinbrooke","csduid":"2469045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Elgin","csduid":"2469050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Huntingdon","csduid":"2469055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Godmanchester","csduid":"2469060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Barbe","csduid":"2469065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Anicet","csduid":"2469070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Dundee","csduid":"2469075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Akwesasne","csduid":"2469802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Urbain-Premier","csduid":"2470005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Martine","csduid":"2470012","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Beauharnois","csduid":"2470022","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Étienne-de-Beauharnois","csduid":"2470030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Louis-de-Gonzague","csduid":"2470035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Stanislas-de-Kostka","csduid":"2470040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Salaberry-de-Valleyfield","csduid":"2470052","cmaname":"Salaberry-de-Valleyfield","cmauid":"465","prname":"Quebec"},
    {"csdname":"Rivière-Beaudette","csduid":"2471005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Télesphore","csduid":"2471015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Polycarpe","csduid":"2471020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Zotique","csduid":"2471025","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Les Coteaux","csduid":"2471033","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Coteau-du-Lac","csduid":"2471040","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Clet","csduid":"2471045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Les Cèdres","csduid":"2471050","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Pointe-des-Cascades","csduid":"2471055","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"L'Île-Perrot","csduid":"2471060","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Notre-Dame-de-l'Île-Perrot","csduid":"2471065","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Pincourt","csduid":"2471070","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Terrasse-Vaudreuil","csduid":"2471075","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Vaudreuil-Dorion","csduid":"2471083","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Vaudreuil-sur-le-Lac","csduid":"2471090","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"L'Île-Cadieux","csduid":"2471095","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Hudson","csduid":"2471100","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Lazare","csduid":"2471105","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Sainte-Marthe","csduid":"2471110","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Justine-de-Newton","csduid":"2471115","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Très-Saint-Rédempteur","csduid":"2471125","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rigaud","csduid":"2471133","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Pointe-Fortune","csduid":"2471140","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Eustache","csduid":"2472005","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Deux-Montagnes","csduid":"2472010","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Sainte-Marthe-sur-le-Lac","csduid":"2472015","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Pointe-Calumet","csduid":"2472020","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Joseph-du-Lac","csduid":"2472025","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Oka","csduid":"2472032","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Placide","csduid":"2472043","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Kanesatake","csduid":"2472802","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Boisbriand","csduid":"2473005","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Sainte-Thérèse","csduid":"2473010","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Blainville","csduid":"2473015","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Rosemère","csduid":"2473020","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Lorraine","csduid":"2473025","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Bois-des-Filion","csduid":"2473030","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Sainte-Anne-des-Plaines","csduid":"2473035","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Mirabel","csduid":"2474005","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Colomban","csduid":"2475005","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Saint-Jérôme","csduid":"2475017","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Sainte-Sophie","csduid":"2475028","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Prévost","csduid":"2475040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Hippolyte","csduid":"2475045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-André-d'Argenteuil","csduid":"2476008","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lachute","csduid":"2476020","cmaname":"Lachute","cmauid":"468","prname":"Quebec"},
    {"csdname":"Gore","csduid":"2476025","cmaname":"Montréal","cmauid":"462","prname":"Quebec"},
    {"csdname":"Mille-Isles","csduid":"2476030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Wentworth","csduid":"2476035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Brownsburg-Chatham","csduid":"2476043","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Grenville-sur-la-Rouge","csduid":"2476052","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Grenville","csduid":"2476055","cmaname":"Hawkesbury (partie du Québec / Quebec part)","cmauid":"502","prname":"Quebec"},
    {"csdname":"Harrington","csduid":"2476065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Estérel","csduid":"2477011","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Marguerite-du-Lac-Masson","csduid":"2477012","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Adèle","csduid":"2477022","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Piedmont","csduid":"2477030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Anne-des-Lacs","csduid":"2477035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Sauveur","csduid":"2477043","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Morin-Heights","csduid":"2477050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-des-Seize-Îles","csduid":"2477055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Wentworth-Nord","csduid":"2477060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Adolphe-d'Howard","csduid":"2477065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Val-Morin","csduid":"2478005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Val-David","csduid":"2478010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lantier","csduid":"2478015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Lucie-des-Laurentides","csduid":"2478020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Agathe-des-Monts","csduid":"2478032","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ivry-sur-le-Lac","csduid":"2478042","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Faustin--Lac-Carré","csduid":"2478047","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Barkmere","csduid":"2478050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Montcalm","csduid":"2478055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Arundel","csduid":"2478060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Huberdeau","csduid":"2478065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Amherst","csduid":"2478070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Brébeuf","csduid":"2478075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Supérieur","csduid":"2478095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Val-des-Lacs","csduid":"2478100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mont-Tremblant","csduid":"2478102","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Conception","csduid":"2478115","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Labelle","csduid":"2478120","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Tremblant-Nord","csduid":"2478127","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Minerve","csduid":"2478130","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Doncaster","csduid":"2478802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-du-Laus","csduid":"2479005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-de-Pontmain","csduid":"2479010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-du-Cerf","csduid":"2479015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Aimé-du-Lac-des-Îles","csduid":"2479022","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kiamika","csduid":"2479025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Nominingue","csduid":"2479030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Rouge","csduid":"2479037","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Macaza","csduid":"2479047","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"L'Ascension","csduid":"2479050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Saguay","csduid":"2479060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Chute-Saint-Philippe","csduid":"2479065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-des-Écorces","csduid":"2479078","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mont-Laurier","csduid":"2479088","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ferme-Neuve","csduid":"2479097","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Saint-Paul","csduid":"2479105","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mont-Saint-Michel","csduid":"2479110","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Anne-du-Lac","csduid":"2479115","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-de-la-Pomme","csduid":"2479902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Akonapwehikan","csduid":"2479904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Wagwabika","csduid":"2479906","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Bazinet","csduid":"2479910","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-De La Bidière","csduid":"2479912","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Oscar","csduid":"2479914","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-de-la-Maison-de-Pierre","csduid":"2479916","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Baie-des-Chaloupes","csduid":"2479920","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Douaire","csduid":"2479922","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Ernest","csduid":"2479924","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Marguerite","csduid":"2479926","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Fassett","csduid":"2480005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Montebello","csduid":"2480010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-de-Bonsecours","csduid":"2480015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-de-la-Paix","csduid":"2480020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-André-Avellin","csduid":"2480027","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Papineauville","csduid":"2480037","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Plaisance","csduid":"2480045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Thurso","csduid":"2480050","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"Lochaber","csduid":"2480055","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"Lochaber-Partie-Ouest","csduid":"2480060","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"Mayo","csduid":"2480065","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"Saint-Sixte","csduid":"2480070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ripon","csduid":"2480078","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mulgrave-et-Derry","csduid":"2480085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Montpellier","csduid":"2480090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Simon","csduid":"2480095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Chénéville","csduid":"2480103","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Namur","csduid":"2480110","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Boileau","csduid":"2480115","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Émile-de-Suffolk","csduid":"2480125","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-des-Plages","csduid":"2480130","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Duhamel","csduid":"2480135","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Val-des-Bois","csduid":"2480140","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"Bowman","csduid":"2480145","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"Gatineau","csduid":"2481017","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"L'Ange-Gardien","csduid":"2482005","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"Notre-Dame-de-la-Salette","csduid":"2482010","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"Val-des-Monts","csduid":"2482015","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"Cantley","csduid":"2482020","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"Chelsea","csduid":"2482025","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"Pontiac","csduid":"2482030","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"La Pêche","csduid":"2482035","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"Denholm","csduid":"2483005","cmaname":"Ottawa - Gatineau (partie du Québec / Quebec part)","cmauid":"505","prname":"Quebec"},
    {"csdname":"Low","csduid":"2483010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kazabazua","csduid":"2483015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Sainte-Marie","csduid":"2483020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Gracefield","csduid":"2483032","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Cayamant","csduid":"2483040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Blue Sea","csduid":"2483045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Bouchette","csduid":"2483050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Thérèse-de-la-Gatineau","csduid":"2483055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Messines","csduid":"2483060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Maniwaki","csduid":"2483065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Déléage","csduid":"2483070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Egan-Sud","csduid":"2483075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Bois-Franc","csduid":"2483085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Montcerf-Lytton","csduid":"2483088","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Aumond","csduid":"2483090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Grand-Remous","csduid":"2483095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kitigan Zibi","csduid":"2483802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Rapide","csduid":"2483804","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Pythonga","csduid":"2483902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Cascades-Malignes","csduid":"2483904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Lenôtre","csduid":"2483906","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Moselle","csduid":"2483908","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Dépôt-Échouani","csduid":"2483912","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Bristol","csduid":"2484005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Shawville","csduid":"2484010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Clarendon","csduid":"2484015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Portage-du-Fort","csduid":"2484020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Bryson","csduid":"2484025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Campbell's Bay","csduid":"2484030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"L'Île-du-Grand-Calumet","csduid":"2484035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Litchfield","csduid":"2484040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Thorne","csduid":"2484045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Alleyn-et-Cawood","csduid":"2484050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Otter Lake","csduid":"2484055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Fort-Coulonge","csduid":"2484060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mansfield-et-Pontefract","csduid":"2484065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Waltham","csduid":"2484070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"L'Isle-aux-Allumettes","csduid":"2484082","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Chichester","csduid":"2484090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sheenboro","csduid":"2484095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rapides-des-Joachims","csduid":"2484100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Nilgaut","csduid":"2484902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Témiscaming","csduid":"2485005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kipawa","csduid":"2485010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Édouard-de-Fabre","csduid":"2485015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Béarn","csduid":"2485020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ville-Marie","csduid":"2485025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Duhamel-Ouest","csduid":"2485030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lorrainville","csduid":"2485037","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Bruno-de-Guigues","csduid":"2485045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Laverlochère","csduid":"2485050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Fugèreville","csduid":"2485055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Latulipe-et-Gaboury","csduid":"2485060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Belleterre","csduid":"2485065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Laforce","csduid":"2485070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Moffet","csduid":"2485075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Angliers","csduid":"2485080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Eugène-de-Guigues","csduid":"2485085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-du-Nord","csduid":"2485090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Guérin","csduid":"2485095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Nédélec","csduid":"2485100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rémigny","csduid":"2485105","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kebaowek","csduid":"2485802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Hunter's Point","csduid":"2485803","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Winneway","csduid":"2485804","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Timiskaming","csduid":"2485806","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Laniel","csduid":"2485905","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Les Lacs-du-Témiscamingue","csduid":"2485907","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rouyn-Noranda","csduid":"2486042","cmaname":"Rouyn-Noranda","cmauid":"485","prname":"Quebec"},
    {"csdname":"Duparquet","csduid":"2487005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rapide-Danseur","csduid":"2487010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Roquemaure","csduid":"2487015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Gallichan","csduid":"2487020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Palmarolle","csduid":"2487025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Germaine-Boulé","csduid":"2487030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Poularies","csduid":"2487035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Taschereau","csduid":"2487042","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Authier","csduid":"2487050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Macamic","csduid":"2487058","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Hélène-de-Mancebourg","csduid":"2487070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Clerval","csduid":"2487075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Reine","csduid":"2487080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Dupuy","csduid":"2487085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Sarre","csduid":"2487090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Chazel","csduid":"2487095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Authier-Nord","csduid":"2487100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Val-Saint-Gilles","csduid":"2487105","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Clermont","csduid":"2487110","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Normétal","csduid":"2487115","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Lambert","csduid":"2487120","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Duparquet","csduid":"2487902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Ojima","csduid":"2487904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Champneuf","csduid":"2488005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rochebaucourt","csduid":"2488010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Morandière","csduid":"2488015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Barraute","csduid":"2488022","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Corne","csduid":"2488030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Landrienne","csduid":"2488035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Marc-de-Figuery","csduid":"2488040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Motte","csduid":"2488045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Mathieu-d'Harricana","csduid":"2488050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Amos","csduid":"2488055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Félix-de-Dalquier","csduid":"2488060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Dominique-du-Rosaire","csduid":"2488065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Berry","csduid":"2488070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Trécesson","csduid":"2488075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Launay","csduid":"2488080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Gertrude-Manneville","csduid":"2488085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Preissac","csduid":"2488090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Pikogan","csduid":"2488802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Despinassy","csduid":"2488902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Chicobi","csduid":"2488904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Val-d'Or","csduid":"2489008","cmaname":"Val-d'Or","cmauid":"480","prname":"Quebec"},
    {"csdname":"Rivière-Héva","csduid":"2489010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Malartic","csduid":"2489015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Senneterre","csduid":"2489040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Senneterre","csduid":"2489045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Belcourt","csduid":"2489050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kitcisakik","csduid":"2489802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Simon","csduid":"2489804","cmaname":"Val-d'Or","cmauid":"480","prname":"Quebec"},
    {"csdname":"Matchi-Manitou","csduid":"2489902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Metei","csduid":"2489908","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Réservoir-Dozois","csduid":"2489910","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Granet","csduid":"2489912","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Tuque","csduid":"2490012","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Bostonnais","csduid":"2490017","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Édouard","csduid":"2490027","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Wemotaci","csduid":"2490802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Obedjiwan","csduid":"2490804","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Bouchette","csduid":"2491005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-André-du-Lac-Saint-Jean","csduid":"2491010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-François-de-Sales","csduid":"2491015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Chambord","csduid":"2491020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Roberval","csduid":"2491025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Hedwidge","csduid":"2491030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Prime","csduid":"2491035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Félicien","csduid":"2491042","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Doré","csduid":"2491050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mashteuiatsh","csduid":"2491802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Ashuapmushuan","csduid":"2491902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Augustin","csduid":"2492005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Péribonka","csduid":"2492010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Jeanne-d'Arc","csduid":"2492015","cmaname":"Dolbeau-Mistassini","cmauid":"411","prname":"Quebec"},
    {"csdname":"Dolbeau-Mistassini","csduid":"2492022","cmaname":"Dolbeau-Mistassini","cmauid":"411","prname":"Quebec"},
    {"csdname":"Albanel","csduid":"2492030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Normandin","csduid":"2492040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Thomas-Didyme","csduid":"2492045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Edmond-les-Plaines","csduid":"2492050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Girardville","csduid":"2492055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Notre-Dame-de-Lorette","csduid":"2492060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Eugène-d'Argentenay","csduid":"2492065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Stanislas","csduid":"2492070","cmaname":"Dolbeau-Mistassini","cmauid":"411","prname":"Quebec"},
    {"csdname":"Passes-Dangereuses","csduid":"2492902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Mistassini","csduid":"2492904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Desbiens","csduid":"2493005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Métabetchouan--Lac-à-la-Croix","csduid":"2493012","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Hébertville","csduid":"2493020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Hébertville-Station","csduid":"2493025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Bruno","csduid":"2493030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Gédéon","csduid":"2493035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Alma","csduid":"2493042","cmaname":"Alma","cmauid":"410","prname":"Quebec"},
    {"csdname":"Saint-Nazaire","csduid":"2493045","cmaname":"Alma","cmauid":"410","prname":"Quebec"},
    {"csdname":"Labrecque","csduid":"2493055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lamarche","csduid":"2493060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"L'Ascension-de-Notre-Seigneur","csduid":"2493065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Henri-de-Taillon","csduid":"2493070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sainte-Monique","csduid":"2493075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Ludger-de-Milot","csduid":"2493080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mont-Apica","csduid":"2493902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Moncouche","csduid":"2493904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Achouakan","csduid":"2493906","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Belle-Rivière","csduid":"2493908","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saguenay","csduid":"2494068","cmaname":"Saguenay","cmauid":"408","prname":"Quebec"},
    {"csdname":"Petit-Saguenay","csduid":"2494205","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"L'Anse-Saint-Jean","csduid":"2494210","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Éternité","csduid":"2494215","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ferland-et-Boilleau","csduid":"2494220","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Félix-d'Otis","csduid":"2494225","cmaname":"Saguenay","cmauid":"408","prname":"Quebec"},
    {"csdname":"Sainte-Rose-du-Nord","csduid":"2494230","cmaname":"Saguenay","cmauid":"408","prname":"Quebec"},
    {"csdname":"Saint-Fulgence","csduid":"2494235","cmaname":"Saguenay","cmauid":"408","prname":"Quebec"},
    {"csdname":"Saint-Honoré","csduid":"2494240","cmaname":"Saguenay","cmauid":"408","prname":"Quebec"},
    {"csdname":"Saint-David-de-Falardeau","csduid":"2494245","cmaname":"Saguenay","cmauid":"408","prname":"Quebec"},
    {"csdname":"Bégin","csduid":"2494250","cmaname":"Saguenay","cmauid":"408","prname":"Quebec"},
    {"csdname":"Saint-Ambroise","csduid":"2494255","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Charles-de-Bourget","csduid":"2494260","cmaname":"Saguenay","cmauid":"408","prname":"Quebec"},
    {"csdname":"Larouche","csduid":"2494265","cmaname":"Saguenay","cmauid":"408","prname":"Quebec"},
    {"csdname":"Lalemant","csduid":"2494926","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Ministuk","csduid":"2494928","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mont-Valin","csduid":"2494930","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Tadoussac","csduid":"2495005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sacré-Coeur","csduid":"2495010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Les Bergeronnes","csduid":"2495018","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Les Escoumins","csduid":"2495025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Longue-Rive","csduid":"2495032","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Portneuf-sur-Mer","csduid":"2495040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Forestville","csduid":"2495045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Colombier","csduid":"2495050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Essipit","csduid":"2495802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-au-Brochet","csduid":"2495902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Baie-Trinité","csduid":"2496005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Godbout","csduid":"2496010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Franquelin","csduid":"2496015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Baie-Comeau","csduid":"2496020","cmaname":"Baie-Comeau","cmauid":"406","prname":"Quebec"},
    {"csdname":"Pointe-Lebel","csduid":"2496025","cmaname":"Baie-Comeau","cmauid":"406","prname":"Quebec"},
    {"csdname":"Pointe-aux-Outardes","csduid":"2496030","cmaname":"Baie-Comeau","cmauid":"406","prname":"Quebec"},
    {"csdname":"Chute-aux-Outardes","csduid":"2496035","cmaname":"Baie-Comeau","cmauid":"406","prname":"Quebec"},
    {"csdname":"Ragueneau","csduid":"2496040","cmaname":"Baie-Comeau","cmauid":"406","prname":"Quebec"},
    {"csdname":"Pessamit","csduid":"2496802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-aux-Outardes","csduid":"2496902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Sept-Îles","csduid":"2497007","cmaname":"Sept-Îles","cmauid":"412","prname":"Quebec"},
    {"csdname":"Port-Cartier","csduid":"2497022","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Fermont","csduid":"2497035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Schefferville","csduid":"2497040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Uashat","csduid":"2497802","cmaname":"Sept-Îles","cmauid":"412","prname":"Quebec"},
    {"csdname":"Maliotenam","csduid":"2497804","cmaname":"Sept-Îles","cmauid":"412","prname":"Quebec"},
    {"csdname":"Kawawachikamach","csduid":"2497806","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Matimekosh","csduid":"2497808","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-John","csduid":"2497810","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Nipissis","csduid":"2497902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Walker","csduid":"2497904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Mouchalagane","csduid":"2497906","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Caniapiscau","csduid":"2497908","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Juillet","csduid":"2497912","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Vacher","csduid":"2497914","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Blanc-Sablon","csduid":"2498005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Bonne-Espérance","csduid":"2498010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Saint-Augustin","csduid":"2498012","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Gros-Mécatina","csduid":"2498014","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Côte-Nord-du-Golfe-du-Saint-Laurent","csduid":"2498015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"L'Île-d'Anticosti","csduid":"2498020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Natashquan","csduid":"2498025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Aguanish","csduid":"2498030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Baie-Johan-Beetz","csduid":"2498035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Havre-Saint-Pierre","csduid":"2498040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Longue-Pointe-de-Mingan","csduid":"2498045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Saint-Jean","csduid":"2498050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-au-Tonnerre","csduid":"2498055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Pakuashipi","csduid":"2498802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"La Romaine","csduid":"2498804","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Natashquan","csduid":"2498806","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mingan","csduid":"2498808","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lac-Jérôme","csduid":"2498904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Petit-Mécatina","csduid":"2498912","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Lebel-sur-Quévillon","csduid":"2499005","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Waswanipi","csduid":"2499010","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Matagami","csduid":"2499015","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Chapais","csduid":"2499020","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Chibougamau","csduid":"2499025","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mistissini","csduid":"2499030","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Waskaganish","csduid":"2499035","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Nemaska","csduid":"2499040","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Eastmain","csduid":"2499045","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Wemindji","csduid":"2499050","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Chisasibi","csduid":"2499055","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Eeyou Istchee Baie-James","csduid":"2499060","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kawawachikamach","csduid":"2499065","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Whapmagoostui","csduid":"2499070","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kuujjuarapik","csduid":"2499075","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Umiujaq","csduid":"2499080","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Inukjuak","csduid":"2499085","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kangiqsualujjuaq","csduid":"2499090","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kuujjuaq","csduid":"2499095","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Tasiujaq","csduid":"2499100","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Aupaluk","csduid":"2499105","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kangirsuk","csduid":"2499110","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Quaqtaq","csduid":"2499115","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Puvirnituq","csduid":"2499120","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Akulivik","csduid":"2499125","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kangiqsujuaq","csduid":"2499130","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Salluit","csduid":"2499135","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ivujivik","csduid":"2499140","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Waswanipi","csduid":"2499802","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Mistissini","csduid":"2499804","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Waskaganish","csduid":"2499806","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Nemaska","csduid":"2499808","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Eastmain","csduid":"2499810","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Wemindji","csduid":"2499812","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Chisasibi","csduid":"2499814","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Whapmagoostui","csduid":"2499816","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Oujé-Bougoumou","csduid":"2499818","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kuujjuarapik","csduid":"2499877","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Umiujaq","csduid":"2499878","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Inukjuak","csduid":"2499879","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Akulivik","csduid":"2499883","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Salluit","csduid":"2499887","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kangiqsujuaq","csduid":"2499888","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Quaqtaq","csduid":"2499889","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kangirsuk","csduid":"2499890","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Aupaluk","csduid":"2499891","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Tasiujaq","csduid":"2499892","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kuujjuaq","csduid":"2499893","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Kangiqsualujjuaq","csduid":"2499894","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Ivujivik","csduid":"2499895","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Rivière-Koksoak","csduid":"2499902","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"Baie-d'Hudson","csduid":"2499904","cmaname":"","cmauid":"","prname":"Quebec"},
    {"csdname":"South Glengarry","csduid":"3501005","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Akwesasne (Part) 59","csduid":"3501007","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"South Stormont","csduid":"3501011","cmaname":"Cornwall","cmauid":"501","prname":"Ontar"},
    {"csdname":"Cornwall","csduid":"3501012","cmaname":"Cornwall","cmauid":"501","prname":"Ontar"},
    {"csdname":"South Dundas","csduid":"3501020","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"North Dundas","csduid":"3501030","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"North Stormont","csduid":"3501042","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"North Glengarry","csduid":"3501050","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"East Hawkesbury","csduid":"3502001","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Hawkesbury","csduid":"3502008","cmaname":"Hawkesbury (Ontario part / partie de l'Ontario)","cmauid":"502","prname":"Ontar"},
    {"csdname":"Champlain","csduid":"3502010","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Alfred and Plantagenet","csduid":"3502023","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"The Nation / La Nation","csduid":"3502025","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Clarence-Rockland","csduid":"3502036","cmaname":"Ottawa - Gatineau (Ontario part / partie de l'Ontario)","cmauid":"505","prname":"Ontar"},
    {"csdname":"Casselman","csduid":"3502044","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Russell","csduid":"3502048","cmaname":"Ottawa - Gatineau (Ontario part / partie de l'Ontario)","cmauid":"505","prname":"Ontar"},
    {"csdname":"Ottawa","csduid":"3506008","cmaname":"Ottawa - Gatineau (Ontario part / partie de l'Ontario)","cmauid":"505","prname":"Ontar"},
    {"csdname":"Edwardsburgh/Cardinal","csduid":"3507004","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Augusta","csduid":"3507006","cmaname":"Brockville","cmauid":"512","prname":"Ontar"},
    {"csdname":"Prescott","csduid":"3507008","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Elizabethtown-Kitley","csduid":"3507014","cmaname":"Brockville","cmauid":"512","prname":"Ontar"},
    {"csdname":"Brockville","csduid":"3507015","cmaname":"Brockville","cmauid":"512","prname":"Ontar"},
    {"csdname":"Front of Yonge","csduid":"3507017","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Leeds and the Thousand Islands","csduid":"3507021","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Gananoque","csduid":"3507024","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Westport","csduid":"3507033","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Rideau Lakes","csduid":"3507040","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Athens","csduid":"3507042","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Merrickville-Wolford","csduid":"3507052","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"North Grenville","csduid":"3507065","cmaname":"Ottawa - Gatineau (Ontario part / partie de l'Ontario)","cmauid":"505","prname":"Ontar"},
    {"csdname":"Montague","csduid":"3509001","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Smiths Falls","csduid":"3509004","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Drummond/North Elmsley","csduid":"3509010","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Tay Valley","csduid":"3509015","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Perth","csduid":"3509021","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Beckwith","csduid":"3509024","cmaname":"Carleton Place","cmauid":"509","prname":"Ontar"},
    {"csdname":"Carleton Place","csduid":"3509028","cmaname":"Carleton Place","cmauid":"509","prname":"Ontar"},
    {"csdname":"Mississippi Mills","csduid":"3509030","cmaname":"Carleton Place","cmauid":"509","prname":"Ontar"},
    {"csdname":"Lanark Highlands","csduid":"3509039","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Frontenac Islands","csduid":"3510005","cmaname":"Kingston","cmauid":"521","prname":"Ontar"},
    {"csdname":"Kingston","csduid":"3510010","cmaname":"Kingston","cmauid":"521","prname":"Ontar"},
    {"csdname":"South Frontenac","csduid":"3510020","cmaname":"Kingston","cmauid":"521","prname":"Ontar"},
    {"csdname":"Central Frontenac","csduid":"3510035","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"North Frontenac","csduid":"3510045","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Loyalist","csduid":"3511005","cmaname":"Kingston","cmauid":"521","prname":"Ontar"},
    {"csdname":"Greater Napanee","csduid":"3511015","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Stone Mills","csduid":"3511030","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Addington Highlands","csduid":"3511035","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Tyendinaga","csduid":"3512001","cmaname":"Belleville","cmauid":"522","prname":"Ontar"},
    {"csdname":"Deseronto","csduid":"3512002","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Tyendinaga Mohawk Territory","csduid":"3512004","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Belleville","csduid":"3512005","cmaname":"Belleville","cmauid":"522","prname":"Ontar"},
    {"csdname":"Quinte West","csduid":"3512015","cmaname":"Belleville","cmauid":"522","prname":"Ontar"},
    {"csdname":"Stirling-Rawdon","csduid":"3512020","cmaname":"Belleville","cmauid":"522","prname":"Ontar"},
    {"csdname":"Centre Hastings","csduid":"3512026","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Tweed","csduid":"3512030","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Madoc","csduid":"3512036","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Marmora and Lake","csduid":"3512046","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Tudor and Cashel","csduid":"3512048","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Limerick","csduid":"3512051","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Wollaston","csduid":"3512054","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Faraday","csduid":"3512058","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Bancroft","csduid":"3512061","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Carlow/Mayo","csduid":"3512065","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Hastings Highlands","csduid":"3512076","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Prince Edward County","csduid":"3513020","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Brighton","csduid":"3514004","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Cramahe","csduid":"3514014","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Hamilton","csduid":"3514019","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Port Hope","csduid":"3514020","cmaname":"Port Hope","cmauid":"528","prname":"Ontar"},
    {"csdname":"Cobourg","csduid":"3514021","cmaname":"Cobourg","cmauid":"527","prname":"Ontar"},
    {"csdname":"Alnwick/Haldimand","csduid":"3514024","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Alderville First Nation","csduid":"3514027","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Trent Hills","csduid":"3514045","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Asphodel-Norwood","csduid":"3515003","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Otonabee-South Monaghan","csduid":"3515005","cmaname":"Peterborough","cmauid":"529","prname":"Ontar"},
    {"csdname":"Hiawatha First Nation","csduid":"3515008","cmaname":"Peterborough","cmauid":"529","prname":"Ontar"},
    {"csdname":"Cavan Monaghan","csduid":"3515013","cmaname":"Peterborough","cmauid":"529","prname":"Ontar"},
    {"csdname":"Peterborough","csduid":"3515014","cmaname":"Peterborough","cmauid":"529","prname":"Ontar"},
    {"csdname":"Selwyn","csduid":"3515015","cmaname":"Peterborough","cmauid":"529","prname":"Ontar"},
    {"csdname":"Curve Lake First Nation 35","csduid":"3515019","cmaname":"Peterborough","cmauid":"529","prname":"Ontar"},
    {"csdname":"Douro-Dummer","csduid":"3515023","cmaname":"Peterborough","cmauid":"529","prname":"Ontar"},
    {"csdname":"Havelock-Belmont-Methuen","csduid":"3515030","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"North Kawartha","csduid":"3515037","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Trent Lakes","csduid":"3515044","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Kawartha Lakes","csduid":"3516010","cmaname":"Kawartha Lakes","cmauid":"530","prname":"Ontar"},
    {"csdname":"Pickering","csduid":"3518001","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Ajax","csduid":"3518005","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Whitby","csduid":"3518009","cmaname":"Oshawa","cmauid":"532","prname":"Ontar"},
    {"csdname":"Oshawa","csduid":"3518013","cmaname":"Oshawa","cmauid":"532","prname":"Ontar"},
    {"csdname":"Clarington","csduid":"3518017","cmaname":"Oshawa","cmauid":"532","prname":"Ontar"},
    {"csdname":"Scugog","csduid":"3518020","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Mississaugas of Scugog Island","csduid":"3518022","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Uxbridge","csduid":"3518029","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Brock","csduid":"3518039","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Vaughan","csduid":"3519028","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Markham","csduid":"3519036","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Richmond Hill","csduid":"3519038","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Whitchurch-Stouffville","csduid":"3519044","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Aurora","csduid":"3519046","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Newmarket","csduid":"3519048","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"King","csduid":"3519049","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"East Gwillimbury","csduid":"3519054","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Georgina","csduid":"3519070","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Chippewas of Georgina Island First Nation","csduid":"3519076","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Toronto","csduid":"3520005","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Mississauga","csduid":"3521005","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Brampton","csduid":"3521010","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Caledon","csduid":"3521024","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"East Garafraxa","csduid":"3522001","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Amaranth","csduid":"3522008","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Grand Valley","csduid":"3522010","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Mono","csduid":"3522012","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Orangeville","csduid":"3522014","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Mulmur","csduid":"3522016","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Melancthon","csduid":"3522019","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Shelburne","csduid":"3522021","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Puslinch","csduid":"3523001","cmaname":"Guelph","cmauid":"550","prname":"Ontar"},
    {"csdname":"Guelph","csduid":"3523008","cmaname":"Guelph","cmauid":"550","prname":"Ontar"},
    {"csdname":"Guelph/Eramosa","csduid":"3523009","cmaname":"Guelph","cmauid":"550","prname":"Ontar"},
    {"csdname":"Erin","csduid":"3523017","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Centre Wellington","csduid":"3523025","cmaname":"Centre Wellington","cmauid":"531","prname":"Ontar"},
    {"csdname":"Mapleton","csduid":"3523033","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Minto","csduid":"3523043","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Wellington North","csduid":"3523050","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Oakville","csduid":"3524001","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Burlington","csduid":"3524002","cmaname":"Hamilton","cmauid":"537","prname":"Ontar"},
    {"csdname":"Milton","csduid":"3524009","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Halton Hills","csduid":"3524015","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Hamilton","csduid":"3525005","cmaname":"Hamilton","cmauid":"537","prname":"Ontar"},
    {"csdname":"Fort Erie","csduid":"3526003","cmaname":"St. Catharines - Niagara","cmauid":"539","prname":"Ontar"},
    {"csdname":"Port Colborne","csduid":"3526011","cmaname":"St. Catharines - Niagara","cmauid":"539","prname":"Ontar"},
    {"csdname":"Wainfleet","csduid":"3526014","cmaname":"St. Catharines - Niagara","cmauid":"539","prname":"Ontar"},
    {"csdname":"West Lincoln","csduid":"3526021","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Pelham","csduid":"3526028","cmaname":"St. Catharines - Niagara","cmauid":"539","prname":"Ontar"},
    {"csdname":"Welland","csduid":"3526032","cmaname":"St. Catharines - Niagara","cmauid":"539","prname":"Ontar"},
    {"csdname":"Thorold","csduid":"3526037","cmaname":"St. Catharines - Niagara","cmauid":"539","prname":"Ontar"},
    {"csdname":"Niagara Falls","csduid":"3526043","cmaname":"St. Catharines - Niagara","cmauid":"539","prname":"Ontar"},
    {"csdname":"Niagara-on-the-Lake","csduid":"3526047","cmaname":"St. Catharines - Niagara","cmauid":"539","prname":"Ontar"},
    {"csdname":"St. Catharines","csduid":"3526053","cmaname":"St. Catharines - Niagara","cmauid":"539","prname":"Ontar"},
    {"csdname":"Lincoln","csduid":"3526057","cmaname":"St. Catharines - Niagara","cmauid":"539","prname":"Ontar"},
    {"csdname":"Grimsby","csduid":"3526065","cmaname":"Hamilton","cmauid":"537","prname":"Ontar"},
    {"csdname":"Haldimand County","csduid":"3528018","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"New Credit (Part) 40A","csduid":"3528035","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Six Nations (Part) 40","csduid":"3528037","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Norfolk County","csduid":"3528052","cmaname":"Norfolk","cmauid":"547","prname":"Ontar"},
    {"csdname":"Brant","csduid":"3529005","cmaname":"Brantford","cmauid":"543","prname":"Ontar"},
    {"csdname":"Brantford","csduid":"3529006","cmaname":"Brantford","cmauid":"543","prname":"Ontar"},
    {"csdname":"Six Nations (Part) 40","csduid":"3529020","cmaname":"Brantford","cmauid":"543","prname":"Ontar"},
    {"csdname":"New Credit (Part) 40A","csduid":"3529021","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"North Dumfries","csduid":"3530004","cmaname":"Kitchener - Cambridge - Waterloo","cmauid":"541","prname":"Ontar"},
    {"csdname":"Cambridge","csduid":"3530010","cmaname":"Kitchener - Cambridge - Waterloo","cmauid":"541","prname":"Ontar"},
    {"csdname":"Kitchener","csduid":"3530013","cmaname":"Kitchener - Cambridge - Waterloo","cmauid":"541","prname":"Ontar"},
    {"csdname":"Waterloo","csduid":"3530016","cmaname":"Kitchener - Cambridge - Waterloo","cmauid":"541","prname":"Ontar"},
    {"csdname":"Wilmot","csduid":"3530020","cmaname":"Kitchener - Cambridge - Waterloo","cmauid":"541","prname":"Ontar"},
    {"csdname":"Wellesley","csduid":"3530027","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Woolwich","csduid":"3530035","cmaname":"Kitchener - Cambridge - Waterloo","cmauid":"541","prname":"Ontar"},
    {"csdname":"Stratford","csduid":"3531011","cmaname":"Stratford","cmauid":"553","prname":"Ontar"},
    {"csdname":"Perth South","csduid":"3531013","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"St. Marys","csduid":"3531016","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"West Perth","csduid":"3531025","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Perth East","csduid":"3531030","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"North Perth","csduid":"3531040","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Norwich","csduid":"3532002","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Tillsonburg","csduid":"3532004","cmaname":"Tillsonburg","cmauid":"546","prname":"Ontar"},
    {"csdname":"South-West Oxford","csduid":"3532012","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Ingersoll","csduid":"3532018","cmaname":"Ingersoll","cmauid":"533","prname":"Ontar"},
    {"csdname":"Zorra","csduid":"3532027","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"East Zorra-Tavistock","csduid":"3532038","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Woodstock","csduid":"3532042","cmaname":"Woodstock","cmauid":"544","prname":"Ontar"},
    {"csdname":"Blandford-Blenheim","csduid":"3532045","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Bayham","csduid":"3534005","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Malahide","csduid":"3534010","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Aylmer","csduid":"3534011","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Central Elgin","csduid":"3534020","cmaname":"London","cmauid":"555","prname":"Ontar"},
    {"csdname":"St. Thomas","csduid":"3534021","cmaname":"London","cmauid":"555","prname":"Ontar"},
    {"csdname":"Southwold","csduid":"3534024","cmaname":"London","cmauid":"555","prname":"Ontar"},
    {"csdname":"Dutton/Dunwich","csduid":"3534030","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"West Elgin","csduid":"3534042","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Chatham-Kent","csduid":"3536020","cmaname":"Chatham-Kent","cmauid":"556","prname":"Ontar"},
    {"csdname":"Moravian 47","csduid":"3536029","cmaname":"Chatham-Kent","cmauid":"556","prname":"Ontar"},
    {"csdname":"Pelee","csduid":"3537001","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Leamington","csduid":"3537003","cmaname":"Leamington","cmauid":"557","prname":"Ontar"},
    {"csdname":"Kingsville","csduid":"3537013","cmaname":"Leamington","cmauid":"557","prname":"Ontar"},
    {"csdname":"Essex","csduid":"3537016","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Amherstburg","csduid":"3537028","cmaname":"Windsor","cmauid":"559","prname":"Ontar"},
    {"csdname":"LaSalle","csduid":"3537034","cmaname":"Windsor","cmauid":"559","prname":"Ontar"},
    {"csdname":"Windsor","csduid":"3537039","cmaname":"Windsor","cmauid":"559","prname":"Ontar"},
    {"csdname":"Tecumseh","csduid":"3537048","cmaname":"Windsor","cmauid":"559","prname":"Ontar"},
    {"csdname":"Lakeshore","csduid":"3537064","cmaname":"Windsor","cmauid":"559","prname":"Ontar"},
    {"csdname":"St. Clair","csduid":"3538003","cmaname":"Sarnia","cmauid":"562","prname":"Ontar"},
    {"csdname":"Walpole Island 46","csduid":"3538004","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Dawn-Euphemia","csduid":"3538007","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Brooke-Alvinston","csduid":"3538015","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Enniskillen","csduid":"3538016","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Oil Springs","csduid":"3538018","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Petrolia","csduid":"3538019","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Sarnia 45","csduid":"3538025","cmaname":"Sarnia","cmauid":"562","prname":"Ontar"},
    {"csdname":"Sarnia","csduid":"3538030","cmaname":"Sarnia","cmauid":"562","prname":"Ontar"},
    {"csdname":"Point Edward","csduid":"3538031","cmaname":"Sarnia","cmauid":"562","prname":"Ontar"},
    {"csdname":"Plympton-Wyoming","csduid":"3538035","cmaname":"Sarnia","cmauid":"562","prname":"Ontar"},
    {"csdname":"Lambton Shores","csduid":"3538040","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Warwick","csduid":"3538043","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Kettle Point 44","csduid":"3538056","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Newbury","csduid":"3539002","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Southwest Middlesex","csduid":"3539005","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Strathroy-Caradoc","csduid":"3539015","cmaname":"London","cmauid":"555","prname":"Ontar"},
    {"csdname":"Chippewas of the Thames First Nation 42","csduid":"3539017","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Munsee-Delaware Nation 1","csduid":"3539018","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Oneida 41","csduid":"3539021","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Thames Centre","csduid":"3539027","cmaname":"London","cmauid":"555","prname":"Ontar"},
    {"csdname":"Middlesex Centre","csduid":"3539033","cmaname":"London","cmauid":"555","prname":"Ontar"},
    {"csdname":"London","csduid":"3539036","cmaname":"London","cmauid":"555","prname":"Ontar"},
    {"csdname":"North Middlesex","csduid":"3539041","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Adelaide-Metcalfe","csduid":"3539047","cmaname":"London","cmauid":"555","prname":"Ontar"},
    {"csdname":"Lucan Biddulph","csduid":"3539060","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"South Huron","csduid":"3540005","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Bluewater","csduid":"3540010","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Central Huron","csduid":"3540025","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Goderich","csduid":"3540028","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Huron East","csduid":"3540040","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Howick","csduid":"3540046","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Morris-Turnberry","csduid":"3540050","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"North Huron","csduid":"3540055","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Ashfield-Colborne-Wawanosh","csduid":"3540063","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"South Bruce","csduid":"3541004","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Huron-Kinloss","csduid":"3541015","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Kincardine","csduid":"3541024","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Brockton","csduid":"3541032","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Arran-Elderslie","csduid":"3541043","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Saugeen Shores","csduid":"3541045","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"South Bruce Peninsula","csduid":"3541055","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Chief's Point No. 28","csduid":"3541056","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Saugeen 29","csduid":"3541057","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Neyaashiinigmiing 27","csduid":"3541060","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Northern Bruce Peninsula","csduid":"3541069","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"West Grey","csduid":"3542004","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Southgate","csduid":"3542005","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Grey Highlands","csduid":"3542015","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Hanover","csduid":"3542029","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Chatsworth","csduid":"3542037","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"The Blue Mountains","csduid":"3542045","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Meaford","csduid":"3542047","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Georgian Bluffs","csduid":"3542053","cmaname":"Owen Sound","cmauid":"566","prname":"Ontar"},
    {"csdname":"Owen Sound","csduid":"3542059","cmaname":"Owen Sound","cmauid":"566","prname":"Ontar"},
    {"csdname":"Adjala-Tosorontio","csduid":"3543003","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Clearview","csduid":"3543005","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"New Tecumseth","csduid":"3543007","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Springwater","csduid":"3543009","cmaname":"Barrie","cmauid":"568","prname":"Ontar"},
    {"csdname":"Bradford West Gwillimbury","csduid":"3543014","cmaname":"Toronto","cmauid":"535","prname":"Ontar"},
    {"csdname":"Severn","csduid":"3543015","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Innisfil","csduid":"3543017","cmaname":"Barrie","cmauid":"568","prname":"Ontar"},
    {"csdname":"Ramara","csduid":"3543019","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Essa","csduid":"3543021","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Oro-Medonte","csduid":"3543023","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Collingwood","csduid":"3543031","cmaname":"Collingwood","cmauid":"567","prname":"Ontar"},
    {"csdname":"Barrie","csduid":"3543042","cmaname":"Barrie","cmauid":"568","prname":"Ontar"},
    {"csdname":"Mnjikaning First Nation 32","csduid":"3543050","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Orillia","csduid":"3543052","cmaname":"Orillia","cmauid":"569","prname":"Ontar"},
    {"csdname":"Wasaga Beach","csduid":"3543064","cmaname":"Wasaga Beach","cmauid":"565","prname":"Ontar"},
    {"csdname":"Tiny","csduid":"3543068","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Christian Island 30","csduid":"3543069","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Christian Island 30A","csduid":"3543070","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Tay","csduid":"3543071","cmaname":"Midland","cmauid":"571","prname":"Ontar"},
    {"csdname":"Penetanguishene","csduid":"3543072","cmaname":"Midland","cmauid":"571","prname":"Ontar"},
    {"csdname":"Midland","csduid":"3543074","cmaname":"Midland","cmauid":"571","prname":"Ontar"},
    {"csdname":"Gravenhurst","csduid":"3544002","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Bracebridge","csduid":"3544018","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Lake of Bays","csduid":"3544027","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Huntsville","csduid":"3544042","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Muskoka Lakes","csduid":"3544053","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Georgian Bay","csduid":"3544065","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Wahta Mohawk Territory","csduid":"3544071","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Moose Point 79","csduid":"3544073","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Highlands East","csduid":"3546005","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Minden Hills","csduid":"3546015","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Algonquin Highlands","csduid":"3546018","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Dysart et al","csduid":"3546024","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Arnprior","csduid":"3547002","cmaname":"Arnprior","cmauid":"507","prname":"Ontar"},
    {"csdname":"McNab/Braeside","csduid":"3547003","cmaname":"Arnprior","cmauid":"507","prname":"Ontar"},
    {"csdname":"Greater Madawaska","csduid":"3547008","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Brudenell, Lyndoch and Raglan","csduid":"3547020","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Madawaska Valley","csduid":"3547030","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Killaloe, Hagarty and Richards","csduid":"3547033","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Bonnechere Valley","csduid":"3547035","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Pikwakanagan (Golden Lake 39)","csduid":"3547037","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Admaston/Bromley","csduid":"3547043","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Horton","csduid":"3547046","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Renfrew","csduid":"3547048","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Whitewater Region","csduid":"3547056","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Pembroke","csduid":"3547064","cmaname":"Pembroke","cmauid":"515","prname":"Ontar"},
    {"csdname":"North Algona Wilberforce","csduid":"3547070","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Laurentian Valley","csduid":"3547075","cmaname":"Pembroke","cmauid":"515","prname":"Ontar"},
    {"csdname":"Petawawa","csduid":"3547076","cmaname":"Petawawa","cmauid":"516","prname":"Ontar"},
    {"csdname":"Laurentian Hills","csduid":"3547090","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Deep River","csduid":"3547096","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Head, Clara and Maria","csduid":"3547098","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"South Algonquin","csduid":"3548001","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Papineau-Cameron","csduid":"3548013","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Mattawan","csduid":"3548019","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Mattawa","csduid":"3548021","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Calvin","csduid":"3548022","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Bonfield","csduid":"3548027","cmaname":"North Bay","cmauid":"575","prname":"Ontar"},
    {"csdname":"Chisholm","csduid":"3548031","cmaname":"North Bay","cmauid":"575","prname":"Ontar"},
    {"csdname":"East Ferris","csduid":"3548034","cmaname":"North Bay","cmauid":"575","prname":"Ontar"},
    {"csdname":"North Bay","csduid":"3548044","cmaname":"North Bay","cmauid":"575","prname":"Ontar"},
    {"csdname":"West Nipissing / Nipissing Ouest","csduid":"3548055","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Temagami","csduid":"3548069","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Bear Island 1","csduid":"3548072","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Nipissing 10","csduid":"3548073","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Nipissing, Unorganized, South Part","csduid":"3548091","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Nipissing, Unorganized, North Part","csduid":"3548094","cmaname":"North Bay","cmauid":"575","prname":"Ontar"},
    {"csdname":"Seguin","csduid":"3549003","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"The Archipelago","csduid":"3549005","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"McMurrich/Monteith","csduid":"3549012","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Perry","csduid":"3549014","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Kearney","csduid":"3549018","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Armour","csduid":"3549019","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Burk's Falls","csduid":"3549022","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Ryerson","csduid":"3549024","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"McKellar","csduid":"3549028","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"McDougall","csduid":"3549031","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Parry Sound","csduid":"3549032","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Carling","csduid":"3549036","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Whitestone","csduid":"3549039","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Magnetawan","csduid":"3549043","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Strong","csduid":"3549046","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Sundridge","csduid":"3549048","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Joly","csduid":"3549051","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Machar","csduid":"3549054","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"South River","csduid":"3549056","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Powassan","csduid":"3549060","cmaname":"North Bay","cmauid":"575","prname":"Ontar"},
    {"csdname":"Callander","csduid":"3549066","cmaname":"North Bay","cmauid":"575","prname":"Ontar"},
    {"csdname":"Nipissing","csduid":"3549071","cmaname":"North Bay","cmauid":"575","prname":"Ontar"},
    {"csdname":"Shawanaga 17","csduid":"3549072","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Parry Island First Nation","csduid":"3549073","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Henvey Inlet 2","csduid":"3549075","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"French River 13","csduid":"3549076","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Dokis 9","csduid":"3549077","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Magnetewan 1","csduid":"3549078","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Parry Sound, Unorganized, North East Part","csduid":"3549095","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Parry Sound, Unorganized, Centre Part","csduid":"3549096","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Tehkummah","csduid":"3551001","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Central Manitoulin","csduid":"3551006","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Assiginack","csduid":"3551011","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Northeastern Manitoulin and the Islands","csduid":"3551017","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Billings","csduid":"3551021","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Gore Bay","csduid":"3551026","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Gordon/Barrie Island","csduid":"3551027","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Burpee and Mills","csduid":"3551028","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Cockburn Island","csduid":"3551034","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Whitefish River (Part) 4","csduid":"3551040","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Sucker Creek 23","csduid":"3551041","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Sheguiandah 24","csduid":"3551042","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Wikwemikong Unceded","csduid":"3551043","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Sheshegwaning 20","csduid":"3551044","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"M'Chigeeng 22 (West Bay 22)","csduid":"3551045","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Manitoulin, Unorganized, West Part","csduid":"3551094","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Zhiibaahaasing 19A (Cockburn Island 19A)","csduid":"3551100","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"French River / Rivière des Français","csduid":"3552001","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"St.-Charles","csduid":"3552004","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Markstay-Warren","csduid":"3552013","cmaname":"Greater Sudbury / Grand Sudbury","cmauid":"580","prname":"Ontar"},
    {"csdname":"Sables-Spanish Rivers","csduid":"3552023","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Espanola","csduid":"3552026","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Baldwin","csduid":"3552028","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Nairn and Hyman","csduid":"3552031","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Killarney","csduid":"3552036","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Whitefish Lake 6","csduid":"3552051","cmaname":"Greater Sudbury / Grand Sudbury","cmauid":"580","prname":"Ontar"},
    {"csdname":"Mattagami 71","csduid":"3552052","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Chapleau 74A","csduid":"3552053","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Duck Lake 76B","csduid":"3552054","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Chapleau 75","csduid":"3552058","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Chapleau","csduid":"3552092","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Sudbury, Unorganized, North Part","csduid":"3552093","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Greater Sudbury / Grand Sudbury","csduid":"3553005","cmaname":"Greater Sudbury / Grand Sudbury","cmauid":"580","prname":"Ontar"},
    {"csdname":"Wahnapitei 11","csduid":"3553040","cmaname":"Greater Sudbury / Grand Sudbury","cmauid":"580","prname":"Ontar"},
    {"csdname":"Coleman","csduid":"3554001","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Latchford","csduid":"3554006","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Cobalt","csduid":"3554008","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Harris","csduid":"3554014","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Temiskaming Shores","csduid":"3554020","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Hudson","csduid":"3554021","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Kerns","csduid":"3554024","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Harley","csduid":"3554026","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Casey","csduid":"3554029","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Brethour","csduid":"3554032","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Hilliard","csduid":"3554034","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Armstrong","csduid":"3554036","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Thornloe","csduid":"3554038","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"James","csduid":"3554042","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Charlton and Dack","csduid":"3554044","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Evanturel","csduid":"3554049","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Englehart","csduid":"3554052","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Chamberlain","csduid":"3554054","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Matachewan","csduid":"3554056","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Matachewan 72","csduid":"3554057","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"McGarry","csduid":"3554058","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Larder Lake","csduid":"3554062","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Gauthier","csduid":"3554066","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Kirkland Lake","csduid":"3554068","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Timiskaming, Unorganized, East Part","csduid":"3554091","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Timiskaming, Unorganized, West Part","csduid":"3554094","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Black River-Matheson","csduid":"3556014","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Timmins","csduid":"3556027","cmaname":"Timmins","cmauid":"586","prname":"Ontar"},
    {"csdname":"Iroquois Falls","csduid":"3556031","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Abitibi 70","csduid":"3556033","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Cochrane","csduid":"3556042","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Smooth Rock Falls","csduid":"3556048","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Fauquier-Strickland","csduid":"3556052","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Moonbeam","csduid":"3556056","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Kapuskasing","csduid":"3556066","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Val Rita-Harty","csduid":"3556070","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Opasatika","csduid":"3556073","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Hearst","csduid":"3556076","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Mattice-Val Côté","csduid":"3556077","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Cochrane, Unorganized, South West Part","csduid":"3556091","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Cochrane, Unorganized, North Part","csduid":"3556092","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Fort Albany (Part) 67","csduid":"3556093","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Factory Island 1","csduid":"3556094","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Constance Lake 92","csduid":"3556095","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Moose Factory 68","csduid":"3556096","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Cochrane, Unorganized, South East Part","csduid":"3556098","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Flying Post 73","csduid":"3556100","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"New Post 69A","csduid":"3556102","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Moosonee","csduid":"3556106","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Jocelyn","csduid":"3557001","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Hilton","csduid":"3557004","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Hilton Beach","csduid":"3557006","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"St. Joseph","csduid":"3557008","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Laird","csduid":"3557011","cmaname":"Sault Ste. Marie","cmauid":"590","prname":"Ontar"},
    {"csdname":"Tarbutt and Tarbutt Additional","csduid":"3557014","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Johnson","csduid":"3557016","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Plummer Additional","csduid":"3557019","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Bruce Mines","csduid":"3557021","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Thessalon 12","csduid":"3557026","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Thessalon","csduid":"3557028","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Huron Shores","csduid":"3557035","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Blind River","csduid":"3557038","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Spanish","csduid":"3557039","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"The North Shore","csduid":"3557040","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Elliot Lake","csduid":"3557041","cmaname":"Elliot Lake","cmauid":"582","prname":"Ontar"},
    {"csdname":"Macdonald, Meredith and Aberdeen Additional","csduid":"3557051","cmaname":"Sault Ste. Marie","cmauid":"590","prname":"Ontar"},
    {"csdname":"Sault Ste. Marie","csduid":"3557061","cmaname":"Sault Ste. Marie","cmauid":"590","prname":"Ontar"},
    {"csdname":"Prince","csduid":"3557066","cmaname":"Sault Ste. Marie","cmauid":"590","prname":"Ontar"},
    {"csdname":"Sagamok","csduid":"3557071","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Serpent River 7","csduid":"3557072","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Mississagi River 8","csduid":"3557073","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Garden River 14","csduid":"3557074","cmaname":"Sault Ste. Marie","cmauid":"590","prname":"Ontar"},
    {"csdname":"Rankin Location 15D","csduid":"3557075","cmaname":"Sault Ste. Marie","cmauid":"590","prname":"Ontar"},
    {"csdname":"Wawa","csduid":"3557076","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Goulais Bay 15A","csduid":"3557077","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Gros Cap 49","csduid":"3557078","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Dubreuilville","csduid":"3557079","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"White River","csduid":"3557091","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Algoma, Unorganized, South East Part","csduid":"3557094","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Algoma, Unorganized, North Part","csduid":"3557095","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Hornepayne","csduid":"3557096","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Neebing","csduid":"3558001","cmaname":"Thunder Bay","cmauid":"595","prname":"Ontar"},
    {"csdname":"Fort William 52","csduid":"3558003","cmaname":"Thunder Bay","cmauid":"595","prname":"Ontar"},
    {"csdname":"Thunder Bay","csduid":"3558004","cmaname":"Thunder Bay","cmauid":"595","prname":"Ontar"},
    {"csdname":"Oliver Paipoonge","csduid":"3558011","cmaname":"Thunder Bay","cmauid":"595","prname":"Ontar"},
    {"csdname":"Gillies","csduid":"3558012","cmaname":"Thunder Bay","cmauid":"595","prname":"Ontar"},
    {"csdname":"O'Connor","csduid":"3558016","cmaname":"Thunder Bay","cmauid":"595","prname":"Ontar"},
    {"csdname":"Conmee","csduid":"3558019","cmaname":"Thunder Bay","cmauid":"595","prname":"Ontar"},
    {"csdname":"Shuniah","csduid":"3558028","cmaname":"Thunder Bay","cmauid":"595","prname":"Ontar"},
    {"csdname":"Dorion","csduid":"3558034","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Red Rock","csduid":"3558041","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Nipigon","csduid":"3558044","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Schreiber","csduid":"3558051","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Terrace Bay","csduid":"3558054","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Marathon","csduid":"3558059","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Pic Mobert North","csduid":"3558060","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Pic Mobert South","csduid":"3558061","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Pic River 50","csduid":"3558062","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Pays Plat 51","csduid":"3558063","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Lake Helen 53A","csduid":"3558064","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Gull River 55","csduid":"3558065","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Manitouwadge","csduid":"3558066","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Ginoogaming First Nation","csduid":"3558067","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Long Lake 58","csduid":"3558068","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Rocky Bay 1","csduid":"3558069","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Greenstone","csduid":"3558075","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Aroland 83","csduid":"3558076","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Lake Nipigon","csduid":"3558077","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Ojibway Nation of Saugeen (Savant Lake)","csduid":"3558080","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Osnaburgh 63A","csduid":"3558085","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Thunder Bay, Unorganized","csduid":"3558090","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Seine River 22A2","csduid":"3558095","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Whitesand","csduid":"3558097","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Lac des Mille Lacs 22A1","csduid":"3558100","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Atikokan","csduid":"3559001","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Alberton","csduid":"3559011","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Fort Frances","csduid":"3559012","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"La Vallee","csduid":"3559016","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Emo","csduid":"3559019","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Chapple","csduid":"3559024","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Manitou Rapids 11","csduid":"3559026","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Long Sault 12","csduid":"3559030","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Morley","csduid":"3559031","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Dawson","csduid":"3559040","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Rainy River","csduid":"3559042","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Lake of the Woods","csduid":"3559047","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Sabaskong Bay (Part) 35C","csduid":"3559048","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Big Grassy River 35G","csduid":"3559051","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Big Island Mainland 93","csduid":"3559052","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Saug-a-Gaw-Sing 1","csduid":"3559053","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Neguaguon Lake 25D","csduid":"3559060","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Rainy Lake 18C","csduid":"3559061","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Agency 1","csduid":"3559062","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Couchiching 16A","csduid":"3559063","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Rainy Lake 26A","csduid":"3559064","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Seine River 23B","csduid":"3559065","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Seine River 23A","csduid":"3559066","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Rainy Lake 17A","csduid":"3559068","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Rainy Lake 17B","csduid":"3559069","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Rainy River, Unorganized","csduid":"3559090","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Ignace","csduid":"3560001","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Whitefish Bay 32A","csduid":"3560004","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Whitefish Bay 33A","csduid":"3560005","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Sabaskong Bay (Part) 35C","csduid":"3560007","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Sioux Narrows-Nestor Falls","csduid":"3560008","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Kenora","csduid":"3560010","cmaname":"Kenora","cmauid":"598","prname":"Ontar"},
    {"csdname":"Machin","csduid":"3560021","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Eagle Lake 27","csduid":"3560024","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Dryden","csduid":"3560027","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Ear Falls","csduid":"3560032","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Sioux Lookout","csduid":"3560034","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Red Lake","csduid":"3560042","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Slate Falls","csduid":"3560046","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Pickle Lake","csduid":"3560049","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Fort Albany (Part) 67","csduid":"3560050","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Attawapiskat 91A","csduid":"3560051","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Marten Falls 65","csduid":"3560052","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Fort Hope 64","csduid":"3560053","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Cat Lake 63C","csduid":"3560054","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Osnaburgh 63B","csduid":"3560055","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Lac Seul 28","csduid":"3560056","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Wabigoon Lake 27","csduid":"3560057","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"English River 21","csduid":"3560058","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Weagamow Lake 87","csduid":"3560059","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Northwest Angle 33B","csduid":"3560060","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Wabaseemoong","csduid":"3560061","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Sabaskong Bay 35D","csduid":"3560063","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Shoal Lake 34B2","csduid":"3560064","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Lake Of The Woods 37","csduid":"3560065","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Kenora 38B","csduid":"3560066","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Poplar Hill","csduid":"3560067","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Shoal Lake (Part) 39A","csduid":"3560068","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Rat Portage 38A","csduid":"3560069","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Deer Lake","csduid":"3560070","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Sandy Lake 88","csduid":"3560071","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Kitchenuhmaykoosib Aaki 84 (Big Trout Lake)","csduid":"3560075","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Sachigo Lake 1","csduid":"3560076","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Pikangikum 14","csduid":"3560077","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Fort Severn 89","csduid":"3560078","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Webequie","csduid":"3560079","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"North Spirit Lake","csduid":"3560080","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Lansdowne House","csduid":"3560081","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Shoal Lake (Part) 40","csduid":"3560082","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Whitefish Bay 34A","csduid":"3560083","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Wabauskang 21","csduid":"3560084","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Wunnumin 1","csduid":"3560085","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Summer Beaver","csduid":"3560086","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Wapekeka 2","csduid":"3560088","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"The Dalles 38C","csduid":"3560089","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Kenora, Unorganized","csduid":"3560090","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Peawanuck","csduid":"3560091","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Neskantaga","csduid":"3560093","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Bearskin Lake","csduid":"3560095","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Kasabonika Lake","csduid":"3560096","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Muskrat Dam Lake","csduid":"3560097","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Kingfisher Lake 1","csduid":"3560098","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Wawakapewin (Long Dog Lake)","csduid":"3560100","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"McDowell Lake","csduid":"3560102","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Kee-Way-Win","csduid":"3560104","cmaname":"","cmauid":"","prname":"Ontar"},
    {"csdname":"Stuartburn","csduid":"4601035","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Piney","csduid":"4601039","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Reynolds","csduid":"4601043","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Whitemouth","csduid":"4601046","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Pinawa","csduid":"4601051","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Lac du Bonnet","csduid":"4601057","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Lac du Bonnet","csduid":"4601060","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Buffalo Point 36","csduid":"4601070","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Alexander","csduid":"4601071","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Powerview-Pine Falls","csduid":"4601075","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Victoria Beach","csduid":"4601078","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Shoal Lake (Part) 40","csduid":"4601079","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Shoal Lake (Part) 39A","csduid":"4601082","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Division No.  1, Unorganized","csduid":"4601094","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Emerson-Franklin","csduid":"4602024","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Roseau Rapids 2A","csduid":"4602026","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Roseau River 2","csduid":"4602027","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"De Salaberry","csduid":"4602032","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"St-Pierre-Jolys","csduid":"4602037","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Hanover","csduid":"4602041","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Steinbach","csduid":"4602044","cmaname":"Steinbach","cmauid":"605","prname":"Manito"},
    {"csdname":"Niverville","csduid":"4602046","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"La Broquerie","csduid":"4602053","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Ste. Anne","csduid":"4602057","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Ste. Anne","csduid":"4602061","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Taché","csduid":"4602069","cmaname":"Winnipeg","cmauid":"602","prname":"Manito"},
    {"csdname":"Ritchot","csduid":"4602075","cmaname":"Winnipeg","cmauid":"602","prname":"Manito"},
    {"csdname":"Montcalm","csduid":"4603030","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Altona","csduid":"4603040","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Rhineland","csduid":"4603041","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Stanley","csduid":"4603047","cmaname":"Winkler","cmauid":"603","prname":"Manito"},
    {"csdname":"Winkler","csduid":"4603050","cmaname":"Winkler","cmauid":"603","prname":"Manito"},
    {"csdname":"Morden","csduid":"4603053","cmaname":"Winkler","cmauid":"603","prname":"Manito"},
    {"csdname":"Thompson","csduid":"4603058","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Roland","csduid":"4603062","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Morris","csduid":"4603065","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Morris","csduid":"4603067","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Dufferin","csduid":"4603072","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Carman","csduid":"4603074","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Pembina","csduid":"4604034","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Louise","csduid":"4604040","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Cartwright-Roblin","csduid":"4604052","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Argyle","csduid":"4604057","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Lorne","csduid":"4604064","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Swan Lake  7","csduid":"4604068","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Killarney - Turtle Mountain","csduid":"4605025","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Boissevain-Morton","csduid":"4605032","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Deloraine-Winchester","csduid":"4605038","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Brenda-Waskada","csduid":"4605044","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Melita","csduid":"4605052","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Two Borders","csduid":"4605056","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Grassland","csduid":"4605062","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Prairie Lakes","csduid":"4605071","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Sifton","csduid":"4606016","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Pipestone","csduid":"4606023","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Canupawakpa Dakota First Nation (Oak Lake 59)","csduid":"4606029","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Wallace-Woodworth","csduid":"4606031","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Virden","csduid":"4606034","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Sioux Valley Dakota Nation","csduid":"4606040","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Glenboro-South Cypress","csduid":"4607039","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Oakland-Wawanesa","csduid":"4607046","cmaname":"Brandon","cmauid":"610","prname":"Manito"},
    {"csdname":"Souris-Glenwood","csduid":"4607052","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Whitehead","csduid":"4607057","cmaname":"Brandon","cmauid":"610","prname":"Manito"},
    {"csdname":"Cornwallis","csduid":"4607060","cmaname":"Brandon","cmauid":"610","prname":"Manito"},
    {"csdname":"Brandon","csduid":"4607062","cmaname":"Brandon","cmauid":"610","prname":"Manito"},
    {"csdname":"North Cypress-Langford","csduid":"4607066","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Carberry","csduid":"4607068","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Elton","csduid":"4607071","cmaname":"Brandon","cmauid":"610","prname":"Manito"},
    {"csdname":"Riverdale","csduid":"4607076","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Norfolk-Treherne","csduid":"4608032","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Victoria","csduid":"4608042","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"North Norfolk","csduid":"4608046","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Glenella-Lansdowne","csduid":"4608055","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"WestLake-Gladstone","csduid":"4608060","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Sandy Bay 5","csduid":"4608069","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Grey","csduid":"4609018","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Portage la Prairie","csduid":"4609024","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Long Plain (Part) 6","csduid":"4609025","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Dakota Plains 6A","csduid":"4609026","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Dakota Tipi 1","csduid":"4609027","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Portage la Prairie","csduid":"4609029","cmaname":"Portage la Prairie","cmauid":"607","prname":"Manito"},
    {"csdname":"Macdonald","csduid":"4610035","cmaname":"Winnipeg","cmauid":"602","prname":"Manito"},
    {"csdname":"Cartier","csduid":"4610043","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"St. François Xavier","csduid":"4610052","cmaname":"Winnipeg","cmauid":"602","prname":"Manito"},
    {"csdname":"Winnipeg","csduid":"4611040","cmaname":"Winnipeg","cmauid":"602","prname":"Manito"},
    {"csdname":"Headingley","csduid":"4611042","cmaname":"Winnipeg","cmauid":"602","prname":"Manito"},
    {"csdname":"Springfield","csduid":"4612047","cmaname":"Winnipeg","cmauid":"602","prname":"Manito"},
    {"csdname":"Brokenhead","csduid":"4612054","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Beausejour","csduid":"4612056","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"East St. Paul","csduid":"4613032","cmaname":"Winnipeg","cmauid":"602","prname":"Manito"},
    {"csdname":"West St. Paul","csduid":"4613037","cmaname":"Winnipeg","cmauid":"602","prname":"Manito"},
    {"csdname":"St. Andrews","csduid":"4613043","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Selkirk","csduid":"4613047","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Dunnottar","csduid":"4613049","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"St. Clements","csduid":"4613056","cmaname":"Winnipeg","cmauid":"602","prname":"Manito"},
    {"csdname":"Brokenhead 4","csduid":"4613062","cmaname":"Winnipeg","cmauid":"602","prname":"Manito"},
    {"csdname":"Rosser","csduid":"4614015","cmaname":"Winnipeg","cmauid":"602","prname":"Manito"},
    {"csdname":"Woodlands","csduid":"4614031","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Rockwood","csduid":"4614036","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Stonewall","csduid":"4614039","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Teulon","csduid":"4614042","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Neepawa","csduid":"4615020","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Oakview","csduid":"4615032","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Hamiota","csduid":"4615037","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Birdtail Creek 57","csduid":"4615042","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Ellice-Archie","csduid":"4615047","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Gambler 63 (Part)","csduid":"4615049","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Prairie View","csduid":"4615056","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Yellowhead","csduid":"4615063","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Keeseekoowenin 61","csduid":"4615067","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Harrison Park","csduid":"4615070","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Rolling River 67","csduid":"4615071","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Rolling River 67B","csduid":"4615072","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Minto-Odanah","csduid":"4615074","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Minnedosa","csduid":"4615075","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Rosedale","csduid":"4615078","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Clanwilliam-Erickson","csduid":"4615092","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Rossburn","csduid":"4616005","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Waywayseecappo First Nation","csduid":"4616017","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Riding Mountain West","csduid":"4616020","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Gambler 63 (Part)","csduid":"4616025","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Russell-Binscarth","csduid":"4616030","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Valley River 63A","csduid":"4616046","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Hillsburg-Roblin-Shell River","csduid":"4616048","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Alonsa","csduid":"4617026","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Ebb and Flow 52","csduid":"4617029","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"McCreary","csduid":"4617035","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Ste. Rose","csduid":"4617041","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Dauphin","csduid":"4617048","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Dauphin","csduid":"4617050","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Gilbert Plains","csduid":"4617054","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Grandview","csduid":"4617058","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Ethelbert","csduid":"4617064","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Mossey River","csduid":"4617072","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Lakeshore","csduid":"4617075","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Division No. 17, Unorganized","csduid":"4617092","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Gimli","csduid":"4618031","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Winnipeg Beach","csduid":"4618034","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Armstrong","csduid":"4618037","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"St. Laurent","csduid":"4618040","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Coldwell","csduid":"4618044","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Dog Creek 46","csduid":"4618055","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"West Interlake","csduid":"4618056","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Grahamdale","csduid":"4618060","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Little Saskatchewan 48","csduid":"4618063","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Fairford (Part) 50","csduid":"4618067","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Fisher","csduid":"4618068","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Arborg","csduid":"4618074","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Bifrost-Riverton","csduid":"4618076","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Division No. 18, Unorganized, East Part","csduid":"4618091","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Division No. 18, Unorganized, West Part","csduid":"4618093","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Division No. 19, Unorganized","csduid":"4619045","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Fort Alexander 3","csduid":"4619050","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Little Grand Rapids 14","csduid":"4619051","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Bloodvein 12","csduid":"4619052","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Hole or Hollow Water 10","csduid":"4619053","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Black River 9","csduid":"4619054","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Fisher River 44","csduid":"4619056","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Fisher River 44A","csduid":"4619058","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Peguis 1B","csduid":"4619059","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Fairford (Part) 50","csduid":"4619060","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Jackhead 43","csduid":"4619061","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Dauphin River 48A","csduid":"4619062","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"The Narrows 49","csduid":"4619065","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Crane River 51","csduid":"4619068","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Waterhen 45","csduid":"4619069","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Pine Creek 66A","csduid":"4619070","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Swan Lake 65C","csduid":"4619071","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Shoal River Indian Reserve 65A","csduid":"4619072","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Poplar River 16","csduid":"4619075","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Berens River 13","csduid":"4619077","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Pauingassi First Nation","csduid":"4619079","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Chemawawin 3","csduid":"4619082","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Mountain (South)","csduid":"4620032","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Swan Valley West","csduid":"4620042","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Swan River","csduid":"4620048","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Minitonas-Bowsman","csduid":"4620051","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Mountain (North)","csduid":"4620055","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Division No. 20, Unorganized, South Part","csduid":"4620066","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Division No. 20, Unorganized, North Part","csduid":"4620069","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Grand Rapids","csduid":"4621025","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Grand Rapids 33","csduid":"4621027","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Chemawawin 2","csduid":"4621029","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Opaskwayak Cree Nation 21A","csduid":"4621033","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Opaskwayak Cree Nation 21B","csduid":"4621034","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Opaskwayak Cree Nation 21C","csduid":"4621035","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Opaskwayak Cree Nation 21I","csduid":"4621040","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Opaskwayak Cree Nation 21E","csduid":"4621043","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"The Pas","csduid":"4621045","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Kelsey","csduid":"4621052","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Moose Lake 31A","csduid":"4621058","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Flin Flon (Part)","csduid":"4621064","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Snow Lake","csduid":"4621071","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Division No. 21, Unorganized","csduid":"4621078","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Mystery Lake","csduid":"4622017","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Thompson","csduid":"4622026","cmaname":"Thompson","cmauid":"640","prname":"Manito"},
    {"csdname":"Division No. 22, Unorganized","csduid":"4622046","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Garden Hill First Nation","csduid":"4622048","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"God's Lake 23","csduid":"4622049","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Oxford House 24","csduid":"4622050","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Cross Lake 19","csduid":"4622051","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Cross Lake 19A","csduid":"4622052","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"God's River 86A","csduid":"4622055","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Red Sucker Lake 1976","csduid":"4622056","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Norway House 17","csduid":"4622058","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Nelson House 170","csduid":"4622059","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Split Lake (Part) 171","csduid":"4622063","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Ilford","csduid":"4622064","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"York Landing","csduid":"4622065","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Cross Lake 19E","csduid":"4622067","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Wasagamack","csduid":"4622800","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"St. Theresa Point","csduid":"4622801","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Mooseocoot","csduid":"4622802","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Gillam","csduid":"4623022","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Gillam","csduid":"4623025","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Fox Lake 2","csduid":"4623027","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Leaf Rapids","csduid":"4623034","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"South Indian Lake","csduid":"4623037","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Granville Lake","csduid":"4623039","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Lynn Lake","csduid":"4623047","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Churchill","csduid":"4623056","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Churchill 1","csduid":"4623058","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Split Lake (Part) 171","csduid":"4623060","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Division No. 23, Unorganized","csduid":"4623062","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Pukatawagan 198","csduid":"4623064","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Brochet 197","csduid":"4623065","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Lac Brochet 197A","csduid":"4623067","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Shamattawa 1","csduid":"4623071","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Black Sturgeon","csduid":"4623800","cmaname":"","cmauid":"","prname":"Manito"},
    {"csdname":"Argyle No. 1","csduid":"4701001","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Gainsborough","csduid":"4701002","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Carievale","csduid":"4701004","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mount Pleasant No. 2","csduid":"4701006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Carnduff","csduid":"4701008","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Enniskillen No. 3","csduid":"4701011","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Glen Ewen","csduid":"4701012","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Oxbow","csduid":"4701014","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Coalfields No. 4","csduid":"4701016","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Frobisher","csduid":"4701017","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"North Portal","csduid":"4701018","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Roche Percée","csduid":"4701019","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bienfait","csduid":"4701021","cmaname":"Estevan","cmauid":"750","prname":"Saskatchew"},
    {"csdname":"Estevan No. 5","csduid":"4701022","cmaname":"Estevan","cmauid":"750","prname":"Saskatchew"},
    {"csdname":"Estevan","csduid":"4701024","cmaname":"Estevan","cmauid":"750","prname":"Saskatchew"},
    {"csdname":"Benson No. 35","csduid":"4701027","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Browning No. 34","csduid":"4701031","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lampman","csduid":"4701032","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Moose Creek No. 33","csduid":"4701036","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Alameda","csduid":"4701037","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Reciprocity No. 32","csduid":"4701039","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Alida","csduid":"4701041","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Storthoaks No. 31","csduid":"4701043","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Storthoaks","csduid":"4701044","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Antler No. 61","csduid":"4701047","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Redvers","csduid":"4701049","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Moose Mountain No. 63","csduid":"4701053","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Manor","csduid":"4701054","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Carlyle","csduid":"4701056","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Brock No. 64","csduid":"4701058","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Arcola","csduid":"4701059","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kisbey","csduid":"4701061","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Tecumseh No. 65","csduid":"4701063","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Forget","csduid":"4701064","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Stoughton","csduid":"4701066","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Heward","csduid":"4701067","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Golden West No. 95","csduid":"4701069","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hazelwood No. 94","csduid":"4701072","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wawken No. 93","csduid":"4701076","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kennedy","csduid":"4701077","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wawota","csduid":"4701078","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kenosee Lake","csduid":"4701079","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Walpole No. 92","csduid":"4701091","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Maryfield No. 91","csduid":"4701094","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Maryfield","csduid":"4701096","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fairlight","csduid":"4701098","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"White Bear 70","csduid":"4701808","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ocean Man 69","csduid":"4701809","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ocean Man 69A","csduid":"4701810","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ocean Man 69C","csduid":"4701812","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ocean Man 69E","csduid":"4701813","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ocean Man 69F","csduid":"4701814","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ocean Man 69G","csduid":"4701815","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ocean Man 69I","csduid":"4701816","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ocean Man 69H","csduid":"4701817","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ocean Man 69D","csduid":"4701818","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pheasant Rump Nakota 68","csduid":"4701819","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cambria No. 6","csduid":"4702001","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Torquay","csduid":"4702002","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Souris Valley No. 7","csduid":"4702006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Tribune","csduid":"4702008","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lake Alma No. 8","csduid":"4702011","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lake Alma","csduid":"4702012","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Surprise Valley No. 9","csduid":"4702014","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Minton","csduid":"4702016","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Happy Valley No. 10","csduid":"4702018","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bengough","csduid":"4702023","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bengough No. 40","csduid":"4702024","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"The Gap No. 39","csduid":"4702026","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ceylon","csduid":"4702028","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Laurier No. 38","csduid":"4702029","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Radville","csduid":"4702031","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lomond No. 37","csduid":"4702033","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Goodwater","csduid":"4702036","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cymri No. 36","csduid":"4702037","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Halbrite","csduid":"4702038","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Midale","csduid":"4702039","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Macoun","csduid":"4702041","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Griffin No. 66","csduid":"4702042","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Weyburn No. 67","csduid":"4702044","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Weyburn","csduid":"4702047","cmaname":"Weyburn","cmauid":"755","prname":"Saskatchew"},
    {"csdname":"McTaggart","csduid":"4702048","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Brokenshell No. 68","csduid":"4702051","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Norton No. 69","csduid":"4702052","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pangman","csduid":"4702054","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Key West No. 70","csduid":"4702057","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ogema","csduid":"4702058","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Elmsthorpe No. 100","csduid":"4702061","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Avonlea","csduid":"4702062","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Caledonia No. 99","csduid":"4702066","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Milestone","csduid":"4702067","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Scott No. 98","csduid":"4702069","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lang","csduid":"4702071","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Yellow Grass","csduid":"4702072","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wellington No. 97","csduid":"4702073","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fillmore No. 96","csduid":"4702076","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Osage","csduid":"4702077","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fillmore","csduid":"4702078","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Creelman","csduid":"4702079","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Piapot Cree First Nation 75H","csduid":"4702800","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hart Butte No. 11","csduid":"4703001","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Coronach","csduid":"4703004","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Poplar Valley No. 12","csduid":"4703006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rockglen","csduid":"4703009","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Old Post No. 43","csduid":"4703011","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wood Mountain","csduid":"4703012","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Glen McPherson No. 46","csduid":"4703016","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mankota No. 45","csduid":"4703018","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mankota","csduid":"4703019","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Waverley No. 44","csduid":"4703022","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Willow Bunch No. 42","csduid":"4703026","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Willow Bunch","csduid":"4703028","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Excel No. 71","csduid":"4703029","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lake of the Rivers No. 72","csduid":"4703034","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Assiniboia","csduid":"4703036","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Stonehenge No. 73","csduid":"4703038","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Limerick","csduid":"4703041","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wood River No. 74","csduid":"4703042","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lafleche","csduid":"4703044","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pinto Creek No. 75","csduid":"4703048","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kincaid","csduid":"4703051","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hazenmore","csduid":"4703052","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Auvergne No. 76","csduid":"4703054","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ponteix","csduid":"4703058","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Whiska Creek No. 106","csduid":"4703059","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Neville","csduid":"4703061","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Vanguard","csduid":"4703062","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Glen Bain No. 105","csduid":"4703064","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Gravelbourg No. 104","csduid":"4703068","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Gravelbourg","csduid":"4703071","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sutton No. 103","csduid":"4703074","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mossbank","csduid":"4703092","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lake Johnston No. 102","csduid":"4703093","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Terrell No. 101","csduid":"4703096","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wood Mountain 160","csduid":"4703801","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Val Marie","csduid":"4704002","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Val Marie No. 17","csduid":"4704003","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lone Tree No. 18","csduid":"4704006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bracken","csduid":"4704008","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Climax","csduid":"4704009","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Frontier No. 19","csduid":"4704011","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Frontier","csduid":"4704012","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Reno No. 51","csduid":"4704019","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Consul","csduid":"4704021","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"White Valley No. 49","csduid":"4704024","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Eastend","csduid":"4704026","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wise Creek No. 77","csduid":"4704028","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cadillac","csduid":"4704029","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Grassy Creek No. 78","csduid":"4704034","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Shaunavon","csduid":"4704036","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Arlington No. 79","csduid":"4704038","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Maple Creek No. 111","csduid":"4704045","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Maple Creek","csduid":"4704048","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Piapot No. 110","csduid":"4704050","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Carmichael No. 109","csduid":"4704054","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Carmichael","csduid":"4704056","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bone Creek No. 108","csduid":"4704058","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lac Pelletier No. 107","csduid":"4704061","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Nekaneet Cree Nation","csduid":"4704802","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Moosomin No. 121","csduid":"4705001","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fleming","csduid":"4705002","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Moosomin","csduid":"4705004","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Welwyn","csduid":"4705006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Martin No. 122","csduid":"4705007","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wapella","csduid":"4705009","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Silverwood No. 123","csduid":"4705011","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kingsley No. 124","csduid":"4705014","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kipling","csduid":"4705016","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Chester No. 125","csduid":"4705018","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Windthorst","csduid":"4705019","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Glenavon","csduid":"4705021","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wolseley","csduid":"4705024","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wolseley No. 155","csduid":"4705027","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Elcapo No. 154","csduid":"4705028","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Grenfell","csduid":"4705029","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Broadview","csduid":"4705031","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Willowdale No. 153","csduid":"4705033","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Whitewood","csduid":"4705034","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rocanville No. 151","csduid":"4705037","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rocanville","csduid":"4705038","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Spy Hill No. 152","csduid":"4705041","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Tantallon","csduid":"4705042","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Spy Hill","csduid":"4705043","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Gerald","csduid":"4705044","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Langenburg No. 181","csduid":"4705047","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Langenburg","csduid":"4705048","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Yarbo","csduid":"4705049","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"West End","csduid":"4705050","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fertile Belt No. 183","csduid":"4705051","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Esterhazy","csduid":"4705052","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Atwater","csduid":"4705053","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bangor","csduid":"4705054","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bird's Point","csduid":"4705055","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Stockholm","csduid":"4705056","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Grayson No. 184","csduid":"4705057","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Dubuc","csduid":"4705058","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Grayson","csduid":"4705059","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Melville Beach","csduid":"4705060","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Waldron","csduid":"4705061","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Killaly","csduid":"4705062","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"McLeod No. 185","csduid":"4705063","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Neudorf","csduid":"4705064","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lemberg","csduid":"4705066","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Stanley No. 215","csduid":"4705067","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Duff","csduid":"4705068","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Goodeve","csduid":"4705069","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fenwood","csduid":"4705071","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cana No. 214","csduid":"4705073","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Melville","csduid":"4705074","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Saltcoats No. 213","csduid":"4705077","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Saltcoats","csduid":"4705078","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bredenbury","csduid":"4705079","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Churchbridge No. 211","csduid":"4705093","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Churchbridge","csduid":"4705094","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"MacNutt","csduid":"4705096","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cowessess 73","csduid":"4705803","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kahkewistahaw 72","csduid":"4705804","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sakimay 74","csduid":"4705805","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ochapowace 71","csduid":"4705806","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Shesheep 74A","csduid":"4705807","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Little Bone 74B","csduid":"4705808","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ochapowace 71-10","csduid":"4705814","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ochapowace 71-54","csduid":"4705818","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ochapowace 71-26","csduid":"4705821","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ochapowace 71-70","csduid":"4705822","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ochapowace 71-7","csduid":"4705823","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ochapowace 71-18","csduid":"4705824","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ochapowace 71-51","csduid":"4705825","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ochapowace 71-44","csduid":"4705826","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Montmartre No. 126","csduid":"4706001","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Montmartre","csduid":"4706002","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kendal","csduid":"4706003","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Francis No. 127","csduid":"4706004","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Odessa","csduid":"4706006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Vibank","csduid":"4706007","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sedley","csduid":"4706008","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Francis","csduid":"4706009","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lajord No. 128","csduid":"4706011","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Bratt's Lake No. 129","csduid":"4706013","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wilcox","csduid":"4706014","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Redburn No. 130","csduid":"4706016","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rouleau","csduid":"4706017","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Briercrest","csduid":"4706018","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Drinkwater","csduid":"4706019","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pense No. 160","csduid":"4706021","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Belle Plaine","csduid":"4706022","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Pense","csduid":"4706023","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Sherwood No. 159","csduid":"4706026","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Regina","csduid":"4706027","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Grand Coulee","csduid":"4706028","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Edenwold No. 158","csduid":"4706029","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"White City","csduid":"4706030","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Pilot Butte","csduid":"4706031","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Balgonie","csduid":"4706032","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Edenwold","csduid":"4706033","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"South Qu'Appelle No. 157","csduid":"4706034","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Qu'Appelle","csduid":"4706036","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"McLean","csduid":"4706037","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Indian Head No. 156","csduid":"4706038","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Indian Head","csduid":"4706039","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sintaluta","csduid":"4706041","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Abernethy No. 186","csduid":"4706042","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fort San","csduid":"4706045","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Abernethy","csduid":"4706046","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Balcarres","csduid":"4706047","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"North Qu'Appelle No. 187","csduid":"4706048","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lebret","csduid":"4706049","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Katepwa","csduid":"4706050","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fort Qu'Appelle","csduid":"4706051","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"B-Say-Tah","csduid":"4706052","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lumsden No. 189","csduid":"4706053","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Disley","csduid":"4706054","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Buena Vista","csduid":"4706055","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Lumsden","csduid":"4706056","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Lumsden Beach","csduid":"4706057","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Regina Beach","csduid":"4706058","cmaname":"Regina","cmauid":"705","prname":"Saskatchew"},
    {"csdname":"Dufferin No. 190","csduid":"4706059","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wee Too Beach","csduid":"4706060","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bethune","csduid":"4706061","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Findlater","csduid":"4706062","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sarnia No. 221","csduid":"4706063","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Chamberlain","csduid":"4706064","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Alice Beach","csduid":"4706065","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Holdfast","csduid":"4706067","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Dilke","csduid":"4706068","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Grandview Beach","csduid":"4706069","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Longlaketon No. 219","csduid":"4706070","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"McKillop No. 220","csduid":"4706071","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Saskatchewan Beach","csduid":"4706072","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bulyea","csduid":"4706073","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Strasbourg","csduid":"4706074","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kannata Valley","csduid":"4706075","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sunset Cove","csduid":"4706076","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Silton","csduid":"4706077","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Craven","csduid":"4706078","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Earl Grey","csduid":"4706079","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Glen Harbour","csduid":"4706080","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"North Grove","csduid":"4706081","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pelican Pointe","csduid":"4706082","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Island View","csduid":"4706085","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cupar No. 218","csduid":"4706091","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Southey","csduid":"4706092","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Markinch","csduid":"4706093","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cupar","csduid":"4706094","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lipton No. 217","csduid":"4706096","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Dysart","csduid":"4706097","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lipton","csduid":"4706098","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Tullymet No. 216","csduid":"4706099","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Piapot 75","csduid":"4706809","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Assiniboine 76","csduid":"4706810","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Standing Buffalo 78","csduid":"4706811","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pasqua 79","csduid":"4706812","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muscowpetung 80","csduid":"4706813","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Little Black Bear 84","csduid":"4706814","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Okanese 82","csduid":"4706815","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Peepeekisis 81","csduid":"4706816","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Star Blanket 83","csduid":"4706818","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wa-Pii Moos-Toosis (White Calf) 83A","csduid":"4706820","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Star Blanket 83C","csduid":"4706828","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Treaty Four Reserve Grounds 77","csduid":"4706829","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Baildon No. 131","csduid":"4707001","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hillsborough No. 132","csduid":"4707004","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rodgers No. 133","csduid":"4707006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Coderre","csduid":"4707008","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Shamrock No. 134","csduid":"4707011","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Shamrock","csduid":"4707012","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lawtonia No. 135","csduid":"4707014","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hodgeville","csduid":"4707016","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Coulee No. 136","csduid":"4707018","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Beaver Flat","csduid":"4707020","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Excelsior No. 166","csduid":"4707021","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Waldeck","csduid":"4707022","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rush Lake","csduid":"4707023","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Morse No. 165","csduid":"4707024","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Herbert","csduid":"4707026","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Morse","csduid":"4707027","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ernfold","csduid":"4707028","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Chaplin No. 164","csduid":"4707029","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Chaplin","csduid":"4707031","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wheatlands No. 163","csduid":"4707032","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mortlach","csduid":"4707034","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Caron No. 162","csduid":"4707036","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Caronport","csduid":"4707037","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Moose Jaw No. 161","csduid":"4707038","cmaname":"Moose Jaw","cmauid":"715","prname":"Saskatchew"},
    {"csdname":"Moose Jaw","csduid":"4707039","cmaname":"Moose Jaw","cmauid":"715","prname":"Saskatchew"},
    {"csdname":"Marquis No. 191","csduid":"4707042","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Tuxford","csduid":"4707043","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Marquis","csduid":"4707044","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sun Valley","csduid":"4707045","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Keeler","csduid":"4707046","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Eyebrow No. 193","csduid":"4707047","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Brownlee","csduid":"4707048","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Eyebrow","csduid":"4707049","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"South Lake","csduid":"4707051","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Enfield No. 194","csduid":"4707053","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Central Butte","csduid":"4707054","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Canaan No. 225","csduid":"4707058","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lucky Lake","csduid":"4707059","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Beechy","csduid":"4707062","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Victory No. 226","csduid":"4707063","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"King George No. 256","csduid":"4707066","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Coteau No. 255","csduid":"4707067","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Coteau Beach","csduid":"4707068","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Maple Bush No. 224","csduid":"4707071","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Riverhurst","csduid":"4707072","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mistusinne","csduid":"4707074","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Huron No. 223","csduid":"4707076","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Tugaske","csduid":"4707077","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Craik No. 222","csduid":"4707091","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Craik","csduid":"4707092","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Aylesbury","csduid":"4707093","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Swift Current No. 137","csduid":"4708001","cmaname":"Swift Current","cmauid":"720","prname":"Saskatchew"},
    {"csdname":"Swift Current","csduid":"4708004","cmaname":"Swift Current","cmauid":"720","prname":"Saskatchew"},
    {"csdname":"Webb No. 138","csduid":"4708006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Webb","csduid":"4708008","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Gull Lake No. 139","csduid":"4708009","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Gull Lake","csduid":"4708011","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Tompkins","csduid":"4708012","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Big Stick No. 141","csduid":"4708016","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Golden Prairie","csduid":"4708018","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Enterprise No. 142","csduid":"4708021","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Richmound","csduid":"4708022","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fox Valley No. 171","csduid":"4708024","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fox Valley","csduid":"4708026","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pittville No. 169","csduid":"4708028","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hazlet","csduid":"4708029","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Riverside No. 168","csduid":"4708031","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cabri","csduid":"4708032","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pennant","csduid":"4708034","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Success","csduid":"4708036","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Saskatchewan Landing No. 167","csduid":"4708038","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Stewart Valley","csduid":"4708039","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lacadena No. 228","csduid":"4708042","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kyle","csduid":"4708044","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Miry Creek No. 229","csduid":"4708046","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Abbey","csduid":"4708048","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lancer","csduid":"4708049","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Clinworth No. 230","csduid":"4708053","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sceptre","csduid":"4708054","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Happyland No. 231","csduid":"4708056","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Prelate","csduid":"4708057","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Leader","csduid":"4708058","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mendham","csduid":"4708059","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Deer Forks No. 232","csduid":"4708061","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Burstall","csduid":"4708062","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Chesterfield No. 261","csduid":"4708065","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Eatonia","csduid":"4708068","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Newcombe No. 260","csduid":"4708071","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Snipe Lake No. 259","csduid":"4708074","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Eston","csduid":"4708076","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Monet No. 257","csduid":"4708092","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Elrose","csduid":"4708094","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Calder No. 241","csduid":"4709001","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Calder","csduid":"4709002","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wallace No. 243","csduid":"4709006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rhein","csduid":"4709009","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Orkney No. 244","csduid":"4709011","cmaname":"Yorkton","cmauid":"710","prname":"Saskatchew"},
    {"csdname":"Yorkton","csduid":"4709012","cmaname":"Yorkton","cmauid":"710","prname":"Saskatchew"},
    {"csdname":"Ebenezer","csduid":"4709014","cmaname":"Yorkton","cmauid":"710","prname":"Saskatchew"},
    {"csdname":"Springside","csduid":"4709016","cmaname":"Yorkton","cmauid":"710","prname":"Saskatchew"},
    {"csdname":"Garry No. 245","csduid":"4709019","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Insinger No. 275","csduid":"4709023","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sheho","csduid":"4709024","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Theodore","csduid":"4709028","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Good Lake No. 274","csduid":"4709029","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Canora","csduid":"4709032","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sliding Hills No. 273","csduid":"4709033","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cote No. 271","csduid":"4709037","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Togo","csduid":"4709038","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kamsack","csduid":"4709039","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"St. Philips No. 301","csduid":"4709042","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pelly","csduid":"4709044","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Keys No. 303","csduid":"4709046","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Buchanan No. 304","csduid":"4709049","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Buchanan","csduid":"4709053","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Invermay No. 305","csduid":"4709054","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rama","csduid":"4709056","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Invermay","csduid":"4709058","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hazel Dell No. 335","csduid":"4709060","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lintlaw","csduid":"4709061","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Preeceville No. 334","csduid":"4709062","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Preeceville","csduid":"4709064","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Endeavour","csduid":"4709065","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sturgis","csduid":"4709066","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Clayton No. 333","csduid":"4709067","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Stenen","csduid":"4709069","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hyas","csduid":"4709071","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Norquay","csduid":"4709072","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Livingston No. 331","csduid":"4709075","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Arran","csduid":"4709076","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cote 64","csduid":"4709819","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Keeseekoose 66","csduid":"4709820","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"The Key 65","csduid":"4709821","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Keeseekoose 66A","csduid":"4709822","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Keeseekoose 66-CA-04","csduid":"4709824","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Keeseekoose 66-CA-05","csduid":"4709826","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Keeseekoose 66-CA-06","csduid":"4709828","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Keeseekoose 66-KE-04","csduid":"4709830","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Keeseekoose 66-KE-05","csduid":"4709832","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hubbard","csduid":"4710002","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ituna Bon Accord No. 246","csduid":"4710003","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ituna","csduid":"4710004","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kellross No. 247","csduid":"4710008","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kelliher","csduid":"4710009","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Leross","csduid":"4710011","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lestock","csduid":"4710012","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Touchwood No. 248","csduid":"4710014","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Semans","csduid":"4710022","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mount Hope No. 279","csduid":"4710024","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Raymore","csduid":"4710027","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Quinton","csduid":"4710028","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Punnichy","csduid":"4710029","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Emerald No. 277","csduid":"4710031","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Chorney Beach","csduid":"4710034","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Leslie Beach","csduid":"4710035","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Foam Lake No. 276","csduid":"4710036","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Foam Lake","csduid":"4710038","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Elfros No. 307","csduid":"4710041","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Elfros","csduid":"4710043","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Big Quill No. 308","csduid":"4710046","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wynyard","csduid":"4710047","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Dafoe","csduid":"4710051","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Prairie Rose No. 309","csduid":"4710052","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Jansen","csduid":"4710054","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Leroy No. 339","csduid":"4710056","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Leroy","csduid":"4710058","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lakeside No. 338","csduid":"4710061","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Watson","csduid":"4710062","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Quill Lake","csduid":"4710064","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lakeview No. 337","csduid":"4710066","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wadena","csduid":"4710068","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sasman No. 336","csduid":"4710071","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Margo","csduid":"4710072","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85","csduid":"4710822","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Gordon 86","csduid":"4710823","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Day Star 87","csduid":"4710824","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Poorman 88","csduid":"4710825","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fishing Lake 89","csduid":"4710826","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Beardy's and Okemasis 96 and 97A","csduid":"4710828","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-17","csduid":"4710832","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-26","csduid":"4710834","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-33","csduid":"4710836","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-28","csduid":"4710838","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-29","csduid":"4710840","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-23","csduid":"4710842","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-12","csduid":"4710843","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-24","csduid":"4710844","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-22","csduid":"4710845","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-27","csduid":"4710846","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-10","csduid":"4710847","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-1","csduid":"4710848","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-8","csduid":"4710849","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-15","csduid":"4710850","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-2A","csduid":"4710851","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskowekwan 85-31","csduid":"4710852","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fishing Lake 89A","csduid":"4710854","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Duval","csduid":"4711002","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Last Mountain Valley No. 250","csduid":"4711003","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Govan","csduid":"4711004","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Big Arm No. 251","csduid":"4711006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Etters Beach","csduid":"4711007","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Imperial","csduid":"4711008","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Liberty","csduid":"4711009","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Arm River No. 252","csduid":"4711011","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Davidson","csduid":"4711014","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Willner No. 253","csduid":"4711016","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Loreburn No. 254","csduid":"4711018","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Elbow","csduid":"4711019","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Loreburn","csduid":"4711021","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Strongfield","csduid":"4711022","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hawarden","csduid":"4711024","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rudy No. 284","csduid":"4711026","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Outlook","csduid":"4711027","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Broderick","csduid":"4711028","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Glenside","csduid":"4711029","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rosedale No. 283","csduid":"4711031","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hanley","csduid":"4711032","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"McCraney No. 282","csduid":"4711034","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kenaston","csduid":"4711036","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bladworth","csduid":"4711038","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wood Creek No. 281","csduid":"4711039","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Simpson","csduid":"4711041","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wreford No. 280","csduid":"4711042","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Nokomis","csduid":"4711044","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Usborne No. 310","csduid":"4711046","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Drake","csduid":"4711048","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lanigan","csduid":"4711049","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Morris No. 312","csduid":"4711052","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Watrous","csduid":"4711053","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Manitou Beach","csduid":"4711054","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Young","csduid":"4711056","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Zelma","csduid":"4711058","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lost River No. 313","csduid":"4711059","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Thode","csduid":"4711060","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Dundurn No. 314","csduid":"4711061","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Dundurn","csduid":"4711063","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Shields","csduid":"4711064","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Corman Park No. 344","csduid":"4711065","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Saskatoon","csduid":"4711066","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Langham","csduid":"4711067","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Warman","csduid":"4711068","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Blucher No. 343","csduid":"4711069","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Martensville","csduid":"4711070","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Bradwell","csduid":"4711071","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Allan","csduid":"4711072","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Dalmeny","csduid":"4711073","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Osler","csduid":"4711075","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Colonsay No. 342","csduid":"4711076","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Clavet","csduid":"4711077","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Meacham","csduid":"4711078","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Colonsay","csduid":"4711079","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Viscount No. 341","csduid":"4711091","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Viscount","csduid":"4711092","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Plunkett","csduid":"4711094","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wolverine No. 340","csduid":"4711096","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Whitecap","csduid":"4711828","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Pleasant Valley No. 288","csduid":"4712001","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"St. Andrews No. 287","csduid":"4712004","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rosetown","csduid":"4712006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Zealandia","csduid":"4712008","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Milden No. 286","csduid":"4712011","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Milden","csduid":"4712012","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wiseton","csduid":"4712014","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Dinsmore","csduid":"4712016","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Macrorie","csduid":"4712019","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fertile Valley No. 285","csduid":"4712020","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Conquest","csduid":"4712022","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Montrose No. 315","csduid":"4712026","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Harris No. 316","csduid":"4712029","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Tessier","csduid":"4712031","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Harris","csduid":"4712032","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Marriott No. 317","csduid":"4712034","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mountain View No. 318","csduid":"4712038","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Biggar No. 347","csduid":"4712042","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Biggar","csduid":"4712046","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Perdue No. 346","csduid":"4712050","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Perdue","csduid":"4712051","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kinley","csduid":"4712052","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Vanscoy No. 345","csduid":"4712054","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Delisle","csduid":"4712056","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Vanscoy","csduid":"4712058","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Asquith","csduid":"4712059","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Eagle Creek No. 376","csduid":"4712064","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Glenside No. 377","csduid":"4712069","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rosemount No. 378","csduid":"4712072","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Battle River No. 438","csduid":"4712078","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Battleford","csduid":"4712079","cmaname":"North Battleford","cmauid":"735","prname":"Saskatchew"},
    {"csdname":"Red Pheasant 108","csduid":"4712829","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mosquito 109","csduid":"4712830","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Grizzly Bear's Head 110 and Lean Man 111","csduid":"4712832","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sweet Grass 113","csduid":"4712833","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sweet Grass 113-M16","csduid":"4712837","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Brock","csduid":"4713002","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Netherhill","csduid":"4713004","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kindersley No. 290","csduid":"4713006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kindersley","csduid":"4713008","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Flaxcombe","csduid":"4713009","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Milton No. 292","csduid":"4713011","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Marengo","csduid":"4713014","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Antelope Park No. 322","csduid":"4713016","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Prairiedale No. 321","csduid":"4713019","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Major","csduid":"4713021","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Smiley","csduid":"4713022","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Oakdale No. 320","csduid":"4713024","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Coleville","csduid":"4713026","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Winslow No. 319","csduid":"4713028","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Dodsland","csduid":"4713029","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Plenty","csduid":"4713031","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Grandview No. 349","csduid":"4713032","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mariposa No. 350","csduid":"4713038","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Tramping Lake","csduid":"4713039","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Progress No. 351","csduid":"4713041","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kerrobert","csduid":"4713042","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Luseland","csduid":"4713044","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Heart's Hill No. 352","csduid":"4713046","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Eye Hill No. 382","csduid":"4713049","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Macklin","csduid":"4713051","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Primate","csduid":"4713053","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Denzil","csduid":"4713054","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Grass Lake No. 381","csduid":"4713056","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Tramping Lake No. 380","csduid":"4713059","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Scott","csduid":"4713062","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Reford No. 379","csduid":"4713064","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Landis","csduid":"4713067","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Buffalo No. 409","csduid":"4713068","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wilkie","csduid":"4713069","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Round Valley No. 410","csduid":"4713072","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Unity","csduid":"4713074","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Senlac No. 411","csduid":"4713076","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Senlac","csduid":"4713078","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Manitou Lake No. 442","csduid":"4713079","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Marsden","csduid":"4713091","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hillsdale No. 440","csduid":"4713092","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Neilburg","csduid":"4713094","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cut Knife No. 439","csduid":"4713096","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cut Knife","csduid":"4713098","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Poundmaker 114","csduid":"4713835","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Little Pine 116","csduid":"4713836","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hudson Bay No. 394","csduid":"4714001","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hudson Bay","csduid":"4714004","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Porcupine No. 395","csduid":"4714006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Porcupine Plain","csduid":"4714007","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Weekes","csduid":"4714009","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kelvington No. 366","csduid":"4714021","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kelvington","csduid":"4714022","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ponass Lake No. 367","csduid":"4714023","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rose Valley","csduid":"4714024","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fosston","csduid":"4714025","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Spalding No. 368","csduid":"4714026","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Spalding","csduid":"4714028","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pleasantdale","csduid":"4714030","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Naicam","csduid":"4714032","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Barrier Valley No. 397","csduid":"4714034","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pleasantdale No. 398","csduid":"4714035","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Archerwill","csduid":"4714036","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bjorkdale No. 426","csduid":"4714038","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mistatim","csduid":"4714039","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bjorkdale","csduid":"4714041","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Tisdale No. 427","csduid":"4714043","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Tisdale","csduid":"4714044","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Star City No. 428","csduid":"4714047","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Valparaiso","csduid":"4714048","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Star City","csduid":"4714049","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Melfort","csduid":"4714051","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Willow Creek No. 458","csduid":"4714053","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Connaught No. 457","csduid":"4714056","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ridgedale","csduid":"4714058","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Arborfield No. 456","csduid":"4714059","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Zenon Park","csduid":"4714061","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Arborfield","csduid":"4714062","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Moose Range No. 486","csduid":"4714067","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Tobin Lake","csduid":"4714068","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Carrot River","csduid":"4714069","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Nipawin No. 487","csduid":"4714072","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Aylsham","csduid":"4714073","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Codette","csduid":"4714074","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Nipawin","csduid":"4714076","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Torch River No. 488","csduid":"4714077","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"White Fox","csduid":"4714079","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Love","csduid":"4714091","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Choiceland","csduid":"4714092","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Smeaton","csduid":"4714093","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Shoal Lake 28A","csduid":"4714839","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Red Earth 29","csduid":"4714840","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Yellowquill 90","csduid":"4714841","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kinistin 91","csduid":"4714842","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Yellow Quill 90-9","csduid":"4714843","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Carrot River 29A","csduid":"4714845","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Opaskwayak Cree Nation 27A (Carrot River)","csduid":"4714846","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"St. Peter No. 369","csduid":"4715001","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Englefeld","csduid":"4715002","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"St. Gregor","csduid":"4715003","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muenster","csduid":"4715004","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Annaheim","csduid":"4715005","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lake Lenore","csduid":"4715006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Humboldt No. 370","csduid":"4715007","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Humboldt","csduid":"4715008","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bayne No. 371","csduid":"4715011","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Bruno","csduid":"4715012","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Grant No. 372","csduid":"4715014","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Prud'Homme","csduid":"4715016","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Vonda","csduid":"4715017","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Aberdeen No. 373","csduid":"4715018","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Aberdeen","csduid":"4715019","cmaname":"Saskatoon","cmauid":"725","prname":"Saskatchew"},
    {"csdname":"Laird No. 404","csduid":"4715026","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hepburn","csduid":"4715027","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Waldheim","csduid":"4715028","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Laird","csduid":"4715029","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rosthern No. 403","csduid":"4715031","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Rosthern","csduid":"4715032","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hague","csduid":"4715034","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fish Creek No. 402","csduid":"4715036","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Alvena","csduid":"4715038","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hoodoo No. 401","csduid":"4715039","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cudworth","csduid":"4715041","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wakaw","csduid":"4715042","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wakaw Lake","csduid":"4715043","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Three Lakes No. 400","csduid":"4715044","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pilger","csduid":"4715045","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"St. Benedict","csduid":"4715046","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Middle Lake","csduid":"4715047","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lake Lenore No. 399","csduid":"4715048","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"St. Brieux","csduid":"4715049","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Flett's Springs No. 429","csduid":"4715051","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Beatty","csduid":"4715052","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Invergordon No. 430","csduid":"4715054","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"St. Louis No. 431","csduid":"4715057","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"St. Louis","csduid":"4715059","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Duck Lake No. 463","csduid":"4715061","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Duck Lake","csduid":"4715062","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Prince Albert No. 461","csduid":"4715064","cmaname":"Prince Albert","cmauid":"745","prname":"Saskatchew"},
    {"csdname":"Prince Albert","csduid":"4715066","cmaname":"Prince Albert","cmauid":"745","prname":"Saskatchew"},
    {"csdname":"Birch Hills No. 460","csduid":"4715067","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Birch Hills","csduid":"4715068","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Candle Lake","csduid":"4715070","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kinistino No. 459","csduid":"4715071","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Weldon","csduid":"4715072","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kinistino","csduid":"4715074","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lakeland No. 521","csduid":"4715075","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Christopher Lake","csduid":"4715076","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Garden River No. 490","csduid":"4715079","cmaname":"Prince Albert","cmauid":"745","prname":"Saskatchew"},
    {"csdname":"Albertville","csduid":"4715085","cmaname":"Prince Albert","cmauid":"745","prname":"Saskatchew"},
    {"csdname":"Weirdale","csduid":"4715091","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Meath Park","csduid":"4715092","cmaname":"Prince Albert","cmauid":"745","prname":"Saskatchew"},
    {"csdname":"Buckland No. 491","csduid":"4715094","cmaname":"Prince Albert","cmauid":"745","prname":"Saskatchew"},
    {"csdname":"Paddockwood","csduid":"4715098","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Paddockwood No. 520","csduid":"4715099","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"One Arrow 95","csduid":"4715844","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Beardy's 97 and Okemasis 96","csduid":"4715845","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskoday First Nation","csduid":"4715847","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wahpaton 94A","csduid":"4715848","cmaname":"Prince Albert","cmauid":"745","prname":"Saskatchew"},
    {"csdname":"James Smith 100","csduid":"4715849","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cumberland 100A","csduid":"4715850","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Little Red River 106C","csduid":"4715851","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Montreal Lake 106B","csduid":"4715853","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"One Arrow 95-1C","csduid":"4715857","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Beardy's and Okemasis 96 and 97B","csduid":"4715859","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"One Arrow 95-1D","csduid":"4715861","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"One Arrow 95-1A","csduid":"4715862","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Denholm","csduid":"4716002","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ruddell","csduid":"4716003","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Maymont","csduid":"4716004","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mayfield No. 406","csduid":"4716005","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Great Bend No. 405","csduid":"4716008","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Radisson","csduid":"4716009","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Borden","csduid":"4716011","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Blaine Lake No. 434","csduid":"4716013","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Marcelin","csduid":"4716014","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Blaine Lake","csduid":"4716016","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Redberry No. 435","csduid":"4716018","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Krydor","csduid":"4716019","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Hafford","csduid":"4716022","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Douglas No. 436","csduid":"4716023","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Speers","csduid":"4716024","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Richard","csduid":"4716026","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"North Battleford","csduid":"4716027","cmaname":"North Battleford","cmauid":"735","prname":"Saskatchew"},
    {"csdname":"North Battleford No. 437","csduid":"4716028","cmaname":"North Battleford","cmauid":"735","prname":"Saskatchew"},
    {"csdname":"North Battleford","csduid":"4716029","cmaname":"North Battleford","cmauid":"735","prname":"Saskatchew"},
    {"csdname":"Round Hill No. 467","csduid":"4716033","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Meeting Lake No. 466","csduid":"4716038","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Leask No. 464","csduid":"4716041","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Leask","csduid":"4716042","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pebble Baye","csduid":"4716043","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Parkside","csduid":"4716044","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Shellbrook No. 493","csduid":"4716046","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Shellbrook","csduid":"4716047","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Canwood No. 494","csduid":"4716051","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Canwood","csduid":"4716052","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Prince Albert National Park","csduid":"4716053","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Debden","csduid":"4716054","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Spiritwood No. 496","csduid":"4716056","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Shell Lake","csduid":"4716057","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Spiritwood","csduid":"4716058","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Big Shell","csduid":"4716059","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Echo Bay","csduid":"4716060","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Leoville","csduid":"4716061","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Medstead No. 497","csduid":"4716062","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Medstead","csduid":"4716063","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Big River","csduid":"4716072","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Big River No. 555","csduid":"4716075","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Chitek Lake","csduid":"4716077","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskeg Lake Cree Nation 102","csduid":"4716854","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mistawasis 103","csduid":"4716855","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sturgeon Lake 101","csduid":"4716856","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Little Red River 106D","csduid":"4716857","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Big River 118","csduid":"4716858","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ahtahkakoop 104","csduid":"4716860","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Saulteaux 159A","csduid":"4716861","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Witchekan Lake 117","csduid":"4716862","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Chitek Lake 191","csduid":"4716863","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lucky Man","csduid":"4716870","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskeg Lake 102B","csduid":"4716872","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pelican Lake 191B","csduid":"4716880","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Witchekan Lake 117D","csduid":"4716882","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskeg Lake 102E","csduid":"4716886","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskeg Lake 102F","csduid":"4716888","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskeg Lake 102G","csduid":"4716890","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Muskeg Lake 102D","csduid":"4716891","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sweet Grass 113-L6","csduid":"4716892","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pelican Lake 191A","csduid":"4716894","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Meota No. 468","csduid":"4717001","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cochin","csduid":"4717002","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Metinota","csduid":"4717004","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Aquadeo","csduid":"4717005","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Meota","csduid":"4717006","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Turtle River No. 469","csduid":"4717008","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Edam","csduid":"4717011","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Paynton No. 470","csduid":"4717013","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Paynton","csduid":"4717014","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Eldon No. 471","csduid":"4717017","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Maidstone","csduid":"4717018","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Waseca","csduid":"4717019","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wilton No. 472","csduid":"4717022","cmaname":"Lloydminster (Saskatchewan part / partie de la Saskatchewan)","cmauid":"840","prname":"Saskatchew"},
    {"csdname":"Lashburn","csduid":"4717024","cmaname":"Lloydminster (Saskatchewan part / partie de la Saskatchewan)","cmauid":"840","prname":"Saskatchew"},
    {"csdname":"Marshall","csduid":"4717026","cmaname":"Lloydminster (Saskatchewan part / partie de la Saskatchewan)","cmauid":"840","prname":"Saskatchew"},
    {"csdname":"Britannia No. 502","csduid":"4717028","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lloydminster (Part)","csduid":"4717029","cmaname":"Lloydminster (Saskatchewan part / partie de la Saskatchewan)","cmauid":"840","prname":"Saskatchew"},
    {"csdname":"Frenchman Butte No. 501","csduid":"4717032","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Paradise Hill","csduid":"4717034","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"St. Walburg","csduid":"4717036","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mervin","csduid":"4717039","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Turtleford","csduid":"4717041","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kivimaa-Moonlight Bay","csduid":"4717042","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Mervin No. 499","csduid":"4717045","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Parkdale No. 498","csduid":"4717047","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Glaslyn","csduid":"4717048","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Meadow Lake","csduid":"4717052","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Greig Lake","csduid":"4717053","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Meadow Lake No. 588","csduid":"4717054","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Dorintosh","csduid":"4717055","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Loon Lake No. 561","csduid":"4717056","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Makwa","csduid":"4717057","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Loon Lake","csduid":"4717058","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Beaver River No. 622","csduid":"4717062","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Goodsoil","csduid":"4717066","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pierceland","csduid":"4717067","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Seekaskootch 119","csduid":"4717801","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Makaoo (Part) 120","csduid":"4717802","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Thunderchild First Nation 115B","csduid":"4717803","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Thunderchild First Nation 115C","csduid":"4717804","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Flying Dust First Nation 105 (Meadow Lake 105)","csduid":"4717805","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Waterhen 130","csduid":"4717806","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Makwa Lake 129B","csduid":"4717807","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Makwa Lake 129","csduid":"4717808","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ministikwan 161","csduid":"4717809","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Ministikwan 161A","csduid":"4717810","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Big Island Lake Cree Territory","csduid":"4717811","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Moosomin 112B","csduid":"4717812","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Saulteaux 159","csduid":"4717813","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Makwa Lake 129C","csduid":"4717815","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Eagles Lake 165C","csduid":"4717816","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Meadow Lake 105A","csduid":"4717819","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Onion Lake 119-1","csduid":"4717820","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Min-A-He-Quo-Sis 116C","csduid":"4717825","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cumberland House","csduid":"4718005","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Timber Bay","csduid":"4718012","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Weyakwin","csduid":"4718015","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Green Lake","csduid":"4718021","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Dore Lake","csduid":"4718023","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cole Bay","csduid":"4718028","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Jans Bay","csduid":"4718030","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Beauval","csduid":"4718033","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"La Ronge","csduid":"4718041","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Air Ronge","csduid":"4718042","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Denare Beach","csduid":"4718049","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Creighton","csduid":"4718051","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Flin Flon (Part)","csduid":"4718052","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pelican Narrows","csduid":"4718055","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sandy Bay","csduid":"4718058","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Missinipe","csduid":"4718062","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pinehouse","csduid":"4718065","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Île-à-la-Crosse","csduid":"4718067","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Patuanak","csduid":"4718069","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Buffalo Narrows","csduid":"4718070","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"St. George's Hill","csduid":"4718071","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Michel Village","csduid":"4718072","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"La Loche","csduid":"4718074","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Turnor Lake","csduid":"4718075","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Division No. 18, Unorganized","csduid":"4718090","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Stony Rapids","csduid":"4718100","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cumberland House Cree Nation 20","csduid":"4718801","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Montreal Lake 106","csduid":"4718802","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Stanley 157","csduid":"4718803","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wapaskokimaw 202","csduid":"4718806","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"La Plonge 192","csduid":"4718807","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Morin Lake 217","csduid":"4718808","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lac La Ronge 156","csduid":"4718809","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sucker River 156C (Nemebien River 156C)","csduid":"4718811","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kitsakie 156B","csduid":"4718812","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Four Portages 157C","csduid":"4718813","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Wapachewunak 192D","csduid":"4718814","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Canoe Lake 165","csduid":"4718817","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Buffalo River Dene Nation 193 (Peter Pond Lake 193)","csduid":"4718818","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Turnor Lake 193B","csduid":"4718819","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Pelican Narrows 184B","csduid":"4718820","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Sturgeon Weir 184F","csduid":"4718821","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Southend 200","csduid":"4718822","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Chicken 225","csduid":"4718823","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Fond du Lac 227","csduid":"4718824","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Brabant Lake","csduid":"4718825","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Southend No. 200A","csduid":"4718826","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Chicken 224","csduid":"4718828","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Clearwater River Dene Band 223","csduid":"4718829","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Grandmother's Bay 219","csduid":"4718831","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Lac La Hache 220","csduid":"4718832","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Clearwater River Dene 222","csduid":"4718839","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Little Hills 158","csduid":"4718844","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Little Hills 158B","csduid":"4718849","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kimosom Pwatinahk 203 (Deschambault Lake)","csduid":"4718852","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Kinoosao-Thomas Clark 204","csduid":"4718854","cmaname":"","cmauid":"","prname":"Saskatchew"},
    {"csdname":"Cypress County","csduid":"4801003","cmaname":"Medicine Hat","cmauid":"805","prname":"Alber"},
    {"csdname":"Medicine Hat","csduid":"4801006","cmaname":"Medicine Hat","cmauid":"805","prname":"Alber"},
    {"csdname":"Forty Mile County No. 8","csduid":"4801008","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Foremost","csduid":"4801009","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Bow Island","csduid":"4801014","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Redcliff","csduid":"4801018","cmaname":"Medicine Hat","cmauid":"805","prname":"Alber"},
    {"csdname":"Warner County No. 5","csduid":"4802001","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Coutts","csduid":"4802002","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Milk River","csduid":"4802004","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Warner","csduid":"4802006","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Raymond","csduid":"4802008","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Stirling","csduid":"4802009","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Lethbridge County","csduid":"4802011","cmaname":"Lethbridge","cmauid":"810","prname":"Alber"},
    {"csdname":"Lethbridge","csduid":"4802012","cmaname":"Lethbridge","cmauid":"810","prname":"Alber"},
    {"csdname":"Coalhurst","csduid":"4802013","cmaname":"Lethbridge","cmauid":"810","prname":"Alber"},
    {"csdname":"Nobleford","csduid":"4802014","cmaname":"Lethbridge","cmauid":"810","prname":"Alber"},
    {"csdname":"Barons","csduid":"4802016","cmaname":"Lethbridge","cmauid":"810","prname":"Alber"},
    {"csdname":"Picture Butte","csduid":"4802018","cmaname":"Lethbridge","cmauid":"810","prname":"Alber"},
    {"csdname":"Coaldale","csduid":"4802019","cmaname":"Lethbridge","cmauid":"810","prname":"Alber"},
    {"csdname":"Taber","csduid":"4802021","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Taber","csduid":"4802022","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Barnwell","csduid":"4802023","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Vauxhall","csduid":"4802029","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Newell County","csduid":"4802031","cmaname":"Brooks","cmauid":"806","prname":"Alber"},
    {"csdname":"Brooks","csduid":"4802034","cmaname":"Brooks","cmauid":"806","prname":"Alber"},
    {"csdname":"Duchess","csduid":"4802036","cmaname":"Brooks","cmauid":"806","prname":"Alber"},
    {"csdname":"Rosemary","csduid":"4802038","cmaname":"Brooks","cmauid":"806","prname":"Alber"},
    {"csdname":"Bassano","csduid":"4802039","cmaname":"Brooks","cmauid":"806","prname":"Alber"},
    {"csdname":"Cardston County","csduid":"4803001","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Magrath","csduid":"4803002","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Improvement District No.  4 Waterton","csduid":"4803003","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Cardston","csduid":"4803004","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Glenwood","csduid":"4803006","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Hill Spring","csduid":"4803008","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Pincher Creek No. 9","csduid":"4803011","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Pincher Creek","csduid":"4803014","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Cowley","csduid":"4803016","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Willow Creek No. 26","csduid":"4803018","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Fort Macleod","csduid":"4803019","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Granum","csduid":"4803021","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Claresholm","csduid":"4803022","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Stavely","csduid":"4803024","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Nanton","csduid":"4803026","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Piikani 147","csduid":"4803801","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Blood 148","csduid":"4803802","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Blood 148A","csduid":"4803803","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Acadia No. 34","csduid":"4804001","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Special Area No. 2","csduid":"4804004","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Empress","csduid":"4804006","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Hanna","csduid":"4804011","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Special Area No. 3","csduid":"4804012","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Oyen","csduid":"4804014","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Cereal","csduid":"4804016","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Youngstown","csduid":"4804019","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Special Area No. 4","csduid":"4804020","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Veteran","csduid":"4804021","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Consort","csduid":"4804022","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Vulcan County","csduid":"4805001","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Carmangay","csduid":"4805002","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Champion","csduid":"4805004","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Vulcan","csduid":"4805006","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Lomond","csduid":"4805008","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Milo","csduid":"4805009","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Arrowwood","csduid":"4805011","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Wheatland County","csduid":"4805012","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Strathmore","csduid":"4805018","cmaname":"Strathmore","cmauid":"826","prname":"Alber"},
    {"csdname":"Hussar","csduid":"4805019","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Standard","csduid":"4805021","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Rockyford","csduid":"4805022","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Drumheller","csduid":"4805026","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Starland County","csduid":"4805031","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Delia","csduid":"4805034","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Munson","csduid":"4805036","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Morrin","csduid":"4805038","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Kneehill County","csduid":"4805041","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Carbon","csduid":"4805042","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Acme","csduid":"4805044","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Linden","csduid":"4805046","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Three Hills","csduid":"4805048","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Trochu","csduid":"4805049","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Siksika 146","csduid":"4805802","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Foothills No. 31","csduid":"4806001","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"High River","csduid":"4806006","cmaname":"High River","cmauid":"821","prname":"Alber"},
    {"csdname":"Longview","csduid":"4806008","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Turner Valley","csduid":"4806009","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Black Diamond","csduid":"4806011","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Okotoks","csduid":"4806012","cmaname":"Okotoks","cmauid":"820","prname":"Alber"},
    {"csdname":"Rocky View County","csduid":"4806014","cmaname":"Calgary","cmauid":"825","prname":"Alber"},
    {"csdname":"Calgary","csduid":"4806016","cmaname":"Calgary","cmauid":"825","prname":"Alber"},
    {"csdname":"Chestermere","csduid":"4806017","cmaname":"Calgary","cmauid":"825","prname":"Alber"},
    {"csdname":"Cochrane","csduid":"4806019","cmaname":"Calgary","cmauid":"825","prname":"Alber"},
    {"csdname":"Airdrie","csduid":"4806021","cmaname":"Calgary","cmauid":"825","prname":"Alber"},
    {"csdname":"Irricana","csduid":"4806022","cmaname":"Calgary","cmauid":"825","prname":"Alber"},
    {"csdname":"Beiseker","csduid":"4806024","cmaname":"Calgary","cmauid":"825","prname":"Alber"},
    {"csdname":"Crossfield","csduid":"4806026","cmaname":"Calgary","cmauid":"825","prname":"Alber"},
    {"csdname":"Mountain View County","csduid":"4806028","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Carstairs","csduid":"4806029","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Cremona","csduid":"4806031","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Didsbury","csduid":"4806032","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Olds","csduid":"4806034","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Sundre","csduid":"4806036","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Eden Valley 216","csduid":"4806803","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Tsuu T'ina Nation 145 (Sarcee 145)","csduid":"4806804","cmaname":"Calgary","cmauid":"825","prname":"Alber"},
    {"csdname":"Provost No. 52","csduid":"4807001","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Provost","csduid":"4807002","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Czar","csduid":"4807004","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Hughenden","csduid":"4807006","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Amisk","csduid":"4807008","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Paintearth County No. 18","csduid":"4807011","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Coronation","csduid":"4807012","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Castor","csduid":"4807014","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Halkirk","csduid":"4807016","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Stettler County No. 6","csduid":"4807019","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Big Valley","csduid":"4807021","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Gadsby","csduid":"4807022","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Botha","csduid":"4807024","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Stettler","csduid":"4807026","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"White Sands","csduid":"4807027","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Rochon Sands","csduid":"4807028","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Donalda","csduid":"4807029","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Flagstaff County","csduid":"4807031","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Alliance","csduid":"4807032","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Forestburg","csduid":"4807036","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Heisler","csduid":"4807038","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Daysland","csduid":"4807039","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Killam","csduid":"4807042","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Sedgewick","csduid":"4807044","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Lougheed","csduid":"4807046","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Hardisty","csduid":"4807048","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Wainwright No. 61","csduid":"4807049","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Chauvin","csduid":"4807051","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Edgerton","csduid":"4807052","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Wainwright","csduid":"4807054","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Irma","csduid":"4807056","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Red Deer County","csduid":"4808001","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Elnora","csduid":"4808002","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Delburne","csduid":"4808004","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Jarvis Bay","csduid":"4808005","cmaname":"Sylvan Lake","cmauid":"831","prname":"Alber"},
    {"csdname":"Bowden","csduid":"4808006","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Innisfail","csduid":"4808008","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Penhold","csduid":"4808009","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Red Deer","csduid":"4808011","cmaname":"Red Deer","cmauid":"830","prname":"Alber"},
    {"csdname":"Sylvan Lake","csduid":"4808012","cmaname":"Sylvan Lake","cmauid":"831","prname":"Alber"},
    {"csdname":"Norglenwold","csduid":"4808013","cmaname":"Sylvan Lake","cmauid":"831","prname":"Alber"},
    {"csdname":"Lacombe County","csduid":"4808022","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Birchcliff","csduid":"4808023","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Eckville","csduid":"4808024","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Half Moon Bay","csduid":"4808025","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Bentley","csduid":"4808026","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Sunbreaker Cove","csduid":"4808027","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Gull Lake","csduid":"4808028","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Blackfalds","csduid":"4808029","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Lacombe","csduid":"4808031","cmaname":"Lacombe","cmauid":"832","prname":"Alber"},
    {"csdname":"Clive","csduid":"4808032","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Alix","csduid":"4808034","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Ponoka County","csduid":"4808038","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Ponoka","csduid":"4808039","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Parkland Beach","csduid":"4808042","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Rimbey","csduid":"4808044","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Samson 137","csduid":"4808811","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Samson 137A","csduid":"4808812","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Montana 139","csduid":"4808813","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Clearwater County","csduid":"4809002","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Burnstick Lake","csduid":"4809005","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Caroline","csduid":"4809010","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Rocky Mountain House","csduid":"4809015","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"O'Chiese 203","csduid":"4809806","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Sunchild 202","csduid":"4809809","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Big Horn 144A","csduid":"4809810","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Camrose County","csduid":"4810001","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Bashaw","csduid":"4810002","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Rosalind","csduid":"4810003","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Ferintosh","csduid":"4810004","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Edberg","csduid":"4810006","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Bawlf","csduid":"4810009","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Camrose","csduid":"4810011","cmaname":"Camrose","cmauid":"833","prname":"Alber"},
    {"csdname":"Bittern Lake","csduid":"4810012","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Hay Lakes","csduid":"4810014","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Beaver County","csduid":"4810016","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Tofield","csduid":"4810018","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Ryley","csduid":"4810019","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Holden","csduid":"4810021","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Viking","csduid":"4810022","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Minburn County No. 27","csduid":"4810026","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Vegreville","csduid":"4810028","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Innisfree","csduid":"4810031","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Mannville","csduid":"4810034","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Vermilion River County","csduid":"4810036","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Paradise Valley","csduid":"4810038","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Lloydminster (Part)","csduid":"4810039","cmaname":"Lloydminster (Alberta part / partie de l'Alberta)","cmauid":"840","prname":"Alber"},
    {"csdname":"Kitscoty","csduid":"4810041","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Vermilion","csduid":"4810042","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Marwayne","csduid":"4810044","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Dewberry","csduid":"4810046","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Two Hills County No. 21","csduid":"4810048","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Myrnam","csduid":"4810051","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Two Hills","csduid":"4810052","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Willingdon","csduid":"4810056","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Lamont County","csduid":"4810058","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Andrew","csduid":"4810059","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Mundare","csduid":"4810061","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Chipman","csduid":"4810062","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Lamont","csduid":"4810064","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Bruderheim","csduid":"4810066","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Improvement District No. 13 Elk Island","csduid":"4810068","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Makaoo (Part) 120","csduid":"4810805","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Wetaskiwin County No. 10","csduid":"4811001","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Wetaskiwin","csduid":"4811002","cmaname":"Wetaskiwin","cmauid":"865","prname":"Alber"},
    {"csdname":"Argentia Beach","csduid":"4811003","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Grandview","csduid":"4811004","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Poplar Bay","csduid":"4811005","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Crystal Springs","csduid":"4811006","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Norris Beach","csduid":"4811007","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Ma-Me-O Beach","csduid":"4811008","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Silver Beach","csduid":"4811009","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Millet","csduid":"4811011","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Leduc County","csduid":"4811012","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Beaumont","csduid":"4811013","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Leduc","csduid":"4811016","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Devon","csduid":"4811018","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Calmar","csduid":"4811019","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Sundance Beach","csduid":"4811020","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Thorsby","csduid":"4811021","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Itaska Beach","csduid":"4811022","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Golden Days","csduid":"4811023","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Warburg","csduid":"4811024","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Breton","csduid":"4811026","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Drayton Valley","csduid":"4811031","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Brazeau County","csduid":"4811032","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Parkland County","csduid":"4811034","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Seba Beach","csduid":"4811038","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Betula Beach","csduid":"4811039","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Point Alison","csduid":"4811041","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Lakeview","csduid":"4811042","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Kapasiwin","csduid":"4811044","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Wabamun","csduid":"4811045","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Spring Lake","csduid":"4811046","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Stony Plain","csduid":"4811048","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Spruce Grove","csduid":"4811049","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Strathcona County","csduid":"4811052","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Fort Saskatchewan","csduid":"4811056","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Sturgeon County","csduid":"4811059","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Edmonton","csduid":"4811061","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"St. Albert","csduid":"4811062","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Gibbons","csduid":"4811064","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Redwater","csduid":"4811065","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Bon Accord","csduid":"4811066","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Morinville","csduid":"4811068","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Legal","csduid":"4811069","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Ermineskin 138","csduid":"4811801","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Pigeon Lake 138A","csduid":"4811802","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Louis Bull 138B","csduid":"4811803","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Stony Plain 135","csduid":"4811804","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Alexander 134","csduid":"4811805","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Wabamun 133A","csduid":"4811806","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Wabamun 133B","csduid":"4811807","cmaname":"Edmonton","cmauid":"835","prname":"Alber"},
    {"csdname":"Cold Lake","csduid":"4812002","cmaname":"Cold Lake","cmauid":"845","prname":"Alber"},
    {"csdname":"Bonnyville No. 87","csduid":"4812004","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Bonnyville","csduid":"4812009","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Bonnyville Beach","csduid":"4812011","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Glendon","csduid":"4812012","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Pelican Narrows","csduid":"4812013","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"St. Paul County No. 19","csduid":"4812014","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Elk Point","csduid":"4812016","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"St. Paul","csduid":"4812018","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Horseshoe Bay","csduid":"4812020","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Smoky Lake County","csduid":"4812022","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Vilna","csduid":"4812024","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Waskatenau","csduid":"4812026","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Smoky Lake","csduid":"4812029","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Lac la Biche County","csduid":"4812037","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Improvement District No. 349","csduid":"4812038","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Unipouheos 121","csduid":"4812802","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Puskiakiwenin 122","csduid":"4812804","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Saddle Lake 125","csduid":"4812806","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"White Fish Lake 128","csduid":"4812808","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Cold Lake 149","csduid":"4812810","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Kehewin 123","csduid":"4812811","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Cold Lake 149A","csduid":"4812813","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Cold Lake 149B","csduid":"4812815","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Beaver Lake 131","csduid":"4812828","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Heart Lake 167","csduid":"4812840","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Lac Ste. Anne County","csduid":"4813001","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Mayerthorpe","csduid":"4813002","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Nakamun Park","csduid":"4813003","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Val Quentin","csduid":"4813005","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"West Cove","csduid":"4813006","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Yellowstone","csduid":"4813007","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Ross Haven","csduid":"4813008","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Castle Island","csduid":"4813009","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Silver Sands","csduid":"4813010","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Sunset Point","csduid":"4813011","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Alberta Beach","csduid":"4813012","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Birch Cove","csduid":"4813013","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Onoway","csduid":"4813014","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"South View","csduid":"4813015","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Sandy Beach","csduid":"4813016","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Sunrise Beach","csduid":"4813017","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Barrhead County No. 11","csduid":"4813018","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Barrhead","csduid":"4813019","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Westlock County","csduid":"4813028","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Woodlands County","csduid":"4813029","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Whitecourt","csduid":"4813030","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Westlock","csduid":"4813031","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Clyde","csduid":"4813032","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Larkspur","csduid":"4813033","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Thorhild County","csduid":"4813036","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Athabasca County","csduid":"4813044","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Mewatha Beach","csduid":"4813045","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Boyle","csduid":"4813046","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Sunset Beach","csduid":"4813047","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Athabasca","csduid":"4813048","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Island Lake","csduid":"4813049","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Island Lake South","csduid":"4813051","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Bondiss","csduid":"4813053","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"South Baptiste","csduid":"4813055","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"West Baptiste","csduid":"4813057","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Whispering Hills","csduid":"4813061","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Alexis 133","csduid":"4813811","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Yellowhead County","csduid":"4814003","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Hinton","csduid":"4814019","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Edson","csduid":"4814024","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Improvement District No. 25 Willmore Wilderness","csduid":"4814028","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Crowsnest Pass","csduid":"4815007","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Kananaskis","csduid":"4815013","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Bighorn No. 8","csduid":"4815015","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Canmore","csduid":"4815023","cmaname":"Canmore","cmauid":"828","prname":"Alber"},
    {"csdname":"Ghost Lake","csduid":"4815027","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Waiparous","csduid":"4815030","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Improvement District No.  9 Banff","csduid":"4815032","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Jasper","csduid":"4815033","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Banff","csduid":"4815035","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Improvement District No. 12 Jasper Park","csduid":"4815037","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Ranchland No. 66","csduid":"4815045","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Stoney 142, 143, 144","csduid":"4815802","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Wood Buffalo","csduid":"4816037","cmaname":"Wood Buffalo","cmauid":"860","prname":"Alber"},
    {"csdname":"Improvement District No. 24 Wood Buffalo","csduid":"4816051","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Janvier 194","csduid":"4816817","cmaname":"Wood Buffalo","cmauid":"860","prname":"Alber"},
    {"csdname":"Gregoire Lake 176","csduid":"4816821","cmaname":"Wood Buffalo","cmauid":"860","prname":"Alber"},
    {"csdname":"Gregoire Lake 176A","csduid":"4816822","cmaname":"Wood Buffalo","cmauid":"860","prname":"Alber"},
    {"csdname":"Chipewyan 201A","csduid":"4816831","cmaname":"Wood Buffalo","cmauid":"860","prname":"Alber"},
    {"csdname":"Old Fort 217","csduid":"4816849","cmaname":"Wood Buffalo","cmauid":"860","prname":"Alber"},
    {"csdname":"Allison Bay 219","csduid":"4816850","cmaname":"Wood Buffalo","cmauid":"860","prname":"Alber"},
    {"csdname":"Dog Head 218","csduid":"4816852","cmaname":"Wood Buffalo","cmauid":"860","prname":"Alber"},
    {"csdname":"Fort Mackay","csduid":"4816859","cmaname":"Wood Buffalo","cmauid":"860","prname":"Alber"},
    {"csdname":"Thebathi 196","csduid":"4816860","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Thabacha Náre 196A","csduid":"4816861","cmaname":"Wood Buffalo","cmauid":"860","prname":"Alber"},
    {"csdname":"High Prairie","csduid":"4817021","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Swan Hills","csduid":"4817024","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Northern Sunrise County","csduid":"4817026","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Big Lakes County","csduid":"4817027","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Slave Lake","csduid":"4817029","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Opportunity No. 17","csduid":"4817031","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Lesser Slave River No.124","csduid":"4817033","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Nampa","csduid":"4817039","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Clear Hills","csduid":"4817062","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Hines Creek","csduid":"4817064","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Northern Lights County","csduid":"4817076","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Manning","csduid":"4817078","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"High Level","csduid":"4817093","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Mackenzie County","csduid":"4817095","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Rainbow Lake","csduid":"4817097","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Jean Baptiste Gambler 183","csduid":"4817817","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Wabasca 166","csduid":"4817818","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Wabasca 166A","csduid":"4817819","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Wabasca 166B","csduid":"4817821","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Wabasca 166C","csduid":"4817822","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Wabasca 166D","csduid":"4817823","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Utikoomak Lake 155","csduid":"4817824","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Clear Hills 152C","csduid":"4817825","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Utikoomak Lake 155A","csduid":"4817827","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Drift Pile River 150","csduid":"4817828","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Sucker Creek 150A","csduid":"4817829","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Kapawe'no First Nation (Pakashan 150D)","csduid":"4817830","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Swan River 150E","csduid":"4817831","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Sawridge 150G","csduid":"4817832","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Sawridge 150H","csduid":"4817833","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Fox Lake 162","csduid":"4817835","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Kapawe'no First Nation (Freeman 150B)","csduid":"4817836","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"John d'Or Prairie 215","csduid":"4817837","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Tall Cree 173","csduid":"4817838","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Tall Cree 173A","csduid":"4817840","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Beaver Ranch 163","csduid":"4817841","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Boyer 164","csduid":"4817842","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Child Lake 164A","csduid":"4817843","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Hay Lake 209","csduid":"4817845","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Bushe River 207","csduid":"4817848","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Upper Hay River 212","csduid":"4817849","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Little Buffalo","csduid":"4817853","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Carcajou 187","csduid":"4817854","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Desmarais","csduid":"4817855","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Woodland Cree 226","csduid":"4817856","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Woodland Cree 228","csduid":"4817857","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Fort Vermilion 173B","csduid":"4817859","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Loon Lake 235","csduid":"4817860","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Fox Creek","csduid":"4818002","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Grande Cache","csduid":"4818005","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Greenview No. 16","csduid":"4818015","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Valleyview","csduid":"4818018","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Sturgeon Lake 154","csduid":"4818816","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Sturgeon Lake 154A","csduid":"4818818","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Grande Prairie County No. 1","csduid":"4819006","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Hythe","csduid":"4819008","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Beaverlodge","csduid":"4819009","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Wembley","csduid":"4819011","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Grande Prairie","csduid":"4819012","cmaname":"Grande Prairie","cmauid":"850","prname":"Alber"},
    {"csdname":"Sexsmith","csduid":"4819014","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Peace River","csduid":"4819038","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Smoky River No. 130","csduid":"4819041","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"McLennan","csduid":"4819042","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Donnelly","csduid":"4819044","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Falher","csduid":"4819046","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Girouxville","csduid":"4819048","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Birch Hills County","csduid":"4819049","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Spirit River No. 133","csduid":"4819054","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Rycroft","csduid":"4819056","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Spirit River","csduid":"4819058","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Saddle Hills County","csduid":"4819059","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Fairview No. 136","csduid":"4819066","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Fairview","csduid":"4819068","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Peace No. 135","csduid":"4819071","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Berwyn","csduid":"4819072","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Grimshaw","csduid":"4819074","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Horse Lakes 152B","csduid":"4819815","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Duncan's 151A","csduid":"4819826","cmaname":"","cmauid":"","prname":"Alber"},
    {"csdname":"Elkford","csduid":"5901003","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sparwood","csduid":"5901006","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fernie","csduid":"5901012","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"East Kootenay A","csduid":"5901017","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"East Kootenay B","csduid":"5901019","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cranbrook","csduid":"5901022","cmaname":"Cranbrook","cmauid":"905","prname":"British Columbia"},
    {"csdname":"Kimberley","csduid":"5901028","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"East Kootenay C","csduid":"5901035","cmaname":"Cranbrook","cmauid":"905","prname":"British Columbia"},
    {"csdname":"East Kootenay E","csduid":"5901037","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Invermere","csduid":"5901039","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Radium Hot Springs","csduid":"5901040","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Canal Flats","csduid":"5901043","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"East Kootenay F","csduid":"5901046","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"East Kootenay G","csduid":"5901048","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tobacco Plains 2","csduid":"5901801","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Isidore's Ranch 4","csduid":"5901802","cmaname":"Cranbrook","cmauid":"905","prname":"British Columbia"},
    {"csdname":"Kootenay 1","csduid":"5901803","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Columbia Lake 3","csduid":"5901804","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cassimayooks (Mayook) 5","csduid":"5901805","cmaname":"Cranbrook","cmauid":"905","prname":"British Columbia"},
    {"csdname":"Shuswap","csduid":"5901806","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Creston","csduid":"5903004","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Central Kootenay A","csduid":"5903010","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Salmo","csduid":"5903011","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Central Kootenay B","csduid":"5903013","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nelson","csduid":"5903015","cmaname":"Nelson","cmauid":"907","prname":"British Columbia"},
    {"csdname":"Central Kootenay C","csduid":"5903017","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Slocan","csduid":"5903019","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kaslo","csduid":"5903023","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Silverton","csduid":"5903027","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"New Denver","csduid":"5903032","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Central Kootenay D","csduid":"5903039","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Central Kootenay E","csduid":"5903041","cmaname":"Nelson","cmauid":"907","prname":"British Columbia"},
    {"csdname":"Central Kootenay F","csduid":"5903043","cmaname":"Nelson","cmauid":"907","prname":"British Columbia"},
    {"csdname":"Castlegar","csduid":"5903045","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Central Kootenay G","csduid":"5903047","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nakusp","csduid":"5903050","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Central Kootenay H","csduid":"5903052","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Central Kootenay I","csduid":"5903056","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Central Kootenay J","csduid":"5903058","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Central Kootenay K","csduid":"5903060","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Creston 1","csduid":"5903807","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fruitvale","csduid":"5905005","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Montrose","csduid":"5905009","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Trail","csduid":"5905014","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Warfield","csduid":"5905018","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Rossland","csduid":"5905023","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kootenay Boundary A","csduid":"5905026","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kootenay Boundary B / Lower Columbia-Old-Glory","csduid":"5905030","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Grand Forks","csduid":"5905032","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Midway","csduid":"5905037","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Greenwood","csduid":"5905042","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kootenay Boundary C / Christina Lake","csduid":"5905050","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kootenay Boundary D / Rural Grand Forks","csduid":"5905052","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kootenay Boundary E / West Boundary","csduid":"5905054","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Osoyoos","csduid":"5907005","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Keremeos","csduid":"5907009","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Oliver","csduid":"5907014","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Okanagan-Similkameen A","csduid":"5907022","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Princeton","csduid":"5907024","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Okanagan-Similkameen B","csduid":"5907026","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Okanagan-Similkameen C","csduid":"5907028","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Summerland","csduid":"5907035","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Penticton","csduid":"5907041","cmaname":"Penticton","cmauid":"913","prname":"British Columbia"},
    {"csdname":"Okanagan-Similkameen D","csduid":"5907047","cmaname":"Penticton","cmauid":"913","prname":"British Columbia"},
    {"csdname":"Okanagan-Similkameen E","csduid":"5907049","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Okanagan-Similkameen F","csduid":"5907051","cmaname":"Penticton","cmauid":"913","prname":"British Columbia"},
    {"csdname":"Okanagan-Similkameen G","csduid":"5907053","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Okanagan-Similkameen H","csduid":"5907055","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Lower Similkameen 2","csduid":"5907801","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Osoyoos 1","csduid":"5907802","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Penticton 1","csduid":"5907803","cmaname":"Penticton","cmauid":"913","prname":"British Columbia"},
    {"csdname":"Chopaka 7 & 8","csduid":"5907805","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Blind Creek 6","csduid":"5907806","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Chuchuwayha 2","csduid":"5907807","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Alexis 9","csduid":"5907808","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Ashnola 10","csduid":"5907809","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Hope","csduid":"5909009","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fraser Valley A","csduid":"5909014","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fraser Valley B","csduid":"5909016","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Chilliwack","csduid":"5909020","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Harrison Hot Springs","csduid":"5909027","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Kent","csduid":"5909032","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Fraser Valley D","csduid":"5909034","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Fraser Valley H","csduid":"5909035","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Fraser Valley E","csduid":"5909036","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Fraser Valley C","csduid":"5909048","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Abbotsford","csduid":"5909052","cmaname":"Abbotsford - Mission","cmauid":"932","prname":"British Columbia"},
    {"csdname":"Mission","csduid":"5909056","cmaname":"Abbotsford - Mission","cmauid":"932","prname":"British Columbia"},
    {"csdname":"Fraser Valley F","csduid":"5909060","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fraser Valley G","csduid":"5909062","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Chawathil 4","csduid":"5909804","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Inkahtsaph 6","csduid":"5909805","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kopchitchin 2","csduid":"5909806","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Ohamil 1","csduid":"5909807","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Puckatholetchin 11","csduid":"5909808","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Saddle Rock 9","csduid":"5909809","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Lukseetsissum 9","csduid":"5909810","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Schkam 2","csduid":"5909812","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Skawahlook 1","csduid":"5909814","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Speyum 3","csduid":"5909815","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Spuzzum 1","csduid":"5909816","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tuckkwiowhum 1","csduid":"5909817","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Yale Town 1","csduid":"5909818","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kahmoose 4","csduid":"5909819","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kwawkwawapilt 6","csduid":"5909821","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Skwah 4","csduid":"5909824","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Skwali 3","csduid":"5909825","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Skway 5","csduid":"5909826","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Soowahlie 14","csduid":"5909827","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Tzeachten 13","csduid":"5909830","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Yakweakwioose 12","csduid":"5909831","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Seabird Island","csduid":"5909832","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Scowlitz 1","csduid":"5909833","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Tseatah 2","csduid":"5909834","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Aitchelitch 9","csduid":"5909835","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Boston Bar 1A","csduid":"5909836","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cheam 1","csduid":"5909837","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Schelowat 1","csduid":"5909838","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Chehalis 5","csduid":"5909839","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Stullawheets 8","csduid":"5909841","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Douglas 8","csduid":"5909842","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Peters 1","csduid":"5909843","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Popkum 1","csduid":"5909844","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Skookumchuck 4","csduid":"5909845","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Bucktum 4","csduid":"5909847","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tipella 7","csduid":"5909848","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Skwahla 2","csduid":"5909849","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Boothroyd 13","csduid":"5909850","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Paqulh","csduid":"5909852","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Q'alatkú7em","csduid":"5909875","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Albert Flat 5","csduid":"5909876","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Upper Sumas 6","csduid":"5909877","cmaname":"Abbotsford - Mission","cmauid":"932","prname":"British Columbia"},
    {"csdname":"Matsqui Main 2","csduid":"5909878","cmaname":"Abbotsford - Mission","cmauid":"932","prname":"British Columbia"},
    {"csdname":"Holachten 8","csduid":"5909879","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Lakahahmen 11","csduid":"5909880","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Skweahm 10","csduid":"5909881","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Squawkum Creek 3","csduid":"5909882","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sachteen","csduid":"5909883","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Skowkale","csduid":"5909884","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Squiaala","csduid":"5909885","cmaname":"Chilliwack","cmauid":"930","prname":"British Columbia"},
    {"csdname":"Langley","csduid":"5915001","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Langley","csduid":"5915002","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Surrey","csduid":"5915004","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"White Rock","csduid":"5915007","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Delta","csduid":"5915011","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Richmond","csduid":"5915015","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Greater Vancouver A","csduid":"5915020","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Vancouver","csduid":"5915022","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Burnaby","csduid":"5915025","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"New Westminster","csduid":"5915029","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Coquitlam","csduid":"5915034","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Belcarra","csduid":"5915036","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Anmore","csduid":"5915038","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Port Coquitlam","csduid":"5915039","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Port Moody","csduid":"5915043","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"North Vancouver","csduid":"5915046","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"North Vancouver","csduid":"5915051","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"West Vancouver","csduid":"5915055","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Bowen Island","csduid":"5915062","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Lions Bay","csduid":"5915065","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Pitt Meadows","csduid":"5915070","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Maple Ridge","csduid":"5915075","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Semiahmoo","csduid":"5915801","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Tsawwassen","csduid":"5915802","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Musqueam 2","csduid":"5915803","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Coquitlam 2","csduid":"5915804","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Coquitlam 1","csduid":"5915805","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Burrard Inlet 3","csduid":"5915806","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Mission 1","csduid":"5915807","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Capilano 5","csduid":"5915808","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Barnston Island 3","csduid":"5915809","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Musqueam 4","csduid":"5915810","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Seymour Creek 2","csduid":"5915811","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Katzie 2","csduid":"5915813","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"McMillan Island 6","csduid":"5915816","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Matsqui 4","csduid":"5915825","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Katzie 1","csduid":"5915830","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Langley 5","csduid":"5915835","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"Whonnock 1","csduid":"5915840","cmaname":"Vancouver","cmauid":"933","prname":"British Columbia"},
    {"csdname":"North Saanich","csduid":"5917005","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Sidney","csduid":"5917010","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Central Saanich","csduid":"5917015","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Saanich","csduid":"5917021","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Saltspring Island","csduid":"5917027","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Southern Gulf Islands","csduid":"5917029","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Oak Bay","csduid":"5917030","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Victoria","csduid":"5917034","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Esquimalt","csduid":"5917040","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Colwood","csduid":"5917041","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Metchosin","csduid":"5917042","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Langford","csduid":"5917044","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"View Royal","csduid":"5917047","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Highlands","csduid":"5917049","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Sooke","csduid":"5917052","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Juan de Fuca (Part 1)","csduid":"5917054","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Juan de Fuca (Part 2)","csduid":"5917056","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cole Bay 3","csduid":"5917801","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Union Bay 4","csduid":"5917802","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"East Saanich 2","csduid":"5917803","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"South Saanich 1","csduid":"5917804","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Galiano Island 9","csduid":"5917805","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Becher Bay 1","csduid":"5917809","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Esquimalt","csduid":"5917811","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"New Songhees 1A","csduid":"5917812","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"Gordon River 2","csduid":"5917815","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"T'Sou-ke","csduid":"5917819","cmaname":"Victoria","cmauid":"935","prname":"British Columbia"},
    {"csdname":"North Cowichan","csduid":"5919008","cmaname":"Duncan","cmauid":"937","prname":"British Columbia"},
    {"csdname":"Duncan","csduid":"5919012","cmaname":"Duncan","cmauid":"937","prname":"British Columbia"},
    {"csdname":"Cowichan Valley D","csduid":"5919013","cmaname":"Duncan","cmauid":"937","prname":"British Columbia"},
    {"csdname":"Cowichan Valley G","csduid":"5919015","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Lake Cowichan","csduid":"5919016","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cowichan Valley H","csduid":"5919017","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Ladysmith","csduid":"5919021","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cowichan Valley F","csduid":"5919033","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cowichan Valley I","csduid":"5919035","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cowichan Valley A","csduid":"5919043","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cowichan Valley B","csduid":"5919046","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cowichan Valley C","csduid":"5919049","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cowichan Valley E","csduid":"5919051","cmaname":"Duncan","cmauid":"937","prname":"British Columbia"},
    {"csdname":"Halalt 2","csduid":"5919801","cmaname":"Duncan","cmauid":"937","prname":"British Columbia"},
    {"csdname":"Squaw-hay-one 11","csduid":"5919802","cmaname":"Duncan","cmauid":"937","prname":"British Columbia"},
    {"csdname":"Tsussie 6","csduid":"5919803","cmaname":"Duncan","cmauid":"937","prname":"British Columbia"},
    {"csdname":"Chemainus 13","csduid":"5919804","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kil-pah-las 3","csduid":"5919808","cmaname":"Duncan","cmauid":"937","prname":"British Columbia"},
    {"csdname":"Penelakut Island 7","csduid":"5919809","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Shingle Point 4","csduid":"5919811","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cowichan Lake","csduid":"5919812","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Malachan 11","csduid":"5919814","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Malahat 11","csduid":"5919815","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Oyster Bay 12","csduid":"5919816","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Portier Pass 5","csduid":"5919817","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Theik 2","csduid":"5919818","cmaname":"Duncan","cmauid":"937","prname":"British Columbia"},
    {"csdname":"Est-Patrolas 4","csduid":"5919820","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tzart-Lam 5","csduid":"5919821","cmaname":"Duncan","cmauid":"937","prname":"British Columbia"},
    {"csdname":"Cowichan","csduid":"5919822","cmaname":"Duncan","cmauid":"937","prname":"British Columbia"},
    {"csdname":"Nanaimo","csduid":"5921007","cmaname":"Nanaimo","cmauid":"938","prname":"British Columbia"},
    {"csdname":"Lantzville","csduid":"5921008","cmaname":"Nanaimo","cmauid":"938","prname":"British Columbia"},
    {"csdname":"Nanaimo A","csduid":"5921010","cmaname":"Nanaimo","cmauid":"938","prname":"British Columbia"},
    {"csdname":"Nanaimo B","csduid":"5921014","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nanaimo C","csduid":"5921016","cmaname":"Nanaimo","cmauid":"938","prname":"British Columbia"},
    {"csdname":"Parksville","csduid":"5921018","cmaname":"Parksville","cmauid":"939","prname":"British Columbia"},
    {"csdname":"Qualicum Beach","csduid":"5921023","cmaname":"Parksville","cmauid":"939","prname":"British Columbia"},
    {"csdname":"Nanaimo E","csduid":"5921030","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nanaimo F","csduid":"5921032","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nanaimo G","csduid":"5921034","cmaname":"Parksville","cmauid":"939","prname":"British Columbia"},
    {"csdname":"Nanaimo H","csduid":"5921036","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nanaimo Town 1","csduid":"5921804","cmaname":"Nanaimo","cmauid":"938","prname":"British Columbia"},
    {"csdname":"Nanoose","csduid":"5921805","cmaname":"Nanaimo","cmauid":"938","prname":"British Columbia"},
    {"csdname":"Qualicum","csduid":"5921806","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nanaimo River","csduid":"5921807","cmaname":"Nanaimo","cmauid":"938","prname":"British Columbia"},
    {"csdname":"Port Alberni","csduid":"5923008","cmaname":"Port Alberni","cmauid":"940","prname":"British Columbia"},
    {"csdname":"Ucluelet","csduid":"5923019","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tofino","csduid":"5923025","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Alberni-Clayoquot B","csduid":"5923033","cmaname":"Port Alberni","cmauid":"940","prname":"British Columbia"},
    {"csdname":"Alberni-Clayoquot D","csduid":"5923035","cmaname":"Port Alberni","cmauid":"940","prname":"British Columbia"},
    {"csdname":"Alberni-Clayoquot E","csduid":"5923037","cmaname":"Port Alberni","cmauid":"940","prname":"British Columbia"},
    {"csdname":"Alberni-Clayoquot F","csduid":"5923039","cmaname":"Port Alberni","cmauid":"940","prname":"British Columbia"},
    {"csdname":"Alberni-Clayoquot A","csduid":"5923047","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Alberni-Clayoquot C","csduid":"5923049","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Ahahswinis 1","csduid":"5923801","cmaname":"Port Alberni","cmauid":"940","prname":"British Columbia"},
    {"csdname":"Alberni 2","csduid":"5923802","cmaname":"Port Alberni","cmauid":"940","prname":"British Columbia"},
    {"csdname":"Anacla 12","csduid":"5923803","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Clakamucus 2","csduid":"5923804","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Elhlateese 2","csduid":"5923805","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Hesquiat 1","csduid":"5923806","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Ittatsoo 1","csduid":"5923807","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Marktosis 15","csduid":"5923808","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Numukamis 1","csduid":"5923809","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Macoah 1","csduid":"5923810","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Opitsat 1","csduid":"5923813","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sachsa 4","csduid":"5923814","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tsahaheh 1","csduid":"5923816","cmaname":"Port Alberni","cmauid":"940","prname":"British Columbia"},
    {"csdname":"Klehkoot 2","csduid":"5923822","cmaname":"Port Alberni","cmauid":"940","prname":"British Columbia"},
    {"csdname":"Esowista 3","csduid":"5923823","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Refuge Cove 6","csduid":"5923824","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tin Wis 11","csduid":"5923825","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Gold River","csduid":"5924025","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Zeballos","csduid":"5924029","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tahsis","csduid":"5924030","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Campbell River","csduid":"5924034","cmaname":"Campbell River","cmauid":"944","prname":"British Columbia"},
    {"csdname":"Sayward","csduid":"5924039","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Strathcona D (Oyster Bay - Buttle Lake)","csduid":"5924042","cmaname":"Campbell River","cmauid":"944","prname":"British Columbia"},
    {"csdname":"Strathcona A","csduid":"5924048","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Strathcona B","csduid":"5924052","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Strathcona C","csduid":"5924054","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Ahaminaquus 12","csduid":"5924803","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Campbell River 11","csduid":"5924804","cmaname":"Campbell River","cmauid":"944","prname":"British Columbia"},
    {"csdname":"Chenahkint 12","csduid":"5924805","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Houpsitas 6","csduid":"5924806","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Quinsam 12","csduid":"5924812","cmaname":"Campbell River","cmauid":"944","prname":"British Columbia"},
    {"csdname":"Village Island 1","csduid":"5924813","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Yuquot 1","csduid":"5924814","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cape Mudge 10","csduid":"5924817","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Squirrel Cove 8","csduid":"5924818","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tork 7","csduid":"5924820","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Oclucje 7","csduid":"5924833","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tsa Xana 18","csduid":"5924835","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Ehatis 11","csduid":"5924836","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Homalco 9","csduid":"5924840","cmaname":"Campbell River","cmauid":"944","prname":"British Columbia"},
    {"csdname":"Comox","csduid":"5926005","cmaname":"Courtenay","cmauid":"943","prname":"British Columbia"},
    {"csdname":"Courtenay","csduid":"5926010","cmaname":"Courtenay","cmauid":"943","prname":"British Columbia"},
    {"csdname":"Cumberland","csduid":"5926014","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Comox Valley A","csduid":"5926021","cmaname":"Courtenay","cmauid":"943","prname":"British Columbia"},
    {"csdname":"Comox Valley B (Lazo North)","csduid":"5926022","cmaname":"Courtenay","cmauid":"943","prname":"British Columbia"},
    {"csdname":"Comox Valley C (Puntledge - Black Creek)","csduid":"5926024","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Comox 1","csduid":"5926801","cmaname":"Courtenay","cmauid":"943","prname":"British Columbia"},
    {"csdname":"Pentledge 2","csduid":"5926802","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Powell River","csduid":"5927008","cmaname":"Powell River","cmauid":"945","prname":"British Columbia"},
    {"csdname":"Powell River A","csduid":"5927010","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Powell River B","csduid":"5927012","cmaname":"Powell River","cmauid":"945","prname":"British Columbia"},
    {"csdname":"Powell River C","csduid":"5927016","cmaname":"Powell River","cmauid":"945","prname":"British Columbia"},
    {"csdname":"Powell River D","csduid":"5927018","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Powell River E","csduid":"5927020","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sliammon 1","csduid":"5927802","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sechelt (Part)","csduid":"5927806","cmaname":"Powell River","cmauid":"945","prname":"British Columbia"},
    {"csdname":"Gibsons","csduid":"5929005","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sechelt","csduid":"5929011","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sunshine Coast A","csduid":"5929018","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sunshine Coast B","csduid":"5929022","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sunshine Coast D","csduid":"5929024","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sunshine Coast E","csduid":"5929026","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sunshine Coast F","csduid":"5929028","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Chekwelp 26","csduid":"5929801","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sechelt (Part)","csduid":"5929803","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Squamish","csduid":"5931006","cmaname":"Squamish","cmauid":"934","prname":"British Columbia"},
    {"csdname":"Pemberton","csduid":"5931012","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Squamish-Lillooet C","csduid":"5931017","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Whistler","csduid":"5931020","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Squamish-Lillooet D","csduid":"5931021","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Lillooet","csduid":"5931026","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Squamish-Lillooet A","csduid":"5931032","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Squamish-Lillooet B","csduid":"5931034","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cheakamus 11","csduid":"5931801","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kowtain 17","csduid":"5931802","cmaname":"Squamish","cmauid":"934","prname":"British Columbia"},
    {"csdname":"Seaichem 16","csduid":"5931806","cmaname":"Squamish","cmauid":"934","prname":"British Columbia"},
    {"csdname":"Stawamus 24","csduid":"5931807","cmaname":"Squamish","cmauid":"934","prname":"British Columbia"},
    {"csdname":"Waiwakum 14","csduid":"5931808","cmaname":"Squamish","cmauid":"934","prname":"British Columbia"},
    {"csdname":"Yekwaupsum 18","csduid":"5931809","cmaname":"Squamish","cmauid":"934","prname":"British Columbia"},
    {"csdname":"Nesuch 3","csduid":"5931812","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Bridge River 1","csduid":"5931813","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cayoosh Creek 1","csduid":"5931814","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Chilhil 6","csduid":"5931815","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fountain  1","csduid":"5931816","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fountain  3","csduid":"5931817","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fountain 10","csduid":"5931818","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fountain 11","csduid":"5931819","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fountain 12","csduid":"5931820","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Lillooet 1","csduid":"5931821","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fountain Creek 8","csduid":"5931822","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"McCartney's Flat 4","csduid":"5931823","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Seton Lake 5","csduid":"5931824","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Necait 6","csduid":"5931826","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nesikep 6","csduid":"5931827","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Pashilqua 2","csduid":"5931828","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Pavilion 1","csduid":"5931829","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Slosh 1","csduid":"5931831","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Towinock 2","csduid":"5931832","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Mission 5","csduid":"5931833","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fountain 1B","csduid":"5931842","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Mount Currie","csduid":"5931843","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nequatque","csduid":"5931844","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Merritt","csduid":"5933006","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Thompson-Nicola M (Beautiful Nicola Valley - North)","csduid":"5933008","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Thompson-Nicola N (Beautiful Nicola Valley - South)","csduid":"5933012","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Lytton","csduid":"5933015","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Ashcroft","csduid":"5933019","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cache Creek","csduid":"5933024","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Clinton","csduid":"5933028","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Thompson-Nicola E (Bonaparte Plateau)","csduid":"5933032","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Logan Lake","csduid":"5933035","cmaname":"Kamloops","cmauid":"925","prname":"British Columbia"},
    {"csdname":"Thompson-Nicola I (Blue Sky Country)","csduid":"5933037","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Thompson-Nicola J (Copper Desert Country)","csduid":"5933039","cmaname":"Kamloops","cmauid":"925","prname":"British Columbia"},
    {"csdname":"Kamloops","csduid":"5933042","cmaname":"Kamloops","cmauid":"925","prname":"British Columbia"},
    {"csdname":"Thompson-Nicola P (Rivers and the Peaks)","csduid":"5933044","cmaname":"Kamloops","cmauid":"925","prname":"British Columbia"},
    {"csdname":"Sun Peaks Mountain","csduid":"5933045","cmaname":"Kamloops","cmauid":"925","prname":"British Columbia"},
    {"csdname":"Chase","csduid":"5933054","cmaname":"Kamloops","cmauid":"925","prname":"British Columbia"},
    {"csdname":"Thompson-Nicola L (Grasslands)","csduid":"5933060","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Clearwater","csduid":"5933067","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Thompson-Nicola A (Wells Gray Country)","csduid":"5933068","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Thompson-Nicola B (Thompson Headwaters)","csduid":"5933070","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Thompson-Nicola O (Lower North Thompson)","csduid":"5933072","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Barriere","csduid":"5933074","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Coldwater 1","csduid":"5933801","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Douglas Lake 3","csduid":"5933802","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Hamilton Creek 2","csduid":"5933803","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Joeyaska 2","csduid":"5933805","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nicola Lake 1","csduid":"5933806","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nicola Mameet 1","csduid":"5933807","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nooaitch 10","csduid":"5933808","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Paul's Basin 2","csduid":"5933809","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Chuchhraischin","csduid":"5933810","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Zoht 4","csduid":"5933811","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Bonaparte 3","csduid":"5933812","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Halhalaeden","csduid":"5933813","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Canoe Creek 1","csduid":"5933814","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Skeetchestn","csduid":"5933817","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"High Bar 1","csduid":"5933819","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Canoe Creek 2","csduid":"5933821","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kitzowit 20","csduid":"5933823","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Skuppah 2A","csduid":"5933824","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Inklyuhkinatko 2","csduid":"5933825","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Basque 18","csduid":"5933828","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Klahkamich 17","csduid":"5933829","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kleetlekut 22","csduid":"5933831","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Klickkumcheen 18","csduid":"5933832","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Inkluckcheen","csduid":"5933833","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kumcheen 1","csduid":"5933834","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Lower Hat Creek 2","csduid":"5933836","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Peq-Paq 22","csduid":"5933837","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Lytton 4A","csduid":"5933839","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Lytton 4E","csduid":"5933840","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Lytton 9A","csduid":"5933841","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Lytton 9B","csduid":"5933842","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Ashcroft 4","csduid":"5933844","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"105 Mile Post 2","csduid":"5933845","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Oregon Jack Creek 5","csduid":"5933846","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nickel Palm 4","csduid":"5933848","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Siska Flat","csduid":"5933849","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nickeyeah 25","csduid":"5933850","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nicomen 1","csduid":"5933851","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nohomeen 23","csduid":"5933852","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nuuautin 2","csduid":"5933853","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Paska Island 3","csduid":"5933854","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Papyum 27","csduid":"5933855","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Pemynoos 9","csduid":"5933857","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Seah 5","csduid":"5933858","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Shackan 11","csduid":"5933859","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kloklowuck 7","csduid":"5933861","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Skuppah 4","csduid":"5933865","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Skwayaynope 26","csduid":"5933866","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Spintlum Flat 3","csduid":"5933868","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nkaih 10","csduid":"5933870","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Marble Canyon 3","csduid":"5933872","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Stryen 9","csduid":"5933873","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tsaukan 12","csduid":"5933874","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Upper Hat Creek 1","csduid":"5933875","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Upper Nepa 6","csduid":"5933876","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Whispering Pines 4","csduid":"5933877","cmaname":"Kamloops","cmauid":"925","prname":"British Columbia"},
    {"csdname":"Yawaucht 11","csduid":"5933878","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Zacht 5","csduid":"5933879","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kamloops 1","csduid":"5933880","cmaname":"Kamloops","cmauid":"925","prname":"British Columbia"},
    {"csdname":"Spences Bridge","csduid":"5933881","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kanaka Bar","csduid":"5933882","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sahhaltkum 4","csduid":"5933884","cmaname":"Kamloops","cmauid":"925","prname":"British Columbia"},
    {"csdname":"Nekalliston 2","csduid":"5933886","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"North Thompson 1","csduid":"5933887","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Louis Creek 4","csduid":"5933888","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Squaam 2","csduid":"5933889","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Shawniken 4B","csduid":"5933895","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nekliptum  1","csduid":"5933896","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Boothroyd 8A (Part)","csduid":"5933897","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Neskonlith","csduid":"5933898","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kelowna","csduid":"5935010","cmaname":"Kelowna","cmauid":"915","prname":"British Columbia"},
    {"csdname":"Central Okanagan","csduid":"5935012","cmaname":"Kelowna","cmauid":"915","prname":"British Columbia"},
    {"csdname":"Lake Country","csduid":"5935016","cmaname":"Kelowna","cmauid":"915","prname":"British Columbia"},
    {"csdname":"Peachland","csduid":"5935018","cmaname":"Kelowna","cmauid":"915","prname":"British Columbia"},
    {"csdname":"Central Okanagan J","csduid":"5935020","cmaname":"Kelowna","cmauid":"915","prname":"British Columbia"},
    {"csdname":"West Kelowna","csduid":"5935029","cmaname":"Kelowna","cmauid":"915","prname":"British Columbia"},
    {"csdname":"Duck Lake 7","csduid":"5935801","cmaname":"Kelowna","cmauid":"915","prname":"British Columbia"},
    {"csdname":"Tsinstikeptum  9","csduid":"5935802","cmaname":"Kelowna","cmauid":"915","prname":"British Columbia"},
    {"csdname":"Tsinstikeptum 10","csduid":"5935803","cmaname":"Kelowna","cmauid":"915","prname":"British Columbia"},
    {"csdname":"Lumby","csduid":"5937005","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Coldstream","csduid":"5937010","cmaname":"Vernon","cmauid":"918","prname":"British Columbia"},
    {"csdname":"Vernon","csduid":"5937014","cmaname":"Vernon","cmauid":"918","prname":"British Columbia"},
    {"csdname":"North Okanagan B","csduid":"5937017","cmaname":"Vernon","cmauid":"918","prname":"British Columbia"},
    {"csdname":"North Okanagan C","csduid":"5937021","cmaname":"Vernon","cmauid":"918","prname":"British Columbia"},
    {"csdname":"North Okanagan D","csduid":"5937022","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"North Okanagan E","csduid":"5937023","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Spallumcheen","csduid":"5937024","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Armstrong","csduid":"5937028","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Enderby","csduid":"5937033","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"North Okanagan F","csduid":"5937041","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Okanagan (Part) 1","csduid":"5937801","cmaname":"Vernon","cmauid":"918","prname":"British Columbia"},
    {"csdname":"Enderby 2","csduid":"5937802","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Priest's Valley 6","csduid":"5937803","cmaname":"Vernon","cmauid":"918","prname":"British Columbia"},
    {"csdname":"Harris 3","csduid":"5937805","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Golden","csduid":"5939007","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Columbia-Shuswap A","csduid":"5939011","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Revelstoke","csduid":"5939019","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Columbia-Shuswap B","csduid":"5939023","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Salmon Arm","csduid":"5939032","cmaname":"Salmon Arm","cmauid":"920","prname":"British Columbia"},
    {"csdname":"Columbia-Shuswap C","csduid":"5939037","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Columbia-Shuswap D","csduid":"5939039","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Columbia-Shuswap E","csduid":"5939043","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Columbia-Shuswap F","csduid":"5939044","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sicamous","csduid":"5939045","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Chum Creek 2","csduid":"5939801","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Hustalen 1","csduid":"5939802","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"North Bay 5","csduid":"5939803","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Okanagan (Part) 1","csduid":"5939804","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Quaaout 1","csduid":"5939805","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Salmon River 1","csduid":"5939806","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Scotch Creek 4","csduid":"5939807","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Switsemalph 3","csduid":"5939808","cmaname":"Salmon Arm","cmauid":"920","prname":"British Columbia"},
    {"csdname":"Switsemalph","csduid":"5939811","cmaname":"Salmon Arm","cmauid":"920","prname":"British Columbia"},
    {"csdname":"One Hundred Mile House","csduid":"5941005","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Williams Lake","csduid":"5941009","cmaname":"Williams Lake","cmauid":"950","prname":"British Columbia"},
    {"csdname":"Cariboo D","csduid":"5941010","cmaname":"Williams Lake","cmauid":"950","prname":"British Columbia"},
    {"csdname":"Cariboo E","csduid":"5941012","cmaname":"Williams Lake","cmauid":"950","prname":"British Columbia"},
    {"csdname":"Quesnel","csduid":"5941013","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Cariboo F","csduid":"5941014","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cariboo G","csduid":"5941015","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cariboo H","csduid":"5941016","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cariboo L","csduid":"5941017","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cariboo A","csduid":"5941019","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Cariboo B","csduid":"5941021","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Wells","csduid":"5941025","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Cariboo C","csduid":"5941026","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Cariboo I","csduid":"5941027","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Cariboo J","csduid":"5941039","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cariboo K","csduid":"5941041","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Alkali Lake 1","csduid":"5941801","cmaname":"Williams Lake","cmauid":"950","prname":"British Columbia"},
    {"csdname":"Canim Lake 1","csduid":"5941802","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Canim Lake 4","csduid":"5941803","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Canoe Creek 3","csduid":"5941804","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Deep Creek 2","csduid":"5941805","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Dog Creek 1","csduid":"5941806","cmaname":"Williams Lake","cmauid":"950","prname":"British Columbia"},
    {"csdname":"Dog Creek 2","csduid":"5941807","cmaname":"Williams Lake","cmauid":"950","prname":"British Columbia"},
    {"csdname":"Lohbiee 3","csduid":"5941808","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Quesnel 1","csduid":"5941809","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Soda Creek 1","csduid":"5941810","cmaname":"Williams Lake","cmauid":"950","prname":"British Columbia"},
    {"csdname":"Johny Sticks 2","csduid":"5941811","cmaname":"Williams Lake","cmauid":"950","prname":"British Columbia"},
    {"csdname":"Williams Lake 1","csduid":"5941812","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Canim Lake 2","csduid":"5941813","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Alexis Creek 14","csduid":"5941817","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Alexis Creek 16","csduid":"5941818","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Anahim's Flat 1","csduid":"5941821","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Charley Boy's Meadow 3","csduid":"5941827","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Chilco Lake 1","csduid":"5941828","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Chilco Lake 1A","csduid":"5941829","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tanakut 4","csduid":"5941831","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kluskus 1","csduid":"5941833","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Louis Squinas Ranch 14","csduid":"5941834","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Coglistiko River 29","csduid":"5941835","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Nazco 20","csduid":"5941837","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Puntzi Lake 2","csduid":"5941838","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Redstone Flat 1","csduid":"5941839","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Squinas 2","csduid":"5941840","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Stone 1","csduid":"5941841","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Agats Meadow 8","csduid":"5941844","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Thomas Squinas Ranch 2A","csduid":"5941845","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Alexis Creek 21","csduid":"5941848","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Baptiste Meadow 2","csduid":"5941849","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Toosey 1","csduid":"5941850","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Towdystan Lake 3","csduid":"5941851","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Trout Lake Alec 16","csduid":"5941852","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Ulkatcho 13","csduid":"5941854","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Windy Mouth 7","csduid":"5941855","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Alexis Creek 34","csduid":"5941856","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cahoose 10","csduid":"5941858","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Blackwater Meadow 11","csduid":"5941859","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cahoose 12","csduid":"5941860","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Betty Creek 18","csduid":"5941861","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Salmon River Meadow 7","csduid":"5941862","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tzetzi Lake 11","csduid":"5941863","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sundayman's Meadow 3","csduid":"5941864","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Euchinico Creek 17","csduid":"5941866","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Sandy Harry 4","csduid":"5941868","cmaname":"Williams Lake","cmauid":"950","prname":"British Columbia"},
    {"csdname":"Fishtrap 19","csduid":"5941871","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Swan Lake  3","csduid":"5941872","cmaname":"Williams Lake","cmauid":"950","prname":"British Columbia"},
    {"csdname":"Alkali Lake 4A","csduid":"5941873","cmaname":"Williams Lake","cmauid":"950","prname":"British Columbia"},
    {"csdname":"Lezbye  6","csduid":"5941876","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Michel Gardens 36","csduid":"5941879","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Ulkatcho 14A","csduid":"5941880","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Baezaeko River 27","csduid":"5941881","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Alexandria","csduid":"5941882","cmaname":"Quesnel","cmauid":"952","prname":"British Columbia"},
    {"csdname":"Anahim's Meadow","csduid":"5941883","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Garden","csduid":"5941884","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Little Springs","csduid":"5941885","cmaname":"Williams Lake","cmauid":"950","prname":"British Columbia"},
    {"csdname":"Alert Bay","csduid":"5943008","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Port McNeill","csduid":"5943012","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Port Alice","csduid":"5943017","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Port Hardy","csduid":"5943023","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Mount Waddington B","csduid":"5943027","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Mount Waddington C","csduid":"5943031","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Mount Waddington D","csduid":"5943033","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Mount Waddington A","csduid":"5943037","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fort Rupert 1","csduid":"5943804","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tsulquate 4","csduid":"5943806","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Dead Point 5","csduid":"5943807","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Gwayasdums 1","csduid":"5943808","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Hopetown 10A","csduid":"5943809","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Quaee 7","csduid":"5943813","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kippase 2","csduid":"5943815","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Quatsino Subdivision 18","csduid":"5943816","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Thomas Point 5","csduid":"5943817","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nimpkish 2","csduid":"5943835","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Hope Island 1","csduid":"5943836","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Alert Bay","csduid":"5943837","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Central Coast A","csduid":"5945006","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Central Coast C","csduid":"5945010","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Central Coast D","csduid":"5945012","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Central Coast E","csduid":"5945014","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Bella Bella 1","csduid":"5945801","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Bella Coola 1","csduid":"5945802","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Katit 1","csduid":"5945803","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Port Edward","csduid":"5947007","cmaname":"Prince Rupert","cmauid":"955","prname":"British Columbia"},
    {"csdname":"Prince Rupert","csduid":"5947012","cmaname":"Prince Rupert","cmauid":"955","prname":"British Columbia"},
    {"csdname":"Skeena-Queen Charlotte A","csduid":"5947016","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Skeena-Queen Charlotte C","csduid":"5947021","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Masset","csduid":"5947023","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Queen Charlotte","csduid":"5947026","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Skeena-Queen Charlotte D","csduid":"5947027","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Port Clements","csduid":"5947030","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Skeena-Queen Charlotte E","csduid":"5947032","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Masset 1","csduid":"5947803","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Skidegate 1","csduid":"5947804","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kulkayu (Hartley Bay) 4","csduid":"5947806","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Dolphin Island 1","csduid":"5947807","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Lax Kw'alaams 1","csduid":"5947809","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"S1/2 Tsimpsean 2","csduid":"5947810","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kitimat","csduid":"5949005","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Terrace","csduid":"5949011","cmaname":"Terrace","cmauid":"965","prname":"British Columbia"},
    {"csdname":"Kitimat-Stikine C (Part 1)","csduid":"5949013","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kitimat-Stikine E","csduid":"5949018","cmaname":"Terrace","cmauid":"965","prname":"British Columbia"},
    {"csdname":"Kitimat-Stikine C (Part 2)","csduid":"5949020","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Hazelton","csduid":"5949022","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"New Hazelton","csduid":"5949024","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kitimat-Stikine B","csduid":"5949028","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Stewart","csduid":"5949032","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nisga'a","csduid":"5949035","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kitimat-Stikine F","csduid":"5949038","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kitimat-Stikine A","csduid":"5949039","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kitimat-Stikine D","csduid":"5949041","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kitasoo 1","csduid":"5949802","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kitamaat 2","csduid":"5949803","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kitsumkaylum 1","csduid":"5949804","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kshish 4","csduid":"5949805","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kulspai 6","csduid":"5949807","cmaname":"Terrace","cmauid":"965","prname":"British Columbia"},
    {"csdname":"Coryatsaqua (Moricetown) 2","csduid":"5949810","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Hagwilget 1","csduid":"5949811","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Gitanmaax 1","csduid":"5949812","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kispiox 1","csduid":"5949813","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Gitsegukla 1","csduid":"5949814","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Gitanyow 1","csduid":"5949815","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Gitwangak 1","csduid":"5949816","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Moricetown 1","csduid":"5949817","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sik-e-dakh 2","csduid":"5949818","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Babine 17","csduid":"5949819","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Bulkley River 19","csduid":"5949820","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Iskut 6","csduid":"5949832","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Guhthe Tah 12","csduid":"5949843","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kitselas 1","csduid":"5949844","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Dease Lake 9","csduid":"5949845","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tahltan 1","csduid":"5949846","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Telegraph Creek","csduid":"5949847","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Vanderhoof","csduid":"5951007","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fraser Lake","csduid":"5951009","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fort St. James","csduid":"5951013","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Bulkley-Nechako C","csduid":"5951015","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Bulkley-Nechako D","csduid":"5951017","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Bulkley-Nechako F","csduid":"5951019","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Burns Lake","csduid":"5951022","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Bulkley-Nechako B","csduid":"5951028","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Bulkley-Nechako E","csduid":"5951031","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Granisle","csduid":"5951032","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Houston","csduid":"5951034","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Telkwa","csduid":"5951038","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Smithers","csduid":"5951043","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Bulkley-Nechako A","csduid":"5951051","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Bulkley-Nechako G","csduid":"5951053","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Ye Koo Che 3","csduid":"5951801","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nautley (Fort Fraser) 1","csduid":"5951802","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nak'azdli (Necoslie 1)","csduid":"5951803","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Sowchea 3","csduid":"5951804","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Binche 2 (Pinchie 2)","csduid":"5951805","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Seaspunkut 4","csduid":"5951806","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Stellaquo (Stella) 1","csduid":"5951807","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Stony Creek 1","csduid":"5951809","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tache 1","csduid":"5951810","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tacla Lake (Ferry Landing) 9","csduid":"5951811","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Laketown 3","csduid":"5951813","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Dzitline Lee 9","csduid":"5951814","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Burns Lake 18","csduid":"5951815","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Cheslatta 1","csduid":"5951818","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Omineca 1","csduid":"5951819","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Palling 1","csduid":"5951820","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Duncan Lake 2","csduid":"5951821","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Francois Lake 7","csduid":"5951822","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Skins Lake 16A","csduid":"5951823","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Skins Lake 16B","csduid":"5951824","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tatla West 11","csduid":"5951825","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Uncha Lake 13A","csduid":"5951826","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Woyenne 27","csduid":"5951827","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Babine  6","csduid":"5951828","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Babine 25","csduid":"5951829","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Jean Baptiste 28","csduid":"5951830","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tatla't East 2","csduid":"5951833","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Williams Prairie Meadow 1A","csduid":"5951840","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Poison Creek 17A","csduid":"5951844","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tadinlay 15","csduid":"5951845","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Nedoats 11","csduid":"5951846","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Babine Lake 21B","csduid":"5951847","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"North Tacla Lake","csduid":"5951848","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Mission Lands 17","csduid":"5951849","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Valemount","csduid":"5953007","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"McBride","csduid":"5953012","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fraser-Fort George H","csduid":"5953019","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Prince George","csduid":"5953023","cmaname":"Prince George","cmauid":"970","prname":"British Columbia"},
    {"csdname":"Mackenzie","csduid":"5953033","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fraser-Fort George A","csduid":"5953038","cmaname":"Prince George","cmauid":"970","prname":"British Columbia"},
    {"csdname":"Fraser-Fort George C","csduid":"5953042","cmaname":"Prince George","cmauid":"970","prname":"British Columbia"},
    {"csdname":"Fraser-Fort George D","csduid":"5953044","cmaname":"Prince George","cmauid":"970","prname":"British Columbia"},
    {"csdname":"Fraser-Fort George E","csduid":"5953046","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fraser-Fort George F","csduid":"5953048","cmaname":"Prince George","cmauid":"970","prname":"British Columbia"},
    {"csdname":"Fraser-Fort George G","csduid":"5953050","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fort George (Shelley) 2","csduid":"5953801","cmaname":"Prince George","cmauid":"970","prname":"British Columbia"},
    {"csdname":"McLeod Lake 1","csduid":"5953802","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Tumbler Ridge","csduid":"5955003","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Pouce Coupe","csduid":"5955005","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Chetwynd","csduid":"5955010","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Dawson Creek","csduid":"5955014","cmaname":"Dawson Creek","cmauid":"975","prname":"British Columbia"},
    {"csdname":"Peace River D","csduid":"5955021","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Peace River E","csduid":"5955023","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Hudson's Hope","csduid":"5955025","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Taylor","csduid":"5955030","cmaname":"Fort St. John","cmauid":"977","prname":"British Columbia"},
    {"csdname":"Fort St. John","csduid":"5955034","cmaname":"Fort St. John","cmauid":"977","prname":"British Columbia"},
    {"csdname":"Peace River B","csduid":"5955040","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Peace River C","csduid":"5955042","cmaname":"Fort St. John","cmauid":"977","prname":"British Columbia"},
    {"csdname":"East Moberly Lake 169","csduid":"5955801","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"West Moberly Lake 168A","csduid":"5955802","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Blueberry River 205","csduid":"5955803","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Doig River 206","csduid":"5955804","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fort Ware 1","csduid":"5955807","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Halfway River 168","csduid":"5955808","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Ingenika Point","csduid":"5955812","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Stikine Region","csduid":"5957022","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Unnamed 10","csduid":"5957802","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Five Mile Point  3","csduid":"5957803","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Good Hope Lake","csduid":"5957804","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Lower Post","csduid":"5957813","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Liard River 3","csduid":"5957814","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Northern Rockies","csduid":"5959007","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fontas 1","csduid":"5959805","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Fort Nelson 2","csduid":"5959806","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Kahntah 3","csduid":"5959809","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Prophet River 4","csduid":"5959810","cmaname":"","cmauid":"","prname":"British Columbia"},
    {"csdname":"Watson Lake","csduid":"6001003","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Faro","csduid":"6001004","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Teslin","csduid":"6001006","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Teslin Post 13","csduid":"6001007","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Carcross 4","csduid":"6001008","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Whitehorse","csduid":"6001009","cmaname":"Whitehorse","cmauid":"990","prname":"Yuk"},
    {"csdname":"Lake Laberge 1","csduid":"6001010","cmaname":"Whitehorse","cmauid":"990","prname":"Yuk"},
    {"csdname":"Carmacks","csduid":"6001012","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Klukshu","csduid":"6001016","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Haines Junction","csduid":"6001018","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Kloo Lake","csduid":"6001019","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Mayo","csduid":"6001022","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Dawson","csduid":"6001029","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Moosehide Creek 2","csduid":"6001031","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Upper Liard","csduid":"6001032","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Two Mile and Two and One-Half Mile Village","csduid":"6001035","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Tagish","csduid":"6001036","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Ross River","csduid":"6001037","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Champagne Landing 10","csduid":"6001038","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Burwash Landing","csduid":"6001039","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Pelly Crossing","csduid":"6001041","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Beaver Creek","csduid":"6001042","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Old Crow","csduid":"6001043","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Mt. Lorne","csduid":"6001044","cmaname":"Whitehorse","cmauid":"990","prname":"Yuk"},
    {"csdname":"Yukon, Unorganized","csduid":"6001045","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Swift River","csduid":"6001046","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Johnsons Crossing","csduid":"6001047","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Carcross","csduid":"6001048","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Destruction Bay","csduid":"6001049","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Stewart Crossing","csduid":"6001050","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Keno Hill","csduid":"6001052","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Ibex Valley","csduid":"6001055","cmaname":"Whitehorse","cmauid":"990","prname":"Yuk"},
    {"csdname":"Teslin","csduid":"6001057","cmaname":"","cmauid":"","prname":"Yuk"},
    {"csdname":"Marsh Lake","csduid":"6001058","cmaname":"Whitehorse","cmauid":"990","prname":"Yuk"},
    {"csdname":"Macpherson-Grizzly Valley","csduid":"6001059","cmaname":"Whitehorse","cmauid":"990","prname":"Yuk"},
    {"csdname":"Whitehorse, Unorganized","csduid":"6001060","cmaname":"Whitehorse","cmauid":"990","prname":"Yuk"},
    {"csdname":"Tsiigehtchic","csduid":"6101010","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Paulatuk","csduid":"6101014","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Fort McPherson","csduid":"6101015","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Inuvik","csduid":"6101017","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Aklavik","csduid":"6101025","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Tuktoyaktuk","csduid":"6101036","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Sachs Harbour","csduid":"6101041","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Region 1, Unorganized","csduid":"6101063","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Ulukhaktok","csduid":"6101095","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Déline","csduid":"6102003","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Tulita","csduid":"6102005","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Norman Wells","csduid":"6102007","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Fort Good Hope","csduid":"6102009","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Colville Lake","csduid":"6102012","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Region 2, Unorganized","csduid":"6102063","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Behchokò","csduid":"6103031","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Whatì","csduid":"6103034","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Gamètì","csduid":"6103049","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Wekweètì","csduid":"6103052","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Region 3, Unorganized","csduid":"6103097","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Kakisa","csduid":"6104005","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Sambaa K’e","csduid":"6104006","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Fort Liard","csduid":"6104009","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Nahanni Butte","csduid":"6104010","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Jean Marie River","csduid":"6104013","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Fort Providence","csduid":"6104014","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Hay River Dene 1","csduid":"6104017","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Fort Simpson","csduid":"6104038","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Wrigley","csduid":"6104044","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Region 4, Unorganized","csduid":"6104097","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Fort Smith","csduid":"6105001","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Enterprise","csduid":"6105003","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Salt Plains 195","csduid":"6105004","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Hay River","csduid":"6105016","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Fort Resolution","csduid":"6105018","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Lutselk'e","csduid":"6105020","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Reliance","csduid":"6105026","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Region 5, Unorganized","csduid":"6105097","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Detah","csduid":"6106021","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Yellowknife","csduid":"6106023","cmaname":"Yellowknife","cmauid":"995","prname":"Northwest Territories"},
    {"csdname":"Region 6, Unorganized","csduid":"6106097","cmaname":"","cmauid":"","prname":"Northwest Territories"},
    {"csdname":"Sanikiluaq","csduid":"6204001","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Iqaluit","csduid":"6204003","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Kimmirut","csduid":"6204005","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Cape Dorset","csduid":"6204007","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Pangnirtung","csduid":"6204009","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Qikiqtarjuaq","csduid":"6204010","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Hall Beach","csduid":"6204011","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Igloolik","csduid":"6204012","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Clyde River","csduid":"6204015","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Arctic Bay","csduid":"6204018","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Nanisivik","csduid":"6204019","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Pond Inlet","csduid":"6204020","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Resolute","csduid":"6204022","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Grise Fiord","csduid":"6204025","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Baffin, Unorganized","csduid":"6204030","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Coral Harbour","csduid":"6205014","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Arviat","csduid":"6205015","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Whale Cove","csduid":"6205016","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Rankin Inlet","csduid":"6205017","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Chesterfield Inlet","csduid":"6205019","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Baker Lake","csduid":"6205023","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Naujaat","csduid":"6205027","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Keewatin, Unorganized","csduid":"6205033","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Kugaaruk","csduid":"6208047","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Kugluktuk","csduid":"6208059","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Bathurst Inlet","csduid":"6208065","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Umingmaktok","csduid":"6208068","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Cambridge Bay","csduid":"6208073","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Gjoa Haven","csduid":"6208081","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Taloyoak","csduid":"6208087","cmaname":"","cmauid":"","prname":"Nunav"},
    {"csdname":"Kitikmeot, Unorganized","csduid":"6208098","cmaname":"","cmauid":"","prname":"Nunav"}];
export default censusGeography2016;