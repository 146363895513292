const censusGeography2021=[
    {"csdname" : "105 Mile Post 2", "csduid" : "5933845", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Abbey", "csduid" : "4708048", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Abbotsford", "csduid" : "5909052", "cmaname" : "", "cmauid" : "932", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Abercorn", "csduid" : "2446005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Aberdeen", "csduid" : "1311026", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Aberdeen", "csduid" : "4715019", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Aberdeen No. 373", "csduid" : "4715018", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Abernethy", "csduid" : "4706046", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Abernethy No. 186", "csduid" : "4706042", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Abitibi 70", "csduid" : "3556033", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Abram-Village", "csduid" : "1103027", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Acadia No. 34", "csduid" : "4804001", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Acadieville", "csduid" : "1308024", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Acme", "csduid" : "4805044", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Acton Vale", "csduid" : "2448028", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Addington", "csduid" : "1314012", "cmaname" : "", "cmauid" : "330", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Addington Highlands", "csduid" : "3511035", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Adelaide-Metcalfe", "csduid" : "3539047", "cmaname" : "", "cmauid" : "555", "prname" : "Ontario"},
    {"csdname" : "Adjala-Tosorontio", "csduid" : "3543003", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Admaston/Bromley", "csduid" : "3547043", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Admirals Beach", "csduid" : "1001186", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Adstock", "csduid" : "2431056", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Agats Meadow 8", "csduid" : "5941844", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Agency 1", "csduid" : "3559062", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Aguanish", "csduid" : "2498030", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Ahahswinis 1", "csduid" : "5923801", "cmaname" : "", "cmauid" : "940", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ahaminaquus 12", "csduid" : "5924803", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ahtahkakoop 104", "csduid" : "4716860", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Airdrie", "csduid" : "4806021", "cmaname" : "", "cmauid" : "825", "prname" : "Alberta"},
    {"csdname" : "Air Ronge", "csduid" : "4718042", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Aitchelitch 9", "csduid" : "5909835", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ajax", "csduid" : "3518005", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Aklavik", "csduid" : "6101025", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Akulivik", "csduid" : "2499125", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Akulivik", "csduid" : "2499883", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Akwesasne", "csduid" : "2469802", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Akwesasne (Part) 59", "csduid" : "3501007", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "A Kwis Ki Mahka", "csduid" : "4623023", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Alameda", "csduid" : "4701037", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Albanel", "csduid" : "2492030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Alberni 2", "csduid" : "5923802", "cmaname" : "", "cmauid" : "940", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alberni-Clayoquot A", "csduid" : "5923047", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alberni-Clayoquot B", "csduid" : "5923033", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alberni-Clayoquot C", "csduid" : "5923049", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alberni-Clayoquot D", "csduid" : "5923035", "cmaname" : "", "cmauid" : "940", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alberni-Clayoquot E", "csduid" : "5923037", "cmaname" : "", "cmauid" : "940", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alberni-Clayoquot F", "csduid" : "5923039", "cmaname" : "", "cmauid" : "940", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alberta Beach", "csduid" : "4813012", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Albert Flat 5", "csduid" : "5909876", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alberton", "csduid" : "1103052", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Alberton", "csduid" : "1103064", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Alberton", "csduid" : "3559011", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Albertville", "csduid" : "2407025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Albertville", "csduid" : "4715085", "cmaname" : "", "cmauid" : "745", "prname" : "Saskatchewan"},
    {"csdname" : "Alderville First Nation", "csduid" : "3514027", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Alert Bay", "csduid" : "5943008", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alert Bay", "csduid" : "5943837", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alexander", "csduid" : "4601071", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Alexander 134", "csduid" : "4811805", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Alexander 134B", "csduid" : "4813829", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Alexandra", "csduid" : "1102013", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Alexandria", "csduid" : "5941882", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alexis 133", "csduid" : "4813811", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Alexis 9", "csduid" : "5907808", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alexis Creek 14", "csduid" : "5941817", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alexis Creek 16", "csduid" : "5941818", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alexis Creek 21", "csduid" : "5941848", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alexis Creek 34", "csduid" : "5941856", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alexis Whitecourt 232", "csduid" : "4813830", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Alfred and Plantagenet", "csduid" : "3502023", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Algoma, Unorganized, North Part", "csduid" : "3557095", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Algoma, Unorganized, South East Part", "csduid" : "3557094", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Algonquin Highlands", "csduid" : "3546018", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Alice Beach", "csduid" : "4706065", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Alida", "csduid" : "4701041", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Alix", "csduid" : "4808034", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Alkali Lake 1", "csduid" : "5941801", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Alkali Lake 4A", "csduid" : "5941873", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Allan", "csduid" : "4711072", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Allardville", "csduid" : "1315006", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Alleyn-et-Cawood", "csduid" : "2484050", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Alliance", "csduid" : "4807032", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Allison Bay 219", "csduid" : "4816850", "cmaname" : "", "cmauid" : "860", "prname" : "Alberta"},
    {"csdname" : "Alma", "csduid" : "1306006", "cmaname" : "", "cmauid" : "999", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Alma", "csduid" : "1306007", "cmaname" : "", "cmauid" : "999", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Alma", "csduid" : "2493042", "cmaname" : "", "cmauid" : "410", "prname" : "Quebec / Québec"},
    {"csdname" : "Alnwick", "csduid" : "1309036", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Alnwick/Haldimand", "csduid" : "3514024", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Alonsa", "csduid" : "4617026", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Altona", "csduid" : "4603040", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Alvena", "csduid" : "4715038", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Amaranth", "csduid" : "3522008", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Amherst", "csduid" : "1211011", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Amherst", "csduid" : "2478070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Amherstburg", "csduid" : "3537028", "cmaname" : "", "cmauid" : "559", "prname" : "Ontario"},
    {"csdname" : "Amisk", "csduid" : "4807008", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Amiskosakahikan 210", "csduid" : "4718855", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Amos", "csduid" : "2488055", "cmaname" : "", "cmauid" : "481", "prname" : "Quebec / Québec"},
    {"csdname" : "Amqui", "csduid" : "2407047", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Anacla 12", "csduid" : "5923803", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Anahim's Flat 1", "csduid" : "5941821", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Anahim's Meadow", "csduid" : "5941883", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Anchor Point", "csduid" : "1009023", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Andover", "csduid" : "1312001", "cmaname" : "", "cmauid" : "999", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Andrew", "csduid" : "4810059", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Ange-Gardien", "csduid" : "2455008", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Anmore", "csduid" : "5915038", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Annaheim", "csduid" : "4715005", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Annandale - Little Pond - Howe Bay", "csduid" : "1101030", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Annapolis Royal", "csduid" : "1205008", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Annapolis, Subd. A", "csduid" : "1205004", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Annapolis, Subd. B", "csduid" : "1205009", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Annapolis, Subd. C", "csduid" : "1205014", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Annapolis, Subd. D", "csduid" : "1205001", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Annapolis Valley First Nation", "csduid" : "1207002", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Antelope Park No. 322", "csduid" : "4713016", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Antigonish", "csduid" : "1214002", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Antigonish, Subd. A", "csduid" : "1214001", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Antigonish, Subd. B", "csduid" : "1214006", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Antler No. 61", "csduid" : "4701047", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Appleton", "csduid" : "1006011", "cmaname" : "", "cmauid" : "011", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Aquadeo", "csduid" : "4717005", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Aquaforte", "csduid" : "1001144", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Arborfield", "csduid" : "4714062", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Arborfield No. 456", "csduid" : "4714059", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Arborg", "csduid" : "4618074", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Archerwill", "csduid" : "4714036", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Arcola", "csduid" : "4701059", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Arctic Bay", "csduid" : "6204018", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Argentia Beach", "csduid" : "4811003", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Argyle", "csduid" : "1202001", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Argyle", "csduid" : "4604057", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Argyle No. 1", "csduid" : "4701001", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Arlington No. 79", "csduid" : "4704038", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Armagh", "csduid" : "2419037", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Armour", "csduid" : "3549019", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Arm River No. 252", "csduid" : "4711011", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Armstrong", "csduid" : "3554036", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Armstrong", "csduid" : "4618037", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Armstrong", "csduid" : "5937028", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Arnold's Cove", "csduid" : "1001270", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Arnprior", "csduid" : "3547002", "cmaname" : "", "cmauid" : "505", "prname" : "Ontario"},
    {"csdname" : "Aroland", "csduid" : "3558076", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Aroostook", "csduid" : "1312002", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Arran", "csduid" : "4709076", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Arran-Elderslie", "csduid" : "3541043", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Arrowwood", "csduid" : "4805011", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Arundel", "csduid" : "2478060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Arviat", "csduid" : "6205015", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Ascot Corner", "csduid" : "2441055", "cmaname" : "", "cmauid" : "433", "prname" : "Quebec / Québec"},
    {"csdname" : "Ashcroft", "csduid" : "5933019", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ashcroft 4", "csduid" : "5933844", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ashfield-Colborne-Wawanosh", "csduid" : "3540063", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Ashnola 10", "csduid" : "5907809", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Asphodel-Norwood", "csduid" : "3515003", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Asquith", "csduid" : "4712059", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Assabaska", "csduid" : "3559046", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Assiginack", "csduid" : "3551011", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Assiniboia", "csduid" : "4703036", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Assiniboine 76", "csduid" : "4706810", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Aston-Jonction", "csduid" : "2450013", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Athabasca", "csduid" : "4813048", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Athabasca County", "csduid" : "4813044", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Athens", "csduid" : "3507042", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Atholville", "csduid" : "1314013", "cmaname" : "", "cmauid" : "330", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Atikokan", "csduid" : "3559001", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Attawapiskat 91A", "csduid" : "3560051", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Atwater", "csduid" : "4705053", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Auclair", "csduid" : "2413045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Audet", "csduid" : "2430055", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Augusta", "csduid" : "3507006", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Aumond", "csduid" : "2483090", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Aupaluk", "csduid" : "2499105", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Aupaluk", "csduid" : "2499891", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Aurora", "csduid" : "3519046", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Austin", "csduid" : "2445085", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Authier", "csduid" : "2487050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Authier-Nord", "csduid" : "2487100", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Auvergne No. 76", "csduid" : "4703054", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Avondale", "csduid" : "1001464", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Avonlea", "csduid" : "4702062", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Ayer's Cliff", "csduid" : "2445035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Aylesbury", "csduid" : "4707093", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Aylmer", "csduid" : "3534011", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Aylsham", "csduid" : "4714073", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Babine 16", "csduid" : "5951831", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Babine 17", "csduid" : "5949819", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Babine 25", "csduid" : "5951829", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Babine 6", "csduid" : "5951828", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Babine Lake 21B", "csduid" : "5951847", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Badger", "csduid" : "1006026", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Baezaeko River 27", "csduid" : "5941881", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Baie-Atibenne", "csduid" : "2462920", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Baie-Comeau", "csduid" : "2496020", "cmaname" : "", "cmauid" : "406", "prname" : "Quebec / Québec"},
    {"csdname" : "Baie-de-la-Bouteille", "csduid" : "2462906", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Baie-des-Chaloupes", "csduid" : "2479920", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Baie-des-Sables", "csduid" : "2408080", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Baie-d'Hudson", "csduid" : "2499904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Baie-du-Febvre", "csduid" : "2450100", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Baie-D'Urfé", "csduid" : "2466112", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Baie-Johan-Beetz", "csduid" : "2498035", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Baie-Obaoca", "csduid" : "2462918", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Baie-Sainte-Catherine", "csduid" : "2415065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Baie-Saint-Paul", "csduid" : "2416013", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Baie-Trinité", "csduid" : "2496005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Baie Verte", "csduid" : "1008079", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Baildon No. 131", "csduid" : "4707001", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Baine Harbour", "csduid" : "1002040", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Baker Lake", "csduid" : "6205023", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Balcarres", "csduid" : "4706047", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Baldwin", "csduid" : "3552028", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Balgonie", "csduid" : "4706032", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Balmoral", "csduid" : "1314005", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Balmoral", "csduid" : "1314006", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Bancroft", "csduid" : "3512061", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Banff", "csduid" : "4815035", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Bangor", "csduid" : "4705054", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Baptiste Meadow 2", "csduid" : "5941849", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Barkmere", "csduid" : "2478050", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Barnston Island 3", "csduid" : "5915809", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Barnston-Ouest", "csduid" : "2444045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Barnwell", "csduid" : "4802023", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Barons", "csduid" : "4802016", "cmaname" : "", "cmauid" : "810", "prname" : "Alberta"},
    {"csdname" : "Barraute", "csduid" : "2488022", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Barrhead", "csduid" : "4813019", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Barrhead County No. 11", "csduid" : "4813018", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Barrie", "csduid" : "3543042", "cmaname" : "", "cmauid" : "568", "prname" : "Ontario"},
    {"csdname" : "Barriere", "csduid" : "5933074", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Barrier Valley No. 397", "csduid" : "4714034", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Barrington", "csduid" : "1201001", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Bas-Caraquet", "csduid" : "1315027", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Bashaw", "csduid" : "4810002", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Basque 18", "csduid" : "5933828", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Bassano", "csduid" : "4802039", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Bath", "csduid" : "1311030", "cmaname" : "", "cmauid" : "999", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Bathurst", "csduid" : "1315008", "cmaname" : "", "cmauid" : "328", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Bathurst", "csduid" : "1315011", "cmaname" : "", "cmauid" : "328", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Bathurst Inlet", "csduid" : "6208065", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Batiscan", "csduid" : "2437210", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Battleford", "csduid" : "4712079", "cmaname" : "", "cmauid" : "735", "prname" : "Saskatchewan"},
    {"csdname" : "Battle River No. 438", "csduid" : "4712078", "cmaname" : "", "cmauid" : "735", "prname" : "Saskatchewan"},
    {"csdname" : "Bauline", "csduid" : "1001512", "cmaname" : "", "cmauid" : "001", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Bawlf", "csduid" : "4810009", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Bay Bulls", "csduid" : "1001557", "cmaname" : "", "cmauid" : "001", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Bay de Verde", "csduid" : "1001347", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Bayham", "csduid" : "3534005", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Bay L'Argent", "csduid" : "1002027", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Bayne No. 371", "csduid" : "4715011", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Bay Roberts", "csduid" : "1001409", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Baytona", "csduid" : "1008040", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Beachside", "csduid" : "1008066", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Beaconsfield", "csduid" : "2466107", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Beardy's 97 and Okemasis 96", "csduid" : "4715845", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Beardy's and Okemasis 96 & 97C", "csduid" : "4716840", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Beardy's and Okemasis 96 and 97A", "csduid" : "4710828", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Beardy's and Okemasis 96 and 97B", "csduid" : "4715859", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Bear Island 1", "csduid" : "3548072", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Béarn", "csduid" : "2485020", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Bear River 6B", "csduid" : "1205006", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Bear River (Part) 6", "csduid" : "1203009", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Bear River (Part) 6", "csduid" : "1205002", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Bearskin Lake", "csduid" : "3560095", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Beatty", "csduid" : "4715052", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Beaubassin East / Beaubassin-est", "csduid" : "1307005", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Beauceville", "csduid" : "2427028", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Beauharnois", "csduid" : "2470022", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Beaulac-Garthby", "csduid" : "2431008", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Beaumont", "csduid" : "2419105", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Beaumont", "csduid" : "4811013", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Beaupré", "csduid" : "2421025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Beausejour", "csduid" : "4612056", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Beauval", "csduid" : "4718033", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Beaver County", "csduid" : "4810016", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Beaver Creek", "csduid" : "6001042", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Beaver Flat", "csduid" : "4707020", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Beaver Lake 131", "csduid" : "4812828", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Beaver Lake 17", "csduid" : "1209037", "cmaname" : "", "cmauid" : "205", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Beaverlodge", "csduid" : "4819009", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Beaver Ranch 163", "csduid" : "4817841", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Beaver River No. 622", "csduid" : "4717062", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Bécancour", "csduid" : "2438010", "cmaname" : "", "cmauid" : "442", "prname" : "Quebec / Québec"},
    {"csdname" : "Becher Bay 1", "csduid" : "5917809", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Beckwith", "csduid" : "3509024", "cmaname" : "", "cmauid" : "505", "prname" : "Ontario"},
    {"csdname" : "Bedeque and Area", "csduid" : "1103015", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Bedford", "csduid" : "2446035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Bedford", "csduid" : "2446040", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Beechy", "csduid" : "4707062", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Bégin", "csduid" : "2494250", "cmaname" : "", "cmauid" : "408", "prname" : "Quebec / Québec"},
    {"csdname" : "Behchokò", "csduid" : "6103031", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Beiseker", "csduid" : "4806024", "cmaname" : "", "cmauid" : "825", "prname" : "Alberta"},
    {"csdname" : "Belcarra", "csduid" : "5915036", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Belcourt", "csduid" : "2489050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Belfast", "csduid" : "1102002", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Bella Bella 1", "csduid" : "5945801", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Bella Coola 1", "csduid" : "5945802", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Bellburns", "csduid" : "1009042", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Belledune", "csduid" : "1314025", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Belleoram", "csduid" : "1003004", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Belle Plaine", "csduid" : "4706022", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Belle-Rivière", "csduid" : "2493908", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Belleterre", "csduid" : "2485065", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Belleville", "csduid" : "3512005", "cmaname" : "", "cmauid" : "522", "prname" : "Ontario"},
    {"csdname" : "Beloeil", "csduid" : "2457040", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Bengough", "csduid" : "4702023", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Bengough No. 40", "csduid" : "4702024", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Benson No. 35", "csduid" : "4701027", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Bentley", "csduid" : "4808026", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Berens River 13", "csduid" : "4619077", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Beresford", "csduid" : "1315012", "cmaname" : "", "cmauid" : "328", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Beresford", "csduid" : "1315015", "cmaname" : "", "cmauid" : "328", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Berry", "csduid" : "2488070", "cmaname" : "", "cmauid" : "481", "prname" : "Quebec / Québec"},
    {"csdname" : "Berthier-sur-Mer", "csduid" : "2418065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Berthierville", "csduid" : "2452035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Bertrand", "csduid" : "1315036", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Berwick", "csduid" : "1207004", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Berwyn", "csduid" : "4819072", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Béthanie", "csduid" : "2448005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Bethune", "csduid" : "4706061", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Betty Creek 18", "csduid" : "5941861", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Betula Beach", "csduid" : "4811039", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Biencourt", "csduid" : "2413055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Bienfait", "csduid" : "4701021", "cmaname" : "", "cmauid" : "750", "prname" : "Saskatchewan"},
    {"csdname" : "Bifrost-Riverton", "csduid" : "4618076", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Big Arm No. 251", "csduid" : "4711006", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Biggar", "csduid" : "4712046", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Biggar No. 347", "csduid" : "4712042", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Big Grassy River 35G", "csduid" : "3559051", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Big Hole Tract 8 (North Half)", "csduid" : "1309033", "cmaname" : "", "cmauid" : "329", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Big Hole Tract 8 (South Half)", "csduid" : "1309034", "cmaname" : "", "cmauid" : "329", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Big Horn 144A", "csduid" : "4809810", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Bighorn No. 8", "csduid" : "4815015", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Big Island Lake Cree Territory", "csduid" : "4717811", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Big Island Mainland 93", "csduid" : "3559052", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Big Lakes County", "csduid" : "4817027", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Big Quill No. 308", "csduid" : "4710046", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Big River", "csduid" : "4716072", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Big River 118", "csduid" : "4716858", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Big River 118A", "csduid" : "4716859", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Big River No. 555", "csduid" : "4716075", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Big Shell", "csduid" : "4716059", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Big Stick No. 141", "csduid" : "4708016", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Big Valley", "csduid" : "4807021", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Billings", "csduid" : "3551021", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Binche 2", "csduid" : "5951805", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Birchcliff", "csduid" : "4808023", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Birch Cove", "csduid" : "4813013", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Birch Hills", "csduid" : "4715068", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Birch Hills County", "csduid" : "4819049", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Birch Hills No. 460", "csduid" : "4715067", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Birchy Bay", "csduid" : "1008038", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Bird Cove", "csduid" : "1009026", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Bird's Point", "csduid" : "4705055", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Birdtail Creek 57", "csduid" : "4615042", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Bishop's Cove", "csduid" : "1001394", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Bishop's Falls", "csduid" : "1006019", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Bittern Lake", "csduid" : "4810012", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Bjorkdale", "csduid" : "4714041", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Bjorkdale No. 426", "csduid" : "4714038", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Black Diamond", "csduid" : "4806011", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Blackfalds", "csduid" : "4808029", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Black Point", "csduid" : "4718089", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Black River 9", "csduid" : "4619054", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Black River-Matheson", "csduid" : "3556014", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Blacks Harbour", "csduid" : "1302012", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Black Sturgeon", "csduid" : "4623800", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Blackville", "csduid" : "1309018", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Blackville", "csduid" : "1309019", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Bladworth", "csduid" : "4711038", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Blaine Lake", "csduid" : "4716016", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Blaine Lake No. 434", "csduid" : "4716013", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Blainville", "csduid" : "2473015", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Blanc-Sablon", "csduid" : "2498005", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Blandford-Blenheim", "csduid" : "3532045", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Blind Creek 6", "csduid" : "5907806", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Blind River", "csduid" : "3557038", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Blissfield", "csduid" : "1309021", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Blissville", "csduid" : "1303001", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Blood 148", "csduid" : "4803802", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Blood 148A", "csduid" : "4803803", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Bloodvein 12", "csduid" : "4619052", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Blucher No. 343", "csduid" : "4711069", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Blueberry River 205", "csduid" : "5955803", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Blue Sea", "csduid" : "2483045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Bluewater", "csduid" : "3540010", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Boileau", "csduid" : "2480115", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Boisbriand", "csduid" : "2473005", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Boischatel", "csduid" : "2421045", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Bois-des-Filion", "csduid" : "2473030", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Bois-Franc", "csduid" : "2483085", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Boissevain-Morton", "csduid" : "4605032", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Bolton-Est", "csduid" : "2445095", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Bolton-Ouest", "csduid" : "2446065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Bon Accord", "csduid" : "4811066", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Bonaparte 3", "csduid" : "5933812", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Bonaventure", "csduid" : "2405045", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Bonavista", "csduid" : "1007023", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Bondiss", "csduid" : "4813053", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Bone Creek No. 108", "csduid" : "4704058", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Bonfield", "csduid" : "3548027", "cmaname" : "", "cmauid" : "575", "prname" : "Ontario"},
    {"csdname" : "Bonnechere Valley", "csduid" : "3547035", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Bonne-Espérance", "csduid" : "2498010", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Bonnyville", "csduid" : "4812009", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Bonnyville Beach", "csduid" : "4812011", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Bonnyville No. 87", "csduid" : "4812004", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Bonsecours", "csduid" : "2442040", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Boothroyd 13", "csduid" : "5909850", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Boothroyd 8A", "csduid" : "5933897", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Borden", "csduid" : "1103002", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Borden", "csduid" : "4716011", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Borden-Carleton", "csduid" : "1103005", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Boston Bar 1A", "csduid" : "5909836", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Botsford", "csduid" : "1307004", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Botwood", "csduid" : "1006022", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Boucherville", "csduid" : "2458033", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Bouchette", "csduid" : "2483050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Bouctouche", "csduid" : "1308005", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Bowden", "csduid" : "4808006", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Bowen Island", "csduid" : "5915062", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Bow Island", "csduid" : "4801014", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Bowman", "csduid" : "2480145", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Boyer 164", "csduid" : "4817842", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Boyle", "csduid" : "4813046", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Brabant Lake", "csduid" : "4718825", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Bracebridge", "csduid" : "3544018", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Bracken", "csduid" : "4704008", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Brackley", "csduid" : "1102055", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Bradford West Gwillimbury", "csduid" : "3543014", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Bradwell", "csduid" : "4711071", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Brampton", "csduid" : "3521010", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Branch", "csduid" : "1001219", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Brandon", "csduid" : "4607062", "cmaname" : "", "cmauid" : "610", "prname" : "Manitoba"},
    {"csdname" : "Brant", "csduid" : "3529005", "cmaname" : "", "cmauid" : "543", "prname" : "Ontario"},
    {"csdname" : "Brantford", "csduid" : "3529006", "cmaname" : "", "cmauid" : "543", "prname" : "Ontario"},
    {"csdname" : "Bratt's Lake No. 129", "csduid" : "4706013", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Brazeau County", "csduid" : "4811032", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Breadalbane", "csduid" : "1102051", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Brébeuf", "csduid" : "2478075", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Bredenbury", "csduid" : "4705079", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Brenda-Waskada", "csduid" : "4605044", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Brent's Cove", "csduid" : "1008077", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Brethour", "csduid" : "3554032", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Breton", "csduid" : "4811026", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Bridge River 1", "csduid" : "5931813", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Bridgewater", "csduid" : "1206004", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Briercrest", "csduid" : "4706018", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Brigham", "csduid" : "2446090", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Bright", "csduid" : "1310026", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Brighton", "csduid" : "1008055", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Brighton", "csduid" : "1311011", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Brighton", "csduid" : "3514004", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Brigus", "csduid" : "1001446", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Bristol", "csduid" : "2484005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Britannia No. 502", "csduid" : "4717028", "cmaname" : "", "cmauid" : "840", "prname" : "Saskatchewan"},
    {"csdname" : "Broadview", "csduid" : "4705031", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Brochet 197", "csduid" : "4623065", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Brock", "csduid" : "3518039", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Brock", "csduid" : "4713002", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Brock No. 64", "csduid" : "4701058", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Brockton", "csduid" : "3541032", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Brockville", "csduid" : "3507015", "cmaname" : "", "cmauid" : "512", "prname" : "Ontario"},
    {"csdname" : "Broderick", "csduid" : "4711028", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Brokenhead", "csduid" : "4612054", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Brokenhead 4", "csduid" : "4613062", "cmaname" : "", "cmauid" : "602", "prname" : "Manitoba"},
    {"csdname" : "Brokenshell No. 68", "csduid" : "4702051", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Brome", "csduid" : "2446070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Bromont", "csduid" : "2446078", "cmaname" : "", "cmauid" : "450", "prname" : "Quebec / Québec"},
    {"csdname" : "Brooke-Alvinston", "csduid" : "3538015", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Brooks", "csduid" : "4802034", "cmaname" : "", "cmauid" : "806", "prname" : "Alberta"},
    {"csdname" : "Brossard", "csduid" : "2458007", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Browning No. 34", "csduid" : "4701031", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Brownlee", "csduid" : "4707048", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Brownsburg-Chatham", "csduid" : "2476043", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Bruce Mines", "csduid" : "3557021", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Brudenell, Lyndoch and Raglan", "csduid" : "3547020", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Bruderheim", "csduid" : "4810066", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Bruno", "csduid" : "4715012", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Brunswick", "csduid" : "1304016", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Bryant's Cove", "csduid" : "1001375", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Bryson", "csduid" : "2484025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "B-Say-Tah", "csduid" : "4706052", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Buchanan", "csduid" : "4709053", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Buchanan No. 304", "csduid" : "4709049", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Buchans", "csduid" : "1006031", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Buckland No. 491", "csduid" : "4715094", "cmaname" : "", "cmauid" : "745", "prname" : "Saskatchewan"},
    {"csdname" : "Bucktum 4", "csduid" : "5909847", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Buctouche 16", "csduid" : "1308003", "cmaname" : "", "cmauid" : "999", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Buena Vista", "csduid" : "4706055", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Buffalo Narrows", "csduid" : "4718070", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Buffalo No. 409", "csduid" : "4713068", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Buffalo Point 36", "csduid" : "4601070", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Buffalo River Dene Nation", "csduid" : "4718818", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Bulkley-Nechako A", "csduid" : "5951051", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Bulkley-Nechako B", "csduid" : "5951028", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Bulkley-Nechako C", "csduid" : "5951015", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Bulkley-Nechako D", "csduid" : "5951017", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Bulkley-Nechako E", "csduid" : "5951031", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Bulkley-Nechako F", "csduid" : "5951019", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Bulkley-Nechako G", "csduid" : "5951053", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Bulkley River 19", "csduid" : "5949820", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Bulyea", "csduid" : "4706073", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Burgeo", "csduid" : "1003028", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Burin", "csduid" : "1002005", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Burk's Falls", "csduid" : "3549022", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Burlington", "csduid" : "1008071", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Burlington", "csduid" : "3524002", "cmaname" : "", "cmauid" : "537", "prname" : "Ontario"},
    {"csdname" : "Burnaby", "csduid" : "5915025", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Burns Lake", "csduid" : "5951022", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Burns Lake 18", "csduid" : "5951815", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Burnstick Lake", "csduid" : "4809005", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Burnt Islands", "csduid" : "1003033", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Burpee and Mills", "csduid" : "3551028", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Burrard Inlet 3", "csduid" : "5915806", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Burstall", "csduid" : "4708062", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Burton", "csduid" : "1303011", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Burwash Landing", "csduid" : "6001039", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Bury", "csduid" : "2441070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Bushe River 207", "csduid" : "4817848", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Cabri", "csduid" : "4708032", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Cache Creek", "csduid" : "5933024", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cacouna", "csduid" : "2412057", "cmaname" : "", "cmauid" : "405", "prname" : "Quebec / Québec"},
    {"csdname" : "Cadillac", "csduid" : "4704029", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Calder", "csduid" : "4709002", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Calder No. 241", "csduid" : "4709001", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Caledon", "csduid" : "3521024", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Caledonia No. 99", "csduid" : "4702066", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Calgary", "csduid" : "4806016", "cmaname" : "", "cmauid" : "825", "prname" : "Alberta"},
    {"csdname" : "Calixa-Lavallée", "csduid" : "2459030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Callander", "csduid" : "3549066", "cmaname" : "", "cmauid" : "575", "prname" : "Ontario"},
    {"csdname" : "Calmar", "csduid" : "4811019", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Calvin", "csduid" : "3548022", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Cambria No. 6", "csduid" : "4702001", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Cambridge", "csduid" : "1304011", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Cambridge", "csduid" : "3530010", "cmaname" : "", "cmauid" : "541", "prname" : "Ontario"},
    {"csdname" : "Cambridge Bay", "csduid" : "6208073", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Cambridge-Narrows", "csduid" : "1304013", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Campbell River", "csduid" : "5924034", "cmaname" : "", "cmauid" : "944", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Campbell River 11", "csduid" : "5924804", "cmaname" : "", "cmauid" : "944", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Campbell's Bay", "csduid" : "2484030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Campbellton", "csduid" : "1008037", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Campbellton", "csduid" : "1314014", "cmaname" : "", "cmauid" : "330", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Campobello Island", "csduid" : "1302004", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Camrose", "csduid" : "4810011", "cmaname" : "", "cmauid" : "833", "prname" : "Alberta"},
    {"csdname" : "Camrose County", "csduid" : "4810001", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Canaan No. 225", "csduid" : "4707058", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Canal Flats", "csduid" : "5901043", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cana No. 214", "csduid" : "4705073", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Candiac", "csduid" : "2467020", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Candle Lake", "csduid" : "4715070", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Caniapiscau", "csduid" : "2497908", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Canim Lake 1", "csduid" : "5941802", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Canim Lake 2", "csduid" : "5941813", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Canim Lake 4", "csduid" : "5941803", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Canmore", "csduid" : "4815023", "cmaname" : "", "cmauid" : "828", "prname" : "Alberta"},
    {"csdname" : "Canning", "csduid" : "1304021", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Canoe Creek 1", "csduid" : "5933814", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Canoe Creek 2", "csduid" : "5933821", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Canoe Creek 3", "csduid" : "5941804", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Canoe Lake 165", "csduid" : "4718817", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Canora", "csduid" : "4709032", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Canterbury", "csduid" : "1310011", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Canterbury", "csduid" : "1310012", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Cantley", "csduid" : "2482020", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Canupawakpa Dakota First Nation (Oak Lake 59)", "csduid" : "4606029", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Canwood", "csduid" : "4716052", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Canwood No. 494", "csduid" : "4716051", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Cap-Chat", "csduid" : "2404047", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Cape Breton", "csduid" : "1217030", "cmaname" : "", "cmauid" : "225", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Cape Broyle", "csduid" : "1001126", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Cape Dorset", "csduid" : "6204007", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Cape Mudge 10", "csduid" : "5924817", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cape St. George", "csduid" : "1004033", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Capilano 5", "csduid" : "5915808", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Caplan", "csduid" : "2405060", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Cap-Pelé", "csduid" : "1307007", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Cap-Saint-Ignace", "csduid" : "2418045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Cap-Santé", "csduid" : "2434030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Caraquet", "csduid" : "1315026", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Caraquet", "csduid" : "1315028", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Carberry", "csduid" : "4607068", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Carbon", "csduid" : "4805042", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Carbonear", "csduid" : "1001370", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Carcajou 187", "csduid" : "4817854", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Carcross", "csduid" : "6001048", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Carcross 4", "csduid" : "6001008", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Cardigan", "csduid" : "1101020", "cmaname" : "", "cmauid" : "999", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Cardston", "csduid" : "4803004", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Cardston County", "csduid" : "4803001", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Cardwell", "csduid" : "1305026", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Cariboo A", "csduid" : "5941019", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cariboo B", "csduid" : "5941021", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cariboo C", "csduid" : "5941026", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cariboo D", "csduid" : "5941010", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cariboo E", "csduid" : "5941012", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cariboo F", "csduid" : "5941014", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cariboo G", "csduid" : "5941015", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cariboo H", "csduid" : "5941016", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cariboo I", "csduid" : "5941027", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cariboo J", "csduid" : "5941039", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cariboo K", "csduid" : "5941041", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cariboo L", "csduid" : "5941017", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Carievale", "csduid" : "4701004", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Carignan", "csduid" : "2457010", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Carleton", "csduid" : "1308026", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Carleton Place", "csduid" : "3509028", "cmaname" : "", "cmauid" : "505", "prname" : "Ontario"},
    {"csdname" : "Carleton-sur-Mer", "csduid" : "2406013", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Carling", "csduid" : "3549036", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Carlow/Mayo", "csduid" : "3512065", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Carlyle", "csduid" : "4701056", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Carmacks", "csduid" : "6001012", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Carman", "csduid" : "4603074", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Carmangay", "csduid" : "4805002", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Carmanville", "csduid" : "1008011", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Carmichael No. 109", "csduid" : "4704054", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Carnduff", "csduid" : "4701008", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Caroline", "csduid" : "4809010", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Caron No. 162", "csduid" : "4707036", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Caronport", "csduid" : "4707037", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Carrot River", "csduid" : "4714069", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Carrot River 29A", "csduid" : "4714845", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Carry the Kettle 76-110", "csduid" : "4706831", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Carry the Kettle 76-19", "csduid" : "4706830", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Carstairs", "csduid" : "4806029", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Cartier", "csduid" : "4610043", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Cartwright, Labrador", "csduid" : "1010012", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Cartwright-Roblin", "csduid" : "4604052", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Cascades-Malignes", "csduid" : "2483904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Cascapédia--Saint-Jules", "csduid" : "2405077", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Casey", "csduid" : "3554029", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Casselman", "csduid" : "3502044", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Cassimayooks (Mayook) 5", "csduid" : "5901805", "cmaname" : "", "cmauid" : "905", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Castlegar", "csduid" : "5903045", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Castle Island", "csduid" : "4813009", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Castor", "csduid" : "4807014", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Cat Lake 63C", "csduid" : "3560054", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Causapscal", "csduid" : "2407018", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Cavan Monaghan", "csduid" : "3515013", "cmaname" : "", "cmauid" : "529", "prname" : "Ontario"},
    {"csdname" : "Cayamant", "csduid" : "2483040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Cayoosh Creek 1", "csduid" : "5931814", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Butte", "csduid" : "4707054", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Central Coast A", "csduid" : "5945006", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Coast C", "csduid" : "5945010", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Coast D", "csduid" : "5945012", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Coast E", "csduid" : "5945014", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Elgin", "csduid" : "3534020", "cmaname" : "", "cmauid" : "555", "prname" : "Ontario"},
    {"csdname" : "Central Frontenac", "csduid" : "3510035", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Central Huron", "csduid" : "3540025", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Central Kings", "csduid" : "1101025", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Central Kings", "csduid" : "1101027", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Central Kootenay A", "csduid" : "5903010", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Kootenay B", "csduid" : "5903013", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Kootenay C", "csduid" : "5903017", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Kootenay D", "csduid" : "5903039", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Kootenay E", "csduid" : "5903041", "cmaname" : "", "cmauid" : "907", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Kootenay F", "csduid" : "5903043", "cmaname" : "", "cmauid" : "907", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Kootenay G", "csduid" : "5903047", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Kootenay H", "csduid" : "5903052", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Kootenay I", "csduid" : "5903056", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Kootenay J", "csduid" : "5903058", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Kootenay K", "csduid" : "5903060", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Manitoulin", "csduid" : "3551006", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Central Okanagan", "csduid" : "5935012", "cmaname" : "", "cmauid" : "915", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Okanagan West", "csduid" : "5935020", "cmaname" : "", "cmauid" : "915", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Central Prince", "csduid" : "1103043", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Central Saanich", "csduid" : "5917015", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Centre Hastings", "csduid" : "3512026", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Centreville", "csduid" : "1311019", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Centreville-Wareham-Trinity", "csduid" : "1007050", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Centre Wellington", "csduid" : "3523025", "cmaname" : "", "cmauid" : "531", "prname" : "Ontario"},
    {"csdname" : "Ceylon", "csduid" : "4702028", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Chamberlain", "csduid" : "3554054", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Chamberlain", "csduid" : "4706064", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Chambly", "csduid" : "2457005", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Chambord", "csduid" : "2491020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Champagne Landing 10", "csduid" : "6001038", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Champion", "csduid" : "4805004", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Champlain", "csduid" : "2437220", "cmaname" : "", "cmauid" : "442", "prname" : "Quebec / Québec"},
    {"csdname" : "Champlain", "csduid" : "3502010", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Champneuf", "csduid" : "2488005", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Chance Cove", "csduid" : "1001281", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Chandler", "csduid" : "2402028", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Change Islands", "csduid" : "1008024", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Channel-Port aux Basques", "csduid" : "1003034", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Chapais", "csduid" : "2499020", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Chapel Arm", "csduid" : "1001289", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Chapel Island 5", "csduid" : "1216014", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Chapleau", "csduid" : "3552092", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Chapleau 74A", "csduid" : "3552053", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Chapleau 75", "csduid" : "3552058", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Chapleau Cree Fox Lake", "csduid" : "3552056", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Chaplin", "csduid" : "4707031", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Chaplin No. 164", "csduid" : "4707029", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Chapple", "csduid" : "3559024", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Charette", "csduid" : "2451080", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Charlemagne", "csduid" : "2460005", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Charley Boy's Meadow 3", "csduid" : "5941827", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Charlo", "csduid" : "1314004", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Charlottetown", "csduid" : "1102075", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Charlottetown (Labrador)", "csduid" : "1010013", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Charlton and Dack", "csduid" : "3554044", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Chartierville", "csduid" : "2441020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Chase", "csduid" : "5933054", "cmaname" : "", "cmauid" : "925", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Châteauguay", "csduid" : "2467050", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Château-Richer", "csduid" : "2421035", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Chatham", "csduid" : "1309006", "cmaname" : "", "cmauid" : "329", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Chatham-Kent", "csduid" : "3536020", "cmaname" : "", "cmauid" : "556", "prname" : "Ontario"},
    {"csdname" : "Chatsworth", "csduid" : "3542037", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Chauvin", "csduid" : "4807051", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Chawathil 4", "csduid" : "5909804", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Chazel", "csduid" : "2487095", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Cheakamus 11", "csduid" : "5931801", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cheam 1", "csduid" : "5909837", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Chehalis 5", "csduid" : "5909839", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Chekwelp 26", "csduid" : "5929801", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Chelsea", "csduid" : "2482025", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Chemainus 13", "csduid" : "5919804", "cmaname" : "", "cmauid" : "936", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Chemawawin 2", "csduid" : "4621029", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Chemawawin 3", "csduid" : "4619082", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Chenahkint 12", "csduid" : "5924805", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Chénéville", "csduid" : "2480103", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Chertsey", "csduid" : "2462047", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Cheslatta 1", "csduid" : "5951818", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Chester", "csduid" : "1206009", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Chesterfield Inlet", "csduid" : "6205019", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Chesterfield No. 261", "csduid" : "4708065", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Chestermere", "csduid" : "4806017", "cmaname" : "", "cmauid" : "825", "prname" : "Alberta"},
    {"csdname" : "Chester No. 125", "csduid" : "4705018", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Chesterville", "csduid" : "2439030", "cmaname" : "", "cmauid" : "440", "prname" : "Quebec / Québec"},
    {"csdname" : "Chetwynd", "csduid" : "5955010", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Chibougamau", "csduid" : "2499025", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Chichester", "csduid" : "2484090", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Chicken 224", "csduid" : "4718828", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Chicken 225", "csduid" : "4718823", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Chief's Point No. 28", "csduid" : "3541056", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Chilco Lake 1", "csduid" : "5941828", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Chilco Lake 1A", "csduid" : "5941829", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Child Lake 164A", "csduid" : "4817843", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Chilhil 6", "csduid" : "5931815", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Chilliwack", "csduid" : "5909020", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Chipman", "csduid" : "1304024", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Chipman", "csduid" : "1304025", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Chipman", "csduid" : "4810062", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Chippewas of Georgina Island First Nation", "csduid" : "3519076", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Chippewas of Rama First Nation", "csduid" : "3543050", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Chippewas of the Thames First Nation 42", "csduid" : "3539017", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Chisasibi", "csduid" : "2499055", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Chisasibi", "csduid" : "2499814", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Chisholm", "csduid" : "3548031", "cmaname" : "", "cmauid" : "575", "prname" : "Ontario"},
    {"csdname" : "Chitek Lake", "csduid" : "4716077", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Chitek Lake 191", "csduid" : "4716863", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Choiceland", "csduid" : "4714092", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Chopaka 7 & 8", "csduid" : "5907805", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Chorney Beach", "csduid" : "4710034", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Christian Island 30", "csduid" : "3543069", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Christian Island 30A", "csduid" : "3543070", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Christopher Lake", "csduid" : "4715076", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Chuchhraischin", "csduid" : "5933810", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Chuchuwayha 2", "csduid" : "5907807", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Chum Creek 2", "csduid" : "5939801", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Churchbridge", "csduid" : "4705094", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Churchbridge No. 211", "csduid" : "4705093", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Churchill", "csduid" : "4623056", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Churchill 1", "csduid" : "4623058", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Chute-aux-Outardes", "csduid" : "2496035", "cmaname" : "", "cmauid" : "406", "prname" : "Quebec / Québec"},
    {"csdname" : "Chute-Saint-Philippe", "csduid" : "2479065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Clakamucus 2", "csduid" : "5923804", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Clanwilliam-Erickson", "csduid" : "4615092", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Clare", "csduid" : "1203001", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Clarence-Rockland", "csduid" : "3502036", "cmaname" : "", "cmauid" : "505", "prname" : "Ontario"},
    {"csdname" : "Clarendon", "csduid" : "1302014", "cmaname" : "", "cmauid" : "999", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Clarendon", "csduid" : "2484015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Clarenville", "csduid" : "1007013", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Claresholm", "csduid" : "4803022", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Clarington", "csduid" : "3518017", "cmaname" : "", "cmauid" : "532", "prname" : "Ontario"},
    {"csdname" : "Clarke's Beach", "csduid" : "1001429", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Clark's Harbour", "csduid" : "1201004", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Clavet", "csduid" : "4711077", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Clayton No. 333", "csduid" : "4709067", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Clear Hills", "csduid" : "4817062", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Clear Hills 152C", "csduid" : "4817825", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Clearview", "csduid" : "3543005", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Clearwater", "csduid" : "5933067", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Clearwater County", "csduid" : "4809002", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Clearwater River Dene 222", "csduid" : "4718839", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Clearwater River Dene Band 223", "csduid" : "4718829", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Clermont", "csduid" : "2415035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Clermont", "csduid" : "2487110", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Clerval", "csduid" : "2487075", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Cleveland", "csduid" : "2442110", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Climax", "csduid" : "4704009", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Clinton", "csduid" : "5933028", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Clinworth No. 230", "csduid" : "4708053", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Clive", "csduid" : "4808032", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Cloridorme", "csduid" : "2403010", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Clyde", "csduid" : "4813032", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Clyde River", "csduid" : "1102037", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Clyde River", "csduid" : "6204015", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Coachman's Cove", "csduid" : "1008092", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Coaldale", "csduid" : "4802019", "cmaname" : "", "cmauid" : "810", "prname" : "Alberta"},
    {"csdname" : "Coalfields No. 4", "csduid" : "4701016", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Coalhurst", "csduid" : "4802013", "cmaname" : "", "cmauid" : "810", "prname" : "Alberta"},
    {"csdname" : "Coaticook", "csduid" : "2444037", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Cobalt", "csduid" : "3554008", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Cobourg", "csduid" : "3514021", "cmaname" : "", "cmauid" : "527", "prname" : "Ontario"},
    {"csdname" : "Cocagne", "csduid" : "1308002", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Cochin", "csduid" : "4717002", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Cochrane", "csduid" : "3556042", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Cochrane", "csduid" : "4806019", "cmaname" : "", "cmauid" : "825", "prname" : "Alberta"},
    {"csdname" : "Cochrane, Unorganized, North Part", "csduid" : "3556092", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Cochrane, Unorganized, South East Part", "csduid" : "3556098", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Cochrane, Unorganized, South West Part", "csduid" : "3556091", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Cockburn Island", "csduid" : "3551034", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Coderre", "csduid" : "4707008", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Codette", "csduid" : "4714074", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Coglistiko River 29", "csduid" : "5941835", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Colborne", "csduid" : "1314003", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Colchester, Subd. A", "csduid" : "1210012", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Colchester, Subd. B", "csduid" : "1210008", "cmaname" : "", "cmauid" : "215", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Colchester, Subd. C", "csduid" : "1210001", "cmaname" : "", "cmauid" : "215", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Cold Lake", "csduid" : "4812002", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Cold Lake 149", "csduid" : "4812810", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Cold Lake 149A", "csduid" : "4812813", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Cold Lake 149B", "csduid" : "4812815", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Coldstream", "csduid" : "5937010", "cmaname" : "", "cmauid" : "918", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Coldwater 1", "csduid" : "5933801", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Coldwell", "csduid" : "4618044", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Cole Bay", "csduid" : "4718028", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Cole Bay 3", "csduid" : "5917801", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cole Harbour 30", "csduid" : "1209019", "cmaname" : "", "cmauid" : "205", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Coleman", "csduid" : "3554001", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Coleville", "csduid" : "4713026", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Colinet", "csduid" : "1001207", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Colliers", "csduid" : "1001456", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Collines-du-Basque", "csduid" : "2403904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Collingwood", "csduid" : "3543031", "cmaname" : "", "cmauid" : "567", "prname" : "Ontario"},
    {"csdname" : "Colombier", "csduid" : "2495050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Colonsay", "csduid" : "4711079", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Colonsay No. 342", "csduid" : "4711076", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Columbia Lake 3", "csduid" : "5901804", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Columbia-Shuswap A", "csduid" : "5939011", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Columbia-Shuswap B", "csduid" : "5939023", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Columbia-Shuswap C", "csduid" : "5939037", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Columbia-Shuswap D", "csduid" : "5939039", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Columbia-Shuswap E", "csduid" : "5939043", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Columbia-Shuswap F", "csduid" : "5939044", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Colville Lake", "csduid" : "6102012", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Colwood", "csduid" : "5917041", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Come By Chance", "csduid" : "1001274", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Comfort Cove-Newstead", "csduid" : "1008039", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Communauté Atikamekw de Manawan", "csduid" : "2462802", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Communauté de Wemotaci", "csduid" : "2490802", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Comox", "csduid" : "5926005", "cmaname" : "", "cmauid" : "943", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Comox 1", "csduid" : "5926801", "cmaname" : "", "cmauid" : "943", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Comox Valley A", "csduid" : "5926021", "cmaname" : "", "cmauid" : "943", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Comox Valley B (Lazo North)", "csduid" : "5926022", "cmaname" : "", "cmauid" : "943", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Comox Valley C (Puntledge - Black Creek)", "csduid" : "5926024", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Compton", "csduid" : "2444071", "cmaname" : "", "cmauid" : "433", "prname" : "Quebec / Québec"},
    {"csdname" : "Conception Bay South", "csduid" : "1001485", "cmaname" : "", "cmauid" : "001", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Conception Harbour", "csduid" : "1001461", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Conche", "csduid" : "1009008", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Conmee", "csduid" : "3558019", "cmaname" : "", "cmauid" : "595", "prname" : "Ontario"},
    {"csdname" : "Connaught No. 457", "csduid" : "4714056", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Conquest", "csduid" : "4712022", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Consort", "csduid" : "4804022", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Constance Lake 92", "csduid" : "3556095", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Consul", "csduid" : "4704021", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Contrecoeur", "csduid" : "2459035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Cook's Harbour", "csduid" : "1009034", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Cookshire-Eaton", "csduid" : "2441038", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Coquitlam", "csduid" : "5915034", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Coquitlam 1", "csduid" : "5915805", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Coquitlam 2", "csduid" : "5915804", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Coral Harbour", "csduid" : "6205014", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Cormack", "csduid" : "1005014", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Corman Park No. 344", "csduid" : "4711065", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Corner Brook", "csduid" : "1005018", "cmaname" : "", "cmauid" : "015", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Cornwall", "csduid" : "1102085", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Cornwall", "csduid" : "3501012", "cmaname" : "", "cmauid" : "501", "prname" : "Ontario"},
    {"csdname" : "Cornwallis", "csduid" : "4607060", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Coronach", "csduid" : "4703004", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Coronation", "csduid" : "4807012", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Coryatsaqua (Moricetown) 2", "csduid" : "5949810", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cote 64", "csduid" : "4709819", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Coteau Beach", "csduid" : "4707068", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Coteau-du-Lac", "csduid" : "2471040", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Coteau No. 255", "csduid" : "4707067", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Cote No. 271", "csduid" : "4709037", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Côte-Nord-du-Golfe-du-Saint-Laurent", "csduid" : "2498015", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Côte-Saint-Luc", "csduid" : "2466058", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Cottlesville", "csduid" : "1008032", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Couchiching 16A", "csduid" : "3559063", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Coulée-des-Adolphe", "csduid" : "2404904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Coulee No. 136", "csduid" : "4707018", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Courcelles", "csduid" : "2430090", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Courtenay", "csduid" : "5926010", "cmaname" : "", "cmauid" : "943", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Coutts", "csduid" : "4802002", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Coverdale", "csduid" : "1306014", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Cowansville", "csduid" : "2446080", "cmaname" : "", "cmauid" : "437", "prname" : "Quebec / Québec"},
    {"csdname" : "Cowessess 73", "csduid" : "4705803", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Cow Head", "csduid" : "1009016", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Cowichan", "csduid" : "5919822", "cmaname" : "", "cmauid" : "937", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cowichan Lake", "csduid" : "5919812", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cowichan Valley A", "csduid" : "5919043", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cowichan Valley B", "csduid" : "5919046", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cowichan Valley C", "csduid" : "5919049", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cowichan Valley D", "csduid" : "5919013", "cmaname" : "", "cmauid" : "937", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cowichan Valley E", "csduid" : "5919051", "cmaname" : "", "cmauid" : "937", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cowichan Valley F", "csduid" : "5919033", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cowichan Valley G", "csduid" : "5919015", "cmaname" : "", "cmauid" : "936", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cowichan Valley H", "csduid" : "5919017", "cmaname" : "", "cmauid" : "936", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cowichan Valley I", "csduid" : "5919035", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cowley", "csduid" : "4803016", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Cox's Cove", "csduid" : "1005023", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Crabtree", "csduid" : "2461013", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Craik", "csduid" : "4707092", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Craik No. 222", "csduid" : "4707091", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Cramahe", "csduid" : "3514014", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Cranbrook", "csduid" : "5901022", "cmaname" : "", "cmauid" : "905", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Crane River 51", "csduid" : "4619068", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Crapaud", "csduid" : "1102034", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Crapaud, Part 1", "csduid" : "1102018", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Crapaud, Part 2", "csduid" : "1103004", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Craven", "csduid" : "4706078", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Creelman", "csduid" : "4702079", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Creighton", "csduid" : "4718051", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Cremona", "csduid" : "4806031", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Creston", "csduid" : "5903004", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Creston 1", "csduid" : "5903807", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Crossfield", "csduid" : "4806026", "cmaname" : "", "cmauid" : "825", "prname" : "Alberta"},
    {"csdname" : "Cross Lake 19", "csduid" : "4622051", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Cross Lake 19A", "csduid" : "4622052", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Cross Lake 19E", "csduid" : "4622067", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Crossroads", "csduid" : "1102010", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Crow Head", "csduid" : "1008029", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Crowsnest Pass", "csduid" : "4815007", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Crystal Springs", "csduid" : "4811006", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Cudworth", "csduid" : "4715041", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Cumberland", "csduid" : "5926014", "cmaname" : "", "cmauid" : "943", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Cumberland 100A", "csduid" : "4715850", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Cumberland House", "csduid" : "4718005", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Cumberland House Cree Nation 20", "csduid" : "4718801", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Cumberland, Subd. A", "csduid" : "1211001", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Cumberland, Subd. B", "csduid" : "1211006", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Cumberland, Subd. C", "csduid" : "1211009", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Cumberland, Subd. D", "csduid" : "1211014", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Cupar", "csduid" : "4706094", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Cupar No. 218", "csduid" : "4706091", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Cupids", "csduid" : "1001437", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Curve Lake First Nation 35", "csduid" : "3515019", "cmaname" : "", "cmauid" : "529", "prname" : "Ontario"},
    {"csdname" : "Cut Knife", "csduid" : "4713098", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Cut Knife No. 439", "csduid" : "4713096", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Cymri No. 36", "csduid" : "4702037", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Cypress County", "csduid" : "4801003", "cmaname" : "", "cmauid" : "805", "prname" : "Alberta"},
    {"csdname" : "Czar", "csduid" : "4807004", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Dakota Plains 6A", "csduid" : "4609026", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Dakota Tipi 1", "csduid" : "4609027", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Dalhousie", "csduid" : "1314008", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Dalhousie", "csduid" : "1314017", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Dalmeny", "csduid" : "4711073", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Daniel's Harbour", "csduid" : "1009015", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Danville", "csduid" : "2440047", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Darlington", "csduid" : "1102035", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Dauphin", "csduid" : "4617048", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Dauphin", "csduid" : "4617050", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Dauphin River 48A", "csduid" : "4619062", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Daveluyville", "csduid" : "2439152", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Davidson", "csduid" : "4711014", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Dawn-Euphemia", "csduid" : "3538007", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Dawson", "csduid" : "3559040", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Dawson", "csduid" : "6001029", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Dawson Creek", "csduid" : "5955014", "cmaname" : "", "cmauid" : "975", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Daysland", "csduid" : "4807039", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Day Star 87", "csduid" : "4710824", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Dead Point 5", "csduid" : "5943807", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Dease Lake 9", "csduid" : "5949845", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Dease River 1", "csduid" : "5957820", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Debden", "csduid" : "4716054", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Deep Creek 2", "csduid" : "5941805", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Deep River", "csduid" : "3547096", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Deer Forks No. 232", "csduid" : "4708061", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Deer Lake", "csduid" : "1005004", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Deer Lake", "csduid" : "3560070", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Dégelis", "csduid" : "2413005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Delburne", "csduid" : "4808004", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Déléage", "csduid" : "2483070", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Delia", "csduid" : "4805034", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Déline", "csduid" : "6102003", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Delisle", "csduid" : "4712056", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Deloraine-Winchester", "csduid" : "4605038", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Delson", "csduid" : "2467025", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Delta", "csduid" : "5915011", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Denare Beach", "csduid" : "4718049", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Denholm", "csduid" : "2483005", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Denholm", "csduid" : "4716002", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Denmark", "csduid" : "1312014", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Denzil", "csduid" : "4713054", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Dépôt-Échouani", "csduid" : "2483912", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Derby", "csduid" : "1309011", "cmaname" : "", "cmauid" : "329", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "De Salaberry", "csduid" : "4602032", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Desbiens", "csduid" : "2493005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Deschaillons-sur-Saint-Laurent", "csduid" : "2438070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Deschambault-Grondines", "csduid" : "2434058", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Deseronto", "csduid" : "3512002", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Desmarais", "csduid" : "4817855", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Destruction Bay", "csduid" : "6001049", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Dettah", "csduid" : "6106021", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Deux-Montagnes", "csduid" : "2472010", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Devon", "csduid" : "4811018", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Devon 30", "csduid" : "1310034", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Didsbury", "csduid" : "4806032", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Dieppe", "csduid" : "1307045", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Digby", "csduid" : "1203004", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Digby", "csduid" : "1203006", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Dilke", "csduid" : "4706068", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Dinsmore", "csduid" : "4712016", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Disley", "csduid" : "4706054", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Disraeli", "csduid" : "2431015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Disraeli", "csduid" : "2431020", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Division No. 10, Subd. A", "csduid" : "1010001", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No. 10, Subd. B", "csduid" : "1010008", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No. 10, Subd. C", "csduid" : "1010020", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No. 10, Subd. D", "csduid" : "1010029", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No. 10, Subd. E", "csduid" : "1010042", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No. 11, Subd. C", "csduid" : "1011005", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No. 11, Subd. E", "csduid" : "1011040", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No. 17, Unorganized", "csduid" : "4617092", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Division No. 18, Unorganized", "csduid" : "4718090", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Division No. 18, Unorganized, East Part", "csduid" : "4618091", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Division No. 18, Unorganized, West Part", "csduid" : "4618093", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Division No. 19, Unorganized", "csduid" : "4619045", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Division No.  1, Subd. A", "csduid" : "1001259", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. B", "csduid" : "1001234", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. C", "csduid" : "1001214", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. D", "csduid" : "1001565", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. E", "csduid" : "1001304", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. F", "csduid" : "1001321", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. G", "csduid" : "1001339", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. H", "csduid" : "1001357", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. I", "csduid" : "1001374", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. J", "csduid" : "1001391", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. K", "csduid" : "1001405", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. L", "csduid" : "1001415", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. M", "csduid" : "1001421", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. N", "csduid" : "1001441", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. O", "csduid" : "1001452", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. R", "csduid" : "1001490", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. U", "csduid" : "1001124", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. V", "csduid" : "1001101", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. W", "csduid" : "1001155", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. X", "csduid" : "1001203", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Subd. Y", "csduid" : "1001293", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  1, Unorganized", "csduid" : "4601094", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Division No. 20, Unorganized, North Part", "csduid" : "4620069", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Division No. 20, Unorganized, South Part", "csduid" : "4620066", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Division No. 21, Unorganized", "csduid" : "4621078", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Division No. 22, Unorganized", "csduid" : "4622046", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Division No. 23, Unorganized", "csduid" : "4623062", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Division No.  2, Subd. C", "csduid" : "1002031", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  2, Subd. D", "csduid" : "1002022", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  2, Subd. E", "csduid" : "1002001", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  2, Subd. F", "csduid" : "1002006", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  2, Subd. G", "csduid" : "1002009", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  2, Subd. H", "csduid" : "1002016", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  2, Subd. I", "csduid" : "1002026", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  2, Subd. J", "csduid" : "1002029", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  2, Subd. K", "csduid" : "1002048", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  2, Subd. L", "csduid" : "1002044", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  3, Subd. A", "csduid" : "1003001", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  3, Subd. B", "csduid" : "1003006", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  3, Subd. C", "csduid" : "1003009", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  3, Subd. D", "csduid" : "1003014", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  3, Subd. E", "csduid" : "1003021", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  3, Subd. F", "csduid" : "1003024", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  3, Subd. H", "csduid" : "1003031", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  3, Subd. I", "csduid" : "1003038", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  3, Subd. J", "csduid" : "1003042", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  4, Subd. A", "csduid" : "1004001", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  4, Subd. B", "csduid" : "1004006", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  4, Subd. C", "csduid" : "1004011", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  4, Subd. D", "csduid" : "1004016", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  4, Subd. E", "csduid" : "1004022", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  5, Subd. A", "csduid" : "1005001", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  5, Subd. C", "csduid" : "1005016", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  5, Subd. D", "csduid" : "1005019", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  5, Subd. E", "csduid" : "1005007", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  5, Subd. F", "csduid" : "1005010", "cmaname" : "", "cmauid" : "015", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  5, Subd. G", "csduid" : "1005003", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  6, Subd. A", "csduid" : "1006029", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  6, Subd. C", "csduid" : "1006014", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  6, Subd. D", "csduid" : "1006001", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  6, Subd. E", "csduid" : "1006008", "cmaname" : "", "cmauid" : "011", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  7, Subd. A", "csduid" : "1007056", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  7, Subd. B", "csduid" : "1007051", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  7, Subd. D", "csduid" : "1007038", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  7, Subd. E", "csduid" : "1007031", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  7, Subd. F", "csduid" : "1007028", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  7, Subd. G", "csduid" : "1007024", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  7, Subd. I", "csduid" : "1007018", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  7, Subd. J", "csduid" : "1007014", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  7, Subd. K", "csduid" : "1007009", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  7, Subd. L", "csduid" : "1007006", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  7, Subd. M", "csduid" : "1007001", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  7, Subd. N", "csduid" : "1007045", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  8, Subd. A", "csduid" : "1008074", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  8, Subd. C", "csduid" : "1008058", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  8, Subd. D", "csduid" : "1008052", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  8, Subd. E", "csduid" : "1008046", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  8, Subd. F", "csduid" : "1008042", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  8, Subd. G", "csduid" : "1008036", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  8, Subd. H", "csduid" : "1008031", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  8, Subd. I", "csduid" : "1008026", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  8, Subd. L", "csduid" : "1008008", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  8, Subd. M", "csduid" : "1008001", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  8, Subd. O", "csduid" : "1008067", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  8, Subd. P", "csduid" : "1008065", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  9, Subd. A", "csduid" : "1009009", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  9, Subd. C", "csduid" : "1009021", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  9, Subd. D", "csduid" : "1009031", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  9, Subd. F", "csduid" : "1009001", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  9, Subd. G", "csduid" : "1009047", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Division No.  9, Subd. H", "csduid" : "1009041", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Dixville", "csduid" : "2444023", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Doaktown", "csduid" : "1309022", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Dodsland", "csduid" : "4713029", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Dog Creek 1", "csduid" : "5941806", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Dog Creek 2", "csduid" : "5941807", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Dog Creek 46", "csduid" : "4618055", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Dog Head 218", "csduid" : "4816852", "cmaname" : "", "cmauid" : "860", "prname" : "Alberta"},
    {"csdname" : "Doig River 206", "csduid" : "5955804", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Dokis 9", "csduid" : "3549077", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Dolbeau-Mistassini", "csduid" : "2492022", "cmaname" : "", "cmauid" : "411", "prname" : "Quebec / Québec"},
    {"csdname" : "Dollard-Des Ormeaux", "csduid" : "2466142", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Dolphin Island 1", "csduid" : "5947807", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Donalda", "csduid" : "4807029", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Doncaster", "csduid" : "2478802", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Donnacona", "csduid" : "2434025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Donnelly", "csduid" : "4819044", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Dorchester", "csduid" : "1307011", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Dorchester", "csduid" : "1307012", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Dore Lake", "csduid" : "4718023", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Dorintosh", "csduid" : "4717055", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Dorion", "csduid" : "3558034", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Dorval", "csduid" : "2466087", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Dosquet", "csduid" : "2433040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Douglas", "csduid" : "1310028", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Douglas 8", "csduid" : "5909842", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Douglas Lake 3", "csduid" : "5933802", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Douglas No. 436", "csduid" : "4716023", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Douro-Dummer", "csduid" : "3515023", "cmaname" : "", "cmauid" : "529", "prname" : "Ontario"},
    {"csdname" : "Dover", "csduid" : "1007057", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Dragon Lake 3", "csduid" : "5941886", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Drake", "csduid" : "4711048", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Drayton Valley", "csduid" : "4811031", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Drift Pile River 150", "csduid" : "4817828", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Drinkwater", "csduid" : "4706019", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Drumheller", "csduid" : "4805026", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Drummond", "csduid" : "1312021", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Drummond", "csduid" : "1312023", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Drummond/North Elmsley", "csduid" : "3509010", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Drummondville", "csduid" : "2449058", "cmaname" : "", "cmauid" : "447", "prname" : "Quebec / Québec"},
    {"csdname" : "Dryden", "csduid" : "3560027", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Dubreuilville", "csduid" : "3557079", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Dubuc", "csduid" : "4705058", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Duchess", "csduid" : "4802036", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Duck Lake", "csduid" : "4715062", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Duck Lake 7", "csduid" : "5935801", "cmaname" : "", "cmauid" : "915", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Duck Lake 76B", "csduid" : "3552054", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Duck Lake No. 463", "csduid" : "4715061", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Dudswell", "csduid" : "2441117", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Duff", "csduid" : "4705068", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Dufferin", "csduid" : "1302031", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Dufferin", "csduid" : "4603072", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Dufferin No. 190", "csduid" : "4706059", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Duhamel", "csduid" : "2480135", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Duhamel-Ouest", "csduid" : "2485030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Dumbarton", "csduid" : "1302044", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Dumfries", "csduid" : "1310014", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Duncan", "csduid" : "5919012", "cmaname" : "", "cmauid" : "937", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Duncan Lake 2", "csduid" : "5951821", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Duncan's 151A", "csduid" : "4819826", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Dundas", "csduid" : "1308001", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Dundee", "csduid" : "2469075", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Dundurn", "csduid" : "4711063", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Dundurn No. 314", "csduid" : "4711061", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Dunham", "csduid" : "2446050", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Dunnottar", "csduid" : "4613049", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Duntara", "csduid" : "1007026", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Duparquet", "csduid" : "2487005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Dupuy", "csduid" : "2487085", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Durham", "csduid" : "1314001", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Durham-Sud", "csduid" : "2449015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Dutton/Dunwich", "csduid" : "3534030", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Duval", "csduid" : "4711002", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Dysart", "csduid" : "4706097", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Dysart et al", "csduid" : "3546024", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Dzitline Lee 9", "csduid" : "5951814", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Eagle Creek No. 376", "csduid" : "4712064", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Eagle Lake 27", "csduid" : "3560024", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Eagles Lake 165C", "csduid" : "4717816", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ear Falls", "csduid" : "3560032", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Earl Grey", "csduid" : "4706079", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "East Angus", "csduid" : "2441060", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "East Broughton", "csduid" : "2431122", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Eastend", "csduid" : "4704026", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Eastern Kings", "csduid" : "1101040", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "East Farnham", "csduid" : "2446085", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "East Ferris", "csduid" : "3548034", "cmaname" : "", "cmauid" : "575", "prname" : "Ontario"},
    {"csdname" : "East Garafraxa", "csduid" : "3522001", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "East Gwillimbury", "csduid" : "3519054", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "East Hants", "csduid" : "1208008", "cmaname" : "", "cmauid" : "205", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "East Hawkesbury", "csduid" : "3502001", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "East Hereford", "csduid" : "2444010", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "East Kootenay A", "csduid" : "5901017", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "East Kootenay B", "csduid" : "5901019", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "East Kootenay C", "csduid" : "5901035", "cmaname" : "", "cmauid" : "905", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "East Kootenay E", "csduid" : "5901037", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "East Kootenay F", "csduid" : "5901046", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "East Kootenay G", "csduid" : "5901048", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Eastmain", "csduid" : "2499045", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Eastmain", "csduid" : "2499810", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Eastman", "csduid" : "2445093", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "East Moberly Lake 169", "csduid" : "5955801", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Eastport", "csduid" : "1007042", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "East River, Part 1", "csduid" : "1102064", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "East River, Part 2", "csduid" : "1101052", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "East Saanich 2", "csduid" : "5917803", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "East St. Paul", "csduid" : "4613032", "cmaname" : "", "cmauid" : "602", "prname" : "Manitoba"},
    {"csdname" : "East Zorra-Tavistock", "csduid" : "3532038", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Eatonia", "csduid" : "4708068", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ebb and Flow 52", "csduid" : "4617029", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Ebenezer", "csduid" : "4709014", "cmaname" : "", "cmauid" : "710", "prname" : "Saskatchewan"},
    {"csdname" : "Echo Bay", "csduid" : "4716060", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Eckville", "csduid" : "4808024", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Edam", "csduid" : "4717011", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Edberg", "csduid" : "4810006", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Eden Valley 216", "csduid" : "4806803", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Edenwold", "csduid" : "4706033", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Edenwold No. 158", "csduid" : "4706029", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Edgerton", "csduid" : "4807052", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Edmonton", "csduid" : "4811061", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Edmundston", "csduid" : "1313027", "cmaname" : "", "cmauid" : "335", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Edson", "csduid" : "4814024", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Edwardsburgh/Cardinal", "csduid" : "3507004", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Eel Ground 2", "csduid" : "1309035", "cmaname" : "", "cmauid" : "329", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Eel River 3", "csduid" : "1314010", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Eel River Crossing", "csduid" : "1314011", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Eeyou Istchee Baie-James", "csduid" : "2499060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Egan-Sud", "csduid" : "2483075", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Ehatis 11", "csduid" : "5924836", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Elbow", "csduid" : "4711019", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Elcapo No. 154", "csduid" : "4705028", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Eldon", "csduid" : "1314016", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Eldon No. 471", "csduid" : "4717017", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Elfros", "csduid" : "4710043", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Elfros No. 307", "csduid" : "4710041", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Elgin", "csduid" : "1306008", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Elgin", "csduid" : "2469050", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Elhlateese 2", "csduid" : "5923805", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Elizabethtown-Kitley", "csduid" : "3507014", "cmaname" : "", "cmauid" : "512", "prname" : "Ontario"},
    {"csdname" : "Elkford", "csduid" : "5901003", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Elk Point", "csduid" : "4812016", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Ellice-Archie", "csduid" : "4615047", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Elliot Lake", "csduid" : "3557041", "cmaname" : "", "cmauid" : "582", "prname" : "Ontario"},
    {"csdname" : "Elliston", "csduid" : "1007020", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Elmsthorpe No. 100", "csduid" : "4702061", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Elnora", "csduid" : "4808002", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Elrose", "csduid" : "4708094", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Elton", "csduid" : "4607071", "cmaname" : "", "cmauid" : "610", "prname" : "Manitoba"},
    {"csdname" : "Embree", "csduid" : "1008043", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Emerald No. 277", "csduid" : "4710031", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Emerson-Franklin", "csduid" : "4602024", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Emo", "csduid" : "3559019", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Empress", "csduid" : "4804006", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Endeavour", "csduid" : "4709065", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Enderby", "csduid" : "5937033", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Enderby 2", "csduid" : "5937802", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Enfield No. 194", "csduid" : "4707053", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Englee", "csduid" : "1009004", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Englefeld", "csduid" : "4715002", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Englehart", "csduid" : "3554052", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "English Harbour East", "csduid" : "1002039", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "English River 21", "csduid" : "3560058", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Enniskillen", "csduid" : "3538016", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Enniskillen No. 3", "csduid" : "4701011", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Enoch Cree Nation", "csduid" : "4811804", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Enterprise", "csduid" : "6105003", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Enterprise No. 142", "csduid" : "4708021", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Entlqwekkinh 19", "csduid" : "5933899", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Entrelacs", "csduid" : "2462053", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Erin", "csduid" : "3523017", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Ermineskin 138", "csduid" : "4811801", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Ernfold", "csduid" : "4707028", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Escuminac", "csduid" : "2406025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Esgenoôpetitj 14", "csduid" : "1309044", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Eskasoni 3", "csduid" : "1217020", "cmaname" : "", "cmauid" : "225", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Esowista 3", "csduid" : "5923823", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Espanola", "csduid" : "3552026", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Esprit-Saint", "csduid" : "2410005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Esquimalt", "csduid" : "5917040", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Esquimalt", "csduid" : "5917811", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Essa", "csduid" : "3543021", "cmaname" : "", "cmauid" : "563", "prname" : "Ontario"},
    {"csdname" : "Essex", "csduid" : "3537016", "cmaname" : "", "cmauid" : "559", "prname" : "Ontario"},
    {"csdname" : "Essipit", "csduid" : "2495802", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Estérel", "csduid" : "2477011", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Esterhazy", "csduid" : "4705052", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Estevan", "csduid" : "4701024", "cmaname" : "", "cmauid" : "750", "prname" : "Saskatchewan"},
    {"csdname" : "Estevan No. 5", "csduid" : "4701022", "cmaname" : "", "cmauid" : "750", "prname" : "Saskatchewan"},
    {"csdname" : "Eston", "csduid" : "4708076", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Est-Patrolas 4", "csduid" : "5919820", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ethelbert", "csduid" : "4617064", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Etters Beach", "csduid" : "4711007", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Euchinico Creek 17", "csduid" : "5941866", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Evanturel", "csduid" : "3554049", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Excel No. 71", "csduid" : "4703029", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Excelsior No. 166", "csduid" : "4707021", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Eyebrow", "csduid" : "4707049", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Eyebrow No. 193", "csduid" : "4707047", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Eye Hill No. 382", "csduid" : "4713049", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Factory Island 1", "csduid" : "3556094", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Fairford 50", "csduid" : "4618067", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Fairlight", "csduid" : "4701098", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Fairview", "csduid" : "4819068", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Fairview No. 136", "csduid" : "4819066", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Falher", "csduid" : "4819046", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Faraday", "csduid" : "3512058", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Farnham", "csduid" : "2446112", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Faro", "csduid" : "6001004", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Fassett", "csduid" : "2480005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Fauquier-Strickland", "csduid" : "3556052", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Fenwood", "csduid" : "4705071", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ferland-et-Boilleau", "csduid" : "2494220", "cmaname" : "", "cmauid" : "408", "prname" : "Quebec / Québec"},
    {"csdname" : "Ferme-Neuve", "csduid" : "2479097", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Fermeuse", "csduid" : "1001136", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Fermont", "csduid" : "2497035", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Fernie", "csduid" : "5901012", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ferryland", "csduid" : "1001149", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Fertile Belt No. 183", "csduid" : "4705051", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Fertile Valley No. 285", "csduid" : "4712020", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Fillmore", "csduid" : "4702078", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Fillmore No. 96", "csduid" : "4702076", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Findlater", "csduid" : "4706062", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Finlay River 6", "csduid" : "5955840", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fish Creek No. 402", "csduid" : "4715036", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Fisher", "csduid" : "4618068", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Fisher River 44", "csduid" : "4619056", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Fisher River 44A", "csduid" : "4619058", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Fisher's Grant 24", "csduid" : "1212019", "cmaname" : "", "cmauid" : "220", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Fishing Lake 89", "csduid" : "4710826", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Fishtrap 19", "csduid" : "5941871", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Five Mile Point 3", "csduid" : "5957803", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Flagstaff County", "csduid" : "4807031", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Flatrock", "csduid" : "1001507", "cmaname" : "", "cmauid" : "001", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Flaxcombe", "csduid" : "4713009", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Fleming", "csduid" : "4705002", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Flett's Springs No. 429", "csduid" : "4715051", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Fleur de Lys", "csduid" : "1008075", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Flin Flon (Part)", "csduid" : "4621064", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Flin Flon (Part)", "csduid" : "4718052", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Florenceville-Bristol", "csduid" : "1311027", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Flower's Cove", "csduid" : "1009024", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Flying Dust First Nation 105", "csduid" : "4717805", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Flying Dust First Nation 105D", "csduid" : "4717830", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Flying Dust First Nation 105E", "csduid" : "4717831", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Flying Dust First Nation 105F", "csduid" : "4717832", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Flying Post 73", "csduid" : "3556100", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Foam Lake", "csduid" : "4710038", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Foam Lake No. 276", "csduid" : "4710036", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Fogo Island", "csduid" : "1008099", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Fond du Lac 227", "csduid" : "4718824", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Fontas 1", "csduid" : "5959805", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Foothills County", "csduid" : "4806001", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Foremost", "csduid" : "4801009", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Forestburg", "csduid" : "4807036", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Forestville", "csduid" : "2495045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Forget", "csduid" : "4701064", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Fort Albany (Part) 67", "csduid" : "3556093", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Fort Albany (Part) 67", "csduid" : "3560050", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Fort Alexander 3", "csduid" : "4619050", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Fort-Coulonge", "csduid" : "2484060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Forteau", "csduid" : "1010005", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Fort Erie", "csduid" : "3526003", "cmaname" : "", "cmauid" : "539", "prname" : "Ontario"},
    {"csdname" : "Fort Folly 1", "csduid" : "1307014", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Fort Frances", "csduid" : "3559012", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Fort George 2", "csduid" : "5953801", "cmaname" : "", "cmauid" : "970", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fort Good Hope", "csduid" : "6102009", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Fort Hope 64", "csduid" : "3560053", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Fortierville", "csduid" : "2438047", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Fort Liard", "csduid" : "6104009", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Fort Macleod", "csduid" : "4803019", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Fort Mckay 174", "csduid" : "4816810", "cmaname" : "", "cmauid" : "860", "prname" : "Alberta"},
    {"csdname" : "Fort McPherson", "csduid" : "6101015", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Fort Nelson 2", "csduid" : "5959806", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fort Providence", "csduid" : "6104014", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Fort Qu'Appelle", "csduid" : "4706051", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Fort Resolution", "csduid" : "6105018", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Fort Rupert 1", "csduid" : "5943804", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fort San", "csduid" : "4706045", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Fort Saskatchewan", "csduid" : "4811056", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Fort Severn 89", "csduid" : "3560078", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Fort Simpson", "csduid" : "6104038", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Fort Smith", "csduid" : "6105001", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Fort St. James", "csduid" : "5951013", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fort St. John", "csduid" : "5955034", "cmaname" : "", "cmauid" : "977", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fortune", "csduid" : "1002018", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Fort Vermilion 173B", "csduid" : "4817859", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Fort Ware 1", "csduid" : "5955807", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fort William 52", "csduid" : "3558003", "cmaname" : "", "cmauid" : "595", "prname" : "Ontario"},
    {"csdname" : "Forty Mile County No. 8", "csduid" : "4801008", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Fossambault-sur-le-Lac", "csduid" : "2422010", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Fosston", "csduid" : "4714025", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Fountain 1", "csduid" : "5931816", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fountain 10", "csduid" : "5931818", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fountain 11", "csduid" : "5931819", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fountain 12", "csduid" : "5931820", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fountain 1B", "csduid" : "5931842", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fountain 1D", "csduid" : "5931825", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fountain 3", "csduid" : "5931817", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fountain 3A", "csduid" : "5931834", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fountain Creek 8", "csduid" : "5931822", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Four Portages 157C", "csduid" : "4718813", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Fox Cove-Mortier", "csduid" : "1002025", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Fox Creek", "csduid" : "4818002", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Fox Harbour", "csduid" : "1001254", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Fox Lake 162", "csduid" : "4817835", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Fox Lake 2", "csduid" : "4623027", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Fox Valley", "csduid" : "4708026", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Fox Valley No. 171", "csduid" : "4708024", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Frampton", "csduid" : "2426005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Francis", "csduid" : "4706009", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Francis No. 127", "csduid" : "4706004", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Francois Lake 7", "csduid" : "5951822", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Franklin", "csduid" : "2469010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Franquelin", "csduid" : "2496015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Fraser-Fort George A", "csduid" : "5953038", "cmaname" : "", "cmauid" : "970", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser-Fort George C", "csduid" : "5953042", "cmaname" : "", "cmauid" : "970", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser-Fort George D", "csduid" : "5953044", "cmaname" : "", "cmauid" : "970", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser-Fort George E", "csduid" : "5953046", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser-Fort George F", "csduid" : "5953048", "cmaname" : "", "cmauid" : "970", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser-Fort George G", "csduid" : "5953050", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser-Fort George H", "csduid" : "5953019", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser Lake", "csduid" : "5951009", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser Valley A", "csduid" : "5909014", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser Valley B", "csduid" : "5909016", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser Valley C", "csduid" : "5909048", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser Valley D", "csduid" : "5909034", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser Valley E", "csduid" : "5909036", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser Valley F", "csduid" : "5909060", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser Valley G", "csduid" : "5909062", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fraser Valley H", "csduid" : "5909035", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fredericton", "csduid" : "1310032", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Fredericton Junction", "csduid" : "1303006", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Frelighsburg", "csduid" : "2446010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Frenchman Butte No. 501", "csduid" : "4717032", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Frenchman's Cove", "csduid" : "1002017", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "French River 13", "csduid" : "3549076", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "French River / Rivière des Français", "csduid" : "3552001", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Frobisher", "csduid" : "4701017", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Frontenac", "csduid" : "2430025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Frontenac Islands", "csduid" : "3510005", "cmaname" : "", "cmauid" : "521", "prname" : "Ontario"},
    {"csdname" : "Frontier", "csduid" : "4704012", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Frontier No. 19", "csduid" : "4704011", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Front of Yonge", "csduid" : "3507017", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Fruitvale", "csduid" : "5905005", "cmaname" : "", "cmauid" : "910", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Fugèreville", "csduid" : "2485055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Gagetown", "csduid" : "1304004", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Gagetown", "csduid" : "1304005", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Gainsborough", "csduid" : "4701002", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Galiano Island 9", "csduid" : "5917805", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Gallants", "csduid" : "1004017", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Gallichan", "csduid" : "2487020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Gambler 63 (Part)", "csduid" : "4615049", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Gambler 63 (Part)", "csduid" : "4616025", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Gambo", "csduid" : "1007047", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Gamètì", "csduid" : "6103049", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Gananoque", "csduid" : "3507024", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Gander", "csduid" : "1006009", "cmaname" : "", "cmauid" : "011", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Garden", "csduid" : "5941884", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Garden Hill First Nation", "csduid" : "4622048", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Garden River 14", "csduid" : "3557074", "cmaname" : "", "cmauid" : "590", "prname" : "Ontario"},
    {"csdname" : "Garden River No. 490", "csduid" : "4715079", "cmaname" : "", "cmauid" : "745", "prname" : "Saskatchewan"},
    {"csdname" : "Garnish", "csduid" : "1002021", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Garry No. 245", "csduid" : "4709019", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Gaskiers-Point La Haye", "csduid" : "1001174", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Gaspé", "csduid" : "2403005", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Gatineau", "csduid" : "2481017", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Gaultois", "csduid" : "1003012", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Gauthier", "csduid" : "3554066", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Georgian Bay", "csduid" : "3544065", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Georgian Bluffs", "csduid" : "3542053", "cmaname" : "", "cmauid" : "566", "prname" : "Ontario"},
    {"csdname" : "Georgina", "csduid" : "3519070", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Gerald", "csduid" : "4705044", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Gesgapegiag", "csduid" : "2406802", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Ghost Lake", "csduid" : "4815027", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Gibbons", "csduid" : "4811064", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Gibsons", "csduid" : "5929005", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Gilbert Plains", "csduid" : "4617054", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Gillam", "csduid" : "4623022", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Gillams", "csduid" : "1005015", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Gillies", "csduid" : "3558012", "cmaname" : "", "cmauid" : "595", "prname" : "Ontario"},
    {"csdname" : "Gimli", "csduid" : "4618031", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Ginoogaming First Nation", "csduid" : "3558067", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Girardville", "csduid" : "2492055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Girouxville", "csduid" : "4819048", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Gitanmaax 1", "csduid" : "5949812", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Gitanyow 1", "csduid" : "5949815", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Gitsegukla 1", "csduid" : "5949814", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Gitwangak 1", "csduid" : "5949816", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Gjoa Haven", "csduid" : "6208081", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Gladstone", "csduid" : "1303004", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Gladue Lake 105B", "csduid" : "4717817", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Glaslyn", "csduid" : "4717048", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Glenavon", "csduid" : "4705021", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Glen Bain No. 105", "csduid" : "4703064", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Glenboro-South Cypress", "csduid" : "4607039", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Glenburnie-Birchy Head-Shoal Brook", "csduid" : "1009030", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Glendon", "csduid" : "4812012", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Glenelg", "csduid" : "1309004", "cmaname" : "", "cmauid" : "329", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Glenella-Lansdowne", "csduid" : "4608055", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Glen Ewen", "csduid" : "4701012", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Glen Harbour", "csduid" : "4706080", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Glen McPherson No. 46", "csduid" : "4703016", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Glenside", "csduid" : "4711029", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Glenside No. 377", "csduid" : "4712069", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Glenwood", "csduid" : "1006012", "cmaname" : "", "cmauid" : "011", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Glenwood", "csduid" : "4803006", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Glooscap 35", "csduid" : "1207027", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Glovertown", "csduid" : "1007044", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Godbout", "csduid" : "2496010", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Goderich", "csduid" : "3540028", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Godmanchester", "csduid" : "2469060", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "God's Lake 23", "csduid" : "4622049", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "God's River 86A", "csduid" : "4622055", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Golden", "csduid" : "5939007", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Golden Days", "csduid" : "4811023", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Golden Prairie", "csduid" : "4708018", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Golden West No. 95", "csduid" : "4701069", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Gold River", "csduid" : "5924025", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Gold River 21", "csduid" : "1206011", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Goodeve", "csduid" : "4705069", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Good Hope Lake", "csduid" : "5957804", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Good Lake No. 274", "csduid" : "4709029", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Goodsoil", "csduid" : "4717066", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Goodwater", "csduid" : "4702036", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Goose Cove East", "csduid" : "1009039", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Gordon", "csduid" : "1312008", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Gordon 86", "csduid" : "4710823", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Gordon/Barrie Island", "csduid" : "3551027", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Gordon River 2", "csduid" : "5917815", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Gore", "csduid" : "2476025", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Gore Bay", "csduid" : "3551026", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Goulais Bay 15A", "csduid" : "3557077", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Govan", "csduid" : "4711004", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Gracefield", "csduid" : "2483032", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Grahamdale", "csduid" : "4618060", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Granby", "csduid" : "2447017", "cmaname" : "", "cmauid" : "450", "prname" : "Quebec / Québec"},
    {"csdname" : "Grand Bank", "csduid" : "1002019", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Grand Bay-Westfield", "csduid" : "1305015", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Grand Coulee", "csduid" : "4706028", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Grande-Anse", "csduid" : "1315038", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Grande Prairie", "csduid" : "4819012", "cmaname" : "", "cmauid" : "850", "prname" : "Alberta"},
    {"csdname" : "Grande Prairie County No. 1", "csduid" : "4819006", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Grande-Rivière", "csduid" : "2402015", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Grandes-Piles", "csduid" : "2435040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Grande-Vallée", "csduid" : "2403020", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Grand Falls", "csduid" : "1312016", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Grand Falls / Grand-Sault", "csduid" : "1312019", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Grand Falls-Windsor", "csduid" : "1006017", "cmaname" : "", "cmauid" : "010", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Grand Forks", "csduid" : "5905032", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Grand Le Pierre", "csduid" : "1002030", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Grand Manan", "csduid" : "1302001", "cmaname" : "", "cmauid" : "999", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Grand Manan", "csduid" : "1302052", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Grand-Métis", "csduid" : "2409060", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Grandmother's Bay 219", "csduid" : "4718831", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Grand Rapids", "csduid" : "4621025", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Grand Rapids 33", "csduid" : "4621027", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Grand-Remous", "csduid" : "2483095", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Grand-Saint-Esprit", "csduid" : "2450065", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Grand Valley", "csduid" : "3522010", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Grandview", "csduid" : "4617058", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Grandview", "csduid" : "4811004", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Grandview Beach", "csduid" : "4706069", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Grandview No. 349", "csduid" : "4713032", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Granisle", "csduid" : "5951032", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Grant No. 372", "csduid" : "4715014", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Granville Lake", "csduid" : "4623039", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Grass Lake No. 381", "csduid" : "4713056", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Grassland", "csduid" : "4605062", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Grassy Creek No. 78", "csduid" : "4704034", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Gravelbourg", "csduid" : "4703071", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Gravelbourg No. 104", "csduid" : "4703068", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Gravenhurst", "csduid" : "3544002", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Grayson", "csduid" : "4705059", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Grayson No. 184", "csduid" : "4705057", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Great Bend No. 405", "csduid" : "4716008", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Greater Madawaska", "csduid" : "3547008", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Greater Napanee", "csduid" : "3511015", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Greater Sudbury / Grand Sudbury", "csduid" : "3553005", "cmaname" : "", "cmauid" : "580", "prname" : "Ontario"},
    {"csdname" : "Green Lake", "csduid" : "4718021", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Greenmount-Montrose", "csduid" : "1103055", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Greenspond", "csduid" : "1007054", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Greenstone", "csduid" : "3558075", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Greenview No. 16", "csduid" : "4818015", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Greenwich", "csduid" : "1305038", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Greenwood", "csduid" : "5905042", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Gregoire Lake 176", "csduid" : "4816821", "cmaname" : "", "cmauid" : "860", "prname" : "Alberta"},
    {"csdname" : "Gregoire Lake 176A", "csduid" : "4816822", "cmaname" : "", "cmauid" : "860", "prname" : "Alberta"},
    {"csdname" : "Greig Lake", "csduid" : "4717053", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Grenfell", "csduid" : "4705029", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Grenville", "csduid" : "2476055", "cmaname" : "", "cmauid" : "502", "prname" : "Quebec / Québec"},
    {"csdname" : "Grenville-sur-la-Rouge", "csduid" : "2476052", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Grey", "csduid" : "4609018", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Grey Highlands", "csduid" : "3542015", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Griffin No. 66", "csduid" : "4702042", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Grimsby", "csduid" : "3526065", "cmaname" : "", "cmauid" : "537", "prname" : "Ontario"},
    {"csdname" : "Grimshaw", "csduid" : "4819074", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Grise Fiord", "csduid" : "6204025", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Grizzly Bear's Head 110 and Lean Man 111", "csduid" : "4712832", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Gros Cap 49", "csduid" : "3557078", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Gros-Mécatina", "csduid" : "2498014", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Grosse-Île", "csduid" : "2401042", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Grosses-Roches", "csduid" : "2408015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Guelph", "csduid" : "3523008", "cmaname" : "", "cmauid" : "550", "prname" : "Ontario"},
    {"csdname" : "Guelph/Eramosa", "csduid" : "3523009", "cmaname" : "", "cmauid" : "550", "prname" : "Ontario"},
    {"csdname" : "Guérin", "csduid" : "2485095", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Guhthe Tah 12", "csduid" : "5949843", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Gull Lake", "csduid" : "4708011", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Gull Lake", "csduid" : "4808028", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Gull Lake No. 139", "csduid" : "4708009", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Gull River 55", "csduid" : "3558065", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Guysborough", "csduid" : "1213004", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Gwayasdums 1", "csduid" : "5943808", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Hafford", "csduid" : "4716022", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Hague", "csduid" : "4715034", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Hagwilget 1", "csduid" : "5949811", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Haines Junction", "csduid" : "6001018", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Halalt 2", "csduid" : "5919801", "cmaname" : "", "cmauid" : "937", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Halbrite", "csduid" : "4702038", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Haldimand County", "csduid" : "3528018", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Half Moon Bay", "csduid" : "4808025", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Halfway River 168", "csduid" : "5955808", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Halhalaeden", "csduid" : "5933813", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Halifax", "csduid" : "1209034", "cmaname" : "", "cmauid" : "205", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Halkirk", "csduid" : "4807016", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Hall Beach", "csduid" : "6204011", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Halton Hills", "csduid" : "3524015", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Hamilton", "csduid" : "3514019", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Hamilton", "csduid" : "3525005", "cmaname" : "", "cmauid" : "537", "prname" : "Ontario"},
    {"csdname" : "Hamilton Creek 2", "csduid" : "5933803", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Hamiota", "csduid" : "4615037", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Hammond", "csduid" : "1305001", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Ham-Nord", "csduid" : "2439010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Hampden", "csduid" : "1005008", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Hampden", "csduid" : "2441075", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Hampshire", "csduid" : "1102021", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Hampstead", "csduid" : "1304006", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Hampstead", "csduid" : "2466062", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Hampton", "csduid" : "1305006", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Hampton", "csduid" : "1305007", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Ham-Sud", "csduid" : "2440005", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Hanley", "csduid" : "4711032", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Hanna", "csduid" : "4804011", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Hanover", "csduid" : "3542029", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Hanover", "csduid" : "4602041", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Hant's Harbour", "csduid" : "1001335", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Hanwell", "csduid" : "1310017", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Happy Adventure", "csduid" : "1007041", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Happyland No. 231", "csduid" : "4708056", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Happy Valley-Goose Bay", "csduid" : "1010025", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Happy Valley No. 10", "csduid" : "4702018", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Harbour Breton", "csduid" : "1003008", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Harbour Grace", "csduid" : "1001379", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Harbour Main-Chapel's Cove-Lakeview", "csduid" : "1001469", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Harcourt", "csduid" : "1308010", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Hardisty", "csduid" : "4807048", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Hardwicke", "csduid" : "1309001", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Hare Bay", "csduid" : "1007049", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Harley", "csduid" : "3554026", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Harrington", "csduid" : "2476065", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Harris", "csduid" : "3554014", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Harris", "csduid" : "4712032", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Harris 3", "csduid" : "5937805", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Harris No. 316", "csduid" : "4712029", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Harrison Hot Springs", "csduid" : "5909027", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Harrison Park", "csduid" : "4615070", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Hart Butte No. 11", "csduid" : "4703001", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Hartland", "csduid" : "1311012", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Harvey", "csduid" : "1306004", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Harvey", "csduid" : "1310005", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Hastings Highlands", "csduid" : "3512076", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Hatley", "csduid" : "2445043", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Hatley", "csduid" : "2445055", "cmaname" : "", "cmauid" : "433", "prname" : "Quebec / Québec"},
    {"csdname" : "Haut-Madawaska", "csduid" : "1313031", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Havelock", "csduid" : "1305028", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Havelock", "csduid" : "2469005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Havelock-Belmont-Methuen", "csduid" : "3515030", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Havre-Saint-Pierre", "csduid" : "2498040", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Hawarden", "csduid" : "4711024", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Hawke's Bay", "csduid" : "1009018", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Hawkesbury", "csduid" : "3502008", "cmaname" : "", "cmauid" : "502", "prname" : "Ontario"},
    {"csdname" : "Hay Lake 209", "csduid" : "4817845", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Hay Lakes", "csduid" : "4810014", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Hay River", "csduid" : "6105016", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Hay River Dene 1", "csduid" : "6104017", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Hazelbrook", "csduid" : "1102011", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Hazel Dell No. 335", "csduid" : "4709060", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Hazelton", "csduid" : "5949022", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Hazelwood No. 94", "csduid" : "4701072", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Hazenmore", "csduid" : "4703052", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Hazlet", "csduid" : "4708029", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Head, Clara and Maria", "csduid" : "3547098", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Headingley", "csduid" : "4611042", "cmaname" : "", "cmauid" : "602", "prname" : "Manitoba"},
    {"csdname" : "Hearst", "csduid" : "3556076", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Heart Lake 167", "csduid" : "4812840", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Heart's Content", "csduid" : "1001325", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Heart's Delight-Islington", "csduid" : "1001312", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Heart's Desire", "csduid" : "1001316", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Heart's Hill No. 352", "csduid" : "4713046", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Hébertville", "csduid" : "2493020", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Hébertville-Station", "csduid" : "2493025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Heisler", "csduid" : "4807038", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Hemmingford", "csduid" : "2468010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Hemmingford", "csduid" : "2468015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Henryville", "csduid" : "2456042", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Henvey Inlet 2", "csduid" : "3549075", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Hepburn", "csduid" : "4715027", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Herbert", "csduid" : "4707026", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Hermitage-Sandyville", "csduid" : "1003011", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Hérouxville", "csduid" : "2435035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Hesquiat 1", "csduid" : "5923806", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Heward", "csduid" : "4701067", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Hiawatha First Nation", "csduid" : "3515008", "cmaname" : "", "cmauid" : "529", "prname" : "Ontario"},
    {"csdname" : "High Bar 1", "csduid" : "5933819", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Highlands", "csduid" : "5917049", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Highlands East", "csduid" : "3546005", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "High Level", "csduid" : "4817093", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "High Prairie", "csduid" : "4817021", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "High River", "csduid" : "4806006", "cmaname" : "", "cmauid" : "821", "prname" : "Alberta"},
    {"csdname" : "Hilliard", "csduid" : "3554034", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Hillsborough", "csduid" : "1306011", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Hillsborough", "csduid" : "1306025", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Hillsborough No. 132", "csduid" : "4707004", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Hillsburg-Roblin-Shell River", "csduid" : "4616048", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Hillsdale No. 440", "csduid" : "4713092", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Hill Spring", "csduid" : "4803008", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Hilton", "csduid" : "3557004", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Hilton Beach", "csduid" : "3557006", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Hinchinbrooke", "csduid" : "2469045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Hines Creek", "csduid" : "4817064", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Hinton", "csduid" : "4814019", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Hodgeville", "csduid" : "4707016", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Holachten 8", "csduid" : "5909879", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Holden", "csduid" : "4810021", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Holdfast", "csduid" : "4706067", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Hole or Hollow Water 10", "csduid" : "4619053", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Holyrood", "csduid" : "1001472", "cmaname" : "", "cmauid" : "001", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Homalco 9", "csduid" : "5924840", "cmaname" : "", "cmauid" : "944", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Honfleur", "csduid" : "2419070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Hoodoo No. 401", "csduid" : "4715039", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Hope", "csduid" : "2405025", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Hope", "csduid" : "5909009", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Hopedale", "csduid" : "1011030", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Hope Island 1", "csduid" : "5943836", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Hope Town", "csduid" : "2405020", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Hopetown 10A", "csduid" : "5943809", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Hopewell", "csduid" : "1306001", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Hornepayne", "csduid" : "3557096", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Horse Lakes 152B", "csduid" : "4819815", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Horseshoe Bay", "csduid" : "4812020", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Horton", "csduid" : "3547046", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Houpsitas 6", "csduid" : "5924806", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Houston", "csduid" : "5951034", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Howick", "csduid" : "2469025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Howick", "csduid" : "3540046", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Howley", "csduid" : "1005006", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Hubbard", "csduid" : "4710002", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Huberdeau", "csduid" : "2478065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Hudson", "csduid" : "2471100", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Hudson", "csduid" : "3554021", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Hudson Bay", "csduid" : "4714004", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Hudson Bay No. 394", "csduid" : "4714001", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Hudson's Hope", "csduid" : "5955025", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Hughenden", "csduid" : "4807006", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Hughes Brook", "csduid" : "1005028", "cmaname" : "", "cmauid" : "015", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Humber Arm South", "csduid" : "1005020", "cmaname" : "", "cmauid" : "015", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Humboldt", "csduid" : "4715008", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Humboldt No. 370", "csduid" : "4715007", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Hunter River", "csduid" : "1102043", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Hunter's Point", "csduid" : "2485803", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Huntingdon", "csduid" : "2469055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Huntsville", "csduid" : "3544042", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Huron East", "csduid" : "3540040", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Huron-Kinloss", "csduid" : "3541015", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Huron No. 223", "csduid" : "4707076", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Huron Shores", "csduid" : "3557035", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Huskisson", "csduid" : "1308012", "cmaname" : "", "cmauid" : "999", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Hussar", "csduid" : "4805019", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Hustalen 1", "csduid" : "5939802", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Hyas", "csduid" : "4709071", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Hythe", "csduid" : "4819008", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Ibex Valley", "csduid" : "6001055", "cmaname" : "", "cmauid" : "990", "prname" : "Yukon"},
    {"csdname" : "Igloolik", "csduid" : "6204012", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Ignace", "csduid" : "3560001", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Île-à-la-Crosse", "csduid" : "4718067", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Ilford", "csduid" : "4622064", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Imperial", "csduid" : "4711008", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Improvement District No. 12 Jasper Park", "csduid" : "4815037", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Improvement District No. 13 Elk Island", "csduid" : "4810068", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Improvement District No. 24 Wood Buffalo", "csduid" : "4816051", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Improvement District No. 25 Willmore Wilderness", "csduid" : "4814028", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Improvement District No. 349", "csduid" : "4812038", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Improvement District No. 4 Waterton", "csduid" : "4803003", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Improvement District No. 9 Banff", "csduid" : "4815032", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Indian Bay", "csduid" : "1007067", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Indian Brook 14", "csduid" : "1208014", "cmaname" : "", "cmauid" : "205", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Indian Head", "csduid" : "4706039", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Indian Head No. 156", "csduid" : "4706038", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Indian Island 28", "csduid" : "1308020", "cmaname" : "", "cmauid" : "999", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Indian Ranch", "csduid" : "1314026", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Ingenika Point", "csduid" : "5955812", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ingersoll", "csduid" : "3532018", "cmaname" : "", "cmauid" : "533", "prname" : "Ontario"},
    {"csdname" : "Inkahtsaph 6", "csduid" : "5909805", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Inkerman", "csduid" : "1315024", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Inkluckcheen", "csduid" : "5933833", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Inklyuhkinatko 2", "csduid" : "5933825", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Innisfail", "csduid" : "4808008", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Innisfil", "csduid" : "3543017", "cmaname" : "", "cmauid" : "568", "prname" : "Ontario"},
    {"csdname" : "Innisfree", "csduid" : "4810031", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Insinger No. 275", "csduid" : "4709023", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Inukjuak", "csduid" : "2499085", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Inukjuak", "csduid" : "2499879", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Inuvik", "csduid" : "6101017", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Invergordon No. 430", "csduid" : "4715054", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Invermay", "csduid" : "4709058", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Invermay No. 305", "csduid" : "4709054", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Invermere", "csduid" : "5901039", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Inverness", "csduid" : "2432058", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Inverness, Subd. A", "csduid" : "1215011", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Inverness, Subd. B", "csduid" : "1215006", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Inverness, Subd. C", "csduid" : "1215001", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Iqaluit", "csduid" : "6204003", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Irishtown-Summerside", "csduid" : "1005030", "cmaname" : "", "cmauid" : "015", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Irlande", "csduid" : "2431040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Irma", "csduid" : "4807056", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Iroquois Falls", "csduid" : "3556031", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Irricana", "csduid" : "4806022", "cmaname" : "", "cmauid" : "825", "prname" : "Alberta"},
    {"csdname" : "Isidore's Ranch 4", "csduid" : "5901802", "cmaname" : "", "cmauid" : "905", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Iskut 6", "csduid" : "5949832", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Island Lake", "csduid" : "4813049", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Island Lake South", "csduid" : "4813051", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Island View", "csduid" : "4706085", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Isle aux Morts", "csduid" : "1003032", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Itaska Beach", "csduid" : "4811022", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Ittatsoo 1", "csduid" : "5923807", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ituna", "csduid" : "4710004", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Ituna Bon Accord No. 246", "csduid" : "4710003", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Ivry-sur-le-Lac", "csduid" : "2478042", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Ivujivik", "csduid" : "2499140", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Ivujivik", "csduid" : "2499885", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Jackhead 43", "csduid" : "4619061", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Jackson's Arm", "csduid" : "1005005", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "James", "csduid" : "3554042", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "James Smith 100", "csduid" : "4715849", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Jans Bay", "csduid" : "4718030", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Jansen", "csduid" : "4710054", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Janvier 194", "csduid" : "4816817", "cmaname" : "", "cmauid" : "860", "prname" : "Alberta"},
    {"csdname" : "Jarvis Bay", "csduid" : "4808005", "cmaname" : "", "cmauid" : "831", "prname" : "Alberta"},
    {"csdname" : "Jasper", "csduid" : "4815033", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Jean Baptiste 28", "csduid" : "5951830", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Jean Baptiste Gambler 183", "csduid" : "4817817", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Jean Marie River", "csduid" : "6104013", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Jocelyn", "csduid" : "3557001", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Joeyaska 2", "csduid" : "5933805", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "John D'Or Prairie 215", "csduid" : "4817837", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Johnson", "csduid" : "3557016", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Johnsons Crossing", "csduid" : "6001047", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Johnston", "csduid" : "1304014", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Johnstons River", "csduid" : "1102015", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Johny Sticks 2", "csduid" : "5941811", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Joliette", "csduid" : "2461025", "cmaname" : "", "cmauid" : "456", "prname" : "Quebec / Québec"},
    {"csdname" : "Joly", "csduid" : "3549051", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Juan de Fuca (Part 1)", "csduid" : "5917054", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Juan de Fuca (Part 2)", "csduid" : "5917056", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kahkewistahaw 72", "csduid" : "4705804", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Kahkewistahaw No. 72-Q", "csduid" : "4705828", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Kahkewistahaw No. 72-W", "csduid" : "4705815", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Kahmoose 4", "csduid" : "5909819", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kahnawake", "csduid" : "2467802", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Kahntah 3", "csduid" : "5959809", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kakisa", "csduid" : "6104005", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Kamloops", "csduid" : "5933042", "cmaname" : "", "cmauid" : "925", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kamloops 1", "csduid" : "5933880", "cmaname" : "", "cmauid" : "925", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kamouraska", "csduid" : "2414050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Kamsack", "csduid" : "4709039", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Kanaka Bar", "csduid" : "5933882", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kananaskis", "csduid" : "4815013", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Kanesatake", "csduid" : "2472802", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Kangiqsualujjuaq", "csduid" : "2499090", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Kangiqsualujjuaq", "csduid" : "2499894", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Kangiqsujuaq", "csduid" : "2499130", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Kangiqsujuaq", "csduid" : "2499888", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Kangirsuk", "csduid" : "2499110", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Kangirsuk", "csduid" : "2499890", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Kannata Valley", "csduid" : "4706075", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Kapasiwin", "csduid" : "4811044", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Kapawe'no First Nation 150B", "csduid" : "4817836", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Kapawe'no First Nation 150D", "csduid" : "4817830", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Kapawe'no First Nation 229", "csduid" : "4817865", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Kapawe'no First Nation 231", "csduid" : "4817826", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Kapuskasing", "csduid" : "3556066", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Kars", "csduid" : "1305036", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Kasabonika Lake", "csduid" : "3560096", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Kaslo", "csduid" : "5903023", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Katepwa", "csduid" : "4706050", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Katit 1", "csduid" : "5945803", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Katzie 1", "csduid" : "5915830", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Katzie 2", "csduid" : "5915813", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kawartha Lakes", "csduid" : "3516010", "cmaname" : "", "cmauid" : "530", "prname" : "Ontario"},
    {"csdname" : "Kawawachikamach", "csduid" : "2497806", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Kawawachikamach", "csduid" : "2499065", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Kazabazua", "csduid" : "2483015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Kearney", "csduid" : "3549018", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Kebaowek", "csduid" : "2485802", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Kedgwick", "csduid" : "1314020", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Keels", "csduid" : "1007025", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Keeseekoose 66", "csduid" : "4709820", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Keeseekoose 66A", "csduid" : "4709822", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Keeseekoose 66-CA-04", "csduid" : "4709824", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Keeseekoose 66-CA-05", "csduid" : "4709826", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Keeseekoose 66-CA-06", "csduid" : "4709828", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Keeseekoose 66-KE-04", "csduid" : "4709830", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Keeseekoose 66-KE-05", "csduid" : "4709832", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Keeseekoowenin 61", "csduid" : "4615067", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Kee-Way-Win", "csduid" : "3560104", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Kehewin 123", "csduid" : "4812811", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Kelliher", "csduid" : "4710009", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Kellross No. 247", "csduid" : "4710008", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Kelowna", "csduid" : "5935010", "cmaname" : "", "cmauid" : "915", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kelsey", "csduid" : "4621052", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Kelvington", "csduid" : "4714022", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Kelvington No. 366", "csduid" : "4714021", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Kenaston", "csduid" : "4711036", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Kendal", "csduid" : "4706003", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Kennedy", "csduid" : "4701077", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Keno Hill", "csduid" : "6001052", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Kenora", "csduid" : "3560010", "cmaname" : "", "cmauid" : "598", "prname" : "Ontario"},
    {"csdname" : "Kenora 38B", "csduid" : "3560066", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Kenora, Unorganized", "csduid" : "3560090", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Kenosee Lake", "csduid" : "4701079", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Kensington", "csduid" : "1103014", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Kensington, Part 1", "csduid" : "1103067", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Kensington, Part 2", "csduid" : "1102066", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Kent", "csduid" : "1311028", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Kent", "csduid" : "5909032", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kentville", "csduid" : "1207012", "cmaname" : "", "cmauid" : "210", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Keremeos", "csduid" : "5907009", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kerns", "csduid" : "3554024", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Kerrobert", "csduid" : "4713042", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Kettle Point 44", "csduid" : "3538056", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Keys No. 303", "csduid" : "4709046", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Key West No. 70", "csduid" : "4702057", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Kiamika", "csduid" : "2479025", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Killaloe, Hagarty and Richards", "csduid" : "3547033", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Killaly", "csduid" : "4705062", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Killam", "csduid" : "4807042", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Killarney", "csduid" : "3552036", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Killarney - Turtle Mountain", "csduid" : "4605025", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Kil-pah-las 3", "csduid" : "5919808", "cmaname" : "", "cmauid" : "937", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kimberley", "csduid" : "5901028", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kimmirut", "csduid" : "6204005", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Kimosom Pwatinahk 203", "csduid" : "4718852", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Kincaid", "csduid" : "4703051", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Kincardine", "csduid" : "3541024", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Kindersley", "csduid" : "4713008", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Kindersley No. 290", "csduid" : "4713006", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "King", "csduid" : "3519049", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Kingfisher Lake 1", "csduid" : "3560098", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "King George No. 256", "csduid" : "4707066", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Kingsbury", "csduid" : "2442070", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Kingsclear", "csduid" : "1310018", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Kingsclear 6", "csduid" : "1310019", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "King's Cove", "csduid" : "1007027", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Kingsey Falls", "csduid" : "2439097", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Kingsley No. 124", "csduid" : "4705014", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "King's Point", "csduid" : "1008069", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Kings, Subd. A", "csduid" : "1207001", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Kings, Subd. B", "csduid" : "1207016", "cmaname" : "", "cmauid" : "210", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Kings, Subd. C", "csduid" : "1207011", "cmaname" : "", "cmauid" : "210", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Kings, Subd. D", "csduid" : "1207021", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Kingston", "csduid" : "1102022", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Kingston", "csduid" : "1305014", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Kingston", "csduid" : "3510010", "cmaname" : "", "cmauid" : "521", "prname" : "Ontario"},
    {"csdname" : "Kingsville", "csduid" : "3537013", "cmaname" : "", "cmauid" : "559", "prname" : "Ontario"},
    {"csdname" : "Kinistin 91", "csduid" : "4714842", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Kinistino", "csduid" : "4715074", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Kinistino No. 459", "csduid" : "4715071", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Kinkora", "csduid" : "1103003", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Kinkora", "csduid" : "1103008", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Kinley", "csduid" : "4712052", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Kinnear's Mills", "csduid" : "2431105", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Kinoosao-Thomas Clark 204", "csduid" : "4718854", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Kipawa", "csduid" : "2485010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Kipling", "csduid" : "4705016", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Kippase 2", "csduid" : "5943815", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kippens", "csduid" : "1004020", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Kirkland", "csduid" : "2466102", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Kirkland Lake", "csduid" : "3554068", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Kisbey", "csduid" : "4701061", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Kiskaciwan No. 208", "csduid" : "4715820", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Kispiox 1", "csduid" : "5949813", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kitamaat 2", "csduid" : "5949803", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kitasoo 1", "csduid" : "5949802", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kitchener", "csduid" : "3530013", "cmaname" : "", "cmauid" : "541", "prname" : "Ontario"},
    {"csdname" : "Kitchenuhmaykoosib Aaki 84", "csduid" : "3560075", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Kitcisakik", "csduid" : "2489802", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Kitigan Zibi", "csduid" : "2483802", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Kitikmeot, Unorganized", "csduid" : "6208098", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Kitimat", "csduid" : "5949005", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kitimat-Stikine A", "csduid" : "5949039", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kitimat-Stikine B", "csduid" : "5949028", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kitimat-Stikine C (Part 1)", "csduid" : "5949013", "cmaname" : "", "cmauid" : "965", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kitimat-Stikine C (Part 2)", "csduid" : "5949020", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kitimat-Stikine D", "csduid" : "5949041", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kitimat-Stikine E", "csduid" : "5949018", "cmaname" : "", "cmauid" : "965", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kitimat-Stikine F", "csduid" : "5949038", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kitsakie 156B", "csduid" : "4718812", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Kitscoty", "csduid" : "4810041", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Kitselas 1", "csduid" : "5949844", "cmaname" : "", "cmauid" : "965", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kitsumkaylum 1", "csduid" : "5949804", "cmaname" : "", "cmauid" : "965", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kitzowit 20", "csduid" : "5933823", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kivalliq, Unorganized", "csduid" : "6205033", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Kivimaa-Moonlight Bay", "csduid" : "4717042", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Klahkamich 17", "csduid" : "5933829", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kleetlekut 22", "csduid" : "5933831", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Klehkoot 2", "csduid" : "5923822", "cmaname" : "", "cmauid" : "940", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Klickkumcheen 18", "csduid" : "5933832", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kloklowuck 7", "csduid" : "5933861", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kloo Lake", "csduid" : "6001019", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Kluskus 1", "csduid" : "5941833", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kneehill County", "csduid" : "4805041", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Kootenay 1", "csduid" : "5901803", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kootenay Boundary A", "csduid" : "5905026", "cmaname" : "", "cmauid" : "910", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kootenay Boundary B / Lower Columbia-Old-Glory", "csduid" : "5905030", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kootenay Boundary C / Christina Lake", "csduid" : "5905050", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kootenay Boundary D / Rural Grand Forks", "csduid" : "5905052", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kootenay Boundary E / West Boundary", "csduid" : "5905054", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kopchitchin 2", "csduid" : "5909806", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kowtain 17", "csduid" : "5931802", "cmaname" : "", "cmauid" : "934", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Krydor", "csduid" : "4716019", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Kshish 4", "csduid" : "5949805", "cmaname" : "", "cmauid" : "965", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kugaaruk", "csduid" : "6208047", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Kugluktuk", "csduid" : "6208059", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Kulkayu (Hartley Bay) 4", "csduid" : "5947806", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kulkayu (Hartley Bay) 4A", "csduid" : "5947802", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kulspai 6", "csduid" : "5949807", "cmaname" : "", "cmauid" : "965", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kumcheen 1", "csduid" : "5933834", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kuujjuaq", "csduid" : "2499095", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Kuujjuaq", "csduid" : "2499893", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Kuujjuarapik", "csduid" : "2499075", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Kuujjuarapik", "csduid" : "2499877", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Kwawkwawapilt 6", "csduid" : "5909821", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Kyle", "csduid" : "4708044", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Labelle", "csduid" : "2478120", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "La Bostonnais", "csduid" : "2490017", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Labrador City", "csduid" : "1010032", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Labrecque", "csduid" : "2493055", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "La Broquerie", "csduid" : "4602053", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Lac-Achouakan", "csduid" : "2493906", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lacadena No. 228", "csduid" : "4708042", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Lac-Akonapwehikan", "csduid" : "2479904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-à-la-Croix", "csduid" : "2409904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Alfred", "csduid" : "2407912", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Ashuapmushuan", "csduid" : "2491902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-au-Brochet", "csduid" : "2495902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-au-Saumon", "csduid" : "2407057", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-aux-Sables", "csduid" : "2435010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac Baker", "csduid" : "1313037", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Lac-Bazinet", "csduid" : "2479910", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Beauport", "csduid" : "2422040", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Blanc", "csduid" : "2434902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Boisbouscache", "csduid" : "2411902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Bouchette", "csduid" : "2491005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Boulé", "csduid" : "2435908", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac Brochet 197A", "csduid" : "4623067", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Lac-Brome", "csduid" : "2446075", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Cabasta", "csduid" : "2462919", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Casault", "csduid" : "2407908", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Chicobi", "csduid" : "2488904", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Croche", "csduid" : "2422902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-De La Bidière", "csduid" : "2479912", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Delage", "csduid" : "2422030", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-de-la-Maison-de-Pierre", "csduid" : "2479916", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-de-la-Pomme", "csduid" : "2479902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-des-Aigles", "csduid" : "2413060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-des-Dix-Milles", "csduid" : "2462914", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-des-Eaux-Mortes", "csduid" : "2409902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-des-Écorces", "csduid" : "2479078", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac des Mille Lacs 22A1", "csduid" : "3558100", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Lac-Despinassy", "csduid" : "2488902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-des-Plages", "csduid" : "2480130", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-des-Seize-Îles", "csduid" : "2477055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Devenyns", "csduid" : "2462904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Douaire", "csduid" : "2479922", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Drolet", "csduid" : "2430080", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac du Bonnet", "csduid" : "4601057", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Lac du Bonnet", "csduid" : "4601060", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Lac-du-Cerf", "csduid" : "2479015", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Duparquet", "csduid" : "2487902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-du-Taureau", "csduid" : "2462922", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Édouard", "csduid" : "2490027", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Ernest", "csduid" : "2479924", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Etchemin", "csduid" : "2428053", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Frontière", "csduid" : "2418010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Granet", "csduid" : "2489912", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Huron", "csduid" : "2410902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lachute", "csduid" : "2476020", "cmaname" : "", "cmauid" : "468", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Jacques-Cartier", "csduid" : "2421904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Jérôme", "csduid" : "2498904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-John", "csduid" : "2497810", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Juillet", "csduid" : "2497912", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac la Biche County", "csduid" : "4812037", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Lac La Hache 220", "csduid" : "4718832", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Lac-Lapeyrère", "csduid" : "2434906", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac La Ronge 156", "csduid" : "4718809", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Lac-Legendre", "csduid" : "2462910", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Lenôtre", "csduid" : "2483906", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Marguerite", "csduid" : "2479926", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Masketsi", "csduid" : "2435902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Matapédia", "csduid" : "2407914", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Matawin", "csduid" : "2462908", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Mégantic", "csduid" : "2430030", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Metei", "csduid" : "2489908", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Minaki", "csduid" : "2462902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Ministuk", "csduid" : "2494928", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Moncouche", "csduid" : "2493904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Moselle", "csduid" : "2483908", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Nilgaut", "csduid" : "2484902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Normand", "csduid" : "2435904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lacolle", "csduid" : "2456023", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Lacombe", "csduid" : "4808031", "cmaname" : "", "cmauid" : "832", "prname" : "Alberta"},
    {"csdname" : "Lacombe County", "csduid" : "4808022", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "La Conception", "csduid" : "2478115", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "La Corne", "csduid" : "2488030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Oscar", "csduid" : "2479914", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac Pelletier No. 107", "csduid" : "4704061", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Lac-Pikauba", "csduid" : "2416902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Poulin", "csduid" : "2429095", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Pythonga", "csduid" : "2483902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Rapide", "csduid" : "2483804", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Saguay", "csduid" : "2479060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Sainte-Marie", "csduid" : "2483020", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Saint-Joseph", "csduid" : "2422015", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Saint-Paul", "csduid" : "2479105", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Santé", "csduid" : "2462916", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Sergent", "csduid" : "2434120", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac Seul 28", "csduid" : "3560056", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Lac Simon", "csduid" : "2489804", "cmaname" : "", "cmauid" : "480", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Simon", "csduid" : "2480095", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac Ste. Anne County", "csduid" : "4813001", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Lac-Supérieur", "csduid" : "2478095", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Tremblant-Nord", "csduid" : "2478127", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Vacher", "csduid" : "2497914", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Wagwabika", "csduid" : "2479906", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lac-Walker", "csduid" : "2497904", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "La Doré", "csduid" : "2491050", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "La Durantaye", "csduid" : "2419090", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Ladysmith", "csduid" : "5919021", "cmaname" : "", "cmauid" : "936", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lafleche", "csduid" : "4703044", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Laforce", "csduid" : "2485070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "La Guadeloupe", "csduid" : "2429030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Laird", "csduid" : "3557011", "cmaname" : "", "cmauid" : "590", "prname" : "Ontario"},
    {"csdname" : "Laird", "csduid" : "4715029", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Laird No. 404", "csduid" : "4715026", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Lajord No. 128", "csduid" : "4706011", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Lakahahmen 11", "csduid" : "5909880", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lake Alma No. 8", "csduid" : "4702011", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Lake Country", "csduid" : "5935016", "cmaname" : "", "cmauid" : "915", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lake Cowichan", "csduid" : "5919016", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lake Helen 53A", "csduid" : "3558064", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Lake Johnston No. 102", "csduid" : "4703093", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Lake Laberge 1", "csduid" : "6001010", "cmaname" : "", "cmauid" : "990", "prname" : "Yukon"},
    {"csdname" : "Lakeland No. 521", "csduid" : "4715075", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Lake Lenore", "csduid" : "4715006", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Lake Lenore No. 399", "csduid" : "4715048", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Lake Nipigon", "csduid" : "3558077", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Lake of Bays", "csduid" : "3544027", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Lake of the Rivers No. 72", "csduid" : "4703034", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Lake of the Woods", "csduid" : "3559047", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Lake of the Woods 37", "csduid" : "3560065", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Lakeshore", "csduid" : "3537064", "cmaname" : "", "cmauid" : "559", "prname" : "Ontario"},
    {"csdname" : "Lakeshore", "csduid" : "4617075", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Lakeside No. 338", "csduid" : "4710061", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Laketown 3", "csduid" : "5951813", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lakeview", "csduid" : "4811042", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Lakeview No. 337", "csduid" : "4710066", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Lalemant", "csduid" : "2494926", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "La Loche", "csduid" : "4718074", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "La Macaza", "csduid" : "2479047", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "La Malbaie", "csduid" : "2415013", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lamaline", "csduid" : "1002012", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Lamarche", "csduid" : "2493060", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "La Martre", "csduid" : "2404030", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lambton", "csduid" : "2430095", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lambton Shores", "csduid" : "3538040", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Lamèque", "csduid" : "1315032", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "La Minerve", "csduid" : "2478130", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lamont", "csduid" : "4810064", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Lamont County", "csduid" : "4810058", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "La Morandière", "csduid" : "2488015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "La Motte", "csduid" : "2488045", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Lampman", "csduid" : "4701032", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Lanark Highlands", "csduid" : "3509039", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Lancer", "csduid" : "4708049", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "L'Ancienne-Lorette", "csduid" : "2423057", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Landis", "csduid" : "4713067", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Landrienne", "csduid" : "2488035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Lang", "csduid" : "4702071", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "L'Ange-Gardien", "csduid" : "2421040", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "L'Ange-Gardien", "csduid" : "2482005", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Langenburg", "csduid" : "4705048", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Langenburg No. 181", "csduid" : "4705047", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Langford", "csduid" : "5917044", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Langham", "csduid" : "4711067", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Langley", "csduid" : "5915001", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Langley", "csduid" : "5915002", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Langley 2", "csduid" : "5909856", "cmaname" : "", "cmauid" : "932", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Laniel", "csduid" : "2485905", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lanigan", "csduid" : "4711049", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Lanoraie", "csduid" : "2452017", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Lansdowne House", "csduid" : "3560081", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "L'Anse-au-Clair", "csduid" : "1010004", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "L'Anse au Loup", "csduid" : "1010002", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "L'Anse-Saint-Jean", "csduid" : "2494210", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Lantier", "csduid" : "2478015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Lantzville", "csduid" : "5921008", "cmaname" : "", "cmauid" : "938", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "La Patrie", "csduid" : "2441027", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "La Pêche", "csduid" : "2482035", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "La Plonge 192", "csduid" : "4718807", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "La Pocatière", "csduid" : "2414085", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "La Prairie", "csduid" : "2467015", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "La Présentation", "csduid" : "2454035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Larder Lake", "csduid" : "3554062", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "La Rédemption", "csduid" : "2409005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "La Reine", "csduid" : "2487080", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lark Harbour", "csduid" : "1005024", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Larkspur", "csduid" : "4813033", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "La Ronge", "csduid" : "4718041", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Larouche", "csduid" : "2494265", "cmaname" : "", "cmauid" : "408", "prname" : "Quebec / Québec"},
    {"csdname" : "LaSalle", "csduid" : "3537034", "cmaname" : "", "cmauid" : "559", "prname" : "Ontario"},
    {"csdname" : "La Sarre", "csduid" : "2487090", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "L'Ascension", "csduid" : "2479050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "L'Ascension-de-Notre-Seigneur", "csduid" : "2493065", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "L'Ascension-de-Patapédia", "csduid" : "2406060", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "LaScie", "csduid" : "1008076", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Lashburn", "csduid" : "4717024", "cmaname" : "", "cmauid" : "840", "prname" : "Saskatchewan"},
    {"csdname" : "L'Assomption", "csduid" : "2460028", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Last Mountain Valley No. 250", "csduid" : "4711003", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Latchford", "csduid" : "3554006", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "La Trinité-des-Monts", "csduid" : "2410010", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Latulipe-et-Gaboury", "csduid" : "2485060", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "La Tuque", "csduid" : "2490012", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Launay", "csduid" : "2488080", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Laurentian Hills", "csduid" : "3547090", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Laurentian Valley", "csduid" : "3547075", "cmaname" : "", "cmauid" : "515", "prname" : "Ontario"},
    {"csdname" : "Laurier No. 38", "csduid" : "4702029", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Laurier-Station", "csduid" : "2433060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Laurierville", "csduid" : "2432072", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Laval", "csduid" : "2465005", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "La Vallee", "csduid" : "3559016", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Lavaltrie", "csduid" : "2452007", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "L'Avenir", "csduid" : "2449025", "cmaname" : "", "cmauid" : "447", "prname" : "Quebec / Québec"},
    {"csdname" : "Laverlochère-Angliers", "csduid" : "2485052", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "La Visitation-de-l'Île-Dupas", "csduid" : "2452050", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "La Visitation-de-Yamaska", "csduid" : "2450085", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lawn", "csduid" : "1002011", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Lawrenceville", "csduid" : "2442045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lawtonia No. 135", "csduid" : "4707014", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Lax Kw'alaams 1", "csduid" : "5947809", "cmaname" : "", "cmauid" : "955", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Leader", "csduid" : "4708058", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Leading Tickles", "csduid" : "1008048", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Leaf Rapids", "csduid" : "4623034", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Leamington", "csduid" : "3537003", "cmaname" : "", "cmauid" : "559", "prname" : "Ontario"},
    {"csdname" : "Leask", "csduid" : "4716042", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Leask No. 464", "csduid" : "4716041", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Lebel-sur-Quévillon", "csduid" : "2499005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lebret", "csduid" : "4706049", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Leclercville", "csduid" : "2433123", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Leduc", "csduid" : "4811016", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Leduc County", "csduid" : "4811012", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Leeds and the Thousand Islands", "csduid" : "3507021", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Lefebvre", "csduid" : "2449020", "cmaname" : "", "cmauid" : "447", "prname" : "Quebec / Québec"},
    {"csdname" : "Legal", "csduid" : "4811069", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Le Goulet", "csduid" : "1315030", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Lejeune", "csduid" : "2413050", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lemberg", "csduid" : "4705066", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Lemieux", "csduid" : "2438020", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Lennox Island 1", "csduid" : "1103035", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Leoville", "csduid" : "4716061", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "L'Épiphanie", "csduid" : "2460037", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Lepreau", "csduid" : "1302008", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Leross", "csduid" : "4710011", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Leroy", "csduid" : "4710058", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Leroy No. 339", "csduid" : "4710056", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Léry", "csduid" : "2467055", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Les Bergeronnes", "csduid" : "2495018", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Les Cèdres", "csduid" : "2471050", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Les Coteaux", "csduid" : "2471033", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Les Éboulements", "csduid" : "2416048", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Les Escoumins", "csduid" : "2495025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Les Hauteurs", "csduid" : "2409015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Les Îles-de-la-Madeleine", "csduid" : "2401023", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Les Lacs-du-Témiscamingue", "csduid" : "2485907", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Leslie Beach", "csduid" : "4710035", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Les Méchins", "csduid" : "2408005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lesser Slave River No. 124", "csduid" : "4817033", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Lethbridge", "csduid" : "4802012", "cmaname" : "", "cmauid" : "810", "prname" : "Alberta"},
    {"csdname" : "Lethbridge County", "csduid" : "4802011", "cmaname" : "", "cmauid" : "810", "prname" : "Alberta"},
    {"csdname" : "Lévis", "csduid" : "2425213", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Lewin's Cove", "csduid" : "1002002", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Lewisporte", "csduid" : "1008044", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Lezbye 6", "csduid" : "5941876", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Liard River 3", "csduid" : "5957814", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Liberty", "csduid" : "4711009", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "L'Île-Cadieux", "csduid" : "2471095", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "L'Île-d'Anticosti", "csduid" : "2498020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "L'Île-Dorval", "csduid" : "2466092", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "L'Île-du-Grand-Calumet", "csduid" : "2484035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "L'Île-Perrot", "csduid" : "2471060", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Lillooet", "csduid" : "5931026", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lillooet 1", "csduid" : "5931821", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Limerick", "csduid" : "3512051", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Limerick", "csduid" : "4703041", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Lincoln", "csduid" : "1303008", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Lincoln", "csduid" : "3526057", "cmaname" : "", "cmauid" : "539", "prname" : "Ontario"},
    {"csdname" : "Linden", "csduid" : "4805046", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Lingwick", "csduid" : "2441085", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Linkletter", "csduid" : "1103023", "cmaname" : "", "cmauid" : "110", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Lintlaw", "csduid" : "4709061", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Linton", "csduid" : "2434904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Lions Bay", "csduid" : "5915065", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lipton", "csduid" : "4706098", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Lipton No. 217", "csduid" : "4706096", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "L'Isle-aux-Allumettes", "csduid" : "2484082", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "L'Isle-aux-Coudres", "csduid" : "2416023", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "L'Islet", "csduid" : "2417078", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "L'Isle-Verte", "csduid" : "2412043", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Listuguj", "csduid" : "2406804", "cmaname" : "", "cmauid" : "330", "prname" : "Quebec / Québec"},
    {"csdname" : "Litchfield", "csduid" : "2484040", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Little Bay", "csduid" : "1008063", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Little Bay East", "csduid" : "1002055", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Little Bay Islands", "csduid" : "1008064", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Little Black Bear 84", "csduid" : "4706814", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Little Bone 74B", "csduid" : "4705808", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Little Buffalo", "csduid" : "4817853", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Little Burnt Bay", "csduid" : "1008045", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Little Grand Rapids 14", "csduid" : "4619051", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Little Hills 158", "csduid" : "4718844", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Little Hills 158B", "csduid" : "4718849", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Little Pine 116", "csduid" : "4713836", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Little Red River 106C", "csduid" : "4715851", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Little Red River 106D", "csduid" : "4716857", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Little Saskatchewan 48", "csduid" : "4618063", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Little Springs", "csduid" : "5941885", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Livingston No. 331", "csduid" : "4709075", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Lloydminster (Part)", "csduid" : "4717029", "cmaname" : "", "cmauid" : "840", "prname" : "Saskatchewan"},
    {"csdname" : "Lloydminster (Part)", "csduid" : "4810039", "cmaname" : "", "cmauid" : "840", "prname" : "Alberta"},
    {"csdname" : "Lochaber", "csduid" : "2480055", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Lochaber-Partie-Ouest", "csduid" : "2480060", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Lockeport", "csduid" : "1201009", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Logan Lake", "csduid" : "5933035", "cmaname" : "", "cmauid" : "925", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Logy Bay-Middle Cove-Outer Cove", "csduid" : "1001511", "cmaname" : "", "cmauid" : "001", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Lohbiee 3", "csduid" : "5941808", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lomond", "csduid" : "4805008", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Lomond No. 37", "csduid" : "4702033", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "London", "csduid" : "3539036", "cmaname" : "", "cmauid" : "555", "prname" : "Ontario"},
    {"csdname" : "Lone Tree No. 18", "csduid" : "4704006", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Long Harbour-Mount Arlington Heights", "csduid" : "1001263", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Long Lake 58", "csduid" : "3558068", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Longlaketon No. 219", "csduid" : "4706070", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Long Plain", "csduid" : "4609030", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Long Plain 6", "csduid" : "4609025", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Longue-Pointe-de-Mingan", "csduid" : "2498045", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Longue-Rive", "csduid" : "2495032", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Longueuil", "csduid" : "2458227", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Longview", "csduid" : "4806008", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Loon Lake", "csduid" : "4717058", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Loon Lake 235", "csduid" : "4817860", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Loon Lake No. 561", "csduid" : "4717056", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Lord's Cove", "csduid" : "1002013", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Loreburn", "csduid" : "4711021", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Loreburn No. 254", "csduid" : "4711018", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Lorne", "csduid" : "1312011", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Lorne", "csduid" : "4604064", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Lorraine", "csduid" : "2473025", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Lorrainville", "csduid" : "2485037", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Lost River No. 313", "csduid" : "4711059", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Lot 11 and Area", "csduid" : "1103039", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Lotbinière", "csduid" : "2433115", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lougheed", "csduid" : "4807046", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Louis Bull 138B", "csduid" : "4811803", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Louis Creek 4", "csduid" : "5933888", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Louise", "csduid" : "4604040", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Louiseville", "csduid" : "2451015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lourdes", "csduid" : "1004037", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Love", "csduid" : "4714091", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Low", "csduid" : "2483010", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Lower Hat Creek 2", "csduid" : "5933836", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lower Post", "csduid" : "5957813", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lower Similkameen 2", "csduid" : "5907801", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Loyalist", "csduid" : "3511005", "cmaname" : "", "cmauid" : "521", "prname" : "Ontario"},
    {"csdname" : "Lucan Biddulph", "csduid" : "3539060", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Lucky Lake", "csduid" : "4707059", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Lucky Man", "csduid" : "4716870", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Lukseetsissum 9", "csduid" : "5909810", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lulu 5", "csduid" : "5907850", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lumby", "csduid" : "5937005", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lumsden", "csduid" : "1008004", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Lumsden", "csduid" : "4706056", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Lumsden Beach", "csduid" : "4706057", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Lumsden No. 189", "csduid" : "4706053", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Lunenburg", "csduid" : "1206001", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Lunenburg", "csduid" : "1206006", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Luseland", "csduid" : "4713044", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Lushes Bight-Beaumont-Beaumont North", "csduid" : "1008056", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Lutselk'e", "csduid" : "6105020", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Lyacksun 3", "csduid" : "5919813", "cmaname" : "", "cmauid" : "936", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lynn Lake", "csduid" : "4623047", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Lyster", "csduid" : "2432065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Lytton", "csduid" : "5933015", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lytton 4A", "csduid" : "5933839", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lytton 4E", "csduid" : "5933840", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lytton 9A", "csduid" : "5933841", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Lytton 9B", "csduid" : "5933842", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Macamic", "csduid" : "2487058", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Macdonald", "csduid" : "4610035", "cmaname" : "", "cmauid" : "602", "prname" : "Manitoba"},
    {"csdname" : "Macdonald, Meredith and Aberdeen Additional", "csduid" : "3557051", "cmaname" : "", "cmauid" : "590", "prname" : "Ontario"},
    {"csdname" : "Machar", "csduid" : "3549054", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Machin", "csduid" : "3560021", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Mackenzie", "csduid" : "5953033", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Mackenzie County", "csduid" : "4817095", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Macklin", "csduid" : "4713051", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "MacNutt", "csduid" : "4705096", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Macoah 1", "csduid" : "5923810", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Macoun", "csduid" : "4702041", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Macpherson-Grizzly Valley", "csduid" : "6001059", "cmaname" : "", "cmauid" : "990", "prname" : "Yukon"},
    {"csdname" : "Macrorie", "csduid" : "4712019", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Madawaska", "csduid" : "1313028", "cmaname" : "", "cmauid" : "999", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Madawaska Valley", "csduid" : "3547030", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Maddington Falls", "csduid" : "2439165", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Madoc", "csduid" : "3512036", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Magnetawan", "csduid" : "3549043", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Magnetewan 1", "csduid" : "3549078", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Magog", "csduid" : "2445072", "cmaname" : "", "cmauid" : "433", "prname" : "Quebec / Québec"},
    {"csdname" : "Magrath", "csduid" : "4803002", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Mahone Bay", "csduid" : "1206008", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Maidstone", "csduid" : "4717018", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Main Brook", "csduid" : "1009028", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Maisonnette", "csduid" : "1315040", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Major", "csduid" : "4713021", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Makaoo (Part) 120", "csduid" : "4717802", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Makaoo (Part) 120", "csduid" : "4810805", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Makkovik", "csduid" : "1011020", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Makwa", "csduid" : "4717057", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Makwa Lake 129", "csduid" : "4717808", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Makwa Lake 129B", "csduid" : "4717807", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Makwa Lake 129C", "csduid" : "4717815", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Malachan 11", "csduid" : "5919814", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Malagawatch 4", "csduid" : "1215005", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Malahat 11", "csduid" : "5919815", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Malahide", "csduid" : "3534010", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Malartic", "csduid" : "2489015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Maliotenam", "csduid" : "2497804", "cmaname" : "", "cmauid" : "412", "prname" : "Quebec / Québec"},
    {"csdname" : "Malpeque Bay", "csduid" : "1103007", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Ma-Me-O Beach", "csduid" : "4811008", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Mandeville", "csduid" : "2452095", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Manitou Beach", "csduid" : "4711054", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Manitou Lake No. 442", "csduid" : "4713079", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Manitoulin, Unorganized, West Part", "csduid" : "3551094", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Manitou Rapids 11", "csduid" : "3559026", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Manitouwadge", "csduid" : "3558066", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Maniwaki", "csduid" : "2483065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Mankota", "csduid" : "4703019", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Mankota No. 45", "csduid" : "4703018", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Manners Sutton", "csduid" : "1310004", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Manning", "csduid" : "4817078", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Mannville", "csduid" : "4810034", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Manor", "csduid" : "4701054", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Manseau", "csduid" : "2438028", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Mansfield-et-Pontefract", "csduid" : "2484065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Maple Bush No. 224", "csduid" : "4707071", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Maple Creek", "csduid" : "4704048", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Maple Creek No. 111", "csduid" : "4704045", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Maple Ridge", "csduid" : "5915075", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Mapleton", "csduid" : "3523033", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Marathon", "csduid" : "3558059", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Marble Canyon 3", "csduid" : "5933872", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Marcelin", "csduid" : "4716014", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Marengo", "csduid" : "4713014", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Margo", "csduid" : "4710072", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Maria", "csduid" : "2406005", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Maricourt", "csduid" : "2442065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Marieville", "csduid" : "2455048", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Mariposa No. 350", "csduid" : "4713038", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Markham", "csduid" : "3519036", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Markinch", "csduid" : "4706093", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Markstay-Warren", "csduid" : "3552013", "cmaname" : "", "cmauid" : "580", "prname" : "Ontario"},
    {"csdname" : "Marktosis 15", "csduid" : "5923808", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Marmora and Lake", "csduid" : "3512046", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Marquis", "csduid" : "4707044", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Marquis No. 191", "csduid" : "4707042", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Marriott No. 317", "csduid" : "4712034", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Marsden", "csduid" : "4713091", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Marshall", "csduid" : "4717026", "cmaname" : "", "cmauid" : "840", "prname" : "Saskatchewan"},
    {"csdname" : "Marsh Lake", "csduid" : "6001058", "cmaname" : "", "cmauid" : "990", "prname" : "Yukon"},
    {"csdname" : "Marsoui", "csduid" : "2404025", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Marston", "csduid" : "2430035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Marten Falls 65", "csduid" : "3560052", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Martensville", "csduid" : "4711070", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Martin No. 122", "csduid" : "4705007", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Martinville", "csduid" : "2444060", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Marwayne", "csduid" : "4810044", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Maryfield", "csduid" : "4701096", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Maryfield No. 91", "csduid" : "4701094", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Mary's Harbour", "csduid" : "1010011", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Marystown", "csduid" : "1002024", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Mascouche", "csduid" : "2464015", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Mashteuiatsh", "csduid" : "2491802", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Maskinongé", "csduid" : "2451008", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Masset", "csduid" : "5947023", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Masset 1", "csduid" : "5947803", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Massey Drive", "csduid" : "1005017", "cmaname" : "", "cmauid" : "015", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Massueville", "csduid" : "2453010", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Matachewan", "csduid" : "3554056", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Matachewan 72", "csduid" : "3554057", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Matagami", "csduid" : "2499015", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Matane", "csduid" : "2408053", "cmaname" : "", "cmauid" : "403", "prname" : "Quebec / Québec"},
    {"csdname" : "Matapédia", "csduid" : "2406045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Matchi-Manitou", "csduid" : "2489902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Matimekosh", "csduid" : "2497808", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Matsqui 4", "csduid" : "5915825", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Matsqui Main 2", "csduid" : "5909878", "cmaname" : "", "cmauid" : "932", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Mattagami 71", "csduid" : "3552052", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Mattawa", "csduid" : "3548021", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Mattawan", "csduid" : "3548019", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Mattice-Val Côté", "csduid" : "3556077", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Maugerville", "csduid" : "1303016", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Mayerthorpe", "csduid" : "4813002", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Mayfield No. 406", "csduid" : "4716005", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Maymont", "csduid" : "4716004", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Mayo", "csduid" : "2480065", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Mayo", "csduid" : "6001022", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "McAdam", "csduid" : "1310006", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "McAdam", "csduid" : "1310007", "cmaname" : "", "cmauid" : "999", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "McBride", "csduid" : "5953012", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "McCartney's Flat 4", "csduid" : "5931823", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "McCraney No. 282", "csduid" : "4711034", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "McCreary", "csduid" : "4617035", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "McDougall", "csduid" : "3549031", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "McGarry", "csduid" : "3554058", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "M'Chigeeng 22", "csduid" : "3551045", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "McIvers", "csduid" : "1005022", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "McKellar", "csduid" : "3549028", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "McKillop No. 220", "csduid" : "4706071", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "McLean", "csduid" : "4706037", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "McLennan", "csduid" : "4819042", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "McLeod Lake 1", "csduid" : "5953802", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "McLeod No. 185", "csduid" : "4705063", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "McMasterville", "csduid" : "2457025", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "McMillan Island 6", "csduid" : "5915816", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "McMurrich/Monteith", "csduid" : "3549012", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "McNab/Braeside", "csduid" : "3547003", "cmaname" : "", "cmauid" : "505", "prname" : "Ontario"},
    {"csdname" : "McTaggart", "csduid" : "4702048", "cmaname" : "", "cmauid" : "755", "prname" : "Saskatchewan"},
    {"csdname" : "Meacham", "csduid" : "4711078", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Meadow Lake", "csduid" : "4717052", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Meadow Lake 105A", "csduid" : "4717819", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Meadow Lake No. 588", "csduid" : "4717054", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Meadows", "csduid" : "1005025", "cmaname" : "", "cmauid" : "015", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Meaford", "csduid" : "3542047", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Meath Park", "csduid" : "4715092", "cmaname" : "", "cmauid" : "745", "prname" : "Saskatchewan"},
    {"csdname" : "Medicine Hat", "csduid" : "4801006", "cmaname" : "", "cmauid" : "805", "prname" : "Alberta"},
    {"csdname" : "Medstead", "csduid" : "4716063", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Medstead No. 497", "csduid" : "4716062", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Meductic", "csduid" : "1310013", "cmaname" : "", "cmauid" : "999", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Medway River 11", "csduid" : "1204001", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Meeting Lake No. 466", "csduid" : "4716038", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Melancthon", "csduid" : "3522019", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Melbourne", "csduid" : "2442075", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Melfort", "csduid" : "4714051", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Melita", "csduid" : "4605052", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Melville", "csduid" : "4705074", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Melville Beach", "csduid" : "4705060", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Membertou 28B", "csduid" : "1217008", "cmaname" : "", "cmauid" : "225", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Memramcook", "csduid" : "1307013", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Mendham", "csduid" : "4708059", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Meota", "csduid" : "4717006", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Meota No. 468", "csduid" : "4717001", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Mercier", "csduid" : "2467045", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Merigomish Harbour 31", "csduid" : "1212023", "cmaname" : "", "cmauid" : "220", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Merrickville-Wolford", "csduid" : "3507052", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Merritt", "csduid" : "5933006", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Mervin", "csduid" : "4717039", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Mervin No. 499", "csduid" : "4717045", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Mesilinka 7", "csduid" : "5955850", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Messines", "csduid" : "2483060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Métabetchouan--Lac-à-la-Croix", "csduid" : "2493012", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Metchosin", "csduid" : "5917042", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Metinota", "csduid" : "4717004", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Métis-sur-Mer", "csduid" : "2409048", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Metro Vancouver A", "csduid" : "5915020", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Mewatha Beach", "csduid" : "4813045", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Michel Gardens 36", "csduid" : "5941879", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Michel Village", "csduid" : "4718072", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Midale", "csduid" : "4702039", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Middle Arm", "csduid" : "1008072", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Middle Lake", "csduid" : "4715047", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Middlesex Centre", "csduid" : "3539033", "cmaname" : "", "cmauid" : "555", "prname" : "Ontario"},
    {"csdname" : "Middleton", "csduid" : "1205016", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Midland", "csduid" : "3543074", "cmaname" : "", "cmauid" : "571", "prname" : "Ontario"},
    {"csdname" : "Midway", "csduid" : "5905037", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Milan", "csduid" : "2430040", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Milden", "csduid" : "4712012", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Milden No. 286", "csduid" : "4712011", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Miles Cove", "csduid" : "1008098", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Milestone", "csduid" : "4702067", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Milk River", "csduid" : "4802004", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Millbrook 27", "csduid" : "1210003", "cmaname" : "", "cmauid" : "215", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Mille-Isles", "csduid" : "2476030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Millertown", "csduid" : "1006028", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Millet", "csduid" : "4811011", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Milltown-Head of Bay d'Espoir", "csduid" : "1003018", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Millville", "csduid" : "1310025", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Milo", "csduid" : "4805009", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Milton", "csduid" : "3524009", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Milton No. 292", "csduid" : "4713011", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Miltonvale Park", "csduid" : "1102040", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Miminegash", "csduid" : "1103062", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Miminegash", "csduid" : "1103063", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Minburn County No. 27", "csduid" : "4810026", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Minden Hills", "csduid" : "3546015", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Mingan", "csduid" : "2498808", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Ming's Bight", "csduid" : "1008095", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Ministikwan 161", "csduid" : "4717809", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ministikwan 161A", "csduid" : "4717810", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Minitonas-Bowsman", "csduid" : "4620051", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Minnedosa", "csduid" : "4615075", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Minto", "csduid" : "1304022", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Minto", "csduid" : "3523043", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Minton", "csduid" : "4702016", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Minto-Odanah", "csduid" : "4615074", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Mirabel", "csduid" : "2474005", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Miramichi", "csduid" : "1309050", "cmaname" : "", "cmauid" : "329", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Miry Creek No. 229", "csduid" : "4708046", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Miscouche", "csduid" : "1103024", "cmaname" : "", "cmauid" : "110", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Miscouche", "csduid" : "1103040", "cmaname" : "", "cmauid" : "110", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Mission", "csduid" : "5909056", "cmaname" : "", "cmauid" : "932", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Mission 1", "csduid" : "5915807", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Mission 5", "csduid" : "5931833", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Mission Lands 17", "csduid" : "5951849", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Mississagi River 8", "csduid" : "3557073", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Mississauga", "csduid" : "3521005", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Mississaugas of Scugog Island", "csduid" : "3518022", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Mississippi Mills", "csduid" : "3509030", "cmaname" : "", "cmauid" : "505", "prname" : "Ontario"},
    {"csdname" : "Mistatim", "csduid" : "4714039", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Mistawasis 103", "csduid" : "4716855", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Mistissini", "csduid" : "2499030", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Mistissini", "csduid" : "2499804", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Mistusinne", "csduid" : "4707074", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Moffet", "csduid" : "2485075", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Moncton", "csduid" : "1307019", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Moncton", "csduid" : "1307022", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Monet No. 257", "csduid" : "4708092", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Mono", "csduid" : "3522012", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Montague", "csduid" : "1102005", "cmaname" : "", "cmauid" : "999", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Montague", "csduid" : "3509001", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Mont-Albert", "csduid" : "2404902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Mont-Alexandre", "csduid" : "2402902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Montana 139", "csduid" : "4808813", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Mont-Apica", "csduid" : "2493902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Montcalm", "csduid" : "2478055", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Montcalm", "csduid" : "4603030", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Mont-Carmel", "csduid" : "2414005", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Montcerf-Lytton", "csduid" : "2483088", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Montebello", "csduid" : "2480010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Mont-Élie", "csduid" : "2415902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Mont-Joli", "csduid" : "2409077", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Mont-Laurier", "csduid" : "2479088", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Montmagny", "csduid" : "2418050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Montmartre", "csduid" : "4706002", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Montmartre No. 126", "csduid" : "4706001", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Montpellier", "csduid" : "2480090", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Montréal", "csduid" : "2466023", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Montréal-Est", "csduid" : "2466007", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Montreal Lake 106", "csduid" : "4718802", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Montreal Lake 106B", "csduid" : "4715853", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Montréal-Ouest", "csduid" : "2466047", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Montrose", "csduid" : "5905009", "cmaname" : "", "cmauid" : "910", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Montrose No. 315", "csduid" : "4712026", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Mont-Royal", "csduid" : "2466072", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Mont-Saint-Grégoire", "csduid" : "2456097", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Mont-Saint-Hilaire", "csduid" : "2457035", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Mont-Saint-Michel", "csduid" : "2479110", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Mont-Saint-Pierre", "csduid" : "2404015", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Mont-Tremblant", "csduid" : "2478102", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Mont-Valin", "csduid" : "2494930", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Moonbeam", "csduid" : "3556056", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Moose Creek No. 33", "csduid" : "4701036", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Moose Factory 68", "csduid" : "3556096", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Moosehide Creek 2", "csduid" : "6001031", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Moose Jaw", "csduid" : "4707039", "cmaname" : "", "cmauid" : "715", "prname" : "Saskatchewan"},
    {"csdname" : "Moose Jaw No. 161", "csduid" : "4707038", "cmaname" : "", "cmauid" : "715", "prname" : "Saskatchewan"},
    {"csdname" : "Moose Lake 31A", "csduid" : "4621058", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Moose Lake 31D", "csduid" : "4621080", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Moose Lake 31G", "csduid" : "4621060", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Moose Mountain No. 63", "csduid" : "4701053", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Mooseocoot", "csduid" : "4622802", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Mooseocoot 4", "csduid" : "4622066", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Moose Point 79", "csduid" : "3544073", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Moose Range No. 486", "csduid" : "4714067", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Moosomin", "csduid" : "4705004", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Moosomin 112B", "csduid" : "4717812", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Moosomin No. 121", "csduid" : "4705001", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Moosonee", "csduid" : "3556106", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Moravian 47", "csduid" : "3536029", "cmaname" : "", "cmauid" : "556", "prname" : "Ontario"},
    {"csdname" : "Morden", "csduid" : "4603053", "cmaname" : "", "cmauid" : "603", "prname" : "Manitoba"},
    {"csdname" : "Morell", "csduid" : "1101043", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Morell", "csduid" : "1101048", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Morell 2", "csduid" : "1101050", "cmaname" : "", "cmauid" : "999", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Moricetown 1", "csduid" : "5949817", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Morin-Heights", "csduid" : "2477050", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Morin Lake 217", "csduid" : "4718808", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Morinville", "csduid" : "4811068", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Morley", "csduid" : "3559031", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Morrin", "csduid" : "4805038", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Morris", "csduid" : "4603065", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Morris", "csduid" : "4603067", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Morris No. 312", "csduid" : "4711052", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Morris-Turnberry", "csduid" : "3540050", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Morrisville", "csduid" : "1003020", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Morse", "csduid" : "4707027", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Morse No. 165", "csduid" : "4707024", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Mortlach", "csduid" : "4707034", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Mosquito 109", "csduid" : "4712830", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Mossbank", "csduid" : "4703092", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Mossey River", "csduid" : "4617072", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Mountain (North)", "csduid" : "4620055", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Mountain (South)", "csduid" : "4620032", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Mountain View County", "csduid" : "4806028", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Mountain View No. 318", "csduid" : "4712038", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Mount Carmel-Mitchells Brook-St. Catherine's", "csduid" : "1001197", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Mount Currie", "csduid" : "5931843", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Mount Hope No. 279", "csduid" : "4710024", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Mount Moriah", "csduid" : "1005033", "cmaname" : "", "cmauid" : "015", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Mount Pearl", "csduid" : "1001542", "cmaname" : "", "cmauid" : "001", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Mount Pleasant No. 2", "csduid" : "4701006", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Mount Stewart", "csduid" : "1102059", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Mount Waddington A", "csduid" : "5943037", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Mount Waddington B", "csduid" : "5943027", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Mount Waddington C", "csduid" : "5943031", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Mount Waddington D", "csduid" : "5943033", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Mt. Lorne", "csduid" : "6001044", "cmaname" : "", "cmauid" : "990", "prname" : "Yukon"},
    {"csdname" : "Muenster", "csduid" : "4715004", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Mulgrave", "csduid" : "1213008", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Mulgrave-et-Derry", "csduid" : "2480085", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Mulmur", "csduid" : "3522016", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Mundare", "csduid" : "4810061", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Munsee-Delaware Nation 1", "csduid" : "3539018", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Munson", "csduid" : "4805036", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Murdochville", "csduid" : "2403025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Murray Harbour", "csduid" : "1101002", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Murray Harbour", "csduid" : "1101003", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Murray River", "csduid" : "1101004", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Murray River, Part 1", "csduid" : "1101005", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Murray River, Part 2", "csduid" : "1102003", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Muscowpetung 80", "csduid" : "4706813", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Musgrave Harbour", "csduid" : "1008006", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Musgravetown", "csduid" : "1007032", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Muskeg Lake 102B", "csduid" : "4716872", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskeg Lake 102D", "csduid" : "4716891", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskeg Lake 102E", "csduid" : "4716886", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskeg Lake 102F", "csduid" : "4716888", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskeg Lake Cree Nation 102", "csduid" : "4716854", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Muskoday First Nation", "csduid" : "4715847", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Muskoka Lakes", "csduid" : "3544053", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Muskowekwan 85", "csduid" : "4710822", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-1", "csduid" : "4710848", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-10", "csduid" : "4710847", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-12", "csduid" : "4710843", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-15", "csduid" : "4710850", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-17", "csduid" : "4710832", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-22", "csduid" : "4710845", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-23", "csduid" : "4710842", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-26", "csduid" : "4710834", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-27", "csduid" : "4710846", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-28", "csduid" : "4710838", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-29", "csduid" : "4710840", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-2A", "csduid" : "4710851", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-31", "csduid" : "4710852", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-33", "csduid" : "4710836", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-4", "csduid" : "4710801", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskowekwan 85-8", "csduid" : "4710849", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Muskrat Dam Lake", "csduid" : "3560097", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Musquash", "csduid" : "1301016", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Musqueam 2", "csduid" : "5915803", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Musqueam 4", "csduid" : "5915810", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Myrnam", "csduid" : "4810051", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Mystery Lake", "csduid" : "4622017", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Nackawic", "csduid" : "1310054", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Nahanni Butte", "csduid" : "6104010", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Naicam", "csduid" : "4714032", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Nain", "csduid" : "1011035", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Nairn and Hyman", "csduid" : "3552031", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Naiscoutaing 17A", "csduid" : "3549093", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Nakamun Park", "csduid" : "4813003", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Nak'azdli", "csduid" : "5951803", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nakusp", "csduid" : "5903050", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nampa", "csduid" : "4817039", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Namur", "csduid" : "2480110", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Nanaimo", "csduid" : "5921007", "cmaname" : "", "cmauid" : "938", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nanaimo A", "csduid" : "5921010", "cmaname" : "", "cmauid" : "938", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nanaimo B", "csduid" : "5921014", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nanaimo C", "csduid" : "5921016", "cmaname" : "", "cmauid" : "938", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nanaimo E", "csduid" : "5921030", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nanaimo F", "csduid" : "5921032", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nanaimo G", "csduid" : "5921034", "cmaname" : "", "cmauid" : "939", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nanaimo H", "csduid" : "5921036", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nanaimo River", "csduid" : "5921807", "cmaname" : "", "cmauid" : "938", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nanaimo Town 1", "csduid" : "5921804", "cmaname" : "", "cmauid" : "938", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nanisivik", "csduid" : "6204019", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Nanoose", "csduid" : "5921805", "cmaname" : "", "cmauid" : "938", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nantes", "csduid" : "2430045", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Nanton", "csduid" : "4803026", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Napierville", "csduid" : "2468030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Natashquan", "csduid" : "2498025", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Natuashish 2", "csduid" : "1010801", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Naujaat", "csduid" : "6205027", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Nautley (Fort Fraser) 1", "csduid" : "5951802", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nazco 20", "csduid" : "5941837", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Necait 6", "csduid" : "5931826", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nédélec", "csduid" : "2485100", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Nedoats 11", "csduid" : "5951846", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Neebing", "csduid" : "3558001", "cmaname" : "", "cmauid" : "595", "prname" : "Ontario"},
    {"csdname" : "Neepawa", "csduid" : "4615020", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Neguac", "csduid" : "1309038", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Neguaguon Lake 25D", "csduid" : "3559060", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Neilburg", "csduid" : "4713094", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Nekalliston 2", "csduid" : "5933886", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nekaneet Cree Nation", "csduid" : "4704802", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Nekliptum 1", "csduid" : "5933896", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nelson", "csduid" : "1309014", "cmaname" : "", "cmauid" : "329", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Nelson", "csduid" : "5903015", "cmaname" : "", "cmauid" : "907", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nelson House 170", "csduid" : "4622059", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Nemaska", "csduid" : "2499040", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Nemaska", "csduid" : "2499808", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Nenagwas 12", "csduid" : "5924843", "cmaname" : "", "cmauid" : "944", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nequatque", "csduid" : "5931844", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nesikep 6", "csduid" : "5931827", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Neskantaga", "csduid" : "3560093", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Neskonlith", "csduid" : "5933898", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nesuch 3", "csduid" : "5931812", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Netherhill", "csduid" : "4713004", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Neudorf", "csduid" : "4705064", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Neuville", "csduid" : "2434007", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Neville", "csduid" : "4703061", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "New Bandon", "csduid" : "1315016", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Newbury", "csduid" : "3539002", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "New Carlisle", "csduid" : "2405040", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Newcastle", "csduid" : "1309031", "cmaname" : "", "cmauid" : "329", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Newcombe No. 260", "csduid" : "4708071", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "New Credit (Part) 40A", "csduid" : "3528035", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "New Credit (Part) 40A", "csduid" : "3529021", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "New Denver", "csduid" : "5903032", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Newell County", "csduid" : "4802031", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "New Glasgow", "csduid" : "1102017", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "New Glasgow", "csduid" : "1212014", "cmaname" : "", "cmauid" : "220", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "New Hazelton", "csduid" : "5949024", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "New London", "csduid" : "1102071", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Newmarket", "csduid" : "3519048", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "New Maryland", "csduid" : "1310001", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "New Maryland", "csduid" : "1310002", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "New Perlican", "csduid" : "1001328", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Newport", "csduid" : "2441037", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "New Post 69A", "csduid" : "3556102", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "New Richmond", "csduid" : "2405070", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "New Ross 20", "csduid" : "1206016", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "New Songhees 1A", "csduid" : "5917812", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "New Tecumseth", "csduid" : "3543007", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "New Westminster", "csduid" : "5915029", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "New-Wes-Valley", "csduid" : "1007060", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Neyaashiinigmiing 27", "csduid" : "3541060", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Niagara Falls", "csduid" : "3526043", "cmaname" : "", "cmauid" : "539", "prname" : "Ontario"},
    {"csdname" : "Niagara-on-the-Lake", "csduid" : "3526047", "cmaname" : "", "cmauid" : "539", "prname" : "Ontario"},
    {"csdname" : "Nickel Palm 4", "csduid" : "5933848", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nickeyeah 25", "csduid" : "5933850", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nicola Lake 1", "csduid" : "5933806", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nicola Mameet 1", "csduid" : "5933807", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nicolet", "csduid" : "2450072", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Nicomen 1", "csduid" : "5933851", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nigadoo", "csduid" : "1315037", "cmaname" : "", "cmauid" : "328", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Nimpkish 2", "csduid" : "5943835", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nipawin", "csduid" : "4714076", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Nipawin No. 487", "csduid" : "4714072", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Nipigon", "csduid" : "3558044", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Nipissing", "csduid" : "3549071", "cmaname" : "", "cmauid" : "575", "prname" : "Ontario"},
    {"csdname" : "Nipissing 10", "csduid" : "3548073", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Nipissing, Unorganized, North Part", "csduid" : "3548094", "cmaname" : "", "cmauid" : "575", "prname" : "Ontario"},
    {"csdname" : "Nipissing, Unorganized, South Part", "csduid" : "3548091", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Nippers Harbour", "csduid" : "1008070", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Nisga'a", "csduid" : "5949035", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Niverville", "csduid" : "4602046", "cmaname" : "", "cmauid" : "602", "prname" : "Manitoba"},
    {"csdname" : "Nkaih 10", "csduid" : "5933870", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nobleford", "csduid" : "4802014", "cmaname" : "", "cmauid" : "810", "prname" : "Alberta"},
    {"csdname" : "Nohomeen 23", "csduid" : "5933852", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nokomis", "csduid" : "4711044", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Nominingue", "csduid" : "2479030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Nooaitch 10", "csduid" : "5933808", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Noonla 6", "csduid" : "5951832", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Norfolk County", "csduid" : "3528052", "cmaname" : "", "cmauid" : "547", "prname" : "Ontario"},
    {"csdname" : "Norfolk-Treherne", "csduid" : "4608032", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Norglenwold", "csduid" : "4808013", "cmaname" : "", "cmauid" : "831", "prname" : "Alberta"},
    {"csdname" : "Normandin", "csduid" : "2492040", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Norman's Cove-Long Cove", "csduid" : "1001285", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Norman Wells", "csduid" : "6102007", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Normétal", "csduid" : "2487115", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Norquay", "csduid" : "4709072", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Norris Arm", "csduid" : "1006003", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Norris Beach", "csduid" : "4811007", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Norris Point", "csduid" : "1009012", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "North Algona Wilberforce", "csduid" : "3547070", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Northampton", "csduid" : "1311008", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "North Battleford", "csduid" : "4716027", "cmaname" : "", "cmauid" : "735", "prname" : "Saskatchewan"},
    {"csdname" : "North Battleford", "csduid" : "4716029", "cmaname" : "", "cmauid" : "735", "prname" : "Saskatchewan"},
    {"csdname" : "North Battleford No. 437", "csduid" : "4716028", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "North Bay", "csduid" : "3548044", "cmaname" : "", "cmauid" : "575", "prname" : "Ontario"},
    {"csdname" : "North Bay 5", "csduid" : "5939803", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Coast A", "csduid" : "5947016", "cmaname" : "", "cmauid" : "955", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Coast C", "csduid" : "5947021", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Coast D", "csduid" : "5947027", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Coast E", "csduid" : "5947032", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Cowichan", "csduid" : "5919008", "cmaname" : "", "cmauid" : "937", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Cypress-Langford", "csduid" : "4607066", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "North Dumfries", "csduid" : "3530004", "cmaname" : "", "cmauid" : "541", "prname" : "Ontario"},
    {"csdname" : "North Dundas", "csduid" : "3501030", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Northeastern Manitoulin and the Islands", "csduid" : "3551017", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Northern Arm", "csduid" : "1006015", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Northern Bruce Peninsula", "csduid" : "3541069", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Northern Lights County", "csduid" : "4817076", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Northern Rockies", "csduid" : "5959007", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Northern Sunrise County", "csduid" : "4817026", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Northesk", "csduid" : "1309028", "cmaname" : "", "cmauid" : "329", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Northfield", "csduid" : "1303018", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "North Frontenac", "csduid" : "3510045", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "North Glengarry", "csduid" : "3501050", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "North Grenville", "csduid" : "3507065", "cmaname" : "", "cmauid" : "505", "prname" : "Ontario"},
    {"csdname" : "North Grove", "csduid" : "4706081", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "North Hatley", "csduid" : "2445050", "cmaname" : "", "cmauid" : "433", "prname" : "Quebec / Québec"},
    {"csdname" : "North Huron", "csduid" : "3540055", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "North Kawartha", "csduid" : "3515037", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "North Lake", "csduid" : "1310008", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "North Middlesex", "csduid" : "3539041", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "North Norfolk", "csduid" : "4608046", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "North Okanagan B", "csduid" : "5937017", "cmaname" : "", "cmauid" : "918", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Okanagan C", "csduid" : "5937021", "cmaname" : "", "cmauid" : "918", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Okanagan D", "csduid" : "5937022", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Okanagan E", "csduid" : "5937023", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Okanagan F", "csduid" : "5937041", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Perth", "csduid" : "3531040", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Northport", "csduid" : "1103050", "cmaname" : "", "cmauid" : "999", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "North Portal", "csduid" : "4701018", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "North Qu'Appelle No. 187", "csduid" : "4706048", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "North River", "csduid" : "1001426", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "North River", "csduid" : "1102072", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "North Rustico", "csduid" : "1102019", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "North Rustico", "csduid" : "1102046", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "North Saanich", "csduid" : "5917005", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Shore", "csduid" : "1102067", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "North Shore", "csduid" : "1102073", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "North Slope", "csduid" : "6001065", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "North Spirit Lake", "csduid" : "3560080", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "North Stormont", "csduid" : "3501042", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "North Tacla Lake", "csduid" : "5951848", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Thompson 1", "csduid" : "5933887", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Vancouver", "csduid" : "5915046", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "North Vancouver", "csduid" : "5915051", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Northwest Angle 33B", "csduid" : "3560060", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "North West River", "csduid" : "1010022", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "North Wiltshire", "csduid" : "1102020", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Norton", "csduid" : "1305016", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Norton", "csduid" : "1305018", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Norton No. 69", "csduid" : "4702052", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Norway House 17", "csduid" : "4622058", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Norwich", "csduid" : "3532002", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Notre-Dame-Auxiliatrice-de-Buckland", "csduid" : "2419010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-de-Bonsecours", "csduid" : "2480015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-de-Ham", "csduid" : "2439015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-de-la-Merci", "csduid" : "2462055", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-de-la-Paix", "csduid" : "2480020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-de-la-Salette", "csduid" : "2482010", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-de-l'Île-Perrot", "csduid" : "2471065", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-de-Lorette", "csduid" : "2492060", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-de-Lourdes", "csduid" : "1313008", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Notre-Dame-de-Lourdes", "csduid" : "2432080", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-de-Lourdes", "csduid" : "2461045", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-de-Montauban", "csduid" : "2435005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-de-Pontmain", "csduid" : "2479010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-des-Anges", "csduid" : "2423015", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-des-Bois", "csduid" : "2430010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-des-Monts", "csduid" : "2415025", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-des-Neiges", "csduid" : "2411045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-des-Pins", "csduid" : "2429120", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-des-Prairies", "csduid" : "2461030", "cmaname" : "", "cmauid" : "456", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-des-Sept-Douleurs", "csduid" : "2412045", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-de-Stanbridge", "csduid" : "2446100", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-du-Bon-Conseil", "csduid" : "2449075", "cmaname" : "", "cmauid" : "447", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-du-Bon-Conseil", "csduid" : "2449080", "cmaname" : "", "cmauid" : "447", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-du-Laus", "csduid" : "2479005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-du-Mont-Carmel", "csduid" : "2437235", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-du-Nord", "csduid" : "2485090", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-du-Portage", "csduid" : "2412080", "cmaname" : "", "cmauid" : "405", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-du-Rosaire", "csduid" : "2418040", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Notre-Dame-du-Sacré-Coeur-d'Issoudun", "csduid" : "2433085", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Nouvelle", "csduid" : "2406020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Noyan", "csduid" : "2456015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Numukamis 1", "csduid" : "5923809", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Nutashkuan", "csduid" : "2498806", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Nuuautin 2", "csduid" : "5933853", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Oak Bay", "csduid" : "5917030", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Oakdale No. 320", "csduid" : "4713024", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Oakland-Wawanesa", "csduid" : "4607046", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Oakview", "csduid" : "4615032", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Oakville", "csduid" : "3524001", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Obadjiwan 15E", "csduid" : "3557092", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Obedjiwan 28", "csduid" : "2490804", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Obushkudayang", "csduid" : "4618061", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Ocean Man 69", "csduid" : "4701809", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ocean Man 69A", "csduid" : "4701810", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ocean Man 69C", "csduid" : "4701812", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ocean Man 69D", "csduid" : "4701818", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ocean Man 69E", "csduid" : "4701813", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ocean Man 69F", "csduid" : "4701814", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ocean Man 69G", "csduid" : "4701815", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ocean Man 69H", "csduid" : "4701817", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ocean Man 69I", "csduid" : "4701816", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ochapowace 71", "csduid" : "4705806", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Ochapowace 71-10", "csduid" : "4705814", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ochapowace 71-18", "csduid" : "4705824", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ochapowace 71-54", "csduid" : "4705818", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ochapowace 71-7", "csduid" : "4705823", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "O'Chiese 203", "csduid" : "4809806", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Oclucje 7", "csduid" : "5924833", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "O'Connor", "csduid" : "3558016", "cmaname" : "", "cmauid" : "595", "prname" : "Ontario"},
    {"csdname" : "Odanak", "csduid" : "2450802", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Odessa", "csduid" : "4706006", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Ogden", "csduid" : "2445020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Ogema", "csduid" : "4702058", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Ohamil 1", "csduid" : "5909807", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Oil Springs", "csduid" : "3538018", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Ojibway Nation of Saugeen (Savant Lake)", "csduid" : "3558080", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Oka", "csduid" : "2472032", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Okanagan (Part) 1", "csduid" : "5937801", "cmaname" : "", "cmauid" : "918", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Okanagan (Part) 1", "csduid" : "5939804", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Okanagan-Similkameen A", "csduid" : "5907022", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Okanagan-Similkameen B", "csduid" : "5907026", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Okanagan-Similkameen C", "csduid" : "5907028", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Okanagan-Similkameen D", "csduid" : "5907047", "cmaname" : "", "cmauid" : "913", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Okanagan-Similkameen E", "csduid" : "5907049", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Okanagan-Similkameen F", "csduid" : "5907051", "cmaname" : "", "cmauid" : "913", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Okanagan-Similkameen G", "csduid" : "5907053", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Okanagan-Similkameen H", "csduid" : "5907055", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Okanagan-Similkameen I", "csduid" : "5907048", "cmaname" : "", "cmauid" : "913", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Okanese 82", "csduid" : "4706815", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Okotoks", "csduid" : "4806012", "cmaname" : "", "cmauid" : "820", "prname" : "Alberta"},
    {"csdname" : "Old Crow", "csduid" : "6001043", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Old Fort 217", "csduid" : "4816849", "cmaname" : "", "cmauid" : "860", "prname" : "Alberta"},
    {"csdname" : "Old Perlican", "csduid" : "1001343", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Old Post No. 43", "csduid" : "4703011", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Olds", "csduid" : "4806034", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "O'Leary", "csduid" : "1103042", "cmaname" : "", "cmauid" : "998", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "O'Leary", "csduid" : "1103065", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Oliver", "csduid" : "5907014", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Oliver Paipoonge", "csduid" : "3558011", "cmaname" : "", "cmauid" : "595", "prname" : "Ontario"},
    {"csdname" : "Omineca 1", "csduid" : "5951819", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "One Arrow 95", "csduid" : "4715844", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "One Arrow 95-1A", "csduid" : "4715862", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "One Arrow 95-1C", "csduid" : "4715857", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "One Arrow 95-1D", "csduid" : "4715861", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "One Hundred Mile House", "csduid" : "5941005", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Oneida 41", "csduid" : "3539021", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Onoway", "csduid" : "4813014", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Opasatika", "csduid" : "3556073", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Opaskwayak Cree Nation 21A", "csduid" : "4621033", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Opaskwayak Cree Nation 21B", "csduid" : "4621034", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Opaskwayak Cree Nation 21C", "csduid" : "4621035", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Opaskwayak Cree Nation 21D", "csduid" : "4621036", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Opaskwayak Cree Nation 21E", "csduid" : "4621043", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Opaskwayak Cree Nation 21I", "csduid" : "4621040", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Opaskwayak Cree Nation 21N", "csduid" : "4621039", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Opaskwayak Cree Nation 27A", "csduid" : "4714846", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "O-Pipon-Na-Piwin Cree Nation 1", "csduid" : "4623040", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Opitsat 1", "csduid" : "5923813", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Opportunity No. 17", "csduid" : "4817031", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Orangeville", "csduid" : "3522014", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Oregon Jack Creek 5", "csduid" : "5933846", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Orford", "csduid" : "2445115", "cmaname" : "", "cmauid" : "433", "prname" : "Quebec / Québec"},
    {"csdname" : "Orillia", "csduid" : "3543052", "cmaname" : "", "cmauid" : "569", "prname" : "Ontario"},
    {"csdname" : "Orkney No. 244", "csduid" : "4709011", "cmaname" : "", "cmauid" : "710", "prname" : "Saskatchewan"},
    {"csdname" : "Ormstown", "csduid" : "2469037", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Oro-Medonte", "csduid" : "3543023", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Oromocto", "csduid" : "1303012", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Oromocto 26", "csduid" : "1303013", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Osage", "csduid" : "4702077", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Oshawa", "csduid" : "3518013", "cmaname" : "", "cmauid" : "532", "prname" : "Ontario"},
    {"csdname" : "Osler", "csduid" : "4711075", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Osnaburgh 63A", "csduid" : "3558085", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Osnaburgh 63B", "csduid" : "3560055", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Osoyoos", "csduid" : "5907005", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Osoyoos 1", "csduid" : "5907802", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Otonabee-South Monaghan", "csduid" : "3515005", "cmaname" : "", "cmauid" : "529", "prname" : "Ontario"},
    {"csdname" : "Ottawa", "csduid" : "3506008", "cmaname" : "", "cmauid" : "505", "prname" : "Ontario"},
    {"csdname" : "Otterburn Park", "csduid" : "2457030", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Otter Lake", "csduid" : "2484055", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Oujé-Bougoumou", "csduid" : "2499818", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Outlook", "csduid" : "4711027", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Owen Sound", "csduid" : "3542059", "cmaname" : "", "cmauid" : "566", "prname" : "Ontario"},
    {"csdname" : "Oxbow", "csduid" : "4701014", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Oxford", "csduid" : "1211012", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Oxford House 24", "csduid" : "4622050", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Oyen", "csduid" : "4804014", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Oyster Bay 12", "csduid" : "5919816", "cmaname" : "", "cmauid" : "936", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Pabineau 11", "csduid" : "1315010", "cmaname" : "", "cmauid" : "328", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Packington", "csduid" : "2413015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Pacquet", "csduid" : "1008078", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Paddockwood", "csduid" : "4715098", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Paddockwood No. 520", "csduid" : "4715099", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Padoue", "csduid" : "2409040", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Paintearth County No. 18", "csduid" : "4807011", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Pakuashipi", "csduid" : "2498802", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Palling 1", "csduid" : "5951820", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Palmarolle", "csduid" : "2487025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Pangman", "csduid" : "4702054", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Pangnirtung", "csduid" : "6204009", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Papineau-Cameron", "csduid" : "3548013", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Papineauville", "csduid" : "2480037", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Papyum 27", "csduid" : "5933855", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Paqtnkek-Niktuek No. 23", "csduid" : "1214010", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Paquetville", "csduid" : "1315019", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Paquetville", "csduid" : "1315020", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Paqulh", "csduid" : "5909852", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Paradise", "csduid" : "1001517", "cmaname" : "", "cmauid" : "001", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Paradise Hill", "csduid" : "4717034", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Paradise Valley", "csduid" : "4810038", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Parisville", "csduid" : "2438055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Parkdale No. 498", "csduid" : "4717047", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Parkers Cove", "csduid" : "1002034", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Parkland Beach", "csduid" : "4808042", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Parkland County", "csduid" : "4811034", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Parkside", "csduid" : "4716044", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Parksville", "csduid" : "5921018", "cmaname" : "", "cmauid" : "939", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Parry Island First Nation", "csduid" : "3549073", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Parry Sound", "csduid" : "3549032", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Parry Sound, Unorganized, Centre Part", "csduid" : "3549096", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Parry Sound, Unorganized, North East Part", "csduid" : "3549095", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Parson's Pond", "csduid" : "1009017", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Pasadena", "csduid" : "1005012", "cmaname" : "", "cmauid" : "015", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Pashilqua 2", "csduid" : "5931828", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Paska Island 3", "csduid" : "5933854", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Paspébiac", "csduid" : "2405032", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Pasqua 79", "csduid" : "4706812", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Passes-Dangereuses", "csduid" : "2492902", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Patuanak", "csduid" : "4718069", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Pauingassi First Nation", "csduid" : "4619079", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Paulatuk", "csduid" : "6101014", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Paul's Basin 2", "csduid" : "5933809", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Pavilion 1", "csduid" : "5931829", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Paynton", "csduid" : "4717014", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Paynton No. 470", "csduid" : "4717013", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Pays Plat 51", "csduid" : "3558063", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Peace No. 135", "csduid" : "4819071", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Peace River", "csduid" : "4819038", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Peace River B", "csduid" : "5955040", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Peace River C", "csduid" : "5955042", "cmaname" : "", "cmauid" : "977", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Peace River D", "csduid" : "5955021", "cmaname" : "", "cmauid" : "975", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Peace River E", "csduid" : "5955023", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Peachland", "csduid" : "5935018", "cmaname" : "", "cmauid" : "915", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Peawanuck", "csduid" : "3560091", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Pebble Baye", "csduid" : "4716043", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Peel", "csduid" : "1311024", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Peepeekisis 81", "csduid" : "4706816", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Peerless Trout 238", "csduid" : "4817834", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Peguis 1B", "csduid" : "4619059", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Peigan Timber Limit \"B\"", "csduid" : "4803805", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Pelee", "csduid" : "3537001", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Pelham", "csduid" : "3526028", "cmaname" : "", "cmauid" : "539", "prname" : "Ontario"},
    {"csdname" : "Pelican Lake 191A", "csduid" : "4716894", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Pelican Lake 191B", "csduid" : "4716880", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Pelican Narrows", "csduid" : "4718055", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Pelican Narrows", "csduid" : "4812013", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Pelican Narrows 184B", "csduid" : "4718820", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Pelican Narrows 206", "csduid" : "4718827", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Pelican Pointe", "csduid" : "4706082", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Pelly", "csduid" : "4709044", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Pelly Crossing", "csduid" : "6001041", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Pemberton", "csduid" : "5931012", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Pembina", "csduid" : "4604034", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Pembroke", "csduid" : "3547064", "cmaname" : "", "cmauid" : "515", "prname" : "Ontario"},
    {"csdname" : "Pemynoos 9", "csduid" : "5933857", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Penelakut Island 7", "csduid" : "5919809", "cmaname" : "", "cmauid" : "936", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Penetanguishene", "csduid" : "3543072", "cmaname" : "", "cmauid" : "571", "prname" : "Ontario"},
    {"csdname" : "Penhold", "csduid" : "4808009", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Pennal 19", "csduid" : "1206014", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Pennant", "csduid" : "4708034", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Pennfield", "csduid" : "1302011", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Pense", "csduid" : "4706023", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Pense No. 160", "csduid" : "4706021", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Penticton", "csduid" : "5907041", "cmaname" : "", "cmauid" : "913", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Penticton 1", "csduid" : "5907803", "cmaname" : "", "cmauid" : "913", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Pentledge 2", "csduid" : "5926802", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Peq-Paq 22", "csduid" : "5933837", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Percé", "csduid" : "2402005", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Perdue", "csduid" : "4712051", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Perdue No. 346", "csduid" : "4712050", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Péribonka", "csduid" : "2492010", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Perry", "csduid" : "3549014", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Perth", "csduid" : "1312004", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Perth", "csduid" : "3509021", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Perth-Andover", "csduid" : "1312006", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Perth East", "csduid" : "3531030", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Perth South", "csduid" : "3531013", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Pessamit", "csduid" : "2496802", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Petawawa", "csduid" : "3547076", "cmaname" : "", "cmauid" : "516", "prname" : "Ontario"},
    {"csdname" : "Peterborough", "csduid" : "3515014", "cmaname" : "", "cmauid" : "529", "prname" : "Ontario"},
    {"csdname" : "Peters 1", "csduid" : "5909843", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Petersville", "csduid" : "1304001", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Peterview", "csduid" : "1006021", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Petitcodiac", "csduid" : "1307029", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Petite-Rivière-Saint-François", "csduid" : "2416005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Petite-Vallée", "csduid" : "2403015", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Petit-Lac-Sainte-Anne", "csduid" : "2414904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Petit-Mécatina", "csduid" : "2498912", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Petit-Rocher", "csduid" : "1315014", "cmaname" : "", "cmauid" : "328", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Petit-Saguenay", "csduid" : "2494205", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Petrolia", "csduid" : "3538019", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Petty Harbour-Maddox Cove", "csduid" : "1001551", "cmaname" : "", "cmauid" : "001", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Pheasant Rump Nakota 68", "csduid" : "4701819", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Piapot 75", "csduid" : "4706809", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Piapot No. 110", "csduid" : "4704050", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Picard", "csduid" : "2414902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Pickering", "csduid" : "3518001", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Pickle Lake", "csduid" : "3560049", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Pic Mobert North", "csduid" : "3558060", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Pic Mobert South", "csduid" : "3558061", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Pic River 50", "csduid" : "3558062", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Pictou", "csduid" : "1212004", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Pictou, Subd. A", "csduid" : "1212001", "cmaname" : "", "cmauid" : "996", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Pictou, Subd. B", "csduid" : "1212006", "cmaname" : "", "cmauid" : "220", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Pictou, Subd. C", "csduid" : "1212011", "cmaname" : "", "cmauid" : "220", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Picture Butte", "csduid" : "4802018", "cmaname" : "", "cmauid" : "810", "prname" : "Alberta"},
    {"csdname" : "Piedmont", "csduid" : "2477030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Pierceland", "csduid" : "4717067", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Pierreville", "csduid" : "2450113", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Pigeon Lake 138A", "csduid" : "4811802", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Piikani 147", "csduid" : "4803801", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Pikangikum 14", "csduid" : "3560077", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Pike River", "csduid" : "2446025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Pikogan", "csduid" : "2488802", "cmaname" : "", "cmauid" : "481", "prname" : "Quebec / Québec"},
    {"csdname" : "Pikwakanagan (Golden Lake 39)", "csduid" : "3547037", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Pilger", "csduid" : "4715045", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Pilley's Island", "csduid" : "1008053", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Pilot Butte", "csduid" : "4706031", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Pinawa", "csduid" : "4601051", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Pincher Creek", "csduid" : "4803014", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Pincher Creek No. 9", "csduid" : "4803011", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Pincourt", "csduid" : "2471070", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Pine Creek 66A", "csduid" : "4619070", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Pinehouse", "csduid" : "4718065", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Piney", "csduid" : "4601039", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Pinto Creek No. 75", "csduid" : "4703048", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Pinware", "csduid" : "1010007", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Piopolis", "csduid" : "2430020", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Pipestone", "csduid" : "4606023", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Pitt Meadows", "csduid" : "5915070", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Pittville No. 169", "csduid" : "4708028", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Placentia", "csduid" : "1001240", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Plaisance", "csduid" : "2480045", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Plaster Rock", "csduid" : "1312009", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Pleasantdale", "csduid" : "4714030", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Pleasantdale No. 398", "csduid" : "4714035", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Pleasant Valley No. 288", "csduid" : "4712001", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Plenty", "csduid" : "4713031", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Plessisville", "csduid" : "2432040", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Plessisville", "csduid" : "2432045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Plummer Additional", "csduid" : "3557019", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Plunkett", "csduid" : "4711094", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Plympton-Wyoming", "csduid" : "3538035", "cmaname" : "", "cmauid" : "562", "prname" : "Ontario"},
    {"csdname" : "Pohénégamook", "csduid" : "2413095", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Point Alison", "csduid" : "4811041", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Point au Gaul", "csduid" : "1002015", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Pointe-à-la-Croix", "csduid" : "2406030", "cmaname" : "", "cmauid" : "330", "prname" : "Quebec / Québec"},
    {"csdname" : "Pointe-aux-Outardes", "csduid" : "2496030", "cmaname" : "", "cmauid" : "406", "prname" : "Quebec / Québec"},
    {"csdname" : "Pointe-Calumet", "csduid" : "2472020", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Pointe-Claire", "csduid" : "2466097", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Pointe-des-Cascades", "csduid" : "2471055", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Point Edward", "csduid" : "3538031", "cmaname" : "", "cmauid" : "562", "prname" : "Ontario"},
    {"csdname" : "Pointe-Fortune", "csduid" : "2471140", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Pointe-Lebel", "csduid" : "2496025", "cmaname" : "", "cmauid" : "406", "prname" : "Quebec / Québec"},
    {"csdname" : "Pointe-Verte", "csduid" : "1315013", "cmaname" : "", "cmauid" : "328", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Point Grondine 3", "csduid" : "3552037", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Point Lance", "csduid" : "1001225", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Point Leamington", "csduid" : "1008049", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Point May", "csduid" : "1002014", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Point of Bay", "csduid" : "1008047", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Poison Creek 17A", "csduid" : "5951844", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ponass Lake No. 367", "csduid" : "4714023", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Pond Inlet", "csduid" : "6204020", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Ponhook Lake 10", "csduid" : "1204009", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Ponoka", "csduid" : "4808039", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Ponoka County", "csduid" : "4808038", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Ponteix", "csduid" : "4703058", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Pontiac", "csduid" : "2482030", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Pont-Rouge", "csduid" : "2434017", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Pool's Cove", "csduid" : "1003005", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Poorman 88", "csduid" : "4710825", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Popkum 1", "csduid" : "5909844", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Poplar Bay", "csduid" : "4811005", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Poplar Hill", "csduid" : "3560067", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Poplar River 16", "csduid" : "4619075", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Poplar Valley No. 12", "csduid" : "4703006", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Porcupine No. 395", "csduid" : "4714006", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Porcupine Plain", "csduid" : "4714007", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Portage-du-Fort", "csduid" : "2484020", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Portage la Prairie", "csduid" : "4609024", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Portage la Prairie", "csduid" : "4609029", "cmaname" : "", "cmauid" : "607", "prname" : "Manitoba"},
    {"csdname" : "Port Alberni", "csduid" : "5923008", "cmaname" : "", "cmauid" : "940", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Port Alice", "csduid" : "5943017", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Port Anson", "csduid" : "1008062", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Port au Choix", "csduid" : "1009022", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Port au Port East", "csduid" : "1004021", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Port au Port West-Aguathuna-Felix Cove", "csduid" : "1004042", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Port Blandford", "csduid" : "1007036", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Port-Cartier", "csduid" : "2497022", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Port Clements", "csduid" : "5947030", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Port Colborne", "csduid" : "3526011", "cmaname" : "", "cmauid" : "539", "prname" : "Ontario"},
    {"csdname" : "Port Coquitlam", "csduid" : "5915039", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Port-Daniel--Gascons", "csduid" : "2402047", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Port Edward", "csduid" : "5947007", "cmaname" : "", "cmauid" : "955", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Port Elgin", "csduid" : "1307002", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Port Hardy", "csduid" : "5943023", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Port Hawkesbury", "csduid" : "1215002", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Port Hope", "csduid" : "3514020", "cmaname" : "", "cmauid" : "528", "prname" : "Ontario"},
    {"csdname" : "Port Hope Simpson", "csduid" : "1010009", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Portier Pass 5", "csduid" : "5919817", "cmaname" : "", "cmauid" : "936", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Port Kirwan", "csduid" : "1001140", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Port McNeill", "csduid" : "5943012", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Port Moody", "csduid" : "5915043", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Portneuf", "csduid" : "2434048", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Portneuf-sur-Mer", "csduid" : "2495040", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Port Rexton", "csduid" : "1007016", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Port Saunders", "csduid" : "1009019", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Portugal Cove South", "csduid" : "1001105", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Portugal Cove-St. Philip's", "csduid" : "1001504", "cmaname" : "", "cmauid" : "001", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Postville", "csduid" : "1011015", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Potton", "csduid" : "2445030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Pouce Coupe", "csduid" : "5955005", "cmaname" : "", "cmauid" : "975", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Pouch Cove", "csduid" : "1001505", "cmaname" : "", "cmauid" : "001", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Poularies", "csduid" : "2487035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Poundmaker 114", "csduid" : "4713835", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Poundmaker 114-18B", "csduid" : "4712870", "cmaname" : "", "cmauid" : "735", "prname" : "Saskatchewan"},
    {"csdname" : "Powassan", "csduid" : "3549060", "cmaname" : "", "cmauid" : "575", "prname" : "Ontario"},
    {"csdname" : "Powell River", "csduid" : "5927008", "cmaname" : "", "cmauid" : "945", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Powerview-Pine Falls", "csduid" : "4601075", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Prairiedale No. 321", "csduid" : "4713019", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Prairie Lakes", "csduid" : "4605071", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Prairie Rose No. 309", "csduid" : "4710052", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Prairie View", "csduid" : "4615056", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Preeceville", "csduid" : "4709064", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Preeceville No. 334", "csduid" : "4709062", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Preissac", "csduid" : "2488090", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Prelate", "csduid" : "4708057", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Prescott", "csduid" : "3507008", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Prévost", "csduid" : "2475040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Price", "csduid" : "2409065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Priest's Valley 6", "csduid" : "5937803", "cmaname" : "", "cmauid" : "918", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Primate", "csduid" : "4713053", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Prince", "csduid" : "3557066", "cmaname" : "", "cmauid" : "590", "prname" : "Ontario"},
    {"csdname" : "Prince Albert", "csduid" : "4715066", "cmaname" : "", "cmauid" : "745", "prname" : "Saskatchewan"},
    {"csdname" : "Prince Albert National Park", "csduid" : "4716053", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Prince Albert No. 461", "csduid" : "4715064", "cmaname" : "", "cmauid" : "745", "prname" : "Saskatchewan"},
    {"csdname" : "Prince Edward County", "csduid" : "3513020", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Prince George", "csduid" : "5953023", "cmaname" : "", "cmauid" : "970", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Prince Rupert", "csduid" : "5947012", "cmaname" : "", "cmauid" : "955", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Princeton", "csduid" : "5907024", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Princeville", "csduid" : "2432033", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Prince William", "csduid" : "1310016", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Progress No. 351", "csduid" : "4713041", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Prophet River 4", "csduid" : "5959810", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Provost", "csduid" : "4807002", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Provost No. 52", "csduid" : "4807001", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Prud'homme", "csduid" : "4715016", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Puckatholetchin 11", "csduid" : "5909808", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Pukatawagan 198", "csduid" : "4623064", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Punnichy", "csduid" : "4710029", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Puntzi Lake 2", "csduid" : "5941838", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Puskiakiwenin 122", "csduid" : "4812804", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Puslinch", "csduid" : "3523001", "cmaname" : "", "cmauid" : "550", "prname" : "Ontario"},
    {"csdname" : "Puvirnituq", "csduid" : "2499120", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Q'alatkú7em", "csduid" : "5909875", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "qathet A", "csduid" : "5927010", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "qathet B", "csduid" : "5927012", "cmaname" : "", "cmauid" : "945", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "qathet C", "csduid" : "5927016", "cmaname" : "", "cmauid" : "945", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "qathet D", "csduid" : "5927018", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "qathet E", "csduid" : "5927020", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Qikiqtaaluk, Unorganized", "csduid" : "6204030", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Qikiqtarjuaq", "csduid" : "6204010", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Quaaout 1", "csduid" : "5939805", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Quaee 7", "csduid" : "5943813", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Qualicum", "csduid" : "5921806", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Qualicum Beach", "csduid" : "5921023", "cmaname" : "", "cmauid" : "939", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Qu'Appelle", "csduid" : "4706036", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Quaqtaq", "csduid" : "2499115", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Quaqtaq", "csduid" : "2499889", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Quatsino Subdivision 18", "csduid" : "5943816", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Québec", "csduid" : "2423027", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Queen Charlotte", "csduid" : "5947026", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Queens", "csduid" : "1204010", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Queensbury", "csduid" : "1310021", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Quesnel", "csduid" : "5941013", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Quesnel 1", "csduid" : "5941809", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Quill Lake", "csduid" : "4710064", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Quinsam 12", "csduid" : "5924812", "cmaname" : "", "cmauid" : "944", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Quinte West", "csduid" : "3512015", "cmaname" : "", "cmauid" : "522", "prname" : "Ontario"},
    {"csdname" : "Quinton", "csduid" : "4710028", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Quispamsis", "csduid" : "1305057", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Racine", "csduid" : "2442032", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Radisson", "csduid" : "4716009", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Radium Hot Springs", "csduid" : "5901040", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Radville", "csduid" : "4702031", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Ragueneau", "csduid" : "2496040", "cmaname" : "", "cmauid" : "406", "prname" : "Quebec / Québec"},
    {"csdname" : "Rainbow Lake", "csduid" : "4817097", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Rainy Lake 17A", "csduid" : "3559068", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Rainy Lake 17B", "csduid" : "3559069", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Rainy Lake 18C", "csduid" : "3559061", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Rainy Lake 26A", "csduid" : "3559064", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Rainy River", "csduid" : "3559042", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Rainy River, Unorganized", "csduid" : "3559090", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Raleigh", "csduid" : "1009035", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Rama", "csduid" : "4709056", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Ramara", "csduid" : "3543019", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Ramea", "csduid" : "1003026", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Ranchland No. 66", "csduid" : "4815045", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Rankin Inlet", "csduid" : "6205017", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Rankin Location 15D", "csduid" : "3557075", "cmaname" : "", "cmauid" : "590", "prname" : "Ontario"},
    {"csdname" : "Rapide-Danseur", "csduid" : "2487010", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Rapides-des-Joachims", "csduid" : "2484100", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Rat Portage 38A", "csduid" : "3560069", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Rawdon", "csduid" : "2462037", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Raymond", "csduid" : "4802008", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Raymore", "csduid" : "4710027", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Reciprocity No. 32", "csduid" : "4701039", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Red Bank 4", "csduid" : "1309023", "cmaname" : "", "cmauid" : "329", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Red Bay", "csduid" : "1010003", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Redberry No. 435", "csduid" : "4716018", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Redburn No. 130", "csduid" : "4706016", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Redcliff", "csduid" : "4801018", "cmaname" : "", "cmauid" : "805", "prname" : "Alberta"},
    {"csdname" : "Red Deer", "csduid" : "4808011", "cmaname" : "", "cmauid" : "830", "prname" : "Alberta"},
    {"csdname" : "Red Deer County", "csduid" : "4808001", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Red Earth 29", "csduid" : "4714840", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Red Harbour", "csduid" : "1002037", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Red Lake", "csduid" : "3560042", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Red Pheasant 108", "csduid" : "4712829", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Red Rock", "csduid" : "3558041", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Red Rock 53", "csduid" : "3558092", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Redstone Flat 1", "csduid" : "5941839", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Red Sucker Lake 1976", "csduid" : "4622056", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Red Sucker Lake 1976A", "csduid" : "4622045", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Redvers", "csduid" : "4701049", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Redwater", "csduid" : "4811065", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Reford No. 379", "csduid" : "4713064", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Refuge Cove 6", "csduid" : "5923824", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Regina", "csduid" : "4706027", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Regina Beach", "csduid" : "4706058", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Region 1, Unorganized", "csduid" : "6101063", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Region 2, Unorganized", "csduid" : "6102063", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Region 3, Unorganized", "csduid" : "6103097", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Region 4, Unorganized", "csduid" : "6104097", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Region 5, Unorganized", "csduid" : "6105097", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Region 6, Unorganized", "csduid" : "6106097", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Reidville", "csduid" : "1005009", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Reliance", "csduid" : "6105026", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Rémigny", "csduid" : "2485105", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Rencontre East", "csduid" : "1003002", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Renews-Cappahayden", "csduid" : "1001131", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Renfrew", "csduid" : "3547048", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Reno No. 51", "csduid" : "4704019", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Renous 12", "csduid" : "1309010", "cmaname" : "", "cmauid" : "999", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Repentigny", "csduid" : "2460013", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Réservoir-Dozois", "csduid" : "2489910", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Resolute", "csduid" : "6204022", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Revelstoke", "csduid" : "5939019", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Rexton", "csduid" : "1308017", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Reynolds", "csduid" : "4601043", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Rhein", "csduid" : "4709009", "cmaname" : "", "cmauid" : "710", "prname" : "Saskatchewan"},
    {"csdname" : "Rhineland", "csduid" : "4603041", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Richard", "csduid" : "4716026", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Richelieu", "csduid" : "2455057", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Richibucto", "csduid" : "1308016", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Richibucto", "csduid" : "1308018", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Richibucto 15", "csduid" : "1308015", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Richmond", "csduid" : "1311001", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Richmond", "csduid" : "2442098", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Richmond", "csduid" : "5915015", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Richmond Hill", "csduid" : "3519038", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Richmond, Subd. A", "csduid" : "1216006", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Richmond, Subd. B", "csduid" : "1216011", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Richmond, Subd. C", "csduid" : "1216001", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Richmound", "csduid" : "4708022", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Rideau Lakes", "csduid" : "3507040", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Ridgedale", "csduid" : "4714058", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Riding Mountain West", "csduid" : "4616020", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Rigaud", "csduid" : "2471133", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Rigolet", "csduid" : "1011010", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Rimbey", "csduid" : "4808044", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Rimouski", "csduid" : "2410043", "cmaname" : "", "cmauid" : "404", "prname" : "Quebec / Québec"},
    {"csdname" : "Ripon", "csduid" : "2480078", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Ristigouche-Partie-Sud-Est", "csduid" : "2406035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Ritchot", "csduid" : "4602075", "cmaname" : "", "cmauid" : "602", "prname" : "Manitoba"},
    {"csdname" : "Riverdale", "csduid" : "4607076", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Riverhead", "csduid" : "1001181", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Riverhurst", "csduid" : "4707072", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "River of Ponds", "csduid" : "1009048", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Riverside-Albert", "csduid" : "1306003", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Riverside No. 168", "csduid" : "4708031", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Riverview", "csduid" : "1306020", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Rivière-à-Claude", "csduid" : "2404020", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-à-Pierre", "csduid" : "2434135", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-au-Tonnerre", "csduid" : "2498055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-aux-Outardes", "csduid" : "2496902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Beaudette", "csduid" : "2471005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Bleue", "csduid" : "2413025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Bonaventure", "csduid" : "2405902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Bonjour", "csduid" : "2408902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-de-la-Savane", "csduid" : "2435906", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-du-Loup", "csduid" : "2412072", "cmaname" : "", "cmauid" : "405", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Éternité", "csduid" : "2494215", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Héva", "csduid" : "2489010", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Koksoak", "csduid" : "2499902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Mistassini", "csduid" : "2492904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Mouchalagane", "csduid" : "2497906", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Nipissis", "csduid" : "2497902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Nouvelle", "csduid" : "2406902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Ojima", "csduid" : "2487904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Ouelle", "csduid" : "2414065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Patapédia-Est", "csduid" : "2407906", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Rouge", "csduid" : "2479037", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Saint-Jean", "csduid" : "2403902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Saint-Jean", "csduid" : "2498050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Vaseuse", "csduid" : "2407904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rivière-Verte", "csduid" : "1313014", "cmaname" : "", "cmauid" : "335", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Rivière-Verte", "csduid" : "1313015", "cmaname" : "", "cmauid" : "335", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Robert's Arm", "csduid" : "1008060", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Roberval", "csduid" : "2491025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Rocanville", "csduid" : "4705038", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Rocanville No. 151", "csduid" : "4705037", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Rochebaucourt", "csduid" : "2488010", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Roche Percée", "csduid" : "4701019", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Rochon Sands", "csduid" : "4807028", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Rockglen", "csduid" : "4703009", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Rockwood", "csduid" : "4614036", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Rocky Bay 1", "csduid" : "3558069", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Rockyford", "csduid" : "4805022", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Rocky Harbour", "csduid" : "1009013", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Rocky Mountain House", "csduid" : "4809015", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Rocky Point 3", "csduid" : "1102030", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Rocky View County", "csduid" : "4806014", "cmaname" : "", "cmauid" : "825", "prname" : "Alberta"},
    {"csdname" : "Roddickton-Bide Arm", "csduid" : "1009007", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Rodgers No. 133", "csduid" : "4707006", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Rogersville", "csduid" : "1309016", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Rogersville", "csduid" : "1309017", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Roland", "csduid" : "4603062", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Rolling River 67", "csduid" : "4615071", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Rolling River 67B", "csduid" : "4615072", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Romaine 2", "csduid" : "2498804", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Roquemaure", "csduid" : "2487015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Rosalind", "csduid" : "4810003", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Roseau Rapids 2A", "csduid" : "4602026", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Roseau River 2", "csduid" : "4602027", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Rose Blanche-Harbour le Cou", "csduid" : "1003045", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Rosedale", "csduid" : "4615078", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Rosedale No. 283", "csduid" : "4711031", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Rosemary", "csduid" : "4802038", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Rosemère", "csduid" : "2473020", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Rosemount No. 378", "csduid" : "4712072", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Rosetown", "csduid" : "4712006", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Rose Valley", "csduid" : "4714024", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Rossburn", "csduid" : "4616005", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Rosser", "csduid" : "4614015", "cmaname" : "", "cmauid" : "602", "prname" : "Manitoba"},
    {"csdname" : "Ross Haven", "csduid" : "4813008", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Rossland", "csduid" : "5905023", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ross River", "csduid" : "6001037", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Rosthern", "csduid" : "4715032", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Rosthern No. 403", "csduid" : "4715031", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Rothesay", "csduid" : "1305008", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Rothesay", "csduid" : "1305045", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Rougemont", "csduid" : "2455037", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Rouleau", "csduid" : "4706017", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Round Hill No. 467", "csduid" : "4716033", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Round Valley No. 410", "csduid" : "4713072", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Routhierville", "csduid" : "2407902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rouyn-Noranda", "csduid" : "2486042", "cmaname" : "", "cmauid" : "485", "prname" : "Quebec / Québec"},
    {"csdname" : "Roxton", "csduid" : "2448015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Roxton Falls", "csduid" : "2448010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Roxton Pond", "csduid" : "2447047", "cmaname" : "", "cmauid" : "450", "prname" : "Quebec / Québec"},
    {"csdname" : "Ruby Creek 2", "csduid" : "5909851", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ruddell", "csduid" : "4716003", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Rudy No. 284", "csduid" : "4711026", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Ruisseau-des-Mineurs", "csduid" : "2407910", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Ruisseau-Ferguson", "csduid" : "2406904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Rush Lake", "csduid" : "4707023", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Rushoon", "csduid" : "1002033", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Russell", "csduid" : "3502048", "cmaname" : "", "cmauid" : "505", "prname" : "Ontario"},
    {"csdname" : "Russell-Binscarth", "csduid" : "4616030", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Rycroft", "csduid" : "4819056", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Ryerson", "csduid" : "3549024", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Ryley", "csduid" : "4810019", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "S1/2 Tsimpsean 2", "csduid" : "5947810", "cmaname" : "", "cmauid" : "955", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Saanich", "csduid" : "5917021", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sabaskong Bay 35D", "csduid" : "3560063", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Sables-Spanish Rivers", "csduid" : "3552023", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Sachigo Lake 1", "csduid" : "3560076", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Sachsa 4", "csduid" : "5923814", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sachs Harbour", "csduid" : "6101041", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Sachteen", "csduid" : "5909883", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sackville", "csduid" : "1307008", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Sackville", "csduid" : "1307009", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Sacré-Coeur", "csduid" : "2495010", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Sacré-Coeur-de-Jésus", "csduid" : "2431130", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saddle Hills County", "csduid" : "4819059", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Saddle Lake 125", "csduid" : "4812806", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Saddle Rock 9", "csduid" : "5909809", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sagamok", "csduid" : "3557071", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Sagard", "csduid" : "2415904", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saguenay", "csduid" : "2494068", "cmaname" : "", "cmauid" : "408", "prname" : "Quebec / Québec"},
    {"csdname" : "Sahhaltkum 4", "csduid" : "5933884", "cmaname" : "", "cmauid" : "925", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Saint-Adalbert", "csduid" : "2417015", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Adelme", "csduid" : "2408030", "cmaname" : "", "cmauid" : "403", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Adelphe", "csduid" : "2435015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Adolphe-d'Howard", "csduid" : "2477065", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Adrien", "csduid" : "2440010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Adrien-d'Irlande", "csduid" : "2431095", "cmaname" : "", "cmauid" : "430", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Agapit", "csduid" : "2433045", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Aimé", "csduid" : "2453015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Aimé-des-Lacs", "csduid" : "2415030", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Aimé-du-Lac-des-Îles", "csduid" : "2479022", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Alban", "csduid" : "2434097", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Albert", "csduid" : "2439085", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Alexandre", "csduid" : "2456055", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Alexandre-de-Kamouraska", "csduid" : "2414035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Alexandre-des-Lacs", "csduid" : "2407065", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Alexis", "csduid" : "2463023", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Alexis-de-Matapédia", "csduid" : "2406050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Alexis-des-Monts", "csduid" : "2451065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Alfred", "csduid" : "2427015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Alphonse", "csduid" : "2405065", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Alphonse-de-Granby", "csduid" : "2447010", "cmaname" : "", "cmauid" : "450", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Alphonse-Rodriguez", "csduid" : "2462025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Amable", "csduid" : "2459015", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Ambroise", "csduid" : "2494255", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Ambroise-de-Kildare", "csduid" : "2461040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Anaclet-de-Lessard", "csduid" : "2410030", "cmaname" : "", "cmauid" : "404", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-André", "csduid" : "1313002", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-André-Avellin", "csduid" : "2480027", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-André-d'Argenteuil", "csduid" : "2476008", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-André-de-Kamouraska", "csduid" : "2414040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-André-de-Restigouche", "csduid" : "2406040", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-André-du-Lac-Saint-Jean", "csduid" : "2491010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint Andrews", "csduid" : "1302024", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint Andrews", "csduid" : "1302026", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Anicet", "csduid" : "2469070", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Anselme", "csduid" : "2419062", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Antoine", "csduid" : "1308007", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Antoine-de-l'Isle-aux-Grues", "csduid" : "2418070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Antoine-de-Tilly", "csduid" : "2433095", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Antoine-sur-Richelieu", "csduid" : "2457075", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Antonin", "csduid" : "2412015", "cmaname" : "", "cmauid" : "405", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Apollinaire", "csduid" : "2433090", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Armand", "csduid" : "2446017", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Arsène", "csduid" : "2412065", "cmaname" : "", "cmauid" : "405", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Athanase", "csduid" : "2413100", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Aubert", "csduid" : "2417055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Augustin", "csduid" : "2492005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Augustin", "csduid" : "2498012", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Augustin-de-Desmaures", "csduid" : "2423072", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Augustin-de-Woburn", "csduid" : "2430005", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Barnabé", "csduid" : "2451025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Barnabé-Sud", "csduid" : "2454105", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Barthélemy", "csduid" : "2452055", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Basile", "csduid" : "1313018", "cmaname" : "", "cmauid" : "335", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Basile", "csduid" : "2434038", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Basile-le-Grand", "csduid" : "2457020", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Benjamin", "csduid" : "2428025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Benoît-du-Lac", "csduid" : "2445080", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Benoît-Labre", "csduid" : "2429100", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Bernard", "csduid" : "2426055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Bernard-de-Lacolle", "csduid" : "2468005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Bernard-de-Michaudville", "csduid" : "2454115", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Blaise-sur-Richelieu", "csduid" : "2456065", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Bonaventure", "csduid" : "2449125", "cmaname" : "", "cmauid" : "447", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Boniface", "csduid" : "2451085", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Bruno", "csduid" : "2493030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Bruno-de-Guigues", "csduid" : "2485045", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Bruno-de-Kamouraska", "csduid" : "2414010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Bruno-de-Montarville", "csduid" : "2458037", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Calixte", "csduid" : "2463055", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Camille", "csduid" : "2440025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Camille-de-Lellis", "csduid" : "2428070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Casimir", "csduid" : "2434078", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Célestin", "csduid" : "2450030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Célestin", "csduid" : "2450035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Césaire", "csduid" : "2455023", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Charles", "csduid" : "1308019", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Charles-Borromée", "csduid" : "2461035", "cmaname" : "", "cmauid" : "456", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Charles-de-Bellechasse", "csduid" : "2419097", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Charles-de-Bourget", "csduid" : "2494260", "cmaname" : "", "cmauid" : "408", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Charles-Garnier", "csduid" : "2409010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Charles-sur-Richelieu", "csduid" : "2457057", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Christophe-d'Arthabaska", "csduid" : "2439060", "cmaname" : "", "cmauid" : "440", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Chrysostome", "csduid" : "2469017", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Claude", "csduid" : "2442100", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Clément", "csduid" : "2411005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Cléophas", "csduid" : "2407090", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Cléophas-de-Brandon", "csduid" : "2452075", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Clet", "csduid" : "2471045", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Colomban", "csduid" : "2475005", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Côme", "csduid" : "2462065", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Côme--Linière", "csduid" : "2429057", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Constant", "csduid" : "2467035", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint Croix", "csduid" : "1302028", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Cuthbert", "csduid" : "2452062", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Cyprien", "csduid" : "2412005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Cyprien", "csduid" : "2428040", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Cyprien-de-Napierville", "csduid" : "2468035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Cyrille-de-Lessard", "csduid" : "2417045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Cyrille-de-Wendover", "csduid" : "2449070", "cmaname" : "", "cmauid" : "447", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Damase", "csduid" : "2407105", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Damase", "csduid" : "2454017", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Damase-de-L'Islet", "csduid" : "2417040", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Damien", "csduid" : "2462075", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Damien-de-Buckland", "csduid" : "2419030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint David", "csduid" : "1302042", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-David", "csduid" : "2453005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-David-de-Falardeau", "csduid" : "2494245", "cmaname" : "", "cmauid" : "408", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Denis-de-Brompton", "csduid" : "2442025", "cmaname" : "", "cmauid" : "433", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Denis-De La Bouteillerie", "csduid" : "2414055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Denis-sur-Richelieu", "csduid" : "2457068", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Didace", "csduid" : "2452090", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Dominique", "csduid" : "2454060", "cmaname" : "", "cmauid" : "452", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Dominique-du-Rosaire", "csduid" : "2488065", "cmaname" : "", "cmauid" : "481", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Donat", "csduid" : "2409030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Donat", "csduid" : "2462060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Adèle", "csduid" : "2477022", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Agathe-de-Lotbinière", "csduid" : "2433017", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Agathe-des-Monts", "csduid" : "2478032", "cmaname" : "", "cmauid" : "467", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Angèle-de-Mérici", "csduid" : "2409035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Angèle-de-Monnoir", "csduid" : "2455030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Angèle-de-Prémont", "csduid" : "2451055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Anne", "csduid" : "1313011", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Sainte-Anne-de-Beaupré", "csduid" : "2421030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Anne-de-Bellevue", "csduid" : "2466117", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Anne-de-la-Pérade", "csduid" : "2437205", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Anne-de-la-Pocatière", "csduid" : "2414090", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Anne-de-la-Rochelle", "csduid" : "2442050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Anne-de-Madawaska", "csduid" : "1313012", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Sainte-Anne-de-Sabrevois", "csduid" : "2456060", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Anne-des-Lacs", "csduid" : "2477035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Anne-des-Monts", "csduid" : "2404037", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Anne-de-Sorel", "csduid" : "2453065", "cmaname" : "", "cmauid" : "454", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Anne-des-Plaines", "csduid" : "2473035", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Anne-du-Lac", "csduid" : "2479115", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Apolline-de-Patton", "csduid" : "2418025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Aurélie", "csduid" : "2428015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Barbe", "csduid" : "2469065", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Béatrix", "csduid" : "2462020", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Brigide-d'Iberville", "csduid" : "2456105", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Brigitte-de-Laval", "csduid" : "2422045", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Brigitte-des-Saults", "csduid" : "2449085", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Catherine", "csduid" : "2467030", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Catherine-de-Hatley", "csduid" : "2445060", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Catherine-de-la-Jacques-Cartier", "csduid" : "2422005", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Cécile-de-Lévrard", "csduid" : "2438060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Cécile-de-Milton", "csduid" : "2447055", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Cécile-de-Whitton", "csduid" : "2430050", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Christine", "csduid" : "2448020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Christine-d'Auvergne", "csduid" : "2434105", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Claire", "csduid" : "2419055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Clotilde", "csduid" : "2468020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Clotilde-de-Beauce", "csduid" : "2431060", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Clotilde-de-Horton", "csduid" : "2439117", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Croix", "csduid" : "2433102", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Edmond-de-Grantham", "csduid" : "2449100", "cmaname" : "", "cmauid" : "447", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Edmond-les-Plaines", "csduid" : "2492050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Édouard", "csduid" : "2468045", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Édouard-de-Fabre", "csduid" : "2485015", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Édouard-de-Lotbinière", "csduid" : "2433080", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Édouard-de-Maskinongé", "csduid" : "2451050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Edwidge-de-Clifton", "csduid" : "2444055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Élisabeth", "csduid" : "2452030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Élizabeth-de-Warwick", "csduid" : "2439090", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Émélie-de-l'Énergie", "csduid" : "2462070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Eulalie", "csduid" : "2450005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Euphémie-sur-Rivière-du-Sud", "csduid" : "2418035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Famille-de-l'Île-d'Orléans", "csduid" : "2420010", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Félicité", "csduid" : "2408023", "cmaname" : "", "cmauid" : "403", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Félicité", "csduid" : "2417025", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Flavie", "csduid" : "2409085", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Florence", "csduid" : "2407010", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Françoise", "csduid" : "2411030", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Françoise", "csduid" : "2438035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Geneviève-de-Batiscan", "csduid" : "2437215", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Geneviève-de-Berthier", "csduid" : "2452040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Germaine-Boulé", "csduid" : "2487030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Gertrude-Manneville", "csduid" : "2488085", "cmaname" : "", "cmauid" : "481", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Hedwidge", "csduid" : "2491030", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Hélène-de-Bagot", "csduid" : "2454095", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Hélène-de-Chester", "csduid" : "2439035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Hélène-de-Kamouraska", "csduid" : "2414025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Hélène-de-Mancebourg", "csduid" : "2487070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Hénédine", "csduid" : "2426040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Irène", "csduid" : "2407040", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Jeanne-d'Arc", "csduid" : "2409020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Jeanne-d'Arc", "csduid" : "2492015", "cmaname" : "", "cmauid" : "411", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Julie", "csduid" : "2459010", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Julienne", "csduid" : "2463060", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Justine", "csduid" : "2428045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Justine-de-Newton", "csduid" : "2471115", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Élie-de-Caxton", "csduid" : "2451075", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Éloi", "csduid" : "2411035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Louise", "csduid" : "2417060", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Elphège", "csduid" : "2450095", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Luce", "csduid" : "2409092", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Lucie-de-Beauregard", "csduid" : "2418020", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Lucie-des-Laurentides", "csduid" : "2478020", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Elzéar", "csduid" : "2405050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Elzéar", "csduid" : "2426022", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Elzéar-de-Témiscouata", "csduid" : "2413085", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Madeleine", "csduid" : "2454025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Madeleine-de-la-Rivière-Madeleine", "csduid" : "2404005", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Marcelline-de-Kildare", "csduid" : "2462030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Marguerite", "csduid" : "2426035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Marguerite-du-Lac-Masson", "csduid" : "2477012", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Marguerite-Marie", "csduid" : "2407005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Marie", "csduid" : "2426030", "cmaname" : "", "cmauid" : "423", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Marie-de-Blandford", "csduid" : "2438015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Marie-Madeleine", "csduid" : "2454030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Marie-Saint-Raphaël", "csduid" : "1315033", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Sainte-Marie-Salomé", "csduid" : "2463005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Marthe", "csduid" : "2471110", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Marthe-sur-le-Lac", "csduid" : "2472015", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Martine", "csduid" : "2470012", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Mélanie", "csduid" : "2461050", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Émile-de-Suffolk", "csduid" : "2480125", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Monique", "csduid" : "2450057", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Monique", "csduid" : "2493075", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Paule", "csduid" : "2408040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Perpétue", "csduid" : "2417030", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Perpétue", "csduid" : "2450050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Pétronille", "csduid" : "2420030", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Éphrem-de-Beauce", "csduid" : "2429112", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Épiphane", "csduid" : "2412030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Praxède", "csduid" : "2431050", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Rita", "csduid" : "2411015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Rose-de-Watford", "csduid" : "2428030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Rose-du-Nord", "csduid" : "2494230", "cmaname" : "", "cmauid" : "408", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Sabine", "csduid" : "2428065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Sabine", "csduid" : "2446105", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Séraphine", "csduid" : "2439105", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Sophie", "csduid" : "2475028", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Sophie-de-Lévrard", "csduid" : "2438040", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Sophie-d'Halifax", "csduid" : "2432023", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Esprit", "csduid" : "2463030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Thècle", "csduid" : "2435050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Thérèse", "csduid" : "2473010", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Thérèse-de-Gaspé", "csduid" : "2402010", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Thérèse-de-la-Gatineau", "csduid" : "2483055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Étienne-de-Beauharnois", "csduid" : "2470030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Étienne-de-Bolton", "csduid" : "2445100", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Étienne-des-Grès", "csduid" : "2451090", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Eugène", "csduid" : "2449105", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Eugène-d'Argentenay", "csduid" : "2492065", "cmaname" : "", "cmauid" : "411", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Eugène-de-Guigues", "csduid" : "2485085", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Eugène-de-Ladrière", "csduid" : "2410075", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Ursule", "csduid" : "2451040", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Eusèbe", "csduid" : "2413030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Eustache", "csduid" : "2472005", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Évariste-de-Forsyth", "csduid" : "2429025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sainte-Victoire-de-Sorel", "csduid" : "2453025", "cmaname" : "", "cmauid" : "454", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Fabien", "csduid" : "2410070", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Fabien-de-Panet", "csduid" : "2418015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Faustin--Lac-Carré", "csduid" : "2478047", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Félicien", "csduid" : "2491042", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Félix-de-Dalquier", "csduid" : "2488060", "cmaname" : "", "cmauid" : "481", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Félix-de-Kingsey", "csduid" : "2449005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Félix-de-Valois", "csduid" : "2462007", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Félix-d'Otis", "csduid" : "2494225", "cmaname" : "", "cmauid" : "408", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Ferdinand", "csduid" : "2432013", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Ferréol-les-Neiges", "csduid" : "2421010", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Flavien", "csduid" : "2433052", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Fortunat", "csduid" : "2431030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-François-d'Assise", "csduid" : "2406055", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-François-de-la-Rivière-du-Sud", "csduid" : "2418060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-François-de-l'Île-d'Orléans", "csduid" : "2420005", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-François-de-Sales", "csduid" : "2491015", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-François-du-Lac", "csduid" : "2450128", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-François-Xavier-de-Brompton", "csduid" : "2442020", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-François-Xavier-de-Viger", "csduid" : "2412025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Frédéric", "csduid" : "2427065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Fulgence", "csduid" : "2494235", "cmaname" : "", "cmauid" : "408", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Gabriel", "csduid" : "2452080", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Gabriel-de-Brandon", "csduid" : "2452085", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Gabriel-de-Rimouski", "csduid" : "2409025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Gabriel-de-Valcartier", "csduid" : "2422025", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Gabriel-Lalemant", "csduid" : "2414075", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Gédéon", "csduid" : "2493035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Gédéon-de-Beauce", "csduid" : "2429013", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint George", "csduid" : "1302016", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Georges", "csduid" : "2429073", "cmaname" : "", "cmauid" : "428", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Georges-de-Clarenceville", "csduid" : "2456010", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Georges-de-Windsor", "csduid" : "2440032", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Gérard-Majella", "csduid" : "2453085", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Germain", "csduid" : "2414045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Germain-de-Grantham", "csduid" : "2449048", "cmaname" : "", "cmauid" : "447", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Gervais", "csduid" : "2419075", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Gilbert", "csduid" : "2434060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Gilles", "csduid" : "2433035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Godefroi", "csduid" : "2405015", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Guillaume", "csduid" : "2449113", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Guillaume-Nord", "csduid" : "2462912", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Guy", "csduid" : "2411020", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Henri", "csduid" : "2419068", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Henri-de-Taillon", "csduid" : "2493070", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Herménégilde", "csduid" : "2444015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Hilaire-de-Dorset", "csduid" : "2429020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Hilarion", "csduid" : "2416050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Hippolyte", "csduid" : "2475045", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Honoré", "csduid" : "2494240", "cmaname" : "", "cmauid" : "408", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Honoré-de-Shenley", "csduid" : "2429038", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Honoré-de-Témiscouata", "csduid" : "2413090", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Hubert-de-Rivière-du-Loup", "csduid" : "2412010", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Hugues", "csduid" : "2454100", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Hyacinthe", "csduid" : "2454048", "cmaname" : "", "cmauid" : "452", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Ignace-de-Loyola", "csduid" : "2452045", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Ignace-de-Stanbridge", "csduid" : "2446095", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Irénée", "csduid" : "2415005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Isidore", "csduid" : "1315021", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Isidore", "csduid" : "1315022", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Isidore", "csduid" : "2426063", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Isidore", "csduid" : "2467040", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Isidore-de-Clifton", "csduid" : "2441012", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jacques", "csduid" : "1313024", "cmaname" : "", "cmauid" : "335", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Jacques", "csduid" : "2463013", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jacques-de-Leeds", "csduid" : "2431140", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jacques-le-Majeur-de-Wolfestown", "csduid" : "2431025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jacques-le-Mineur", "csduid" : "2468040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint James", "csduid" : "1302039", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Janvier-de-Joly", "csduid" : "2433065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jean-Baptiste", "csduid" : "2457033", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jean-de-Brébeuf", "csduid" : "2431100", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jean-de-Cherbourg", "csduid" : "2408010", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jean-de-Dieu", "csduid" : "2411010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jean-de-la-Lande", "csduid" : "2413010", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jean-de-l'Île-d'Orléans", "csduid" : "2420015", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jean-de-Matha", "csduid" : "2462015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jean-Port-Joli", "csduid" : "2417070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jean-sur-Richelieu", "csduid" : "2456083", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jérôme", "csduid" : "2475017", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Joachim", "csduid" : "2421020", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Joachim-de-Shefford", "csduid" : "2447040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint John", "csduid" : "1301006", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Joseph", "csduid" : "1313022", "cmaname" : "", "cmauid" : "335", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Joseph-de-Beauce", "csduid" : "2427043", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Joseph-de-Coleraine", "csduid" : "2431045", "cmaname" : "", "cmauid" : "430", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Joseph-de-Kamouraska", "csduid" : "2414030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Joseph-de-Lepage", "csduid" : "2409070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Joseph-des-Érables", "csduid" : "2427050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Joseph-de-Sorel", "csduid" : "2453050", "cmaname" : "", "cmauid" : "454", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Joseph-du-Lac", "csduid" : "2472025", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jude", "csduid" : "2454110", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Jules", "csduid" : "2427055", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Julien", "csduid" : "2431035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Just-de-Bretenières", "csduid" : "2418005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Juste-du-Lac", "csduid" : "2413040", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Justin", "csduid" : "2451045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Lambert", "csduid" : "2458012", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Lambert", "csduid" : "2487120", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Lambert-de-Lauzon", "csduid" : "2426070", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Laurent-de-l'Île-d'Orléans", "csduid" : "2420020", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Lazare", "csduid" : "2471105", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Lazare-de-Bellechasse", "csduid" : "2419050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Léandre", "csduid" : "2408065", "cmaname" : "", "cmauid" : "403", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Léolin", "csduid" : "1315017", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Léonard", "csduid" : "1313004", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Léonard", "csduid" : "1313006", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Léonard-d'Aston", "csduid" : "2450042", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Léonard-de-Portneuf", "csduid" : "2434115", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Léon-de-Standon", "csduid" : "2419020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Léon-le-Grand", "csduid" : "2407030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Léon-le-Grand", "csduid" : "2451035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Liboire", "csduid" : "2454072", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Liguori", "csduid" : "2463065", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Lin--Laurentides", "csduid" : "2463048", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Louis", "csduid" : "1308021", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Louis", "csduid" : "2454120", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Louis-de-Blandford", "csduid" : "2439170", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Louis-de-Gonzague", "csduid" : "2428035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Louis-de-Gonzague", "csduid" : "2470035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Louis-de-Gonzague-du-Cap-Tourmente", "csduid" : "2421015", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Louis de Kent", "csduid" : "1308022", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Louis-du-Ha! Ha!", "csduid" : "2413080", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Luc-de-Bellechasse", "csduid" : "2428060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Luc-de-Vincennes", "csduid" : "2437225", "cmaname" : "", "cmauid" : "442", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Lucien", "csduid" : "2449030", "cmaname" : "", "cmauid" : "447", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Ludger", "csduid" : "2430072", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Ludger-de-Milot", "csduid" : "2493080", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Magloire", "csduid" : "2428075", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Majorique-de-Grantham", "csduid" : "2449095", "cmaname" : "", "cmauid" : "447", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Malachie", "csduid" : "2419025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Malo", "csduid" : "2444003", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Marc-de-Figuery", "csduid" : "2488040", "cmaname" : "", "cmauid" : "481", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Marc-des-Carrières", "csduid" : "2434065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Marc-du-Lac-Long", "csduid" : "2413020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Marcel", "csduid" : "2417020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Marcel-de-Richelieu", "csduid" : "2454125", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Marcellin", "csduid" : "2410025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Marc-sur-Richelieu", "csduid" : "2457050", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Martin", "csduid" : "2429045", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint Martins", "csduid" : "1301001", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint Mary", "csduid" : "1308006", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint Marys", "csduid" : "1310031", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Mathias-sur-Richelieu", "csduid" : "2455065", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Mathieu", "csduid" : "2467005", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Mathieu-de-Beloeil", "csduid" : "2457045", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Mathieu-de-Rioux", "csduid" : "2411050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Mathieu-d'Harricana", "csduid" : "2488050", "cmaname" : "", "cmauid" : "481", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Mathieu-du-Parc", "csduid" : "2451070", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Maurice", "csduid" : "2437230", "cmaname" : "", "cmauid" : "442", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Maxime-du-Mont-Louis", "csduid" : "2404010", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Médard", "csduid" : "2411025", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Michel", "csduid" : "2468050", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Michel-de-Bellechasse", "csduid" : "2419110", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Michel-des-Saints", "csduid" : "2462085", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Michel-du-Squatec", "csduid" : "2413065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Modeste", "csduid" : "2412020", "cmaname" : "", "cmauid" : "405", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Moïse", "csduid" : "2407095", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Narcisse", "csduid" : "2437240", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Narcisse-de-Beaurivage", "csduid" : "2433030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Narcisse-de-Rimouski", "csduid" : "2410015", "cmaname" : "", "cmauid" : "404", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Nazaire", "csduid" : "2493045", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Nazaire-d'Acton", "csduid" : "2448050", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Nazaire-de-Dorchester", "csduid" : "2419015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Nérée-de-Bellechasse", "csduid" : "2419045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Noël", "csduid" : "2407100", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Norbert", "csduid" : "2452070", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Norbert-d'Arthabaska", "csduid" : "2439043", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Octave-de-Métis", "csduid" : "2409055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Odilon-de-Cranbourne", "csduid" : "2427035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Omer", "csduid" : "2417005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Onésime-d'Ixworth", "csduid" : "2414080", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Ours", "csduid" : "2453032", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Pacôme", "csduid" : "2414070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Pamphile", "csduid" : "2417010", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Pascal", "csduid" : "2414018", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Patrice-de-Beaurivage", "csduid" : "2433025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Patrice-de-Sherrington", "csduid" : "2468025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint Patrick", "csduid" : "1302021", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Paul", "csduid" : "1308008", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Paul", "csduid" : "2461005", "cmaname" : "", "cmauid" : "456", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Paul-d'Abbotsford", "csduid" : "2455015", "cmaname" : "", "cmauid" : "450", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Paul-de-la-Croix", "csduid" : "2412035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Paul-de-l'Île-aux-Noix", "csduid" : "2456035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Paul-de-Montminy", "csduid" : "2418030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Paulin", "csduid" : "2451060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Philémon", "csduid" : "2419005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Philibert", "csduid" : "2429065", "cmaname" : "", "cmauid" : "428", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Philippe", "csduid" : "2467010", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Philippe-de-Néri", "csduid" : "2414060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Pie", "csduid" : "2454008", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Pie-de-Guire", "csduid" : "2449130", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Pierre", "csduid" : "2461020", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Pierre-Baptiste", "csduid" : "2432050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Pierre-de-Broughton", "csduid" : "2431135", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Pierre-de-Lamy", "csduid" : "2413075", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Pierre-de-la-Rivière-du-Sud", "csduid" : "2418055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Pierre-de-l'Île-d'Orléans", "csduid" : "2420025", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Pierre-les-Becquets", "csduid" : "2438065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Placide", "csduid" : "2472043", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Polycarpe", "csduid" : "2471020", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Prime", "csduid" : "2491035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Prosper", "csduid" : "2428020", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Prosper-de-Champlain", "csduid" : "2437250", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Quentin", "csduid" : "1314021", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Quentin", "csduid" : "1314022", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Raphaël", "csduid" : "2419082", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Raymond", "csduid" : "2434128", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Rémi", "csduid" : "2468055", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Rémi-de-Tingwick", "csduid" : "2439020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-René", "csduid" : "2429050", "cmaname" : "", "cmauid" : "428", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-René-de-Matane", "csduid" : "2408035", "cmaname" : "", "cmauid" : "403", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Robert", "csduid" : "2453020", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Robert-Bellarmin", "csduid" : "2430070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Roch-de-l'Achigan", "csduid" : "2463035", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Roch-de-Mékinac", "csduid" : "2435045", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Roch-de-Richelieu", "csduid" : "2453040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Roch-des-Aulnaies", "csduid" : "2417065", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Roch-Ouest", "csduid" : "2463040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Romain", "csduid" : "2430100", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Rosaire", "csduid" : "2439145", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Samuel", "csduid" : "2439130", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saints-Anges", "csduid" : "2426010", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Sauveur", "csduid" : "2477043", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Sébastien", "csduid" : "2430085", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Sébastien", "csduid" : "2456050", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Sévère", "csduid" : "2451030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Séverin", "csduid" : "2427070", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Séverin", "csduid" : "2435020", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Siméon", "csduid" : "2405055", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Siméon", "csduid" : "2415058", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Simon", "csduid" : "2454090", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Simon-de-Rimouski", "csduid" : "2411055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Simon-les-Mines", "csduid" : "2429125", "cmaname" : "", "cmauid" : "428", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Sixte", "csduid" : "2480070", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saints-Martyrs-Canadiens", "csduid" : "2439005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Stanislas", "csduid" : "2437245", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Stanislas", "csduid" : "2492070", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Stanislas-de-Kostka", "csduid" : "2470040", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint Stephen", "csduid" : "1302034", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Saint-Sulpice", "csduid" : "2460020", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Sylvère", "csduid" : "2438005", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Sylvestre", "csduid" : "2433007", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Télesphore", "csduid" : "2471015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Tharcisius", "csduid" : "2407070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Théodore-d'Acton", "csduid" : "2448045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Théophile", "csduid" : "2429005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Thomas", "csduid" : "2461027", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Thomas-Didyme", "csduid" : "2492045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Thuribe", "csduid" : "2434085", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Tite", "csduid" : "2435027", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Tite-des-Caps", "csduid" : "2421005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Ubalde", "csduid" : "2434090", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Ulric", "csduid" : "2408073", "cmaname" : "", "cmauid" : "403", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Urbain", "csduid" : "2416055", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Urbain-Premier", "csduid" : "2470005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Valentin", "csduid" : "2456030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Valère", "csduid" : "2439135", "cmaname" : "", "cmauid" : "440", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Valérien", "csduid" : "2410060", "cmaname" : "", "cmauid" : "404", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Valérien-de-Milton", "csduid" : "2454065", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Vallier", "csduid" : "2419117", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Venant-de-Paquette", "csduid" : "2444005", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Vianney", "csduid" : "2407075", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Victor", "csduid" : "2427008", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Wenceslas", "csduid" : "2450023", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Zacharie", "csduid" : "2428005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Zénon", "csduid" : "2462080", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Zénon-du-Lac-Humqui", "csduid" : "2407035", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Zéphirin-de-Courval", "csduid" : "2450090", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Saint-Zotique", "csduid" : "2471025", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Sakimay 74", "csduid" : "4705805", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Salaberry-de-Valleyfield", "csduid" : "2470052", "cmaname" : "", "cmauid" : "465", "prname" : "Quebec / Québec"},
    {"csdname" : "Salisbury", "csduid" : "1307024", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Salisbury", "csduid" : "1307028", "cmaname" : "", "cmauid" : "305", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Salluit", "csduid" : "2499135", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Salluit", "csduid" : "2499887", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Sally's Cove", "csduid" : "1009037", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Salmo", "csduid" : "5903011", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Salmon Arm", "csduid" : "5939032", "cmaname" : "", "cmauid" : "920", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Salmon Cove", "csduid" : "1001361", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Salmon River 1", "csduid" : "5939806", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Salmon River Meadow 7", "csduid" : "5941862", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Saltcoats", "csduid" : "4705078", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Saltcoats No. 213", "csduid" : "4705077", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Salt Plains 195", "csduid" : "6105004", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Saltspring Island", "csduid" : "5917027", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Salvage", "csduid" : "1007046", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Sambaa Ke", "csduid" : "6104006", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Samiajij Miawpukek", "csduid" : "1003801", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Samson 137", "csduid" : "4808811", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Samson 137A", "csduid" : "4808812", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Sand Point First Nation", "csduid" : "3558074", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Sandringham", "csduid" : "1007043", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Sandy Bay", "csduid" : "4718058", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Sandy Bay 5", "csduid" : "4608069", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Sandy Beach", "csduid" : "4813016", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Sandy Cove", "csduid" : "1007039", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Sandy Harry 4", "csduid" : "5941868", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sandy Lake 88", "csduid" : "3560071", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Sanikiluaq", "csduid" : "6204001", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Sarnia", "csduid" : "3538030", "cmaname" : "", "cmauid" : "562", "prname" : "Ontario"},
    {"csdname" : "Sarnia 45", "csduid" : "3538025", "cmaname" : "", "cmauid" : "562", "prname" : "Ontario"},
    {"csdname" : "Sarnia No. 221", "csduid" : "4706063", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Saskatchewan Beach", "csduid" : "4706072", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Saskatchewan Landing No. 167", "csduid" : "4708038", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Saskatoon", "csduid" : "4711066", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Sasman No. 336", "csduid" : "4710071", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Saug-A-Gaw-Sing 1", "csduid" : "3559053", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Saugeen 29", "csduid" : "3541057", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Saugeen Shores", "csduid" : "3541045", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Sault-au-Cochon", "csduid" : "2421902", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Saulteaux 159", "csduid" : "4717813", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Saulteaux 159A", "csduid" : "4716861", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Sault Ste. Marie", "csduid" : "3557061", "cmaname" : "", "cmauid" : "590", "prname" : "Ontario"},
    {"csdname" : "Sawridge 150G", "csduid" : "4817832", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Sawridge 150H", "csduid" : "4817833", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Sayabec", "csduid" : "2407085", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sayward", "csduid" : "5924039", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sceptre", "csduid" : "4708054", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Schefferville", "csduid" : "2497040", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Schelowat 1", "csduid" : "5909838", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Schkam 2", "csduid" : "5909812", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Schreiber", "csduid" : "3558051", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Scotch Creek 4", "csduid" : "5939807", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Scotchfort 4", "csduid" : "1102057", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Scotstown", "csduid" : "2441080", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Scott", "csduid" : "2426048", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Scott", "csduid" : "4713062", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Scott No. 98", "csduid" : "4702069", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Scowlitz 1", "csduid" : "5909833", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Scugog", "csduid" : "3518020", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Seabird Island", "csduid" : "5909832", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Seah 5", "csduid" : "5933858", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Seaichem 16", "csduid" : "5931806", "cmaname" : "", "cmauid" : "934", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Seal Cove (Fortune Bay)", "csduid" : "1003010", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Seal Cove (White Bay)", "csduid" : "1008091", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Seaspunkut 4", "csduid" : "5951806", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Seba Beach", "csduid" : "4811038", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Sechelt", "csduid" : "5929011", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sechelt (Part)", "csduid" : "5927806", "cmaname" : "", "cmauid" : "945", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sechelt (Part)", "csduid" : "5929803", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sedgewick", "csduid" : "4807044", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Sedley", "csduid" : "4706008", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Seekaskootch 119", "csduid" : "4717801", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Seguin", "csduid" : "3549003", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Seine River 23A", "csduid" : "3559066", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Selkirk", "csduid" : "4613047", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Selwyn", "csduid" : "3515015", "cmaname" : "", "cmauid" : "529", "prname" : "Ontario"},
    {"csdname" : "Semans", "csduid" : "4710022", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Semiahmoo", "csduid" : "5915801", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Senlac", "csduid" : "4713078", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Senlac No. 411", "csduid" : "4713076", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Senneterre", "csduid" : "2489040", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Senneterre", "csduid" : "2489045", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Senneville", "csduid" : "2466127", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Sept-Îles", "csduid" : "2497007", "cmaname" : "", "cmauid" : "412", "prname" : "Quebec / Québec"},
    {"csdname" : "Serpent River 7", "csduid" : "3557072", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Seton Lake 5", "csduid" : "5931824", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Severn", "csduid" : "3543015", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Sexsmith", "csduid" : "4819014", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Seymour Creek 2", "csduid" : "5915811", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Shackan 11", "csduid" : "5933859", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Shamattawa 1", "csduid" : "4623071", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Shamrock", "csduid" : "4707012", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Shamrock No. 134", "csduid" : "4707011", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Shannon", "csduid" : "2422020", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Shaunavon", "csduid" : "4704036", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Shawanaga 17", "csduid" : "3549072", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Shawinigan", "csduid" : "2436033", "cmaname" : "", "cmauid" : "444", "prname" : "Quebec / Québec"},
    {"csdname" : "Shawniken 4B", "csduid" : "5933895", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Shawville", "csduid" : "2484010", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Shediac", "csduid" : "1307016", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Shediac", "csduid" : "1307052", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Sheenboro", "csduid" : "2484095", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Sheet Harbour 36", "csduid" : "1209038", "cmaname" : "", "cmauid" : "205", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Sheffield", "csduid" : "1303014", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Shefford", "csduid" : "2447035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Sheguiandah 24", "csduid" : "3551042", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Sheho", "csduid" : "4709024", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Shelburne", "csduid" : "1201006", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Shelburne", "csduid" : "1201008", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Shelburne", "csduid" : "3522021", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Shellbrook", "csduid" : "4716047", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Shellbrook No. 493", "csduid" : "4716046", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Shell Lake", "csduid" : "4716057", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Sherbrooke", "csduid" : "1103018", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Sherbrooke", "csduid" : "2443027", "cmaname" : "", "cmauid" : "433", "prname" : "Quebec / Québec"},
    {"csdname" : "Sherwood No. 159", "csduid" : "4706026", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Sheshatshiu 3", "csduid" : "1010802", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Shesheep 74A", "csduid" : "4705807", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Sheshegwaning 20", "csduid" : "3551044", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Shields", "csduid" : "4711064", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Shigawake", "csduid" : "2405010", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Shingle Point 4", "csduid" : "5919811", "cmaname" : "", "cmauid" : "936", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Shippagan", "csduid" : "1315029", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Shippagan", "csduid" : "1315031", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Shoal Lake 28A", "csduid" : "4714839", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Shoal Lake 34B2", "csduid" : "3560064", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Shoal Lake 39A", "csduid" : "3560068", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Shoal Lake (Part) 40", "csduid" : "3560082", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Shoal Lake (Part) 40", "csduid" : "4601079", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Shoal River 65B", "csduid" : "4619040", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Shoal River Indian Reserve 65A", "csduid" : "4619072", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Shuniah", "csduid" : "3558028", "cmaname" : "", "cmauid" : "595", "prname" : "Ontario"},
    {"csdname" : "Shuswap", "csduid" : "5901806", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sicamous", "csduid" : "5939045", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sidney", "csduid" : "5917010", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sifton", "csduid" : "4606016", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Sik-e-dakh 2", "csduid" : "5949818", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Siksika 146", "csduid" : "4805802", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Silton", "csduid" : "4706077", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Silver Beach", "csduid" : "4811009", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Silver Sands", "csduid" : "4813010", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Silverton", "csduid" : "5903027", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Silverwood No. 123", "csduid" : "4705011", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Simonds", "csduid" : "1301004", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Simonds", "csduid" : "1311021", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Simpson", "csduid" : "4711041", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Sintaluta", "csduid" : "4706041", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Sioux Lookout", "csduid" : "3560034", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Sioux Narrows-Nestor Falls", "csduid" : "3560008", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Sioux Valley Dakota Nation", "csduid" : "4606040", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Siska Flat", "csduid" : "5933849", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Six Nations (Part) 40", "csduid" : "3528037", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Six Nations (Part) 40", "csduid" : "3529020", "cmaname" : "", "cmauid" : "543", "prname" : "Ontario"},
    {"csdname" : "Skawahlook 1", "csduid" : "5909814", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Skeetchestn", "csduid" : "5933817", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Skidegate 1", "csduid" : "5947804", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Skins Lake 16A", "csduid" : "5951823", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Skins Lake 16B", "csduid" : "5951824", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Skookumchuck 4", "csduid" : "5909845", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Skowkale", "csduid" : "5909884", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Skuppah 2A", "csduid" : "5933824", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Skuppah 4", "csduid" : "5933865", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Skwah 4", "csduid" : "5909824", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Skwali 3", "csduid" : "5909825", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Skway 5", "csduid" : "5909826", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Skwayaynope 26", "csduid" : "5933866", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Skweahm 10", "csduid" : "5909881", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Slate Falls", "csduid" : "3560046", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Slave Lake", "csduid" : "4817029", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Sliammon 1", "csduid" : "5927802", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sliding Hills No. 273", "csduid" : "4709033", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Slocan", "csduid" : "5903019", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Slosh 1", "csduid" : "5931831", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Small Point-Adam's Cove-Blackhead-Broad Cove", "csduid" : "1001352", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Smeaton", "csduid" : "4714093", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Smiley", "csduid" : "4713022", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Smithers", "csduid" : "5951043", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Smiths Falls", "csduid" : "3509004", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Smoky Lake", "csduid" : "4812029", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Smoky Lake County", "csduid" : "4812022", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Smoky River No. 130", "csduid" : "4819041", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Smooth Rock Falls", "csduid" : "3556048", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Snipe Lake No. 259", "csduid" : "4708074", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Snow Lake", "csduid" : "4621071", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Soda Creek 1", "csduid" : "5941810", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sooke", "csduid" : "5917052", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Soowahlie 14", "csduid" : "5909827", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sorel-Tracy", "csduid" : "2453052", "cmaname" : "", "cmauid" : "454", "prname" : "Quebec / Québec"},
    {"csdname" : "Souris", "csduid" : "1101035", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Souris", "csduid" : "1101036", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Souris-Glenwood", "csduid" : "4607052", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Souris Valley No. 7", "csduid" : "4702006", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Souris West", "csduid" : "1101033", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "South Algonquin", "csduid" : "3548001", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Southampton", "csduid" : "1310024", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "South Baptiste", "csduid" : "4813055", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "South Brook", "csduid" : "1008059", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "South Bruce", "csduid" : "3541004", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "South Bruce Peninsula", "csduid" : "3541055", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "South Dundas", "csduid" : "3501020", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Southend 200", "csduid" : "4718822", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Southend No. 200A", "csduid" : "4718826", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Southern Gulf Islands", "csduid" : "5917029", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Southern Harbour", "csduid" : "1001267", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Southesk", "csduid" : "1309026", "cmaname" : "", "cmauid" : "329", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Southey", "csduid" : "4706092", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "South Frontenac", "csduid" : "3510020", "cmaname" : "", "cmauid" : "521", "prname" : "Ontario"},
    {"csdname" : "Southgate", "csduid" : "3542005", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "South Glengarry", "csduid" : "3501005", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "South Huron", "csduid" : "3540005", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "South Indian Lake", "csduid" : "4623037", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "South Lake", "csduid" : "4707051", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "South Qu'Appelle No. 157", "csduid" : "4706034", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "South River", "csduid" : "1001434", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "South River", "csduid" : "3549056", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "South Saanich 1", "csduid" : "5917804", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "South Stormont", "csduid" : "3501011", "cmaname" : "", "cmauid" : "501", "prname" : "Ontario"},
    {"csdname" : "South View", "csduid" : "4813015", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Southwest Middlesex", "csduid" : "3539005", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "South-West Oxford", "csduid" : "3532012", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Southwold", "csduid" : "3534024", "cmaname" : "", "cmauid" : "555", "prname" : "Ontario"},
    {"csdname" : "Sowchea 3", "csduid" : "5951804", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Spalding", "csduid" : "4714028", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Spalding No. 368", "csduid" : "4714026", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Spallumcheen", "csduid" : "5937024", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Spaniard's Bay", "csduid" : "1001396", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Spanish", "csduid" : "3557039", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Sparwood", "csduid" : "5901006", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Special Area No. 2", "csduid" : "4804004", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Special Area No. 3", "csduid" : "4804012", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Special Area No. 4", "csduid" : "4804020", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Speers", "csduid" : "4716024", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Spences Bridge", "csduid" : "5933881", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Speyum 3", "csduid" : "5909815", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Spintlum Flat 3", "csduid" : "5933868", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Spirit River", "csduid" : "4819058", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Spirit River No. 133", "csduid" : "4819054", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Spiritwood", "csduid" : "4716058", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Spiritwood No. 496", "csduid" : "4716056", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Split Lake 171", "csduid" : "4622063", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Springdale", "csduid" : "1008061", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Springfield", "csduid" : "1305034", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Springfield", "csduid" : "4612047", "cmaname" : "", "cmauid" : "602", "prname" : "Manitoba"},
    {"csdname" : "Spring Lake", "csduid" : "4811046", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Springside", "csduid" : "4709016", "cmaname" : "", "cmauid" : "710", "prname" : "Saskatchewan"},
    {"csdname" : "Springwater", "csduid" : "3543009", "cmaname" : "", "cmauid" : "568", "prname" : "Ontario"},
    {"csdname" : "Spruce Grove", "csduid" : "4811049", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Spuzzum 1", "csduid" : "5909816", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Spy Hill", "csduid" : "4705043", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Spy Hill No. 152", "csduid" : "4705041", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Squaam 2", "csduid" : "5933889", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Squamish", "csduid" : "5931006", "cmaname" : "", "cmauid" : "934", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Squamish-Lillooet A", "csduid" : "5931032", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Squamish-Lillooet B", "csduid" : "5931034", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Squamish-Lillooet C", "csduid" : "5931017", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Squamish-Lillooet D", "csduid" : "5931021", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Squaw-Hay-One 11", "csduid" : "5919802", "cmaname" : "", "cmauid" : "937", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Squawkum Creek 3", "csduid" : "5909882", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Squiaala", "csduid" : "5909885", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Squinas 2", "csduid" : "5941840", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Squirrel Cove 8", "csduid" : "5924818", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "St. Alban's", "csduid" : "1003019", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. Albert", "csduid" : "4811062", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Stanbridge East", "csduid" : "2446045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Stanbridge Station", "csduid" : "2446030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Standard", "csduid" : "4805021", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Standing Buffalo 78", "csduid" : "4706811", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "St. Andrews", "csduid" : "4613043", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "St. Andrews No. 287", "csduid" : "4712004", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Stanley", "csduid" : "1310036", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Stanley", "csduid" : "1310037", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Stanley", "csduid" : "4603047", "cmaname" : "", "cmauid" : "603", "prname" : "Manitoba"},
    {"csdname" : "Stanley 157", "csduid" : "4718803", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Stanley Bridge, Hope River, Bayview, Cavendish and North Rustico", "csduid" : "1102045", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Stanley No. 215", "csduid" : "4705067", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Stanstead", "csduid" : "2445008", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Stanstead", "csduid" : "2445025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Stanstead-Est", "csduid" : "2444050", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "St. Anthony", "csduid" : "1009029", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Star Blanket 83", "csduid" : "4706818", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Star Blanket 83C", "csduid" : "4706828", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Star City", "csduid" : "4714049", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Star City No. 428", "csduid" : "4714047", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Starland County", "csduid" : "4805031", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Stavely", "csduid" : "4803024", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Stawamus 24", "csduid" : "5931807", "cmaname" : "", "cmauid" : "934", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "St. Basile 10", "csduid" : "1313020", "cmaname" : "", "cmauid" : "335", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "St. Benedict", "csduid" : "4715046", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "St. Bernard's-Jacques Fontaine", "csduid" : "1002041", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. Brendan's", "csduid" : "1007048", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. Bride's", "csduid" : "1001228", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. Brieux", "csduid" : "4715049", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "St. Catharines", "csduid" : "3526053", "cmaname" : "", "cmauid" : "539", "prname" : "Ontario"},
    {"csdname" : "St.-Charles", "csduid" : "3552004", "cmaname" : "", "cmauid" : "580", "prname" : "Ontario"},
    {"csdname" : "St. Clair", "csduid" : "3538003", "cmaname" : "", "cmauid" : "562", "prname" : "Ontario"},
    {"csdname" : "St. Clements", "csduid" : "4613056", "cmaname" : "", "cmauid" : "602", "prname" : "Manitoba"},
    {"csdname" : "Steady Brook", "csduid" : "1005011", "cmaname" : "", "cmauid" : "015", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Ste. Anne", "csduid" : "4602057", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Ste. Anne", "csduid" : "4602061", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Steinbach", "csduid" : "4602044", "cmaname" : "", "cmauid" : "605", "prname" : "Manitoba"},
    {"csdname" : "Stellaquo (Stella) 1", "csduid" : "5951807", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Stellarton", "csduid" : "1212009", "cmaname" : "", "cmauid" : "220", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Stenen", "csduid" : "4709069", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Stephenville", "csduid" : "1004019", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Stephenville Crossing", "csduid" : "1004018", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Stequmwhulpa 5", "csduid" : "5933892", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ste. Rose", "csduid" : "4617041", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Stettler", "csduid" : "4807026", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Stettler County No. 6", "csduid" : "4807019", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Stewart", "csduid" : "5949032", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Stewart Crossing", "csduid" : "6001050", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Stewart Valley", "csduid" : "4708039", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Stewiacke", "csduid" : "1210002", "cmaname" : "", "cmauid" : "996", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "St. Felix", "csduid" : "1103053", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "St. François Xavier", "csduid" : "4610052", "cmaname" : "", "cmauid" : "602", "prname" : "Manitoba"},
    {"csdname" : "St. George", "csduid" : "1302018", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "St. George's", "csduid" : "1004013", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. George's Hill", "csduid" : "4718071", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "St. Gregor", "csduid" : "4715003", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Stikine Region", "csduid" : "5957022", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Stirling", "csduid" : "4802009", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Stirling-Rawdon", "csduid" : "3512020", "cmaname" : "", "cmauid" : "522", "prname" : "Ontario"},
    {"csdname" : "St. Jacques-Coomb's Cove", "csduid" : "1003003", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. John's", "csduid" : "1001519", "cmaname" : "", "cmauid" : "001", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. Joseph", "csduid" : "3557008", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "St. Joseph's", "csduid" : "1001192", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. Laurent", "csduid" : "4618040", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "St. Lawrence", "csduid" : "1002008", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. Lewis", "csduid" : "1010010", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. Louis", "csduid" : "1103057", "cmaname" : "", "cmauid" : "999", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "St. Louis", "csduid" : "4715059", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "St. Louis No. 431", "csduid" : "4715057", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "St. Lunaire-Griquet", "csduid" : "1009032", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. Martins", "csduid" : "1301002", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "St. Mary's", "csduid" : "1001178", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. Mary's", "csduid" : "1213001", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "St. Mary's", "csduid" : "5901808", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "St. Marys", "csduid" : "3531016", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "St. Nicholas", "csduid" : "1103022", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Stockholm", "csduid" : "4705056", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Stoke", "csduid" : "2442005", "cmaname" : "", "cmauid" : "433", "prname" : "Quebec / Québec"},
    {"csdname" : "Stone 1", "csduid" : "5941841", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Stoneham-et-Tewkesbury", "csduid" : "2422035", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Stonehenge No. 73", "csduid" : "4703038", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Stone Mills", "csduid" : "3511030", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Stonewall", "csduid" : "4614039", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Stoney 142, 143, 144", "csduid" : "4815802", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Stoney 142B", "csduid" : "4815815", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Stony Creek 1", "csduid" : "5951809", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Stony Plain", "csduid" : "4811048", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Stony Rapids", "csduid" : "4718100", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Stornoway", "csduid" : "2430105", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Storthoaks", "csduid" : "4701044", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Storthoaks No. 31", "csduid" : "4701043", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Stoughton", "csduid" : "4701066", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "St. Paul", "csduid" : "4812018", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "St. Paul County No. 19", "csduid" : "4812014", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "St. Pauls", "csduid" : "1009020", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. Peter No. 369", "csduid" : "4715001", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "St. Peters", "csduid" : "1101037", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "St. Peters Bay", "csduid" : "1101044", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "St. Philips No. 301", "csduid" : "4709042", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "St-Pierre-Jolys", "csduid" : "4602037", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Strasbourg", "csduid" : "4706074", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Stratford", "csduid" : "1102080", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Stratford", "csduid" : "2430110", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Stratford", "csduid" : "3531011", "cmaname" : "", "cmauid" : "553", "prname" : "Ontario"},
    {"csdname" : "Strathcona A", "csduid" : "5924048", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Strathcona B", "csduid" : "5924052", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Strathcona C", "csduid" : "5924054", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Strathcona County", "csduid" : "4811052", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Strathcona D (Oyster Bay - Buttle Lake)", "csduid" : "5924042", "cmaname" : "", "cmauid" : "944", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Strathmore", "csduid" : "4805018", "cmaname" : "", "cmauid" : "826", "prname" : "Alberta"},
    {"csdname" : "Strathroy-Caradoc", "csduid" : "3539015", "cmaname" : "", "cmauid" : "555", "prname" : "Ontario"},
    {"csdname" : "Strong", "csduid" : "3549046", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Strongfield", "csduid" : "4711022", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Stryen 9", "csduid" : "5933873", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "St. Shott's", "csduid" : "1001120", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. Stephen", "csduid" : "1302037", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "St. Theresa Point", "csduid" : "4622801", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "St. Thomas", "csduid" : "3534021", "cmaname" : "", "cmauid" : "555", "prname" : "Ontario"},
    {"csdname" : "Stuartburn", "csduid" : "4601035", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Studholm", "csduid" : "1305031", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Stukely-Sud", "csduid" : "2445105", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Stullawheets 8", "csduid" : "5909841", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sturgeon County", "csduid" : "4811059", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Sturgeon Falls 23", "csduid" : "3559089", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Sturgeon Lake 101", "csduid" : "4716856", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Sturgeon Lake 154", "csduid" : "4818816", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Sturgeon Lake 154A", "csduid" : "4818818", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Sturgeon Weir 184F", "csduid" : "4718821", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Sturgeon Weir 205", "csduid" : "4718815", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Sturgis", "csduid" : "4709066", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "St. Vincent's-St. Stephen's-Peter's River", "csduid" : "1001169", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "St. Walburg", "csduid" : "4717036", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Success", "csduid" : "4708036", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Sucker Creek 150A", "csduid" : "4817829", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Sucker Creek 23", "csduid" : "3551041", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Sucker River 156C (Nemebien River 156C)", "csduid" : "4718811", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Sudbury, Unorganized, North Part", "csduid" : "3552093", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Summer Beaver", "csduid" : "3560086", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Summerford", "csduid" : "1008033", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Summerland", "csduid" : "5907035", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Summerside", "csduid" : "1103025", "cmaname" : "", "cmauid" : "110", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Sunbreaker Cove", "csduid" : "4808027", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Sunchild 202", "csduid" : "4809809", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Sundance Beach", "csduid" : "4811020", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Sundayman's Meadow 3", "csduid" : "5941864", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sundre", "csduid" : "4806036", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Sundridge", "csduid" : "3549048", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Sunnyside", "csduid" : "1001277", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Sun Peaks Mountain", "csduid" : "5933045", "cmaname" : "", "cmauid" : "925", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sunrise Beach", "csduid" : "4813017", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Sunset Beach", "csduid" : "4813047", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Sunset Cove", "csduid" : "4706076", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Sunset Point", "csduid" : "4813011", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Sunshine Coast A", "csduid" : "5929018", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sunshine Coast B", "csduid" : "5929022", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sunshine Coast D", "csduid" : "5929024", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sunshine Coast E", "csduid" : "5929026", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sunshine Coast F", "csduid" : "5929028", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sun Valley", "csduid" : "4707045", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Surprise Valley No. 9", "csduid" : "4702014", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Surrey", "csduid" : "5915004", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sussex", "csduid" : "1305021", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Sussex", "csduid" : "1305022", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Sussex Corner", "csduid" : "1305023", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Sutton", "csduid" : "2446058", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Sutton No. 103", "csduid" : "4703074", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Swan Hills", "csduid" : "4817024", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Swan Lake 3", "csduid" : "5941872", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Swan Lake 65C", "csduid" : "4619071", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Swan Lake 7", "csduid" : "4604068", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Swan Lake 7A", "csduid" : "4607040", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Swan River", "csduid" : "4620048", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Swan River 150E", "csduid" : "4817831", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Swan Valley West", "csduid" : "4620042", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Sweet Grass 113", "csduid" : "4712833", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Sweet Grass 113-M16", "csduid" : "4712837", "cmaname" : "", "cmauid" : "735", "prname" : "Saskatchewan"},
    {"csdname" : "Swift Current", "csduid" : "4708004", "cmaname" : "", "cmauid" : "720", "prname" : "Saskatchewan"},
    {"csdname" : "Swift Current No. 137", "csduid" : "4708001", "cmaname" : "", "cmauid" : "720", "prname" : "Saskatchewan"},
    {"csdname" : "Swift River", "csduid" : "6001046", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Switsemalph", "csduid" : "5939811", "cmaname" : "", "cmauid" : "920", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Switsemalph 3", "csduid" : "5939808", "cmaname" : "", "cmauid" : "920", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Sylvan Lake", "csduid" : "4808012", "cmaname" : "", "cmauid" : "831", "prname" : "Alberta"},
    {"csdname" : "Taber", "csduid" : "4802021", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Taber", "csduid" : "4802022", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Tabusintac 9", "csduid" : "1309047", "cmaname" : "", "cmauid" : "999", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Taché", "csduid" : "4602069", "cmaname" : "", "cmauid" : "602", "prname" : "Manitoba"},
    {"csdname" : "Tache 1", "csduid" : "5951810", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tacla Lake (Ferry Landing) 9", "csduid" : "5951811", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tadinlay 15", "csduid" : "5951845", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tadoussac", "csduid" : "2495005", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Tagish", "csduid" : "6001036", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Tahltan 1", "csduid" : "5949846", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tahsis", "csduid" : "5924030", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tall Cree 173", "csduid" : "4817838", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Tall Cree 173A", "csduid" : "4817840", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Taloyoak", "csduid" : "6208087", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Tanakut 4", "csduid" : "5941831", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tantallon", "csduid" : "4705042", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Tarbutt and Tarbutt Additional", "csduid" : "3557014", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Taschereau", "csduid" : "2487042", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Tasiujaq", "csduid" : "2499100", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Tasiujaq", "csduid" : "2499892", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Tatla't East 2", "csduid" : "5951833", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tatla West 11", "csduid" : "5951825", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tay", "csduid" : "3543071", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Taylor", "csduid" : "5955030", "cmaname" : "", "cmauid" : "977", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tay Valley", "csduid" : "3509015", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Tecumseh", "csduid" : "3537048", "cmaname" : "", "cmauid" : "559", "prname" : "Ontario"},
    {"csdname" : "Tecumseh No. 65", "csduid" : "4701063", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Tehkummah", "csduid" : "3551001", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Telegraph Creek", "csduid" : "5949847", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Telkwa", "csduid" : "5951038", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Temagami", "csduid" : "3548069", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Témiscaming", "csduid" : "2485005", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Témiscouata-sur-le-Lac", "csduid" : "2413073", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Temiskaming Shores", "csduid" : "3554020", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Terrace", "csduid" : "5949011", "cmaname" : "", "cmauid" : "965", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Terrace Bay", "csduid" : "3558054", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Terra Nova", "csduid" : "1007040", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Terrasse-Vaudreuil", "csduid" : "2471075", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Terrebonne", "csduid" : "2464008", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Terrell No. 101", "csduid" : "4703096", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Terrenceville", "csduid" : "1002036", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Teslin", "csduid" : "6001006", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Teslin", "csduid" : "6001057", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Teslin Post 13", "csduid" : "6001007", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Tessier", "csduid" : "4712031", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Teulon", "csduid" : "4614042", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Thabacha Náre 196A", "csduid" : "4816861", "cmaname" : "", "cmauid" : "860", "prname" : "Alberta"},
    {"csdname" : "Thames Centre", "csduid" : "3539027", "cmaname" : "", "cmauid" : "555", "prname" : "Ontario"},
    {"csdname" : "The Archipelago", "csduid" : "3549005", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Thebathi 196", "csduid" : "4816860", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "The Blue Mountains", "csduid" : "3542045", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "The Dalles 38C", "csduid" : "3560089", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "The Gap No. 39", "csduid" : "4702026", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Theik 2", "csduid" : "5919818", "cmaname" : "", "cmauid" : "937", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "The Key 65", "csduid" : "4709821", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "The Narrows 49", "csduid" : "4619065", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "The Nation / La Nation", "csduid" : "3502025", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "The North Shore", "csduid" : "3557040", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Theodore", "csduid" : "4709028", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "The Pas", "csduid" : "4621045", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Thessalon", "csduid" : "3557028", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Thessalon 12", "csduid" : "3557026", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Thetford Mines", "csduid" : "2431084", "cmaname" : "", "cmauid" : "430", "prname" : "Quebec / Québec"},
    {"csdname" : "Thode", "csduid" : "4711060", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Thomas Point 5", "csduid" : "5943817", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Thomas Point 5A", "csduid" : "5943826", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Thomas Squinas Ranch 2A", "csduid" : "5941845", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Thompson", "csduid" : "4603058", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Thompson", "csduid" : "4622026", "cmaname" : "", "cmauid" : "640", "prname" : "Manitoba"},
    {"csdname" : "Thompson-Nicola A (Wells Gray Country)", "csduid" : "5933068", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Thompson-Nicola B (Thompson Headwaters)", "csduid" : "5933070", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Thompson-Nicola E (Bonaparte Plateau)", "csduid" : "5933032", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Thompson-Nicola I (Blue Sky Country)", "csduid" : "5933037", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Thompson-Nicola J (Copper Desert Country)", "csduid" : "5933039", "cmaname" : "", "cmauid" : "925", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Thompson-Nicola L (Grasslands)", "csduid" : "5933060", "cmaname" : "", "cmauid" : "996", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Thompson-Nicola M (Beautiful Nicola Valley - North)", "csduid" : "5933008", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Thompson-Nicola N (Beautiful Nicola Valley - South)", "csduid" : "5933012", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Thompson-Nicola O (Lower North Thompson)", "csduid" : "5933072", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Thompson-Nicola P (Rivers and the Peaks)", "csduid" : "5933044", "cmaname" : "", "cmauid" : "925", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Thorhild County", "csduid" : "4813036", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Thorne", "csduid" : "2484045", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Thornloe", "csduid" : "3554038", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Thorold", "csduid" : "3526037", "cmaname" : "", "cmauid" : "539", "prname" : "Ontario"},
    {"csdname" : "Thorsby", "csduid" : "4811021", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Three Hills", "csduid" : "4805048", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Three Lakes No. 400", "csduid" : "4715044", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Three Rivers", "csduid" : "1101045", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Thunder Bay", "csduid" : "3558004", "cmaname" : "", "cmauid" : "595", "prname" : "Ontario"},
    {"csdname" : "Thunder Bay, Unorganized", "csduid" : "3558090", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Thunderchild First Nation 115B", "csduid" : "4717803", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Thunderchild First Nation 115C", "csduid" : "4717804", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Thurso", "csduid" : "2480050", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Tide Head", "csduid" : "1314015", "cmaname" : "", "cmauid" : "330", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Tignish", "csduid" : "1103059", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Tignish", "csduid" : "1103061", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Tignish Shore", "csduid" : "1103060", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Tillsonburg", "csduid" : "3532004", "cmaname" : "", "cmauid" : "546", "prname" : "Ontario"},
    {"csdname" : "Tilt Cove", "csduid" : "1008073", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Timber Bay", "csduid" : "4718012", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Timiskaming", "csduid" : "2485806", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Timiskaming, Unorganized, East Part", "csduid" : "3554091", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Timiskaming, Unorganized, West Part", "csduid" : "3554094", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Timmins", "csduid" : "3556027", "cmaname" : "", "cmauid" : "586", "prname" : "Ontario"},
    {"csdname" : "Tingwick", "csduid" : "2439025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Tin Wis 11", "csduid" : "5923825", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tiny", "csduid" : "3543068", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Tipella 7", "csduid" : "5909848", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tisdale", "csduid" : "4714044", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Tisdale No. 427", "csduid" : "4714043", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Tlaa Gaa Aawtlaas 28", "csduid" : "5947823", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tobacco Plains 2", "csduid" : "5901801", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tobin Lake", "csduid" : "4714068", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Tobique 20", "csduid" : "1312007", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Tofield", "csduid" : "4810018", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Tofino", "csduid" : "5923025", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Togo", "csduid" : "4709038", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Tompkins", "csduid" : "4708012", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Toosey 1", "csduid" : "5941850", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Torbay", "csduid" : "1001509", "cmaname" : "", "cmauid" : "001", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Torch River No. 488", "csduid" : "4714077", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Tork 7", "csduid" : "5924820", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Toronto", "csduid" : "3520005", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Torquay", "csduid" : "4702002", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Touchwood No. 248", "csduid" : "4710014", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Tourville", "csduid" : "2417035", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Towdystan Lake 3", "csduid" : "5941851", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Towinock 2", "csduid" : "5931832", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tracadie", "csduid" : "1315002", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Tracy", "csduid" : "1303005", "cmaname" : "", "cmauid" : "320", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Trail", "csduid" : "5905014", "cmaname" : "", "cmauid" : "910", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tramping Lake", "csduid" : "4713039", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Tramping Lake No. 380", "csduid" : "4713059", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Traytown", "csduid" : "1007037", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Treaty Four Reserve Grounds 77", "csduid" : "4706829", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Trécesson", "csduid" : "2488075", "cmaname" : "", "cmauid" : "481", "prname" : "Quebec / Québec"},
    {"csdname" : "Trent Hills", "csduid" : "3514045", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Trent Lakes", "csduid" : "3515044", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Trenton", "csduid" : "1212016", "cmaname" : "", "cmauid" : "220", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Trepassey", "csduid" : "1001113", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Très-Saint-Rédempteur", "csduid" : "2471125", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Très-Saint-Sacrement", "csduid" : "2469030", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Tribune", "csduid" : "4702008", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Tring-Jonction", "csduid" : "2427060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Trinity Bay North", "csduid" : "1007022", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Trinity (Trinity Bay)", "csduid" : "1007015", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Triton", "csduid" : "1008054", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Trochu", "csduid" : "4805049", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Trois-Pistoles", "csduid" : "2411040", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Trois-Rives", "csduid" : "2435055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Trois-Rivières", "csduid" : "2437067", "cmaname" : "", "cmauid" : "442", "prname" : "Quebec / Québec"},
    {"csdname" : "Trout Lake Alec 16", "csduid" : "5941852", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Trout River", "csduid" : "1009010", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Truro", "csduid" : "1210006", "cmaname" : "", "cmauid" : "215", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Tsahaheh 1", "csduid" : "5923816", "cmaname" : "", "cmauid" : "940", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tsaukan 12", "csduid" : "5933874", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tsawwassen", "csduid" : "5915802", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tsa Xana 18", "csduid" : "5924835", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tseatah 2", "csduid" : "5909834", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tsiigehtchic", "csduid" : "6101010", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Tsinstikeptum 10", "csduid" : "5935803", "cmaname" : "", "cmauid" : "915", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tsinstikeptum 9", "csduid" : "5935802", "cmaname" : "", "cmauid" : "915", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "T'Sou-ke", "csduid" : "5917819", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tsulquate 4", "csduid" : "5943806", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tsussie 6", "csduid" : "5919803", "cmaname" : "", "cmauid" : "937", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tsuu T'ina Nation 145", "csduid" : "4806804", "cmaname" : "", "cmauid" : "825", "prname" : "Alberta"},
    {"csdname" : "Tuckkwiowhum 1", "csduid" : "5909817", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tudor and Cashel", "csduid" : "3512048", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Tugaske", "csduid" : "4707077", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Tuktoyaktuk", "csduid" : "6101036", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Tulita", "csduid" : "6102005", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Tullymet No. 216", "csduid" : "4706099", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Tumbler Ridge", "csduid" : "5955003", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Turner Valley", "csduid" : "4806009", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Turnor Lake", "csduid" : "4718075", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Turnor Lake 193B", "csduid" : "4718819", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Turtleford", "csduid" : "4717041", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Turtle River No. 469", "csduid" : "4717008", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Tuxford", "csduid" : "4707043", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Tweed", "csduid" : "3512030", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Twillingate", "csduid" : "1008035", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Two Borders", "csduid" : "4605056", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Two Hills", "csduid" : "4810052", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Two Hills County No. 21", "csduid" : "4810048", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Twoyqhalsht 16", "csduid" : "5933893", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tyendinaga", "csduid" : "3512001", "cmaname" : "", "cmauid" : "522", "prname" : "Ontario"},
    {"csdname" : "Tyendinaga Mohawk Territory", "csduid" : "3512004", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Tyne Valley", "csduid" : "1103021", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Tyne Valley", "csduid" : "1103033", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Tzart-Lam 5", "csduid" : "5919821", "cmaname" : "", "cmauid" : "937", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Tzeachten 13", "csduid" : "5909830", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Uashat 27", "csduid" : "2497802", "cmaname" : "", "cmauid" : "412", "prname" : "Quebec / Québec"},
    {"csdname" : "Ucluelet", "csduid" : "5923019", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ulkatcho 13", "csduid" : "5941854", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ulkatcho 14A", "csduid" : "5941880", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ulukhaktok", "csduid" : "6101095", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Ulverton", "csduid" : "2442078", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Umingmaktok", "csduid" : "6208068", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Umiujaq", "csduid" : "2499080", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Umiujaq", "csduid" : "2499878", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Uncha Lake 13A", "csduid" : "5951826", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Union Bay 4", "csduid" : "5917802", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Union Road", "csduid" : "1102050", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Unipouheos 121", "csduid" : "4812802", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Unity", "csduid" : "4713074", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Unnamed 10", "csduid" : "5957802", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Upham", "csduid" : "1305004", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Upper Hat Creek 1", "csduid" : "5933875", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Upper Hay River 212", "csduid" : "4817849", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Upper Island Cove", "csduid" : "1001385", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Upper Liard", "csduid" : "6001032", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Upper Miramichi", "csduid" : "1309027", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Upper Nepa 6", "csduid" : "5933876", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Upper Sumas 6", "csduid" : "5909877", "cmaname" : "", "cmauid" : "932", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Upton", "csduid" : "2448038", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Usborne No. 310", "csduid" : "4711046", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Utikoomak Lake 155", "csduid" : "4817824", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Utikoomak Lake 155A", "csduid" : "4817827", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Uxbridge", "csduid" : "3518029", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Val-Alain", "csduid" : "2433070", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Val-Brillant", "csduid" : "2407080", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Valcourt", "csduid" : "2442055", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Valcourt", "csduid" : "2442060", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Val-David", "csduid" : "2478010", "cmaname" : "", "cmauid" : "467", "prname" : "Quebec / Québec"},
    {"csdname" : "Val-des-Bois", "csduid" : "2480140", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Val-des-Lacs", "csduid" : "2478100", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Val-des-Monts", "csduid" : "2482015", "cmaname" : "", "cmauid" : "505", "prname" : "Quebec / Québec"},
    {"csdname" : "Val-des-Sources", "csduid" : "2440043", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Val-d'Or", "csduid" : "2489008", "cmaname" : "", "cmauid" : "480", "prname" : "Quebec / Québec"},
    {"csdname" : "Valemount", "csduid" : "5953007", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Val-Joli", "csduid" : "2442095", "cmaname" : "", "cmauid" : "433", "prname" : "Quebec / Québec"},
    {"csdname" : "Vallée-Jonction", "csduid" : "2426015", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Valley River 63A", "csduid" : "4616046", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Valleyview", "csduid" : "4818018", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Val Marie", "csduid" : "4704002", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Val Marie No. 17", "csduid" : "4704003", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Val-Morin", "csduid" : "2478005", "cmaname" : "", "cmauid" : "467", "prname" : "Quebec / Québec"},
    {"csdname" : "Valparaiso", "csduid" : "4714048", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Val Quentin", "csduid" : "4813005", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Val-Racine", "csduid" : "2430015", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Val Rita-Harty", "csduid" : "3556070", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Val-Saint-Gilles", "csduid" : "2487105", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Vancouver", "csduid" : "5915022", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Vanderhoof", "csduid" : "5951007", "cmaname" : "", "cmauid" : "998", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Vanguard", "csduid" : "4703062", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Vanscoy", "csduid" : "4712058", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Vanscoy No. 345", "csduid" : "4712054", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Varennes", "csduid" : "2459020", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Vaudreuil-Dorion", "csduid" : "2471083", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Vaudreuil-sur-le-Lac", "csduid" : "2471090", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Vaughan", "csduid" : "3519028", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "Vauxhall", "csduid" : "4802029", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Vegreville", "csduid" : "4810028", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Venise-en-Québec", "csduid" : "2456005", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Verchères", "csduid" : "2459025", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "Vermilion", "csduid" : "4810042", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Vermilion River County", "csduid" : "4810036", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Vernon", "csduid" : "5937014", "cmaname" : "", "cmauid" : "918", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Vernon River", "csduid" : "1102016", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Veteran", "csduid" : "4804021", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Vibank", "csduid" : "4706007", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Victoria", "csduid" : "1001365", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Victoria", "csduid" : "1102032", "cmaname" : "", "cmauid" : "999", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Victoria", "csduid" : "1103068", "cmaname" : "", "cmauid" : "999", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Victoria", "csduid" : "4608042", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Victoria", "csduid" : "5917034", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Victoria Beach", "csduid" : "4601078", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "Victoria, Subd. A", "csduid" : "1218006", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Victoria, Subd. B", "csduid" : "1218001", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Victoriaville", "csduid" : "2439062", "cmaname" : "", "cmauid" : "440", "prname" : "Quebec / Québec"},
    {"csdname" : "Victory No. 226", "csduid" : "4707063", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "View Royal", "csduid" : "5917047", "cmaname" : "", "cmauid" : "935", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Viking", "csduid" : "4810022", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Village Island 1", "csduid" : "5924813", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ville-Marie", "csduid" : "2485025", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Villeroy", "csduid" : "2432085", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Vilna", "csduid" : "4812024", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Virden", "csduid" : "4606034", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Viscount", "csduid" : "4711092", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Viscount No. 341", "csduid" : "4711091", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Vonda", "csduid" : "4715017", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Vulcan", "csduid" : "4805006", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Vulcan County", "csduid" : "4805001", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Wabamun 133A", "csduid" : "4811806", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Wabamun 133B", "csduid" : "4811807", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Wabana", "csduid" : "1001494", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Wabasca 166", "csduid" : "4817818", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Wabasca 166A", "csduid" : "4817819", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Wabasca 166B", "csduid" : "4817821", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Wabasca 166C", "csduid" : "4817822", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Wabasca 166D", "csduid" : "4817823", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Wabaseemoong", "csduid" : "3560061", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Wabauskang 21", "csduid" : "3560084", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Wabigoon Lake 27", "csduid" : "3560057", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Wabush", "csduid" : "1010034", "cmaname" : "", "cmauid" : "998", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Wadena", "csduid" : "4710068", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Wagmatcook 1", "csduid" : "1218003", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Wahnapitei 11", "csduid" : "3553040", "cmaname" : "", "cmauid" : "580", "prname" : "Ontario"},
    {"csdname" : "Wahpaton 94A", "csduid" : "4715848", "cmaname" : "", "cmauid" : "745", "prname" : "Saskatchewan"},
    {"csdname" : "Wahta Mohawk Territory", "csduid" : "3544071", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Wainfleet", "csduid" : "3526014", "cmaname" : "", "cmauid" : "539", "prname" : "Ontario"},
    {"csdname" : "Wainwright", "csduid" : "4807054", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Wainwright No. 61", "csduid" : "4807049", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Waiparous", "csduid" : "4815030", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Waiwakum 14", "csduid" : "5931808", "cmaname" : "", "cmauid" : "934", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Wakaw", "csduid" : "4715042", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Wakaw Lake", "csduid" : "4715043", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Wakefield", "csduid" : "1311014", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Waldeck", "csduid" : "4707022", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Waldheim", "csduid" : "4715028", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Waldron", "csduid" : "4705061", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Wallace Hills 14A", "csduid" : "1209800", "cmaname" : "", "cmauid" : "205", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Wallace No. 243", "csduid" : "4709006", "cmaname" : "", "cmauid" : "710", "prname" : "Saskatchewan"},
    {"csdname" : "Wallace-Woodworth", "csduid" : "4606031", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Walpole Island 46", "csduid" : "3538004", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Walpole No. 92", "csduid" : "4701091", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Waltham", "csduid" : "2484070", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Wapachewunak 192D", "csduid" : "4718814", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Wapaskokimaw 202", "csduid" : "4718806", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Wapekeka 2", "csduid" : "3560088", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Wapella", "csduid" : "4705009", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Wa-Pii Moos-Toosis (White Calf) 83A", "csduid" : "4706820", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Warburg", "csduid" : "4811024", "cmaname" : "", "cmauid" : "835", "prname" : "Alberta"},
    {"csdname" : "Warden", "csduid" : "2447030", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Warfield", "csduid" : "5905018", "cmaname" : "", "cmauid" : "910", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Warman", "csduid" : "4711068", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "Warner", "csduid" : "4802006", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Warner County No. 5", "csduid" : "4802001", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Warren Grove", "csduid" : "1102070", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Warwick", "csduid" : "2439077", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Warwick", "csduid" : "3538043", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Wasaga Beach", "csduid" : "3543064", "cmaname" : "", "cmauid" : "565", "prname" : "Ontario"},
    {"csdname" : "Wasagamack", "csduid" : "4622800", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Waseca", "csduid" : "4717019", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Waskaganish", "csduid" : "2499035", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Waskaganish", "csduid" : "2499806", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Waskatenau", "csduid" : "4812026", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Waswanipi", "csduid" : "2499010", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Waswanipi", "csduid" : "2499802", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Waterborough", "csduid" : "1304018", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Waterford", "csduid" : "1305024", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Waterhen 130", "csduid" : "4717806", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Waterhen 45", "csduid" : "4619069", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Waterloo", "csduid" : "2447025", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Waterloo", "csduid" : "3530016", "cmaname" : "", "cmauid" : "541", "prname" : "Ontario"},
    {"csdname" : "Waterville", "csduid" : "2444080", "cmaname" : "", "cmauid" : "433", "prname" : "Quebec / Québec"},
    {"csdname" : "Watrous", "csduid" : "4711053", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Watson", "csduid" : "4710062", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Watson Lake", "csduid" : "6001003", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Waverley No. 44", "csduid" : "4703022", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Wawa", "csduid" : "3557076", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Wawakapewin", "csduid" : "3560100", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Wawken No. 93", "csduid" : "4701076", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Wawota", "csduid" : "4701078", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Waywayseecappo First Nation", "csduid" : "4616017", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Waywayseecappo Highway 10", "csduid" : "4607072", "cmaname" : "", "cmauid" : "610", "prname" : "Manitoba"},
    {"csdname" : "Weagamow Lake 87", "csduid" : "3560059", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Webb", "csduid" : "4708008", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Webb No. 138", "csduid" : "4708006", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Webequie", "csduid" : "3560079", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Weedon", "csduid" : "2441098", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Weekes", "csduid" : "4714009", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Wee Too Beach", "csduid" : "4706060", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Weirdale", "csduid" : "4715091", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Wekweètì", "csduid" : "6103052", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Weldford", "csduid" : "1308014", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Weldon", "csduid" : "4715072", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Welland", "csduid" : "3526032", "cmaname" : "", "cmauid" : "539", "prname" : "Ontario"},
    {"csdname" : "Wellesley", "csduid" : "3530027", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Wellington", "csduid" : "1103020", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Wellington", "csduid" : "1103029", "cmaname" : "", "cmauid" : "996", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Wellington", "csduid" : "1308004", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Wellington No. 97", "csduid" : "4702073", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Wellington North", "csduid" : "3523050", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Wells", "csduid" : "5941025", "cmaname" : "", "cmauid" : "952", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Wembley", "csduid" : "4819011", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Wemindji", "csduid" : "2499050", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Wemindji", "csduid" : "2499812", "cmaname" : "", "cmauid" : "998", "prname" : "Quebec / Québec"},
    {"csdname" : "Wendake", "csduid" : "2423802", "cmaname" : "", "cmauid" : "421", "prname" : "Quebec / Québec"},
    {"csdname" : "Wentworth", "csduid" : "2476035", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Wentworth-Nord", "csduid" : "2477060", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Wepuskow Sahgaiechan 165D", "csduid" : "4718860", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "West Baptiste", "csduid" : "4813057", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Westbury", "csduid" : "2441065", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "West Cove", "csduid" : "4813006", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "West Elgin", "csduid" : "3534042", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "West End", "csduid" : "4705050", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Westfield", "csduid" : "1305011", "cmaname" : "", "cmauid" : "310", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "West Grey", "csduid" : "3542004", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "West Hants", "csduid" : "1208003", "cmaname" : "", "cmauid" : "996", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "West Interlake", "csduid" : "4618056", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "West Isles", "csduid" : "1302006", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "West Kelowna", "csduid" : "5935029", "cmaname" : "", "cmauid" : "915", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "WestLake-Gladstone", "csduid" : "4608060", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "West Lincoln", "csduid" : "3526021", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Westlock", "csduid" : "4813031", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Westlock County", "csduid" : "4813028", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "West Moberly Lake 168A", "csduid" : "5955802", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Westmorland", "csduid" : "1307001", "cmaname" : "", "cmauid" : "997", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Westmount", "csduid" : "2466032", "cmaname" : "", "cmauid" : "462", "prname" : "Quebec / Québec"},
    {"csdname" : "West Nipissing / Nipissing Ouest", "csduid" : "3548055", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "West Perth", "csduid" : "3531025", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "West Point", "csduid" : "1103066", "cmaname" : "", "cmauid" : "997", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Westport", "csduid" : "1008093", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Westport", "csduid" : "3507033", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "West River", "csduid" : "1102068", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "West St. Modeste", "csduid" : "1010006", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "West St. Paul", "csduid" : "4613037", "cmaname" : "", "cmauid" : "602", "prname" : "Manitoba"},
    {"csdname" : "West Vancouver", "csduid" : "5915055", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Westville", "csduid" : "1212008", "cmaname" : "", "cmauid" : "220", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Wetaskiwin", "csduid" : "4811002", "cmaname" : "", "cmauid" : "865", "prname" : "Alberta"},
    {"csdname" : "Wetaskiwin County No. 10", "csduid" : "4811001", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Weyakwin", "csduid" : "4718015", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Weyburn", "csduid" : "4702047", "cmaname" : "", "cmauid" : "755", "prname" : "Saskatchewan"},
    {"csdname" : "Weyburn No. 67", "csduid" : "4702044", "cmaname" : "", "cmauid" : "755", "prname" : "Saskatchewan"},
    {"csdname" : "Whale Cove", "csduid" : "6205016", "cmaname" : "", "cmauid" : "000", "prname" : "Nunavut"},
    {"csdname" : "Whapmagoostui", "csduid" : "2499070", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Whapmagoostui", "csduid" : "2499816", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Whatì", "csduid" : "6103034", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Wheatland County", "csduid" : "4805012", "cmaname" : "", "cmauid" : "996", "prname" : "Alberta"},
    {"csdname" : "Wheatlands No. 163", "csduid" : "4707032", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Whiska Creek No. 106", "csduid" : "4703059", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Whispering Hills", "csduid" : "4813061", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Whispering Pines 4", "csduid" : "5933877", "cmaname" : "", "cmauid" : "925", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Whistler", "csduid" : "5931020", "cmaname" : "", "cmauid" : "997", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Whitbourne", "csduid" : "1001298", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Whitby", "csduid" : "3518009", "cmaname" : "", "cmauid" : "532", "prname" : "Ontario"},
    {"csdname" : "Whitchurch-Stouffville", "csduid" : "3519044", "cmaname" : "", "cmauid" : "535", "prname" : "Ontario"},
    {"csdname" : "White Bear 70", "csduid" : "4701808", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Whitecap", "csduid" : "4711828", "cmaname" : "", "cmauid" : "725", "prname" : "Saskatchewan"},
    {"csdname" : "White City", "csduid" : "4706030", "cmaname" : "", "cmauid" : "705", "prname" : "Saskatchewan"},
    {"csdname" : "Whitecourt", "csduid" : "4813030", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Whitefish Bay 32A", "csduid" : "3560004", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Whitefish Bay 33A", "csduid" : "3560005", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Whitefish Bay 34A", "csduid" : "3560083", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "White Fish Lake 128", "csduid" : "4812808", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Whitefish Lake 6", "csduid" : "3552051", "cmaname" : "", "cmauid" : "580", "prname" : "Ontario"},
    {"csdname" : "Whitefish River 4", "csduid" : "3551040", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "White Fox", "csduid" : "4714079", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Whitehead", "csduid" : "4607057", "cmaname" : "", "cmauid" : "610", "prname" : "Manitoba"},
    {"csdname" : "Whitehorse", "csduid" : "6001009", "cmaname" : "", "cmauid" : "990", "prname" : "Yukon"},
    {"csdname" : "Whitehorse, Unorganized", "csduid" : "6001060", "cmaname" : "", "cmauid" : "990", "prname" : "Yukon"},
    {"csdname" : "Whitemouth", "csduid" : "4601046", "cmaname" : "", "cmauid" : "997", "prname" : "Manitoba"},
    {"csdname" : "White River", "csduid" : "3557091", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "White Rock", "csduid" : "5915007", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Whitesand", "csduid" : "3558097", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "White Sands", "csduid" : "4807027", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Whitestone", "csduid" : "3549039", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "White Valley No. 49", "csduid" : "4704024", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Whitewater Region", "csduid" : "3547056", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"},
    {"csdname" : "Whiteway", "csduid" : "1001308", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Whitewood", "csduid" : "4705034", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Whonnock 1", "csduid" : "5915840", "cmaname" : "", "cmauid" : "933", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Whycocomagh 2", "csduid" : "1215008", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Wickham", "csduid" : "1304008", "cmaname" : "", "cmauid" : "996", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Wickham", "csduid" : "2449040", "cmaname" : "", "cmauid" : "447", "prname" : "Quebec / Québec"},
    {"csdname" : "Wicklow", "csduid" : "1311031", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Wikwemikong Unceded", "csduid" : "3551043", "cmaname" : "", "cmauid" : "998", "prname" : "Ontario"},
    {"csdname" : "Wilcox", "csduid" : "4706014", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Wildcat 12", "csduid" : "1204015", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Wilkie", "csduid" : "4713069", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Williams Lake", "csduid" : "5941009", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Williams Lake 1", "csduid" : "5941812", "cmaname" : "", "cmauid" : "950", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Williams Prairie Meadow 1A", "csduid" : "5951840", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Willner No. 253", "csduid" : "4711016", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Willow Bunch", "csduid" : "4703028", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Willow Bunch No. 42", "csduid" : "4703026", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Willow Cree", "csduid" : "4715860", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Willow Creek No. 26", "csduid" : "4803018", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Willow Creek No. 458", "csduid" : "4714053", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Willowdale No. 153", "csduid" : "4705033", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Wilmot", "csduid" : "1311018", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Wilmot", "csduid" : "3530020", "cmaname" : "", "cmauid" : "541", "prname" : "Ontario"},
    {"csdname" : "Wilton No. 472", "csduid" : "4717022", "cmaname" : "", "cmauid" : "840", "prname" : "Saskatchewan"},
    {"csdname" : "Windsor", "csduid" : "2442088", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Windsor", "csduid" : "3537039", "cmaname" : "", "cmauid" : "559", "prname" : "Ontario"},
    {"csdname" : "Windthorst", "csduid" : "4705019", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Windy Mouth 7", "csduid" : "5941855", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Winkler", "csduid" : "4603050", "cmaname" : "", "cmauid" : "603", "prname" : "Manitoba"},
    {"csdname" : "Winneway", "csduid" : "2485804", "cmaname" : "", "cmauid" : "999", "prname" : "Quebec / Québec"},
    {"csdname" : "Winnipeg", "csduid" : "4611040", "cmaname" : "", "cmauid" : "602", "prname" : "Manitoba"},
    {"csdname" : "Winnipeg Beach", "csduid" : "4618034", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Winsloe North", "csduid" : "1102069", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "Winslow No. 319", "csduid" : "4713028", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Winterland", "csduid" : "1002023", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Winterton", "csduid" : "1001332", "cmaname" : "", "cmauid" : "997", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Wise Creek No. 77", "csduid" : "4704028", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Wiseton", "csduid" : "4712014", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Witchekan Lake 117", "csduid" : "4716862", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Witchekan Lake 117D", "csduid" : "4716882", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Witless Bay", "csduid" : "1001559", "cmaname" : "", "cmauid" : "001", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Wolfville", "csduid" : "1207024", "cmaname" : "", "cmauid" : "997", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Wôlinak", "csduid" : "2438802", "cmaname" : "", "cmauid" : "442", "prname" : "Quebec / Québec"},
    {"csdname" : "Wollaston", "csduid" : "3512054", "cmaname" : "", "cmauid" : "997", "prname" : "Ontario"},
    {"csdname" : "Wolseley", "csduid" : "4705024", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Wolseley No. 155", "csduid" : "4705027", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Wolverine No. 340", "csduid" : "4711096", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Wood Buffalo", "csduid" : "4816037", "cmaname" : "", "cmauid" : "860", "prname" : "Alberta"},
    {"csdname" : "Wood Creek No. 281", "csduid" : "4711039", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Woodland Cree 226", "csduid" : "4817856", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Woodland Cree 227", "csduid" : "4817850", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Woodland Cree 228", "csduid" : "4817857", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "Woodlands", "csduid" : "4614031", "cmaname" : "", "cmauid" : "996", "prname" : "Manitoba"},
    {"csdname" : "Woodlands County", "csduid" : "4813029", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Wood Mountain", "csduid" : "4703012", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Wood Mountain 160", "csduid" : "4703801", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Wood River No. 74", "csduid" : "4703042", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Woodstock", "csduid" : "1008096", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Woodstock", "csduid" : "1311004", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Woodstock", "csduid" : "1311006", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Woodstock", "csduid" : "3532042", "cmaname" : "", "cmauid" : "544", "prname" : "Ontario"},
    {"csdname" : "Woodstock 23", "csduid" : "1311005", "cmaname" : "", "cmauid" : "998", "prname" : "New Brunswick / Nouveau-Brunswick"},
    {"csdname" : "Woody Point, Bonne Bay", "csduid" : "1009011", "cmaname" : "", "cmauid" : "999", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "Woolwich", "csduid" : "3530035", "cmaname" : "", "cmauid" : "541", "prname" : "Ontario"},
    {"csdname" : "Wotton", "csduid" : "2440017", "cmaname" : "", "cmauid" : "997", "prname" : "Quebec / Québec"},
    {"csdname" : "Woyenne 27", "csduid" : "5951827", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Wreford No. 280", "csduid" : "4711042", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Wrigley", "csduid" : "6104044", "cmaname" : "", "cmauid" : "000", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Wunnumin 1", "csduid" : "3560085", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Wuskwi Sipihk First Nation 2", "csduid" : "4620060", "cmaname" : "", "cmauid" : "999", "prname" : "Manitoba"},
    {"csdname" : "Wynyard", "csduid" : "4710047", "cmaname" : "", "cmauid" : "998", "prname" : "Saskatchewan"},
    {"csdname" : "Yakweakwioose 12", "csduid" : "5909831", "cmaname" : "", "cmauid" : "930", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Yale Town 1", "csduid" : "5909818", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Yamachiche", "csduid" : "2451020", "cmaname" : "", "cmauid" : "442", "prname" : "Quebec / Québec"},
    {"csdname" : "Yamaska", "csduid" : "2453072", "cmaname" : "", "cmauid" : "996", "prname" : "Quebec / Québec"},
    {"csdname" : "Yarbo", "csduid" : "4705049", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Yarmouth", "csduid" : "1202004", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Yarmouth", "csduid" : "1202006", "cmaname" : "", "cmauid" : "998", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Yarmouth 33", "csduid" : "1202040", "cmaname" : "", "cmauid" : "999", "prname" : "Nova Scotia / Nouvelle-Écosse"},
    {"csdname" : "Yawaucht 11", "csduid" : "5933878", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Ye Koo Che 3", "csduid" : "5951801", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Yekwaupsum 18", "csduid" : "5931809", "cmaname" : "", "cmauid" : "934", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Yellow Grass", "csduid" : "4702072", "cmaname" : "", "cmauid" : "996", "prname" : "Saskatchewan"},
    {"csdname" : "Yellowhead", "csduid" : "4615063", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Yellowhead County", "csduid" : "4814003", "cmaname" : "", "cmauid" : "998", "prname" : "Alberta"},
    {"csdname" : "Yellowknife", "csduid" : "6106023", "cmaname" : "", "cmauid" : "995", "prname" : "Northwest Territories / Territoires du Nord-Ouest"},
    {"csdname" : "Yellowquill 90", "csduid" : "4714841", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Yellow Quill 90-9", "csduid" : "4714843", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Yellowstone", "csduid" : "4813007", "cmaname" : "", "cmauid" : "999", "prname" : "Alberta"},
    {"csdname" : "York", "csduid" : "1102061", "cmaname" : "", "cmauid" : "105", "prname" : "Prince Edward Island / Île-du-Prince-Édouard"},
    {"csdname" : "York Harbour", "csduid" : "1005035", "cmaname" : "", "cmauid" : "996", "prname" : "Newfoundland and Labrador / Terre-Neuve-et-Labrador"},
    {"csdname" : "York Landing", "csduid" : "4622065", "cmaname" : "", "cmauid" : "998", "prname" : "Manitoba"},
    {"csdname" : "Yorkton", "csduid" : "4709012", "cmaname" : "", "cmauid" : "710", "prname" : "Saskatchewan"},
    {"csdname" : "Young", "csduid" : "4711056", "cmaname" : "", "cmauid" : "997", "prname" : "Saskatchewan"},
    {"csdname" : "Youngstown", "csduid" : "4804019", "cmaname" : "", "cmauid" : "997", "prname" : "Alberta"},
    {"csdname" : "Yukon, Unorganized", "csduid" : "6001045", "cmaname" : "", "cmauid" : "000", "prname" : "Yukon"},
    {"csdname" : "Yuquot 1", "csduid" : "5924814", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Zacht 5", "csduid" : "5933879", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Zealandia", "csduid" : "4712008", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Zeballos", "csduid" : "5924029", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Zelma", "csduid" : "4711058", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Zenon Park", "csduid" : "4714061", "cmaname" : "", "cmauid" : "999", "prname" : "Saskatchewan"},
    {"csdname" : "Zhiibaahaasing 19A", "csduid" : "3551100", "cmaname" : "", "cmauid" : "999", "prname" : "Ontario"},
    {"csdname" : "Zoht 4", "csduid" : "5933811", "cmaname" : "", "cmauid" : "999", "prname" : "British Columbia / Colombie-Britannique"},
    {"csdname" : "Zorra", "csduid" : "3532027", "cmaname" : "", "cmauid" : "996", "prname" : "Ontario"}
]
export default censusGeography2021;